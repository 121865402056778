import { render, staticRenderFns } from "./BankAccountItem.vue?vue&type=template&id=9e2880fe&scoped=true"
import script from "./BankAccountItem.vue?vue&type=script&lang=js"
export * from "./BankAccountItem.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9e2880fe",
  null
  
)

export default component.exports