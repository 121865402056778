<template>
  <div class="timer">
    <div class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    <div class="time">{{ seconds }}</div>
  </div>

</template>

<script>
export default {
  name: 'TimerComponent',
  props: {
    totalTime: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      seconds: this.totalTime
    }
  },
  mounted () {
    this.printNumbersForEverySec(this.totalTime)
  },
  methods: {
    printNumbersForEverySec (seconds) {
      let counter = seconds

      const interval = setInterval(() => {
        counter--
        this.seconds = counter
        if (counter === 0) {
          clearInterval(interval)
        }
      }, 1000)

      this.seconds = seconds
    }
  }
}
</script>
