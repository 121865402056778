export default {
  login: {
    username: 'Email address or username',
    guidance: 'Enter in your account',
    register_question: 'Don\'t have an account?',
    username_validation: 'Please enter an email or an username',
    login_validation: 'Invalid password or username/email',
    forgot_password: 'Forgot password?',
    user_rejected: 'Your account is inactive. For more details you can contact us at suport@investimental.ro'
  },
  general: {
    log_in: 'Log in',
    two_factor_auth: '2FA',
    login: 'Login',
    register: 'Register',
    reset_password: 'Reset password',
    save: 'Save',
    resend_code: 'Resend code'
  },
  recovery_password: {
    title: 'Fill in the new password',
    success_message: 'The password was successfully changed',
    recovery_instructions: 'Enter the email address and follow the received instructions',
    recovery_success: 'We\'ve send an email with the password reset instructions',
    back_to_login: 'Back to login'
  },
  reset_password: {
    old_password: 'Old password',
    new_password: 'New password',
    repeat_new_password: 'Repeat new password',
    old_password_invalid: 'Invalid old password!',
    reset_successfully: 'User reset password successfully!',
    old_password_validation: 'Please enter the old password!',
    new_password_validation: 'Please enter a new password!'
  },
  two_factor_auth: {
    code: 'Code',
    recovery_code: 'Recovery Code',
    use_recovery_code: 'Use recovery code',
    check: 'Check',
    save_device: 'Save device',
    guidance: 'You need to activate the two-factor authentication to protect your account.',
    enable_2fa: 'Enable 2FA',
    grace_period: 'We recommend to activate two factor authentication for a better security of your account. This option becomes mandatory after {days} days of account opening.',
    download_app: 'If you don`t already have one, you can download it below',
    change2fa: 'Change 2FA application'
  }
}
