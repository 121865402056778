<template>
  <div></div>
</template>

<script>

export default {
  name: 'vue-title',
  props: {
    title: {
      type: String,
      default: 'Investimental'
    }
  },
  watch: {
    title: {
      immediate: true,
      handler () {
        document.title = this.title
      }
    }
  }
}

</script>
