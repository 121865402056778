<template>
    <div v-if="!loading">
        <div class="personal-info" :class="{ 'px-1 py-7': isMobile }">
            <div class="flex items-center mb-4" :class="{ 'px-6': isMobile }">
                <iconColor color="green" icon="user-dot-border" />

                <div class="ml-5">
                    <span class="edit font-extrabold name account-title">{{
                        name
                    }}</span>
                </div>
            </div>

            <div class="desktop-flex gap-8 mt-8">
                <div class="info mb-4" :class="{ 'w-1/2': !isMobile }">
                    <InputText
                        :id="'name'"
                        v-model="name"
                        :label="$t('onboarding.legal_person_details.last_name')"
                        :type-input="'text'"
                        :disabled-input="true"
                    />
                </div>

                <div class="info mb-4" :class="{ 'w-1/2': !isMobile }">
                    <InputText
                        :id="'lastName'"
                        v-model="tin"
                        :label="$t('onboarding.legal_person_details.tin')"
                        :type-input="'text'"
                        :disabled-input="true"
                    />
                </div>
            </div>

            <div class="desktop-flex gap-8 items-end" :class="'mt-8'">
                <div class="info mb-4" :class="{ 'w-1/2': !isMobile }">
                    <InputText
                        :id="'cnp'"
                        v-model="crn"
                        :label="$t('onboarding.legal_person_details.crn')"
                        :type-input="'text'"
                        :disabled-input="true"
                    />
                </div>

                <div class="info mb-4" :class="{ 'w-1/2': !isMobile }">
                    <InputText
                        :id="'administrator_pin'"
                        v-model="administrator_pin"
                        :label="$t('onboarding.legal_person_details.pin')"
                        :type-input="'text'"
                        :disabled-input="true"
                    />
                </div>
            </div>
        </div>
    </div>
    <PulseLoader v-else class="w-fit m-auto p-10 justify-center flex"/>
</template>

<script>
import StepBar from '../../../layout/StepBar'
import Button from '../../../subcomponents/Button'
import Card from '../../../subcomponents/Card'
import IconMessage from '../../../subcomponents/IconMessage'
import InputText from '../../../subcomponents/InputText'
import Message from '../../../subcomponents/Message'
import { mapActions, mapGetters } from 'vuex'
import Calendar from '../../../subcomponents/Calendar.vue'
import IconBox from '../../../subcomponents/IconBox.vue'
import IconColor from '../../../subcomponents/IconColor.vue'
import PopUpModal from '../../../subcomponents/PopUpModal.vue'

export default {
  name: 'CompanyCardForm',
  components: {
    PopUpModal,
    IconColor,
    IconBox,
    Calendar,
    StepBar,
    Button,
    Card,
    IconMessage,
    InputText,
    Message
  },
  data () {
    return {
      loading: false,
      name: null,
      tin: null,
      crn: null,
      administrator_pin: null
    }
  },
  async mounted () {
    await this.setData()
  },
  computed: {
    ...mapGetters({
      screenDevice: 'layout/screenDevice'
    }),
    isMobile () {
      return this.screenDevice === this.$screenDeviceConstants.MOBILE
    }
  },
  methods: {
    ...mapActions({
      getUserData: 'user/getUserData'
    }),
    async setData () {
      this.loading = true
      let userDataDb = await this.getUserData()

      if (userDataDb.status === 200) {
        this.name = userDataDb.data.data.user.company.name
        this.tin = userDataDb.data.data.user.company.tin
        this.crn = userDataDb.data.data.user.company.crn
        this.administrator_pin =
                    userDataDb.data.data.user.company.administrator_pin
      }
      this.loading = false
    }
  }
}
</script>

<style scoped>
</style>
