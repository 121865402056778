<template>
  <div>
    <SelectDropDown
      v-if="detail.type === 'selector'"
      v-model="selectedAnswerDetail"
      :options="detail.values"
      class="mb-4"
      required
    />

    <textarea
      v-if="detail.type === 'textarea'"
      v-model="selectedAnswerDetail"
      :placeholder="detail.placeholder"
      class="textarea input-primary"
      cols="30"
      maxlength="200"
      required
      rows="10"
    ></textarea>

    <InputText
      v-if="detail.type === 'input'"
      v-model="selectedAnswerDetail"
      :disabled-input="disabled"
      :type-input="'text'"
    />

    <FullAddress v-if="detail.type === 'addressComponent'"
                 :preSelectedAddress="preSelectedAnswerDetail ? preSelectedAnswerDetail : {}"
                 :precompleted-text="preSelectedAnswerDetail.street"
                 :type="'onboarding'"
                 class="mt-5"
                 @fullAddress="setFullAddress"/>
  </div>
</template>

<script>
import InputText from '../subcomponents/InputText'
import SelectDropDown from '../subcomponents/SelectDropDown'
import FullAddress from '../subcomponents/FullAddress'

export default {
  name: 'Detail',
  components: {InputText, SelectDropDown, FullAddress},
  props: {
    detail: {
      type: Object,
      required: true
    },
    preSelectedAnswerDetail: {
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      selectedAnswerDetail: ''
    }
  },
  beforeMount () {
    if (this.detail.type === 'selector') {
      this.selectedAnswerDetail = this.detail.values.filter((value) => value.value === this.preSelectedAnswerDetail)[0]
      return
    }

    this.selectedAnswerDetail = this.preSelectedAnswerDetail
  },
  methods: {
    setFullAddress (address) {
      this.selectedAnswerDetail = address
    }
  },
  watch: {
    selectedAnswerDetail (selectedAnswerDetail) {
      let value = selectedAnswerDetail

      if (typeof value === 'undefined') {
        return
      }

      if (this.detail.type === 'selector') {
        value = selectedAnswerDetail.value
      }

      this.$emit('selectedAnswerDetail', this.detail.identifier, value)
    }
  }
}
</script>

<style scoped>

</style>
