<template>
    <div class="email-verification">
        <vue-title :title="$t('general.meta_titles.phone_validation')"></vue-title>

        <div class="m-auto">
            <StepBar :current-step="selectedTabId" :steps="tabs"></StepBar>
        </div>
        <Card :card-classes="'margin-bottom-medium mobile-border-none'">
            <template v-slot:top>
                <img src="~@/assets/img/icons/green_circle_person.svg"/>
            </template>

            <template v-slot:title>
                <div>{{ $t("update_personal_data.phone_number.phone_verif_guidance") }}</div>
            </template>

            <template v-slot:body>
                <VerifyPhoneNumber :from="'PJ'"/>
            </template>
        </Card>
    </div>
</template>

<script>
import StepBar from '../layout/StepBar'
import { mapActions, mapGetters } from 'vuex'
import Button from '../subcomponents/Button'
import Message from '../subcomponents/Message'
import { ONBOARDING_INFO } from '../../router'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import Card from '../subcomponents/Card'
import OtpInput from '../subcomponents/security-code/OtpInput'
import EmailVerificationCode from '../subcomponents/EmailVerificationCode.vue'
import UserService from '../../services/auth/userService'
import InputText from '@/components/subcomponents/InputText.vue'
import VerifyPhoneNumber from '@/components/profile/update-personal-data/VerifyPhoneNumber.vue'

export default {
  name: 'PhoneValidation',
  components: { VerifyPhoneNumber, InputText, Card, StepBar, Button, Message, PulseLoader, OtpInput, EmailVerificationCode },
  data () {
    return {
      tabs: [
        {
          id: 1,
          label: this.$i18n.t('onboarding.general.create_legal_account')
        },
        {
          id: 2,
          label: this.$i18n.t('onboarding.general.identity_verification')
        },
        {
          id: 3,
          label: this.$i18n.t('onboarding.general.legal_person_details')
        }
      ],
      selectedTabId: 2

    }
  },
  mounted () {
  },
  computed: {
    ...mapGetters({
      userEmail: 'user/email',
      userToken: 'user/token',
      screenDevice: 'layout/screenDevice'
    })
  },
  methods: {
    ...mapActions({
      verifyCode: 'user/verifyCode',
      resendCode: 'user/resendCode',
      logout: 'user/logout'
    }),
    async validateCode () {
      this.loading = true
      let response = await this.verifyCode({
        verificationCode: this.verificationCode
      }).catch(error => {
        this.loading = false
        if (error.response.status === 400) {
          this.validation.verificationCode = this.$i18n.t(
            'onboarding.email_verification.invalid_code'
          )
        }
      })

      if (response && response.status === 200) {
        this.validation.resentCode = this.$i18n.t(
          'onboarding.email_verification.user_verif_success'
        )

        if (this.$route.meta['source'] === 'lms') {
          let responseLms = await UserService.getLmsUrl()

          if (responseLms.data.data.url && responseLms.data.data.url.length) {
            window.location.href = responseLms.data.data.url
          }

          if (responseLms.status === 500) {
            this.lmsError = response.message
          }
          if (responseLms.data.data.status === 'retry') {
            this.interval = setInterval(this.regenerateLmsUrl, 1500)
          }
        } else {
          await this.$router.push({ name: ONBOARDING_INFO })
        }
      }

      this.loading = false
    },

    regenerateLmsUrl () {
      UserService.getLmsUrl()
        .then(response => {
          if (response.data.data.status === 'done') {
            window.location.href = response.data.data.url
            clearInterval(this.interval)
          }
        })
    },

    async resetStep () {
      await this.$confirm(
        this.$t('onboarding.reset_steps.reset_email_question'),
        '',
        'warning',
        { cancelButtonText: this.$t('general.general.cancel') }
      ).then(async () => {
        await this.logout()
        if (!this.isLogged) {
          if (this.$route.meta['source'] === 'lms') {
            await this.$router.push({name: 'register-lms'})
          } else {
            await this.$router.push({name: 'register-pj'})
          }
        }
      })
    },
    setVerificationCode (code) {
      this.verificationCode = code
    }
  },
  watch: {
    verificationCode () {
      this.validation.verificationCode = ''
    }
  }
}
</script>

<style scoped></style>
