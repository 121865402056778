export function format (dateString, formatString = 'DD/MM/YYYY') {
  if (!dateString) return ''

  formatString = formatString.toUpperCase()

  // Check if the date string contains hyphens, slashes, or dots
  const delimiter = dateString.includes('-')
    ? '-'
    : dateString.includes('/')
      ? '/'
      : '.'

  // Split the date string into year, month, and day
  const parts = dateString.split(delimiter)
  const year = parts[0]
  let month = parts[1]
  let day = parts[2]

  if (month.length === 1) {
    month = '0' + month
  }

  if (day.length === 1) {
    day = '0' + day
  }

  const delimiterFormat = formatString.includes('-')
    ? '-'
    : dateString.includes('/')
      ? '/'
      : '.'

  // Split the format string into date part placeholders
  const formatParts = formatString.split(delimiterFormat)

  // Create an object to map date parts to their corresponding values
  const dateParts = {
    'DD': day,
    'MM': month,
    'YYYY': year
  }

  // Replace format placeholders with the corresponding date parts
  // Return the formatted date
  return formatParts
    .map(part => dateParts[part])
    .reverse()
    .join(delimiterFormat)
}
