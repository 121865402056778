<template>
  <div>
    <vue-title :title="$t('general.meta_titles.messages')"></vue-title>
    <general-layout>
      <template v-slot:tabs>
        <Tabs :show-id-title="false" :user-info="userInfo" :general-info="generalInfo"/>
      </template>

      <template v-slot:body>
        <ClientNotifications type="general"></ClientNotifications>
      </template>
    </general-layout>
  </div>
</template>

<script>

import ClientNotifications from './ClientNotifications'
import GeneralLayout from '@/components/layout/GeneralLayout.vue'
import Tabs from '@/components/layout/Tabs.vue'
import {mapActions, mapGetters} from 'vuex'

export default {
  name: 'Messages',
  components: {Tabs, GeneralLayout, ClientNotifications},
  computed: {
    ...mapGetters({
      unreadUserNotifications: 'user/unreadUserNotifications',
      unreadGeneralNotifications: 'user/unreadGeneralNotifications'
    }),
    userInfo () {
      return this.unreadUserNotifications
    },
    generalInfo () {
      return this.unreadGeneralNotifications
    }
  },
  created () {
    this.getUnreadNotifications()
  },
  methods: {
    ...mapActions({
      getUnreadNotifications: 'user/getUnreadNotifications'
    })
  }
}
</script>

<style scoped></style>
