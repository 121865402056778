<template>
  <div class="stock-wrapper" :class="{'smaller-logo' : smallerLogo, 'stock-symbol-wrapper': !img}">
    <img alt="logo" :src="img" v-if="img">
    <div class="stock-symbol" :class="color" v-else>
      {{ symbol }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'StockSecurityLogo',
  props: {
    img: {
      type: String,
      default: ''
    },
    smallerLogo: {
      type: Boolean,
      default: false
    },
    symbol: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: 'light-blue'
    }
  },
  data () {
    return {
      colorBlock: [
        'light-blue',
        'dark-blue',
        'yellow',
        'primary',
        'pink'
      ]
    }
  }
}
</script>
