import UserService from '../services/auth/userService'
import {mapGetters} from 'vuex'

export default {
  computed: {
    ...mapGetters({
      user: 'user/user',
      jwt: 'user/jwt'
    })
  },
  data () {
    return {
      showNewUserModal: false,
      arenaXtLoading: false,
      middlewareLoading: false,
      DXLoading: false
    }
  },
  methods: {
    async logoutUser () {
      await this.logout()
      if (!this.isLogged) {
        await this.$router.push({name: 'login'})
      }
    },
    async getMiddlewareToken () {
      if (navigator.userAgent.toLowerCase().indexOf('iphone') > -1) {
        window.location.href = 'https://itunes.apple.com/app/investimental/id6443487507'
        return
      }

      if (navigator.userAgent.toLowerCase().indexOf('android') > -1) {
        window.location.href = 'https://play.google.com/store/apps/details?id=ro.bvb.investimental'
        return
      }

      this.middlewareLoading = true
      let middlewareTokenResponse = await UserService.generateMiddlewareToken(this.jwt)
      this.middlewareLoading = false

      if (middlewareTokenResponse.status === 200 && this.user.status !== 'new') {
        const url = process.env.VUE_APP_WEB_TERMINAL_URL
        let target = this.isDesktop ? '_blank' : '_top'
        setTimeout(() => {
          window.open(url + '?token=' + middlewareTokenResponse.data.data.token, target)
        })
      } else {
        this.showNewUserModal = true
      }
    },
    async getDxToken () {
      if (navigator.userAgent.toLowerCase().indexOf('iphone') > -1) {
        window.location.href = 'https://itunes.apple.com/app/investimental-global/id6449232447'
        return
      }

      if (navigator.userAgent.toLowerCase().indexOf('android') > -1) {
        window.location.href = 'https://play.google.com/store/apps/details?id=com.devexperts.dxmarket.investimental'
        return
      }

      this.DXLoading = true
      let jwt = await UserService.generateDxToken().catch(() => {
        this.DXLoading = false
      })
      this.DXLoading = false

      if (jwt.status === 200 && this.user.status !== 'new') {
        const url = process.env.VUE_APP_DX_URL
        let target = this.isDesktop ? '_blank' : '_top'
        setTimeout(() => {
          window.open(url + '?token=' + jwt.data.data.token, target)
        })
      } else {
        this.showNewUserModal = true
      }
    },
    async getJwt () {
      if (navigator.userAgent.toLowerCase().indexOf('iphone') > -1) {
        window.location.href = 'https://itunes.apple.com/app/investimental/id6443487507'
        return
      }

      if (navigator.userAgent.toLowerCase().indexOf('android') > -1) {
        window.location.href = 'https://play.google.com/store/apps/details?id=ro.bvb.investimental'
        return
      }

      this.arenaXtLoading = true
      let jwt = await UserService.generateJwt()
      this.arenaXtLoading = false

      if (jwt.status === 200 && this.user.status !== 'new') {
        const url = process.env.VUE_APP_ARENA_XT_URL
        let target = this.isDesktop ? '_blank' : '_top'
        setTimeout(() => {
          window.open(url + '?jwt=' + jwt.data.data.jwt, target)
        })
      } else {
        this.showNewUserModal = true
      }
    },

    closePopUp () {
      this.showNewUserModal = false
    },

    async loginInLms () {
      let target = this.isDesktop ? '_blank' : '_top'
      await UserService.getLmsUrl()
        .then(response => {
          setTimeout(() => {
            window.open(response.data.data.url, target)
          })
        })
    }
  }
}
