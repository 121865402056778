<template>
  <div class="targets-component gap-10 flex mt-4">
    <div class="left">
      <TargetsInstrumentTable
        :instruments="addedInstruments"
        :total="sumTotalOfTargets"
        @removeInstrument="removePosition"
        @setPositions="setPositions"
        @validationPercentage="validationPercentage"
      />
    </div>
    <div class="right">
      <DoughnutChartPIES
        :chart-id="'total'"
        :total="sumTotalOfTargets"
        :chart-data-sets="chartData"
        :show-total="true"
        :border-width="3"
      />

      <div class="buttons-wrapper">
        <div class="buttons-first">
          <Button
            @call="distributeEqually"
            extra-classes="px-5 mb-3.5"
            :text="$t('deposit_withdrawal.auto_invest.targets.distribute_equally')"
            :type="'btn-dark-outline'"
          />

          <Button
            @call="addInstruments"
            extra-classes="px-5"
            :text="$t('deposit_withdrawal.auto_invest.targets.add_instruments')"
            :type="'btn-dark-background'"
            :icon="'icons/circled_add'"
            :icon-position="'left'"
          />
        </div>
        <div class="relative w-full block mb-4 text-center">
          <div class="error-color text-center absolute w-full m-auto bottom-0.5" v-if="sumTotalOfTargets !== 100 || validationMinPercentage.length">
            <div v-if="sumTotalOfTargets !== 100">{{ $t('deposit_withdrawal.auto_invest.transfers.instruments_total_validation') }}</div>
            <div v-if="validationMinPercentage.length">{{ validationMinPercentage }}</div>
          </div>
        </div>
        <Button
          :text="$t('general.general.next_step')"
          :type="'btn-primary'"
          :disabled="sumTotalOfTargets !== 100 || addedInstruments.length < minimumInstrumentsOfPie || !!validationMinPercentage.length"
          class="mt-4 w-fit"
          @call="lastStep()"/>
      </div>
    </div>
  </div>
</template>

<script>
import DoughnutChartPIES from '@/components/subcomponents/auto-invest/DoughnutChartPIES.vue'
import TargetsInstrumentTable from '@/components/subcomponents/auto-invest/TargetsInstrumentTable.vue'
import {mapActions, mapGetters} from 'vuex'
import Button from '@/components/subcomponents/Button.vue'

export default {
  name: 'TargetsPiesComponent',
  components: {Button, TargetsInstrumentTable, DoughnutChartPIES},
  computed: {
    ...mapGetters({
      newPie: 'pies/newPie',
      minimumInstrumentsOfPie: 'user/minimumInstrumentsOfPie',
      deletedStockSecurities: 'pies/deletedStockSecurities'
    })
  },
  data () {
    return {
      addedInstruments: [],
      sumTotalOfTargets: 0,
      chartData: {
        datasets: [
          {
            backgroundColor: ['#46A4E4', '#00163E', '#F5D150', '#3AD5AF', '#CEA6DE'],
            data: []
          }
        ]
      },
      validationMinPercentage: ''
    }
  },
  async mounted () {
    this.addedInstruments = this.newPie.positions ? this.newPie.positions : []

    if (this.newPie.account_alias) {
      await this.distributeEqually()
    } else {
      await this.createTargetShown()
    }
    await this.setPositions(this.addedInstruments)
  },
  methods: {
    ...mapActions({
      setNewPie: 'pies/setNewPie',
      setAddedStockSecurities: 'pies/setAddedStockSecurities',
      setDeletedStockSecurities: 'pies/setDeletedStockSecurities'
    }),

    validationPercentage (message) {
      this.validationMinPercentage = message
    },

    distributeEqually () {
      const length = this.addedInstruments.length
      const target = 100 / length
      this.validationMinPercentage = ''

      let sum = 0
      this.addedInstruments.forEach((instrument, index) => {
        instrument.target_percentage_of_pie = target

        if (index === this.addedInstruments.length - 1) {
          instrument.target_shown = Math.round((100 - Math.round(sum * 100) / 100) * 100) / 100
        } else {
          instrument.target_shown = Math.ceil(target * 100) / 100
          sum += instrument.target_shown
        }
      })
    },

    createTargetShown () {
      let sum = 0
      this.addedInstruments.forEach((instrument, index) => {
        if (instrument.target_percentage_of_pie && instrument.current_value >= 0) {
          if (index === this.addedInstruments.length - 1) {
            instrument.target_shown = Math.round((100 - sum) * 100) / 100
          } else {
            instrument.target_shown = Math.floor(instrument.target_percentage_of_pie * 100) / 100
            sum += instrument.target_shown
          }
        } else {
          instrument.target_shown = 1
          instrument.target_percentage_of_pie = '1'
        }
      })
    },

    setPositions (positions) {
      this.newPie.positions = positions
      this.setNewPie(this.newPie)
      this.chartData.datasets[0].data = positions.map((position) => position.target_shown)
      this.chartData.labels = positions.map((position) => position.stock_security.symbol)
      this.totalSumOfTargets()
    },

    totalSumOfTargets () {
      let sum = 0
      this.newPie.positions.forEach((instrument) => {
        sum += instrument.target_shown
      })
      this.sumTotalOfTargets = Math.round(sum * 100) / 100
    },

    async removePosition (position) {
      let deletedInstruments = this.deletedStockSecurities
      let deletedInstrument = this.addedInstruments.filter(item => item.stock_security.id === position.stock_security.id)[0].stock_security

      deletedInstruments.push(deletedInstrument.id)

      this.addedInstruments = this.addedInstruments.filter((instrument) => instrument.stock_security.id !== position.stock_security.id)

      await this.setDeletedStockSecurities(deletedInstruments)

      if (this.newPie.account_alias) {
        await this.distributeEqually()
      }

      await this.setPositions(this.addedInstruments)
    },

    addInstruments () {
      this.$emit('addInstruments')
    },

    validatedInstruments () {
      let validated = true
      this.validationMinPercentage = ''
      this.addedInstruments.forEach((instrument) => {
        if (instrument.target_percentage_of_pie < 1) {
          validated = false
        }
      })

      if (!validated) {
        this.validationMinPercentage = this.$t('deposit_withdrawal.auto_invest.targets.validation_min_percentage')
        return false
      }

      return true
    },

    async lastStep () {
      if (this.validatedInstruments()) {
        await this.setPositions(this.addedInstruments)
        this.$emit('lastStep')
      }
    }
  }
}
</script>
