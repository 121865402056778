<template>
  <div v-if="loaded" class="select-domicile">
    <vue-title :title="$t('general.meta_titles.domicile')"></vue-title>

    <div class="m-auto">
      <StepBar :current-step="selectedTabId" :steps="tabs"></StepBar>
    </div>
    <Card :card-classes="'margin-bottom-medium mobile-border-none'">
      <template v-slot:top>
        <img src="~@/assets/img/icons/yellow_user_verified.svg">
      </template>

      <template v-slot:title>
        <div>{{ $t('onboarding.general.identity_verification') }}</div>
      </template>

      <template v-slot:body>
        <div slot="body">
          <div class="mb-10">
            <label>
              {{ $t('onboarding.select_domicile.permanent_domicile') }}:
            </label>
            <SelectDropDownFav
              :id="selectedCountry+'id'"
              v-model="selectedCountry"
              :options="countriesAll"
              :name-format-search="'label'"
              class="mr-2"
              required
            />
            <Message v-if="validateCountry" :message="$t('onboarding.select_domicile.select_country')" :type="'error'"/>
          </div>

          <div class="mb-10">
            <div>{{ $t('onboarding.select_domicile.citizenship') }}</div>

            <div
              v-for="(selectedCitizenship, key) in selectedCitizenships"
              :key="key"
              class="flex w-full mt-3">
              <SelectDropDownFav
                :id="selectedCitizenship.label+'id'"
                v-model="selectedCitizenship.label"
                :name-format="'label'"
                :options="countriesRemaining"
                class="mr-2 w-full"
                @input="updateCitizenships($event, key)"
              />

              <div
                v-if="selectedCitizenships.length > 1"
                class="cursor-pointer delete-button"
                @click="deleteCitizenshipModel(key)">
                <div class="flex items-center delete-icon">
                  <img
                    :src="require(`@/assets/img/icons/trash-can-fill.svg`)"
                    alt="icon"
                  />
                </div>
              </div>
            </div>

            <div class="mt-5">
              <Button
                :icon="'icons/plus-circle-lined'"
                :icon-position="'left'"
                :text="$t('onboarding.select_domicile.multiple_citizenship')"
                :type="'btn-icon-text'"
                class="mt-4 w-fit"
                @call="addCitizenship()">
              </Button>
            </div>
          </div>

          <Message v-if="validateCitizenships" :message="$t('onboarding.select_domicile.select_citizenships')"
                   :type="'error'"/>
          <Message v-if="completedCitizenships" :message="$t('onboarding.select_domicile.complete_citizenships')"
                   :type="'error'"/>

          <div class="flex justify-between items-center mt-10">
            <Button
              @call="resetStep()"
              :text="$t('general.general.back')"
              :type="'btn btn-secondary padding-medium end-position no-wrap'"
              :icon="'icons/arrow_back_green'"
              :icon-position="'left'"
            />

            <div class="flex justify-end w-fit ml-auto">
              <Button
                :text="$t('general.general.continue')"
                :type="'btn btn-primary padding-big'"
                @call = "next()"
                :loading="loadingData.continue"
              />
            </div>
          </div>
        </div>
      </template>
    </Card>
  </div>
</template>

<script>
import StepBar from '../layout/StepBar'
import ApiService from '../../services/apiService'
import Message from '../subcomponents/Message'
import Button from '../subcomponents/Button'
import Card from '../subcomponents/Card'
import SelectDropDown from '../subcomponents/SelectDropDown'
import SelectDropDownFav from '../subcomponents/SelectDropDownFav'
import {ONBOARDING_VERIFY_IDENTITY} from '../../router'
import {mapGetters, mapActions} from 'vuex'

export default {
  name: 'SelectDomicile',
  components: {Button, Message, StepBar, Card, SelectDropDown, SelectDropDownFav},
  computed: {
    ...mapGetters({
      screenDevice: 'layout/screenDevice',
      user: 'user/user'
    })
  },
  data () {
    return {
      tabs: [
        {
          id: 1,
          label: this.$i18n.t('onboarding.general.create_account')
        },
        {
          id: 2,
          label: this.$i18n.t('onboarding.general.identity_verification')
        },
        {
          id: 3,
          label: this.$i18n.t('onboarding.general.financial_data')
        }
      ],
      loadingData: {
        continue: false
      },
      selectedTabId: 2,
      tabsClasses: ['justify-center'],
      countriesRemaining: [],
      countriesAll: [],
      selectedCountry: null,
      loaded: false,
      validateCountry: false,
      selectedCitizenships: [],
      citizenships: [],
      validateCitizenships: false,
      completedCitizenships: false
    }
  },
  async mounted () {
    let countriesData = await ApiService.withAuth().get('/api/countries')
    let favCountry = process.env.VUE_APP_FAVORITE_COUNTRY
    let favCountryCollected = []

    if (countriesData.status === 200) {
      countriesData.data.data.map(country => {
        if (favCountry.includes(country.name)) {
          favCountryCollected.push(this.createCountryObj(country))
        } else {
          this.countriesAll.push(this.createCountryObj(country))
        }
      })

      favCountryCollected.push({
        label: 'none',
        id: null,
        divider: true
      })

      this.countriesAll = favCountryCollected.concat(this.countriesAll)

      this.countriesRemaining = this.countriesAll
      this.citizenships = this.countriesRemaining
      if (this.user.citizenships.length) {
        this.selectedCitizenships = this.user.citizenships.map((country) => {
          return this.createCountryObj(country)
        })
      } else {
        this.selectedCitizenships.push({
          label: '',
          id: null
        })
      }

      if (this.user.domicile) {
        this.selectedCountry = {
          id: this.user.domicile.id,
          label: this.user.domicile.name
        }
      }

      this.loaded = true
    }
  },
  methods: {
    ...mapActions({
      logout: 'user/logout'
    }),
    deleteCitizenshipModel (key) {
      this.selectedCitizenships.splice(key, 1)
    },
    updateCitizenships (label, key) {
      this.$set(this.selectedCitizenships, key, this.citizenships.find(citizenship => citizenship.label === label))
    },
    addCitizenship () {
      this.selectedCitizenships.push({
        label: '',
        id: null
      })
    },
    validate () {
      let valid = true

      if (!this.selectedCountry) {
        this.validateCountry = true
        valid = false
      }

      if (!this.selectedCitizenships.length) {
        this.validateCitizenships = true
        valid = false
      } else {
        for (let obj of this.selectedCitizenships) {
          if (obj.id === null || obj.label === '' || !obj.label) {
            this.completedCitizenships = true
            valid = false
          }
        }
      }

      return valid
    },
    async next () {
      this.loadingData.continue = true
      try {
        if (!this.validate()) {
          return
        }

        let selectDomicileData = await ApiService.withAuth().put('/api/user', {
          domicile_id: this.selectedCountry.id,
          citizenships: this.selectedCitizenships.map((country) => {
            return country.id
          }).join(',')
        })

        if (selectDomicileData.data.data.user.has_access_in_portal) {
          await this.$router.push({name: 'homepage'})
          return
        }

        await this.$router.push({ name: ONBOARDING_VERIFY_IDENTITY })
      } finally {
        this.loadingData.continue = false
      }
    },
    async resetStep () {
      await this.$confirm(
        this.$t('onboarding.reset_steps.reset_email_question'),
        '',
        'warning',
        { cancelButtonText: this.$t('general.general.cancel') }
      ).then(async () => {
        await this.logout()
        if (!this.isLogged) {
          this.$router.push({ name: 'register' })
        }
      })
    },
    createCountryObj (country) {
      return {label: country.name, id: country.id}
    }
  },
  watch: {
    selectedCountry () {
      this.validateCountry = false
    },
    selectedCitizenships (selectedCitizenships) {
      let countriesIds = []

      selectedCitizenships.forEach((citizenship) => {
        if (citizenship.id) {
          countriesIds.push(citizenship.id)
        }
      })

      this.countriesRemaining = this.countriesAll.filter((country) => !countriesIds.includes(country.id))

      this.validateCitizenships = false
      this.completedCitizenships = false
    }
  }
}
</script>

<style scoped>

</style>
