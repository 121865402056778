import i18n from '@/i18n.js'

export let menus =
  {
    bankAccounts: [
      {
        id: 1,
        label: i18n.t('general.menu.bank_accounts'),
        title: i18n.t('general.menu.money'),
        route: 'bank-accounts'
      },
      {
        id: 2,
        label: i18n.t('general.menu.deposits'),
        title: i18n.t('general.menu.money'),
        route: 'deposits'
      },
      {
        id: 3,
        label: i18n.t('general.menu.withdrawals'),
        title: i18n.t('general.menu.money'),
        route: 'withdrawals'
      },
      {
        id: 4,
        label: i18n.t('general.menu.internal_transfers'),
        title: i18n.t('general.menu.money'),
        route: 'account-transfers'
      },
      {
        id: 5,
        label: i18n.t('general.menu.report'),
        title: i18n.t('general.menu.money'),
        route: 'report'
      }
    ],
    assets: [
      {
        id: 1,
        label: i18n.t('general.menu.portofolio'),
        title: i18n.t('general.menu.transfers'),
        route: 'portofolio'
      },
      {
        id: 2,
        label: i18n.t('general.menu.pies'),
        title: i18n.t('general.menu.transfers'),
        route: 'pies',
        hideForCompany: true,
        isBeta: true
      },
      {
        id: 3,
        label: i18n.t('general.menu.multi_account'),
        title: i18n.t('general.menu.transfers'),
        route: 'multi-account',
        hideForCompany: true
      },
      {
        id: 4,
        label: i18n.t('general.menu.fiscal_debt'),
        title: i18n.t('general.menu.transfers'),
        route: 'fiscal-debt',
        hideForCompany: true
      },
      {
        id: 5,
        label: i18n.t('general.menu.instruments_transfer'),
        title: i18n.t('general.menu.transfers'),
        route: 'instruments-transfer'
      }

    ],
    settings: [
      {
        id: 1,
        label: i18n.t('general.menu.settings'),
        title: i18n.t('general.menu.my_account'),
        route: 'settings'
      },
      {
        id: 2,
        label: i18n.t('general.menu.personal_data'),
        title: i18n.t('general.menu.my_account'),
        route: 'personal-data',
        hideForCompany: true
      },
      {
        id: 3,
        label: i18n.t('general.menu.fiscal_data'),
        title: i18n.t('general.menu.my_account'),
        route: 'fiscal-data',
        hideForCompany: true
      },
      {
        id: 4,
        label: i18n.t('general.menu.pj_data'),
        title: i18n.t('general.menu.my_account'),
        route: 'pj-data',
        showOnlyToCompany: true
      },
      {
        id: 5,
        label: i18n.t('general.menu.documents'),
        title: i18n.t('general.menu.my_account'),
        route: 'profile-documents',
        parent: 'profile-documents',
        subroutes: [
          {
            label: i18n.t('general.menu.pj_documents'),
            title: i18n.t('general.menu.my_account'),
            route: 'pj-documents',
            parent: 'profile-documents',
            showOnlyToCompany: true
          },
          {
            label: i18n.t('general.menu.personalised_documents'),
            title: i18n.t('general.menu.my_account'),
            route: 'profile-documents',
            parent: 'profile-documents'
          },
          {
            label: i18n.t('general.menu.clients_documents'),
            title: i18n.t('general.menu.my_account'),
            route: 'client-documents',
            parent: 'profile-documents',
            hideForCompany: true
          },
          {
            label: i18n.t('general.menu.notification_documents'),
            title: i18n.t('general.menu.my_account'),
            route: 'notification-documents',
            parent: 'profile-documents'
          },
          {
            label: i18n.t('general.menu.invoices'),
            title: i18n.t('general.menu.my_account'),
            route: 'invoices',
            parent: 'profile-documents',
            showOnlyToCompany: true
          }
        ]
      },
      {
        id: 6,
        label: i18n.t('general.menu.security'),
        title: i18n.t('general.menu.my_account'),
        route: 'password-reset'
      }
    ],
    help: [{
      id: 5,
      label: i18n.t('general.menu.help'),
      route: 'help'
    }],
    notifications: [
      {
        id: 1,
        label: i18n.t('general.menu.notifications'),
        title: i18n.t('general.menu.notifications'),
        route: 'notifications',
        unreadNotificationsBubble: true
      },
      {
        id: 2,
        label: i18n.t('general.menu.messages'),
        title: i18n.t('general.menu.notifications'),
        route: 'messages',
        unreadMessagesBubble: true
      },
      {
        id: 3,
        label: i18n.t('general.menu.analyses'),
        title: i18n.t('general.menu.notifications'),
        route: 'morning-news',
        parent: 'notifications',
        hideForDesktop: true,
        hideForS1User: true,
        subroutes: [
          {
            label: i18n.t('general.menu.morning_news'),
            title: i18n.t('general.menu.notifications'),
            route: 'morning-news',
            parent: 'notifications'
          },
          {
            label: i18n.t('general.menu.flash_report'),
            title: i18n.t('general.menu.notifications'),
            route: 'flash-report',
            parent: 'notifications'
          }
        ]
      },
      {
        id: 4,
        label: i18n.t('general.menu.analyses'),
        title: i18n.t('general.menu.analyses'),
        route: 'analyses',
        hideForMobile: true,
        hideForS1User: true
      },
      {
        id: 5,
        label: i18n.t('general.menu.configurations'),
        title: i18n.t('general.menu.configurations'),
        route: 'configure-notifications'
      }
    ]
  }
