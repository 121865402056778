<template>
  <div>
    <vue-title :title="$t('general.meta_titles.password_recovery')"></vue-title>
    <Card class="padding-custom w-full m-auto mb-10" :card-classes="'mobile-card-portal '">
      <div slot="title" class="title mb-6" v-if="!passwordReset">{{ $t('auth.recovery_password.title') }}</div>
      <template v-slot:body>
        <div v-if="!passwordReset">
          <form>
            <InputText
              class="m-bottom-custom"
              v-model="password"
              id="password"
              :type-input="'password'"
              :label="$t('general.general.password')"
            />

            <div v-for="validation in validation.password" :key="validation">
              <Message :formatIsHtml="true" :message="validation" :type="'error'" class="mb-5"/>
            </div>

            <InputText
              class="m-bottom-custom"
              v-model="repeatPassword"
              id="repeatPassword"
              :type-input="'password'"
              :label="$t('general.general.repeat_password')"
              @focusout="focusOut(password, repeatPassword)"/>
            <Message
              v-if="validation.repeatPassword"
              :message="validation.repeatPassword"
              :type="'error'"
              class="mb-5"
            />
            <Message
              v-if="validation.passwordMatch"
              :message="validation.passwordMatch"
              :type="'error'"
              class="mb-5"
            />
            <Message
              v-if="validation.general"
              :message="validation.general"
              :type="'error'"
              class="mb-5"
            />
            <Button :loading="loadingData.resendEmail" @call="passwordRecovery" :w100="true" :text="$t('general.general.send')" :type="'btn-primary'" />
          </form>
        </div>
        <div v-else class="text-center">
          <div class="my-5 title">
            {{ $t('auth.recovery_password.success_message') }}
          </div>
          <div
            class="cursor-pointer color-primary"
            @click="$router.push({ name: 'login' })"
          >
            {{ $t('auth.general.login') }}
          </div>
        </div>
      </template>
    </Card>
  </div>
</template>

<script>
import Button from '../subcomponents/Button'
import Message from '../subcomponents/Message'
import { mapActions, mapGetters } from 'vuex'
import Card from '../subcomponents/Card'
import InputText from '../subcomponents/InputText'
import PasswordValidations from '../../mixins/PasswordValidations'

export default {
  name: 'PasswordRecoverySendLink',
  components: { Button, Message, Card, InputText },
  mixins: [ PasswordValidations ],
  computed: {
    ...mapGetters({
      isLogged: 'user/isLogged',
      twoFactor: 'user/twoFactor'
    })
  },
  mounted () {
    this.token = this.$route.params.token
  },
  data () {
    return {
      loadingData: {
        resendEmail: false
      },
      email: '',
      password: '',
      repeatPassword: '',
      validation: {
        repeatPassword: '',
        password: [],
        passwordMatch: '',
        general: ''
      },
      token: '',
      passwordReset: false
    }
  },
  methods: {
    ...mapActions({
      recoverPassword: 'user/recoverPassword'
    }),
    validate () {
      this.validation.password = []
      let valid = true

      if (!this.validatePassword(this.password, this.repeatPassword)) {
        valid = false
      }

      return valid
    },
    async passwordRecovery () {
      try {
        this.loadingData.resendEmail = true

        if (!this.validate()) {
          return null
        }

        let response = await this.recoverPassword({
          password: this.password,
          token: this.token
        }).catch((error) => {
          this.validation.general = error.response.data.message
          if (error.response.data.data.hasOwnProperty('reset') && error.response.data.data.reset === false) {
            this.validation.general = response.response.data.message
          }
        })

        if (response && response.data.data && response.data.data.reset) {
          this.passwordReset = response.data.data.reset
        }
      } finally {
        this.loadingData.resendEmail = false
      }
    }
  },
  watch: {
    password (nVal) {
      if (nVal) {
        this.validation.password = []
        this.validation.passwordMatch = ''
        this.validation.general = ''
      }
    }
  }
}
</script>

<style scoped></style>
