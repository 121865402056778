<template>
  <div>
    <Transition name="slide-fade">
      <table class="assets-table mt-8" v-if="openedTable">
        <thead>
        <tr>
          <th>{{ $t('deposit_withdrawal.fiscal_obligations.date') }}</th>
          <th>{{ $t('deposit_withdrawal.portfolio.symbol') }}</th>
          <th>{{ $t('deposit_withdrawal.portfolio.quantity') }}</th>
          <th>{{ $t('deposit_withdrawal.fiscal_obligations.price') + ' ' + getCurrency }}</th>
          <th>{{ $t('deposit_withdrawal.portfolio.medium_cost') + ' ' + getCurrency }}</th>
          <th>{{ $t('deposit_withdrawal.fiscal_obligations.tax') + ' ' + getCurrency }}</th>
          <th>{{ $t('deposit_withdrawal.fiscal_obligations.anaf_tax') }}</th>
        </tr>
        </thead>
        <tbody>

        <tr v-for="(asset, key) in assets" :key="key" class="row">
          <td>{{ asset.date ? formatDate(asset.date) : '-' }}</td>
          <td>
            <div class="symbol" :class="[`symbol-${colorSymbol}`]">
              {{asset.symbol ? asset.symbol : '-'}}
            </div>
          </td>
          <td>{{ asset.quantity ? formattedQuantity(asset) : '-' }}</td>
          <td>{{ asset.price ? asset.price : '-' }}</td>
          <td>{{ asset.medium_cost ? asset.medium_cost : '-' }}</td>
          <td class="flex justify-center">
            <div class="symbol-grey mr-2">{{ asset.tax_percentage ? parseFloat(asset.tax_percentage).toLocaleString('en-US', {maximumFractionDigits: 0}) + '%' : '-' }}</div>
            <div class="symbol-grey">{{ asset.tax_value ? asset.tax_value : '-' }}</div>
          </td>
          <td>{{ asset.tax_anaf ? parseFloat(asset.tax_anaf).toLocaleString('en-US', {maximumFractionDigits: 0}) : '-' }}</td>
        </tr>
        </tbody>
      </table>
    </Transition>

    <div class="total-assets cursor-pointer" :class="{ 'mt-8': !openedTable }" @click="toggleTable">
      <div class="left-side " >
        <div class="yellow-bubble-arrow">
          <img :src="require(`@/assets/img/icons/arrow-white-${openedTable ? 'up' : 'down'}.svg`)" alt="arrow">
        </div>
        <div class="total-title">
          <div class="instrument-name">{{ name }}</div>
        </div>
      </div>

      <div class="total-value mr-4">
        {{ totalTax }}
      </div>

    </div>
  </div>
</template>

<script>

export default {
  name: 'FiscalObligationsAssetsTableDesktop',
  props: {
    colorSymbol: {
      type: String,
      default: 'green'
    },
    assets: {
      type: Array,
      default: () => {
        return []
      }
    },
    expanded: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      openedTable: false,
      loading: true,
      tabsClasses: ['mb-4']
    }
  },
  watch: {
    expanded () {
      this.openedTable = this.expanded
    }
  },
  computed: {
    getCurrency () {
      return this.assets[0].currency
    },
    getDate () {
      return this.formatDate(this.assets[0].date)
    },
    totalTax () {
      let total = 0
      this.assets.forEach(asset => {
        total += parseFloat(asset.tax_anaf)
      })

      return parseFloat(total).toLocaleString('en-US', {maximumFractionDigits: 0})
    }
  },
  methods: {
    toggleTable () {
      this.openedTable = !this.openedTable
    },
    formatDate (oldDate) {
      let date = new Date(oldDate)
      return `${('0' + (date.getDate())).slice(-2)}/${('0' + (date.getMonth() + 1)).slice(-2)}/${date.getFullYear()}`
    },
    formattedQuantity (asset) {
      return parseFloat(asset.quantity).toLocaleString(
        'en-US',
        {maximumFractionDigits: this.getCurrency === 'USD' ? 3 : 0}
      )
    }
  }
}

</script>
