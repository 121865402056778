<template>
  <div>
    <general-layout>
      <template v-slot:title>
        <vue-title :title="$t('general.meta_titles.profile_documents')"/>
      </template>

      <template v-slot:tabs>
        <Tabs
          :show-id-title="false"
          :tabs-classes="tabsClasses"
        />
      </template>

      <template v-slot:body>
        <div v-if="!hasAccessInPortal">
          <div class="desktop-flex  mt-8 gap-8">
            <ContinueToOnboarding/>

            <div v-if="!isMobile">
              <Card :card-classes="'w-full h-full' + (isMobile ? ' mb-8' : '')">
                <template v-slot:body>
                  <div>
                    <p v-html="$t('guides.account_and_contact_data.account_p2')"/>
                    <br>
                    <p v-html="$t('guides.account_and_contact_data.account_p1')"/>
                  </div>
                </template>
              </Card>
            </div>
          </div>
        </div>

        <div class="mt-8" v-else>
          <Breadcrumb
            v-if="!isMobile"
            :items="[
              {
                label: $t('documents.breadcrumb.document')
              },
              {
                label: $t('documents.breadcrumb.pj_documents'),
                routeName: 'pj-documents'
              }
            ]"
          />

          <div class="desktop-flex gap-8 mt-12">
            <div class="w-full">
              <Card :card-classes="'margin-bottom-medium mobile-border-none mb-4'">
                <template v-slot:body>
                  {{ $t('documents.legal_person.pj_documents_info')}}
                </template>
              </Card>
            </div>
            <div class="w-full">
              <Accordion
                :card-classes="'mobile-card-portal'"
                class="mb-4"
                icon="transfer-portfolio"
                icon-color="green"
                :icon-position="['left']"
                :key="'to'"
              >
                <template v-slot:title>{{ $t('documents.legal_person.company_identification_documents') }}</template>

                <template v-slot:body>
                  <div>
                    <div class="mb-4"> {{ $t('documents.legal_person.documents_from_legal_person') }}:</div>
                    <p class="mb-4" v-for="(document, key) in documentsFromLegalPerson" :key="key"
                       v-html="'- ' + document"/>
                  </div>
                  <div>
                    <UploadDocuments
                      :selected-document-type="documentType"
                      :component="'pj_documents'"
                      :selected-document-category="documentCategory"
                      :all-document-categories="documentCategories"
                      :upload-multiple-files="true"
                      @documentUploaded="handleFileUpload($event, 'documentsFromLegalPerson')"
                      @documentsUploaded="handleFileUpload($event, 'documentsFromLegalPerson')"
                    />

                    <div
                      v-for="(document, index) in documentsUploadedLegalPerson"
                      :key="index"
                      v-if="document.name"
                      class="flex flex-col"
                    >
                      <div class="flex items-center mt-4">
                        {{ document.name }}

                        <div @click="deleteFile(index, 'documentsFromLegalPerson')" class="ml-4 cursor-pointer">
                          <img src="@/assets/img/icons/close.svg" alt="close">
                        </div>
                      </div>

                      <Message
                        v-if="validation.documentsFromLegalPerson['sizeDocument'][index]"
                        :message="validation.documentsFromLegalPerson['sizeDocument'][index]"
                        :type="'error'"
                      />

                      <Message
                        v-if="validation.documentsFromLegalPerson['typeDocument'][index]"
                        :message="validation.documentsFromLegalPerson['typeDocument'][index]"
                        :type="'error'"
                      />
                    </div>

                    <Button
                      :class="{ 'mt-4': true, 'disabled disabled-transparent': !documentsFromLegalPerson.length, 'mb-6': isMobile }"
                      :text="$t('documents.general.send_documents')"
                      :type="'btn-primary'"
                      @call="sendDocuments('documentsFromLegalPerson')"
                    />

                    <Message
                      v-if="uploadSuccessfulLegalPerson"
                      :message="$t('documents.general.pj_upload_successful')"
                      :type="'success'"
                      class="mb-5"
                    />
                  </div>
                </template>
              </Accordion>

              <Accordion :card-classes="'mobile-card-portal'" icon="transfer-portfolio" icon-color="green"
                         :icon-position="['left']" :key="'from'">
                <template v-slot:title>
                  {{ $t('documents.legal_person.contractual_documents') }}
                </template>

                <template v-slot:body>
                  <div>
                    <div class="mb-4"> {{ $t('documents.legal_person.documents_from_investimental') }}:</div>
                    <div class="color-blue mb-4 cursor-pointer"
                       v-for="(document, key) in documentsFromInvestimental" :key="key" >
                      <a :href="document.link" target="_blank">
                        {{ document.label }}
                      </a>
                    </div>
                    <div class="mt-6"> {{ $t('documents.legal_person.signed_documents') }}</div>
                  </div>
                  <div>
                    <UploadDocuments
                      :selected-document-type="documentType"
                      :component="'pj_documents'"
                      :selected-document-category="documentCategory"
                      :all-document-categories="documentCategories"
                      :upload-multiple-files="true"
                      @documentUploaded="handleFileUpload($event, 'documentsFromInvestimental')"
                      @documentsUploaded="handleFileUpload($event, 'documentsFromInvestimental')"
                    />

                    <div
                      v-for="(document, index) in documentsUploadedInvestimental"
                      :key="index"
                      v-if="document.name"
                      class="flex flex-col"
                    >
                      <div class="flex items-center mt-4">
                        {{ document.name }}

                        <div @click="deleteFile(index, 'documentsFromInvestimental')" class="ml-4 cursor-pointer">
                          <img src="@/assets/img/icons/close.svg" alt="close">
                        </div>
                      </div>

                      <Message
                        v-if="validation.documentsFromInvestimental['sizeDocument'][index]"
                        :message="validation.documentsFromInvestimental['sizeDocument'][index]"
                        :type="'error'"
                      />

                      <Message
                        v-if="validation.documentsFromInvestimental['typeDocument'][index]"
                        :message="validation.documentsFromInvestimental['typeDocument'][index]"
                        :type="'error'"
                      />
                    </div>

                    <Button
                      :class="{ 'mt-4': true, 'disabled disabled-transparent': !documentsFromInvestimental.length, 'mb-6': isMobile }"
                      :text="$t('documents.general.send_documents')"
                      :type="'btn-primary'"
                      @call="sendDocuments('documentsFromInvestimental')"
                    />

                    <Message
                      v-if="uploadSuccessfulInvestimental"
                      :message="$t('documents.general.pj_upload_successful')"
                      :type="'success'"
                      class="mb-5"
                    />
                  </div>

                </template>
              </Accordion>
            </div>
          </div>
        </div>
      </template>
    </general-layout>
  </div>
</template>

<script>
import GeneralLayout from '../../layout/GeneralLayout'
import Tabs from '../../layout/Tabs'
import Button from '../../subcomponents/Button'
import {mapGetters} from 'vuex'
import Card from '../../subcomponents/Card'
import IconBox from '../../subcomponents/IconBox.vue'
import ContinueToOnboarding from '../ContinueToOnboarding'

import DocumentService from '../../../services/documents/documentsService'
import Resources from '../../subcomponents/Resources.vue'
import UploadDocuments from '../../subcomponents/UploadDocuments.vue'
import Breadcrumb from '../../subcomponents/Breadcrumb.vue'
import DocumentCard from './DocumentCard.vue'
import ApiService from '@/services/apiService'
import Message from '@/components/subcomponents/Message.vue'
import mime from 'mime-types'
import Accordion from '@/components/subcomponents/Accordion.vue'

export default {
  name: 'LegalPersonDocuments',
  components: {
    Accordion,
    Message,
    DocumentCard,
    Breadcrumb,
    UploadDocuments,
    Resources,
    GeneralLayout,
    Tabs,
    Button,
    Card,
    IconBox,
    ContinueToOnboarding
  },
  data () {
    return {
      loading: true,
      uploadSuccessfulLegalPerson: false,
      uploadSuccessfulInvestimental: false,
      addNewIban: false,
      newIban: null,
      newIbanDocument: null,
      documentsUploadedLegalPerson: [],
      documentsUploadedInvestimental: [],
      tabsClasses: [],
      resourcesLinks: [
        {
          label: this.$t('useful_links.profile_documents.category'),
          value: '/ghid/ghid-incadrare-in-categoria-client-profesional-la-cerere/'
        },
        {
          label: this.$t('useful_links.profile_documents.professional'),
          value: '/wp-content/uploads/2023/07/2023-03-Cerere-incadrare-categoria-client-profesional.pdf'
        }
      ],
      documentsFromLegalPerson: [
        this.$i18n.t('documents.legal_person.cui'),
        this.$i18n.t('documents.legal_person.society_act'),
        this.$i18n.t('documents.legal_person.beneficiary_declaration'),
        this.$i18n.t('documents.legal_person.identity_documents'),
        this.$i18n.t('documents.legal_person.multiple_admins'),
        this.$i18n.t('documents.legal_person.bank_account_document'),
        this.$i18n.t('documents.legal_person.lei_code')
      ],
      documentsFromInvestimental: [
        {
          label: this.$i18n.t('documents.legal_person.attachment_1'),
          link: 'https://www.investimental.ro/wp-content/Documente-PJ/Anexa-1-Date-si-informatii-persoane-juridice.pdf'
        },
        {
          label: this.$i18n.t('documents.legal_person.attachment_2'),
          link: 'https://www.investimental.ro/wp-content/Documente-PJ/Anexa-2-Declaratia-privind-rezidenta-fiscala-persoane-juridice.pdf'
        },
        {
          label: this.$i18n.t('documents.legal_person.attachment_3'),
          link: 'https://www.investimental.ro/wp-content/Documente-PJ/Anexa-3-Serviciul-tranzactionare-BVB-Spot.pdf'
        },
        {
          label: this.$i18n.t('documents.legal_person.attachment_4'),
          link: 'https://www.investimental.ro/wp-content/Documente-PJ/Anexa-4-Serviciul-tranzactionare-Investimental-Global.pdf'
        },
        {
          label: this.$i18n.t('documents.legal_person.contract'),
          link: 'https://www.investimental.ro/wp-content/Documente-PJ/Contract-servicii-de-investitii-financiare.pdf'
        },
        {
          label: this.$i18n.t('documents.legal_person.evaluation_questionnaire'),
          link: 'https://www.investimental.ro/wp-content/Documente-PJ/Chestionar-adecvare.pdf'
        }
      ],
      documentType: {
        id: 3,
        label: this.$i18n.t('documents.general.other_documents'),
        value: 'others'
      },
      documentCategories: [],
      documentCategory: 'legal-person-documents',
      validation: {
        documentsFromInvestimental: {
          sizeDocument: [],
          typeDocument: []
        },
        documentsFromLegalPerson: {
          sizeDocument: [],
          typeDocument: []
        }
      },
      allowedDocumentTypes: ['pdf', 'doc', 'docx', 'jpg', 'jpeg', 'png'],
      validationCounter: 0
    }
  },
  methods: {
    deleteFile (index, type) {
      if (type === 'documentsFromLegalPerson') {
        this.documentsUploadedLegalPerson.splice(index, 1)
      } else {
        this.documentsUploadedInvestimental.splice(index, 1)
      }
    },

    handleFileUpload (files, type) {
      if (type === 'documentsFromLegalPerson') {
        for (let i = 0; i < files.length; i++) {
          if (this.documentsUploadedLegalPerson.length < 25) {
            this.documentsUploadedLegalPerson.push(files[i])
          }
        }

        this.validation.sizeDocument = new Array(this.documentsUploadedLegalPerson.length).fill('')
        this.validation.typeDocument = new Array(this.documentsUploadedLegalPerson.length).fill('')
      } else {
        for (let i = 0; i < files.length; i++) {
          if (this.documentsUploadedInvestimental.length < 25) {
            this.documentsUploadedInvestimental.push(files[i])
          }
        }

        this.validation.sizeDocument = new Array(this.documentsUploadedInvestimental.length).fill('')
        this.validation.typeDocument = new Array(this.documentsUploadedInvestimental.length).fill('')
      }
    },

    validateDocument (index, document, type) {
      let valid = true

      if (((document.size / 1024) / 1024).toFixed(4) > 25) {
        this.validation[type]['sizeDocument'][index] = this.$t('documents.general.size_too_big')

        valid = false
      }

      if (!this.validFileType(document.type, document.name)) {
        this.validation[type]['typeDocument'][index] = this.$t('documents.general.type_assets_document')

        valid = false
      }

      return valid
    },

    validFileType (mimeType, name) {
      const mime = require('mime-types')
      var ext = name.substr(name.lastIndexOf('.') + 1)

      return !!(this.allowedDocumentTypes.includes(mime.extension(mimeType)) || this.allowedDocumentTypes.includes(ext))
    },

    async sendDocuments (type) {
      let valid = true
      let documents = type === 'documentsFromLegalPerson' ? this.documentsUploadedLegalPerson : this.documentsUploadedInvestimental

      documents.forEach((file, index) => {
        if (!this.validateDocument(index, file, type)) {
          valid = false
        }
      })

      this.validationCounter++

      if (!valid) {
        return
      }

      let response = await ApiService.withAuth().post(
        'api/user/documents/multiple-documents',
        {
          files: documents
        },
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      )
      if (response.status === 200) {
        if (type === 'documentsFromLegalPerson') {
          this.documentsUploadedLegalPerson = []
          this.uploadSuccessfulLegalPerson = true
        } else {
          this.documentsUploadedInvestimental = []
          this.uploadSuccessfulInvestimental = true
        }

        setTimeout(() => {
          this.uploadSuccessfulLegalPerson = false
          this.uploadSuccessfulInvestimental = false
        }, 10000)
      }
    }
  },
  computed: {
    ...mapGetters({
      screenDevice: 'layout/screenDevice',
      hasAccessInPortal: 'user/hasAccessInPortal',
      isLogged: 'user/isLogged'
    }),
    isMobile () {
      return this.screenDevice === this.$screenDeviceConstants.MOBILE
    }
  }
}
</script>

<style scoped></style>
