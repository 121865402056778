<template>
  <div class="portofolio-obligations portofolio-transfer">
    <general-layout>
      <template v-slot:title>
        <vue-title :title="$t('general.meta_titles.multi_account')"></vue-title>
      </template>

      <template v-slot:tabs>
        <Tabs :show-id-title="false"/>
      </template>

      <template v-slot:body>
        <div v-if="user.status !== 'approved'">
          <Card :card-classes="'margin-bottom-medium mobile-border-none mb-4'">
            <template v-slot:body>
              {{ $t('deposit_withdrawal.multi_account.not_approved') }}
            </template>
          </Card>
        </div>

        <div v-else>
          <div v-if="!newAccountIsAdded">
            <div v-if="loadedDataAccounts">
              <div v-if="Object.keys(accounts).length">
                <MultiAccountTableDesktop
                  v-if="!isMobile"
                  :accounts="accounts"
                />

                <MultiAccountTableMobile
                  v-if="isMobile"
                  :accounts="accounts"
                />

                <Button
                  v-if="Object.keys(accounts).length < 10"
                  class="m-bottom-custom"
                  :class="{ 'mt-10': Object.keys(accounts).length }"
                  :text="$t('deposit_withdrawal.multi_account.add_new_account')"
                  @call="setNewAccountIsAdded"
                  :type="'btn-primary'"
                />
              </div>

              <div v-else
                   class="gap-8" :class="[isDesktop ? 'desktop-flex items-stretch' : '', !isDesktop ? 'flex flex-col items-center' : '']">
                <div :class="[!isDesktop ? 'w-full flex flex-col items-center' : 'w-1/2']">
                  <Transition appear name="fade">
                    <Card class="personal-info w-full" :card-classes="'mobile-card-portal'">
                      <template v-slot:body>
                        <img
                          class="mx-auto mb-2.5"
                          :src="require('@/assets/img/multi_cont_empty_state.png')"
                          alt="multi-account-empty-state"
                        >
                        <Button
                          class="mb-3"
                          :extra-classes="'mx-auto'"
                          :text="$t('deposit_withdrawal.multi_account.add_new_account')"
                          @call="setNewAccountIsAdded"
                          :type="'btn-primary'"
                        />
                      </template>
                    </Card>
                  </Transition>
                </div>

                <div :class="{'w-full' : isMobile}">
                  <Card
                    v-if="!isMobile"
                    class="w-full"
                    :card-classes="'mobile-card-resources padding-small flex-layout'"
                    :no-title="true"
                  >
                    <template v-slot:title>
                      {{ $t('general.menu.multi_account') }}
                    </template>

                    <template v-slot:body>
                      <div class="mt-2">
                        <p v-html="$t('guides.multi_account.info_p1')"/>
                        <p v-html="$t('guides.multi_account.info_p2')"/>
                      </div>
                    </template>
                  </Card>

                  <div :class="{'mt-6': !isMobile}" class="w-full">
                    <Resources :links="resourcesLinks"/>
                  </div>
                </div>
              </div>
            </div>

            <pulse-loader v-else class="m-auto w-fit"/>
          </div>

          <div v-else>
            <Card>
              <template v-slot:title>
                {{ $t('deposit_withdrawal.multi_account.add_new_account') }}
              </template>

              <template v-slot:body>
                <div class="mt-4">
                  <SelectDropDown
                    v-model="selectedType"
                    :options="accountTypes"
                    :label="$t('deposit_withdrawal.multi_account.account_type')"
                    class="mb-4"
                  />

                  <Message
                    v-if="validations.selectedType"
                    :message="validations.selectedType"
                    :type="'error'"
                    class="mb-4"
                  />
                  <InputText
                    v-model="alias"
                    id="accountName"
                    :type-input="'text'"
                    :label="$t('deposit_withdrawal.multi_account.account_name')"
                  />

                  <Message
                    v-if="validations.alias"
                    :message="validations.alias"
                    :type="'error'"
                    class="mb-4"
                  />

                  <Message
                    v-if="validations.aliasLength"
                    :message="validations.aliasLength"
                    :type="'error'"
                    class="mb-4"
                  />

                  <div class="mt-4 text-xs">{{ $t('deposit_withdrawal.multi_account.alias_warning') }}</div>

                  <div class="flex mt-10">
                    <Button
                      class="mr-5"
                      @call="save()"
                      :loading="loadedDataForSave"
                      :text="$t('deposit_withdrawal.multi_account.add')"
                      :type="'btn-primary'"
                    />
                    <Button
                      :text="$t('general.general.cancel')"
                      @call=" cancelNewAccountIsAdded()"
                      :type="'btn-secondary'"
                    />
                  </div>

                  <Message
                    v-if="messageSuccess"
                    :message="messageSuccess"
                    :type="'success'"
                    class="mb-4"
                  />

                  <Message
                    v-if="validations.general"
                    :message="validations.general"
                    :type="'error'"
                    class="mb-4"
                  />
                </div>
              </template>
            </Card>
          </div>
        </div>

      </template>
    </general-layout>
  </div>
</template>

<script>
import Tabs from '@/components/layout/Tabs.vue'
import GeneralLayout from '@/components/layout/GeneralLayout.vue'
import SelectDropDown from '@/components/subcomponents/SelectDropDown.vue'
import Button from '@/components/subcomponents/Button.vue'
import Card from '@/components/subcomponents/Card.vue'
import InputText from '@/components/subcomponents/InputText.vue'
import Message from '@/components/subcomponents/Message.vue'
import ApiService from '@/services/apiService'
import MultiAccountTableDesktop from '@/components/subcomponents/multi-account/MultiAccountTableDesktop.vue'
import MultiAccountTableMobile from '@/components/subcomponents/multi-account/MultiAccountTableMobile.vue'
import {mapGetters} from 'vuex'
import ContinueToOnboarding from '@/components/profile/ContinueToOnboarding.vue'
import SelectResidenceData from '@/components/profile/settings/FiscalData/SelectResidenceData.vue'
import Resources from '@/components/subcomponents/Resources.vue'
import AccountTransfersService from '@/services/deposit-withdrawal/accountTransfersService'

export default {
  name: 'MultiAccount',
  components: {
    Resources,
    SelectResidenceData,
    ContinueToOnboarding,
    Message,
    InputText,
    Card,
    Button,
    SelectDropDown,
    GeneralLayout,
    Tabs,
    MultiAccountTableMobile,
    MultiAccountTableDesktop
  },
  data () {
    return {
      accounts: [],
      loadedDataForSave: false,
      loadedDataAccounts: false,
      newAccountIsAdded: false,
      accountTypes: ['BVB'],
      selectedType: 'BVB',
      alias: '',
      validations: {
        alias: '',
        selectedType: '',
        general: '',
        aliasLength: ''
      },
      messageSuccess: '',
      resourcesLinks: [
        { label: this.$t('useful_links.multi_account.how_to_use'), value: '/ghid/ghid-multicont' }
      ]
    }
  },
  computed: {
    ...mapGetters({
      screenDevice: 'layout/screenDevice',
      user: 'user/user'
    }),
    isDesktop () {
      return this.screenDevice === this.$screenDeviceConstants.DESKTOP
    },
    isMobile () {
      return this.screenDevice === this.$screenDeviceConstants.MOBILE
    },
    isProduction () {
      return process.env.VUE_APP_ENV === 'production'
    }
  },
  mounted () {
    this.getAccountsData()
  },
  methods: {
    async getAccountsData () {
      await AccountTransfersService.getAccounts({multi_account: true})
        .then((response) => {
          if (response.data.data) {
            this.accounts = response.data.data.filter((account) => {
              return account.is_main === 0
            })
          }
          this.loadedDataAccounts = true
        })
      this.accounts.sort((firstAcc, secondAcc) =>
        this.reverseString(firstAcc.code) > this.reverseString(secondAcc.code)
          ? 1 : this.reverseString(secondAcc.code) > this.reverseString(firstAcc.code)
            ? -1 : 0
      )
    },

    reverseString (string) {
      return string.split('').reverse().join('')
    },

    setNewAccountIsAdded () {
      this.newAccountIsAdded = true
    },

    cancelNewAccountIsAdded () {
      this.newAccountIsAdded = false
      this.alias = ''
      this.selectedType = ''
    },

    validate () {
      let valid = true

      if (!this.alias) {
        this.validations.alias = this.$t('deposit_withdrawal.multi_account.validation_alias')
        valid = false
      } else if (this.validations.aliasLength) {
        valid = false
      }

      if (!this.selectedType) {
        this.validations.selectedType = this.$t('deposit_withdrawal.multi_account.validation_account_type')
        valid = false
      }

      return valid
    },

    async save () {
      if (!this.validate()) {
        return
      }

      this.loadedDataForSave = true

      let response = await ApiService.withAuth().post('api/user/accounts', {
        alias: this.alias
      })
        .catch((error) => {
          this.validations.general = error.response.data.message

          setTimeout(() => {
            this.validations.general = ''
          }, 5000)
        })

      if (response && response.status === 200) {
        this.messageSuccess = response.data.message
      }

      setTimeout(() => {
        this.messageSuccess = ''

        this.cancelNewAccountIsAdded()
        this.getAccountsData()

        this.loadedDataForSave = false
      }, 1000)
    }
  },
  watch: {
    alias: {
      handler (alias) {
        if (alias.length) {
          this.validations.alias = ''
        }
        if (alias.length > 20) {
          this.validations.aliasLength = this.$t('deposit_withdrawal.multi_account.validation_alias_length')
        } else {
          this.validations.aliasLength = ''
        }
      },
      deep: true
    },

    selectedType: {
      handler () {
        this.validations.selectedType = ''
      },
      deep: true
    }
  }
}
</script>
