<template>
  <div>
    <div>
      <div class="custom-dimensions-of-chart">
        <PieChart
          v-if="dataSetsChart.length"
          :width="275"
          :height="275"
          :border-width="3"
          :chart-data-sets="dataSetsChart"
          :chart-data-sets-labels="datasetChartLabels"
          :chart-id="`table-${pie.id}`"
        />
      </div>
      <div :class="[ !isDesktop ? 'mt-5 mb-6' : 'mb-28 mt-8' ]">
        <div class="flex justify-between">
          <span class="pie-values-label">
            {{ $t('deposit_withdrawal.auto_invest.pie.invested_value') }}
          </span>
          <span class="pie-values">
            {{
              pie.total_value_invested ? parseFloat(pie.total_value_invested).toLocaleString('en-US', {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2
              }) : 0
            }}
            USD
          </span>
        </div>
        <div class="flex justify-between">
          <span class="pie-values-label">
            {{ $t('deposit_withdrawal.auto_invest.pie.cash_value') }}
          </span>
          <span class="pie-values">
            {{
              pie.cash ? parseFloat(pie.cash).toLocaleString('en-US', {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2
              }) : 0
            }}
            USD
          </span>
        </div>
        <div class="divider-for-values"></div>
        <div class="flex justify-between">
          <span class="pie-values-label">
            {{ $t('deposit_withdrawal.auto_invest.pie.total_value') }}
          </span>
          <span class="pie-values">
            {{
              pie.total_value ? parseFloat(pie.total_value).toLocaleString('en-US', {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2
              }) : 0
            }}
            USD
          </span>
        </div>
        <div class="flex justify-between mt-2">
          <span class="pie-values-label">
            {{ $t('deposit_withdrawal.account_transfers.amount_owed') }}
          </span>
          <span class="pie-values">
            {{
              parseFloat(taxes).toLocaleString('en-US', {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2 })
            }}
            USD
          </span>
        </div>
      </div>
      <div class="flex gap-3.5 justify-center">
        <Button
          @call="depositPie"
          :disabled="disabledButtons"
          extra-classes="px-5"
          :class="[isMobile ? 'w-1/2' : '']"
          :text="$t('deposit_withdrawal.auto_invest.deposit_pie')"
          :type="'btn-dark-background'"
        />
        <Button
          @call="withdrawPie"
          extra-classes="px-5"
          :disabled="disabledButtons || newPie"
          :class="[isMobile ? 'w-1/2' : '']"
          :text="$t('deposit_withdrawal.auto_invest.withdraw_pie')"
          :type="'btn-dark-outline'"
        />
      </div>
    </div>
    <PopUpModal
      class="h-fit"
      :modal-fit-content="true"
      :modal-opened="showDepositModal"
      :modal-title="$t('deposit_withdrawal.deposits.deposit')"
      @closeModal="closeDepositPopUp()">
      <div>

        <div class="font-bold mb-2">
          {{ $t('deposit_withdrawal.account_transfers.available_sum') }}: {{ accountBalance.toLocaleString('en-US', {maximumFractionDigits: 2, minimumFractionDigits: 2}) }} USD
        </div>

        <div class="font-bold mb-4" v-if="pie.total_value === 0">
          {{ $t('deposit_withdrawal.auto_invest.min_sum')}}: {{ minSumDeposit.toLocaleString('en-US', {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2
        }) }} USD
        </div>

        <InputText
          :id="'sumForDeposit'"
          v-model="sumForDeposit"
          :type-input="'number'"
          :max-decimals="2"
          @input="truncateSum"
          :label="$t('deposit_withdrawal.auto_invest.transfers.place_sum')"
          :sufix="'USD'"
        />
        <div v-if="validationDecimalsSum" class="error-color text-xs mt-1 pl-2">{{ validationDecimalsSum }}</div>

        <SelectDropDown
          v-model="selectedAccount"
          class="mt-3"
          :id="'accounts'"
          :label="$t('deposit_withdrawal.auto_invest.transfers.from_account')"
          :options="accounts"
          disabled
        />

        <div class="flex mt-8" :class="[isMobile ? 'flex-col-reverse mx-9' : 'gap-4 justify-center']">
          <Button
            :class="[isMobile ? 'mt-3' : 'mt-5']"
            :text="$t('deposit_withdrawal.auto_invest.transfers.cancel')"
            :type="'btn-dark-outline'"
            @call="closeDepositPopUp()"/>

          <Button
            :class="[isMobile ? '' : 'mt-5']"
            :text="$t('deposit_withdrawal.auto_invest.transfers.deposit')"
            :type="'btn-dark-background'"
            :loading="depositLoader"
            :disabled="!sumForDeposit || !Object.keys(selectedAccount).length ||
             (pie.total_value === 0 && sumForDeposit < minSumDeposit) ||
              parseFloat(sumForDeposit) > accountBalance ||
              validationDecimalsSum.length > 0"
            @call="deposit()"/>
        </div>

      </div>

    </PopUpModal>

    <PopUpModal
      class="h-fit"
      :modal-fit-content="true"
      :modal-opened="showWithdrawModal"
      :modal-title="$t('deposit_withdrawal.withdrawals.withdraw')"
      @closeModal="closeWithdrawPopUp()">
      <div>
        <div class="font-bold mb-2">
          {{ $t('deposit_withdrawal.account_transfers.available_sum') }}: {{ accountBalance }} USD
        </div>

        <div class="text-sm mb-4">
          {{ $t('deposit_withdrawal.auto_invest.transfers.withdraw_info') }}
        </div>

        <InputText
          :id="'sumForWithdraw'"
          v-model="sumForWithdraw"
          :type-input="'number'"
          :max-decimals="2"
          @input="truncateSum"
          :label="$t('deposit_withdrawal.auto_invest.transfers.place_sum')"
          :sufix="'USD'"
        />
        <div v-if="validationDecimalsSum" class="error-color text-xs mt-1 pl-2">{{ validationDecimalsSum }}</div>

        <SelectDropDown
          v-model="selectedAccount"
          class="mt-3"
          :id="'accounts'"
          :label="$t('deposit_withdrawal.auto_invest.transfers.choose_account')"
          :options="accountsWithBankAccounts"
        />

        <div class="mb-2 mt-4 font-bold" v-if="sumForWithdraw">
          {{ $t('deposit_withdrawal.account_transfers.amount_owed') }}:
          {{ parseFloat(taxes).toLocaleString('en-US', {maximumFractionDigits: 2, minimumFractionDigits: 2}) }} USD
        </div>

        <div class="mb-4 font-bold" v-if="sumForWithdraw">
          {{ $t('deposit_withdrawal.account_transfers.amount_withdrawn') }}:
          {{ parseFloat(sumForWithdraw - taxes).toLocaleString('en-US', {maximumFractionDigits: 2, minimumFractionDigits: 2}) }} USD
        </div>

        <div class="flex mt-8" :class="[isMobile ? 'flex-col-reverse mx-9' : 'gap-4 justify-center']">
          <Button
            :class="[isMobile ? 'mt-3' : 'mt-5']"
            :text="$t('deposit_withdrawal.auto_invest.transfers.cancel')"
            :type="'btn-dark-outline'"
            @call="closeWithdrawPopUp()"/>

          <Button
            :class="[isMobile ? '' : 'mt-5']"
            :text="$t('deposit_withdrawal.auto_invest.transfers.withdraw')"
            :type="'btn-dark-background'"
            :loading="withdrawLoader"
            :disabled="!sumForWithdraw || !Object.keys(selectedAccount).length ||
             parseFloat(sumForWithdraw) > accountBalance || validationDecimalsSum.length > 0 ||
              sumForWithdraw - taxes <= 0"
            @call="withdraw()"/>
        </div>

      </div>

    </PopUpModal>
  </div>
</template>

<script>
import PieChart from '@/components/subcomponents/charts/PieChart.vue'
import Button from '@/components/subcomponents/Button.vue'
import DoughnutChart from '@/components/subcomponents/charts/DoughnutChart.vue'
import PopUpModal from '@/components/subcomponents/PopUpModal.vue'
import InputText from '@/components/subcomponents/InputText.vue'
import SelectDropDown from '@/components/subcomponents/SelectDropDown.vue'
import AccountTransfersService from '@/services/deposit-withdrawal/accountTransfersService'
import BankAccountsService from '@/services/deposit-withdrawal/bankAccountsService'
import WithdrawalsService from '@/services/deposit-withdrawal/withdrawalsService'
import piesService from '@/services/deposit-withdrawal/piesService'
import {mapGetters} from 'vuex'

export default {
  name: 'PieChartData',
  components: {SelectDropDown, InputText, PopUpModal, DoughnutChart, Button, PieChart},
  props: {
    pie: {
      type: Object,
      default: () => {
      }
    },
    datasetChartLabels: {
      type: Array,
      default: () => []
    },
    disabledButtons: {
      type: Boolean,
      default: false
    },
    newPie: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      withdrawLoader: false,
      depositLoader: false,
      showDepositModal: false,
      sumForDeposit: 0,
      selectedAccount: {},
      accounts: [],
      showWithdrawModal: false,
      accountBalance: 0,
      sumForWithdraw: 0,
      accountsWithBankAccounts: [],
      dataSetsChart: [],
      taxes: 0,
      commissionPercentageEquity: null,
      validationDecimalsSum: ''
    }
  },
  async mounted () {
    await this.setAccounts()

    this.$nextTick(() => {
      if (this.pie.positions.length) {
        this.getDataSetsForArray()
      }
    })

    await this.getTaxes()
  },
  computed: {
    ...mapGetters({
      minimumValueOfOrder: 'user/minimumValueOfOrder',
      screenDevice: 'layout/screenDevice'
    }),

    isMobile () {
      return this.screenDevice === this.$screenDeviceConstants.MOBILE
    },

    isDesktop () {
      return this.screenDevice === this.$screenDeviceConstants.DESKTOP
    },

    minSumDeposit () {
      let minSum = 0

      let minPercentage = Math.min(...this.pie.positions.map(position => position.target_percentage_of_pie))

      minSum = (parseFloat(this.minimumValueOfOrder) / (minPercentage / 100)) * (1 + this.commissionPercentageEquity) + 1

      return Math.ceil(minSum * 100) / 100
    }
  },
  methods: {
    async getTaxes () {
      await piesService.getTaxesForPie(this.pie.id)
        .then(response => {
          this.taxes = response.data.data
        })
    },

    truncateSum (event) {
      if (String(event).split('.')[1]?.length > 2) {
        this.validationDecimalsSum = this.$i18n.t('deposit_withdrawal.auto_invest.pie.sum_with_two_decimals')
      } else {
        this.validationDecimalsSum = ''
      }
    },

    async depositPie () {
      this.showDepositModal = true

      piesService.getCommissionPercentageEquityPie(this.pie.id).then(response => {
        this.commissionPercentageEquity = response.data.data.commissionPercentageEquity / 100
      }).catch(error => {
        console.log(error)
      })

      await this.setSelectedAccount()
      await this.getAvailableSum(this.selectedAccount.code)
    },

    getDataSetsForArray () {
      let arrayOfCurrentPercentages = this.pie.positions.map(a => a.current_percentage_of_pie)
      let arrayOfTargetPercentages = this.pie.positions.map(a => a.target_percentage_of_pie)

      if (arrayOfCurrentPercentages.every(val => val === 0 || val === undefined)) {
        this.dataSetsChart = arrayOfTargetPercentages
      } else {
        this.dataSetsChart = arrayOfCurrentPercentages
      }
    },

    closeDepositPopUp () {
      this.showDepositModal = false
      this.sumForDeposit = ''
      this.selectedAccount = {}
      this.validationDecimalsSum = ''
      this.accountBalance = 0
    },

    withdrawPie () {
      this.showWithdrawModal = true
      this.getAvailableSum(this.pie.account_code)
    },

    closeWithdrawPopUp () {
      this.showWithdrawModal = false
      this.validationDecimalsSum = ''
      this.sumForWithdraw = null
      this.selectedAccount = null
    },

    async deposit () {
      this.depositLoader = true
      let payload = {
        fromAmount: this.sumForDeposit,
        fromAccountId: this.selectedAccount.code,
        toAccountId: this.pie.account_code
      }

      await this.sendDataTransfer(payload)

      this.showDepositModal = false
      this.depositLoader = false
      setTimeout(() => {
        this.sumForDeposit = ''
        this.selectedAccount = {}
        this.accountBalance = 0
      }, 1000)
    },

    async withdraw () {
      this.withdrawLoader = true
      let payload = {}

      if (this.selectedAccount.hasOwnProperty('iban')) {
        payload = {
          internalAccountCode: this.pie.account_code,
          iban: this.selectedAccount.iban,
          value: this.sumForWithdraw,
          fromPie: 1
        }

        await this.sendDataWithdrawal(payload)
      } else {
        payload = {
          fromAmount: this.sumForWithdraw,
          fromAccountId: this.pie.account_code,
          toAccountId: this.selectedAccount.code,
          fromPie: 1
        }

        await this.sendDataTransfer(payload)
      }

      this.showWithdrawModal = false
      this.withdrawLoader = false

      setTimeout(() => {
        this.sumForWithdraw = ''
        this.selectedAccount = {}
        this.accountBalance = 0
      }, 1000)
    },

    async setAccounts () {
      let bankAccounts = await BankAccountsService.getBankAccounts()
      let accounts = await AccountTransfersService.getAccounts()

      this.bankAccounts = bankAccounts.data.data.bankAccounts

      this.bankAccounts.forEach(account => {
        account.label = account.currency + '-' + account.iban
      })

      this.accounts = accounts.data.data
      this.accounts.forEach(account => {
        account.name = (account.source === 'arenaxt' ? 'BVB' : 'GLOBAL') + ' ' +
          account.alias + ' ' + account.currency
      })

      this.accounts.forEach(account => {
        account.label = account.name
      })

      this.accountsWithBankAccounts = this.accounts.filter(account =>
        (account.currency === 'USD' && account.is_main)
      )

      let usdBankAccounts = this.bankAccounts.filter(account => account.currency === 'USD')

      this.accountsWithBankAccounts = this.accountsWithBankAccounts.concat(usdBankAccounts)
    },

    setSelectedAccount () {
      this.selectedAccount = this.accounts.find(account => account.currency === 'USD' && account.is_main)
    },

    async sendDataTransfer (payload) {
      let message = ''

      await AccountTransfersService.sendTransfer(payload)
        .then(response => {
          message = response.data.message
        }).catch(error => {
          message = error.response.data.message
        })

      this.$emit('transferSetMessage', message)
    },

    async sendDataWithdrawal (payload) {
      let message = ''

      await WithdrawalsService.sendWithdrawal(payload)
        .then(response => {
          message = response.data.message
        }).catch(error => {
          message = error.response.data.message
        })

      this.$emit('withdrawalSetMessage', message)
    },

    async getAvailableSum (accountCode) {
      await AccountTransfersService.getAccountBalance({
        internalAccount: accountCode
      })
        .then(response => {
          this.accountBalance = Math.floor(response.data.data * 100) / 100
        })
    }
  },
  watch: {
    pie: {
      handler: async function () {
        await this.getDataSetsForArray()
      },
      deep: true
    }
  }
}
</script>
