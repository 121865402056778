export default {
  general: {
    marketing_agreement: 'I want to receive marketing communications according to the Privacy Policy',
    transactions_notifications_agreement: 'I want to be notified by email when I have made transactions',
    privacy_policy_agreement_title: 'Acord comunicări de marketing',
    privacy_policy_agreement: 'I agree to receive marketing messages according to the Privacy Policy'
  },
  notification: {
    read: 'Read',
    unread: 'Unread',
    all_read_unread: 'All (Read/Unread)',
    all_notifications: 'All (Alert/Notification)',
    search_notification: 'Search notification',
    notification: 'Notification',
    alert: 'Alert',
    request_processing: 'Your request is being processed',
    mark_all_as_read: 'Mark all as read'
  }
}
