import IdentityCardService from '../../services/personal-data/identityCardService'
import i18n from '../../i18n'
import {romanianPINValidation} from '@/helpers/pinValidation'

const getDefaultState = () => {
  return {
    identityCardInfo: {
      firstName: null,
      lastName: null,
      cnp: null,
      CI: null,
      series: null,
      identity_type: null,
      address: {},
      identity_card: null,
      releaseDate: '',
      expirationDate: '',
      nationality: '',
      type: ''
    },
    validations: {
      general: '',
      street: '',
      country: '',
      locality: '',
      expirationDate: '',
      releaseDate: '',
      series: '',
      number: '',
      zip_code: '',
      firstName: '',
      lastName: '',
      identityCardFile: '',
      expirationReleaseDates: '',
      cnp: ''
    },
    onboarding: false
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    resetState (state) {
      Object.assign(state, getDefaultState())
    },
    setIdentityCardInfo (state, identityCardInfo) {
      state.identityCardInfo = identityCardInfo
    },
    setIdentityCardFirstName (state, firstName) {
      state.identityCardInfo.firstName = firstName
    },
    setIdentityCardLastName (state, lastName) {
      state.identityCardInfo.lastName = lastName
    },
    setIdentityCardCI (state, CI) {
      state.identityCardInfo.CI = CI
    },
    setIdentityCardSeries (state, series) {
      state.identityCardInfo.series = series
    },
    setIdentityCardAddress (state, address) {
      state.identityCardInfo.address = address
    },
    setIdentityCardExpirationDate (state, expirationDate) {
      state.identityCardInfo.expirationDate = expirationDate
    },
    setGeneralValidation (state, message) {
      state.validations.general = message
    },
    setSeriesValidation (state, message) {
      state.validations.series = message
    },
    setReleaseDateValidation (state, message) {
      state.validations.releaseDate = message
    },
    setExpirationDateValidation (state, message) {
      state.validations.expirationDate = message
    },
    setExpirationReleaseDatesValidation (state, message) {
      state.validations.expirationReleaseDates = message
    },
    setStreetValidation (state, message) {
      state.validations.street = message
    },
    setZipCodeValidation (state, message) {
      state.validations.zip_code = message
    },
    setNumberValidation (state, message) {
      state.validations.number = message
    },
    setCountryValidation (state, message) {
      state.validations.country = message
    },
    setLocalityValidation (state, message) {
      state.validations.locality = message
    },
    setCnpValidation (state, message) {
      state.validations.cnp = message
    },
    setFirstNameValidation (state, message) {
      state.validations.firstName = message
    },
    setLastNameValidation (state, message) {
      state.validations.lastName = message
    },
    setIdentityCardFileValidation (state, message) {
      state.validations.identityCardFile = message
    },
    setOnboardingVariable (state, payload) {
      state.onboarding = payload
    },
    setIdentityCardCnp (state, cnp) {
      state.identityCardInfo.cnp = cnp
    }
  },
  getters: {
    getIdentityCardInfo ({identityCardInfo}) {
      return identityCardInfo
    },
    getSeriesValidation ({validations}) {
      return validations.series
    },
    getNumberValidation ({validations}) {
      return validations.number
    },
    getCnpValidation ({validations}) {
      return validations.cnp
    },
    getReleaseDateValidation ({validations}) {
      return validations.releaseDate
    },
    getExpirationDateValidation ({validations}) {
      return validations.expirationDate
    },
    getExpirationReleaseDatesValidation ({validations}) {
      return validations.expirationReleaseDates
    },
    getCountryValidation ({validations}) {
      return validations.country
    },
    getLocalityValidation ({validations}) {
      return validations.locality
    },
    getStreetValidation ({validations}) {
      return validations.street
    },
    getZipCodeValidation ({validations}) {
      return validations.zip_code
    },
    getFirstNameValidation ({validations}) {
      return validations.firstName
    },
    getLastNameValidation ({validations}) {
      return validations.lastName
    },
    getIdentityCardFileValidation ({validations}) {
      return validations.identityCardFile
    },
    getGeneralValidation ({validations}) {
      return validations.general
    }
  },
  actions: {
    resetState (ctx) {
      ctx.commit('resetState')
    },
    resetValidations (ctx) {
      ctx.commit('setSeriesValidation', '')
      ctx.commit('setExpirationDateValidation', '')
      ctx.commit('setReleaseDateValidation', '')
      ctx.commit('setExpirationReleaseDatesValidation', '')
      ctx.commit('setNumberValidation', '')
      ctx.commit('setFirstNameValidation', '')
      ctx.commit('setLastNameValidation', '')
      ctx.commit('setStreetValidation', '')
      ctx.commit('setCountryValidation', '')
      ctx.commit('setLocalityValidation', '')
    },
    setIdentityCardInfo (ctx, payload) {
      ctx.commit('setIdentityCardInfo', payload)
    },
    setIdentityCardFirstName (ctx, payload) {
      ctx.commit('setIdentityCardFirstName', payload)
    },
    setIdentityCardLastName (ctx, payload) {
      ctx.commit('setIdentityCardLastName', payload)
    },
    setIdentityCardCnp (ctx, payload) {
      ctx.commit('setIdentityCardCnp', payload)
    },
    setIdentityCardCI (ctx, payload) {
      ctx.commit('setIdentityCardCI', payload)
    },
    setIdentityCardSeries (ctx, payload) {
      ctx.commit('setIdentityCardSeries', payload)
    },
    setIdentityCardAddress (ctx, payload) {
      ctx.commit('setIdentityCardAddress', payload)
    },
    setIdentityCardExpirationDate (ctx, payload) {
      ctx.commit('setIdentityCardExpirationDate', payload)
    },
    setGeneralValidation (ctx, payload) {
      ctx.commit('setGeneralValidation', payload)
    },
    setFirstNameValidation (ctx, payload) {
      ctx.commit('setFirstNameValidation', payload)
    },
    setLastNameValidation (ctx, payload) {
      ctx.commit('setLastNameValidation', payload)
    },
    setSeriesValidation (ctx, payload) {
      ctx.commit('setSeriesValidation', payload)
    },
    setNumberValidation (ctx, payload) {
      ctx.commit('setNumberValidation', payload)
    },
    setReleaseDateValidation (ctx, payload) {
      ctx.commit('setReleaseDateValidation', payload)
    },
    setExpirationDateValidation (ctx, payload) {
      ctx.commit('setExpirationDateValidation', payload)
    },
    setExpirationReleaseDatesValidation (ctx, payload) {
      ctx.commit('setExpirationReleaseDatesValidation', payload)
    },
    setCountryValidation (ctx, payload) {
      ctx.commit('setCountryValidation', payload)
    },
    setZipCodeValidation (ctx, payload) {
      ctx.commit('setZipCodeValidation', payload)
    },
    setLocalityValidation (ctx, payload) {
      ctx.commit('setLocalityValidation', payload)
    },
    setStreetValidation (ctx, payload) {
      ctx.commit('setStreetValidation', payload)
    },
    setCnpValidation (ctx, payload) {
      ctx.commit('setCnpValidation', payload)
    },
    setIdentityCardFileValidation (ctx, payload) {
      ctx.commit('setIdentityCardFileValidation', payload)
    },
    setOnboardingVariable (ctx, payload) {
      ctx.commit('setOnboardingVariable', payload)
    },
    validate (ctx, payload) {
      let today = new Date()
      today.setHours(0, 0, 0, 0)

      this.dispatch('identityCard/resetValidations')

      if (!payload.series && payload.type !== 'passport') {
        ctx.commit('setSeriesValidation', i18n.t('general.validations.enter_series'))

        return false
      }

      if (!payload.releaseDate && !ctx.state.onboarding) {
        ctx.commit('setReleaseDateValidation', i18n.t('general.validations.release_date'))

        return false
      }

      if (!payload.address) {
        ctx.commit('setGeneralValidation', i18n.t('general.validations.address_field'))

        return false
      }

      if (!payload.address.street || !payload.address.locality || !payload.address.country || !payload.address.number) {
        ctx.commit('setGeneralValidation', i18n.t('general.validations.address_field'))

        return false
      }

      if (!payload.expirationDate) {
        ctx.commit('setExpirationDateValidation', i18n.t('general.validations.expiration_date'))

        return false
      }

      if (reverseDate(payload.expirationDate) < today) {
        ctx.commit('setExpirationDateValidation', i18n.t('general.validations.expiration_date_past'))

        return false
      }

      if (payload.releaseDate && reverseDate(payload.releaseDate) > today) {
        ctx.commit('setReleaseDateValidation', i18n.t('general.validations.release_date_future'))

        return false
      }

      if (payload.releaseDate && reverseDate(payload.releaseDate) >= reverseDate(payload.expirationDate)) {
        ctx.commit('setExpirationReleaseDatesValidation', i18n.t('general.validations.expiration_date_after_release_date'))

        return false
      }

      if (!payload.CI) {
        ctx.commit('setNumberValidation', i18n.t('general.validations.enter_number'))

        return false
      }

      if (!payload.firstName) {
        ctx.commit('setFirstNameValidation', i18n.t('general.validations.enter_first_name'))

        return false
      }

      if (!payload.lastName) {
        ctx.commit('setLastNameValidation', i18n.t('general.validations.enter_last_name'))

        return false
      }

      if (!payload.cnp) {
        ctx.commit('setCnpValidation', i18n.t('onboarding.legal_person_details.validation_pin'))

        return false
      } else if (payload.cnp.length > 13) {
        ctx.commit('setCnpValidation', i18n.t('onboarding.legal_person_details.validation_pin_length'))

        return false
      }

      return true
    },
    async saveIdCard (ctx, payload) {
      return IdentityCardService.saveNewIdCard(payload)
    }
  }
}

function reverseDate (date) {
  if (!date) {
    return ''
  }
  let tempDate = new Date(date.split('/').reverse().join('/'))

  return tempDate.setHours(0, 0, 0, 0)
}
