<template>
  <div class="notifications">
    <general-layout>
      <template v-slot:tabs>
        <Tabs :show-id-title="false" :user-info="userInfo" :general-info="generalInfo"/>
      </template>

      <template v-slot:body>
        <div class="flex gap-8">
          <ClientNotifications
            :img-name="'morning_news'"
            :hide-guide="true"
            :only-search-input="true"
            :all-read-button="true"
            type="morning"
          />

          <ClientNotifications
            :img-name="'flash_report'"
            :hide-guide="true"
            :only-search-input="true"
            :all-read-button="true"
            type="flash"
          />
        </div>
      </template>
    </general-layout>
  </div>
</template>

<script>

import GeneralLayout from '../../layout/GeneralLayout'
import Tabs from '../../layout/Tabs'
import { mapActions, mapGetters } from 'vuex'
import ClientNotifications from '@/components/profile/notifications/ClientNotifications.vue'

export default {
  name: 'AnalysesNews',
  props: {
  },
  components: {
    ClientNotifications,
    GeneralLayout,
    Tabs
  },
  computed: {
    ...mapGetters({
      unreadUserNotifications: 'user/unreadUserNotifications',
      unreadGeneralNotifications: 'user/unreadGeneralNotifications'
    }),
    userInfo () {
      return this.unreadUserNotifications
    },
    generalInfo () {
      return this.unreadGeneralNotifications
    }
  },
  created () {
    this.getUnreadNotifications()
  },
  methods: {
    ...mapActions({
      getUnreadNotifications: 'user/getUnreadNotifications'
    })
  }
}
</script>
