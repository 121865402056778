export default {
  general: {
    beta: 'BETA',
    email_address: 'Adresă de email',
    password: 'Parolă',
    repeat_password: 'Repetați parola',
    continue: 'Continuă',
    confirm: 'Confirmă',
    click_here: 'Click aici',
    delete: 'Șterge',
    send: 'Trimite',
    complete: 'Finalizare',
    upload_files: 'Încarcă fișiere',
    upload_file: 'Încarcă fișier',
    file_types: 'PDF, PNG sau JPG',
    edit: 'Editează',
    cancel: 'Anulează',
    created: 'Creat pe',
    username: 'User',
    phone_number: 'Număr de telefon',
    update: 'Actualizează',
    save: 'Salvează',
    enabled: 'Activat',
    code: 'Cod',
    disabled: 'Dezactivat',
    password_rules: 'Între 8-20 caractere, cel puțin o literă mare, cel puțin o cifră, cel puțin un simbol ( @#$%^&+.,:;!= )',
    all: 'Toate',
    back: 'Înapoi',
    reset: 'Reset',
    password_confirmation_button: 'Confirmă parola',
    display: 'Afișează',
    year: 'Anul',
    month: 'Luna',
    january: 'Ianuarie',
    february: 'Februarie',
    march: 'Martie',
    april: 'Aprilie',
    may: 'Mai',
    june: 'Iunie',
    july: 'Iulie',
    august: 'August',
    september: 'Septembrie',
    october: 'Octombrie',
    november: 'Noiembrie',
    december: 'Decembrie',
    no_options: 'Ne pare rău, nu există opțiuni.',
    others: 'Altele',
    ok: 'Ok',
    new_user_warn: 'În urma răspunsurilor date de tine în procesul de onboarding, contul tău e în curs de validare manuală. Te ținem la curent pe email și promitem că nu va dura mult.',
    new_company_warn: 'Pentru a putea accesa platforma de tranzacționare, mai e nevoie de un pas: te rugăm să încarci documentele necesare pentru finalizarea procesului de onboarding. Le găsești {openTag}AICI{closeTag}.',
    upload: 'Încarcă',
    required: '* Câmpuri obligatorii',
    next_step: 'Mai departe',
    attention: 'Atenție!',
    loose_updates: 'Modificările nesalvate vor fi anulate fără a putea fi recuperate. <span class="font-bold">De acord?</span>',
    yes_undo: 'Da, anulez',
    no_thinking: 'Nu, mă mai gândesc',
    agree: 'De acord',
    close: 'Închide',
    how_did_you_hear: 'Cum ai auzit de noi? (Opțional)'

  },
  validations: {
    password_validation: 'Vă rugăm să introduceți o parolă',
    password_validation_alpha_dash: 'Parola trebuie să conțină doar caractere alfanumerice și caractere de punctuație',
    password_characters_validation: 'Parola trebuie să conțină cel puțin o literă majusculă și o literă mică.',
    password_diacritic_validation: 'Parola nu trebuie să conțină diacritice.',
    password_number_validation: 'Parola trebuie să conțină cel puțin o cifră.',
    password_symbol_validation: 'Parola trebuie să conțină cel puțin un caracter special.</br>( @ # $ % ^ & + . , : ; ! = )',
    password_length_validation: 'Parola trebuie să fie între 8 și 20 de caractere.',
    repeat_password_validation: 'Vă rugăm să repetați parola',
    password_match: 'Parolele nu se potrivesc',
    email_validation: 'Vă rugăm să introduceți un e-mail',
    email_format_validation: 'Vă rugăm să introduceți un e-mail valid',
    iban_validation: 'IBAN invalid',
    iban_duplicate: 'Acest IBAN este asociat deja cu un cont de utilizator',
    bank_statement_validation: 'Vă rugăm încărcați un extras de cont',
    bank_statement_validation_type: 'Documentul trebuie să fie de tip PDF, DOC, DOCX, JPG, JPEG sau PNG ',
    currency_validation: 'Vă rugăm alegeți moneda',
    checkbox_validation: 'Vă rugăm să completați câmpul de mai sus',
    password_confirmation: 'Vă rugăm să confirmați parola',
    incorrect_password: 'Parola este incorectă!',
    internal_error: 'A apărut o erroare internă, te rog să ne contactezi',
    choose_answer: 'Vă rugăm selectați un răspuns!',
    code: 'Vă rugăm să scanați codul QR și să confirmați codul!',
    sms_code: 'Vă rugăm să introduceți codul primit prin SMS!',
    complete_field: 'Vă rugăm completați acest câmp!',
    address_field: 'Vă rugăm să completați adresa!',
    address_partial_error: 'Vă rugăm sa completați',
    address_fields: {
      country: 'țara',
      county: 'județul',
      locality: 'localitatea',
      street: 'strada',
      number: 'numărul',
      zip_code: 'codul poștal'
    },
    enter_address_street: 'Vă rugăm să completați strada!',
    enter_address_country: 'Vă rugăm să completați țara!',
    enter_address_locality: 'Vă rugăm să completați localitatea!',
    enter_first_name: 'Vă rugăm să completați prenumele!',
    enter_last_name: 'Vă rugăm să completați numele!',
    enter_identity_card_file: 'Vă rugăm să încărcați actul de identitate!',
    success_upload_document: 'Documentul a fost încărcat cu succes!',
    email_already_exists: 'Adresa de e-mail este deja asociată unui cont. Pentru a continua deschiderea de cont folosind această adresă de e-mail trebuie să accesați <a target="_blank" href="/login" class="underline cursor-pointer">Login</a> și să introduceți adresa de e-mail și parola setată anterior',
    documents_server_error: 'Cererea ta nu a putut fi procesată. Te rugăm să ne <a style="text-decoration: underline" taget="_blank" href="https://investimental.websenior.ro/contact/">contactezi</a>',
    release_date: 'Vă rugăm selectați data eliberării!',
    release_date_future: 'Data eliberării nu poate fi în viitor!',
    expiration_date_after_release_date: 'Data expirării trebuie să fie mai mare cu cel puțin o zi decât cea de eliberare!',
    expiration_date: 'Vă rugăm selectați data expirării!',
    expiration_date_past: 'Data expirării nu poate fi în trecut!',
    upload_id_card: 'Vă rugăm încărcați o copie a cărții de identitate!',
    enter_series: 'Vă rugăm să completați seria!',
    enter_number: 'Vă rugăm să completați numărul!',
    phone_number: 'Vă rugăm să completați numărul de telefon!',
    dial_code: 'Vă rugăm să completați prefixul!',
    enter_pin: 'Vă rugăm să completați CNP-ul!',
    error_upload_document: 'Documentul nu a putut fi încărcat! Te rugăm să reîncerci sau să ne contactezi.'
  },
  questionnaire: {
    back: 'Înapoi',
    finish: 'Finalizare',
    next: 'Următoarea',
    continue: 'Continuă',
    title: 'Completați chestionarul de înregistrare',
    intro: 'Este important să răspunzi sincer și complet la toate solicitările de informații în cadrul Chestionarului,\n' +
      'pentru a permite Investimental să evalueze corect toleranța ta la risc, capacitatea de a suporta pierderi și,\n' +
      'implicit, caracterul corespunzător/adecvat al produselor și serviciilor pe care Investimental ți le poate oferi.'
  },
  menu: {
    portal: 'Portal',
    my_account: 'Profilul meu',
    deposit: 'Depuneri',
    withdraw: 'Retrageri',
    transfers: 'Investiții',
    money: 'Bani',
    documents: 'Documente',
    security: 'Securitate',
    notifications: 'Notificări',
    messages: 'Mesaje',
    help: 'Ajutor',
    log_out: 'Deconectare',
    visit_investimental: 'Vizitează Investimental',
    dx: 'DX',
    web_terminal: 'Web Terminal',
    arena_xt: 'BVB',
    personalised_documents: 'Contractuale',
    bank_accounts: 'Codurile IBAN',
    deposits: 'Alimentări',
    withdrawals: 'Retrageri',
    password_reset: 'Resetare parolă',
    mfa_activation: 'Activare 2FA',
    settings: 'User și date de contact',
    personal_data: 'Date personale',
    fiscal_data: 'Date fiscale',
    configurations: 'Configurări',
    clients_documents: 'Client',
    instruments_transfer: 'Transfer Portofoliu',
    multi_account: 'MultiCont',
    account_transfers: 'Transfer între conturi',
    update_user_data: 'Actualizează-ți datele',
    lms: 'Mentor',
    global: 'GLOBAL',
    portofolio: 'Portofoliu',
    internal_transfers: 'Transfer între conturi',
    fiscal_debt: 'Datorii fiscale',
    report: 'Raport',
    notification_documents: 'Atașamente Notificări',
    pj_data: 'Date persoană juridică',
    pj_documents: 'Persoană juridică',
    invoices: 'Facturi',
    pies: 'PIES',
    analyses: 'Analize',
    morning_news: 'Zori de bursă',
    flash_report: 'Flash report'
  },
  footer: {
    disclaimer: 'Disclaimer',
    personal_data_policy: 'Politică Confidențialitate',
    cookie_policy: 'Politica de Cookies'
  },
  two_factor_auth_activation: {
    disabled: 'Autentificarea cu doi factori a fost dezactivată cu succes!',
    failed: 'Cod incorect!',
    no_2fa: 'Fără 2FA',
    google_authentication_2fa: '2FA prin aplicație',
    sms_authentication_2fa: '2FA prin sms',
    enabled_google: 'Autentificare cu doi factori prin aplicație activată',
    enabled_sms: 'Autentificare cu doi factori prin sms activată'
  },
  address: {
    country: 'Țara',
    county: 'Județ',
    locality_sector: 'Localitate/Sector',
    street: 'Stradă',
    street_short: 'Str.',
    number: 'Nr.',
    building: 'Bloc',
    building_short: 'Bl.',
    entrance: 'Scara',
    entrance_short: 'Sc.',
    flat: 'Apartament',
    flat_short: 'Ap.',
    floor: 'Etaj',
    floor_short: 'Et.',
    zip_code: 'Cod poștal',
    google_autocomplete_label: 'Caută după stradă și număr',
    complete_address: 'Vă rugăm să completați adresa!',
    start_typing: 'Începeți să scrieți'
  },
  continue_to_onboarding: {
    restrict_access_message: 'Pentru a accesa această zonă este necesar să finalizezi procesul de onboarding.',
    press_button_message: 'Apasă butonul de mai jos pentru a continua.',
    button_text: 'Continuă onboarding-ul'
  },
  meta_titles: {
    register: 'Înregistrare | Onboarding Investimental',
    email_verification: 'Verificare Email | Onboarding Investimental',
    domicile: 'Domiciliu | Onboarding Investimental',
    verify_identity: 'Verificare Identitate | Onboarding Investimental',
    review_ocr_data: 'Verificare Date | Onboarding Investimental',
    verify_bank_account: 'Cont Bancar | Onboarding Investimental',
    select_residence: 'Rezidența | Onboarding Investimental',
    questionnaire: 'Chestionar | Onboarding Investimental',
    documents: 'Documente | Onboarding Investimental',
    onboarding_info: 'Informații | Onboarding Investimental',
    personal_data: 'Date Personale | Portal Investimental',
    settings: 'User și date de contact | Portal Investimental',
    fiscal_data: 'Date Fiscale | Portal Investimental',
    fiscal_debt: 'Datorii Fiscale | Portal Investimental',
    bank_accounts: 'Codurile IBAN | Portal Investimental',
    deposits: 'Alimentări | Portal Investimental',
    withdrawals: 'Retrageri | Portal Investimental',
    instruments_transfer: 'Transfer Portofoliu | Portal Investimental',
    multi_account: 'MultiCont | Portal Investimental',
    account_transfers: 'Transferuri între conturi | Portal Investimental',
    profile_documents: 'Documente contractuale | Portal Investimental',
    client_documents: 'Documente client | Portal Investimental',
    password_reset: 'Securitate | Portal Investimental',
    two_factor_authentication_activation: 'Activare 2FA | Portal Investimental',
    notifications: 'Notificări | Portal Investimental',
    messages: 'Mesaje | Portal Investimental',
    configure_notifications: 'Configurări | Portal Investimental',
    login: 'Autentificare | Portal Investimental',
    help: 'Ajutor | Investimental Portal',
    portofolio: 'Portofoliu | Investimental Portal',
    report: 'Raport | Investimental Portal',
    password_recovery: 'Recuperare parolă | Investimental Portal',
    legal_person_details: 'Detalii Persoană Juridică | Investimental Onboarding',
    phone_validation: 'Validare telefon | Investimental Onboarding',
    legal_person_data: 'Date Persoană Juridică | Investimental Portal',
    pies: 'PIES | Investimental Portal',
    force_reset_password: 'Resetează parola | Investimental Portal'
  },
  forms: {
    calendar: {
      placeholder: 'zz/ll/aaaa'
    }
  },
  days_of_week: {
    monday: 'Luni',
    tuesday: 'Marți',
    wednesday: 'Miercuri',
    thursday: 'Joi',
    friday: 'Vineri',
    saturday: 'Sâmbătă',
    sunday: 'Duminică'
  },
  months_of_year: {
    january: 'Ianuarie',
    february: 'Februarie',
    march: 'Martie',
    april: 'Aprilie',
    may: 'Mai',
    june: 'Iunie',
    july: 'Iulie',
    august: 'August',
    september: 'Septembrie',
    october: 'Octombrie',
    november: 'Noiembrie',
    december: 'Decembrie'
  }
}
