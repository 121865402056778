<template>
  <div class="report">
    <general-layout>
      <template v-slot:title>
        <vue-title :title="$t('general.meta_titles.report')"></vue-title>
      </template>

      <template v-slot:tabs>
        <Tabs
          :show-id-title="false"
        />
      </template>

      <template v-slot:body>
        <div v-if="!hasAccessInPortal" class="desktop-flex gap-8">
          <ContinueToOnboarding/>
        </div>

        <div v-else class="desktop-flex">
          <PulseLoader class="w-fit m-auto p-10 justify-center flex" v-if="loading"/>

          <Card v-else :w100="true" :no-title="true" :card-classes="'mobile-card-portal report-card'">
            <div v-if="reportData.length" v-for="(transfer, index) in reportData" :key="index"
                 class="transfer-report-container"
                 :class="[ transfer.identifier ]">
              <div class="transfer-report">
                <div class="left">
                  <img :src="getIcon(transfer)" alt="transfer icon" class="transfer-identifier-icon">

                  <div class="transfer-info">
                    <div :class="{ 'transfer-layout': transfer.identifier === 'transfer' || transfer.identifier === 'voucher'}">
                      <div class="from-account">
                        {{ getTransferInfo(transfer, 'from') }}
                      </div>

                      <img
                        class="mx-2"
                        v-if="transfer.identifier === 'transfer' || transfer.identifier === 'voucher'"
                        src="@/assets/img/icons/arrow_right.svg"
                        alt="arrow right"
                      >

                      <div class="to-account">
                        {{ getTransferInfo(transfer, 'to') }}
                      </div>
                    </div>

                    <div class="secondary-info">
                      {{ getTransferSecondaryInfo(transfer) }}
                    </div>

                    <div
                      v-if="transfer.exchange_rate !== null && transfer.exchange_rate !== ''"
                      class="secondary-info second-line">
                      {{ exchangeRate(transfer) }}
                    </div>
                  </div>
                </div>

                <div class="report-details">
                  <div class="amount">
                    <div v-if="transfer.from_amount !== null" class="from-amount flex justify-end">
                      {{ getTransferSecondaryAmount(transfer) }}
                    </div>
                    <div class="flex justify-end">
                      {{ getTransferAmount(transfer) }}
                    </div>
                  </div>

                  <div class="date">
                    <div>
                      {{ formatDate(transfer.created_at) }}
                    </div>
                  </div>

                  <div v-if="hasPendingStatus(transfer)" class="date pending">
                    {{ $t('deposit_withdrawal.report.pending') }}
                  </div>

                  <div v-if="hasRejectedStatus(transfer)" class="date cancelled">
                    {{ $t('deposit_withdrawal.report.cancelled') }}
                  </div>
                </div>
              </div>
            </div>

            <div v-if="nextPage" class="show-more" @click="showMore">
              {{ $t('deposit_withdrawal.report.show_more') }}
            </div>

            <div v-if="!reportData.length" class="no-reports-message">
              {{ $t('deposit_withdrawal.report.no_reports') }}
            </div>
          </Card>
        </div>
      </template>
    </general-layout>
  </div>
</template>

<script>

import GeneralLayout from '@/components/layout/GeneralLayout.vue'
import Tabs from '@/components/layout/Tabs.vue'
import Card from '@/components/subcomponents/Card.vue'
import ContinueToOnboarding from '@/components/profile/ContinueToOnboarding.vue'
import {mapGetters} from 'vuex'
import ApiService from '@/services/apiService'

export default {
  name: 'Report',
  components: {ContinueToOnboarding, Card, Tabs, GeneralLayout},
  data () {
    return {
      loading: true,
      showAll: false,
      reportData: [],
      nextPage: null,
      constants: {
        deposit: 'deposit',
        withdrawal: 'withdrawal',
        transfer: 'transfer',
        pending: 'pending',
        rejected: 'rejected',
        canceled: 'canceled',
        unprocessed: 'unprocessed',
        corporateEvent: 'corporate-event',
        voucher: 'voucher',
        from: 'from',
        to: 'to',
        pieTaxes: 'pie-taxes'
      }
    }
  },
  computed: {
    ...mapGetters({
      screenDevice: 'layout/screenDevice',
      hasAccessInPortal: 'user/hasAccessInPortal',
      isLogged: 'user/isLogged'
    })
  },
  mounted () {
    this.getData()
  },
  methods: {
    getData () {
      this.loading = true

      ApiService.withAuth().get('/api/user/banking-operations/history')
        .then(response => {
          this.reportData = response.data.data.data
          this.loading = false
          this.nextPage = response.data.data.next_page_url
        })
        .catch(error => {
          this.loading = false
          this.$store.dispatch('error/showError', error)
        })
    },
    showMore () {
      ApiService.withAuth().get(this.nextPage)
        .then(response => {
          this.reportData = this.reportData.concat(response.data.data.data)
          this.nextPage = response.data.data.next_page_url
        })
        .catch(error => {
          this.loading = false
          this.$store.dispatch('error/showError', error)
        })
    },
    getIcon (transfer) {
      if (transfer.status === this.constants.pending || transfer.status === this.constants.unprocessed) {
        return require(`@/assets/img/icons/clock.svg`)
      } else if (transfer.identifier === this.constants.corporateEvent) {
        return require(`@/assets/img/icons/deposit-icon.svg`)
      } else if (transfer.identifier === this.constants.pieTaxes) {
        return require(`@/assets/img/icons/withdrawal-icon.svg`)
      } else {
        return require(`@/assets/img/icons/${transfer.identifier}-icon.svg`)
      }
    },

    getAliasName (transfer, type) {
      if (transfer.identifier === this.constants.deposit && type === this.constants.from) {
        return (transfer.currency !== 'USD' ? 'BVB' : 'GLOBAL') + ' ' +
          (transfer.to_alias ?? '-') + ' ' + (transfer.currency ? transfer.currency : (transfer.to_currency ?? '-'))
      }

      if (transfer.identifier === this.constants.corporateEvent || (transfer.identifier === this.constants.voucher && type === this.constants.to)) {
        return (transfer.currency !== 'USD' ? 'BVB' : 'GLOBAL') + ' ' +
          (transfer.to_alias ?? '-') + ' ' + (transfer.currency ? transfer.currency : (transfer.to_currency ?? '-'))
      }

      if ((transfer.identifier === this.constants.withdrawal || transfer.identifier === this.constants.pieTaxes) && type === this.constants.from) {
        if (transfer.currency) {
          return (transfer.currency !== 'USD' ? 'BVB' : 'GLOBAL') + ' ' +
            transfer.from_alias + ' ' + transfer.currency
        } else {
          return (transfer.from_currency !== 'USD' ? 'BVB' : 'GLOBAL') + ' ' +
            transfer.from_alias + ' ' + transfer.from_currency
        }
      }

      if (type === this.constants.from && transfer.identifier === this.constants.voucher) {
        return this.$i18n.t('deposit_withdrawal.report.voucher')
      }

      if (transfer.identifier === this.constants.transfer) {
        if (type === this.constants.from) {
          return (transfer.from_currency !== 'USD' ? 'BVB' : 'GLOBAL') + ' ' +
            transfer.from_alias + ' ' + transfer.from_currency
        } else {
          // @TODO: DELETE AFTER NEW DESIGN FOR TAXES IS IMPLEMENTED !!!
          if (transfer.to_alias === 'IMPOZIT ANAF') {
            return 'IMPOZIT ANAF'
          }

          return (transfer.to_currency !== 'USD' ? 'BVB' : 'GLOBAL') + ' ' +
            transfer.to_alias + ' ' + transfer.to_currency
        }
      }
    },

    getTransferInfo (transfer, type) {
      if ((transfer.identifier === this.constants.corporateEvent || transfer.identifier === this.constants.deposit) &&
        type === this.constants.from) {
        return this.$i18n.t('deposit_withdrawal.report.deposit_in') + ' ' + this.getAliasName(transfer, type)
      } else if (transfer.identifier === this.constants.withdrawal && type === this.constants.from) {
        return this.$i18n.t('deposit_withdrawal.report.withdrawal_from') + ' ' + this.getAliasName(transfer, type)
      } else if (transfer.identifier === this.constants.pieTaxes && type === this.constants.from) {
        return this.$i18n.t('deposit_withdrawal.report.withdrawal_tax_from') + ' ' + this.getAliasName(transfer, type)
      } else if (transfer.identifier === this.constants.transfer || transfer.identifier === this.constants.voucher) {
        return this.getAliasName(transfer, type)
      } else {
        return ''
      }
    },

    getTransferSecondaryInfo (transfer) {
      if (transfer.identifier === this.constants.deposit) {
        let currency = (transfer.currency ? transfer.currency : transfer.from_currency)

        if (transfer.iban && transfer.iban_currency) {
          currency = transfer.iban_currency
        }

        return this.$i18n.t('deposit_withdrawal.account_transfers.from') + ' ' + (transfer.iban ?? ' ') + ' ' + currency
      } else if (transfer.identifier === this.constants.withdrawal) {
        let currency = (transfer.to_currency ? transfer.to_currency : transfer.currency)

        if (transfer.iban && transfer.iban_currency) {
          currency = transfer.iban_currency
        }

        return this.$i18n.t('deposit_withdrawal.account_transfers.to') + ' ' + (transfer.iban ?? ' ') + ' ' + currency
      } else if (transfer.identifier === this.constants.corporateEvent) {
        return this.$i18n.t('deposit_withdrawal.report.dividend') + ' ' + transfer.security_symbol
      } else {
        return ''
      }
    },

    exchangeRate (transfer) {
      let currencies = ''
      if (transfer.to_currency === 'RON') {
        currencies = transfer.from_currency + '/' + transfer.to_currency
      } else if (transfer.from_currency === 'EUR') {
        currencies = transfer.from_currency + '/' + transfer.to_currency
      } else {
        currencies = transfer.to_currency + '/' + transfer.from_currency
      }
      return this.$i18n.t('deposit_withdrawal.account_transfers.exchange_rate') + ' ' + currencies + ' = ' + transfer.exchange_rate
    },

    getTransferAmount (transfer) {
      if (transfer.identifier === this.constants.deposit) {
        let amount = '\u00A0\u00A0'
        let currency = transfer.currency ? transfer.currency : (transfer.to_currency ?? '-')

        if (transfer.to_amount !== null) {
          amount = parseFloat(transfer.to_amount).toLocaleString('en-US', { maximumFractionDigits: 2 })
        } else if (transfer.value && transfer.from_amount === null) {
          amount = parseFloat(transfer.value).toLocaleString('en-US', { maximumFractionDigits: 2 })
        }

        return '+' + amount + ' ' + currency
      } else if (transfer.identifier === this.constants.withdrawal || transfer.identifier === this.constants.pieTaxes) {
        let amount = '\u00A0\u00A0'
        let currency = transfer.to_currency

        if (transfer.to_amount !== null) {
          amount = parseFloat(transfer.to_amount).toLocaleString('en-US', { maximumFractionDigits: 2 })
        } else if (transfer.value && transfer.from_amount === null) {
          amount = parseFloat(transfer.value).toLocaleString('en-US', { maximumFractionDigits: 2 })
          currency = transfer.currency
        }

        return '-' + amount + ' ' + currency
      } else if (transfer.identifier === this.constants.transfer) {
        let amount = transfer.to_amount ? parseFloat(transfer.to_amount).toLocaleString('en-US', { maximumFractionDigits: 2 }) : '\u00A0\u00A0'
        let currency = transfer.currency ? transfer.currency : transfer.to_currency

        return '+' + amount + ' ' + currency
      } else if (transfer.identifier === this.constants.corporateEvent || transfer.identifier === this.constants.voucher) {
        let amount = transfer.value ? parseFloat(transfer.value).toLocaleString('en-US', { maximumFractionDigits: 2 }) : '\u00A0\u00A0'
        let currency = transfer.currency ? transfer.currency : transfer.to_currency

        return '+' + amount + ' ' + currency
      }
    },

    formatDate (oldDate) {
      let date = new Date(oldDate)

      return `${('0' + (date.getDate())).slice(-2)}.${('0' + (date.getMonth() + 1)).slice(-2)}.${date.getFullYear()}`
    },
    getTransferSecondaryAmount (transfer) {
      return (transfer.identifier !== this.constants.deposit ? '-' : '+') +
        parseFloat(transfer['from_amount']).toLocaleString('en-US', {maximumFractionDigits: 2}) +
        ' ' + (transfer['from_currency'])
    },
    internalAccount (currency) {
      return currency === 'USD' ? 'GLOBAL USD' : 'BVB ' + currency
    },
    hasRejectedStatus (transfer) {
      return transfer.status === this.constants.rejected || transfer.status === this.constants.canceled
    },
    hasPendingStatus (transfer) {
      return transfer.status === this.constants.pending || transfer.status === this.constants.unprocessed
    }
  }
}
</script>

<style scoped>

</style>
