import ApiService from '../apiService'

class QuestionnaireService {
  getQuestions (level = null) {
    return ApiService.withAuth().get('/api/questionnaire', { params: {level} })
  }
  getQuestionnaire (payload) {
    return ApiService.withAuth().get('/api/user/questionnaire', { params: payload })
  }
  saveQuestionnaire (payload) {
    return ApiService.withAuth().post('/api/user/questionnaire', {
      answers: payload.answers,
      isFinish: payload.isFinish,
      redoTest: payload.redoTest
    })
  }
  resetStep (payload) {
    return ApiService.withAuth().put('/api/user', payload)
  }
}

export default new QuestionnaireService()
