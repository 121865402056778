<template>
  <div>
    <div class="flex relative items-center justify-between">
      <div class="mr-5">
        {{ question.label }}
      </div>
      <div class="flex items-center">
        <div v-if="question.explanation" class="w-4">
          <div class="tooltip-wrapper">
            <img alt="my-account" src="~@/assets/img/icons/info.svg">
            <div class="tooltip" v-html="question.explanation"/>
          </div>
        </div>
        <img
          v-if="editableQuestion"
          class="mb-2 ml-3 h-fit cursor-pointer w-6 max-w-none"
          alt="edit"
          src="~@/assets/img/icons/edit-icon.svg"
          @click="toggleDisabledQuestion">
      </div>
    </div>

    <div :class="{'pointer-events-none disabled disabled-transparent': disabledQuestion}">
      <div class="flex mt-4">
        <SelectDropDown
          v-model="selectedAnswer"
          :disabled="disabled"
          :options="question.answers"
          class="mb-4 w-full"
        />
      </div>
      <div v-if="selectedAnswer && selectedAnswer.requiredDetails && selectedAnswer.details">
        <div class="mb-2">
          {{ $t('onboarding.legal_person_details.last_name') }}
        </div>
        <Details
           :disabled="disabled"
           :details="selectedAnswer.details"
           :pre-selected-answer-details="selectedDetailsAnswers"
           :details-error="detailsError"
           @setDetailsAnswers="setDetailsAnswers"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Checkbox from '../../subcomponents/Checkbox'
import Details from '../Details'
import SelectDropDown from '../../subcomponents/SelectDropDown'

export default {
  name: 'SelectorQuestion',
  components: {Details, Checkbox, SelectDropDown},
  props: {
    parent: {
      type: String,
      default: 'onboarding'
    },
    question: {
      type: Object,
      required: true
    },
    preSelectedAnswer: {
      type: Object,
      default: () => {
        return {}
      }
    },
    questionsLength: {
      type: Number,
      default: 0
    },
    detailsError: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      value: null,
      selectedAnswer: '',
      detailsAnswers: {},
      disabledQuestion: true
    }
  },
  computed: {
    selectedDetailsAnswers () {
      let answerIdentifier = Object.keys(this.preSelectedAnswer)[0]

      if (typeof this.detailsAnswers[answerIdentifier] === 'undefined') {
        return {}
      }

      return this.detailsAnswers[answerIdentifier]
    },
    editableQuestion () {
      return this.parent === 'update-data' && this.question.identifier.substring(1) < this.questionsLength - 1
    }
  },
  beforeMount () {
    if (Object.keys(this.preSelectedAnswer).length === 0) {
      this.$emit('setAnswers', this.question.identifier, {})

      return
    }

    let answerIdentifier = Object.keys(this.preSelectedAnswer)[0]

    this.selectedAnswer = this.question.answers.filter((answer) => answer.identifier === answerIdentifier)[0]

    this.$emit('setAnswers', this.question.identifier, this.preSelectedAnswer[answerIdentifier])

    if (!this.preSelectedAnswer[answerIdentifier] && Object.keys(this.preSelectedAnswer[answerIdentifier]).length === 0) {
      return
    }

    this.$set(this.detailsAnswers, answerIdentifier, this.preSelectedAnswer[answerIdentifier])
  },

  mounted () {
    this.disabledQuestion = this.parent === 'update-data' && this.question.identifier.substring(1) < this.questionsLength - 1
  },

  methods: {
    setAnswer (answer) {
      this.selectedAnswer = answer
      this.$emit('setAnswers', this.question.identifier, this.buildAnswer())
    },
    setDetailsAnswers (detailsAnswers) {
      let selectedAnswerIdentifier = this.selectedAnswer.identifier

      this.$set(this.detailsAnswers, selectedAnswerIdentifier, detailsAnswers)

      this.$emit('setAnswers', this.question.identifier, this.buildAnswer())
    },
    buildAnswer () {
      let finalAnswer = {}

      if (this.selectedAnswer) {
        finalAnswer[this.selectedAnswer.identifier] = this.detailsAnswers[this.selectedAnswer.identifier] ? this.detailsAnswers[this.selectedAnswer.identifier] : []
      }

      return finalAnswer
    },
    toggleDisabledQuestion () {
      this.disabledQuestion = !this.disabledQuestion
      this.$emit('toggleDisabledQuestion', this.disabledQuestion)
    }
  },
  watch: {
    selectedAnswer: {
      handler: function (answer) {
        this.setAnswer(answer)
      },
      deep: true
    },
    disabled: function () {
      this.disabledQuestion = this.disabled
    }
  }
}
</script>

<style scoped>

</style>
