// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from './router'
import {store} from './store'
import i18n from './i18n'
import VueMobileDetection from 'vue-mobile-detection'
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
import {ToggleButton} from 'vue-js-toggle-button'
import screenDeviceConstants from './constants/screenDeviceConstants'
import PulseLoader from 'vue-spinner/src/PulseLoader'
import VTooltip from 'v-tooltip'
import VueSimpleAlert from 'vue-simple-alert'
import WordpressLinks from './mixins/WordpressLinks'
import FunctionalCalendar from 'vue-functional-calendar'
import TitleComponent from './components/subcomponents/MetaTitle.vue'
import VueGtag from 'vue-gtag'

import './assets/css/tailwind.css'

import 'intro.js/introjs.css'
import shareConstants from './constants/shareConstants'
import piesConstants from '@/constants/piesConstants'
// Vue.use(VueIntro)
Vue.component('vue-title', TitleComponent)

Vue.use(FunctionalCalendar, {
  dateFormat: 'dd/mm/yyyy',
  dayNames: [
    i18n.t('settings.calendar.days.monday'),
    i18n.t('settings.calendar.days.tuesday'),
    i18n.t('settings.calendar.days.wednesday'),
    i18n.t('settings.calendar.days.thursday'),
    i18n.t('settings.calendar.days.friday'),
    i18n.t('settings.calendar.days.saturday'),
    i18n.t('settings.calendar.days.sunday')
  ],
  monthNames: [
    i18n.t('settings.calendar.months.january.long'),
    i18n.t('settings.calendar.months.february.long'),
    i18n.t('settings.calendar.months.march.long'),
    i18n.t('settings.calendar.months.april.long'),
    i18n.t('settings.calendar.months.may.long'),
    i18n.t('settings.calendar.months.june.long'),
    i18n.t('settings.calendar.months.july.long'),
    i18n.t('settings.calendar.months.august.long'),
    i18n.t('settings.calendar.months.september.long'),
    i18n.t('settings.calendar.months.october.long'),
    i18n.t('settings.calendar.months.november.long'),
    i18n.t('settings.calendar.months.december.long')
  ],
  shortMonthNames: [
    i18n.t('settings.calendar.months.january.short'),
    i18n.t('settings.calendar.months.february.short'),
    i18n.t('settings.calendar.months.march.short'),
    i18n.t('settings.calendar.months.april.short'),
    i18n.t('settings.calendar.months.may.short'),
    i18n.t('settings.calendar.months.june.short'),
    i18n.t('settings.calendar.months.july.short'),
    i18n.t('settings.calendar.months.august.short'),
    i18n.t('settings.calendar.months.september.short'),
    i18n.t('settings.calendar.months.october.short'),
    i18n.t('settings.calendar.months.november.short'),
    i18n.t('settings.calendar.months.december.short')
  ]
})

// Vue gtag

Vue.use(VueGtag, {
  config: {
    id: process.env.VUE_APP_GA_ID,
    params: {
      send_page_view: false
    }
  }
}, router)

// Vue components
Vue.component('ToggleButton', ToggleButton)
Vue.component('v-select', vSelect)
Vue.component('PulseLoader', PulseLoader)

// Vue configuration
Vue.prototype.$screenDeviceConstants = screenDeviceConstants
Vue.prototype.$shareConstants = shareConstants
Vue.prototype.$piesConstants = piesConstants
Vue.config.productionTip = false

Vue.use(VueMobileDetection)
Vue.use(VueSimpleAlert)
Vue.use(VTooltip, {
  defaultTrigger: window.innerWidth > 768 ? 'hover focus click' : 'click'
})

// Vue mixins
Vue.mixin(WordpressLinks)

VTooltip.options.defaultTemplate = '<div class="tooltip-vue" role="tooltip"><div class="tooltip-vue-arrow">' +
  '</div><div class="tooltip-vue-inner"></div></div>'
VTooltip.options.defaultArrowSelector = '.tooltip-vue-arrow, .tooltip-vue__arrow'
VTooltip.options.defaultInnerSelector = '.tooltip-vue-inner, .tooltip-vue__inner'
VTooltip.options.defaultBoundariesElement = 'window'
VTooltip.options.defaultOffset = 10
VTooltip.options.defaultPlacement = window.innerWidth < 768 ? 'auto' : 'top'

// Hide console.log on production
const originalConsoleLog = console.log
console.log = function (...args) {
  if (process.env.VUE_APP_ENV !== 'production') {
    originalConsoleLog.call(console, ...args)
  }
}

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  store,
  components: {App},
  i18n,
  template: '<App/>',
  created () {
    document.title = 'Investimental'
    window.addEventListener('resize', this.resize)
    window.domain = this.getDomain(window.location.hostname)
    store.dispatch('layout/setScreenDevice')
  },
  methods: {
    resize () {
      store.dispatch('layout/setScreenDevice')
    },
    getDomain (url, subdomain) {
      subdomain = subdomain || false

      url = url.replace(/(https?:\/\/)?(www.)?/i, '')

      if (!subdomain) {
        url = url.split('.')

        url = url.slice(url.length - 2).join('.')
      }

      if (url.indexOf('/') !== -1) {
        return url.split('/')[0]
      }

      return url
    }
  }
})
