<template>
<div>
  <PopUpModal
    class="h-fit"
    :modal-fit-content="true"
    :modal-opened="steps.showSumModal"
    :modal-title="$t('deposit_withdrawal.auto_invest.sell_pie')"
    @closeModal="closePopUp()">
    <div class="flex flex-col">
      <div class="mb-6">{{ $t('deposit_withdrawal.auto_invest.max_sum') }}: {{ maxAvailableSum }} USD</div>
      <InputText
        :id="'sumForDeposit'"
        v-model="sumForSell"
        :type-input="'number'"
        :max-decimals="2"
        :label="$t('deposit_withdrawal.auto_invest.transfers.place_sum')"
        :sufix="'USD'"
      />

      <div class="text-red-500 text-sm" v-if="messageErrorSum">{{ messageErrorSum }}</div>

      <div class="flex justify-center" :class="[isMobile ? 'flex-col-reverse mx-9 mt-9' : 'mt-20']">
        <Button
          @call="closePopUp"
          extra-classes="px-5 mt-3"
          :text="$t('general.general.cancel')"
          :type="'btn-dark-outline'"
        />

        <Button
          @call="nextStep('showInstrumentsForSale')"
          extra-classes="px-5 mt-3"
          :class="{'ml-4': !isMobile}"
          :text="$t('deposit_withdrawal.auto_invest.transfers.sell')"
          :type="'btn-dark-background'"
          :disabled="!!messageErrorSum.length || sumForSell === 0.00"
        />
      </div>
    </div>
  </PopUpModal>
  <PopUpModal
    class="h-fit sell-target-card"
    :modal-height-content="true"
    :modal-opened="steps.showInstrumentsForSale"
    :modal-title="$t('deposit_withdrawal.auto_invest.sell_pie')"
    @closeModal="closePopUp()">
    <div class="flex flex-col">
      <div class="text-sm text-center mx-auto" :class="[!isMobile ? 'my-5' : 'my-2']">{{ $t('deposit_withdrawal.auto_invest.transfers.percentages_info') }}</div>
      <div class="text-center mx-auto" :class="[!isMobile ? 'my-5' : 'mb-2']">
        {{ $t('deposit_withdrawal.auto_invest.sell.sell_sum') }}:
        {{ parseFloat(sumForSell).toLocaleString('en-US', {maximumFractionDigits: 2, minimumFractionDigits: 2}) }} USD
      </div>

      <div>
        <SellTargetsInstrumentTable
          v-if="!isMobile"
          :total="parseFloat(sumForSell)"
          :isSellAll="parseFloat(sumForSell) === parseFloat(maxAvailableSum.replaceAll(',', ''))"
          :instruments="pie.positions"
          @instrumentsInvalid="instrumentsInvalid"
          @instrumentsTotalInvalid="instrumentsTotalInvalid"
          @stockInstruments="setStocksToSell"
        />

        <SellTargetsInstrumentTableMobile
          v-if="isMobile"
          :total="parseFloat(sumForSell)"
          :isSellAll="parseFloat(sumForSell) === parseFloat(maxAvailableSum.replaceAll(',', ''))"
          :instruments="pie.positions"
          @instrumentsInvalid="instrumentsInvalid"
          @instrumentsTotalInvalid="instrumentsTotalInvalid"
          @stockInstruments="setStocksToSell"
        />
      </div>

      <div class="text-red-500 mt-5" v-if="messageErrorInstruments">{{ messageErrorInstruments }}</div>
      <div class="text-red-500 mt-5" v-if="messageErrorTotalInstruments">{{ messageErrorTotalInstruments }}</div>

      <div class="buttons-bottom">
        <Button
          @call="nextStep('showInfoCommissionModal')"
          extra-classes="px-5 mt-3"
          :text="$t('deposit_withdrawal.auto_invest.transfers.sell')"
          :type="'btn-dark-background'"
          :disabled="!!messageErrorTotalInstruments.length || !!messageErrorInstruments.length"
        />

        <Button
          @call="closePopUp"
          extra-classes="px-5 mt-3"
          :text="$t('general.general.cancel')"
          :type="'btn-dark-outline'"
        />
      </div>
    </div>
  </PopUpModal>
  <PopUpModal
    :modal-height-content="true"
    :small-padding="true"
    :bigger-card="true"
    class="h-fit sell-target-card"
    :modal-fit-content="true"
    :modal-opened="steps.showInfoCommissionModal"
    :modal-title="$t('deposit_withdrawal.auto_invest.rebalance.title')"
    @closeModal="closePopUp()">
    <div>
      <div class="my-4" :class="{'px-14' : !isMobile}" v-html="$t('deposit_withdrawal.auto_invest.sell.message_1')"/>

      <div v-if="!isMobile" class="mb-4 px-10">
        <table class="w-full">
          <thead>
          <tr>
            <th>{{ $t('deposit_withdrawal.auto_invest.symbol') }}</th>
            <th>{{ $t('deposit_withdrawal.auto_invest.side') }}</th>
            <th>{{ $t('deposit_withdrawal.auto_invest.sum') }}</th>
            <th>{{ $t('deposit_withdrawal.auto_invest.commission')}}</th>
            <th>{{ $t('deposit_withdrawal.auto_invest.value') }}</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(soldInstrument, key) in soldInstruments" :key="key" v-if="soldInstrument.soldValue > 0">
            <td class="text-center">{{ soldInstrument.position.stock_security.symbol }}</td>
            <td class="text-center">{{ $t('deposit_withdrawal.auto_invest.sell_side') }}</td>
            <td class="text-center">{{ parseFloat(soldInstrument.soldValue).toLocaleString('en-US', {maximumFractionDigits: 2, minimumFractionDigits:2}) }}</td>
            <td class="text-center">{{ parseFloat(commissionPercentageEquity * soldInstrument.soldValue).toLocaleString('en-US', {maximumFractionDigits: 2, minimumFractionDigits:2}) }}</td>
            <td class="text-center">{{ (parseFloat(soldInstrument.soldValue) + commissionPercentageEquity * soldInstrument.soldValue).toLocaleString('en-US', {maximumFractionDigits: 2, minimumFractionDigits:2}) }}</td>
          </tr>
          </tbody>
        </table>
      </div>

      <div class="assets-mobile mb-4" v-if="isMobile">
        <div class="flex bg-white gap-5 table-assets no-padding">
          <div>
            <div class="header-table flex flex-col">
              <div>{{ $t('deposit_withdrawal.auto_invest.symbol') }}</div>
              <div>{{ $t('deposit_withdrawal.auto_invest.side') }}</div>
              <div>{{ $t('deposit_withdrawal.auto_invest.sum') }}</div>
              <div>{{ $t('deposit_withdrawal.auto_invest.commission')}}</div>
              <div>{{ $t('deposit_withdrawal.auto_invest.value') }}</div>
            </div>
          </div>

          <div class="overflow-x-scroll flex gap-5">
            <div class="body-table flex flex-col" v-for="(soldInstrument, key) in soldInstruments" :key="key"  v-if="soldInstrument.soldValue > 0">
              <div>{{ soldInstrument.position.stock_security.symbol }}</div>
              <div>{{ $t('deposit_withdrawal.auto_invest.sell_side') }}</div>
              <div>{{ parseFloat(soldInstrument.soldValue).toLocaleString('en-US', {maximumFractionDigits: 2, minimumFractionDigits:2}) }}</div>
              <div>{{ parseFloat(commissionPercentageEquity * soldInstrument.soldValue).toLocaleString('en-US', {maximumFractionDigits: 2, minimumFractionDigits:2}) }}</div>
              <div>{{ (parseFloat(soldInstrument.soldValue) + commissionPercentageEquity * soldInstrument.soldValue).toLocaleString('en-US', {maximumFractionDigits: 2, minimumFractionDigits:2}) }}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="text-center mb-4" v-html="$t('deposit_withdrawal.auto_invest.rebalance.message_2')"/>
      <div class="text-center font-bold" v-html="$t('deposit_withdrawal.auto_invest.rebalance.message_3')"/>
      <div :class="[isMobile ? 'flex flex-col mx-9' : 'flex justify-center' ]" class="gap-3 mt-8">
        <Button
          :class="[isMobile ? 'w-full' : '' ]"
          :text="$t('general.general.no_thinking')"
          :type="'btn-dark-outline'"
          :loading="loadingSellingPie"
          @call="closePopUp()"/>
        <Button
          :class="[isMobile ? 'w-full' : '' ]"
          :text="$t('general.general.agree')"
          :type="'btn-dark-background'"
          :loading="loadingSellingPie"
          @call="lastStep()"/>
      </div>
      <div class="flex text-sm color-blue-gray" :class="[isMobile ? 'flex-col mt-3 mx-auto w-fit' : 'mt-5 justify-center']">
        <div class="mb-1.5 w-fit" :class="{'mr-8' : !isMobile}">{{ $t('deposit_withdrawal.auto_invest.rebalance.account_code') }}: {{ pie.account_code }} </div>
        <div class="flex w-fit">
          <div class="mr-3 font-semibold">{{dateRebalance}}</div>
          <div class="font-semibold">{{hoursRebalance}}</div>
        </div>
      </div>
    </div>
  </PopUpModal>
</div>
</template>

<script>
import PopUpModal from '@/components/subcomponents/PopUpModal.vue'
import Button from '@/components/subcomponents/Button.vue'
import InputText from '@/components/subcomponents/InputText.vue'
import SellTargetsInstrumentTable from '@/components/subcomponents/auto-invest/SellTargetsInstrumentTable.vue'
import piesService from '@/services/deposit-withdrawal/piesService'
import SellTargetsInstrumentTableMobile from '@/components/subcomponents/auto-invest/SellTargetsInstrumentTableMobile.vue'
import {mapGetters} from 'vuex'

export default {
  name: 'SellPie',
  components: {SellTargetsInstrumentTableMobile, InputText, Button, PopUpModal, SellTargetsInstrumentTable},
  props: {
    pie: {
      type: Object,
      required: true
    },
    maxAvailableSum: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      screenDevice: 'layout/screenDevice'
    }),

    isMobile () {
      return this.screenDevice === this.$screenDeviceConstants.MOBILE
    }
  },
  data () {
    return {
      steps: {
        showSumModal: true,
        showInstrumentsForSale: false,
        showInfoCommissionModal: false
      },
      sumForSell: 0,
      messageErrorSum: '',
      messageErrorInstruments: '',
      messageErrorTotalInstruments: '',
      soldInstruments: [],
      commissionPercentageEquity: null,
      dateRebalance: '',
      hoursRebalance: '',
      loadingSellingPie: false
    }
  },
  mounted () {
    piesService.getCommissionPercentageEquityPie(this.pie.id).then(response => {
      this.commissionPercentageEquity = response.data.data.commissionPercentageEquity / 100
    }).catch(error => {
      console.log(error)
    })
  },
  watch: {
    sumForSell: {
      handler (sumForSell) {
        let maxSum = this.maxAvailableSum.replace(',', '')
        let sum = sumForSell.replace(',', '')

        if (parseFloat(sum) > parseFloat(maxSum)) {
          this.messageErrorSum = this.$t('deposit_withdrawal.auto_invest.transfers.max_sum_error')
        } else {
          this.messageErrorSum = ''
        }
      }
    }
  },
  methods: {
    todayDate () {
      const date = new Date()
      this.dateRebalance = date.getDate() + ' ' + date.toLocaleString('ro-RO', { month: 'long' }) + ' ' + date.getFullYear()
    },

    todayHours () {
      const date = new Date()
      this.hoursRebalance = date.getHours() + ':' + ('0' + (date.getMinutes())).slice(-2)
    },

    nextStep (step) {
      this.steps[step] = true

      if (step === 'showInfoCommissionModal') {
        this.todayDate()
        this.todayHours()
      }

      if (step !== 'showSumModal') {
        document.querySelector('body').classList.add('overflow-hidden')
      } else {
        document.querySelector('body').classList.remove('overflow-hidden')
      }

      Object.keys(this.steps).forEach(key => {
        if (key !== step) {
          this.steps[key] = false
        }
      })
    },
    closePopUp () {
      document.querySelector('body').classList.remove('overflow-hidden')
      this.$emit('closePopUp')
    },
    async lastStep () {
      this.loadingSellingPie = true
      const uuidv4 = require('uuid/v4')

      let batchId = uuidv4()

      let stocksToSell = this.soldInstruments.filter(instrument => instrument.soldValue > 0)

      let payload = {
        batch_id: batchId,
        stocks_to_sell: stocksToSell,
        amount: this.sumForSell
      }
      let message = ''

      await piesService.sellPie(this.pie.id, payload).then(response => {
        message = response.data.message
      }).catch(error => {
        message = error.response.data.message
        console.log(error)
      })

      this.$emit('startSellingPie', {message: message, batchId: batchId})
      this.loadingSellingPie = false
      document.querySelector('body').classList.remove('overflow-hidden')
    },
    instrumentsInvalid (instrumentIsInvalid) {
      this.messageErrorInstruments = instrumentIsInvalid ? this.$t('deposit_withdrawal.auto_invest.transfers.instruments_validation') : ''
    },

    instrumentsTotalInvalid (instrumentTotalIsInvalid) {
      let sumOfPercentages = this.soldInstruments.reduce((acc, instrument) => {
        return acc + parseFloat(instrument.sold_percentage_shown)
      }, 0)
      this.messageErrorTotalInstruments = instrumentTotalIsInvalid ? this.$t('deposit_withdrawal.auto_invest.transfers.sell_instruments_total_validation', {sumOfPercentages: sumOfPercentages.toFixed(2)}) : ''
    },

    setStocksToSell (stocksToSell) {
      this.soldInstruments = stocksToSell
    }
  }
}
</script>
