import ApiService from '../services/apiService'

export default {
  data () {
    return {
      interval: null
    }
  },
  methods: {
    async fillDocuments () {
      await this.checkIfDocumentsFilled()

      if (!this.loaded && this.validateServerError) {
        this.interval = setInterval(this.checkIfDocumentsFilled, 2000)
      }
    },
    async checkIfDocumentsFilled () {
      let response = await this.makeRequest().catch(() => {
        this.validateServerError = false
      })

      if (!this.validateServerError) {
        return
      }

      console.log(response.data.message)

      let data = response.data.data
      if (response.status === 200) {
        if (
          data.processedUsingOldVersion || (data.orderIsFulfilled &&
            !data.hasDocumentsConsent &&
            !data.orderIsReset &&
            !data.dataChanged &&
            data.documents.length)
        ) {
          this.documents = data.documents
          this.loaded = true
          console.log('100%')
          return
        }

        if (!data.orderIsFulfilled || data.orderIsReset || data.dataChanged) {
          return
        }

        let batch = data.batch
        if (typeof batch === 'undefined' || batch === null || data.hasDocumentsConsent) {
          this.validateServerError = false
        } else {
          console.log(batch.progress + '%')
        }
      }
    },
    async download (personalDocument) {
      await ApiService.withAuth().get(personalDocument.url, {responseType: 'blob'})
        .then((response) => {
          const mime = require('mime-types')

          let fileName = personalDocument.original_name

          let fileExt = fileName.substr(fileName.lastIndexOf('.') + 1)

          if (typeof mime.types[fileExt] === 'undefined') {
            fileName = `${personalDocument.original_name}.${mime.extension('application/pdf')}`
          }

          let FILE = window.URL.createObjectURL(new Blob([response.data]))

          let docUrl = document.createElement('a')
          docUrl.href = FILE
          docUrl.setAttribute('download', fileName)

          document.body.appendChild(docUrl)

          docUrl.click()
          docUrl.remove()
        })
    },
    validate () {
      let validated = true

      for (let [key, value] of Object.entries(this.agreements)) {
        if (!value) {
          this.validations[key] = false

          validated = false
        }
      }

      return validated
    }
  },
  beforeDestroy () {
    clearInterval(this.interval)
  },
  watch: {
    agreements: {
      handler (newValue) {
        for (let [key, value] of Object.entries(newValue)) {
          if (value) {
            this.validations[key] = true
          }
        }
      },
      deep: true
    },
    loaded (loaded) {
      if (loaded) {
        clearInterval(this.interval)
      }
    },
    validateServerError (validateServerError) {
      if (validateServerError === false) {
        clearInterval(this.interval)
      }
    }
  }
}
