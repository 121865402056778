<template>
  <div class="accounts-mobile" v-if="Object.keys(accounts).length">

    <Transition name="slide-fade">
      <div class="flex bg-white pt-5 pl-5 pb-8 gap-5 table-assets">
        <div class="header flex flex-col">
          <div>{{ $t('deposit_withdrawal.multi_account.table.id') }}</div>
          <div>{{ $t('deposit_withdrawal.multi_account.table.market') }}</div>
          <div>{{ $t('deposit_withdrawal.multi_account.table.alias') }}</div>
          <div>{{ $t('deposit_withdrawal.multi_account.table.currency') }}</div>
          <div>{{ $t('deposit_withdrawal.multi_account.table.account') }}</div>
        </div>

        <div class="overflow-x-scroll flex gap-5">
          <div class=" body flex flex-col" v-for="(account, key) in accounts" :key="key" >
              <div>{{ key + 1 }}</div>
              <div>{{ account.source === 'arenaxt' ? 'BVB' : 'GLOBAL'}}</div>
              <div class="whitespace-nowrap">{{ account.alias }}</div>
              <div>{{ account.currency }}</div>
              <div>{{ account.code }}</div>
          </div>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script>

export default {
  name: 'MultiAccountTableMobile',
  props: {
    accounts: {
      type: Object,
      default: () => {
        return {}
      }
    }
  }
}

</script>
