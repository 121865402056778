<template>
  <div class="portofolio-page">
    <general-layout :no-padding-top="true" :no-padding="$isMobile()">
      <template v-slot:title>
        <vue-title :title="$t('general.meta_titles.pies')"></vue-title>
      </template>

      <template v-slot:tabs>
        <Tabs
          :show-id-title="false"
          :is-show-pie="true"
        />
      </template>

      <template v-slot:body>
        <div class="autoinvest-pies">
          <ShowPieDesktop
            v-if="isDesktop"
            :existing-new-pie="existingNewPie"
            @editPie="editPie"
            @cancelUpdates="cancelUpdates"
            @goBackToPies="goBackToPies()"
            @savedPie="savedPie"
            @resetExistingNewPie="existingNewPie = false"
          />
          <ShowPieMobile
            v-if="!isDesktop && !savingPie"
            :existing-new-pie="existingNewPie"
            @editPie="editPie"
            @cancelUpdates="cancelUpdates"
            @goBackToPies="goBackToPies()"
            @savedPie="savedPie"
            @resetExistingNewPie="existingNewPie = false"
          />
        </div>
        <div v-if="savingPie" class="autoinvest-pies">
          <SavePie
            :step="step"
            @lastStep="lastStep"
            @setMobileStep="step = $event"
            @clearData="clearData"
            @closeModal="closePieModal"
          />
        </div>
      </template>
    </general-layout>
  </div>

</template>

<script>
import ShowPieDesktop from '@/components/profile/deposit-withdrawal/auto-invest/ShowPieDesktop.vue'
import ShowPieMobile from '@/components/profile/deposit-withdrawal/auto-invest/ShowPieMobile.vue'
import SavePie from '@/components/profile/deposit-withdrawal/auto-invest/SavePie.vue'
import {mapActions, mapGetters} from 'vuex'
import GeneralLayout from '@/components/layout/GeneralLayout.vue'
import Tabs from '@/components/layout/Tabs.vue'
import Resources from '@/components/subcomponents/Resources.vue'
import Card from '@/components/subcomponents/Card.vue'
import AutoInvestPiesTableMobile from '@/components/subcomponents/auto-invest/AutoInvestPiesTableMobile.vue'
import AutoInvestPiesTableDesktop from '@/components/subcomponents/auto-invest/AutoInvestPiesTableDesktop.vue'
import Button from '@/components/subcomponents/Button.vue'
import {Chart} from 'chart.js'

export default {
  name: 'ShowPie',
  components: {
    Button,
    AutoInvestPiesTableDesktop,
    AutoInvestPiesTableMobile,
    Card,
    Resources,
    Tabs,
    GeneralLayout,
    SavePie,
    ShowPieMobile,
    ShowPieDesktop},
  computed: {
    ...mapGetters({
      getPieId: 'pies/pieId',
      addedStockSecurities: 'pies/addedStockSecurities',
      deletedStockSecurities: 'pies/deletedStockSecurities',
      newPie: 'pies/newPie',
      screenDevice: 'layout/screenDevice'
    }),
    isMobile () {
      return this.screenDevice === this.$screenDeviceConstants.MOBILE
    },
    isDesktop () {
      return this.screenDevice === this.$screenDeviceConstants.DESKTOP
    }
  },
  data () {
    return {
      existingNewPie: false,
      step: '',
      savingPie: false
    }
  },

  mounted () {
    this.registerNewChartPlugin()

    if (this.$route.name === 'pie' && this.$route.params.id === '-1' && Object.keys(this.newPie).length === 0) {
      this.$router.push({name: 'pies'})
    }
  },

  methods: {
    ...mapActions({
      setNewPie: 'pies/setNewPie',
      setAddedStockSecurities: 'pies/setAddedStockSecurities',
      setDeletedStockSecurities: 'pies/setDeletedStockSecurities',
      setPieId: 'pies/setPieId'
    }),
    editPie () {
      this.savingPie = true
      this.step = 'showInstrumentsForPie'
    },

    async goBackToPies () {
      if (this.getPieId) {
        await this.setPieId(null)
      } else {
        await this.setNewPie({})
      }
      this.savingPie = false
      await this.$router.push({name: 'pies'})
    },

    async savedPie () {
      this.step = ''
    },

    async cancelUpdates () {
      this.step = ''
    },

    closePieModal () {
      this.savingPie = false
      this.step = ''
    },

    clearData () {
      this.setNewPie({})

      if (this.addedStockSecurities.length || this.deletedStockSecurities.length) {
        this.setAddedStockSecurities([])
        this.setDeletedStockSecurities([])
      }
    },

    async lastStep () {
      if (this.newPie.positions.length && !this.newPie.account_alias) {
        this.existingNewPie = true
      }
    },
    registerNewChartPlugin () {
      Chart.register({
        id: 'textCenter',
        beforeDraw: function (chart, args, options) {
          const enableTextCenter = chart.options.plugins.textCenter.enabled
          if (!enableTextCenter) {
            return
          }
          const {ctx, chartArea: {top, right, bottom, left, width, height}} = chart
          ctx.save()
          ctx.font = '700 22px sans-serif'
          ctx.fillStyle = chart.config._config.options.centerTextColor || '#001C4D'
          ctx.textAlign = 'center'
          ctx.textBaseline = 'middle'
          const totalText = chart.config._config.options.centerText || 'Total\n100%'
          const textLines = totalText.split('\n')
          const firstLineY = top + height / 2 - 5
          ctx.fillText(textLines[0], left + width / 2, firstLineY)

          const secondLineY = top + height / 2 + 17
          ctx.fillText(textLines[1], (left + width / 2) + 3, secondLineY)
          ctx.restore()
        }
      })
    }
  }
}
</script>
