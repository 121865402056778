<template>
  <div class="relative" :class="{'flex justify-end': isMobile && collapsedReferralsMessage}">
    <div class="component-referrals" :class="{'collapsed': isMobile && collapsedReferralsMessage}">
      <div v-if="!collapsedReferralsMessage" class="message-container">
        <div class="message-wrapper">
          <img class="h-8 mr-2" src="@/assets/img/icons/present.svg" alt="gift">
          <span v-if="isMobile" v-html="$t('settings.referrals.recommend_link_mobile')"></span>
          <span v-else v-html="$t('settings.referrals.recommend_link')"></span>
        </div>

        <div class="flex">
          <div class="relative share-wrapper">
            <img class="share-icon" @click="generateAndCopyLink" src="@/assets/img/icons/share.svg" alt="share">

            <transition name="fade">
              <div v-if="showCopiedMessage" class="copied-succesfully">
                {{$t('settings.referrals.message_copied_succesfully')}}
              </div>
            </transition>
          </div>
          <img class="share-icon" @click="sendWhatsApp" src="@/assets/img/icons/whatsapp.svg" alt="whatsapp">
          <img class="share-icon" @click="sendEmail" src="@/assets/img/icons/email.svg" alt="email">
          <img v-if="isMobile" class="share-icon" @click="sendSMS" src="@/assets/img/icons/sms.svg" alt="sms">
        </div>
      </div>

      <div class="collapse" @click="toggleCollapse">
        <img v-if="collapsedReferralsMessage && isMobile" class="h-8 mr-2" src="@/assets/img/icons/present.svg" alt="gift">

        <img class="arrow-icon" :class="{'collapsed': collapsedReferralsMessage}" src="@/assets/img/icons/arrow-down-white.svg" alt="arrow">
      </div>
    </div>
  </div>
</template>

<script>

import {mapActions, mapGetters} from 'vuex'
import Button from './Button.vue'
import UserService from '../../services/auth/userService'

export default {
  name: 'Referrals',
  components: {Button},
  data () {
    return {
      body: null,
      subject: null,
      showCopiedMessage: false,
      operatingSystem: null,
      referralLink: null
    }
  },
  computed: {
    ...mapGetters({
      screenDevice: 'layout/screenDevice',
      collapsedReferralsMessage: 'layout/collapsedReferralsMessage'
    }),
    isMobile () {
      return this.screenDevice === this.$screenDeviceConstants.MOBILE
    },
    isDesktop () {
      return this.screenDevice === this.$screenDeviceConstants.DESKTOP
    }
  },
  async mounted () {
    await this.generateLink('link')
    this.getMobileOperatingSystem()
  },
  methods: {
    ...mapActions({
      setCollapsedReferralsMessage: 'layout/setCollapsedReferralsMessage'
    }),

    toggleCollapse () {
      this.setCollapsedReferralsMessage(!this.collapsedReferralsMessage)
    },

    async generateLink (channel) {
      await UserService.generateReferralCode(channel).then(response => {
        if (channel === 'link') {
          this.referralLink = response.data.data.referralLink
        } else {
          this.body = response.data.data.body

          if (channel === 'email') {
            this.subject = response.data.data.subject
          }
        }
      })
    },

    copiedSuccesfully () {
      this.showCopiedMessage = true

      setTimeout(() => {
        this.showCopiedMessage = false
      }, 3000)
    },

    async generateAndCopyLink () {
      await navigator.clipboard.writeText(this.referralLink)

      this.copiedSuccesfully()
    },

    async sendWhatsApp () {
      await this.generateLink('message')

      const messageText = encodeURIComponent(this.body)

      let whatsappUrl = this.$shareConstants.WHATSAPP_MOBILE + messageText

      setTimeout(() => {
        window.open(whatsappUrl, '_blank')
      }, 1)
    },

    async sendEmail () {
      await this.generateLink('email')

      const subject = encodeURIComponent(this.subject)
      const body = encodeURIComponent(this.body)
      const emailUrl = this.$shareConstants.EMAIL_SUBJECT + subject + this.$shareConstants.EMAIL_BODY + body

      setTimeout(() => {
        window.open(emailUrl, '_blank')
      }, 1)
    },

    async sendSMS () {
      await this.generateLink('message')

      const message = encodeURIComponent(this.body)

      let smsUrl = ''

      if (this.operatingSystem === 'ios') {
        smsUrl = this.$shareConstants.SMS_IOS + message
      } else {
        smsUrl = this.$shareConstants.SMS_ANDROID + message
      }

      setTimeout(() => {
        window.open(smsUrl, '_blank')
      }, 1)
    },

    getMobileOperatingSystem () {
      let userAgent = navigator.userAgent || navigator.vendor || window.opera

      // Windows Phone must come first because its UA also contains "Android"
      if (/windows phone/i.test(userAgent)) {
        this.operatingSystem = 'windows'
      }

      if (/android/i.test(userAgent)) {
        this.operatingSystem = 'android'
      }

      // iOS detection from: http://stackoverflow.com/a/9039885/177710
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        this.operatingSystem = 'ios'
      }

      return 'unknown'
    }
  }
}
</script>
