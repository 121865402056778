const obj = {
  methods: {
    getWpEndpoint () {
      return process.env.VUE_APP_WP_ENDPOINT
    },
    wpLink (path) {
      path = path.replace(/^(\/+)/, '')
      let endpoint = this.getWpEndpoint().replace(/\/+$/, '')
      return `${endpoint}/${path}`
    },
    getLMSWpLink () {
      return 'https://mentor.investimental.ro/'
    }
  }
}

export default obj

export function wpLink (path) {
  return obj.methods.wpLink(path)
}
