<template>
  <div>
    <div class="table-targets w-full" v-if="!loadingInstruments">
      <div class="thead">
        <div class="tr">
          <div class="th">{{ $t('deposit_withdrawal.auto_invest.instruments.table.instrument') }}</div>
          <div class="th">{{ $t('deposit_withdrawal.auto_invest.targets.target_weights') }}</div>
          <div class="th">{{ $t('deposit_withdrawal.auto_invest.targets.remove') }}</div>
        </div>
      </div>
      <div class="tbody">
        <div v-for="(instrument, index) in addedInstruments" :key="index">
          <div class="tr">
            <div class="td">
              <div class="flex items-center gap-3">
                <StockSecurityLogo :img="instrument.stock_security.logo" :symbol="instrument.stock_security.symbol" :color="colorBlock[index % 5]"/>
                <div class="break-all">
                  {{ instrument.stock_security.name ? instrument.stock_security.name : '-' }}
                </div>
              </div>
            </div>
            <div class="td">
              <div>
                <input
                  type="range"
                  min="1"
                  max="100"
                  :id="`range-${index}`"
                  :value="instrument.target_shown"
                  @input="changeTarget($event, index)"
                  class="slider"
                  :class="[`color-${index % 5}`]"
                />
              </div>
              <div class="special-target-input-wrapper">
                <div class="special-target-input" :class="{'error-color': total !== 100}">
                  <InputText
                    id="'pie-name'"
                    :value="instrument.target_shown"
                    @input="changeTargetInput($event, index)"
                  />
                  <div>%</div>
                </div>
              </div>
            </div>

            <div class="td">
              <img
                class="cursor-pointer"
                alt=""
                :src="require('@/assets/img/icons/circled_minus_white_bg.svg')"
                :class="{'opacity-50 pointer-events-none': instruments.length < minimumInstrumentsOfPie ||
               (instrument.current_value ? parseFloat(instrument.current_value).toFixed(2) !== '0.00' : false) }"
                @click="removeInstrument(instrument)"
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <pulse-loader v-if="loadingInstruments" />
  </div>
</template>

<script>
import StockSecurityLogo from '@/components/subcomponents/StockSecurityLogo.vue'
import InputText from '@/components/subcomponents/InputText.vue'
import {mapGetters} from 'vuex'

export default {
  name: 'TargetsInstrumentTable',
  components: {InputText, StockSecurityLogo},
  props: {
    total: {
      type: Number,
      default: 0
    },
    instruments: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapGetters({
      newPie: 'pies/newPie',
      minimumInstrumentsOfPie: 'user/minimumInstrumentsOfPie'
    })
  },
  data () {
    return {
      addedInstruments: [],
      colorBlock: [
        'light-blue',
        'dark-blue',
        'yellow',
        'primary',
        'pink'
      ],
      loadingInstruments: false
    }
  },
  methods: {
    async setPositions () {
      this.$emit('setPositions', this.addedInstruments)
    },
    removeInstrument (instrument) {
      this.$emit('removeInstrument', instrument)
    },
    changeTarget (event, index) {
      this.addedInstruments[index].target_percentage_of_pie = event.target.value
      this.addedInstruments[index].target_shown = Math.round(event.target.value * 100) / 100

      if (event.target.value < 1) {
        this.$emit('validationPercentage', this.$i18n.t('deposit_withdrawal.auto_invest.targets.validation_min_percentage'))
      } else {
        this.$emit('validationPercentage', '')
      }
    },
    changeTargetInput (event, index) {
      this.addedInstruments[index].target_percentage_of_pie = event
      this.addedInstruments[index].target_shown = Math.round(event * 100) / 100

      if (event < 1) {
        this.$emit('validationPercentage', this.$i18n.t('deposit_withdrawal.auto_invest.targets.validation_min_percentage'))
      } else {
        this.$emit('validationPercentage', '')
      }
    }
  },
  watch: {
    addedInstruments: {
      handler (addedInstruments) {
        this.setPositions(addedInstruments)
      },
      deep: true
    },
    instruments: {
      handler (instruments) {
        this.loadingInstruments = true
        this.addedInstruments = instruments
        this.loadingInstruments = false
      },
      deep: true
    }
  }
}
</script>
