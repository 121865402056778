export default {
  general: {
    useful_links: 'Useful links',
    withdrawals_guide: 'Withdrawals guide',
    install_arena_xt: 'How to install Arena XT'
  },
  instruments_transfer: {
    transfer_guide: 'Cum transfer banii între conturile mele de tranzacționare?',
    transfer_to_investimental: 'Cum transfer instrumentele financiare la Investimental',
    instruments_transfer_guide: 'Ghid transfer instrumente financiare'
  },
  withdrawals: {
    withdrawal_guide: 'Ghid retragere',
    withdrawal_how_to: 'Vreau sa retrag bani, cum fac?',
    withdrawal_add_iban: 'Vreau să adaug un nou cod IBAN, cum fac?',
    withdrawal_delete_iban: 'Vreau să șterg un cod IBAN, cum fac?',
    withdrawal_time: 'În cât timp ajung banii în contul meu bancar după ce am solicitat retragerea?'
  },
  deposits: {
    deposits_guide: 'Ghid alimentare cont',
    deposit_how_to: 'Vreau să alimentez, cum fac?',
    deposit_accounts: 'Lista conturi curente Investimental',
    deposit_what_accounts: 'În ce conturi bancare Investimental pot face alimentarea?',
    deposit_money_time_to_arrive: 'În cât timp ajung banii în contul de tranzacționare?'
  },
  bank_accounts: {
    bank_accounts_how_to_add_iban: 'Vreau să adaug un nou cod IBAN, cum fac?',
    bank_accounts_how_to_delete_iban: 'Vreau să șterg un cod IBAN, cum fac?'
  },
  account_and_contact_data: {
    account_cant_access_email: 'Nu mai am acces la adresa de email, ce pot face?',
    account_cant_access_phone: 'Nu mai am acces la numărul de telefon, ce pot face?',
    account_my_user_account: 'Care este contul meu de utilizator Investimental BVB și Global?'
  },
  personal_data: {
    personal_data_identity_proof: 'Cu ce document fac dovada identității?',
    personal_data_address_proof: 'Cu ce document fac dovada adresei?'
  },
  fiscal_data: {
    fiscal_guide_residence: 'Ghid pentru stabilirea rezidenței',
    fiscal_what_documents: 'Ce documente sunt necesare pentru dovada rezidenței?',
    tax_information: 'Tax information'
  },
  password_reset: {
    password_how_to: 'Vreau să-mi schimb parola, cum fac?',
    password_email_problem: 'Nu am primit e-mail-ul pentru resetarea parolei, ce fac?',
    password_sso: 'Ce înseamnă autentificare Single Sign-On (SSO) și la ce ma ajută?',
    password_forget: 'Am uitat parola, ce fac?'
  },
  two_factor_activation: {
    two_factor_what_is: 'Ce înseamnă autentificarea cu doi factori (2FA)?',
    two_factor_activate: 'Cum îmi activez autentificarea cu doi factori (2FA)?',
    two_factor_auth: 'Cum mă autentific cu doi factori (2FA)',
    two_factor_cant_access: 'Nu mai am acces la aplicația 2FA, cum mă pot autentifica?',
    two_factor_disable: 'Vreau să-mi dezactivez 2FA, cum fac?'
  },
  profile_documents: {
    mifid: 'MiFID sumar',
    category: 'Ghid privind încadrarea în categoria client profesional la cerere',
    profesional: 'Cerere încadrare client profesional'
  },
  multi_account: {
    how_to_use: 'How do I use multi-account?'
  },
  pies: {
    what_are_pies: 'What are PIEs?',
    how_to_use: 'How do I start investing in PIEs?',
    long_term_invest: 'Investiția pe termen lung în PIEs'
  }
}
