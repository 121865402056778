<template>
  <div class="residence-data personal-data">
    <div class="personal-info">
      <div class="info flex justify-between mb-10">
        <div :class="{'pointer-events-none disabled disabled-transparent': disabledRoFiscal}">
          <Checkbox
            :id="'roFiscal'"
            v-model="roFiscal"
            :label="$t('settings.residence_data.ro_resident')"
          />

          <div v-if="roFiscal && getUserData.identity_card" class="font-bold mt-4 ml-8">
            {{ getUserData.identity_card.personal_identification_number }}
          </div>
        </div>

        <img class="mb-3 cursor-pointer w-6 max-w-none" alt="edit" src="~@/assets/img/icons/edit-icon.svg" @click="toggleRoFiscal">
      </div>

      <div class="info flex justify-between" :class="{'mb-10' : !dataSaved}">
          <div>
            <div class="w-4/5">
              <Checkbox
                :id="'otherFiscal'"
                v-model="otherFiscal"
                :class="{'pointer-events-none disabled disabled-transparent': disabledOtherFiscal}"
                :label="$t('settings.residence_data.other_resident')"
              />
            </div>

            <template v-if="otherFiscal">
              <div
                v-for="(fiscalData, counter) in otherFiscalData"
                :key="counter"
                :class="{'pointer-events-none disabled disabled-transparent': disabledOtherFiscal}"
                class="mt-4">
                <div class="select-residence-fiscal-data">
                  <SelectDropDown
                    :id="fiscalData.country + 'id'"
                    v-model="fiscalData.country"
                    :label="$t('settings.residence_data.country')"
                    :options="countries"
                    class="mr-2"
                    :disabled="disabledOtherFiscal"
                    required
                  />

                  <InputText
                    :id="fiscalData.country + 'fiscalDataCode'"
                    v-model="fiscalData.fiscalCode"
                    :label="$t('settings.residence_data.fiscal_code')"
                    :type-input="'text'"
                    :disabled-input="disabledOtherFiscal"
                    required
                  />

                  <div
                    v-if="otherFiscalData.length > 1"
                    class="cursor-pointer delete-button"
                    @click="deleteOtherFiscal(counter)">
                    <div class="flex items-center delete-icon">
                      <span v-if="screenDevice === $screenDeviceConstants.MOBILE">{{ $t('general.general.delete') }}</span>

                      <img
                        :src="require(`@/assets/img/icons/trash-can-fill.svg`)"
                        alt="icon"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <Message
                v-if="!fiscalOtherValid"
                :message="fiscalValidationMessage"
                :type="'error'"
              />

              <Message
                v-if="!countryOtherValid"
                :message="countryOtherValidMessage"
                :type="'error'"
              />

              <Button
                :icon="'icons/plus-circle-lined'"
                :icon-position="'left'"
                :text="$t('settings.residence_data.add_country')"
                :type="'btn-icon-text disabled-transparent'"
                :disabled="disabledOtherFiscal"
                class="mt-4 w-fit disabled-transparent"
                @call="addOtherFiscal"
              />
            </template>
          </div>
          <div class="">
            <img class="mb-3 cursor-pointer w-6 max-w-none" alt="edit" src="~@/assets/img/icons/edit-icon.svg" @click="toggleOtherFiscal">
          </div>
      </div>

      <div class="info flex items-center justify-between mb-10">
        <Checkbox
          :id="'usaFiscal'"
          v-model="usaFiscal"
          :class="{'pointer-events-none disabled disabled-transparent': disabledUsaFiscal}"
          :label="$t('settings.residence_data.us_resident')"
          :name="'checkbox'"
        />

        <img class="mb-3 cursor-pointer w-6 max-w-none" alt="edit" src="~@/assets/img/icons/edit-icon.svg" @click="toggleUsaFiscal">
      </div>

      <Message
        v-if="dataSaved"
        class="mb-5"
        :message="$t('settings.residence_data.data_saved')"
        :type="'success'"
      />

      <Message
        v-if="validation"
        :message="validation"
        :type="'error'"
      />

      <div class="flex justify-between items-center mt-10">
        <Button :icon="'icons/arrow_back_green'"
                :icon-position="'left'"
                :text="$t('general.general.back')"
                :type="'btn btn-secondary padding-big'"
                @call="resetStep"/>

        <Button :icon="'icons/check-white'"
                :icon-position="'right'"
                :text="$t('general.general.confirm')"
                :type="'btn btn-primary padding-big'"
                @call="next"/>
      </div>
    </div>
  </div>
</template>

<script>
import GeneralLayout from '../../layout/GeneralLayout'
import Button from '../../subcomponents/Button'
import {mapGetters} from 'vuex'
import ApiService from '../../../services/apiService'
import Message from '../../subcomponents/Message'
import InputText from '../../subcomponents/InputText'
import Card from '../../subcomponents/Card'
import Checkbox from '../../subcomponents/Checkbox'
import IconBox from '../../subcomponents/IconBox.vue'
import SelectDropDown from '../../subcomponents/SelectDropDown'

export default {
  name: 'UpdateResidence',
  components: {
    Message,
    GeneralLayout,
    Button,
    Card,
    InputText,
    IconBox,
    Checkbox,
    SelectDropDown
  },
  data () {
    return {
      countries: {},
      allCountries: {},
      fiscalOtherValid: true,
      countryOtherValid: true,
      loading: true,
      usaFiscal: false,
      otherFiscal: false,
      roFiscal: false,
      dataSaved: false,
      fiscalValidationMessage: '',
      countryOtherValidMessage: '',
      otherFiscalData: [],
      validation: '',
      disabledUsaFiscal: true,
      disabledOtherFiscal: true,
      disabledRoFiscal: true
    }
  },
  async created () {
    let countriesData = this.getCountries
    this.countries = countriesData.map(countryData => {
      return {
        id: countryData.code,
        label: countryData.name
      }
    }).filter((country) => {
      return country.id.toUpperCase() !== 'RO' && country.id !== 'US'
    })

    this.allCountries = this.countries

    this.roFiscal = (('has_tax_residence_ro' in this.updatedData.step_tax_residence) && this.updatedData.step_tax_residence.has_tax_residence_ro !== '')
      ? this.updatedData.step_tax_residence.has_tax_residence_ro : this.getUserData.has_tax_residence_ro === true
    this.usaFiscal = (('has_tax_residence_usa' in this.updatedData.step_tax_residence) && this.updatedData.step_tax_residence.has_tax_residence_usa !== '')
      ? this.updatedData.step_tax_residence.has_tax_residence_usa : this.getUserData.has_tax_residence_usa === true

    let other = []

    if (!('tax_residence_other' in this.updatedData.step_tax_residence) && Object.values(this.getUserData.tax_residence_other).length) {
      other = this.getUserData.tax_residence_other
    }

    if (('tax_residence_other' in this.updatedData.step_tax_residence) && Object.values(this.updatedData.step_tax_residence.tax_residence_other).length) {
      other = this.updatedData.step_tax_residence.tax_residence_other
    }

    if (other.length) {
      this.otherFiscalData = other.map(taxResidenceOther => {
        if (!Object.values(taxResidenceOther)) {
          return []
        }

        return {
          country: this.countries.filter((country) => country.id === taxResidenceOther.country_code)[0],
          fiscalCode: taxResidenceOther.tax_residence_number
        }
      })

      this.otherFiscal = this.otherFiscalData.filter((otherFiscalData) => {
        return otherFiscalData.country !== undefined
      }).length !== 0
    }

    await this.setData()
  },
  computed: {
    ...mapGetters({
      hasAccessInPortal: 'user/hasAccessInPortal',
      screenDevice: 'layout/screenDevice',
      updatedData: 'updatePersonalData/getUpdatedData',
      getUserData: 'user/user',
      getCountries: 'staticData/getCountries'
    })
  },
  methods: {
    toggleRoFiscal () {
      this.disabledRoFiscal = !this.disabledRoFiscal
    },
    toggleUsaFiscal () {
      this.disabledUsaFiscal = !this.disabledUsaFiscal
    },
    toggleOtherFiscal () {
      this.disabledOtherFiscal = !this.disabledOtherFiscal
    },
    async setData () {
      this.email = this.getUserData.email
      this.telephone = this.getUserData.phone
    },
    addOtherFiscal () {
      this.otherFiscalData.push({
        country: '',
        fiscalCode: ''
      })
    },
    deleteOtherFiscal (counter) {
      this.otherFiscalData.splice(counter, 1)
    },
    doesContainDuplicates (array) {
      let set = new Set(array)
      return set.size !== array.length
    },
    async next () {
      let taxResidenceOther = []
      let countries = []

      let updatedData = this.updatedData

      this.otherFiscalData.forEach(fiscalData => {
        if (!fiscalData.country) {
          this.countryOtherValid = false
        }

        if (fiscalData.country && !fiscalData.fiscalCode) {
          this.fiscalOtherValid = false
        }

        if (fiscalData.country && fiscalData.fiscalCode) {
          taxResidenceOther.push({
            country_code: fiscalData.country.id,
            tax_residence_number: fiscalData.fiscalCode
          })

          countries.push(fiscalData.country.id)
        }
      })

      if (this.roFiscal !== this.getUserData.has_tax_residence_ro) {
        updatedData.step_tax_residence.has_tax_residence_ro = this.roFiscal
      }

      if (this.usaFiscal !== this.getUserData.has_tax_residence_usa) {
        updatedData.step_tax_residence.has_tax_residence_usa = this.usaFiscal
      }

      if (JSON.stringify(taxResidenceOther) !== JSON.stringify(this.getUserData.tax_residence_other)) {
        updatedData.step_tax_residence.tax_residence_other = taxResidenceOther
      }

      let nextStep = 'updateQuestionnaire'

      this.$emit('next', {updatedData, nextStep})
    },
    async resetStep () {
      this.$emit('prev', 'updateIdentityCard')
    }
  },
  watch: {
    otherFiscalData: {
      handler (otherFiscalData) {
        this.fiscalOtherValid = true
        this.countryOtherValid = true
        let countriesIds = []

        otherFiscalData.forEach((fiscalData) => {
          if (fiscalData.country) {
            countriesIds.push(fiscalData.country.id)
          }
        })

        this.countries = this.allCountries.filter((country) => !countriesIds.includes(country.id))
      },
      deep: true
    },
    otherFiscal (nVal) {
      if (!nVal) {
        this.otherFiscalData = [
          {
            country: '',
            fiscalCode: ''
          }
        ]
      } else {
        this.validation = ''
      }
    },
    roFiscal (nVal) {
      if (nVal) {
        this.validation = ''
      }
    },
    usaFiscal (nVal) {
      if (nVal) {
        this.validation = ''
      }
    }
  }
}
</script>
