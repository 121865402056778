import ApiService from '../apiService'

class DocumentsService {
  async getAllDocuments () {
    return ApiService.withAuth().get('/api/user/documents')
  }
  async getAllInvoices () {
    return ApiService.withAuth().get('/api/user/documents/invoices')
  }

  async getUploadedDocuments () {
    return ApiService.withAuth().get('/api/user/documents/uploaded')
  }

  async getUserNotificationDocuments () {
    return ApiService.withAuth().get('/api/user/documents/notification')
  }

  async getClientDocumentCategories () {
    return ApiService.withAuth().get('/api/client-document-categories')
  }

  async downloadDocument (doc) {
    return ApiService.withAuth().get(doc.url, {responseType: 'arraybuffer'})
      .then((response) => {
        var db = require('mime-db')
        let documentNameAndExtension = doc.original_name
        if (response.headers['content-type'].split(':')[1].trim()) {
          let extension = response.headers['content-type'].split(':')[1].trim()
          extension = db[extension].extensions[0]
          if (!documentNameAndExtension.includes('.' + extension)) {
            documentNameAndExtension = documentNameAndExtension + '.' + extension
          }
        }

        var FILE = window.URL.createObjectURL(new Blob([response.data]))
        var docUrl = document.createElement('a')
        docUrl.href = FILE
        docUrl.setAttribute('download', documentNameAndExtension)
        document.body.appendChild(docUrl)
        docUrl.click()
        docUrl.remove()
      })
  }

  async saveDocument (payload) {
    return ApiService.withAuth().post('/api/user/documents', payload, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }

  async deleteDocument (payload) {
    return ApiService.withAuth().delete('/api/user/documents/' + payload)
  }

  async saveTmpDocuments (payload) {
    return ApiService.withAuth().post('/api/user/documents/tmp-documents', payload, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }

  async getTmpDocuments (route) {
    return ApiService.withAuth().get(route, {
      responseType: 'blob'
    })
  }
}

export default new DocumentsService()
