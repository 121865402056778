<template>
  <div class="personal-data">
    <general-layout>
      <template v-slot:title>
        <vue-title :title="$t('general.meta_titles.fiscal_data')"></vue-title>
      </template>

      <template v-slot:tabs>
        <Tabs
          :show-id-title="false"
        />
      </template>

      <template v-slot:body>
        <div class="gap-8" :class="[isDesktop ? 'desktop-flex items-stretch' : '', !isDesktop ? 'flex flex-col items-center' : '']">
          <div :class="[!isDesktop ? 'w-full flex flex-col items-center' : 'w-1/2']">
            <div v-if="!hasAccessInPortal">
              <ContinueToOnboarding/>
            </div>

            <div v-if="hasAccessInPortal">
              <PulseLoader class="w-fit m-auto p-10 justify-center flex" v-if="loading"/>

              <Transition appear name="fade">
                <Card v-if="!loading" class="personal-info w-full" :card-classes="'mobile-card-portal'">
                  <template v-slot:body>
                    <SelectResidenceData/>
                  </template>
                </Card>
              </Transition>
            </div>
          </div>

          <div :class="{'w-full' : isMobile}">
            <Card
              v-if="!isMobile"
              class="w-full"
              :class="{'h-full' : !hasAccessInPortal}"
              :card-classes="'mobile-card-resources padding-small flex-layout'"
              :no-title="true"
            >
              <template v-slot:body>
                <img class="big-icon" :src="require(`@/assets/img/icons/folder.svg`)" alt="user">

                <div>
                  <p v-html="$t('guides.fiscal_data.fiscal_p1')"/>
                  <br>
                  <p v-html="$t('guides.fiscal_data.fiscal_p2')"/>
                </div>
              </template>
            </Card>

            <div :class="{'mt-6': !isMobile}" class="w-full" v-if="hasAccessInPortal">
              <Resources :links="resourcesLinks"/>
            </div>
          </div>
        </div>
      </template>
    </general-layout>
  </div>
</template>

<script>
import GeneralLayout from '../../../layout/GeneralLayout'
import Tabs from '../../../layout/Tabs'
import SelectResidenceData from './SelectResidenceData'
import {mapGetters} from 'vuex'
import InputText from '../../../subcomponents/InputText'
import Card from '../../../subcomponents/Card'
import IconBox from '../../../subcomponents/IconBox.vue'
import ContinueToOnboarding from '../../ContinueToOnboarding'
import Resources from '../../../subcomponents/Resources.vue'

export default {
  name: 'FiscalData',
  components: {GeneralLayout, Tabs, SelectResidenceData, Card, InputText, IconBox, ContinueToOnboarding, Resources},
  data () {
    return {
      loading: true,
      usaFiscal: false,
      otherFiscal: false,
      roFiscal: false,
      cnp: null,
      otherFiscalData: [
        {
          country: '',
          fiscalCode: ''
        }
      ],
      resourcesLinks: [
        { label: this.$t('useful_links.fiscal_data.tax_information'), value: '/legale/fiscalitate/' }
      ]
    }
  },
  computed: {
    ...mapGetters({
      screenDevice: 'layout/screenDevice',
      hasAccessInPortal: 'user/hasAccessInPortal',
      isLogged: 'user/isLogged',
      user: 'user/user'
    }),
    isMobile () {
      return this.screenDevice === this.$screenDeviceConstants.MOBILE
    },
    isDesktop () {
      return this.screenDevice === this.$screenDeviceConstants.DESKTOP
    }
  },
  async mounted () {
    if (this.isLogged) {
      this.firstName = this.user.first_name
      this.lastName = this.user.last_name

      if (this.user.identity_card) {
        this.cnp = this.user.identity_card.personal_identification_number
        this.CI = this.user.identity_card.series
        this.address = this.user.identity_card.address
      }

      await this.setData()
    }
  },
  methods: {
    async setData () {
      this.email = this.user.email
      this.telephone = this.user.phone
      this.loading = false
    },
    addOtherFiscal () {
      this.otherFiscalData.push({
        country: '',
        fiscalCode: ''
      })
    },
    deleteOtherFiscal (counter) {
      this.otherFiscalData.splice(counter, 1)
    }
  }
}
</script>

<style scoped></style>
