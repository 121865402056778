<template>
  <div class="relative" :class="{'flex justify-end': isMobile && collapsedBannerMessage}">
    <div class="component-referrals" :class="{'collapsed': isMobile && collapsedBannerMessage}">
      <div v-if="!collapsedBannerMessage" class="message-container">
        <div class="message-wrapper">
          <span class="banner-message" v-html="bannerMessage"></span>
        </div>
      </div>

      <div class="collapse" @click="toggleCollapse">
        <img class="arrow-icon" :class="{'collapsed': collapsedBannerMessage}" src="@/assets/img/icons/arrow-down-white.svg" alt="arrow">
      </div>
    </div>
  </div>
</template>

<script>

import {mapActions, mapGetters} from 'vuex'
import Button from './Button.vue'

export default {
  name: 'Banner',
  components: {Button},
  props: {
    bannerMessage: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      body: null,
      subject: null,
      showCopiedMessage: false,
      operatingSystem: null,
      referralLink: null
    }
  },
  computed: {
    ...mapGetters({
      screenDevice: 'layout/screenDevice',
      collapsedBannerMessage: 'layout/collapsedBannerMessage'
    }),
    isMobile () {
      return this.screenDevice === this.$screenDeviceConstants.MOBILE
    },
    isDesktop () {
      return this.screenDevice === this.$screenDeviceConstants.DESKTOP
    }
  },
  async mounted () {
    await this.generateLink('link')
  },
  methods: {
    ...mapActions({
      setCollapsedBannerMessage: 'layout/setCollapsedBannerMessage'
    }),

    toggleCollapse () {
      this.setCollapsedBannerMessage(!this.collapsedBannerMessage)
    }
  }
}
</script>
