<template>
    <div class="document-card">
        <Card
            :key="counter"
            :w100="true"
            :title-small="true"
            :card-classes="computedCardClasses"
        >
            <template v-slot:body>
                <div class="document-card-grid">
                    <div class="icon-color-element">
                        <IconColor
                            :small="small"
                            :color="iconColor"
                            :positions="['left']"
                            icon="paper-corner-fold"
                        />
                    </div>

                    <div
                        class="title-element cursor-pointer"
                        @click="download()"
                    >
                        <div class="title">{{ formatName(document.name) }}</div>
                    </div>
                </div>

                <div class="data-and-download-element">
                    <div class="flex details items-center">
                        <div class="mr-3">
                            <img
                                src="@/assets/img/icons/calendar.svg"
                                alt="calendar"
                            />
                        </div>

                        <div class="whitespace-nowrap">
                            {{ document.created_at }}
                        </div>
                    </div>

                    <div
                        class="download cursor-pointer ml-3"
                        @click="download()"
                    >
                        <div class="download-icon" :class="[iconColor]"></div>
                    </div>
                </div>
            </template>
        </Card>
    </div>
</template>

<script>
import Button from '../../subcomponents/Button.vue'
import Card from '../../subcomponents/Card.vue'
import DocumentsService from '../../../services/documents/documentsService'
import IconColor from '../../subcomponents/IconColor.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'DocumentCard',
  components: {
    IconColor,
    Button,
    Card
  },
  props: {
    counter: {
      type: Number,
      default: 0
    },
    document: {
      type: Object,
      required: true
    },
    small: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      screenDevice: 'layout/screenDevice'
    }),
    isMobile () {
      return this.screenDevice === this.$screenDeviceConstants.MOBILE
    },
    iconColor () {
      if (this.counter % 3 === 0) {
        return 'green'
      }

      if (this.counter % 3 === 1) {
        return 'yellow'
      }

      return 'blue'
    },
    computedCardClasses () {
      let classes = 'mobile-card-portal'
      if (!this.small) {
        classes += ' margin-bottom-big'
      } else {
        classes += ' card-small'
      }
      return classes
    }
  },
  methods: {
    async download () {
      await DocumentsService.downloadDocument(this.document)
    },
    formatName (name) {
      let nameSplit = name.split('_')
      if (nameSplit.length < 3) return name
      let date = nameSplit[2].split(' ')[0].split('-').join(':')
      return date + '-' + nameSplit[0]
    }
  }
}
</script>

<style scoped>
</style>
