<template>
  <div class="notifications mt-6">
    <pulse-loader v-if="loading" class="m-auto w-fit"/>

    <img v-if="!loading && imgName.length" :src="require(`@/assets/img/${imgName}.png`)" alt="notifications-image">

    <div class="filters" :class="[numberOfCols, {'mt-4' : imgName.length}]" v-if="!loading">
      <InputText :background-color-white="true" :id="'search'" v-model="filters.search"
                 :label="$t('notifications.notification.search_notification')" :grey-label="true" :type-input="'text'"/>

      <div class="calendars relative" v-if="!onlySearchInput">
        <Calendar
          id="dateStart"
          v-model="filters.dateStart"
          :input-label="'De la'"
          :input-grey-label="true"
          :background-color-white="true"
        />

        <Calendar
          id="dateEnd"
          v-model="filters.dateEnd"
          :input-label="'Până la'"
          :input-grey-label="true"
          :background-color-white="true"
        />
      </div>

      <SelectDropDown
        v-if="!onlySearchInput"
        :background-color-white="true"
        v-model="filters.readStatus"
        :id="'type'"
        :options="statuses"
        :placeholder="$t('notifications.notification.all_read_unread')"
        :label="'Afișare'"
        :grey-label="true"
        required
      />

      <div class="flex justify-center items-end mb-2">
        <div
          v-if="allReadButton"
          :class="{'opacity-50 pointer-events-none' : !notifications.length || loadingNotificationsRead}"
          class="cursor-pointer color-primary"
          @click="markAllAsRead"
        >
          {{ $t('notifications.notification.mark_all_as_read') }}
        </div>
      </div>

    </div>

    <div class="desktop-flex gap-8 mt-10">
      <div class="w-full">
        <Transition-Group appear name="fade">
          <template v-if="!loading">
            <Card :title-small="true" v-for="(notification, index) in notifications"
                  :card-classes="`w-full p-5 mobile-card-portal `"
                  :key="notification.id" class="w-full single-notification mb-2">
              <template v-slot:title>
                <div class="flex justify-between cursor-pointer items-center" @click="toggleNotification(index)">
                  <span class="header">{{ notification.subject }}</span>

                  <div class="flex status items-center text-base">
                        <span class="mr-4" :class="[
                          {
                            read: notification.date_read_at,
                            unread: notification.date_read_at === null
                          }
                        ]">
                          {{ status(notification.date_read_at) }}
                        </span>

                    <img v-if="openNotification === notification.id" src="@/assets/img/icons/arrow_filled_up.svg" alt="arrow_up">
                    <img v-else src="@/assets/img/icons/arrow_filled_down.svg" alt="arrow_down">
                  </div>
                </div>
              </template>

              <template v-slot:body>
                <div v-if="notification.id === openNotification" @click="handleClick" class="content" v-html="formatNotification(notification.body)"></div>
                <div v-if="notification.id === openNotification" class="content">
                  <div class="notification-documents" v-if="notification.attachment">
                    <div class="notification-document" v-for="(attachment, index) in notification.attachment" :key="index">
                      <DocumentCard :small="true" :document="attachment"/>
                    </div>
                  </div>
                </div>
                <div class="mt-4 flex">
                  <img src="@/assets/img/icons/grey_calendar.svg" alt="calendar" class="mr-2.5">
                  <div class="color-light-gray text-sm mt-1">
                    {{ notification.created_at }}
                  </div>
                </div>
              </template>
            </Card>
          </template>
        </Transition-Group>
      </div>

      <div class="w-full" :class="[isMobile ? 'mt-8' : '']" v-if="!hideGuide">
        <div class="w-full">
          <IconBox
            icon-color="green"
            icon="help-placeholder"
            class="mb-2 guide"
            :href="wpLink('/ghid/deschidere-cont-persoane-fizice/')"
            :text="$t('useful_links.notifications.guide_open_account')"
          />

          <IconBox
            icon-color="yellow"
            icon="help-placeholder"
            class="mb-2 guide"
            :href="wpLink('/ghid/ghid-alimentare-cont/')"
            :text="$t('guides.general.deposit_guide')"
          />
        </div>
      </div>
    </div>

    <Pagination v-if="paginationData && paginationData.per_page < paginationData.total"
                @goToPage="loadPage($event)" :data="paginationData"
    />
  </div>
</template>

<script>
import Pagination from '../../subcomponents/Pagination'
import GeneralLayout from '../../layout/GeneralLayout'
import Tabs from '../../layout/Tabs'
import Button from '../../subcomponents/Button'
import NotificationsService from '../../../services/notifications/notificationsService'
import InputText from '../../subcomponents/InputText'
import SelectDropDown from '../../subcomponents/SelectDropDown'
import Card from '../../subcomponents/Card'
import IconBox from '../../subcomponents/IconBox.vue'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import { mapActions, mapGetters } from 'vuex'
import Calendar from '../../subcomponents/Calendar.vue'
import { format } from '../../../helpers/dateHelper'
import DocumentService from '../../../services/documents/documentsService'
import DocumentCard from '@/components/profile/documents/DocumentCard.vue'
import NavbarButtonMethods from '@/mixins/NavbarButtonMethods'
export default {
  name: 'ClientNotifications',
  mixins: [NavbarButtonMethods],
  props: {
    type: {
      type: String,
      required: true
    },
    onlySearchInput: {
      type: Boolean,
      default: false
    },
    hideGuide: {
      type: Boolean,
      default: false
    },
    imgName: {
      type: String,
      default: ''
    },
    allReadButton: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Calendar,
    GeneralLayout,
    Tabs,
    Button,
    IconBox,
    Card,
    InputText,
    PulseLoader,
    SelectDropDown,
    Pagination,
    DocumentCard
  },
  data () {
    return {
      loading: true,
      currentPage: null,
      notifications: [],
      paginationData: null,
      filters: {
        search: null,
        dateStart: null,
        dateEnd: null,
        readStatus: null
      },
      statuses: [
        { label: this.$t('notifications.notification.read'), value: true },
        { label: this.$t('notifications.notification.unread'), value: false }
      ],
      generalInfo: 0,
      userInfo: 0,
      openNotification: null,
      loadingNotificationsRead: false
    }
  },
  async created () {
    this.loading = true
    if (this.isLogged) {
      await this.setData()
    }
  },
  computed: {
    ...mapGetters({
      screenDevice: 'layout/screenDevice',
      unreadUserNotifications: 'user/unreadUserNotifications',
      unreadGeneralNotifications: 'user/unreadGeneralNotifications',
      isLogged: 'user/isLogged'
    }),
    isDesktop () {
      return this.screenDevice === this.$screenDeviceConstants.DESKTOP
    },
    isMobile () {
      return this.screenDevice !== this.$screenDeviceConstants.DESKTOP
    },
    numberOfCols () {
      if (this.allReadButton && this.onlySearchInput) {
        return 'grid-cols-2'
      }

      if (this.onlySearchInput) {
        return 'grid-cols-1'
      }

      if (this.allReadButton && !this.onlySearchInput) {
        return 'grid-cols-4'
      }
    }
  },
  watch: {
    filters: {
      handler () {
        this.loadPage(1)
      },
      deep: true
    }
  },
  methods: {
    ...mapActions({
      getUnreadNotifications: 'user/getUnreadNotifications'
    }),
    formatNotification (message) {
      message = message.replace(/<a target='_blank' href='[^']*'>Investimental GLOBAL<\/a>/g, "<a target='_blank' class='link cursor-pointer'>Investimental GLOBAL</a>")
      message = message.replace(/<a target='_blank' href='[^']*'>Investimental BVB<\/a>/g, "<a target='_blank' class='link cursor-pointer'>Investimental BVB</a>")

      return message
    },
    handleClick (event) {
      const target = event.target.closest('.link')

      if (target) {
        if (target.textContent.includes('Investimental GLOBAL')) {
          this.getDxToken()
        } else if (target.textContent.includes('Investimental BVB')) {
          this.getJwt()
        }
      }
    },
    async setData ($page) {
      let allDbNotifications = await NotificationsService.getAllNotifications(
        $page || 1,
        {
          search: this.filters.search,
          dateStart: format(this.filters.dateStart, 'dd-mm-yyyy'),
          dateEnd: format(this.filters.dateEnd, 'dd-mm-yyyy'),
          type: this.type,
          readStatus: this.filters.readStatus
            ? this.filters.readStatus.value
            : null
        }
      )

      if (allDbNotifications.data.data.notifications.data) {
        this.notifications = Object.values(allDbNotifications.data.data.notifications.data)
        this.paginationData = allDbNotifications.data.data.notifications
      }

      this.loading = false

      await this.getUnreadNotifications()

      this.userInfo = this.unreadUserNotifications
      this.generalInfo = this.unreadGeneralNotifications
    },
    status (status) {
      if (status) {
        return this.$t('notifications.notification.read')
      } else {
        return this.$t('notifications.notification.unread')
      }
    },
    async toggleNotification (index) {
      if (!this.notifications[index].date_read_at) {
        await NotificationsService.setNotificationStatus(
          this.notifications[index].id,
          { read: true }
        )
        this.notifications[index].date_read_at = Date()
      }
      await this.getUnreadNotifications()

      this.userInfo = this.unreadUserNotifications
      this.generalInfo = this.unreadGeneralNotifications
      // this.readNotification = this.notifications[index]

      if (this.notifications[index].id === this.openNotification) {
        this.openNotification = null
      } else {
        this.openNotification = this.notifications[index].id
      }
    },
    async goBack () {
      await this.setData()
      this.readNotification = false
    },
    async loadPage ($page) {
      await this.setData($page)
    },
    async downloadAttachment (doc) {
      await DocumentService.downloadDocument(doc)
    },
    async markAllAsRead () {
      this.loadingNotificationsRead = true
      await NotificationsService.setAllNotificationsRead(this.type)
      await this.setData()
      this.loadingNotificationsRead = false
    }
  }
}
</script>
