import auth from './ro/auth'
import guides from './ro/guides'
// eslint-disable-next-line camelcase
import useful_links from './ro/useful-links'
import onboarding from './ro/onboarding'
import general from './ro/general'
// eslint-disable-next-line camelcase
import deposit_withdrawal from './ro/profile/deposit-withdrawal'
import settings from './ro/profile/settings'
import notifications from './ro/profile/notifications'
import documents from './ro/profile/documents'
import help from './ro/profile/help'
// eslint-disable-next-line camelcase
import update_personal_data from './ro/profile/update-personal-data'

export default {
  auth,
  guides,
  useful_links,
  onboarding,
  general,
  settings,
  deposit_withdrawal,
  notifications,
  documents,
  update_personal_data,
  help
}
