<template>
  <div>
    <div v-for="(detail, key) in details" :key="key">
      <div :class="{'error-border': detailsError}">
        <div v-if="detail.label" class="mt-4">{{ detail.label }}</div>
        <Detail :detail="detail"
                :disabled="disabled"
                :pre-selected-answer-detail="answerDetails[detail.identifier]"
                @selectedAnswerDetail="selectedAnswerDetail"/>
      </div>
    </div>
  </div>
</template>

<script>
import SelectDropDown from '../subcomponents/SelectDropDown'
import InputText from '../subcomponents/InputText'
import Detail from './Detail'

export default {
  name: 'Details',
  components: {Detail, SelectDropDown, InputText},
  props: {
    details: {
      type: Array,
      required: true
    },
    answerIdentifier: {
      type: String,
      default: () => {
        return ''
      }
    },
    preSelectedAnswerDetails: {
      type: Object,
      default: () => {
        return {}
      }
    },
    detailsError: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  beforeMount () {
    this.answerDetails = this.preSelectedAnswerDetails

    this.details.forEach((detail) => {
      if (typeof this.answerDetails[detail.identifier] === 'undefined') {
        this.$set(this.answerDetails, detail.identifier, '')
      }
    })
  },
  data () {
    return {
      answerDetails: {}
    }
  },
  methods: {
    selectedAnswerDetail (detailIdentifier, value) {
      this.$set(this.answerDetails, detailIdentifier, value)
      this.$emit('setDetailsAnswers', this.answerDetails, this.answerIdentifier)
    }
  }
}
</script>

<style scoped>

</style>
