<template>
  <div class="component-maintenance-mode-accouncement-banner">
    {{ message }}
  </div>
</template>
<script>
export default {
  name: 'MaintenanceModeAnnouncementBanner',
  props: {
    message: {
      type: String,
      default: ''
    }
  }
}
</script>
