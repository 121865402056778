<template>
  <div>
    <div @click="handleClick" :class="{'disabled': loading}" class="mt-2 font-bold color-username cursor-pointer">
      {{ $t('auth.general.resend_code') }}
    </div>
    <div v-if="loading">
      <TimerComponent :total-time="30"/>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import TimerComponent from '@/components/subcomponents/TimerComponent.vue'

export default {
  name: 'ResendCode',
  components: {TimerComponent},

  data () {
    return {
      loading: false
    }
  },

  methods: {
    ...mapActions({
      generateSmscode: 'user/generateSmscode'
    }),

    async handleClick () {
      if (!this.loading) {
        this.loading = true
        await this.generateSmscode()

        setTimeout(() => {
          this.loading = false
        }, 30000)
      }
    }
  }
}
</script>
