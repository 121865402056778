<template>
    <div class="time-periods">
        <div
          class="time-period"
          :class="{ 'active': activeTimePeriod === '1W' }"
          @click="setActiveTimePeriod('1W')"
        >
          {{ $t('deposit_withdrawal.portfolio.time_periods.one_week') }}
        </div>

        <div
          class="time-period"
          :class="{ active: activeTimePeriod === '1M' }"
          @click="setActiveTimePeriod('1M')"
        >
          {{ $t('deposit_withdrawal.portfolio.time_periods.one_month') }}
        </div>
        <div
          class="time-period"
          :class="{ active: activeTimePeriod === '3M' }"
          @click="setActiveTimePeriod('3M')"
        >
          {{ $t('deposit_withdrawal.portfolio.time_periods.three_months') }}
        </div>

        <div
          class="time-period"
          :class="{ active: activeTimePeriod === '1Y' }"
          @click="setActiveTimePeriod('1Y')"
        >
          {{ $t('deposit_withdrawal.portfolio.time_periods.one_year') }}
        </div>

        <div
          class="time-period"
          :class="{ active: activeTimePeriod === 'MAX' }"
          @click="setActiveTimePeriod('MAX')"
        >
          {{ $t('deposit_withdrawal.portfolio.time_periods.max') }}
        </div>
      </div>
</template>

<script>
export default {
  name: 'TimePeriod',
  props: {
  },
  data () {
    return {
      activeTimePeriod: 'MAX'
    }
  },
  methods: {
    setActiveTimePeriod (timePeriod) {
      this.activeTimePeriod = timePeriod
      this.$emit('timePeriodChange', this.activeTimePeriod)
    }
  }
}
</script>
