<template>
  <div>
    <div class="questionnaire-component" v-if="!showIntro">
      <div class="progress-bar bg-gray-200 m-top-custom m-bottom-custom">
        <div :style="progressBarLength" class="bar">{{ progressBar }}%</div>
      </div>

      <QuestionnaireMain
        :questions="questions"
        :questions-answers="answers"
        :selected-question-prop="selectedQuestion"
        @next="next"
        @back="back"
        @finish="finish"
        @showIntro="toggleShowIntro"
      />
    </div>

    <div v-else>
      <div class="mt-4 mb-10">{{ $t('general.questionnaire.intro') }}</div>

      <div class="nav m-top-custom-l flex justify-between">
        <Button :icon="'icons/arrow_icon_back'"
                :icon-position="'left'"
                :text="$t('general.questionnaire.back')"
                :type="'btn btn-secondary'"
                class="prev"
                @call="resetStep"/>

        <Button :disabled="false"
                :icon="'icons/arrow_icon'"
                :icon-position="'right'"
                :text="$t('general.questionnaire.next')"
                :type="'btn btn-primary end-position'"
                class="next"
                @call="startQuestionnaire"/>
      </div>
    </div>

    <PulseLoader v-if="!loaded"/>
  </div>
</template>

<script>
import PulseLoader from 'vue-spinner/src/PulseLoader'
import Main from '../../questionnaire/Main.vue'
import Card from '../../subcomponents/Card.vue'
import StepBar from '../../layout/StepBar.vue'
import Button from '../../subcomponents/Button.vue'
import questionnaireService from '../../../services/onboarding/questionnaireService'
import Message from '../../subcomponents/Message.vue'
import CheckboxQuestion from '../../questionnaire/question-types/CheckboxQuestion.vue'
import MultipleQuestion from '../../questionnaire/question-types/MultipleQuestion.vue'
import SelectorQuestion from '../../questionnaire/question-types/SelectorQuestion.vue'
import ButtonQuestion from '../../questionnaire/question-types/ButtonQuestion.vue'
import {mapGetters} from 'vuex'
import QuestionnaireMain from './subcomponents/QuestionnaireMain.vue'
export default {
  name: 'UpdateQuestionnaire',
  components: {
    QuestionnaireMain,
    ButtonQuestion,
    SelectorQuestion,
    MultipleQuestion,
    CheckboxQuestion,
    Message,
    Main,
    Card,
    StepBar,
    PulseLoader,
    Button
  },
  props: {
    showIntroProp: {
      type: Boolean,
      default: true
    },
    selectedQuestionProp: {
      type: Number,
      default: 0
    },
    fromDocs: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      questions: [],
      answers: {},
      loaded: false,
      showIntro: true,
      selectedQuestion: 0
    }
  },
  computed: {
    ...mapGetters({
      updatedData: 'updatePersonalData/getUpdatedData',
      temporaryStepQuestionnaire: 'updatePersonalData/getTemporaryStepQuestionnaire'
    }),
    progressBarLength () {
      return 'width:' + this.progressBar + '%'
    },
    progressBar () {
      return Math.round(
        ((this.selectedQuestion + 1) * 100) / this.questions.length
      )
    }
  },
  async beforeMount () {
    let questionsIndexResponse = await questionnaireService.getQuestions()

    if (questionsIndexResponse.status === 200) {
      this.questions = questionsIndexResponse.data.data
    }

    let questionnaireResponse = await questionnaireService.getQuestionnaire()

    if (questionnaireResponse.status === 200) {
      if (questionnaireResponse.data.data.questionnaire &&
        Object.values(questionnaireResponse.data.data.questionnaire.answers).length) {
        this.answers = Object.fromEntries(
          Object.entries(questionnaireResponse.data.data.questionnaire.answers).slice(
            0, Object.values(questionnaireResponse.data.data.questionnaire.answers).length - 3
          )
        )
      }

      this.loaded = true
    }

    this.showIntro = this.showIntroProp
    this.selectedQuestion = this.fromDocs ? this.selectedQuestionProp : 0

    this.$emit('setFromDocs')
  },
  methods: {
    startQuestionnaire () {
      this.showIntro = false
    },
    toggleShowIntro () {
      this.showIntro = true
    },
    next () {
      this.selectedQuestion++
    },
    back () {
      this.selectedQuestion--
    },
    async finish () {
      let nextStep = 'updateDocuments'
      let updatedData = this.updatedData

      updatedData.step_questionnaire = JSON.parse(JSON.stringify(this.temporaryStepQuestionnaire)) !== JSON.parse(JSON.stringify(this.answers)) ? this.temporaryStepQuestionnaire : null

      this.$emit('next', {updatedData, nextStep})
    },
    resetStep () {
      this.$emit('prev', 'updateResidence')
    }
  }
}
</script>

<style scoped>

</style>
