<template>
  <div>
    <div class="flex relative items-center justify-between">
      <div class="mr-5">
        {{ question.label }}
      </div>
      <div class="flex items-center">
        <div v-if="question.explanation" class="w-4">
          <div class="tooltip-wrapper">
            <img alt="my-account" src="~@/assets/img/icons/info.svg">
            <div class="tooltip" v-html="question.explanation"/>
          </div>
        </div>
        <img
          v-if="parent === 'update-data'"
          class="mb-2 ml-3 h-fit cursor-pointer w-6 max-w-none"
          alt="edit"
          src="~@/assets/img/icons/edit-icon.svg"
          @click="toggleDisabledQuestion">
      </div>
    </div>

    <div class="flex mt-4" :class="{'pointer-events-none disabled disabled-transparent': disabledQuestion && parent === 'update-data'}">
      <InputText
        v-model="value"
        class="mb-4 w-full"
      />
    </div>
  </div>
</template>

<script>
import Checkbox from '../../subcomponents/Checkbox'
import SelectDropDown from '../../subcomponents/SelectDropDown'
import InputText from '../../subcomponents/InputText'

export default {
  name: 'InputQuestion',
  components: {InputText, Checkbox, SelectDropDown},
  props: {
    parent: {
      type: String,
      default: 'onboarding'
    },
    question: {
      type: Object,
      required: true
    },
    preSelectedAnswer: {
      type: Object,
      default: () => {
        return {}
      }
    },
    disabled: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      value: '',
      identifier: '',
      selectedAnswer: {},
      disabledQuestion: true
    }
  },
  beforeMount () {
    this.identifier = this.question.answers[0].identifier

    if (Object.keys(this.preSelectedAnswer).length === 0) {
      return
    }

    let answerIdentifier = Object.keys(this.preSelectedAnswer)[0]

    this.selectedAnswer = this.question.answers.filter((answer) => answer.identifier === answerIdentifier)[0]

    this.value = this.preSelectedAnswer[answerIdentifier]
    this.identifier = answerIdentifier
  },
  methods: {
    setAnswer (answer) {
      this.selectedAnswer = answer

      if (!answer) {
        this.$emit('setAnswers', this.question.identifier, null)

        return
      }

      this.$emit('setAnswers', this.question.identifier, this.buildAnswer())
    },
    buildAnswer () {
      let finalAnswer = {}

      finalAnswer[this.identifier] = this.value

      return finalAnswer
    },
    toggleDisabledQuestion () {
      this.disabledQuestion = !this.disabledQuestion
      this.$emit('toggleDisabledQuestion', this.disabledQuestion)
    }
  },
  watch: {
    value (value) {
      this.setAnswer(value)
    },
    disabled: function () {
      this.disabledQuestion = this.disabled
    }
  }
}
</script>

<style scoped>

</style>
