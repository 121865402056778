<template>
  <div name='side-menu' @click="checkIfOpen" v-if="isLogged && !isDesktop" :class="{'open' : value}"
       class="mobile-menu">
    <div class="drawer">
      <div class="avatar-box">
        <div class="avatar">
          <img src="@/assets/img/icons/user.svg" alt="user">
        </div>

        <div>
          <div class="font-extrabold text-lg whitespace-nowrap mt-2.5">{{ user.first_name }} {{user.last_name }}</div>
        </div>
      </div>

      <div class="links">
        <div class="link" :class="{'active': $route.meta.menu === 'settings'}"
             @click="openRoute('settings')">
          {{ $t('general.menu.my_account') }}
        </div>

        <div class="link" :class="{'active': $route.meta.menu === 'assets'}" @click="openRoute('portofolio')">
          {{ $t('general.menu.transfers') }}

        </div>

        <div class="link money" :class="{'active': $route.meta.menu === 'bankAccounts'}" @click="openRoute('bank-accounts')">
          {{ $t('general.menu.money') }}

        </div>

        <Button
          :loading=arenaXtLoading
          @call="getJwt"
          extra-classes="px-5"
          class="mt-8 bvb"
          :text="$t('general.menu.arena_xt')"
          :type="'btn-primary'"
          :icon="'icons/bvb'"
          :icon-position="'left'"
        />

        <Button
          :loading=middlewareLoading
          @call="getMiddlewareToken"
          extra-classes="px-5"
          class="mt-8 bvb"
          v-if="middlewareActive"
          :text="$t('general.menu.web_terminal')"
          :type="'btn-primary'"
          :icon="'icons/bvb'"
          :icon-position="'left'"
        />

        <Button
          :loading=DXLoading
          @call="getDxToken"
          extra-classes="px-5"
          class="mt-8 dx"
          :text="$t('general.menu.global')"
          :icon="'icons/global'"
          :icon-position="'left'"
          :type="'btn-primary'"
        />

        <Button
          extra-classes="px-5"
          class="mt-8 lms"
          :text="$t('general.menu.lms')"
          :icon="'icons/mentor'"
          :icon-position="'left'"
          :type="'btn-dark-outline'"
          @call="loginInLms()"
        />
      </div>

      <div class="logout" @click="logoutUser">
        <img src="@/assets/img/icons/exit.svg" alt="exit">

        <div>
          {{ $t('general.menu.log_out') }}
        </div>
      </div>

      <PopUpModal
        :modal-fit-content="true"
        :modal-opened="showNewUserModal"
        @closeModal="closePopUp()">
        <div :class="[!isMobile ? 'flex flex-col justify-center': 'mt-16']">
          <div class="text-center">{{ $t('general.general.new_user_warn') }}</div>
          <Button class="mt-5 mx-auto" :text="$t('general.general.ok')" :type="'btn-primary'" @call="closePopUp()"/>
        </div>

      </PopUpModal>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import Button from '../subcomponents/Button.vue'
import NavbarButtonMethods from '../../mixins/NavbarButtonMethods'
import {USER_STATUS_APPROVED} from '../../services/auth/userService'
import PopUpModal from '@/components/subcomponents/PopUpModal.vue'

export default {
  name: 'Sidebar',
  components: {PopUpModal, Button},
  mixins: [NavbarButtonMethods],
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  comments: {
    Button
  },
  computed: {
    ...mapGetters({
      user: 'user/user',
      isLogged: 'user/isLogged',
      screenDevice: 'layout/screenDevice',
      middlewareActive: 'user/middlewareActive'
    }),
    userStatusApproved () {
      return USER_STATUS_APPROVED
    },
    getCurrentRoute () {
      return this.$route.name
    },
    isDesktop () {
      return this.screenDevice === this.$screenDeviceConstants.DESKTOP
    },
    isMobile () {
      return this.screenDevice === this.$screenDeviceConstants.MOBILE
    }
  },
  methods: {
    ...mapActions({
      logout: 'user/logout',
      setIntroPing: 'guide/setIntroPing'
    }),
    openRoute (nameRoute) {
      this.closeMenu()
      if (this.getCurrentRoute === nameRoute) return
      this.$router.push({name: nameRoute})
    },
    closeMenu () {
      this.$emit('closeMenu')
    },
    checkIfOpen (event) {
      if (this.value && event.target.attributes.name && event.target.attributes.name.value === 'side-menu') {
        this.closeMenu()
      }
    }
  },
  watch: {
    async value (val) {
      if (val) {
        const sleep = (ms) => {
          return new Promise(resolve => setTimeout(resolve, ms))
        }

        await sleep(500)

        await this.setIntroPing((Math.random() + 1).toString(36).substring(7))
      }
    }
  }
}
</script>

<style scoped>

</style>
