<template>
  <div>
    <div>
      <NavbarBasicOnboarding/>
    </div>
    <div class="onboarding-layout">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import NavbarBasicOnboarding from './navbar/NavbarBasicOnboarding'

export default {
  name: 'OnboardingLayout',
  components: { NavbarBasicOnboarding }
}
</script>

<style scoped>

</style>
