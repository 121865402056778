<template>
  <div v-if="!loading">
    <div class="personal-info" :class="{'px-1 py-7' : isMobile}">
      <div class="flex items-center mb-4" :class="{'px-6' : isMobile}">
        <iconColor color="green" icon="user-dot-border"/>

        <div class="ml-5">
          <span class="edit font-extrabold name account-title" v-if="!isEdit">{{ firstName }} {{ lastName }}</span>
        </div>
      </div>

      <div class="desktop-flex gap-8 mt-8" v-if="isEdit">
        <div class="info mb-4" :class="{'w-1/2' : !isMobile}">
          <InputText
            :id="'firstName'"
            v-model="firstName"
            :label="$t('settings.general.first_name')"
            :type-input="'text'"
            :disabled-input="!isEdit"
          />
        </div>

        <div class="info mb-4" :class="{'w-1/2' : !isMobile}">
          <InputText
            :id="'lastName'"
            v-model="lastName"
            :label="$t('settings.general.last_name')"
            :type-input="'text'"
            :disabled-input="!isEdit"
          />
        </div>
      </div>

      <Message v-if="warnings.cnp" :message="warnings.cnp" :type="'warning'" class="mb-5"/>

      <div class="desktop-flex gap-8" :class="{'mt-8' : !isEdit}">
        <div class="info mb-4" :class="{'w-1/2' : !isMobile}">
          <InputText
            :id="'cnp'"
            v-model="cnp"
            :label="$t('settings.general.cnp')"
            :type-input="'text'"
            :disabled-input="onboarding ? !isEdit : true"
          />
        </div>

        <div class="info mb-4" :class="{'w-1/2' : !isMobile}" v-if="type !== types.passport">
          <InputText
            :id="'series'"
            v-model="series"
            :label="$t('settings.general.series')"
            :type-input="'text'"
            :disabled-input="!isEdit"
          />
        </div>
      </div>

      <div class="desktop-flex gap-8">
        <div class="info mb-4" :class="{'w-1/2' : !isMobile}">
          <InputText
            :id="'CI'"
            v-model="CI"
            :label="$t('settings.general.number')"
            :type-input="'text'"
            :disabled-input="!isEdit"
          />
        </div>

        <div class="info mb-4" v-if="!onboarding" :class="{'w-1/2' : !isMobile}">
          <Calendar
            id="releaseDate"
            v-model="releaseDate"
            :disabled-dates="['afterToday']"
            :input-label="$t('settings.general.release_date')"
            :disable="!isEdit"
          />
        </div>
      </div>

      <div class="" :class="{'grid grid-cols-2 gap-8' : !isMobile}">
        <div class="info mb-4">
          <Calendar
            id="expirationDate"
            v-model="expirationDate"
            :disabled-dates="['beforeToday']"
            :input-label="$t('settings.general.expiration_date')"
            :disable="!isEdit"/>
        </div>

        <div class="info mb-4 " v-if="!onboarding">
          <div class="input-text" @click="openAddressField" :class="{'pointer-events-none' : !isEdit}">
            <div class="form-label inline-block grey-label pl-4">{{ $t('settings.general.identity_card_address') }}</div>
            <div class="input-primary w-full address-label" :class="{'disabled' : !isEdit}">{{ address ? address.label : '' }}</div>
          </div>
        </div>
      </div>

      <div class="info mb-4" v-if="type === types.passport" :class="{'w-1/2' : !isMobile}">
        <span class="info-title">{{ $t('settings.general.identity_card_type') }}</span>
        <span class="edit">{{ type }}</span>
      </div>

      <div class="info flex items-center my-5" v-if="isEdit && !onboarding">
        <span @click="$refs.identity_card.click()" class="document cursor-pointer">
          <img
            :src="require(`@/assets/img/icons/upload-document.svg`)"
            alt="icon"
          />

          <span class="info-title" v-if="identity_card">{{ identity_card.name }}</span>

          <div class="info-title flex whitespace-nowrap" v-else>
            <div>{{$t("general.general.upload_files")}}</div>
            <div class="info-title">{{$t('general.general.file_types')}}</div>
          </div>
        </span>

        <div
          v-if="identity_card && !onboarding"
          class="edit-button pl-2"
          @click="$refs.identity_card.click()">
          {{ $t('general.general.edit') }}
        </div>
      </div>

      <input
        v-if="isEdit && !onboarding"
        :ref="'identity_card'"
        hidden
        name="identity_card"
        id="identity_card"
        type="file"
        @change="handleFileUpload"
      />

      <div :class="{'divider-top' : !isMobile}">
        <IconMessage
          v-if="!onboarding && !legalPerson"
          :icon="'icons/warning-filled'"
          :text="$t('settings.general.update_id_card_info')"
        />

        <IconMessage
          v-if="savedData"
          :color-green="true"
          :icon="'icons/check-mark-8'"
          :text="$t('settings.general.save_data_succes')"
        />

        <Button
          :text="!onboarding ? $t('settings.general.update_id_card') : $t('settings.general.update_ocr_data')"
          :type="'btn-secondary'"
          class="button-full-mobile"
          v-if="!isEdit"
          @call="toggleIsEdit"
        />

        <Button
          :text="$t('general.general.save')"
          class="button-full-mobile"
          v-if="isEdit && !onboarding"
          @call="saveData"
        />
      </div>
    </div>

    <div class="mt-5" v-if="onboarding">
      <FullAddress
        :pre-selected-address="address"
        :show-save-button="false"
        :show-only-first-input="true"
        @fullAddress="setDomicileAddress"
      />
    </div>

    <div v-if="onboarding" class="italic text-xs mt-2 pl-4">{{ $t('settings.general.address_note') }}</div>

    <div>
      <Message v-if="generalValidation" :message="generalValidation" :type="'error'" class="mb-5"/>
      <Message v-if="firstNameValidation" :message="firstNameValidation" :type="'error'" class="mb-5"/>
      <Message v-if="lastNameValidation" :message="lastNameValidation" :type="'error'" class="mb-5"/>
      <Message v-if="seriesValidation && type !== 'passport'" :message="seriesValidation" :type="'error'" class="mb-5"/>
      <Message v-if="numberValidation" :message="numberValidation" :type="'error'" class="mb-5"/>
      <Message v-if="releaseDateValidation" :message="releaseDateValidation" :type="'error'" class="mb-5"/>
      <Message v-if="expirationDateValidation" :message="expirationDateValidation" :type="'error'" class="mb-5"/>
      <Message v-if="expirationReleaseDatesValidation" :message="expirationReleaseDatesValidation" :type="'error'" class="mb-5"/>
      <Message v-if="identityCardFileValidation" :message="identityCardFileValidation" :type="'error'" class="mb-5"/>
      <Message v-if="cnpValidation" :message="cnpValidation" :type="'error'" class="mb-5"/>
    </div>

    <PopUpModal
      class="h-full w-full"
      :modal-title="$t('settings.general.domicile_address')"
      :modal-opened="editDomicileAddress"
      @closeModal="closeModal"
    >
      <FullAddress
        :pre-selected-address="address"
        @fullAddress="setDomicileAddress"
      />
    </PopUpModal>
  </div>
</template>

<script>
import StepBar from '../../../layout/StepBar'
import Button from '../../../subcomponents/Button'
import Card from '../../../subcomponents/Card'
import IconMessage from '../../../subcomponents/IconMessage'
import InputText from '../../../subcomponents/InputText'
import Message from '../../../subcomponents/Message'
import {mapActions, mapGetters} from 'vuex'
import FullAddress from '../../../subcomponents/FullAddress'
import FullAddressMixin from '../../../../mixins/FullAddress'
import IdentityCardService from '../../../../services/personal-data/identityCardService'
import Calendar from '../../../subcomponents/Calendar.vue'
import IconBox from '../../../subcomponents/IconBox.vue'
import IconColor from '../../../subcomponents/IconColor.vue'
import PopUpModal from '../../../subcomponents/PopUpModal.vue'
import {romanianPINValidation} from '@/helpers/pinValidation'

export default {
  name: 'IdentityCardForm',
  components: {
    PopUpModal,
    IconColor,
    IconBox,
    Calendar,
    StepBar,
    Button,
    Card,
    IconMessage,
    InputText,
    Message,
    FullAddress
  },
  mixins: [FullAddressMixin],
  props: {
    onboarding: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    editDomicile: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    legalPerson: {
      type: Boolean,
      default: () => {
        return false
      }
    }
  },
  data () {
    return {
      firstName: null,
      lastName: null,
      cnp: null,
      CI: null,
      series: null,
      type: null,
      address: {},
      identity_card: null,
      savedData: false,
      isEdit: false,
      addressEdit: false,
      validation: {
        general: '',
        street: '',
        country: '',
        locality: '',
        expirationDate: '',
        releaseDate: '',
        zipCode: '',
        firstName: '',
        lastName: '',
        identityCardFile: ''
      },
      loading: false,
      preselectedAddress: {},
      precompletedText: '',
      editExpirationDate: false,
      editIdentityType: false,
      editReleaseDate: false,
      releaseDate: '',
      expirationDate: '',
      userData: {},
      types: {},
      nationality: '',
      identityCardObject: {},
      editDomicileAddress: false,
      buttonloading: {
        save: false
      },
      warnings: {
        cnp: ''
      }
    }
  },
  async mounted () {
    await this.setIdentityCardFileValidation(null)

    await this.setData()

    if (this.editDomicile) {
      this.isEdit = true
      this.editAddress = true
    }

    if (this.onboarding && this.address && Object.keys(this.address).length) {
      this.preselectedAddress = this.address
      this.precompletedText = `${this.address.street}`

      if (this.preselectedAddress.number) {
        this.precompletedText += `, ${this.$i18n.t('general.address.number')} ${this.preselectedAddress.number}`
      }
      this.addressEdit = true
    }

    await this.setOnboardingVariable(this.onboarding)
  },
  computed: {
    ...mapGetters({
      generalValidation: 'identityCard/getGeneralValidation',
      seriesValidation: 'identityCard/getSeriesValidation',
      numberValidation: 'identityCard/getNumberValidation',
      expirationDateValidation: 'identityCard/getExpirationDateValidation',
      releaseDateValidation: 'identityCard/getReleaseDateValidation',
      identityCardInfo: 'identityCard/getIdentityCardInfo',
      firstNameValidation: 'identityCard/getFirstNameValidation',
      lastNameValidation: 'identityCard/getLastNameValidation',
      identityCardFileValidation: 'identityCard/getIdentityCardFileValidation',
      expirationReleaseDatesValidation: 'identityCard/getExpirationReleaseDatesValidation',
      screenDevice: 'layout/screenDevice',
      cnpValidation: 'identityCard/getCnpValidation'
    }),
    isMobile () {
      return this.screenDevice === this.$screenDeviceConstants.MOBILE
    }
  },
  methods: {
    ...mapActions({
      getUserAddressData: 'user/getUserAddressData',
      getUserData: 'user/getUserData',
      setIdentityCardInfo: 'identityCard/setIdentityCardInfo',
      setIdentityCardFirstName: 'identityCard/setIdentityCardFirstName',
      setIdentityCardLastName: 'identityCard/setIdentityCardLastName',
      setIdentityCardSeries: 'identityCard/setIdentityCardSeries',
      setIdentityCardCI: 'identityCard/setIdentityCardCI',
      setIdentityCardAddress: 'identityCard/setIdentityCardAddress',
      setIdentityCardExpirationDate: 'identityCard/setIdentityCardExpirationDate',
      setFirstNameValidation: 'identityCard/setFirstNameValidation',
      setLastNameValidation: 'identityCard/setLastNameValidation',
      setGeneralValidation: 'identityCard/setGeneralValidation',
      setSeriesValidation: 'identityCard/setSeriesValidation',
      setNumberValidation: 'identityCard/setNumberValidation',
      setExpirationDateValidation: 'identityCard/setExpirationDateValidation',
      setIdentityCardFileValidation: 'identityCard/setIdentityCardFileValidation',
      setReleaseDateValidation: 'identityCard/setReleaseDateValidation',
      setOnboardingVariable: 'identityCard/setOnboardingVariable',
      setExpirationReleaseDatesValidation: 'identityCard/setExpirationReleaseDatesValidation',
      saveNewIdCard: 'identityCard/saveIdCard',
      validate: 'identityCard/validate',
      changeAddress: 'user/changeAddress',
      fetchUser: 'user/fetch',
      setCnpValidation: 'identityCard/setCnpValidation',
      setIdentityCardCnp: 'identityCard/setIdentityCardCnp'
    }),

    closeModal () {
      this.editDomicileAddress = false
      this.$emit('closeAddressPopup')
    },

    toggleIsEdit () {
      this.isEdit = !this.isEdit

      if (this.isEdit && !this.onboarding) {
        this.series = ''
        this.CI = ''
        this.releaseDate = ''
        this.expirationDate = ''
      }

      this.$emit('edit', this.isEdit)
    },

    choseExpirationDay (day) {
      let date = new Date(day.date)

      this.expirationDate = this.formatDate(date)
    },

    choseReleaseDay (day) {
      let date = new Date(day.date)

      this.releaseDate = this.formatDate(date)
    },

    formatDate (date) {
      if (!date) {
        return ''
      }

      let newDate = new Date(date)
      let month = '' + (newDate.getMonth() + 1)
      let day = '' + newDate.getDate()
      let year = newDate.getFullYear()

      if (month.length < 2) {
        month = '0' + month
      }

      if (day.length < 2) {
        day = '0' + day
      }

      return [day, month, year].join('/')
    },

    formatDateForBackEnd (date) {
      if (!date) {
        return ''
      }

      const tmpDate = date.split('/').reverse().join('/')

      let newDate = new Date(tmpDate)

      let month = '' + (newDate.getMonth() + 1)
      let day = '' + newDate.getDate()
      let year = newDate.getFullYear()

      if (month.length < 2) {
        month = '0' + month
      }

      if (day.length < 2) {
        day = '0' + day
      }

      return [year, month, day].join('-')
    },

    checkIfIdentityCardDataChanges () {
      return this.userData.first_name !== this.firstName ||
        this.userData.last_name !== this.lastName ||
        this.userData.identity_card.series !== this.series ||
        this.userData.identity_card.number !== this.CI ||
        this.formatDate(this.userData.identity_card.expiration_date) !== this.expirationDate ||
        this.formatDate(this.userData.identity_card.release_date) !== this.releaseDate ||
        this.addressChanged()
    },

    validateIdentityCardFile () {
      if (this.onboarding) {
        return true
      }

      if (this.identity_card && typeof this.identity_card === 'object' && this.identity_card.size > 0) {
        return true
      }

      this.setIdentityCardFileValidation(this.$i18n.t('general.validations.enter_identity_card_file'))

      return false
    },
    async setDomicileAddress (address) {
      this.address = address
      this.editDomicileAddress = false
      this.$emit('closeAddressPopup')
    },

    openAddressField () {
      this.editDomicileAddress = true
    },

    async saveData () {
      try {
        this.buttonloading.save = true

        const validation = await this.validate(this.copyIdentityCard())

        if (!validation) {
          return
        }

        if (!this.checkIfIdentityCardDataChanges()) {
          this.isEdit = false
          this.$emit('edit', this.isEdit)

          return
        } else if (!this.validateIdentityCardFile()) {
          return
        }

        let idCard = {
          first_name: this.firstName,
          last_name: this.lastName,
          personal_identification_number: this.cnp,
          address: JSON.stringify(this.address),
          type: this.type,
          nationality: this.nationality,
          number: this.CI,
          expiration_date: this.formatDateForBackEnd(this.expirationDate),
          release_date: this.formatDateForBackEnd(this.releaseDate),
          identity_card: this.identity_card,
          on_boarding: this.onboarding
        }

        if (idCard.type !== 'passport') {
          idCard.series = this.series
        }

        await this.saveNewIdCard(idCard).then((response) => {
          if (response.status === 200 || response.status === 201) {
            this.setData()
            this.setIdentityCardInfo(response.data.data)
            this.isEdit = false
            this.savedData = true
            this.fetchUser()
            this.identity_card = null

            setTimeout(() => {
              this.savedData = false
            }, 3000)
          }
        }).catch((response) => {
          if (response.response.status === 500) {
            this.validation = this.$i18n.t('general.validations.internal_error')
          }
        })

        this.buttonloading.save = false
      } finally {
        this.buttonloading.save = false
      }
    },

    async setData () {
      this.validation.general = ''
      let userDataDb = await this.getUserData()

      if (userDataDb.status === 200) {
        this.userData = userDataDb.data.data.user
        this.firstName = this.userData.first_name
        this.lastName = this.userData.last_name
        this.email = this.userData.email
        this.telephone = this.userData.phone

        if (this.userData.identity_card) {
          this.cnp = this.userData.identity_card.personal_identification_number
          this.CI = this.userData.identity_card.number
          this.series = this.userData.identity_card.series
          this.expirationDate = this.formatDate(this.userData.identity_card.expiration_date)
          this.releaseDate = this.formatDate(this.userData.identity_card.release_date)
          this.nationality = this.userData.identity_card.nationality
          this.type = this.userData.identity_card.type
          this.address = this.userData.identity_card.address ?? {}
        }

        if (this.onboarding && this.userData.domicile.code === 'RO' && !romanianPINValidation(this.cnp)) {
          this.warnings.cnp = this.$i18n.t('onboarding.legal_person_details.warning_pin_validation')
        }

        this.loading = false

        await this.setIdentityCardInfo(this.copyIdentityCard())
      }

      await IdentityCardService.getTypes().then((response) => {
        if (response.status === 200 && response.data.data) {
          this.types = response.data.data
        }
      })
    },

    copyIdentityCard () {
      let identityCard = {}

      identityCard.firstName = this.firstName
      identityCard.lastName = this.lastName
      identityCard.CI = this.CI
      identityCard.series = this.series
      identityCard.cnp = this.cnp
      identityCard.releaseDate = this.releaseDate
      identityCard.expirationDate = this.expirationDate
      identityCard.address = this.address
      identityCard.nationality = this.nationality
      identityCard.type = this.type

      return identityCard
    },

    handleFileUpload (event) {
      this.identity_card = event.target.files[0]
      this.validation.general = ''
    },

    addressChanged () {
      if (!this.userData.domicile_address) {
        return true
      }

      return this.address.street !== this.userData.domicile_address.street ||
        this.address.number !== this.userData.domicile_address.number ||
        this.address.building !== this.userData.domicile_address.building ||
        this.address.floor !== this.userData.domicile_address.floor ||
        this.address.apartment !== this.userData.domicile_address.apartment ||
        this.address.staircase !== this.userData.domicile_address.staircase ||
        this.address.zip_code !== this.userData.domicile_address.zip_code ||
        this.address.country !== this.userData.domicile_address.country ||
        this.address.county !== this.userData.domicile_address.county ||
        this.address.locality !== this.userData.domicile_address.locality
    }
  },
  watch: {
    editDomicile () {
      if (this.editDomicile) {
        this.isEdit = true
        this.editDomicileAddress = true
      }
    },

    firstName (nVal) {
      this.setIdentityCardFirstName(nVal)

      if (nVal) {
        this.setFirstNameValidation('')
      }
    },
    lastName (nVal) {
      this.setIdentityCardLastName(nVal)

      if (nVal) {
        this.setLastNameValidation('')
      }
    },
    series (nVal) {
      this.setIdentityCardSeries(nVal)

      if (nVal) {
        this.setSeriesValidation('')
      }
    },
    CI (nVal) {
      this.setIdentityCardCI(nVal)

      if (nVal) {
        this.setNumberValidation('')
      }
    },
    expirationDate (nVal) {
      this.setIdentityCardExpirationDate(nVal)

      if (nVal) {
        this.editReleaseDate = false
        this.editExpirationDate = false
        this.setExpirationDateValidation('')
        this.setExpirationReleaseDatesValidation('')
      }
    },
    releaseDate (releaseDate) {
      if (releaseDate) {
        this.editReleaseDate = false
        this.editExpirationDate = false
        this.setReleaseDateValidation('')
        this.setExpirationReleaseDatesValidation('')
      }
    },
    identity_card (nVal) {
      if (nVal && typeof nVal === 'object' && nVal.size > 0) {
        this.setIdentityCardFileValidation('')
      }
    },
    identityCardObject: {
      handler (identityCardObject) {
        this.setIdentityCardInfo(identityCardObject)
      },
      deep: true
    },
    address: {
      handler (address) {
        this.setIdentityCardAddress(address)

        if (address) {
          this.setGeneralValidation('')
        }
      },
      deep: true
    },
    cnp: {
      handler (cnp) {
        this.setIdentityCardCnp(cnp)
        if (cnp) {
          this.setCnpValidation('')
        }
      },
      deep: true
    }
  }
}
</script>

<style scoped>

</style>
