const getDefaultState = () => {
  return {
    pies: {},
    pieId: null,
    newPie: {},
    addedStockSecurities: [],
    deletedStockSecurities: []
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    resetState (state) {
      Object.assign(state, getDefaultState())
    },
    setPieId (state, pieId) {
      state.pieId = pieId
    },
    setNewPie (state, newPie) {
      state.newPie = newPie
    },
    setAddedStockSecurities (state, addedStockSecurities) {
      state.addedStockSecurities = addedStockSecurities
    },
    setDeletedStockSecurities (state, deletedStockSecurities) {
      state.deletedStockSecurities = deletedStockSecurities
    },
    setPies (state, pies) {
      state.pies = pies
    }
  },
  getters: {
    pieId ({pieId}) {
      return pieId
    },
    newPie ({newPie}) {
      return newPie
    },
    addedStockSecurities ({addedStockSecurities}) {
      return addedStockSecurities
    },
    deletedStockSecurities ({deletedStockSecurities}) {
      return deletedStockSecurities
    },
    pies ({pies}) {
      return pies
    }
  },
  actions: {
    resetState (ctx) {
      ctx.commit('resetState')
    },
    setPieId (ctx, pieId) {
      ctx.commit('setPieId', pieId)
    },
    setNewPie (ctx, newPie) {
      ctx.commit('setNewPie', newPie)
    },
    setAddedStockSecurities (ctx, addedStockSecurities) {
      ctx.commit('setAddedStockSecurities', addedStockSecurities)
    },
    setDeletedStockSecurities (ctx, deletedStockSecurities) {
      ctx.commit('setDeletedStockSecurities', deletedStockSecurities)
    },
    setPies (ctx, pies) {
      ctx.commit('setPies', pies)
    }
  }
}
