import ApiService from '../apiService'

class NotificationsService {
  async getAgreements () {
    return ApiService.withAuth().get('/api/user/agreements')
  }
  async getAgreementType ($type) {
    return ApiService.withAuth().get('/api/user/agreements/types/' + $type)
  }
  async setAgreement ($value, $payload) {
    return ApiService.withAuth().put('/api/user/agreements/types/' + $value, $payload)
  }
  async getAllNotifications ($page, $payload) {
    return ApiService.withAuth().get('/api/user/notifications?page=' + $page, { params: $payload })
  }
  async setNotificationStatus ($id, $payload) {
    return ApiService.withAuth().put('/api/user/notifications/' + $id, $payload)
  }
  getUnreadNotifications (payload) {
    return ApiService.withAuth().get('/api/user/notifications/unread', { params: payload })
  }
  setAllNotificationsRead (type) {
    return ApiService.withAuth().post('/api/user/notifications/read-all-notifications', { type: type })
  }
}

export default new NotificationsService()
