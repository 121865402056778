<template>
  <div>
    <div v-for="(question, questionIndex) in questions" :key="questionIndex">
      <div v-if="questionIndex === selectedQuestion">
        <div class="flex justify-between" v-if="(question.type === 'button' || question.type === 'radio')">
          <ButtonQuestion
            v-if="(question.type === 'button' || question.type === 'radio') && !skipQuestions.includes(question.identifier)"
            :parent="'update-data'"
            :pre-selected-answer="tmpAnswer"
            :question="question"
            :questions-length="questions.length"
            class="w-full"
            @toggleDisabledQuestion="toggleDisabledQuestion"
            @setAnswers="setAnswers"/>
        </div>

        <div v-if="question.type === 'selector'" class="flex justify-between">
          <SelectorQuestion v-if="question.type === 'selector' && !skipQuestions.includes(question.identifier)"
                            :parent="'update-data'"
                            :pre-selected-answer="tmpAnswer"
                            :question="question"
                            :questions-length="questions.length"
                            class="w-full"
                            @toggleDisabledQuestion="toggleDisabledQuestion"
                            @setAnswers="setAnswers"/>
        </div>

        <div v-if="question.type === 'group'" class="flex justify-between">
          <MultipleQuestion v-if="question.type === 'group' && !skipQuestions.includes(question.identifier)"
                            :parent="'update-data'"
                            :identifier="question.identifier"
                            :questions="question.subquestions"
                            class="w-full"
                            :questions-answers="tmpAnswer"
                            :details-error="validation.details"
                            @toggleDisabledQuestion="toggleDisabledQuestion"
                            @setAnswers="setAnswers"/>
        </div>

        <div v-if="question.type === 'checkbox'" class="flex justify-between">
          <CheckboxQuestion v-if="question.type === 'checkbox' && !skipQuestions.includes(question.identifier)"
                            :parent="'update-data'"
                            :pre-selected-answers="tmpAnswer"
                            :question="question"
                            :questions-length="questions.length"
                            :restrictions="restrictions[question.identifier]"
                            class="w-full"
                            @toggleDisabledQuestion="toggleDisabledQuestion"
                            @setAnswers="setAnswers"/>
        </div>
      </div>
    </div>

    <Message v-if="validation.general" :message="validation.general" :type="'error'" class="mb-5"/>

    <Message v-if="validation.details" :message="validation.details" :type="'error'" class="mb-5" :formatIsHtml="true"/>

    <div class="nav m-top-custom-l flex justify-between">
      <Button :icon="'icons/arrow_icon_back'"
              :icon-position="'left'"
              :text="$t('general.questionnaire.back')"
              :type="'btn btn-secondary'"
              class="prev"
              @call="back"/>

      <Button :disabled="false"
              :icon="'icons/arrow_icon'"
              :icon-position="'right'"
              :text="(questions.length === selectedQuestion + 1) ? $t('general.questionnaire.finish') : $t('general.questionnaire.next')"
              :type="'btn btn-primary end-position'"
              class="next"
              @call="next"/>
    </div>
  </div>
</template>

<script>
import PulseLoader from 'vue-spinner/src/PulseLoader'
import {mapActions, mapGetters} from 'vuex'
import ButtonQuestion from '../../../questionnaire/question-types/ButtonQuestion.vue'
import SelectorQuestion from '../../../questionnaire/question-types/SelectorQuestion.vue'
import MultipleQuestion from '../../../questionnaire/question-types/MultipleQuestion.vue'
import CheckboxQuestion from '../../../questionnaire/question-types/CheckboxQuestion.vue'
import Message from '../../../subcomponents/Message.vue'
import Main from '../../../questionnaire/Main.vue'
import Card from '../../../subcomponents/Card.vue'
import StepBar from '../../../layout/StepBar.vue'
import Button from '../../../subcomponents/Button.vue'

export default {
  name: 'QuestionnaireMain',
  components: {
    ButtonQuestion,
    SelectorQuestion,
    MultipleQuestion,
    CheckboxQuestion,
    Message,
    Main,
    Card,
    StepBar,
    PulseLoader,
    Button
  },
  props: {
    questions: {
      type: Array,
      required: true
    },
    questionsAnswers: {
      type: Object,
      default: () => {
        return {}
      }
    },
    selectedQuestionProp: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      loaded: false,
      selectedQuestion: 0,
      validation: {
        general: '',
        details: ''
      },
      tmpIdentifier: '',
      tmpAnswer: {},
      restrictions: {},
      skipQuestions: [],
      disabledQuestion: true,
      allAnswers: {}
    }
  },
  computed: {
    ...mapGetters({
      updatedData: 'updatePersonalData/getUpdatedData',
      temporaryStepQuestionnaire: 'updatePersonalData/getTemporaryStepQuestionnaire'
    }),
    progressBarLength () {
      return 'width:' + this.progressBar + '%'
    },
    progressBar () {
      return Math.round(
        ((this.selectedQuestion + 1) * 100) / this.questions.length
      )
    }
  },
  async beforeMount () {
    this.allAnswers = Object.assign(this.questionsAnswers, this.temporaryStepQuestionnaire)

    if (this.questionsAnswers && this.questions && this.questions[this.selectedQuestion]) {
      this.tmpAnswer = this.questionsAnswers[this.questions[this.selectedQuestion].identifier]
    }

    if (this.tmpAnswer === 'N/A') {
      this.tmpAnswer = {}
    }

    this.selectedQuestion = this.selectedQuestionProp
  },
  methods: {
    ...mapActions({
      setTemporaryStepQuestionnaire: 'updatePersonalData/setTemporaryStepQuestionnaire'
    }),
    toggleDisabledQuestion (disabledQuestion) {
      this.disabledQuestion = disabledQuestion
    },
    resetValidation () {
      this.validation = {
        general: '',
        details: ''
      }
    },
    setAnswers (identifier, answers) {
      if (this.tmpAnswer === 'N/A') {
        this.tmpAnswer = {}
      }

      if (!this.disabledQuestion) {
        this.tmpAnswer = answers
        this.tmpIdentifier = identifier

        this.resetValidation()

        let temporaryData = {}

        temporaryData[identifier] = answers

        this.setTemporaryStepQuestionnaire(temporaryData)

        this.allAnswers[identifier] = answers
      }

      this.updateRestrictionsAndSkipQuestions()
    },
    updateRestrictionsAndSkipQuestions () {
      this.restrictions = {}
      this.skipQuestions = []

      for (const [questionIdentifier, answers] of Object.entries(this.allAnswers)) {
        let question = this.questions.filter((question) => question.identifier === questionIdentifier)[0]

        if (question.type !== 'group') {
          for (const answerIdentifier of Object.keys(answers)) {
            let answerFromQuestion = question.answers.filter((questionAnswer) => questionAnswer.identifier === answerIdentifier)[0]

            if (!answerFromQuestion) {
              continue
            }

            if (answerFromQuestion.restrictions) {
              this.addRestrictions(answerFromQuestion.restrictions)
            }

            if (answerFromQuestion.skipQuestions) {
              this.skipQuestions = this.skipQuestions.concat(answerFromQuestion.skipQuestions)
            }
          }
        }
      }
    },
    addRestrictions (restrictions) {
      for (const [questionIdentifier, answerRestrictions] of Object.entries(restrictions)) {
        this.$set(this.restrictions, questionIdentifier, answerRestrictions)
      }
    },
    validate () {
      if (!this.tmpAnswer) {
        this.validation.general = this.$i18n.t('general.validations.choose_answer')

        return false
      }

      if (this.tmpIdentifier !== '' && this.temporaryStepQuestionnaire[this.tmpIdentifier] !== 'N/A') {
        let question = this.questions.filter((question) => question.identifier === this.tmpIdentifier)[0]
        let tmpAnswer = this.tmpAnswer
        if (question.type === 'group') {
          if (Object.keys(this.tmpAnswer).length) {
            let questionIndex

            [questionIndex, tmpAnswer] = Object.entries(this.tmpAnswer)[0]
            question = question.subquestions.filter((subquestion) => subquestion.identifier === questionIndex)[0]
          }
        }

        if (!tmpAnswer || !Object.keys(tmpAnswer).length) {
          this.validation.general = this.$i18n.t('general.validations.choose_answer')

          return false
        }

        for (const [answerIdentifier, details] of Object.entries(tmpAnswer)) {
          let answer = question.answers.filter(answer => answer.identifier === answerIdentifier)[0]

          if (!answer.requiredDetails) {
            continue
          }

          let answerType = question.answers.find(ans => ans.identifier === Object.keys(this.tmpAnswer)[0])

          if (answerType && answerType.details && answerType.details[0].type === 'addressComponent') {
            if (Object.values(details)[0] === '') {
              this.validation.details = this.$i18n.t('general.validations.address_field')

              return false
            } else {
              let fullAddress = Object.values(details)[0]

              if (fullAddress.country === '' || (!fullAddress.county && fullAddress.country_code === 'RO') ||
                !fullAddress.locality || !fullAddress.street) {
                this.validation.details = `<div>${this.$i18n.t('general.validations.address_partial_error')}<ul class="custom-ul">`

                if (fullAddress.country === '') {
                  this.validation.details += `<li>${this.$i18n.t('general.validations.address_fields.country')}</li>`
                }

                if (!fullAddress.county && fullAddress.country_code === 'RO') {
                  this.validation.details += `<li>${this.$i18n.t('general.validations.address_fields.county')}</li>`
                }

                if (!fullAddress.locality) {
                  this.validation.details += `<li>${this.$i18n.t('general.validations.address_fields.locality')}</li>`
                }

                if (!fullAddress.street) {
                  this.validation.details += `<li>${this.$i18n.t('general.validations.address_fields.street')}</li>`
                }

                this.validation.details += `</ul></div>`

                return false
              }
            }
          }

          if (details && !Object.values(details).length) {
            this.validation.details = this.$i18n.t('general.validations.complete_field')

            return false
          }

          for (const detail of Object.values(details)) {
            if (!detail) {
              this.validation.details = this.$i18n.t('general.validations.complete_field')

              return false
            }
          }
        }
      }

      return true
    },
    next () {
      if (this.selectedQuestion === this.questions.length - 1) {
        this.$emit('finish')

        return
      }

      if (!this.validate()) {
        return
      }

      if (this.skipQuestion(this.selectedQuestion + 1)) {
        this.next()

        return
      }

      this.tmpIdentifier = this.questions[this.selectedQuestion + 1].identifier
      this.tmpAnswer = this.questionsAnswers[this.questions[this.selectedQuestion + 1].identifier]

      if (this.temporaryStepQuestionnaire[this.questions[this.selectedQuestion + 1].identifier] && this.temporaryStepQuestionnaire[this.questions[this.selectedQuestion + 1].identifier] !== this.tmpAnswer) {
        this.tmpAnswer = this.temporaryStepQuestionnaire[this.questions[this.selectedQuestion + 1].identifier]
      }

      if (this.tmpAnswer === 'N/A') {
        this.tmpAnswer = {}
      }

      this.disabledQuestion = this.tmpIdentifier.substring(1) < this.questions.length - 1

      this.resetValidation()

      this.selectedQuestion++
      this.$emit('next')
    },
    skipQuestion (selectedQuestion) {
      if (!this.questions[selectedQuestion]) {
        return false
      }

      if (!this.skipQuestions.includes(this.questions[selectedQuestion].identifier)) {
        return false
      }

      this.setAnswers(this.questions[selectedQuestion].identifier, 'N/A')
      this.selectedQuestion = selectedQuestion

      return true
    },
    back () {
      if (this.selectedQuestion === 0) {
        this.$emit('showIntro')

        return
      }

      this.disabledQuestion = this.tmpIdentifier.substring(1) < this.questions.length - 1

      if (this.skipQuestion(this.selectedQuestion - 1)) {
        this.back()

        return
      }

      this.tmpAnswer = this.questionsAnswers[this.questions[this.selectedQuestion - 1].identifier]
      this.tmpIdentifier = this.questions[this.selectedQuestion - 1].identifier

      if (this.temporaryStepQuestionnaire[this.questions[this.selectedQuestion - 1].identifier] && this.temporaryStepQuestionnaire[this.questions[this.selectedQuestion - 1].identifier] !== this.tmpAnswer) {
        this.tmpAnswer = this.temporaryStepQuestionnaire[this.questions[this.selectedQuestion - 1].identifier]
      }

      if (this.tmpAnswer === 'N/A') {
        this.tmpAnswer = {}
      }

      this.resetValidation()
      this.selectedQuestion--
      this.$emit('back')
    }
  }
}
</script>
