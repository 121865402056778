export default {
  general: {
    marketing_agreement: 'Vreau să primesc comunicări de marketing conform politicii de confidențialitate',
    transactions_notifications_agreement: 'Vreau să fiu notificat pe email atunci când am realizat tranzacții',
    privacy_policy_agreement: 'Sunt de acord să primesc mesaje de marketing conform politicii de confidențialitate'
  },
  notification: {
    read: 'Citit',
    unread: 'Necitit',
    all_read_unread: 'Toate (Citit/Necitit)',
    all_notifications: 'Toate (Alerta/Notificare)',
    search_notification: 'Caută notificare',
    notification: 'Notificare',
    alert: 'Alertă',
    request_processing: 'Cerearea ta de reabonare este în curs de procesare.',
    mark_all_as_read: 'Marchează toate ca citite'
  }
}
