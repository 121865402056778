import ApiService from '../apiService'
import CookieService from '../../services/auth/cookieService'
import router from '../../router'
import {store} from '@/store'

export const USER_STATUS_NEW = 'new'
export const USER_STATUS_APPROVED = 'approved'
export const USER_STATUS_REJECTED = 'rejected'
export const USER_STATUS_PENDING = 'pending'

class UserService {
  login (username, password, device = null) {
    return ApiService.withoutAuth().post('/api/login', {
      username,
      password,
      device
    })
  }

  loginFromJwt (jwt) {
    return ApiService.withoutAuth().post('/api/login/jwt', {
      jwt
    })
  }

  logout () {
    return ApiService.withAuth().post('/api/logout').then((logout) => {
      if (logout.status === 200) {
        window.clearStorage()
        localStorage.clear()
        CookieService.userHasLoggedOut()
      }

      return logout
    })
  }

  resetPassword (payload) {
    return ApiService.withAuth().post('/api/user/password/reset', {
      oldPassword: payload.old_password,
      newPassword: payload.new_password
    })
  }

  forceResetPassword (payload) {
    return ApiService.withAuth().post('/api/user/password/force-reset', {
      password: payload.password
    })
  }

  verifyCode (payload) {
    return ApiService.withAuth().post('/api/user/email/verify', {
      email_verify_code: payload.verificationCode,
      hubspot_cookie: payload.hubspotCookie
    })
  }

  register (payload) {
    return ApiService.withoutAuth().post('/api/user', payload)
  }

  resendCode (payload) {
    return ApiService.withAuth().post('/api/user/email/resend')
  }

  challenge (payload) {
    return ApiService.withAuth().post('/api/user/two-factor/challenge', payload)
  }

  getQrCode () {
    return ApiService.withAuth().get('/api/user/two-factor/qr')
  }

  createQR () {
    return ApiService.withAuth().post('/api/user/two-factor/qr')
  }

  confirm (payload) {
    return ApiService.withAuth().post('/api/user/two-factor/confirm', payload)
  }

  enableTwoFactor (payload) {
    return ApiService.withAuth().post('/api/user/two-factor/authentication', payload)
  }

  confirmPassword (payload) {
    return ApiService.withAuth().post('/api/user/password/confirm', payload)
  }

  disableTwoFactor () {
    return ApiService.withAuth().delete('/api/user/two-factor/authentication')
  }
  verifySmsCode (payload) {
    return ApiService.withAuth().post('/api/user/two-factor/verify-sms-two-factor', payload)
  }

  generateSmscode () {
    return ApiService.withAuth().get('/api/user/two-factor/generate-sms-two-factor')
  }

  getUserData () {
    return ApiService.withAuth().get('/api/user').catch((error) => {
      if (error.response.status === 403 && error.response.data.data.type === 'force-reset-password') {
        return error.response
      }

      if (error.response.status === 401) {
        window.clearStorage()
        router.push({ name: 'login' }).catch(() => { })
      }
    })
  }

  getUserAddressData (payload) {
    return ApiService.withAuth().get('/api/user/addresses', payload)
  }

  async regenerateMiddlewareJwt (payload) {
    const regenerateJwtData = await ApiService.withAuth().post('/api/middleware/regenerate-jwt', payload)

    if (regenerateJwtData.status === 200) {
      store.commit('user/setJwt', regenerateJwtData.data.data.jwt)
    }
  }

  sendRecoveryLink (payload) {
    return ApiService.withoutAuth().post('/api/password/recovery/send-link', {
      email: payload.email
    })
  }

  recoverPassword (payload) {
    return ApiService.withoutAuth().post('/api/password/recovery/reset', {
      password: payload.password,
      token: payload.token
    })
  }

  generateJwt () {
    return ApiService.withAuth().post('/api/jwt/generate')
  }

  generateDxToken () {
    return ApiService.withAuth().post('/api/dx/generate')
  }

  generateMiddlewareToken (token) {
    return ApiService.withAuth().post('/api/middleware/generate', {
      jwt: token
    })
  }
  save (payload) {
    return ApiService.withAuth().put('/api/user', payload)
  }

  getLmsUrl (user) {
    return ApiService.withAuth().get('/api/login/generate-lms-url', user)
  }

  generateReferralCode (channelMessage) {
    return ApiService.withAuth().get('/api/user/referral-code', {params: {channel: channelMessage}})
  }
}

export default new UserService()
