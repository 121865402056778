<template>
  <div
    :class="type === 'success' ? 'border-green-400 text-green-700 bg-green-100' : '' || type === 'error' ? 'bg-red-100 border-red-400 text-red-700' : ''
    || type === 'info' ? 'bg-zinc-100 bg-zinc-400 text-gray-800' : '' || type === 'warning' ? 'bg-yellow-100 border-yellow-400 text-yellow-700' : ''"
    class="mt-5 border w-full px-4 py-3 rounded relative"
    role="alert">
    <div v-if="showClose">
      <img
        class="ml-auto mr-0 mb-1 h-3.5 cursor-pointer"
        :src="require('@/assets/img/icons/close.svg')"
        alt="close"
        @click="$emit('close')"
      >
    </div>
    <template v-if="Array.isArray(message)">
      <template v-for="(oneMessage ,index) in message">
        <span :key="index" class="block">{{ oneMessage }}</span>
      </template>
    </template>
    <template v-else>
      <div v-if="formatIsHtml" class="block sm:inline" v-html="message"></div>
      <span v-else class="block sm:inline">{{ message }}</span>
    </template>
  </div>
</template>

<script>
import {mapActions} from 'vuex'

export default {
  name: 'Message',
  props: {
    message: {
      type: [String, Array],
      required: true
    },
    type: {
      type: String,
      required: true
    },
    formatIsHtml: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    showClose: {
      type: Boolean,
      default: () => {
        return false
      }
    }
  },
  methods: {
    ...mapActions({
      logout: 'user/logout'
    }),
    async redirectFromHref () {
      await this.logout()
      await this.$router.push({name: 'password-recovery-send-link'})
    }
  },
  mounted () {
    let redirectToPasswordRecoveryLink = document.getElementById('redirect-to-password-recovery-link')

    if (redirectToPasswordRecoveryLink) {
      redirectToPasswordRecoveryLink.addEventListener('click', this.redirectFromHref)
    }
  }
}
</script>

<style scoped>

</style>
