export default {
  methods: {
    getLongLabelForAddress (address) {
      if (!address) return ''
      return (address.locality ? address.locality : '') + ', ' +
        address.street + (address.number ? ', ' + this.$i18n.t('general.address.number') + ' ' + address.number : '') +
        (address.building ? ', ' + this.$i18n.t('general.address.building_short') + ' ' + address.building : '') +
        (address.staircase ? ', ' + this.$i18n.t('general.address.entrance_short') + ' ' + address.staircase : '') +
        (address.floor ? ', ' + this.$i18n.t('general.address.floor_short') + ' ' + address.floor : '') +
        (address.apartment ? ', ' + this.$i18n.t('general.address.flat_short') + ' ' + address.apartment : '') +
        (address.zip_code ? ', ' + this.$i18n.t('general.address.zip_code') + ' ' + address.zip_code : '')
    }
  }
}
