<template>
  <div class="security">
    <general-layout :title-classes="'mb-0-important pt-0-important'" :general-layout-classes="'mb-0-important pt-0-important'">
      <template v-slot:title>
        <vue-title :title="$t('general.meta_titles.password_reset')"></vue-title>
      </template>

      <template v-slot:tabs>
        <Tabs :show-id-title="false" :tabs-classes="tabsClasses"/>
      </template>

      <template v-slot:body>
        <div>
          <div class="m-auto w-fit">
            <Card class="w-full" :class="{'!p-12': !isMobile}" :card-classes="'mobile-card-portal password-reset'">
              <template v-slot:body>
                <div class="password-reset-component mt-6">
                  <InputText
                    v-model="password"
                    id="password_new"
                    :grey-label="true"
                    :type-input="'password'"
                    :label="$t('auth.reset_password.new_password')"
                  />

                  <span class="color-light-gray text-sm">{{ $t('general.general.password_rules') }}</span>

                  <div v-for="validation in validation.password" :key="validation">
                    <Message :formatIsHtml="true" :message="validation" :type="'error'" class="mb-5"/>
                  </div>

                  <InputText
                    class="mb-8 mt-5"
                    v-model="repeatPassword"
                    id="password_repeat"
                    :grey-label="true"
                    :type-input="'password'"
                    :label="$t('auth.reset_password.repeat_new_password')"
                    @focusout="focusOut(password, repeatPassword)"/>

                  <Message
                    :type="'error'"
                    v-if="validation.repeatPassword"
                    :message="validation.repeatPassword"
                    class="mb-5"
                  />

                  <Message
                    v-if="validation.passwordMatch"
                    :message="validation.passwordMatch"
                    :type="'error'"
                    class="mb-5"
                  />

                  <Message
                    :type="'success'"
                    v-if="validation.general.success"
                    :message="validation.general.success"
                    class="mb-5"
                  />

                  <Message
                    :type="'error'"
                    v-if="validation.general.error"
                    :message="validation.general.error"
                    class="mb-5"
                  />

                  <Button
                    @call="passwordReset"
                    :extra-classes="'!px-8 !py-3'"
                    :loading="loadingData.save"
                    :text="$t('auth.general.save')"
                    :type="'btn-primary'"
                  />
                </div>
              </template>
            </Card>
          </div>
        </div>
      </template>
    </general-layout>
  </div>
</template>

<script>
import GeneralLayout from '../../layout/GeneralLayout'
import Button from '../../subcomponents/Button'
import {mapActions, mapGetters} from 'vuex'
import Message from '../../subcomponents/Message'
import Tabs from '../../layout/Tabs'
import InputText from '../../subcomponents/InputText'
import Card from '../../subcomponents/Card'
import IconBox from '../../subcomponents/IconBox.vue'
import PasswordValidations from '../../../mixins/PasswordValidations'
import TwoFactorAuthenticationActivation from '../../profile/TwoFactorAuthenticationActivation.vue'
import Resources from '../../subcomponents/Resources.vue'
import {FORCE_TWO_FACTOR_AUTHENTICATION_ACTIVATION} from '@/router'

export default {
  name: 'ForceResetPassword',
  components: {
    Resources,
    TwoFactorAuthenticationActivation,
    GeneralLayout,
    Button,
    Message,
    Tabs,
    InputText,
    Card,
    IconBox
  },
  mixins: [ PasswordValidations ],
  data () {
    return {
      loadingData: {
        save: false
      },
      password: '',
      repeatPassword: '',
      validation: {
        password: [],
        repeatPassword: '',
        passwordMatch: '',
        general: {
          success: '',
          error: ''
        }
      },
      tabsClasses: []
    }
  },
  methods: {
    ...mapActions({
      forceResetPassword: 'user/forceResetPassword'
    }),
    async passwordReset () {
      try {
        this.loadingData.save = true
        if (!this.validate()) {
          return
        }

        await this.forceResetPassword({
          password: this.password
        }).then(response => {
          if (response.status === 200) {
            this.validation.general.success = this.$i18n.t('auth.reset_password.reset_successfully')
            this.password = ''
            this.repeatPassword = ''

            setTimeout(() => {
              this.validation.general.success = ''
            }, 5000)
          }
        }).catch(response => {
          if (response.response.status === 401) {
            if (response.response.data.data.hasOwnProperty('reset') && response.response.data.data.reset === false) {
              this.validation.general.error = response.response.data.message
            }

            setTimeout(() => {
              this.validation.general.error = ''
            }, 5000)
          }
        })
      } finally {
        this.loadingData.save = false
      }

      window.location.href = this.$router.getRoutes().find((route) => route.name === FORCE_TWO_FACTOR_AUTHENTICATION_ACTIVATION).path
    },
    validate () {
      this.validation.password = []
      let valid = true

      if (!this.validatePassword(this.password, this.repeatPassword)) {
        valid = false
      }

      return valid
    }
  },
  computed: {
    ...mapGetters({
      screenDevice: 'layout/screenDevice'
    }),
    isMobile () {
      return this.screenDevice === this.$screenDeviceConstants.MOBILE
    }
  },
  watch: {
    password (nVal) {
      if (nVal) {
        this.validation.password = []
        this.validation.general.error = ''
        this.validation.passwordMatch = ''
      }
    },
    repeatPassword (nVal) {
      if (nVal) {
        this.validation.repeatPassword = ''
        this.validation.general.error = ''
      }
    }
  }
}
</script>

<style scoped></style>
