<template>
  <div>
    <div class="flex relative items-center justify-between">
      <div class="mr-5">
        {{ question.label }}
      </div>
      <div class="flex items-center">
        <div v-if="question.explanation" class="w-4">
          <div class="tooltip-wrapper">
            <img alt="my-account" src="~@/assets/img/icons/info.svg">
            <div class="tooltip" v-html="question.explanation"/>
          </div>
        </div>
        <img
          v-if="editableQuestion"
          class="mb-2 ml-3 h-fit cursor-pointer w-6 max-w-none"
          alt="edit"
          src="~@/assets/img/icons/edit-icon.svg"
          @click="toggleDisabledQuestion">
      </div>
    </div>

    <div :key="componentKey" class="mt-4" :class="{'pointer-events-none disabled disabled-transparent': disabledQuestion}">
      <template v-for="answer in question.answers">
        <div v-if="answer && answer.identifier" :key="answer.identifier" :class="{'opacity-50 pointer-events-none': restrictions.includes(answer.identifier)}" class="mt-4">
          <div :key="answer.identifier">
            <div class="checkbox" @click="setAnswer(answer)">
              <div :class="{'checked': hasAnswer(answer)}" class="custom-checkbox"></div>
              <input :id="answer.identifier" :name="answer.id" :value="hasAnswer(answer)" class="checkbox-primary"
                     type="checkbox">
              <label :for="answer.id" class="ml-2 cursor-pointer" v-html="answer.label"></label>
            </div>

            <Details v-if="answer.requiredDetails && hasAnswer(answer)"
                     :answer-identifier="answer.identifier"
                     :details="answer.details"
                     :pre-selected-answer-details="getDetailsAnswers(answer)"
                     class="mt-4"
                     @setDetailsAnswers="setDetailsAnswers"/>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import Checkbox from '../../subcomponents/Checkbox'
import Details from '../Details'
import {ref} from 'vue'

export default {
  name: 'CheckboxQuestion',
  components: {Details, Checkbox},
  props: {
    parent: {
      type: String,
      default: 'onboarding'
    },
    question: {
      type: Object,
      required: true
    },
    preSelectedAnswers: {
      type: Object,
      default: () => {
        return {}
      }
    },
    restrictions: {
      type: Array,
      default: () => {
        return []
      }
    },
    questionsLength: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      selectedAnswers: {},
      detailsAnswers: {},
      componentKey: ref(0),
      disabledQuestion: true
    }
  },
  computed: {
    editableQuestion () {
      return this.parent === 'update-data' && this.question.identifier.substring(1) < this.questionsLength - 1
    }
  },
  beforeMount () {
    if (Object.keys(this.preSelectedAnswers).length === 0) {
      this.$emit('setAnswers', this.question.identifier, {})
      return
    }
    this.selectedAnswers = Object.assign({}, this.preSelectedAnswers)

    for (const selectedAnswer in this.selectedAnswers) {
      if (this.restrictions.includes(selectedAnswer)) {
        this.$delete(this.selectedAnswers, selectedAnswer)
      }

      this.$set(this.detailsAnswers, selectedAnswer, this.selectedAnswers[selectedAnswer])
    }
  },

  mounted () {
    this.disabledQuestion = this.parent === 'update-data' && this.question.identifier.substring(1) < this.questionsLength - 1
  },

  methods: {
    async forceRerender () {
      this.componentKey++
    },
    getDetailsAnswers (answer) {
      if (typeof this.detailsAnswers[answer.identifier] === 'undefined') {
        return {}
      }

      return this.detailsAnswers[answer.identifier]
    },
    hasAnswer (answer) {
      return typeof this.selectedAnswers[answer.identifier] !== 'undefined'
    },
    hasRemoveAnswers (answer) {
      return !!answer.removeAnswers
    },
    deleteAnswersBasedOnProperty (hasProperty) {
      let identifiers = this.question.answers.filter(ans => hasProperty ? ans.removeAnswers : !ans.hasOwnProperty('removeAnswers'))

      if (!identifiers.length) {
        return
      }

      for (let identifier of identifiers) {
        this.$delete(this.selectedAnswers, identifier.identifier)
      }
    },
    setAnswer (answer) {
      if (this.hasAnswer(answer)) {
        this.$delete(this.selectedAnswers, answer.identifier)
        this.forceRerender()
        this.$emit('setAnswers', this.question.identifier, this.buildAnswer())
        return
      }

      this.deleteAnswersBasedOnProperty(!this.hasRemoveAnswers(answer))
      this.$set(this.selectedAnswers, answer.identifier, this.getDetailsAnswers(answer))
      this.$emit('setAnswers', this.question.identifier, this.buildAnswer())
    },
    setDetailsAnswers (detailsAnswers, answerIdentifier) {
      this.$set(this.selectedAnswers, answerIdentifier, detailsAnswers)
      this.$set(this.detailsAnswers, answerIdentifier, detailsAnswers)
      this.$emit('setAnswers', this.question.identifier, this.buildAnswer())
    },
    buildAnswer () {
      let finalAnswer = this.selectedAnswers

      if (this.selectedAnswers) {
        for (const [answerIndex, details] of Object.entries(this.detailsAnswers)) {
          if (this.selectedAnswers[answerIndex]) {
            finalAnswer[answerIndex] = details
          }
        }
      }

      return finalAnswer
    },
    toggleDisabledQuestion () {
      this.disabledQuestion = !this.disabledQuestion
      this.$emit('toggleDisabledQuestion', this.disabledQuestion)
    }
  }
}
</script>

<style scoped>

</style>
