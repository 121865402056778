<template>
  <div class="bank-accounts">
    <general-layout>
      <template v-slot:title>
        <vue-title :title="$t('general.meta_titles.bank_accounts')"/>
      </template>

      <template v-slot:tabs>
        <Tabs
          :show-id-title="false"
        />
      </template>

      <template v-slot:body>
        <div v-if="!hasAccessInPortal">
          <div class="desktop-flex gap-8">
            <ContinueToOnboarding/>

            <div v-if="!isMobile">
              <Card class="w-full h-full mb-4">
                <template v-slot:body>
                  <div>
                    <p v-html="$t('guides.bank_accounts.bank_accounts_p1')"></p>
                    <br>
                    <p v-html="$t('guides.bank_accounts.bank_accounts_p2')"></p>
                    <br>
                    <p>{{ $t('guides.bank_accounts.bank_accounts_p3') }}</p>
                  </div>
                </template>
              </Card>
            </div>
          </div>
        </div>

        <div v-if="hasAccessInPortal">
          <PulseLoader class="w-fit m-auto p-10 justify-center flex" v-if="loading"/>

          <Transition appear name="fade">
            <div v-if="!loading">
              <BankAccountsDesktop v-if="isDesktop" :bank-accounts-default="bankAccounts"/>
              <BankAccountsMobile v-else :bank-accounts-default="bankAccounts"/>
            </div>
          </Transition>
        </div>
      </template>
    </general-layout>
  </div>
</template>

<script>
import GeneralLayout from '../../layout/GeneralLayout'
import Tabs from '../../layout/Tabs'
import Button from '../../subcomponents/Button'
import InputText from '../../subcomponents/InputText'
import Card from '../../subcomponents/Card'
import IconBox from '../../subcomponents/IconBox.vue'
import Message from '../../subcomponents/Message'
import {mapActions, mapGetters} from 'vuex'
import ContinueToOnboarding from '../ContinueToOnboarding'
import SelectDropDown from '../../subcomponents/SelectDropDown'
import Checkbox from '../../subcomponents/Checkbox.vue'
import BankAccountItem from './BankAccountItem.vue'
import AddBankAccount from './AddBankAccount.vue'
import Resources from '../../subcomponents/Resources.vue'
import BankAccountsDesktop from './BankAccountsDesktop.vue'
import BankAccountsService from '../../../services/deposit-withdrawal/bankAccountsService'
import BankAccountsMobile from './BankAccountsMobile.vue'

export default {
  name: 'BankAccounts',
  components: { BankAccountsMobile, BankAccountsDesktop, Resources, AddBankAccount, BankAccountItem, Checkbox, GeneralLayout, Tabs, Button, InputText, Card, IconBox, Message, ContinueToOnboarding, SelectDropDown },
  async mounted () {
    await this.setData()
    this.loading = false

    this.$nextTick(() => {
      this.setIntroPing((Math.random() + 1).toString(36).substring(7))
    })
  },
  computed: {
    ...mapGetters({
      screenDevice: 'layout/screenDevice',
      hasAccessInPortal: 'user/hasAccessInPortal',
      isLogged: 'user/isLogged'
    }),
    isMobile () {
      return this.screenDevice === this.$screenDeviceConstants.MOBILE
    },
    isDesktop () {
      return this.screenDevice === this.$screenDeviceConstants.DESKTOP
    }
  },
  data () {
    return {
      loading: true,
      bankAccounts: []
    }
  },
  methods: {
    ...mapActions({
      setIntroPing: 'guide/setIntroPing'
    }),
    async setData () {
      let bankAccounts = await BankAccountsService.getBankAccounts()

      this.bankAccounts = bankAccounts.data.data.bankAccounts
    }
  }
}
</script>

<style scoped></style>
