<template>
  <div class="relative">
    <Doughnut
      ref="doughnutChart"
      :chart-options="chartOptions"
      :chart-data="chartDataSets"
      :chart-id="chartId"
      :dataset-id-key="datasetIdKey"
      :plugins="plugins"
      :css-classes="cssClasses"
      :styles="styles"
      :width="widthChart"
      :height="heightChart"
    />
  </div>
</template>

<script>
import {Doughnut} from 'vue-chartjs/legacy'
import {mapGetters} from 'vuex'
import {Chart} from 'chart.js'

export default {
  name: 'DoughnutChartPIES',
  components: {
    Doughnut
  },
  props: {
    total: {
      type: Number,
      default: 0
    },
    chartId: {
      type: String,
      default: 'doughnut-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    chartDataSets: {
      type: Object,
      default: () => {}
    },
    plugins: {
      type: Array,
      default: () => []
    },
    legendPlace: {
      type: String,
      default: 'bottom'
    },
    withCurrency: {
      type: Boolean,
      default: false
    },
    widthChart: {
      type: Number,
      default: 320
    },
    heightChart: {
      type: Number,
      default: 320
    },
    showTotal: {
      type: Boolean,
      default: false
    },
    borderWidth: {
      type: Number,
      default: 8
    }
  },

  computed: {
    ...mapGetters({
      newPie: 'pies/newPie'
    })
  },
  watch: {
    total (value) {
      this.chartOptions.centerTextColor = value > 100 ? '#CA0000' : '#001C4D'
      this.chartOptions.centerText = 'Total\n' + value + '%'
    }
  },
  data () {
    return {
      sumTotalOfTargets: 0,
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        elements: {
          arc: {
            borderWidth: this.borderWidth
          }
        },
        plugins: {
          tooltip: {
            enabled: true,
            cornerRadius: 2,
            displayColors: false
          },
          legend: {
            display: false
          },
          textCenter: {
            enabled: this.showTotal
          }
        },
        centerText: '',
        centerTextColor: '#001C4D'
      }
    }
  },
  mounted () {
  },
  methods: {
  }
}
</script>
