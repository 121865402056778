import Sumsub from '../../services/auth/sumsubService'

const getDefaultState = () => {
  return {
    token: '',
    userId: null
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    resetState (state) {
      Object.assign(state, getDefaultState())
    },
    setToken (state, token) {
      state.token = token
    },
    setUserId (state, userId) {
      state.userId = userId
    }
  },
  getters: {
    token ({token}) {
      return token
    },
    userId ({userId}) {
      return userId
    }
  },
  actions: {
    resetState (ctx) {
      ctx.commit('user/resetState')
    },
    async getToken (ctx, userId) {
      let tokenData = await Sumsub.getNewAccessSumsub(userId)

      ctx.commit('setToken', tokenData.token)
      ctx.commit('setUserId', tokenData.userId)
    }
  }
}
