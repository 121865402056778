<template>
  <div>
    <Card class="bank-account" :key="counter" :w100="true" :title-small="true"
          :card-classes="`bank-account-card`">
      <template v-slot:body>
        <div>
          <div class="flex items-center justify-between mb-4">
            <div class="flex items-center">
              <div class="mr-4">
                <IconColor :positions="['left']" color="green" icon="card_bank"/>
              </div>
              <div class="iban">
                <span>{{ bankAccount.iban }}</span>
              </div>
            </div>
            <div v-if="deletable" class="cursor-pointer" @click="remove(bankAccount)">
              <img class="w-5" alt="delete" :src="require('@/assets/img/icons/trash-can-fill.svg')">
            </div>
          </div>

          <div class="details">
            <div>
              <span>{{ $t('deposit_withdrawal.bank_accounts.bank') }}: {{ bankAccount.bank_name }}</span>
            </div>

            <div>
              <span>{{ $t('deposit_withdrawal.bank_accounts.currency') }}: {{ bankAccount.currency }}</span>
            </div>

            <div>
              <span>{{ $t('deposit_withdrawal.general.status') }}: {{ $t(`deposit_withdrawal.general.${bankAccount.status}`) }}</span>
            </div>
          </div>
        </div>

        <div class="flex justify-between bottom">
          <div class="flex details items-center">
            <div class="mr-3">
              <img src="@/assets/img/icons/calendar.svg" alt="calendar">
            </div>
            <div>{{ bankAccount.created_at }}</div>
          </div>

          <div>

            <div class="cursor-pointer flex" v-if="bankAccount.documents.length" @click="download()">
              <div class="download-icon green" :class="{'mr-2' : !isMobile}"></div>
              <div
                v-if="isDesktop"
                class="document cursor-pointer download-button"
                >
                {{ $t('deposit_withdrawal.general.download_bank_statement') }}
              </div>
            </div>

          </div>
        </div>
      </template>
    </Card>
  </div>
</template>

<script>
import Card from '../../subcomponents/Card.vue'
import Button from '../../subcomponents/Button.vue'
import DocumentService from '../../../services/documents/documentsService'
import IconColor from '../../subcomponents/IconColor.vue'
import {mapGetters} from 'vuex'
import BankAccountsService from '@/services/deposit-withdrawal/bankAccountsService'

export default {
  name: 'BankAccountItem',
  components: {IconColor, Button, Card},
  props: {
    counter: {
      type: Number,
      default: 0
    },
    bankAccount: {
      type: Object,
      required: true
    },
    deletable: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters({
      screenDevice: 'layout/screenDevice'
    }),
    isMobile () {
      return this.screenDevice === this.$screenDeviceConstants.MOBILE
    },
    isDesktop () {
      return this.screenDevice === this.$screenDeviceConstants.DESKTOP
    }
  },
  methods: {
    async download () {
      await DocumentService.downloadDocument(this.bankAccount.documents[0])
    },
    async remove (bankAccount) {
      await this.$confirm(
        this.$t('deposit_withdrawal.bank_accounts.delete_confirmation', {bankAccount: bankAccount.iban}),
        '',
        'warning',
        {cancelButtonText: this.$t('documents.general.delete'), confirmButtonText: this.$t('documents.general.confirm')}
      ).then(async () => {
        await BankAccountsService.deleteBankAccount(bankAccount.id).then(async response => {
          if (response.status === 200) {
            await this.setData()
          }
        })
      })
    },
    async setData () {
      this.$emit('setData')
    }
  }
}
</script>

<style scoped>

</style>
