<template>
  <div>
    <div v-if="from === 'PJ' && !inputValidationCode" class="text-lg pb-7">
     <div class="form-group mb-6 flex items-end">
       <PhoneNumber
        :pj-onboarding="true"
        @back="resetStep()"
        @phoneInput="validation.phoneNumber = ''"
        @validate="next"
       />
     </div>

     <Message
      v-if="validation.phoneNumber"
      :message="validation.phoneNumber"
      :type="'error'"
      class="mb-5"
     />

     <Message
      v-if="validation.dialCode"
      :message="validation.dialCode"
      :type="'error'"
      class="mb-5"
     />
    </div>

    <div class="text-lg pb-7" v-else>
      {{ $t("update_personal_data.phone_number.phone_verif_guidance") }}
      <b> {{ " " + updatedData.step_phone.phone_number }} </b>
    </div>

    <div v-if="!(from === 'PJ' && !inputValidationCode)" class="text-lg pb-7">
      {{ $t("update_personal_data.phone_number.code_guidance") }}
    </div>

    <form v-if="!(from === 'PJ' && !inputValidationCode)">
      <div class="text-lg mb-px">
        {{ $t("onboarding.email_verification.verif_code") }}
      </div>

      <OtpInput
        :codeLength="4"
        @on-complete="setVerificationCode"
        @on-paste="setVerificationCode">
      </OtpInput>

      <pulse-loader v-if="loading" class="m-auto w-fit" />

      <Message
        v-if="validation.verificationCode"
        :message="validation.verificationCode"
        :type="'error'"
        class="m-auto"
      />

      <Message
        v-if="validation.resentCode"
        :message="validation.resentCode"
        :type="'success'"
        class="m-auto"
      />

      <div class="mt-5">
        <div
          class="color-blue font-bold margin-bot-resend cursor-pointer"
          @click="resendValidationCode">
          {{ $t("onboarding.email_verification.resend_code") }}
        </div>

        <div v-if="from === 'PJ'" class="flex justify-between py-4">
          <Button
            @call="back"
            :text="$t('general.general.back')"
            :type="'btn btn-secondary padding-medium end-position no-wrap'"
            :icon="'icons/arrow_back_green'"
            :icon-position="'left'"
          />

          <div @click="validateCode">
            <Button
              :text="$t('onboarding.general.validate')"
              :type="'btn btn-primary padding-big end-position'"
              :icon="'icons/check-white'"
              :icon-position="'right'"
            />
          </div>
        </div>

       <div v-else class="flex justify-between py-4">
        <Button
         @call="resetStep()"
         :text="$t('general.general.back')"
         :type="'btn btn-secondary padding-medium end-position no-wrap'"
         :icon="'icons/arrow_back_green'"
         :icon-position="'left'"
        />

        <div @click="validateCode">
         <Button
          :text="$t('onboarding.general.validate')"
          :type="'btn btn-primary padding-big end-position'"
          :icon="'icons/check-white'"
          :icon-position="'right'"
         />
        </div>
       </div>
      </div>
    </form>
  </div>
</template>

<script>
import Button from '../../subcomponents/Button.vue'
import OtpInput from '../../subcomponents/security-code/OtpInput.vue'
import Message from '../../subcomponents/Message.vue'
import {mapActions, mapGetters} from 'vuex'
import ApiService from '../../../services/apiService'
import InputText from '@/components/subcomponents/InputText.vue'
import {ONBOARDING_LEGAL_PERSON_DETAILS, ONBOARDING_PHONE_VALIDATION} from '@/router'
import CountryCodesPhone from '@/components/subcomponents/CountryCodesPhone.vue'
import PhoneNumber from '@/components/profile/update-personal-data/PhoneNumber.vue'
export default {
  name: 'VerifyPhoneNumber',
  components: { PhoneNumber, CountryCodesPhone, InputText, Button, OtpInput, Message },
  props: {
    from: {
      type: String,
      default: 'updatePersonalData'
    }
  },
  data () {
    return {
      countriesData: [],
      selectedCode: '',
      verificationCode: '',
      loading: false,
      phoneNumber: '',
      validation: {
        dialCode: '',
        phoneNumber: '',
        verificationCode: '',
        resentCode: ''
      },
      countriesOrderedByDialCode: [],
      inputValidationCode: false
    }
  },
  computed: {
    ...mapGetters({
      updatedData: 'updatePersonalData/getUpdatedData'
    })
  },

  methods: {

    back () {
      this.selectedCode = ''
      this.phoneNumber = ''
      this.updatedData.step_phone.phone_number = ''
      this.inputValidationCode = false
    },
    next (phone) {
      this.phoneNumber = phone
      this.sendPhoneNr()
    },
    setSelectedCode (code) {
      this.selectedCode = code
    },
    resetStep () {
      if (this.parent === 'onboarding') {
        this.$emit('resetStep')
      } else {
        this.$emit('prev', 'updatePhoneNumber')
      }
    },
    setVerificationCode (code) {
      this.$emit('setVerificationCode', code)
      this.verificationCode = code
    },
    async resendValidationCode () {
      this.loading = true

      let response = await ApiService.withAuth().post('/api/user/update-phone/generate-code', {
        phone: this.updatedData.step_phone.phone_number
      }).finally(() => {
        this.loading = false
      })

      if (response && response.status === 200) {
        this.validation.resentCode = this.$i18n.t(
          'onboarding.email_verification.code_verif_success'
        )
      }
    },
    async validateCode () {
      if (!this.validate()) {
        return
      }

      let updatedData = this.updatedData
      updatedData.step_phone.verificationPhoneNumber = this.verificationCode

      let response = await ApiService.withAuth().post('/api/user/onboarding/company/verify-code', {
        phone_verify_code: this.verificationCode
      }).catch((error) => {
        this.validation.verificationCode = error.response.data.message
      })

      if (response && response.status === 200) {
        await this.$router.push({name: ONBOARDING_LEGAL_PERSON_DETAILS})
      }
    },
    validate () {
      if (!this.selectedCode && this.from !== 'PJ') {
        this.validations.dialCode = this.$i18n.t('general.validations.dial_code')
        return false
      }
      if (!this.phoneNumber) {
        this.validations.phoneNumber = this.$i18n.t('general.validations.phone_number')
        return false
      }
      return true
    },
    async sendPhoneNr () {
      await ApiService.withAuth().post('/api/user/onboarding/company/verify-phone', {
        phone: this.phoneNumber
      }).catch((error) => {
        this.validation.phoneNumber = error.response.data.message
      }).then(() => {
        if (!this.validation.phoneNumber) {
          this.inputValidationCode = true
          this.updatedData.step_phone.phone_number = this.phoneNumber
        }
      })
    }
  },
  watch: {
    verificationCode (nVal) {
      if (nVal) {
        this.validation.verificationCode = ''
      }
    }
  }
}
</script>

<style scoped>

</style>
