<template>
  <div>
    <nav>
      <div class="portal-navbar-wrapper">
        <div class="portal-navbar" :class="{'fixed w-full': menuOpened}">
          <a target="_blank" :href="getWpEndpoint()">
            <img v-if="!isMobile" class="m-auto logo" src="@/assets/img/logo.svg" alt="logo">
            <img v-else class="m-auto logo" src="@/assets/img/logo_icon.svg" alt="logo">
          </a>
          <img class="home-icon cursor-pointer ml-4 my-auto mr-auto" src="@/assets/img/icons/house.svg" alt="house-icon" @click="$router.push({name: 'homepage'})">

          <div v-if="isDesktop" class="navbar-wrapper">
            <div class="links">
              <div class="link" :class="{'active': $route.meta.menu === 'settings'}" @click="$router.push({name: 'settings'})">
                {{ $t('general.menu.my_account') }}

                <div class="underline" v-if="$route.meta.menu === 'settings'"></div>
              </div>

              <div class="link" :class="{'active': $route.meta.menu === 'assets'}" @click="$router.push({name: 'portofolio'})">
                {{ $t('general.menu.transfers') }}

                <div class="underline" v-if="$route.meta.menu === 'assets'"></div>
              </div>

              <div class="link money" :class="{'active': $route.meta.menu === 'bankAccounts'}" @click="$router.push({name: 'bank-accounts'})">
                {{ $t('general.menu.money') }}

                <div class="underline" v-if="$route.meta.menu === 'bankAccounts'"></div>
              </div>
            </div>

            <div class="right-side">
              <div class="flex">
                <Button class="bvb" :loading=arenaXtLoading v-if="hasAccessInPortal" :text="$t('general.menu.arena_xt')" :type="'btn-primary'" :icon="'icons/bvb'" :icon-position="'left'" @call="getJwt"/>

                <Button class="dx" :loading=middlewareLoading v-if="hasAccessInPortal && middlewareActive" :text="$t('general.menu.web_terminal')" :icon="'icons/global'" :icon-position="'left'" :type="'btn-primary'" @call="getMiddlewareToken"/>

                <Button class="dx" :loading=DXLoading v-if="hasAccessInPortal" :text="$t('general.menu.global')" :icon="'icons/global'" :icon-position="'left'" :type="'btn-primary'" @call="getDxToken"/>

                <Button class="lms" :text="$t('general.menu.lms')" :icon="'icons/mentor'" :icon-position="'left'" :type="'btn-dark-outline'" @call="loginInLms"/>

                <div class="icons">
                  <img
                    class="help cursor-pointer"
                    :src="require(`@/assets/img/icons/${ $route.meta.menu === 'help' ? 'help-active.svg' : 'help.svg' }`)"
                    alt="help"
                    @click="$router.push({name: 'help'})"
                  >

                  <div class="notifications-wrapper cursor-pointer">
                    <img class="notifications"
                         :src="require(`@/assets/img/icons/${ $route.meta.menu === 'notifications' ? 'notifications-active.svg' : 'notifications.svg' }`)"
                         alt="notifications"
                         @click="$router.push({name: 'notifications'})"
                    >

                    <div class="bubble">{{ unreadNotifications }}</div>
                  </div>
                </div>
              </div>

              <div @mouseover="openedLogOut = true" class="avatar relative card-demo">
                <span>{{ user.first_name ? user.first_name[0] : '' }}{{ user.last_name ? user.last_name[0] : '' }}</span>

                <div @mouseleave="openedLogOut = false" v-if="openedLogOut" class="logout-dropdown" >
                  <div>
                    <div class="name">
                      <div class="whitespace-nowrap">{{ user.first_name }} {{ user.last_name ? user.last_name[0] : '' }}.</div>
                      <div class="username whitespace-nowrap">{{ user.username }}</div>
                    </div>

                    <div class="dropdown-link flex items-center" @click="logoutUser">
                      <img src="@/assets/img/icons/exit.svg">
                      <div class="color-primary">{{ $t('general.menu.log_out') }}</div>
                    </div>
                  </div>
                </div>
              </div>

              <img class="burger" src="@/assets/img/icons/menu.svg" alt="menu" v-if="!isDesktop" @click="menuOpened = true">
            </div>
          </div>

          <div v-else>
            <div class="right-side">
              <div class="flex">
                <div class="icons">
                  <Transition name="fade">
                    <img class="mentor" src="@/assets/img/icons/mentor.svg" alt="mentor" v-if="!menuOpened" @click="loginInLms">
                  </Transition>

                  <img
                    :src="require(`@/assets/img/icons/${ $route.meta.menu === 'help' ? 'help-active.svg' : 'help.svg' }`)"
                    alt="help"
                    class="help"
                    @click="$router.push({name: 'help'})"
                  >

                  <div class="notifications-wrapper">
                    <img class="notifications"
                         :src="require(`@/assets/img/icons/${ $route.meta.menu === 'notifications' ? 'notifications-active.svg' : 'notifications.svg' }`)"
                         alt="notifications"
                         @click="$router.push({name: 'notifications'})"
                    >

                    <div class="bubble">{{ unreadNotifications }}</div>
                  </div>
                </div>
              </div>

              <img class="burger" src="@/assets/img/icons/burger.svg" alt="menu" @click="toggleMenu">
            </div>
          </div>

          <Transition>
            <Sidebar @closeMenu="menuOpened = false" v-model="menuOpened"/>
          </Transition>

          <FooterBar v-if="!isDesktop"></FooterBar>

          <PopUpModal
            :modal-fit-content="true"
            :modal-opened="showNewUserModal"
            @closeModal="closePopUp()">
            <div :class="[!isMobile ? 'flex flex-col justify-center': 'mt-16']">
              <div v-if="user.type !== 'company'" class="text-center">{{ $t('general.general.new_user_warn') }}</div>
              <div v-else class="text-center" v-html="newCompanyMessage"></div>
              <Button class="mt-5 mx-auto" :text="$t('general.general.ok')" :type="'btn-primary'" @call="closePopUp()"/>
            </div>

          </PopUpModal>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import {USER_STATUS_APPROVED} from '@/services/auth/userService'
import {mapActions, mapGetters} from 'vuex'
import Button from '../../subcomponents/Button'
import Sidebar from '../Sidebar'
import NavbarButtonMethods from '../../../mixins/NavbarButtonMethods'
import FooterBar from '../FooterBar.vue'
import PopUpModal from '@/components/subcomponents/PopUpModal.vue'
import introJS from 'intro.js'
export default {
  name: 'NavbarPortal',
  components: { PopUpModal, Sidebar, Button, FooterBar },
  mixins: [NavbarButtonMethods],
  computed: {
    ...mapGetters({
      isLogged: 'user/isLogged',
      hasAccessInPortal: 'user/hasAccessInPortal',
      user: 'user/user',
      screenDevice: 'layout/screenDevice',
      unreadNotifications: 'user/unreadNotifications',
      getStep: 'guide/step',
      middlewareActive: 'user/middlewareActive'
    }),
    userStatusApproved () {
      return USER_STATUS_APPROVED
    },
    getCurrentRoute () {
      return this.$route.name
    },
    isDesktop () {
      return this.screenDevice === this.$screenDeviceConstants.DESKTOP
    },
    isMobile () {
      return this.screenDevice === this.$screenDeviceConstants.MOBILE
    },
    newCompanyMessage () {
      return this.$t('general.general.new_company_warn', {openTag: `<a class="color-blue" href="${this.goToPJDoc()}">`, closeTag: `</a>`})
    }
  },
  data () {
    return {
      menuOpened: false,
      openedLogOut: false
    }
  },
  mounted () {
    document.getElementById('hubspot-messages-iframe-container')
      ?.style.setProperty('display', '', 'important')

    window.openSideBar = () => {
      this.menuOpened = true
    }

    window.closeSideBar = () => {
      this.menuOpened = false
    }
  },
  methods: {
    ...mapActions({
      logout: 'user/logout',
      setStep: 'guide/setStep'
    }),
    toggleMenu () {
      this.menuOpened = !this.menuOpened
    },
    goToPJDoc () {
      return window.location.origin + '/pj-documents'
    }
  },
  watch: {
    menuOpened (nVal) {
      document.getElementById('hubspot-messages-iframe-container')?.style.setProperty('display', nVal ? 'none' : '', 'important')
      document.getElementsByClassName('background-fade-right')[0]?.style.setProperty('display', nVal ? 'none' : '', 'important')
      document.getElementsByClassName('background-fade-left')[0]?.style.setProperty('display', nVal ? 'none' : '', 'important')
      if (document.getElementsByClassName('calendars')[0]) {
        let calendars = document.getElementsByClassName('calendars')[0].children
        for (let i = 0; i < calendars.length; i++) {
          calendars[i].style.setProperty('display', nVal ? 'none' : '', 'important')
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
