<template>
  <div>
    <vue-title :title="$t('general.meta_titles.register')"></vue-title>

    <div class="m-auto" v-if="$route.meta['source'] !== 'lms'">
      <StepBar :current-step="selectedTabId" :steps="tabs"></StepBar>
    </div>

    <Card :card-classes="'margin-bottom-medium mobile-border-none'"
          :class="[$route.meta['source'] === 'lms' ?
          (screenDevice !== $screenDeviceConstants.DESKTOP && screenDevice !== $screenDeviceConstants.TABLET ? 'mt-20' : 'mt-36') :
          '']">
      <template v-slot:top>
        <div class="flex">
          <img class="my-auto" src="~@/assets/img/icons/green_circle_person.svg">
          <div class="ml-4">{{ $t('onboarding.legal_person.top_message') }}</div>
        </div>
      </template>

      <template v-slot:title>
        <div>{{ $t('onboarding.legal_person.create_account') }}</div>
      </template>

      <template v-slot:body>
        <form>
          <div class="form-group mb-6">
            <InputText
              :id="'email'"
              v-model="email"
              :type-input="'email'"
              :label="$t('onboarding.legal_person.email_address')">
            </InputText>
          </div>

          <Message v-if="validation.email" :message="validation.email" :type="'error'" class="mb-5"/>

          <Message v-if="validation.general" :formatIsHtml="true" :message="validation.general" :type="'error'" class="mb-5"/>

          <div class="form-group mb-6">
            <InputText
              :id="'password'"
              v-model="password"
              :type-input="'password'"
              :label="$t('general.general.password')">
            </InputText>
            <span class="color-light-gray text-sm">{{ $t('general.general.password_rules') }}</span>
          </div>
          <div v-for="(validation, index) in validation.password" :key="index">
            <Message :formatIsHtml="true" :message="validation" :type="'error'" class="mb-5"/>
          </div>
          <div class="form-group mb-6">
            <InputText
              :id="'repeatPassword'"
              v-model="repeatPassword"
              :type-input="'password'"
              :label="$t('general.general.repeat_password')"
              @focusout="focusOut(password, repeatPassword)"/>
          </div>

          <Message v-if="validation.repeatPassword" :message="validation.repeatPassword" :type="'error'" class="mb-5"/>

          <Message v-if="validation.passwordMatch" :message="validation.passwordMatch" :type="'error'" class="mb-5"/>

          <div class="mt-4">{{ $t('onboarding.legal_person.as_legal_representative') }}</div>

          <div class="my-4" v-for="agreement in agreements" :key="agreement.type">
            <Checkbox
              :id="agreement.type"
              v-model="agreement.checked"
              :name="agreement.type"
              :label="agreement.label">
            </Checkbox>

            <Message v-if="validation.agreements[agreement.value]" :type="'error'" :message="validation.agreements[agreement.value]"></Message>
          </div>

          <div class="flex my-5">
            <div class="mr-2">
              {{ $t('onboarding.register.log_in_question') }}
            </div>

            <div>
              <span class="cursor-pointer color-primary" @click="$router.push({name: 'login'})">{{ $t('general.general.click_here') }}</span>
            </div>
          </div>

          <Button class="mb-5"
                  :text="$t('general.general.continue')"
                  :type="'btn btn-primary padding-big m-auto'"
                  :loading="loadingRegister"
                  @call="registerUser"
          />
          <pulse-loader class="mt-5" v-if="loadingRegister"/>
        </form>
      </template>
    </Card>
  </div>
</template>

<script>
import Tabs from '../layout/Tabs'
import {mapActions, mapGetters} from 'vuex'
import Button from '../subcomponents/Button'
import Message from '../subcomponents/Message'
import {ONBOARDING_EMAIL_VERIFICATION, ONBOARDING_PHONE_VALIDATION} from '../../router'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import StepBar from '../layout/StepBar'
import Card from '../subcomponents/Card'
import InputText from '../subcomponents/InputText'
import Checkbox from '../subcomponents/Checkbox'
import PasswordValidations from '../../mixins/PasswordValidations'

export default {
  name: 'LegalPersonRegister',
  components: {InputText, Card, StepBar, Tabs, Button, Message, PulseLoader, Checkbox},
  mixins: [ PasswordValidations ],
  data () {
    return {
      tabs: [
        {
          id: 1,
          label: this.$i18n.t('onboarding.general.create_account')
        },
        {
          id: 2,
          label: this.$i18n.t('onboarding.general.identity_verification')
        },
        {
          id: 3,
          label: this.$i18n.t('onboarding.general.financial_data')
        }
      ],
      selectedTabId: 1,
      email: '',
      password: '',
      repeatPassword: '',
      gdprAccord: false,
      marketingAccord: false,
      loadingRegister: false,
      typeOptions: [
        {
          label: this.$i18n.t('onboarding.register.company'),
          value: 'company'
        },
        {
          label: this.$i18n.t('onboarding.register.person'),
          value: 'person'
        }
      ],
      maritalStatusOptions: [
        {
          label: this.$i18n.t('onboarding.register.not_married'),
          value: 'not-married'
        },
        {
          label: this.$i18n.t('onboarding.register.married'),
          value: 'married'
        }
      ],
      validation: {
        email: '',
        password: [],
        repeatPassword: '',
        passwordMatch: '',
        general: '',
        agreements: {
          'distanceContract': '',
          'investimentalPresentation': '',
          'gdpr': ''
        }
      },
      agreements:
        {
          'gdpr': {
            type: 'gdpr',
            label: this.$i18n.t('onboarding.register.gdpr'),
            checked: false,
            required: true,
            value: 'gdpr'
          },
          'marketing': {
            type: 'marketing',
            label: this.$i18n.t('onboarding.register.marketing'),
            checked: false,
            value: 'marketing'
          }
        },
      tabsClasses: ['justify-center']
    }
  },
  mounted () {
    if (this.isLogged) {
      this.$router.push({name: ONBOARDING_PHONE_VALIDATION})
      return
    }

    this.email = this.getEmail
  },
  computed: {
    ...mapGetters({
      getEmail: 'user/email',
      getGdprAccord: 'user/gdprAccord',
      getMarketingAccord: 'user/marketingAccord',
      getUserToken: 'user/token',
      screenDevice: 'layout/screenDevice'
    })
  },
  methods: {
    ...mapActions({
      register: 'user/register',
      setEmail: 'user/setEmail',
      setGdprAccord: 'user/setGdprAccord',
      setMarketingAccord: 'user/setMarketingAccord',
      setToken: 'user/setToken',
      setLoggedIn: 'user/setLoggedId',
      setUsername: 'user/setUsername'
    }),
    async registerUser () {
      this.loadingRegister = true
      try {
        if (!this.validate()) {
          return
        }
        let register = await this.register({
          email: this.email,
          password: this.password,
          agreements: this.agreements,
          source: 'portal',
          type: 'company'
        }).catch(response => {
          if (response.response.data.errors && response.response.data.errors.password) {
            this.validation.password.push(response.response.data.errors.password)
          }
          if (response.response.data.data.emailAlreadyExists) {
            this.validation.general = this.$i18n.t('general.validations.email_already_exists')
          }
        })
        if (register && register.status === 200) {
          this.setEmail(this.email)
          this.setToken(register.data.data.token)
          this.setLoggedIn({
            loggedIn: true,
            has2FAConfirmed: false
          })
          await this.$router.push({name: ONBOARDING_EMAIL_VERIFICATION})
        }
      } finally {
        this.loadingRegister = false
      }
    },
    validate () {
      this.validation.password = []
      let valid = true

      const validateEmail = (email) => {
        return String(email)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          )
      }

      if (!this.agreements.gdpr.checked) {
        this.validation.agreements.gdpr = this.$t('onboarding.register.validation_agreement')
        valid = false
      }

      if (!this.validatePassword(this.password, this.repeatPassword)) {
        valid = false
      }

      if (!this.email) {
        this.validation.email = this.$t('general.validations.email_validation')
        valid = false
      } else if (!validateEmail(this.email)) {
        this.validation.email = this.$t('general.validations.email_format_validation')
        valid = false
      }

      return valid
    }
  },
  watch: {
    password (nVal) {
      if (nVal) {
        this.validation.password = []
        this.validation.passwordMatch = ''
      }
    },
    email (nVal) {
      if (nVal) {
        this.validation.email = ''
        this.validation.general = ''
      }
    },
    agreements: {
      handler (val) {
        if (val.gdpr.checked) {
          this.validation.agreements.gdpr = ''
        }
      },
      deep: true
    }
  }
}
</script>

<style scoped>

</style>
