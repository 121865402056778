<template>
  <div>
    <vue-title :title="$t('general.meta_titles.onboarding_info')"/>

    <Transition appear name="fade">
      <Card
        :card-classes="'prepare-onboarding mx-auto m-bottom-custom-l mobile-border-none mobile-card-no-background'"
        :class="[
        $route.meta['source'] === 'lms'
          ? screenDevice !== $screenDeviceConstants.DESKTOP && screenDevice !== $screenDeviceConstants.TABLET
            ? 'mt-20'
            : 'mt-36'
          : ''
      ]"
      >
        <template v-slot:title>
          <div class="text-center">{{ $t('onboarding.prepare_onboarding.title') }}</div>
        </template>

        <template v-slot:body>
          <div class="m-top-custom">
            <div class="content flex items-center">
              <div class="image">
                <img src="@/assets/img/prepare-onboarding.svg" alt="transfer-portfolio" />
              </div>

              <div>
                <span class="subtitle">{{ $t('onboarding.prepare_onboarding.subtitle') }}</span>

                <ul class="requirements">
                  <li>{{ $t('onboarding.prepare_onboarding.requirement1') }}</li>
                  <li>{{ $t('onboarding.prepare_onboarding.requirement2') }}</li>
                </ul>

              </div>
            </div>

            <div class="block m-top-custom">
              <a href="https://alpha.investimental.ro/deschidere-cont-cu-pasaportul/" target="_blank" class="color-primary block text-center">
                {{ $t('onboarding.prepare_onboarding.details_link') }}
              </a>

              <span class="block text-center">{{ $t('onboarding.prepare_onboarding.details1') }}</span>

              <span class="block text-center"><b>{{ $t('onboarding.prepare_onboarding.ending') }}</b></span>
            </div>

            <span class="block text-center m-top-custom m-bottom-custom">
              <Button
                @call="next"
                :text="$t('onboarding.general.continue')"
                :type="'btn btn-primary m-auto'"
              />
            </span>

            <span class="flex justify-center">
              <img class="m-right-custom" src="@/assets/img/icons/attention-onboarding.svg" alt="transfer-portfolio" />
              <span>{{ $t('onboarding.prepare_onboarding.legal_person') }}</span>
              <span class="cursor-pointer color-primary ml-1" @click="$router.push({name: 'register-pj'})">
                {{ $t('onboarding.prepare_onboarding.click_here') }}
              </span>
            </span>

            <span class="flex justify-center">
              <img class="m-right-custom" src="@/assets/img/icons/attention-onboarding.svg" alt="transfer-portfolio" />
              {{ $t('onboarding.prepare_onboarding.info') }}
            </span>

          </div>
        </template>
      </Card>
    </Transition>
  </div>
</template>

<script>
import Tabs from '../layout/Tabs'
import Button from '../subcomponents/Button'
import Message from '../subcomponents/Message'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import StepBar from '../layout/StepBar'
import Card from '../subcomponents/Card'
import InputText from '../subcomponents/InputText'
import Checkbox from '../subcomponents/Checkbox'
import PasswordValidations from '../../mixins/PasswordValidations'
import { ONBOARDING_SELECT_DOMICILE } from '../../router'

export default {
  name: 'OnboardingInfo',
  components: { InputText, Card, StepBar, Tabs, Button, Message, PulseLoader, Checkbox },
  mixins: [PasswordValidations],
  data () {
    return {
      loadingRegister: false
    }
  },
  methods: {
    async next () {
      await this.$router.push({ name: ONBOARDING_SELECT_DOMICILE })
    }
  }
}
</script>

<style scoped></style>
