<template>
  <div>
    <vue-title :title="$t('general.meta_titles.select_residence')"></vue-title>

    <div class="rounded-lg bg-primary m-auto w-fit">
      <StepBar :current-step="selectedTabId" :steps="tabs"></StepBar>
    </div>

    <Card :card-classes="'margin-bottom-medium mobile-border-none'">
      <template v-slot:top>
        <img src="~@/assets/img/icons/yellow_user_verified.svg">
      </template>

      <template v-slot:title>
        <div>{{ $t('onboarding.general.identity_verification') }}</div>
      </template>

      <template v-slot:body>
        <div>
          <SelectResidenceData/>
        </div>
      </template>
    </Card>
  </div>
</template>

<script>
import StepBar from '../layout/StepBar'
import SelectResidenceData from '../profile/settings/FiscalData/SelectResidenceData'
import Card from '../subcomponents/Card'
export default {
  name: 'SelectResidence',
  components: {Card, SelectResidenceData, StepBar},
  data () {
    return {
      tabs: [
        {
          id: 1,
          label: this.$i18n.t('onboarding.general.create_account')
        },
        {
          id: 2,
          label: this.$i18n.t('onboarding.general.identity_verification')
        },
        {
          id: 3,
          label: this.$i18n.t('onboarding.general.financial_data')
        }
      ],
      selectedTabId: 2,
      tabsClasses: ['mb-4']
    }
  }
}
</script>

<style scoped>

</style>
