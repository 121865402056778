<template>
  <div>
    <div class="flex flex-col mb-10">
      <template>
        <div v-for="option in options" :key="option.value" class="flex flex-col">
          <div class="mt-4">
            <Checkbox
              :id="'option' + option.value"
              :label="$t(option.label)"
              :value="option.value === selectedOption"
              :icon="option.icon"
              class="mr-4"
              :type="'radio'"
              :name="'checkbox'"
              @click.native="setOption(option)"
            />

            <div v-if="!validation.enabled && passwordConfirmed && qrCode && option.value === selectedOption && isAuthenticator">
              <div v-html="qrCode" class="mt-2 w-full mb-10 qr-code-2fa"></div>

              <div class="mt-4 w-full mb-6">
                {{ $i18n.t('general.validations.code') }}
              </div>

              <OtpInput
                :code-length="6"
                @on-complete="setVerificationCode"
                @on-paste="setVerificationCode"
              />
            </div>

            <div v-if="!validation.enabled && passwordConfirmed && option.value === selectedOption && isSms">
              <div class="mt-4 w-full mb-6">{{ $i18n.t('general.validations.sms_code') }}</div>

              <OtpInput
                :code-length="6"
                @on-complete="setVerificationCode"
                @on-paste="setVerificationCode"
              />

              <ResendCode/>
            </div>
          </div>
        </div>
      </template>
    </div>

    <div v-if="showPasswordConfirm">
      <InputText
        class="mb-3"
        :placeholder="$t('general.general.password')"
        v-model="password"
        id="password"
        :type-input="'password'"
        :label="$t('general.validations.password_confirmation')"
      />

      <div
        class="cursor-pointer color-blue font-bold mb-3 text-right"
        @click="goToForgotPassword()"
      >
        {{ $t('auth.login.forgot_password') }}
      </div>

      <Button :loading="loadingData.save" @call="checkPassword" :w100="true" :text="$t('general.general.password_confirmation_button')"/>

    </div>

    <Message
      v-if="validation.password"
      :type="'error'"
      :message="validation.password"
      class="mb-5"
    />

    <Message
      v-if="validation.enabled"
      :type="'success'"
      :message="validation.enabled"
      class="mb-5"
    />

    <Message
      v-if="validation.disabled"
      :type="'success'"
      :message="validation.disabled"
      class="mb-5"
    />

    <Message
      v-if="validation.failed"
      :type="'error'"
      :message="validation.failed"
      class="mb-5"
    />

    <Button
      v-if="showSaveButton"
      :loading="loadingData.save"
      @call="save"
      :w100="true"
      :text="$t('general.general.save')"/>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Button from '../../subcomponents/Button'
import Message from '../../subcomponents/Message'
import GeneralLayout from '../../layout/GeneralLayout'
import Tabs from '../../layout/Tabs'
import InputText from '../../subcomponents/InputText'
import Card from '../../subcomponents/Card'
import IconBox from '../../subcomponents/IconBox.vue'
import Checkbox from '../../subcomponents/Checkbox.vue'
import OtpInput from '../../subcomponents/security-code/OtpInput.vue'
import { TWO_FA_TYPE_AUTH, TWO_FA_TYPE_SMS, TWO_FA_TYPE_NOT_NEEDED } from '@/store/modules/user'
import ResendCode from '@/components/auth/subcomponents/ResendCode.vue'

export default {
  name: 'TwoFactorAuthenticationActivation',
  components: {
    ResendCode,
    OtpInput,
    Checkbox,
    Button,
    Message,
    GeneralLayout,
    Tabs,
    InputText,
    Card,
    IconBox
  },
  props: {
    canDisable: {
      type: Boolean,
      default: true
    }
  },
  data () {
    let options = [
      {
        label: 'general.two_factor_auth_activation.sms_authentication_2fa',
        value: TWO_FA_TYPE_SMS,
        icon: 'smsauth'
      },
      {
        label: 'general.two_factor_auth_activation.google_authentication_2fa',
        value: TWO_FA_TYPE_AUTH,
        icon: 'appauth'
      }
    ]

    if (this.canDisable) {
      options.push({
        label: 'general.two_factor_auth_activation.no_2fa',
        value: TWO_FA_TYPE_NOT_NEEDED
      })
    }

    return {
      loadingData: {
        save: false
      },
      password: '',
      validation: {
        password: '',
        enabled: '',
        disabled: '',
        confirmed: '',
        failed: ''
      },
      qrCode: '',
      code: '',
      passwordConfirmed: false,
      isEnabled: false,
      options,
      selectedOption: null,
      isSms: false,
      isAuthenticator: false
    }
  },
  computed: {
    ...mapGetters({
      screenDevice: 'layout/screenDevice',
      user: 'user/user'
    }),
    showPasswordConfirm () {
      return this.user.two_factor_type !== this.selectedOption && !this.passwordConfirmed
    },
    showSaveButton () {
      return this.user.two_factor_type !== this.selectedOption && this.passwordConfirmed
    }
  },
  async created () {
    this.selectedOption = this.user.two_factor_type
  },
  methods: {
    ...mapActions({
      enableTwoFactor: 'user/enableTwoFactor',
      confirmPassword: 'user/confirmPassword',
      getQrCode: 'user/getQrCode',
      createQR: 'user/createQR',
      confirm: 'user/confirm',
      verifySmsCode: 'user/verifySmsCode',
      generateSmscode: 'user/generateSmscode',
      fetchUser: 'user/fetch'
    }),

    async goToForgotPassword () {
      await this.$router.push({name: 'password-recovery-send-link'})
    },

    setVerificationCode (code) {
      this.code = code
    },

    setOption (option) {
      this.selectedOption = option.value
    },

    async checkPassword () {
      if (!this.password) {
        this.validation.password = this.$t('general.validations.password_validation')

        return
      }

      if (this.password) {
        let passwordConfirmed = await this.confirmPassword({
          password: this.password
        })

        if (passwordConfirmed && passwordConfirmed.data.data.confirmed === true) {
          this.passwordConfirmed = true
        }

        if (passwordConfirmed && passwordConfirmed.data.data.confirmed === false) {
          this.validation.password = this.$i18n.t('general.validations.incorrect_password')
          return
        }
      }

      if (this.selectedOption === TWO_FA_TYPE_AUTH) {
        this.isAuthenticator = true
      }

      if (this.selectedOption === TWO_FA_TYPE_SMS) {
        this.isSms = true
      }

      if (this.isAuthenticator) {
        let qrCodeResponse = await this.getQrCode().catch(() => {})

        if (qrCodeResponse && qrCodeResponse.status === 200) {
          this.qrCode = qrCodeResponse.data.svg
        }

        if (!qrCodeResponse || (qrCodeResponse && qrCodeResponse.data.length === 0)) {
          let newQrCode = await this.createQR()

          if (newQrCode.status === 200) {
            this.qrCode = newQrCode.data.svg
          }
        }
      } else if (this.isSms) {
        await this.generateSmscode()
      }
    },
    async save () {
      try {
        this.loadingData.save = true

        if (!this.password) {
          this.validation.password = this.$i18n.t('general.validations.password_confirmation')
        }

        let response

        if (this.isAuthenticator) {
          let response = await this.confirm({
            code: this.code
          })

          if (!response.data.data.isConfirmed) {
            this.validation.failed = this.$i18n.t('general.two_factor_auth_activation.failed')

            return
          }

          if (response.data.data.isConfirmed) {
            let enabled = await this.enableTwoFactor({two_factor: this.selectedOption})

            if (!enabled) {
              return
            }

            this.isEnabled = true
            this.validation.enabled = this.$i18n.t('general.two_factor_auth_activation.enabled_google')
          }
        }

        if (this.isSms) {
          let response = await this.verifySmsCode({
            code: this.code
          })

          if (!response.data.data || !response.data.data.isConfirmed) {
            this.validation.failed = this.$i18n.t('general.two_factor_auth_activation.failed')

            return
          }

          if (response.data.data.isConfirmed) {
            let enabled = await this.enableTwoFactor({two_factor: this.selectedOption})

            if (!enabled) {
              return
            }

            this.isEnabled = true
            this.validation.enabled = this.$i18n.t('general.two_factor_auth_activation.enabled_sms')
          }
        }

        if (response && response.status === 200) {
          this.passwordConfirmed = false
          this.password = ''
        }
      } finally {
        await this.fetchUser()

        this.loadingData.save = false
      }
    }
  },
  watch: {
    password (nVal) {
      if (nVal) {
        this.validation.password = ''
      }
    },
    selectedOption () {
      this.password = ''
      this.validation.enabled = ''
      this.validation.disabled = ''
      this.qrCode = ''
      this.passwordConfirmed = false
      this.isAuthenticator = false
      this.isSms = false
      this.$emit('selected')
    },
    isEnabled () {
      this.qrCode = ''
      this.code = ''
    },
    code (nVal) {
      if (nVal) {
        this.validation.failed = ''
      }
    }
  }
}
</script>

<style scoped>

</style>
