<template>
  <div class="full-height-color">
    <div>
      <NavbarBasicOnboarding/>
    </div>
    <div class="w-full main basic-layout">
      <slot></slot>
    </div>
    <div><FooterCustom :background="'light-gray'"/></div>
  </div>
</template>

<script>
import NavbarBasicOnboarding from './navbar/NavbarBasicOnboarding'
import FooterCustom from './FooterCustom'

export default {
  name: 'BasicLayout',
  components: { NavbarBasicOnboarding, FooterCustom }
}
</script>

<style scoped>

</style>
