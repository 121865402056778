<template>
  <div class="general-layout-component" :class="generalLayoutClasses">
    <Referrals v-if="displayReferrals"/>

    <Banner v-if="banner" :banner-message="banner"/>

    <div class="title" :class="titleClasses">
      <slot name="title"></slot>
    </div>
    <div>
      <MaintenanceModeAnnouncementBanner v-if="maintenanceModeAnnouncement" :message="maintenanceModeAnnouncement"/>
    </div>
    <slot name="tabs"></slot>
    <div class="page-container flex justify-between" :class="{'no-padding' : noPadding}">
      <div class="main-container">
        <slot name="body"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import Referrals from '../subcomponents/Referrals.vue'
import MaintenanceModeAnnouncementBanner from '../subcomponents/MaintenanceModeAnnouncementBanner.vue'
import {mapGetters} from 'vuex'
import Banner from '@/components/subcomponents/Banner.vue'

export default {
  name: 'GeneralLayout',
  components: {Banner, MaintenanceModeAnnouncementBanner, Referrals},
  props: {
    noPadding: {
      type: Boolean,
      default: false
    },
    titleClasses: {
      type: String,
      default: ''
    },
    generalLayoutClasses: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapGetters({
      maintenanceModeAnnouncement: 'user/maintenanceModeAnnouncement',
      banner: 'user/banner',
      user: 'user/user',
      userIsApproved: 'user/userIsApproved',
      userIsStaff: 'user/userIsStaff',
      userIsRelevant: 'user/userIsRelevant'
    }),
    displayReferrals () {
      // TODO: uncomment this when they say we can
      // return this.userIsApproved && !(this.userIsStaff || this.userIsRelevant)
      return false
    }
  }
}
</script>
