<template>
  <div class="table-stock-securities instruments w-full">
    <div class="tbody" @scroll="onScroll">
      <div v-for="(stock, index) in stockSecurities" :key="index" :class="{'stock-top-10' : index < 10  && search === 0}">
        <div class="tr">
          <div class="td">
            <div class="flex items-center gap-3">
              <StockSecurityLogo :img="stock.logo" :smaller-logo="true" :symbol="stock.symbol" :color="colorBlock[index % 5]"/>
              <div>
                {{ stock.name ? stock.name : '-' }}
              </div>
            </div>
          </div>
          <div class="td flex flex-col">
            <div>{{ stock.symbol }}</div>

            <div>{{ $t('deposit_withdrawal.auto_invest.instruments.table.action') }}</div>
          </div>
          <div class="td">
            <img
              v-if="!addedInstrument(stock)"
              alt=""
              :src="require('@/assets/img/icons/circled_plus_white_bg.svg')"
              class="cursor-pointer"
              :class="{'opacity-50 pointer-events-none': addedInstruments.length >= maxNumberOfInstruments }"
              @click="addingInstrument(stock)"
            >
            <img
              v-else
              class="cursor-pointer"
              alt=""
              :src="require('@/assets/img/icons/circled_minus_white_bg.svg')"
              :class="{'opacity-50 pointer-events-none': stockHasValue(stock)}"
              @click="removeInstrument(stock)"
            >
          </div>
        </div>
      </div>
      <PulseLoader class="flex justify-center py-4" v-if="loadingNewPage && currentPage < pagesNumber"/>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import StockSecurityLogo from '@/components/subcomponents/StockSecurityLogo.vue'

export default {
  name: 'InstrumentsTableMobile',
  components: {StockSecurityLogo},
  props: {
    stockSecurities: {
      type: Array,
      default: () => []
    },
    pagesNumber: {
      type: Number,
      default: 1
    },
    currentPage: {
      type: Number,
      default: 1
    },
    addedInstruments: {
      type: Array,
      default: () => []
    },
    editingPie: {
      type: Boolean,
      default: false
    },
    search: {
      type: Number,
      default: 0
    },
    maxNumberOfInstruments: {
      type: [Number, String],
      default: 0
    }
  },
  computed: {
    ...mapGetters({
      newPie: 'pies/newPie',
      addedStockSecurities: 'pies/addedStockSecurities'
    })
  },
  data () {
    return {
      loadingNewPage: false,
      newAddedInstruments: [],
      deletedInstruments: [],
      colorBlock: [
        'light-blue',
        'dark-blue',
        'yellow',
        'primary',
        'pink'
      ]
    }
  },
  mounted () {

  },
  methods: {
    ...mapActions({
      setAddedStockSecurities: 'pies/setAddedStockSecurities',
      setDeletedStockSecurities: 'pies/setDeletedStockSecurities'
    }),

    stockHasValue (stock) {
      let instrument = this.addedInstruments.filter(item => item.stock_security.id === stock.id)
      return instrument.length ? instrument[0].current_value > 0 : false
    },

    onScroll (e) {
      if (this.currentPage >= this.pagesNumber) return

      let table = document.getElementsByClassName('table-stock-securities')[0]

      if (parseInt(e.target.children[e.target.children.length - 1].getBoundingClientRect().bottom) ===
        parseInt(table.getBoundingClientRect().bottom)) {
        this.loadingNewPage = true
        this.$emit('nextPage')
      }
    },

    addingInstrument (instrument) {
      let alreadyAddedStocks = this.addedStockSecurities
      let addedInstrument = {
        stock_security_id: instrument.id,
        stock_security: instrument,
        target_percentage_of_pie: 0
      }

      if (this.editingPie) {
        this.newAddedInstruments.push(instrument.id)
        alreadyAddedStocks.push(instrument.id)
        this.setAddedStockSecurities(alreadyAddedStocks)
      }

      this.$emit('addedInstrument', addedInstrument)
    },

    removeInstrument (instrument) {
      if (this.newAddedInstruments.filter(item => item === instrument.id).length) {
        this.newAddedInstruments = this.newAddedInstruments.filter(item => item !== instrument.id)
      }
      this.$emit('removeInstrument', instrument.id)
    },

    addedInstrument (instrument) {
      return !!this.addedInstruments.filter(item => item.stock_security.id === instrument.id).length
    },

    addedNewInstrument (instrument) {
      return !!this.newAddedInstruments.filter(item => item === instrument.id).length
    }
  },
  watch: {
    stockSecurities: {
      handler (stockSecurities) {
        if (stockSecurities.length) {
          this.loadingNewPage = false
        }
      },
      deep: true
    }
  }
}
</script>
