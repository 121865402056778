<template>
  <div>
    <general-layout>
      <template v-slot:title>
        <vue-title :title="$t('general.meta_titles.two_factor_authentication_activation')"></vue-title>
      </template>

      <template v-slot:tabs>
        <Tabs :show-id-title="false" :tabs-classes="tabsClasses"/>
      </template>

      <template v-slot:body>
        <Card class="m-bottom-custom w-full" :card-classes="'mobile-card-portal'">
          <template v-slot:body>
            <TwoFactorAuthenticationActivation/>
          </template>
        </Card>
      </template>

      <template v-slot:observations>
        <div>
          <Card class="w-full" :card-classes="'mobile-card-resources padding-small'">
            <template v-slot:body>
              <div>
                <p v-html="$t('guides.two_factor_activation.two_factor_p1')"/>
                <br>
                <p v-html="$t('guides.two_factor_activation.two_factor_p2')"/>
              </div>
            </template>
          </Card>
        </div>
      </template>

      <template v-slot:resources>
        <div class="mt-4">
          <div class="left-side-title">{{ $t('useful_links.general.useful_links') }}</div>

          <Card class="mt-4 w-full"
                :card-classes="'grey-card mobile-card-resources padding-small'">
            <template v-slot:body>
              <div>
                <a class="color-blue" target="_blank" :href="wpLink('/faq/cum-ma-autentific-cu-doi-factori-2fa/')">
                  {{ $t('useful_links.two_factor_activation.two_factor_what_is') }}
                </a>
              </div>

              <div class="mt-2">
                <a class="color-blue" target="_blank" :href="wpLink('/faq/cum-imi-activez-autentificarea-cu-doi-factori-2fa/')">
                  {{ $t('useful_links.two_factor_activation.two_factor_activate') }}
                </a>
              </div>

              <div class="mt-2">
                <a class="color-blue" target="_blank" :href="wpLink('/faq/cum-ma-autentific-cu-doi-factori-2fa/')">
                  {{ $t('useful_links.two_factor_activation.two_factor_auth') }}
                </a>
              </div>

              <div class="mt-2">
                <a class="color-blue" target="_blank" :href="wpLink('/faq/nu-mai-am-acces-la-aplicatia-de-autentificare-cu-doi-factori-cum-ma-pot-autentifica/')">
                  {{ $t('useful_links.two_factor_activation.two_factor_cant_access') }}
                </a>
              </div>

              <div class="mt-2">
                <a class="color-blue" target="_blank" :href="wpLink('/faq/vreau-sa-mi-dezactivez-2fa-cum-fac/')">
                  {{ $t('useful_links.two_factor_activation.two_factor_disable') }}
                </a>
              </div>
            </template>
          </Card>
        </div>
      </template>
    </general-layout>
  </div>
</template>

<script>
import GeneralLayout from '../layout/GeneralLayout'
import Tabs from '../layout/Tabs'
import Card from '../subcomponents/Card'
import TwoFactorAuthenticationActivation from '../auth/subcomponents/TwoFactorAuthenticationActivation.vue'

export default {
  name: 'TwoFactorAuthenticationActivationPage',
  components: {
    TwoFactorAuthenticationActivation,
    GeneralLayout,
    Tabs,
    Card
  },
  data () {
    return {
      tabsClasses: ['mb-4']
    }
  }
}
</script>

<style scoped></style>
