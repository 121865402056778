<template>
  <div>
    <div class="questionnaire-component">
      <div class="progress-bar bg-gray-200 m-top-custom m-bottom-custom">
        <div :style="progressBarLength" class="bar">{{ progressBar }}%</div>
      </div>

      <div v-for="(question, questionIndex) in questions" :key="questionIndex">
        <div v-if="questionIndex === selectedQuestion">
          <ButtonQuestion
            v-if="(question.type === 'button' || question.type === 'radio') && !skipQuestions.includes(question.identifier)"
            :pre-selected-answer="preSelectedAnswer(question)"
            :question="question"
            @setAnswers="setAnswers"/>

          <SelectorQuestion v-if="question.type === 'selector' && !skipQuestions.includes(question.identifier)"
                            :pre-selected-answer="preSelectedAnswer(question)"
                            :question="question"
                            @setAnswers="setAnswers"/>

          <MultipleQuestion v-if="question.type === 'group' && !skipQuestions.includes(question.identifier)"
                            :identifier="question.identifier"
                            :questions="question.subquestions"
                            :disabled-question="false"
                            :questions-answers="preSelectedAnswer(question)"
                            @setAnswers="setAnswers"/>

          <CheckboxQuestion v-if="question.type === 'checkbox' && !skipQuestions.includes(question.identifier)"
                            :pre-selected-answers="preSelectedAnswer(question)"
                            :question="question"
                            :restrictions="restrictions[question.identifier]"
                            @setAnswers="setAnswers"/>
        </div>
      </div>

      <Message v-if="validation.general" :message="validation.general" :type="'error'" class="mb-5"/>

      <Message v-if="validation.details" :message="validation.details" :type="'error'" class="mb-5" :formatIsHtml="true"/>

      <div class="nav m-top-custom-l flex justify-between">
        <Button :icon="'icons/arrow_icon_back'"
                :icon-position="'left'"
                :text="$t('general.questionnaire.back')"
                :type="'btn btn-secondary'"
                class="prev"
                @call="back"/>

        <Button
                :text="(questions.length === selectedQuestion + 1) ? $t('general.questionnaire.finish') : $t('general.general.continue')"
                :type="'btn btn-primary padding-medium end-position'"
                class="next"
                :loading="loadingData.continue"
                @call="next"/>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonQuestion from './question-types/ButtonQuestion'
import SelectorQuestion from './question-types/SelectorQuestion'
import Button from '../subcomponents/Button'
import MultipleQuestion from './question-types/MultipleQuestion'
import CheckboxQuestion from './question-types/CheckboxQuestion'
import Message from '../subcomponents/Message'

export default {
  name: 'Main',
  components: {CheckboxQuestion, MultipleQuestion, ButtonQuestion, SelectorQuestion, Button, Message},
  props: {
    questions: {
      type: Array,
      required: true
    },
    questionsAnswers: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      loadingData: {
        continue: false
      },
      selectedQuestion: 0,
      selectedQuestionsAnswers: {},
      validation: {
        general: '',
        details: ''
      },
      tmpIdentifier: '',
      tmpAnswer: {},
      restrictions: {},
      skipQuestions: []
    }
  },
  beforeMount () {
    this.selectedQuestionsAnswers = this.questionsAnswers

    if (Object.values(this.selectedQuestionsAnswers).length) {
      this.selectedQuestion = Object.values(this.selectedQuestionsAnswers).length
    }

    if (Object.values(this.selectedQuestionsAnswers).length === this.questions.length) {
      this.selectedQuestion--
    }
  },
  computed: {
    progressBarLength () {
      return 'width:' + this.progressBar + '%'
    },
    progressBar () {
      return Math.round(
        ((this.selectedQuestion + 1) * 100) / this.questions.length
      )
    }
  },
  methods: {
    preSelectedAnswer (question) {
      if (typeof this.selectedQuestionsAnswers[question.identifier] === 'string' &&
        this.selectedQuestionsAnswers[question.identifier] === 'N/A') {
        return {}
      }

      return this.selectedQuestionsAnswers[question.identifier]
    },
    setAnswers (identifier, answers) {
      this.tmpAnswer = answers
      this.tmpIdentifier = identifier

      this.resetValidation()

      this.$set(this.selectedQuestionsAnswers, identifier, answers)

      if (typeof answers === 'object' && !Object.keys(answers).length) {
        delete this.selectedQuestionsAnswers[identifier]
      }

      if (!answers) {
        delete this.selectedQuestionsAnswers[identifier]
      }

      this.updateRestrictionsAndSkipQuestions()
    },
    updateRestrictionsAndSkipQuestions () {
      this.restrictions = {}
      this.skipQuestions = []

      for (const [questionIdentifier, answers] of Object.entries(this.selectedQuestionsAnswers)) {
        let question = this.questions.filter((question) => question.identifier === questionIdentifier)[0]

        if (question.type !== 'group') {
          for (const answerIdentifier of Object.keys(answers)) {
            let answerFromQuestion = question.answers.filter((questionAnswer) => questionAnswer.identifier === answerIdentifier)[0]

            if (!answerFromQuestion) {
              continue
            }

            if (answerFromQuestion.restrictions) {
              this.addRestrictions(answerFromQuestion.restrictions)
            }

            if (answerFromQuestion.skipQuestions) {
              this.skipQuestions = this.skipQuestions.concat(answerFromQuestion.skipQuestions)
            }
          }
        }
      }
    },
    addRestrictions (restrictions) {
      for (const [questionIdentifier, answerRestrictions] of Object.entries(restrictions)) {
        this.$set(this.restrictions, questionIdentifier, answerRestrictions)
      }
    },
    back () {
      if (this.selectedQuestion === 0) {
        this.$emit('showIntro')

        return
      }

      if (this.skipQuestion(this.selectedQuestion - 1)) {
        this.back()

        return
      }

      this.resetValidation()
      this.$emit('back', this.selectedQuestionsAnswers)
      this.selectedQuestion--
    },
    finish () {
      this.resetValidation()
      this.$emit('finish', this.selectedQuestionsAnswers)
    },
    skipQuestion (selectedQuestion) {
      if (!this.questions[selectedQuestion]) {
        return false
      }

      if (!this.skipQuestions.includes(this.questions[selectedQuestion].identifier)) {
        return false
      }

      this.setAnswers(this.questions[selectedQuestion].identifier, 'N/A')
      this.selectedQuestion = selectedQuestion

      return true
    },
    next () {
      this.loadingData.continue = true

      try {
        if (this.skipQuestion(this.selectedQuestion + 1)) {
          this.next()
          return
        }
        if (!this.validate()) {
          return
        }

        this.tmpIdentifier = ''
        this.tmpAnswer = {}

        this.resetValidation()

        if (this.selectedQuestion === this.questions.length - 1) {
          this.$emit('finish', this.selectedQuestionsAnswers)

          return
        }

        this.$emit('next', this.selectedQuestionsAnswers)

        this.selectedQuestion++
      } finally {
        this.loadingData.continue = false
      }
    },
    validate () {
      if (Object.keys(this.selectedQuestionsAnswers).length <= this.selectedQuestion) {
        this.validation.general = this.$i18n.t('general.validations.choose_answer')

        return false
      }

      if (this.tmpIdentifier !== '' && this.selectedQuestionsAnswers[this.tmpIdentifier] !== 'N/A') {
        let question = this.questions.filter((question) => question.identifier === this.tmpIdentifier)[0]
        let tmpAnswer = this.tmpAnswer

        if (question.type === 'group') {
          let questionIndex

          [questionIndex, tmpAnswer] = Object.entries(this.tmpAnswer)[0]
          question = question.subquestions.filter((subquestion) => subquestion.identifier === questionIndex)[0]
        }

        if (!tmpAnswer) {
          this.validation.general = this.$i18n.t('general.validations.choose_answer')

          return false
        }

        for (const [answerIdentifier, details] of Object.entries(tmpAnswer)) {
          let answer = question.answers.filter(answer => answer.identifier === answerIdentifier)[0]

          if (!answer.requiredDetails) {
            continue
          }

          let answerType = question.answers.find(ans => ans.identifier === Object.keys(this.tmpAnswer)[0])

          if (answerType && answerType.details && answerType.details[0].type === 'addressComponent') {
            if (!Object.values(details)[0]) {
              this.validation.details = this.$i18n.t('general.validations.address_field')

              return false
            } else {
              let fullAddress = Object.values(details)[0]

              if (fullAddress.country === '' || (!fullAddress.county && fullAddress.country_code === 'RO') ||
                !fullAddress.locality || !fullAddress.street || !fullAddress.number) {
                this.validation.details = `<div>${this.$i18n.t('general.validations.address_partial_error')}<ul class="custom-ul">`

                if (fullAddress.country === '') {
                  this.validation.details += `<li>${this.$i18n.t('general.validations.address_fields.country')}</li>`
                }

                if (!fullAddress.county && fullAddress.country_code === 'RO') {
                  this.validation.details += `<li>${this.$i18n.t('general.validations.address_fields.county')}</li>`
                }

                if (!fullAddress.locality) {
                  this.validation.details += `<li>${this.$i18n.t('general.validations.address_fields.locality')}</li>`
                }

                if (!fullAddress.street) {
                  this.validation.details += `<li>${this.$i18n.t('general.validations.address_fields.street')}</li>`
                }

                if (!fullAddress.number) {
                  this.validation.details += `<li>${this.$i18n.t('general.validations.address_fields.number')}</li>`
                }

                if (!fullAddress.zip_code) {
                  this.validation.details += `<li>${this.$i18n.t('general.validations.address_fields.zip_code')}</li>`
                }

                this.validation.details += `</ul></div>`

                return false
              }
            }
          }

          if (!Object.values(details).length) {
            this.validation.details = this.$i18n.t('general.validations.complete_field')

            return false
          }

          for (const detail of Object.values(details)) {
            if (!detail) {
              this.validation.details = this.$i18n.t('general.validations.complete_field')

              return false
            }
          }
        }
      }
      return true
    },
    resetValidation () {
      this.validation = {
        general: '',
        details: ''
      }
    }
  }
}
</script>

<style scoped>

</style>
