<template>
  <div class="checkbox" :class="{'checkbox-white' : checkboxWhite}">
    <div class="relative">
      <div
        :class="[type === 'button'? 'custom-checkbox' : 'custom-radio',{'checked': isChecked !== null ? isChecked : value}]"></div>
      <input v-if="type === 'button'" :id="id" :name="name" :value="value" class="checkbox-primary" type="checkbox"
             @change="checked" @keyup.enter="enterClicked">
    </div>

    <label :for="id" class="cursor-pointer" v-html="label"></label>
    <img v-if="icon" class="icon-checkbox" width="50" :src="require(`@/assets/img/icons/${icon}.png`)" alt="2fa"/>
  </div>
</template>

<script>
export default {
  name: 'Checkbox',
  props: {
    id: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    value: {
      type: [Boolean, Number, Array, Object, String],
      default: null
    },
    dataValue: {
      type: [Array, Object, String],
      default: null
    },
    exitentData: {
      type: [Array, Object, String],
      default: null
    },
    label: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'button'
    },
    checkboxWhite: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isChecked: null
    }
  },
  mounted () {
    this.checkIfExists()
  },
  methods: {
    checked (e) {
      if (this.dataValue) {
        e.target.checked ? this.isChecked = true : this.isChecked = false
        this.$emit('input', this.dataValue)
      } else {
        this.$emit('input', !this.value)
      }
    },
    checkIfExists () {
      if (this.exitentData) {
        this.isChecked = this.exitentData.indexOf(this.dataValue) !== -1
      }
    },
    enterClicked () {
      this.$emit('on-key-enter')
    }
  }
}
</script>

<style scoped>

</style>
