export default {
  data () {
    return {
      sections: [
        {
          title: this.$i18n.t('help.sections.transfers_payments.title'),
          links: [
            {
              label: this.$i18n.t('help.sections.transfers_payments.labels.add_iban'),
              link: 'https://alpha.investimental.ro/faq/vreau-sa-adaug-un-nou-cod-iban-cum-fac/',
              icon_color: 'yellow',
              icon_path: 'add',
              position: ['left']
            },
            {
              label: this.$i18n.t('help.sections.transfers_payments.labels.remove_iban'),
              link: 'https://alpha.investimental.ro/faq/vreau-sa-sterg-un-cod-iban-cum-fac/',
              icon_color: 'purple',
              icon_path: 'price_tag',
              position: ['top']
            },
            {
              label: this.$i18n.t('help.sections.transfers_payments.labels.charge_account'),
              link: 'https://alpha.investimental.ro/faq/vreau-sa-alimentez/',
              icon_color: 'purple',
              icon_path: 'yen',
              position: ['top']
            },
            {
              label: this.$i18n.t('help.sections.transfers_payments.labels.which_accounts_can_charge'),
              link: 'https://alpha.investimental.ro/faq/in-ce-conturi-investimental-pot-alimenta/',
              icon_color: 'orange',
              icon_path: 'change',
              position: ['bottom']
            },
            {
              label: this.$i18n.t('help.sections.transfers_payments.labels.time_to_receive_money_transaction'),
              link: 'https://alpha.investimental.ro/faq/in-cat-timp-ajung-banii-in-contul-de-tranzactionare/',
              icon_color: 'green',
              icon_path: 'insert_coin',
              position: ['right']
            },
            {
              label: this.$i18n.t('help.sections.transfers_payments.labels.withdraw_money'),
              link: 'https://alpha.investimental.ro/faq/cum-fac-retrageri/',
              icon_color: 'yellow',
              icon_path: 'get-money',
              position: ['left']
            },
            {
              label: this.$i18n.t('help.sections.transfers_payments.labels.time_to_receive_money'),
              link: 'https://alpha.investimental.ro/faq/in-cat-timp-ajung-banii-in-contul-meu-bancar-dupa-ce-am-solicitat-retragerea/',
              icon_color: 'blue',
              icon_path: 'flip_coin',
              position: ['bottom', 'right']
            }
          ]
        },
        {
          title: this.$i18n.t('help.sections.account_administration.title'),
          links: [
            {
              label: this.$i18n.t('help.sections.account_administration.labels.lost_access_to_email'),
              link: 'https://alpha.investimental.ro/faq/nu-mai-am-acces-la-email-ce-fac/',
              icon_color: 'pink',
              icon_path: 'multiply',
              position: ['right', 'bottom']
            },
            {
              label: this.$i18n.t('help.sections.account_administration.labels.lost_access_to_phone'),
              link: 'https://alpha.investimental.ro/faq/nu-mai-am-acces-la-numarul-de-telefon-ce-fac/',
              icon_color: 'green',
              icon_path: 'smartphone',
              position: ['right']
            },
            {
              label: this.$i18n.t('help.sections.account_administration.labels.arena_xt_account'),
              link: 'https://alpha.investimental.ro/faq/care-este-contul-meu-de-utilizator-arena-xt/',
              icon_color: 'pink',
              icon_path: 'id-card',
              position: ['right', 'bottom']
            },
            {
              label: this.$i18n.t('help.sections.account_administration.labels.forgot_password'),
              link: 'https://alpha.investimental.ro/faq/ce-fac-daca-am-uitat-parola/',
              icon_color: 'yellow',
              icon_path: 'compass',
              position: ['left']
            },
            {
              label: this.$i18n.t('help.sections.account_administration.labels.change_password'),
              link: 'https://alpha.investimental.ro/faq/cum-imi-pot-schimba-parola-in-portal/',
              icon_color: 'green',
              icon_path: 'lock',
              position: ['right']
            },
            {
              label: this.$i18n.t('help.sections.account_administration.labels.email_not_received'),
              link: 'https://alpha.investimental.ro/faq/nu-am-primit-email-ul-pentru-resetarea-parolei-ce-fac/',
              icon_color: 'pink',
              icon_path: 'minus',
              position: ['bottom', 'right']
            },
            {
              label: this.$i18n.t('help.sections.account_administration.labels.single_sign_on'),
              link: 'https://alpha.investimental.ro/faq/ce-insemna-autentificare-single-sign-on-sso-si-la-ce-ma-ajuta/',
              icon_color: 'orange',
              icon_path: 'graph',
              position: ['bottom']
            }
          ]
        },
        {
          title: this.$i18n.t('help.sections.open_account.title'),
          links: [
            {
              label: this.$i18n.t('help.sections.open_account.labels.proof_of_identity'),
              link: 'https://alpha.investimental.ro/faq/cu-ce-document-fac-dovada-adresei/',
              icon_color: 'yellow',
              icon_path: 'record',
              position: ['left']
            },
            {
              label: this.$i18n.t('help.sections.open_account.labels.proof_of_address'),
              link: 'https://alpha.investimental.ro/faq/cu-ce-document-fac-dovada-adresei-2/',
              icon_color: 'blue',
              icon_path: 'bookmark',
              position: ['right', 'bottom']
            },
            {
              label: this.$i18n.t('help.sections.open_account.labels.proof_of_residence'),
              link: 'https://alpha.investimental.ro/faq/documente-rezidenta/',
              icon_color: 'pink',
              icon_path: 'home',
              position: ['right', 'bottom']
            },
            {
              label: this.$i18n.t('help.sections.open_account.labels.what_is_mifid'),
              link: 'https://alpha.investimental.ro/faq/ce-este-mifid/',
              icon_color: 'orange',
              icon_path: 'bank',
              position: ['bottom']
            }
          ]
        }
      ]
    }
  }
}
