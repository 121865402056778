import auth from './en/auth'
import guides from './en/guides'
// eslint-disable-next-line camelcase
import useful_links from './en/useful-links'
import onboarding from './en/onboarding'
import general from './en/general'
// eslint-disable-next-line camelcase
import deposit_withdrawal from './en/profile/deposit-withdrawal'
import settings from './en/profile/settings'
import notifications from './en/profile/notifications'
import documents from './en/profile/documents'
import help from './en/profile/help'
// eslint-disable-next-line camelcase
import update_personal_data from './en/profile/update-personal-data'

export default {
  auth,
  guides,
  useful_links,
  onboarding,
  general,
  settings,
  deposit_withdrawal,
  notifications,
  documents,
  update_personal_data,
  help
}
