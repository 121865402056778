<template>
  <div class="personal-data portofolio-page">
    <general-layout>
      <template v-slot:title>
        <vue-title :title="$t('general.meta_titles.portofolio')"></vue-title>
      </template>

      <template v-slot:tabs>
        <Tabs
          :show-id-title="false"
        />
      </template>

      <template v-slot:body>

        <div>
          <div class="w-full">
            <SelectDropDown
              v-if="multiAccounts.length > 1"
              v-model="selectedAccount"
              :options="multiAccounts"
              :clearable="false"
              :display-key="'name'"
              :label="$t('deposit_withdrawal.portfolio.select_portfolio')"
              :class="isMobile ? 'w-full' : 'w-1/4'"
              class="mb-4"
            />
            <Card class="relative w-full line-chart-card" v-if="!loading">
              <template v-slot:body>
                <LineChart
                  :selected="selectedAccount"
                  :total="totalAssets.totals.totalAssetsEvaluationInRon.toLocaleString('en-US', {maximumFractionDigits: 2})"
                ></LineChart>
              </template>
            </Card>
          </div>

          <div class="portfolio" v-if="!loading">
            <div v-if="!isMobile">

              <AssetsTableDesktop
                v-if="ronAssets.assets.length"
                :color-symbol="'green'"
                :assets="ronAssets"
                :instrument-name="getInstrumentName('RON')"
              />
              <AssetsTableDesktop
                v-if="eurAssets.assets.length"
                :color-symbol="'yellow'"
                :assets="eurAssets"
                :instrument-name="getInstrumentName('EUR')"
                class="mt-9"
              />
              <AssetsTableDesktop
                v-if="usdAssets.assets.length"
                :color-symbol="'blue'"
                :assets="usdAssets"
                :instrument-name="getInstrumentName('USD')"
                class="mt-9"
              />

            </div>
            <div v-if="isMobile">

              <AssetsTableMobile
                v-if="ronAssets.assets.length"
                :color-symbol="'green'"
                :assets="ronAssets"
                :instrument-name="getInstrumentName('RON')"

              />
              <AssetsTableMobile
                v-if="eurAssets.assets.length"
                :color-symbol="'yellow'"
                :assets="eurAssets"
                :instrument-name="getInstrumentName('EUR')"
                class="mt-9"
              />
              <AssetsTableMobile
                v-if="usdAssets.assets.length"
                :color-symbol="'blue'"
                :assets="usdAssets"
                :instrument-name="getInstrumentName('USD')"
                class="mt-9"
              />

            </div>

            <div class="portfolio-total">
              <div>{{ $t('deposit_withdrawal.portfolio.total_general') }}</div>
              <div>{{ totalAssets.totals.totalAssetsEvaluationInRon.toLocaleString('en-US', {maximumFractionDigits: 2}) }} RON</div>
            </div>
          </div>

          <div class="pie-charts" v-if="!loading">
            <div class="first-row">
              <div class="first-element">
                <Card class="relative h-full" >
                  <template v-slot:title>
                    <div class="title-chart right-side">
                      <div class="w-fit">{{ $t('deposit_withdrawal.portfolio.my_portfolio') }}:</div>
                      <div class="font-normal uppercase w-fit">{{ $t('deposit_withdrawal.portfolio.currency') }}</div>
                    </div>
                  </template>
                  <template v-slot:body>
                    <DoughnutChart
                        :with-currency="true"
                        :legend-place="'right'"
                        :chart-id="'total'"
                        :total="totalAssets.totals.totalAssetsEvaluationInRon"
                        :chart-data-sets="getDataForCurrencyChart()"
                    />
                  </template>
                </Card>
              </div>
              <div class="second-element">
                <Card class="relative h-full">
                  <template v-slot:title>
                    <div class="title-chart right-side">
                      <div class="w-fit">{{ $t('deposit_withdrawal.portfolio.my_portfolio') }}:</div>
                      <div class="font-normal uppercase w-fit">{{ $t('deposit_withdrawal.portfolio.yield') }}</div>
                    </div>
                  </template>
                  <template v-slot:body>
                    <DoughnutChart
                        :with-currency="true"
                        :legend-place="'right'"
                        :chart-id="'totalVariation'"
                        :total="totalAssets.totals.totalAssetsEvaluationInRon"
                        :chart-data-sets="getDataForVariationChart()"
                    />
                  </template>
                </Card>
              </div>
            </div>
            <div
              class="second-row w-full"
              :class="[!isMobile ? `grid-cols-${numberOfCharts} mt-8 mx-auto` : '', numberOfCharts !== 3 ? 'not-all-charts' : '']">
              <div class="third-element" v-if="totalAssets.totals.ronAssetsEvaluation">
                <Card class="h-full relative" >
                  <template v-slot:title>
                    <div class="title-chart">{{ getInstrumentName('RON') }}</div>
                  </template>

                  <template v-slot:body>
                    <DoughnutChart
                        :chart-id="'ronTotal'"
                        :total="totalAssets.totals.ronAssetsEvaluation"
                        :chart-data-sets="getDataForSpecificCurrencyChart('RON')"
                    />
                  </template>
                </Card>
              </div>
              <div class="fourth-element" v-if="totalAssets.totals.eurAssetsEvaluation">
                <Card class="h-full relative">
                  <template v-slot:title>
                    <div class="title-chart">{{ getInstrumentName('EUR') }}</div>
                  </template>
                  <template v-slot:body>
                    <DoughnutChart
                      :chart-id="'eurTotal'"
                      :total="totalAssets.totals.eurAssetsEvaluation"
                      :chart-data-sets="getDataForSpecificCurrencyChart('EUR')"
                    />
                  </template>
                </Card>
              </div>
              <div class="fifth-element" v-if="totalAssets.totals.usdAssetsEvaluation">
                <Card class="h-full relative">
                  <template v-slot:title>
                    <div class="title-chart">{{ getInstrumentName('USD') }}</div>
                  </template>
                  <template v-slot:body>
                    <DoughnutChart
                      :chart-id="'usdTotal'"
                      :total="totalAssets.totals.usdAssetsEvaluation"
                      :chart-data-sets="getDataForSpecificCurrencyChart('USD')"
                    />
                  </template>
                </Card>
              </div>
            </div>

          </div>
        </div>

        <div class="flex justify-center">
          <PulseLoader v-if="loading"/>
        </div>

      </template>
    </general-layout>
  </div>
</template>

<script>
import Tabs from '../../layout/Tabs.vue'
import GeneralLayout from '../../layout/GeneralLayout.vue'
import AssetsTableDesktop from '../../subcomponents/assets/AssetsTableDesktop.vue'
import ApiService from '../../../services/apiService'
import DoughnutChart from '@/components/subcomponents/charts/DoughnutChart.vue'
import Card from '@/components/subcomponents/Card.vue'
import PortofolioData from '@/mixins/PortofolioData'
import AssetsTableMobile from '@/components/subcomponents/assets/AssetsTableMobile.vue'
import {mapGetters} from 'vuex'
import LineChart from '@/components/subcomponents/charts/LineChart.vue'
import SelectDropDown from '@/components/subcomponents/SelectDropDown.vue'

export default {
  name: 'Portfolio',
  mixins: [PortofolioData],
  components: {
    SelectDropDown,
    LineChart,
    AssetsTableMobile,
    DoughnutChart,
    GeneralLayout,
    Tabs,
    AssetsTableDesktop,
    Card
  },
  computed: {
    ...mapGetters({
      screenDevice: 'layout/screenDevice'
    }),
    isMobile () {
      return this.screenDevice === this.$screenDeviceConstants.MOBILE
    },
    numberOfCharts () {
      if (this.totalAssets.totals.ronAssetsEvaluation && this.totalAssets.totals.eurAssetsEvaluation && this.totalAssets.totals.usdAssetsEvaluation) {
        return 3
      } else if ((this.totalAssets.totals.ronAssetsEvaluation && this.totalAssets.totals.eurAssetsEvaluation && !this.totalAssets.totals.usdAssetsEvaluation) ||
        (this.totalAssets.totals.ronAssetsEvaluation && this.totalAssets.totals.usdAssetsEvaluation && !this.totalAssets.totals.eurAssetsEvaluation) ||
        (this.totalAssets.totals.eurAssetsEvaluation && this.totalAssets.totals.usdAssetsEvaluation && !this.totalAssets.totals.ronAssetsEvaluation)) {
        return 2
      } else {
        return 1
      }
    }
  },
  methods: {
    getInstrumentName (currency) {
      if (this.selectedAccount.alias) {
        return this.selectedAccount.aliases[currency]
      } else {
        return this.mainAccounts.filter(account => account.currency === currency)[0].name
      }
    }
  },
  async created () {
    await this.getMultiAccounts()
    await this.getPortfolio(this.selectedAccount.alias)
    this.alterDataForTables()
  },
  watch: {
    selectedAccount: {
      handler: async function (val) {
        await this.getPortfolio(val.alias)
        this.alterDataForTables()
      },
      deep: true
    }
  }

}

</script>
