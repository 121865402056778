<template>
  <div class="bank-accounts">
    <div :class="{'flex gap-7': isTablet}">
      <div class="w-full">
        <div class="bank-accounts-data">
          <div>
            <div v-if="newIbanIsAdded">
              <AddBankAccount :bank-accounts="bankAccounts" @setData="setData" @cancelNewIbanIsAdded="cancelNewIbanIsAdded"/>
            </div>

            <Transition-Group appear name="fade">
              <template v-if="bankAccounts && !newIbanIsAdded">
                <div
                  class="info bank-account mb-8"
                  v-for="(bankAccount, counter) in bankAccounts" :key="counter" ref="bankAccountItem">
                  <BankAccountItem
                    :deletable="Object.keys(bankAccounts).length > 1"
                    :bank-account="bankAccount"
                    :counter="counter"
                    :key="counter"
                    @setData="setData"/>
                </div>
              </template>
            </Transition-Group>
          </div>
        </div>

        <div class="padding-card mt-4">
          <Button
            class="m-bottom-custom"
            v-if="!newIbanIsAdded"
            :text="$t('deposit_withdrawal.bank_accounts.add_new_iban')"
            @call="setNewIbanIsAdded"
            :type="'btn-primary'"
          />
        </div>
      </div>

      <div :class="{'mt-8': !isTablet}" class="w-full">
        <Resources :links="resourcesLinks"/>
      </div>
    </div>
  </div>
</template>

<script>
import GeneralLayout from '../../layout/GeneralLayout'
import Tabs from '../../layout/Tabs'
import Button from '../../subcomponents/Button'
import InputText from '../../subcomponents/InputText'
import Card from '../../subcomponents/Card'
import IconBox from '../../subcomponents/IconBox.vue'
import Message from '../../subcomponents/Message'
import ContinueToOnboarding from '../ContinueToOnboarding'
import SelectDropDown from '../../subcomponents/SelectDropDown'
import Checkbox from '../../subcomponents/Checkbox.vue'
import BankAccountItem from './BankAccountItem.vue'
import AddBankAccount from './AddBankAccount.vue'
import Resources from '../../subcomponents/Resources.vue'
import BankAccountsService from '../../../services/deposit-withdrawal/bankAccountsService'
import {mapGetters} from 'vuex'

export default {
  name: 'BankAccountsMobile',
  components: { Resources, AddBankAccount, BankAccountItem, Checkbox, GeneralLayout, Tabs, Button, InputText, Card, IconBox, Message, ContinueToOnboarding, SelectDropDown },
  props: {
    bankAccountsDefault: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      bankAccounts: this.bankAccountsDefault,
      newIbanIsAdded: false,
      resourcesLinks: [
        { label: this.$t('useful_links.bank_accounts.bank_accounts_how_to_add_iban'), value: '/faq/vreau-sa-adaug-un-nou-cod-iban-cum-fac/' },
        { label: this.$t('useful_links.bank_accounts.bank_accounts_how_to_delete_iban'), value: '/faq/vreau-sa-sterg-un-cod-iban-cum-fac/' }
      ],
      newIban: null,
      isRevolutIbanChecked: false,
      isRevolutIban: false,
      validation: '',
      selectedCurrency: ''
    }
  },
  methods: {
    async setData () {
      let bankAccounts = await BankAccountsService.getBankAccounts()

      this.bankAccounts = bankAccounts.data.data.bankAccounts
    },
    cancelNewIbanIsAdded () {
      this.newIbanIsAdded = false
    },
    async setNewIbanIsAdded () {
      this.newIbanIsAdded = true
      this.newIban = null
      this.newIbanDocument = null
      this.isRevolutIbanChecked = false
      this.isRevolutIban = false
      this.validation = ''
      this.selectedCurrency = ''
    }
  },
  watch: {
    newIban: {
      handler (newIbanValue) {
        if (newIbanValue) {
          this.newIban = this.newIban.replace(/\s/g, '')
          this.isRevolutIban = newIbanValue.slice(4, 8) === 'BREL'
        } else {
          this.isRevolutIban = false
        }
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters({
      screenDevice: 'layout/screenDevice'
    }),
    isTablet () {
      return this.screenDevice === this.$screenDeviceConstants.TABLET
    }
  }
}
</script>

<style scoped></style>
