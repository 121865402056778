<template>
  <div class="personal-data">
    <general-layout>
      <template v-slot:title>
        <vue-title :title="$t('general.meta_titles.withdrawals')"></vue-title>
      </template>

      <template v-slot:tabs>
        <Tabs
          :show-id-title="false"
        />
      </template>

      <template v-slot:body>
        <div v-if="!hasAccessInPortal" class="desktop-flex gap-8">
          <ContinueToOnboarding/>

          <div :class="{'w-1/2' : !isMobile}">
            <Card v-if="!isMobile">
              <p> {{ $t('guides.withdrawals.withdrawals_p1') }} </p>
              <br>
              <b> {{ $t('guides.withdrawals.withdrawals_p2') }} </b>
            </Card>
          </div>
        </div>

        <div v-else>
          <PulseLoader class="w-fit m-auto p-10 justify-center flex" v-if="loading"/>

          <div v-else class="desktop-flex gap-8">
            <Card :class="[!isMobile ? 'w-1/2 !p-12' : '!py-11', isTablet ? 'h-fit !px-12 !pb-12 !pt-4' : '']"
                  :w100="true" :no-title="true"
                  :card-classes="'margin-bottom-big mobile-card-portal'">
              <template v-slot:title>
                <div>{{ $t("deposit_withdrawal.account_transfers.new_withdrawal") }}</div>
              </template>

              <template v-slot:body>
                <div class="my-2 flex">
                  <div class="mr-2">
                    {{ $t('deposit_withdrawal.account_transfers.available_sum') }}:
                  </div>

                  <div v-if="accountBalance && showCurrency">
                    {{ parseFloat(accountBalance).toLocaleString('en-US', {maximumFractionDigits: 2}) }} {{ fromAccount ? fromAccount.currency : null }}
                  </div>

                  <div v-else class="flex justify-center items-center">
                    <img class="h-4" src="@/assets/img/icons/clock.svg" alt="clock">
                  </div>
                </div>

                <div :class="{'desktop-flex': isDesktop}" class="gap-7 justify-between mb-5">
                  <div :class="[!isDesktop ? 'w-full' : 'w-1/2']">
                    <SelectDropDown
                      class="mb-5"
                      :id="'accountsFrom'"
                      :options="filterData('from')"
                      :label="$t('deposit_withdrawal.account_transfers.from')"
                      v-model="fromAccount"
                      @input="setSelectedAccount($event, 'from')"
                    />
                  </div>

                  <div class="flex gap-7 w-1/2" v-if="isDesktop">
                    <InputText
                      class="w-1/2"
                      :id="'accountFromSum'"
                      v-model="fromAccountSum"
                      :label="$t('deposit_withdrawal.account_transfers.sum')"
                    />

                    <InputText
                      class="w-1/2"
                      :id="'accountsFromCurrency'"
                      :value="fromAccount && showCurrency ? fromAccount.currency : null"
                      :read-only="true"
                      :label="$t('deposit_withdrawal.account_transfers.currency')"
                    />
                  </div>
                </div>

                <Message
                  v-if="validations.fromAccount"
                  :message="validations.fromAccount"
                  :type="'error'"
                  class="m-auto"
                />

                <div class="flex gap-7 justify-between">
                  <div :class="[!isDesktop ? 'w-full' : 'w-1/2']">
                    <div>
                      <SelectDropDown
                        :id="'accountsTo'"
                        :options="filterData('to')"
                        :label="$t('deposit_withdrawal.account_transfers.to')"
                        :show-currency="true"
                        :is-mobile="isMobile"
                        v-model="toAccount"
                        @input="setSelectedAccount($event, 'to')"
                      />

                      <Message
                        v-if="validations.toAccount"
                        :message="validations.toAccount"
                        :type="'error'"
                        class="m-auto"
                      />
                    </div>

                    <div class="mt-6 flex" v-if="!isDesktop">
                      <div class="w-1/2">
                        <InputText
                          :id="'accountFromSum'"
                          v-model="fromAccountSum"
                          :label="$t('deposit_withdrawal.account_transfers.sum')"
                        />

                        <InputText
                          class="mt-6"
                          :id="'accountsFromCurrency'"
                          :value="fromAccount && showCurrency ? fromAccount.currency : null"
                          :read-only="true"
                          :label="$t('deposit_withdrawal.account_transfers.currency')"
                        />
                      </div>

                      <img v-if="!isDesktop" class=" w-1/2" src="@/assets/img/icons/money-transfer.svg" alt="money-transfer">
                    </div>

                    <Button
                      class="mt-6"
                      :text="$t('deposit_withdrawal.withdrawals.withdraw')"
                      :loading="loading"
                      @call="sendData"
                      :type="'btn-primary'"
                    />
                  </div>

                  <img v-if="isDesktop" class=" w-1/2" src="@/assets/img/icons/money-transfer.svg" alt="money-transfer">
                </div>

                <Message
                  v-if="validations.sum"
                  :message="validations.sum"
                  :type="'error'"
                  class="m-auto"
                />

                <Message
                  :type="'success'"
                  v-if="validations.success"
                  :message="validations.success"
                  class="mb-5"
                />

                <Message
                  :type="'error'"
                  v-if="validations.fail"
                  :message="validations.fail"
                  :format-is-html="true"
                  class="mb-5"
                />

                <Message
                  :type="'error'"
                  v-if="validations.unpaidTaxes"
                  :message="validations.unpaidTaxes"
                  :format-is-html="true"
                  :show-close="true"
                  @close="closeValidation('unpaidTaxes')"
                  class="mb-5"
                />

                <p v-if="differentCurrency" class="font-bold">{{ $t('deposit_withdrawal.general.different_currencies') }}</p>

              </template>
            </Card>

            <div :class="{'w-1/2' : !isMobile}">
              <Card v-if="!isMobile">
                <p> {{ $t('guides.withdrawals.withdrawals_p1') }} </p>
                <br>
                <b> {{ $t('guides.withdrawals.withdrawals_p2') }} </b>
              </Card>

              <Resources :class="[isMobile ? 'mt-8' : 'mt-4']" :links="resourcesLinks"/>
            </div>
          </div>
        </div>
      </template>
    </general-layout>
  </div>
</template>

<script>
/* eslint-disable camelcase */
import GeneralLayout from '../../layout/GeneralLayout'
import Tabs from '../../layout/Tabs'
import Button from '../../subcomponents/Button'
import {mapGetters} from 'vuex'
import BankAccountsService from '../../../services/deposit-withdrawal/bankAccountsService'
import DocumentService from '../../../services/documents/documentsService'

import InputText from '../../subcomponents/InputText'
import Card from '../../subcomponents/Card'
import IconBox from '../../subcomponents/IconBox.vue'
import ContinueToOnboarding from '../ContinueToOnboarding'
import Message from '../../subcomponents/Message'
import SelectDropDown from '../../subcomponents/SelectDropDown'
import Resources from '../../subcomponents/Resources'
import AccountTransfersService from '../../../services/deposit-withdrawal/accountTransfersService'
import WithdrawalsService from '@/services/deposit-withdrawal/withdrawalsService'
export default {
  name: 'Withdrawals',
  components: { GeneralLayout, Tabs, Button, InputText, Card, IconBox, ContinueToOnboarding, Message, SelectDropDown, Resources },
  data () {
    return {
      loading: true,
      resourcesLinks: [
        { label: this.$t('useful_links.withdrawals.withdrawal_guide'), value: '/ghid/ghid-retragere-numerar/' },
        { label: this.$t('useful_links.withdrawals.withdrawal_how_to'), value: '/faq/cum-fac-retrageri/' },
        { label: this.$t('useful_links.withdrawals.withdrawal_add_iban'), value: '/faq/vreau-sa-adaug-un-nou-cod-iban-cum-fac/' },
        { label: this.$t('useful_links.withdrawals.withdrawal_delete_iban'), value: '/faq/vreau-sa-sterg-un-cod-iban-cum-fac/' },
        { label: this.$t('useful_links.withdrawals.withdrawal_time'), value: '/faq/in-cat-timp-ajung-banii-in-contul-meu-bancar-dupa-ce-am-solicitat-retragerea/' }
      ],
      transactionalAccounts: [],
      accountBalance: '',
      fromAccount: '',
      fromAccountSum: '',
      toAccount: '',
      validations: {
        toAccount: '',
        fromAccount: '',
        sum: '',
        success: '',
        fail: '',
        unpaidTaxes: ''
      },
      bankAccounts: null,
      accountsList: [],
      showMessage: false,
      showCurrency: false
    }
  },
  async created () {
    if (this.isLogged) {
      await this.setData()
    }
  },
  watch: {
    fromAccount () {
      this.validations.fromAccount = ''
      this.serverError = ''

      this.getAvailableSum()
    },
    toAccount () {
      this.validations.toAccount = ''
      this.serverError = ''
    },
    fromAccountSum () {
      this.validations.sum = ''
      this.serverError = ''
    }
  },
  methods: {
    closeValidation (validation) {
      this.validations[validation] = ''
    },

    async getAvailableSum () {
      if (this.fromAccount) {
        await AccountTransfersService.getAccountBalance({
          internalAccount: this.fromAccount.code,
          internalAccountSource: this.fromAccount.source
        })
          .then(response => {
            this.accountBalance = response.data.data.toString()
            this.showCurrency = true
          })
      } else {
        this.accountBalance = ''
      }
    },
    async setData () {
      let bankAccounts = await BankAccountsService.getBankAccounts()

      this.bankAccounts = bankAccounts.data.data.bankAccounts
      this.accountsList = this.bankAccounts

      let transactionalAccounts = await AccountTransfersService.getAccounts()

      this.transactionalAccounts = transactionalAccounts.data.data
      this.transactionalAccounts = this.transactionalAccounts
        .filter(account => (account.source === 'arenaxt' && account.status.toLowerCase() !== 'closed') || (account.source !== 'arenaxt' && !account.is_from_pie))

      this.transactionalAccounts
        .forEach(account => {
          account.name = (account.source === 'arenaxt' ? 'BVB' : 'GLOBAL') + ' ' +
            account.alias + ' ' + account.currency
        })

      this.loading = false
    },
    async download (doc) {
      await DocumentService.downloadDocument(doc)
    },
    async sendData () {
      if (this.validate()) {
        this.loading = true

        try {
          await WithdrawalsService.sendWithdrawal({
            internalAccountCode: this.fromAccount.code,
            iban: this.toAccount.iban,
            value: this.fromAccountSum
          }).then((response) => {
            if (response.status === 200) {
              this.validations.success = this.$i18n.t('deposit_withdrawal.withdrawals.success')
              this.toAccount = ''
              this.fromAccount = ''
              this.fromAccountSum = ''

              setTimeout(() => {
                this.validations.success = ''
              }, 5000)
            }
          }).catch((error) => {
            if (error?.response?.data?.data?.error_unpaid_trades_taxes) {
              this.validations.unpaidTaxes = error?.response?.data?.message
            } else {
              this.validations.fail = this.$i18n.t('deposit_withdrawal.withdrawals.failed')
            }

            setTimeout(() => {
              this.validations.fail = ''
            }, 5000)

            setTimeout(() => {
              this.validations.unpaidTaxes = ''
            }, 30000)
          })
        } finally {
          this.loading = false
        }
      }
    },
    filterData (filter) {
      if (filter === 'to') {
        this.bankAccounts.forEach(account => {
          account.label = account.iban
        })

        if (!this.fromAccount) {
          return this.bankAccounts.filter(account => account.status === 'confirmed')
        }

        let accounts = this.bankAccounts.filter(account => account.currency === this.fromAccount.currency && account.status === 'confirmed')

        if (!accounts.length) {
          return this.bankAccounts.filter(account => account.status === 'confirmed')
        }

        return accounts
      } else if (filter === 'from' && this.transactionalAccounts) {
        this.transactionalAccounts.forEach(account => {
          account.label = account.name
        })

        if (!this.toAccount) {
          return this.transactionalAccounts
        }

        let accounts = this.transactionalAccounts.filter(account => account.currency === this.toAccount.currency)

        if (!accounts.length) {
          return this.transactionalAccounts
        }

        return accounts
      }
    },
    setSelectedAccount (selectedAccount, accountType) {
      if (accountType === 'from') {
        this.fromAccount = selectedAccount ? this.transactionalAccounts.find(account => account.code === selectedAccount.code) : null

        this.showCurrency = false
      } else {
        this.toAccount = selectedAccount ? this.bankAccounts.find(account => account.iban === selectedAccount.iban) : null
      }
    },
    validate () {
      let valid = true

      if (!this.toAccount) {
        this.validations.toAccount = this.$i18n.t('deposit_withdrawal.account_transfers.validation_account')
        valid = false
      }

      if (!this.fromAccount) {
        this.validations.fromAccount = this.$i18n.t('deposit_withdrawal.account_transfers.validation_account')
        valid = false
      }

      if (isNaN(this.fromAccountSum)) {
        this.validations.sum = this.$i18n.t('deposit_withdrawal.account_transfers.validation_sum_NaN')
        valid = false
      }

      if (this.fromAccountSum <= 0) {
        this.validations.sum = this.$i18n.t('deposit_withdrawal.account_transfers.validation_sum_greater_than_zero')
        valid = false
      }

      if (!this.fromAccountSum && this.fromAccount) {
        this.validations.sum = this.$i18n.t('deposit_withdrawal.account_transfers.validation_sum')
        valid = false
      }

      if (parseFloat(this.fromAccountSum) > parseFloat(this.accountBalance)) {
        this.validations.sum = this.$i18n.t('deposit_withdrawal.account_transfers.validation_insufficient_funds')
        valid = false
      }

      return valid
    },
    back () {
      this.fromAccount = ''
      this.toAccount = ''
      this.showMessage = false
    }
  },
  computed: {
    ...mapGetters({
      user: 'user/user',
      screenDevice: 'layout/screenDevice',
      hasAccessInPortal: 'user/hasAccessInPortal',
      isLogged: 'user/isLogged'
    }),

    differentCurrency () {
      if (!this.fromAccount || !this.toAccount) {
        return false
      }

      return this.fromAccount.currency !== this.bankAccounts.find(account => account.iban === this.toAccount.iban).currency
    },

    usdTransfer () {
      return this.fromAccount && this.fromAccount.currency === 'USD'
    },

    selectedCurrency () {
      if (this.toAccount) {
        return this.bankAccounts.find(account => account.iban === this.toAccount.iban).currency
      }

      if (this.fromAccount) {
        return this.transactionalAccounts.find(account => account.code === this.fromAccount.code).currency
      }

      return ''
    },

    isMobile () {
      return this.screenDevice === this.$screenDeviceConstants.MOBILE
    },

    isDesktop () {
      return this.screenDevice === this.$screenDeviceConstants.DESKTOP
    },

    isTablet () {
      return this.screenDevice === this.$screenDeviceConstants.TABLET
    }
  }
}
</script>

<style scoped></style>
