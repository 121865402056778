<template>
  <div>
    <div ref="navbarPortal">
      <NavbarPortal />
    </div>
    <div class="portal-layout" ref="portalLayout">
      <div class="mx-auto main pb-10" :class="isLmsLogin ? 'w-100' : 'w-custom'">
        <slot></slot>
      </div>
    </div>
    <div ref="footerPortal">
      <FooterCustom :background="'gray'" />
    </div>
  </div>
</template>

<script>
import NavbarPortal from './navbar/NavbarPortal'
import FooterCustom from './FooterCustom'
import {mapActions, mapGetters} from 'vuex'
import introJS from 'intro.js'
import UserService from '@/services/auth/userService'

export default {
  name: 'PortalLayout',
  components: { NavbarPortal, FooterCustom },
  computed: {
    ...mapGetters({
      screenDevice: 'layout/screenDevice',
      getStep: 'guide/step',
      getIntroPing: 'guide/introPing',
      user: 'user/user',
      hasAccessInPortal: 'user/hasAccessInPortal'
    }),
    isLmsLogin () {
      return this.$route.meta['source'] === 'lms'
    },
    isMobile () {
      return this.screenDevice === this.$screenDeviceConstants.MOBILE
    },
    useNativeGuide () {
      return process.env.VUE_APP_USE_NATIVE_GUIDE === 'true'
    }
  },
  data () {
    return {
      intro: null
    }
  },
  created () {
    this.syncNotifications()

    setInterval(() => {
      this.syncNotifications()
    }, 60 * 1000)
  },
  mounted () {
    if (this.screenDevice === this.$screenDeviceConstants.DESKTOP) {
      this.$nextTick(() => {
        this.$refs.portalLayout.style.minHeight = `calc( 100vh - ${this.$refs.navbarPortal.offsetHeight}px - ${this.$refs.footerPortal.offsetHeight}px )`
      })
    }

    this.$gtag.set({user_id: this.user.id})
  },
  methods: {
    ...mapActions({
      getUnreadNotifications: 'user/getUnreadNotifications',
      setStep: 'guide/setStep',
      updateUser: 'user/updateUser',
      resetStateSteps: 'guide/resetState'
    }),
    async syncNotifications () {
      await this.getUnreadNotifications()
    },
    async setGuidePage (step) {
      let router = this.$router

      const openSideBarMobileSteps = [3, 7, 8, 9]
      const excludeCloseSideBarMobileSteps = [2, 6]

      if (this.screenDevice === this.$screenDeviceConstants.MOBILE) {
        if (openSideBarMobileSteps.includes(step)) {
          window.openSideBar()
        } else if (!excludeCloseSideBarMobileSteps.includes(step)) {
          window.closeSideBar()
        }
      }

      if (step < 3 && router.currentRoute.name !== 'settings') {
        await router.push({name: 'settings'})
        return
      }

      if ((step === 3 && router.currentRoute.name !== 'bank-accounts' && this.screenDevice === this.$screenDeviceConstants.DESKTOP) ||
          (step === 4 && router.currentRoute.name !== 'bank-accounts' && this.screenDevice === this.$screenDeviceConstants.MOBILE)) {
        await router.push({name: 'bank-accounts'})
        return
      }

      if ((step >= 4 && router.currentRoute.name !== 'deposits' && this.screenDevice === this.$screenDeviceConstants.DESKTOP) ||
          (step >= 5 && router.currentRoute.name !== 'deposits' && this.screenDevice === this.$screenDeviceConstants.MOBILE)) {
        await router.push({name: 'deposits'})
      }
    },
    async startGuide () {
      if (!this.useNativeGuide) {
        return
      }

      let router = this.$router

      if (this.intro) {
        this.intro.exit(true)
        this.intro = null
      }

      this.intro = introJS()

      let thisComponent = this

      if (this.screenDevice === this.$screenDeviceConstants.DESKTOP) {
        await this.intro.setOptions({
          scrollToElement: false,
          dontShowAgainCookie: 'investimental-guide',
          nextLabel: 'Înainte',
          prevLabel: 'Înapoi',
          doneLabel: 'Sfârșit',
          disableInteraction: false,
          exitOnOverlayClick: false,
          steps: [
            {
              element: document.querySelector('.avatar'),
              title: 'Uuu, felicitări pentru noul tău cont Investimental!',
              intro: 'Pentru că știm că orice început poate fi dificil, acordă-ne un minut să trecem în revistă următorii' +
                ' pași, astfel încât să poți tranzacționa cât mai repede și mai ușor.'
            },
            {
              element: document.querySelector('.username'),
              title: 'În primul rând, numele tău de utilizator Investimental:',
              intro: 'Îl vei folosi dacă vreodată vrei să te loghezi direct in platformele de tranzacționare fără să treci' +
                ' prin Portal, deși de aici ai acces direct cu un singur click.'
            },
            {
              element: document.querySelector('.money'),
              title: 'Bun, acum despre tranzacționare:',
              intro: 'Primul pas va fi să îți alimentezi contul. Știi cum se zice: it takes money to make money. E singurul' +
                ' lucru rămas de făcut înainte să tranzacționezi. Pentru asta mergem în secțiunea Bani...'
            },
            {
              element: document.querySelector('.tab-deposits'),
              intro: '...apoi Alimentări'
            },
            {
              element: document.querySelector('.deposits-card'),
              title: 'E super simplu și aproape instant.',
              intro: 'Selectezi aici contul tău bancar înregistrat la Investimental din care vrei să faci alimentarea și ' +
                'contul de tranzacționare în care vrei să trimiți banii. Apoi click pe Afișează. Vei avea exact detaliile' +
                ' pe care să le folosești în aplicația ta preferată de Internet Banking, ca banii să ajungă în contul selectat' +
                ' aproape instant (dacă banca ta e înrolată în programul de instant payment).'
            },
            {
              element: document.querySelector('.bvb'),
              title: 'Ești gata de tranzacționare! Felicitări!',
              intro: 'Cu un singur click aici pe butonul BVB vei putea tranzacționa pe Bursa de Valori București...'
            },
            {
              element: document.querySelector('.dx'),
              title: 'Ești gata de tranzacționare! Felicitări!',
              intro: '...iar un click aici te va loga în platforma dedicată burselor americane. Îți ținem pumnii să ai parte' +
                ' de cât mai multe tranzacții de succes!'
            },
            {
              element: document.querySelector('.lms'),
              title: 'Dar stai, asta nu e tot! vorba teleshopping-ului:',
              intro: 'Nu putem să te lăsăm să pornești la drum fără să-ți oferim și suport educațional, ambalat într-un' +
                ' pachet de nerefuzat împreună cu un discount consistent la comisionul de tranzacționare! InvestiMentor ' +
                'le are pe toate. Click pe buton și succes!'
            }
          ]
        })
      } else {
        await this.intro.setOptions({
          scrollToElement: false,
          dontShowAgainCookie: 'investimental-guide',
          nextLabel: 'Înainte',
          prevLabel: 'Înapoi',
          doneLabel: 'Sfârșit',
          tooltipPosition: 'bottom-middle-aligned',
          exitOnOverlayClick: false,
          steps: [
            {
              element: document.querySelector('.my-profile'),
              title: 'Uuu, felicitări pentru noul tău cont Investimental!',
              intro: 'Pentru că știm că orice început poate fi dificil, acordă-ne un minut să trecem în revistă următorii' +
                ' pași, astfel încât să poți tranzacționa cât mai repede și mai ușor.'
            },
            {
              element: document.querySelector('.username'),
              title: 'În primul rând, numele tău de utilizator Investimental:',
              intro: 'Îl vei folosi dacă vreodată vrei să te loghezi direct in platformele de tranzacționare fără să treci' +
                ' prin Portal, deși de aici ai acces direct cu un singur click.'

            },
            {
              element: document.querySelector('.burger'),
              title: 'Bun, acum despre tranzacționare:',
              intro: 'Primul pas va fi să îți alimentezi contul. Știi cum se zice: it takes money to make money. E singurul' +
                ' lucru rămas de făcut înainte să tranzacționezi. Pentru asta mergem în acest meniu ...'
            },
            {
              element: document.querySelector('.money'),
              intro: 'în secțiunea Bani...'
            },
            {
              element: document.querySelector('.tab-deposits'),
              intro: '...apoi Alimentări'
            },
            {
              element: document.querySelector('.deposits-card'),
              title: 'E super simplu și aproape instant.',
              intro: 'Selectezi aici contul tău bancar înregistrat la Investimental din care vrei să faci alimentarea și ' +
                'contul de tranzacționare în care vrei să trimiți banii. Apoi click pe Afișează. Vei avea exact detaliile' +
                ' pe care să le folosești în aplicația ta preferată de Internet Banking, ca banii să ajungă în contul selectat' +
                ' aproape instant (dacă banca ta e înrolată în programul de instant payment).'
            },
            {
              element: document.querySelector('.burger'),
              intro: 'Click pe meniu şi presto!'
            },
            {
              element: document.querySelector('.bvb'),
              title: 'Ești gata de tranzacționare! Felicitări!',
              intro: 'Cu un singur click aici pe butonul BVB vei putea tranzacționa pe Bursa de Valori București...'
            },
            {
              element: document.querySelector('.dx'),
              title: 'Ești gata de tranzacționare! Felicitări!',
              intro: '...iar un click aici te va loga în platforma dedicată burselor americane. Îți ținem pumnii să ai parte' +
                ' de cât mai multe tranzacții de succes!'
            },
            {
              element: document.querySelector('.lms'),
              title: 'Dar stai, asta nu e tot! vorba teleshopping-ului:',
              intro: 'Nu putem să te lăsăm să pornești la drum fără să-ți oferim și suport educațional, ambalat într-un' +
                ' pachet de nerefuzat împreună cu un discount consistent la comisionul de tranzacționare! InvestiMentor' +
                ' le are pe toate. Click pe buton și succes!'
            }
          ]
        })
      }

      if (this.getStep > 0) {
        await this.intro.goToStepNumber(this.getStep + 1)
        await this.setGuidePage(this.getStep)
      }

      if (this.screenDevice === this.$screenDeviceConstants.MOBILE) {
        let el = document.getElementsByName('side-menu')[0]

        if (el.classList.contains('open') &&
          (this.getStep === 2 || this.getStep === 6)) {
          await this.intro.goToStepNumber(this.getStep + 2)
        }
      }

      await this.intro.start()

      this.$nextTick(() => {
        document.querySelector('.introjs-skipbutton').addEventListener('click', async function (event) {
          await thisComponent.updateUser({'guide_completed': 1})
          thisComponent.resetStateSteps()

          event.preventDefault()
          event.stopPropagation()
        })

        if (this.getStep === 5 && this.screenDevice === this.$screenDeviceConstants.MOBILE) {
          document.querySelector('.introjs-floating').style.setProperty('top', '140%')
        }
      })

      this.intro.onbeforechange(async function (targetElement) {
        await thisComponent.setGuidePage(thisComponent.intro.currentStep())
      })

      this.intro.onafterchange(function (targetElement) {
        thisComponent.setStep(thisComponent.intro.currentStep())
        thisComponent.intro.refresh(true)
      })

      this.intro.oncomplete(async function () {
        await thisComponent.updateUser({'guide_completed': 1})
        thisComponent.resetStateSteps()
      })
    }
  },
  watch: {
    async getIntroPing () {
      if (!this.useNativeGuide) {
        return
      }

      if (this.user.guide_completed_at === null && this.hasAccessInPortal) {
        if (this.intro) {
          await this.intro.exit(true)
          this.intro = null
        }

        await this.startGuide()
      }
    },
    isMobile (isMobileNewValue) {
      if (isMobileNewValue && this.$router.currentRoute.name === 'analyses') {
        this.$router.push({name: 'morning-news'})
      } else if (!isMobileNewValue &&
        (this.$router.currentRoute.name === 'morning-news' || this.$router.currentRoute.name === 'flash-report')) {
        this.$router.push({name: 'analyses'})
      }
    }
  }
}
</script>

<style scoped></style>
