const MOBILE = 'mobile'
const TABLET = 'tablet'
const DESKTOP = 'desktop'

const MOBILE_MAX_SIZE = 600
const TABLET_MAX_SIZE = 1250

export default Object.freeze({
  MOBILE,
  TABLET,
  DESKTOP,
  MOBILE_MAX_SIZE,
  TABLET_MAX_SIZE
})
