import QuestionnaireService from '../../services/onboarding/questionnaireService'

const getDefaultState = () => {
  return {
    questions: [],
    questionIndex: 0
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    resetState (state) {
      Object.assign(state, getDefaultState())
    },
    setQuestions (state, questions) {
      state.questions = questions
    }
  },
  getters: {
    questions ({questions}) {
      return questions
    },
    questionIndex ({questionIndex}) {
      return questionIndex
    }
  },
  actions: {
    resetState (ctx) {
      ctx.commit('user/resetState')
    },

    setQuestions (ctx, questions) {
      ctx.commit('setQuestions', questions)
    },

    async getQuestionnaire (ctx, payload) {
      return QuestionnaireService.getQuestionnaire(payload)
        .then((result) => {
          return result
        })
    },

    async saveQuestionnaire (ctx, payload) {
      return QuestionnaireService.saveQuestionnaire(payload).then((result) => {
        ctx.commit('setQuestions', result.data.data.questionnaire.answers)
      })
    },

    async getQuestions () {
      return QuestionnaireService.getQuestions()
    }
  }
}
