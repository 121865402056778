<template>
  <div>
    <Card class="personal-info"
          :card-classes="'mobile-card-portal'">
      <template v-slot:body>
        <div class="mb-6">
          <InputText
            :placeholder="$t('onboarding.verify_bank_account.iban')"
            v-model="newIban"
            id="iban"
            :type-input="'text'"
            :label="$t('deposit_withdrawal.bank_accounts.add_iban')"
          />

          <SelectDropDown
            v-model="selectedCurrency"
            class="mt-5"
            :id="'currencies'"
            :placeholder="$t('deposit_withdrawal.bank_accounts.currency')"
            :options="currencies"
            required
          />

          <div v-if="isLibraBankIban">
            <SelectDropDown
              class="mt-5"
              :id="'libraBankCheck'"
              v-model="libraBankFintech"
              :options="libraBankFintechs"
              :placeholder="$t('deposit_withdrawal.bank_accounts.bank')"
            />
          </div>

          <div class="file-upload my-4 info flex items-center">
            <span @click="$refs.documentIban.click()" class="document cursor-pointer">
              <img
               :src="require(`@/assets/img/icons/upload-document.svg`)"
               alt="icon"
              />

              <span v-if="newIbanDocument">{{ newIbanDocument.name }}</span>

              <span v-else>
               {{ $t('general.general.upload_file') }}

                 <span class="info-title">{{ $t('general.general.file_types') }}</span>
              </span>
            </span>

            <div
              v-if="newIbanDocument"
              class="edit-button pl-2"
              @click="$refs.documentIban.click()">
              {{ $t('general.general.edit') }}
            </div>
          </div>

          <input
            :ref="'documentIban'"
            hidden
            name="documentIban"
            id="documentIban"
            type="file"
            @change="handleFileUpload"
          />

          <Message v-if="validation" :message="validation" :type="'error'" class="mb-5"/>

          <div class="flex">
            <Button @call="save()" :loading="loadingData.saveBankAccount" class="mr-2"
                    :text="$t('deposit_withdrawal.bank_accounts.add_iban')" :type="'btn-primary'"/>

            <Button
              :text="$t('general.general.cancel')"
              @call=" cancelNewIbanIsAdded()"
              :type="'btn-secondary'"
            />
          </div>
        </div>
      </template>
    </Card>
  </div>
</template>

<script>
import Card from '../../subcomponents/Card.vue'
import SelectDropDown from '../../subcomponents/SelectDropDown.vue'
import InputText from '../../subcomponents/InputText.vue'
import Checkbox from '../../subcomponents/Checkbox.vue'
import Message from '../../subcomponents/Message.vue'
import Button from '../../subcomponents/Button.vue'
import BankAccountsService from '../../../services/deposit-withdrawal/bankAccountsService'
import {mapGetters} from 'vuex'

export default {
  name: 'AddBankAccount',
  components: {Button, Message, Checkbox, InputText, SelectDropDown, Card},
  computed: {
    ...mapGetters({
      isLogged: 'user/isLogged'
    })
  },
  async created () {
    if (this.isLogged) {
      await this.setData()
      await this.getLibraBankFintechs()
    }
  },
  props: {
    bankAccounts: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      selectedCurrency: '',
      currencies: ['RON', 'EUR', 'USD'],
      newIban: null,
      isLibraBankIban: false,
      libraBankFintech: '',
      libraBankFintechs: [],
      validation: null,
      newIbanDocument: null,
      loadingData: {
        saveBankAccount: false,
        deleteBankAccount: false
      }
    }
  },
  methods: {
    handleFileUpload (event) {
      this.newIbanDocument = event.target.files[0]
    },
    cancelNewIbanIsAdded () {
      this.$emit('cancelNewIbanIsAdded')
    },
    async save () {
      this.loadingData.saveBankAccount = true

      if (await this.validateIban()) {
        let payload = {
          iban: this.newIban,
          currency: this.selectedCurrency,
          libraBankFintech: this.libraBankFintech.id
        }

        if (this.newIbanDocument) {
          payload.document = this.newIbanDocument
        }

        await BankAccountsService.saveBankAccount(payload).then((response) => {
          if (response.status === 200 || response.status === 201) {
            this.loading = true
            this.newIbanIsAdded = false
            this.setData()
            this.cancelNewIbanIsAdded()
          }
        }).catch((response) => {
          if (response.response.status === 500) {
            this.validation = this.$i18n.t('general.validations.internal_error') + ': ' + response.response.data.message
          }

          if (response.response.status === 409) {
            this.validation = this.$i18n.t('general.validations.iban_duplicate')
          }

          if (response.response.status === 400) {
            if (response.response.data.data.invalidIban === true) {
              this.validation = this.$i18n.t('deposit_withdrawal.bank_accounts.invalid_iban')
            } else if (response.response.data.data.exceededNoOfBvbAccounts === true) {
              this.validation = this.$i18n.t('deposit_withdrawal.bank_accounts.exceeded_no_of_bvb_accounts')
            } else {
              this.validation = this.$i18n.t('deposit_withdrawal.bank_accounts.account_verif') + ': ' + response.response.data.message
            }
          }
        })
        this.loadingData.saveBankAccount = false
      } else {
        if (this.bankAccounts.length >= 1 && !this.libraBankFintech) {
          this.validation = this.$i18n.t('deposit_withdrawal.bank_accounts.complete_iban')
        } else {
          this.validation = this.$i18n.t('deposit_withdrawal.bank_accounts.complete_data')
        }
        this.loadingData.saveBankAccount = false
      }
    },
    async getLibraBankFintechs () {
      await BankAccountsService.getLibraBankFintechs().then((response) => {
        if (response.status === 200) {
          this.libraBankFintechs = response.data.data.libraBankFintechs.map((fintech) => {
            return {
              id: fintech.id,
              label: fintech.name,
              short_name: fintech.short_name,
              bic_code: fintech.bic_code
            }
          })
        }
      })
    },
    async setData () {
      this.$emit('setData')
    },
    async validateIban () {
      if (this.bankAccounts.length >= 1 && !this.libraBankFintech) {
        return !!(this.newIban && this.selectedCurrency)
      } else {
        return !!(this.newIbanDocument && this.newIban && this.selectedCurrency)
      }
    }
  },
  watch: {
    newIban: {
      handler (newIbanValue) {
        if (newIbanValue) {
          this.newIban = this.newIban.replace(/\s/g, '')
          this.isLibraBankIban = newIbanValue.slice(4, 8) === 'BREL'
        } else {
          this.isLibraBankIban = false
        }
      },
      deep: true
    }
  }
}
</script>

<style scoped>

</style>
