export default {
  general: {
    update_account: 'Actualizare date și informații',
    dial_code: 'Prefix'
  },
  email_verification: {
    email_verification_guidance: 'Validați adresa de e-mail',
    code_guidance: 'Vă rugăm să introduceți codul de 4 caractere primit pe adresa de e-mail. Nu uita să verifici și casuța de spam/junk.'
  },
  phone_number: {
    phone_verif_guidance: 'Validați numărul de telefon',
    code_guidance: 'Vă rugăm să introduceți codul de 4 caractere primit pe numărul de telefon'
  }
}
