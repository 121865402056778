<template>
  <div class="portofolio-obligations portofolio-transfer">
    <general-layout>
      <template v-slot:title>
        <vue-title :title="$t('general.meta_titles.fiscal_debt')"></vue-title>
      </template>

      <template v-slot:tabs>
        <Tabs :show-id-title="false" />
      </template>

      <template v-slot:body>
        <div class="filters grid-cols-2">

          <div class="calendars relative">

            <SelectDropDown
              :background-color-white="true"
              v-model="filterYear"
              :id="'year'"
              :options="years"
              :placeholder="$t('general.general.year')"
              :grey-label="true"
              :icon="'input-calendar'"
              :big-padding="true"
              required
            />

            <SelectDropDown
              :background-color-white="true"
              v-model="filterMonth"
              :id="'month'"
              :options="months"
              :placeholder="$t('general.general.month')"
              :grey-label="true"
              :icon="'input-calendar'"
              :big-padding="true"
              required
            />
          </div>

          <div class="flex justify-end" v-if="!$isMobile()">
            <div
              class="extend-table w-fit mr-2"
              :class="{ 'cursor-wait opacity-50': !loadedRequestTaxes, 'opacity-50 pointer-events-none': restOfPayment <= 0 }"
              @click="payTaxes()">
              {{ $t('deposit_withdrawal.fiscal_obligations.pay_taxes') }}
            </div>

            <div class="extend-table" @click="toggleAllTables">
              {{ $t('deposit_withdrawal.fiscal_obligations.' + (tableExtended ? 'collapse' : 'expand')) }}
            </div>
          </div>
        </div>

        <div class="custom-taxes-message" v-if="!$isMobile()">
          <Message
            v-if="validations.taxes.success"
            :message="validations.taxes.success"
            :type="'success'"
            class="m-auto"
          />
          <Message
            v-if="validations.taxes.fail"
            :message="validations.taxes.fail"
            :type="'error'"
            class="m-auto"
          />
        </div>

        <div class="fiscal-obligations-container">

          <PulseLoader class="w-fit m-auto p-10 justify-center flex" v-if="loading" />

          <div class="fiscal-obligations" v-if="!loading">
            <div v-for="(fiscalObligations, name) in filteredAssets" :key="name">
              <div v-if="!$isMobile()">
                <FiscalObligationsAssetsTableDesktop
                  v-if="fiscalObligations.length"
                  :color-symbol="getColorForTable(fiscalObligations[0].currency)"
                  :assets="fiscalObligations"
                  :expanded="tableExtended"
                  :name="name.toString()"
                  :ref="`table-${name}`"
                />
              </div>

              <div v-if="$isMobile()">
                <FiscalObligationsAssetsTableMobile
                  v-if="fiscalObligations.length"
                  :color-symbol="getColorForTable(fiscalObligations[0].currency)"
                  :assets="fiscalObligations"
                  :expanded="tableExtended"
                  :name="name.toString()"
                  :ref="`table-${name}-mobile`"
                />

                <div v-if="Object.keys(assets)[0] === name">
                  <div class="flex justify-between">
                    <div
                      class="extend-table w-fit"
                      :class="{ 'cursor-wait opacity-50': !loadedRequestTaxes, 'opacity-50 pointer-events-none': restOfPayment <= 0 }"
                      @click="payTaxes()">
                      <span>{{ $t('deposit_withdrawal.fiscal_obligations.pay_taxes') }}</span>
                    </div>

                    <div class="extend-table w-fit ml-2" @click="tableExtended = !tableExtended">
                      {{ $t('deposit_withdrawal.fiscal_obligations.' + (tableExtended ? 'collapse' : 'expand')) }}
                    </div>
                  </div>

                  <div class="custom-taxes-message">
                    <Message
                      v-if="validations.taxes.success"
                      :message="validations.taxes.success"
                      :type="'success'"
                      class="m-auto"
                    />
                    <Message
                      v-if="validations.taxes.fail"
                      :message="validations.taxes.fail"
                      :type="'error'"
                      class="m-auto"
                    />
                  </div>
                </div>

              </div>
            </div>

            <div class="tax-value white">
              <div> {{ $t('deposit_withdrawal.fiscal_obligations.total_tax') }} </div>
              <div class="value">{{ parseFloat(totalTax).toLocaleString('en-US', {maximumFractionDigits: 0}) }}</div>

            </div>

            <div class="tax-value white">
              <div> {{ $t('deposit_withdrawal.fiscal_obligations.withheld_tax') }} </div>
              <div class="value">{{ parseFloat(withheldTax).toLocaleString('en-US', {maximumFractionDigits: 0}) }}</div>

            </div>

            <div class="tax-value">
              <div> {{ $t('deposit_withdrawal.fiscal_obligations.rest_of_payment') }} </div>
              <div class="value">{{ parseFloat(restOfPayment).toLocaleString('en-US', {maximumFractionDigits: 0}) }}</div>
            </div>
          </div>
        </div>
      </template>
    </general-layout>
  </div>
</template>
<script>
import GeneralLayout from '../../layout/GeneralLayout'
import Tabs from '../../layout/Tabs'
import Button from '../../subcomponents/Button'
import { mapGetters } from 'vuex'
import InputText from '../../subcomponents/InputText'
import Accordion from '../../subcomponents/Accordion'
import Card from '../../subcomponents/Card'
import IconBox from '../../subcomponents/IconBox.vue'
import Message from '../../subcomponents/Message'
import UploadDocuments from '../../subcomponents/UploadDocuments'
import ApiService from '../../../services/apiService'
import Resources from '../../subcomponents/Resources.vue'
import FiscalObligationsAssetsTableDesktop from '@/components/subcomponents/assets/FiscalObligationsAssetsTableDesktop.vue'
import FiscalObligationsAssetsTableMobile from '@/components/subcomponents/assets/FiscalObligationsAssetsTableMobile.vue'
import Calendar from '@/components/subcomponents/Calendar.vue'
import SelectDropDown from '@/components/subcomponents/SelectDropDown.vue'
import i18n from '@/i18n'

export default {
  name: 'FiscalObligations',
  components: {
    SelectDropDown,
    Calendar,
    FiscalObligationsAssetsTableMobile,
    FiscalObligationsAssetsTableDesktop,
    UploadDocuments,
    GeneralLayout,
    Tabs,
    Button,
    InputText,
    Accordion,
    IconBox,
    Message,
    Card,
    Resources
  },
  data () {
    return {
      loading: true,
      assets: [],
      filteredAssets: [],
      filterYear: '',
      filterMonth: '',
      tableExtended: false,
      loadedRequestTaxes: true,
      tablesWithData: 0,
      years: [],
      status_paid: 'retinut',
      months: [
        i18n.t('general.general.january'),
        i18n.t('general.general.february'),
        i18n.t('general.general.march'),
        i18n.t('general.general.april'),
        i18n.t('general.general.may'),
        i18n.t('general.general.june'),
        i18n.t('general.general.july'),
        i18n.t('general.general.august'),
        i18n.t('general.general.september'),
        i18n.t('general.general.october'),
        i18n.t('general.general.november'),
        i18n.t('general.general.december')
      ],
      validations: {
        taxes: {
          success: '',
          fail: ''
        }
      },
      totalTax: 0,
      withheldTax: 0,
      restOfPayment: 0
    }
  },
  computed: {
    ...mapGetters({
      user: 'user/user',
      screenDevice: 'layout/screenDevice',
      hasAccessInPortal: 'user/hasAccessInPortal',
      isLogged: 'user/isLogged'
    })
  },
  async mounted () {
    if (this.isLogged) {
      await this.getData()
      for (let i = new Date().getFullYear(); i >= 2019; i--) {
        this.years.push(i.toString())
      }
    }
  },

  methods: {
    getColorForTable (currency) {
      switch (currency) {
        case 'RON':
          return 'green'
        case 'EUR':
          return 'yellow'
        case 'USD':
          return 'blue'
      }
    },

    calculateSumsForTaxes () {
      this.totalTax = 0
      this.withheldTax = 0
      this.restOfPayment = 0

      for (const account in this.assets) {
        for (const asset in this.assets[account]) {
          this.totalTax += parseFloat(this.assets[account][asset].tax_anaf)
          if (this.assets[account][asset].tax_status === this.status_paid) {
            this.withheldTax += parseFloat(this.assets[account][asset].tax_anaf)
          }
        }
      }

      this.restOfPayment = this.totalTax - this.withheldTax
    },

    async getData () {
      let response = await ApiService.withAuth().get('api/user/trades-taxes')
      if (response.status === 200) {
        this.assets = response.data.data
        this.filterAssets(this.assets)
        this.calculateSumsForTaxes()
        this.loading = false
      }
    },
    filterAssets (assets) {
      this.filteredAssets = assets
    },

    async payTaxes () {
      this.loadedRequestTaxes = false
      await ApiService.withAuth().get('api/user/trades-taxes/pay')
        .then(response => {
          this.loadedRequestTaxes = true
          if (response.status === 200) {
            this.validations.taxes.success = response.data.message

            this.getData()
          }
        })
        .catch(error => {
          this.loadedRequestTaxes = true
          if (error.response.data.data.error) {
            this.validations.taxes.fail = error.response.data.message
          }
        })

      setTimeout(() => {
        this.validations.taxes.success = ''
        this.validations.taxes.fail = ''
      }, 3000)
    },

    toggleAllTables () {
      this.tableExtended = !this.tableExtended
    }
  },

  watch: {
    filterYear: function () {
      let filteredAssets = {}
      if (this.filterYear && this.filterYear.length) {
        for (const account in this.assets) {
          filteredAssets[account] = this.assets[account].filter(asset => new Date(asset.date).getFullYear() === parseInt(this.filterYear))
        }
      } else {
        filteredAssets = this.assets
      }

      this.filterAssets(filteredAssets)
    },
    filterMonth: function () {
      let filteredAssets = {}
      if (this.filterMonth && this.filterMonth.length) {
        for (const account in this.assets) {
          filteredAssets[account] = this.assets[account].filter(asset => new Date(asset.date).getMonth() === parseInt(this.filterMonth) ||
            new Date(asset.date).getMonth() === this.months.indexOf(this.filterMonth))
        }
      } else {
        filteredAssets = this.assets
      }

      this.filterAssets(filteredAssets)
    }
  }
}
</script>
