<template>
  <div class="footer-custom" :class="[`footer-${background}`, {'padding-bottom': isLogged}]">
    <div class="divider" v-if="isMobile"></div>
    <div class="orientation">
      <div><a target="_blank" :href="wpLink('/legale/disclaimer/')">{{ $t('general.footer.disclaimer') }}</a></div>
      <div class="divider">|</div>
      <div><a target="_blank" :href="wpLink('/legale/politica-de-confidentialitate/')">{{ $t('general.footer.personal_data_policy') }}</a></div>
      <div class="divider">|</div>
      <div><a target="_blank" :href="wpLink('/politica-utilizare-cookies/')">{{ $t('general.footer.cookie_policy') }}</a></div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  name: 'FooterCustom',
  props: {
    background: {
      type: String,
      default: 'white'
    }
  },
  computed: {
    ...mapGetters({
      isLogged: 'user/isLogged',
      screenDevice: 'layout/screenDevice'
    }),
    isMobile () {
      return this.screenDevice === this.$screenDeviceConstants.MOBILE
    }
  }
}
</script>

<style scoped>

</style>
