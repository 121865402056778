<template>
  <div class="step-bar">
    <div v-for="(step,index) in getStepsWithoutLast" :key="index" class="step-group" :class="[`step-${index + 1}`]">
      <div class="step-name">{{ step.label }}</div>
      <div class="flex items-center">
        <div class="bullet" :class="{ 'active' : currentStep - 1 >= index, 'done' : currentStep - 1 > index }"></div>
        <div class="line" :class="{ 'active' : currentStep - 1 > index }"></div>
      </div>
    </div>
    <div class="step-group" :class="[`step-${Object.keys(steps).length}`]">
      <div class="step-name">{{ steps[Object.keys(steps).length - 1].label }}</div>
      <div class="bullet" :class="{ 'active' : currentStep >= Object.keys(steps).length , 'done' : currentStep > Object.keys(steps).length }"></div>
    </div>
  </div>
</template>

<script>
import Sidebar from './Sidebar'
export default {
  name: 'StepBar',
  components: {Sidebar},
  props: {
    steps: {
      type: Array,
      default: () => []
    },
    currentStep: {
      type: Number,
      default: 2
    }
  },
  computed: {
    getStepsWithoutLast () {
      let newSteps = this.steps
      return newSteps.slice(0, -1)
    }
  }
}
</script>

<style scoped>

</style>
