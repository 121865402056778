<template>
  <div class="login-component login pb-10">
    <vue-title
      :title="$t('general.meta_titles.force_reset_password')"
    ></vue-title>
    <Card class="padding-custom fa-container text-center" :card-classes="'margin-bottom-medium'">
      <div slot="title" class="title">
        {{ $t("auth.general.force_reset_password") }}
      </div>
      <div slot="title" class="subtitle">
        {{ $t("auth.recovery_password.guidance") }}
      </div>
      <div slot="body">
        <div class="download-app">
          <ForcePasswordReset/>
        </div>
      </div>
      <div slot="footer">
        <span class="flex text-left m-top-custom"><img class="m-right-custom" src="@/assets/img/icons/attention-onboarding.svg" alt="transfer-portfolio" />{{ $t('onboarding.prepare_onboarding.info') }}</span>
      </div>
    </Card>
  </div>
</template>

<script>
import Card from '../subcomponents/Card'
import { mapGetters } from 'vuex'
import ForcePasswordReset from '@/components/auth/subcomponents/ForceResetPassword.vue'

export default {
  name: 'ForceResetPasswordPage',
  components: { ForcePasswordReset, Card },
  computed: {
    ...mapGetters({
      user: 'user/user'
    })
  },
  data () {
    return {
      selected: false
    }
  },
  async mounted () {
    if (this.user && !this.user.has_force_reset_password) {
      this.goToHomepage()
    }
  },
  methods: {
    goToHomepage () {
      window.location.href = '/'
    }
  },
  watch: {
    user: {
      handler (user) {
        if (user && !user.has_force_reset_password) {
          this.goToHomepage()
        }
      },
      deep: true
    }
  }
}
</script>

<style scoped></style>
