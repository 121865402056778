<template>
  <Card card-classes="continue-onboarding">
    <template v-slot:body>
      <div :class="{flex: !isMobile}">
        <div class="image" :class="{'mr-8': !isMobile}">
          <img width="230" height="230" src="@/assets/img/continue-onboarding.png" alt="continue-onboarding">
        </div>

        <div>
          <div class="mb-1">{{ $t('general.continue_to_onboarding.restrict_access_message') }}</div>
          <div class="mb-6 mt-2.5 font-bold">{{ $t('general.continue_to_onboarding.press_button_message') }}</div>

          <div @click="continueToOnboarding">
            <Button :text="$t('general.continue_to_onboarding.button_text')" :type="'btn btn-primary'"></Button>
          </div>
        </div>
      </div>
    </template>
  </Card>
</template>

<script>
import Button from '../subcomponents/Button'
import Card from '../subcomponents/Card'
import {mapGetters} from 'vuex'
import {ONBOARDING_EMAIL_VERIFICATION, ONBOARDING_LEGAL_PERSON_DETAILS, ONBOARDING_PHONE_VALIDATION} from '@/router'

export default {
  name: 'ContinueToOnboarding',
  components: {Card, Button},
  computed: {
    ...mapGetters({
      user: 'user/user',
      screenDevice: 'layout/screenDevice'
    }),
    isMobile () {
      return this.screenDevice === this.$screenDeviceConstants.MOBILE
    }
  },
  methods: {
    continueToOnboarding () {
      if (this.user.type === 'company') {
        let onboardingStep = this.user.email_verified_at
          ? (this.user.phone_verified_at ? ONBOARDING_LEGAL_PERSON_DETAILS : ONBOARDING_PHONE_VALIDATION)
          : ONBOARDING_EMAIL_VERIFICATION
        this.$router.push({name: onboardingStep}).catch(() => {})
      } else {
        this.$router.push({name: 'onboarding-email-verification'}).catch(() => {
        })
      }
    }
  }
}
</script>

<style scoped>

</style>
