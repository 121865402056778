<template>
  <Pie
    ref="doughnutChart"
    :chart-options="chartOptions"
    :chart-data="dataSets"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="width"
    :height="height"
  />
</template>

<script>
import { Pie } from 'vue-chartjs/legacy'

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale
} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale)

export default {
  name: 'PieChart',
  components: {
    Pie
  },
  props: {
    chartId: {
      type: String,
      default: 'pie-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    chartDataSets: {
      type: Array,
      default: () => []
    },
    chartDataSetsLabels: {
      type: Array,
      default: () => []
    },
    plugins: {
      type: Array,
      default: () => []
    },
    width: {
      type: Number,
      default: 250
    },
    height: {
      type: Number,
      default: 250
    },
    borderWidth: {
      type: Number,
      default: 0
    }
  },
  mounted () {
    this.dataSets = {
      labels: this.chartDataSetsLabels.length > 0 ? this.chartDataSetsLabels : Array.from({length: this.chartDataSets.length}, (_, i) => i + 1),
      datasets: [
        {
          backgroundColor: ['#46A4E4', '#00163E', '#F5D150', '#3AD5AF', '#CEA6DE'],
          data: this.chartDataSets
        }
      ]
    }
  },
  data () {
    return {
      dataSets: {},
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        elements: {
          arc: {
            borderWidth: this.borderWidth
          }
        },
        plugins: {
          tooltip: {
            enabled: this.chartDataSetsLabels.length > 0,
            displayColors: false,
            callbacks: {
              label: function (tooltipItems, data) {
                return tooltipItems.label
              }
            }
          },
          legend: {
            display: false
          }
        }
      }
    }
  },
  watch: {
    chartDataSets: {
      handler (chartDataSets) {
        this.dataSets = {
          labels: this.chartDataSetsLabels.length > 0 ? this.chartDataSetsLabels : Array.from({length: chartDataSets.length}, (_, i) => i + 1),
          datasets: [
            {
              backgroundColor: ['#46A4E4', '#00163E', '#F5D150', '#3AD5AF', '#CEA6DE'],
              data: chartDataSets
            }
          ]
        }
      },
      deep: true
    }
  }
}
</script>
