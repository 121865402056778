const WHATSAPP_DESKTOP = 'https://web.whatsapp.com/send?text='
const WHATSAPP_MOBILE = 'https://wa.me/?text='
const EMAIL_BODY = '&body='
const EMAIL_SUBJECT = 'mailto:?subject='
const SMS_IOS = 'sms:&body='
const SMS_ANDROID = 'sms:?body='

export default Object.freeze({
  WHATSAPP_DESKTOP,
  WHATSAPP_MOBILE,
  EMAIL_BODY,
  EMAIL_SUBJECT,
  SMS_IOS,
  SMS_ANDROID
})
