<template>
  <div>
    <div class="chart-header">
      <div class="title-chart">{{ $t('deposit_withdrawal.portfolio.consolidated_portfolio') }}</div>
      <TimePeriod v-if="!isMobile" @timePeriodChange="setActiveTimePeriod"/>
    </div>
    <div class="chart-total">
      {{ total }}
    </div>
    <LineChartGenerator
      ref="line"
      :chart-options="chartOptions"
      :chart-data="chartData"
      :chart-id="chartId"
      :dataset-id-key="datasetIdKey"
      :plugins="plugins"
      :css-classes="cssClasses"
      :styles="styles"
      :width="width"
      :height="height"
    />
    <TimePeriod v-if="isMobile" @timePeriodChange="setActiveTimePeriod"/>
  </div>
</template>

<script>
import { Line as LineChartGenerator } from 'vue-chartjs/legacy'

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
  Filler
} from 'chart.js'
import {mapGetters} from 'vuex'
import TimePeriod from '@/components/subcomponents/assets/TimePeriod.vue'
import PortfolioData from '@/mixins/PortofolioData'
import ApiService from '@/services/apiService'

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
  Filler
)

export default {
  name: 'LineChart',
  components: {
    TimePeriod,
    LineChartGenerator
  },
  mixins: [PortfolioData],
  props: {
    selected: {
      type: Object,
      default: () => {}
    },
    chartId: {
      type: String,
      default: 'line-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 400
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    plugins: {
      type: Array,
      default: () => []
    },
    total: {
      type: String,
      default: ''
    }
  },
  computed: {
    ...mapGetters({
      screenDevice: 'layout/screenDevice'
    }),
    isMobile () {
      return this.screenDevice === this.$screenDeviceConstants.MOBILE
    }
  },
  async created () {
    this.selectedAccount = this.selected
    this.setDataChart(this.activeTimePeriod)
    this.paddingTicks = this.isMobile ? 13 : 25
    this.chartOptions.scales.y.ticks.padding = this.paddingTicks
  },
  data () {
    return {
      paddingTicks: 25,
      activeTimePeriod: 'MAX',
      chartData: {
        labels: [],
        datasets: [
          {
            label: 'Valoare RON',
            pointBackgroundColor: '#46A4E4',
            pointRadius: 2,
            pointHoverRadius: 6,
            pointHoverBorderWidth: 4,
            pointHoverBorderColor: '#fff',
            backgroundColor: (ctx) => {
              const canvas = ctx.chart.ctx
              const gradient = canvas.createLinearGradient(0, 0, 0, 400)

              gradient.addColorStop(0.2, '#46a4e433')
              gradient.addColorStop(0.5, '#46a4e41a')
              gradient.addColorStop(1, '#46a4e400')

              return gradient
            },
            fill: true,
            data: []
          }
        ]
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        tension: 0.4,
        borderColor: '#46A4E4',
        scales: {
          x: {
            beginAtZero: true,
            grid: {
              display: false
            }
          },
          y: {
            beginAtZero: true,
            grid: {
              drawBorder: false,
              drawTicks: false,
              borderDash: [8, 6]
            },
            ticks: {
              callback: function (value) {
                return Math.abs(value) > 999 ? Math.sign(value) * ((Math.abs(value) / 1000).toFixed(1)) + 'k' : Math.sign(value) * Math.abs(value)
              }
            }
          }
        },
        plugins: {
          legend: {
            display: false
          }
        }
      }
    }
  },
  methods: {
    setActiveTimePeriod (timePeriod) {
      this.activeTimePeriod = timePeriod
      this.setDataChart(timePeriod)
    },
    async setDataChart (timePeriod) {
      await this.filterDataForChart(timePeriod)

      this.chartData.labels = Object.keys(this.consolidatedAssets)
      this.chartData.labels = this.chartData.labels.map((label) => {
        return label.split(' ')[0].split('-').reverse().join('/')
      })
      this.chartData.datasets[0].data = Object.values(this.consolidatedAssets)
    }
  },
  watch: {
    selected: {
      handler: async function () {
        await this.filterDataForChart('MAX')
      },
      deep: true
    }
  }
}
</script>
