const getDefaultState = () => {
  return {
    step: 0,
    introPing: null
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    resetState (state) {
      Object.assign(state, getDefaultState())
    },
    setStep (state, step) {
      state.step = step
    },
    setIntroPing (state, introPing) {
      state.introPing = introPing
    }
  },
  getters: {
    step ({step}) {
      return step
    },
    introPing ({introPing}) {
      return introPing
    }
  },
  actions: {
    resetState (ctx) {
      if (!process.env.VUE_APP_USE_NATIVE_GUIDE) {
        return
      }

      ctx.commit('resetState')
    },
    setStep (ctx, step) {
      if (!process.env.VUE_APP_USE_NATIVE_GUIDE) {
        return
      }

      ctx.commit('setStep', step)
    },
    setIntroPing (ctx, intro) {
      if (!process.env.VUE_APP_USE_NATIVE_GUIDE) {
        return
      }

      ctx.commit('setIntroPing', intro)
    }
  }
}
