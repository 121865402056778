<template>
  <div class="vue-otp-input">
    <div
      class="otp-wrapper text-center"
      :id="id"
      ref="digitInput"
    >
      <input
        v-for="(digitInput, index) in codeLength"
        :key="id + index"
        autocomplete="one-time-code"
        :type="type"
        v-model="inputValue[index]"
        class="otp-input"
        :placeholder="''"
        @focus="onFocus(index)"
        @paste="onPaste"
        @input="onInput(index, $event)"
        @change="onChanged"
        @keydown="keydownHandler(index, $event)"
        @keyup.enter="enterClicked"
      />
    </div>
  </div>
</template>

<script>

export default {
  name: 'OtpInput',
  props: {
    id: {
      type: String,
      default: 'otp'
    },
    codeLength: {
      type: Number,
      default: 4
    },
    type: {
      type: String,
      default: 'text'
    }
  },
  data () {
    return {
      inputValue: [],
      joinedValue: '',
      isInputFocused: false,
      activeInput: 0
    }
  },
  mounted () {
    this.onFocus(0)
    this.$refs.digitInput.children[0].focus()
  },
  methods: {

    enterClicked () {
      if (this.joinedValue.length === this.codeLength) {
        this.$emit('on-key-enter', this.joinedValue)
      }
    },

    keydownHandler (index, e) {
      if (e.keyCode === 8 && e.target.value === '') {
        this.$refs.digitInput.children[Math.max(0, index - 1)].focus()
        this.inputValue.pop()
        this.activeInput = index - 1
      }
    },
    onInput (index, ev) {
      if (ev.target.value.length === this.codeLength) {
        this.inputValue = ev.target.value.split('')
      }
      if (ev.inputType && ev.inputType === 'deleteContentBackward') {
        return
      }
      if (this.inputValue.length === this.codeLength && (ev.inputType === 'insertFromPaste' || !ev.hasOwnProperty('inputType'))) {
        let tempVal = this.inputValue[0]
        let tempArray = Array.from(tempVal)
        this.inputValue[0] = tempArray[0]
        this.joinedValue = this.inputValue.map((value) => value).join('')
        this.onComplete(this.joinedValue)
      } else {
        const [first] = ev.data
        this.inputValue[index] = first
        this.activeInput = this.inputValue.length

        if (this.activeInput < this.codeLength) {
          this.$refs.digitInput.children[this.activeInput].focus()
        }
        this.joinedValue = this.inputValue.map((value) => value).join('')

        if (this.joinedValue.length === this.codeLength) {
          this.onComplete(this.joinedValue)
        }
      }
    },
    onFocus (index) {
      this.activeInput = index
      this.isInputFocused = true
      this.$refs.digitInput.children[index].focus()
    },
    onComplete (joinedValue) {
      this.$emit('on-complete', joinedValue)
    },
    onChanged (event) {
      // handle paste form gKeyboard
      if (event.target.value.length === this.codeLength) {
        let trimmedText = event.target.value.trim()
        const [...first] = trimmedText
        this.inputValue = first
      }

      this.$emit('on-changed', event)
    },
    onPaste (event) {
      if (event.target.value.length === this.codeLength) {
        let trimmedText = event.clipboardData.getData('text').trim()
        const [...first] = trimmedText
        this.inputValue = first
      }

      this.$emit('on-paste', event)
    }
  }
}
</script>
