<template>
  <div class="general-loading-parent">
    <PulseLoader v-if="generalLoading" class="general-loading"/>
    <BasicLayout v-if="layoutType === 'basic' && !generalLoading">
      <router-view></router-view>
    </BasicLayout>
    <OnboardingLayout v-if="layoutType === 'onboarding' && !generalLoading">
      <router-view></router-view>
    </OnboardingLayout>
    <PortalLayout v-if="layoutType === 'portal' && !generalLoading">
      <router-view></router-view>
    </PortalLayout>
    <product-fruits
      v-if="user.type !== 'company' && !useNativeGuide"
      :project-code="productFruitsWorkspace"
      language="ro"
      :username="user.username"
      :email="user.email"
      :firstname="user.first_name"
      :lastname="user.last_name"
      :sign-up-at="user.created_at"
      role="user"
      :props="{propA: 'A', propB: 'B'}"
    />
  </div>
</template>

<script>

import {mapActions, mapGetters} from 'vuex'
import BasicLayout from './components/layout/BasicLayout'
import OnboardingLayout from './components/layout/OnboardingLayout'
import PortalLayout from './components/layout/PortalLayout'
import CookieService from './services/auth/cookieService'
import MaintenanceModeAnnouncementBanner from '@/components/subcomponents/MaintenanceModeAnnouncementBanner.vue'
import introJS from 'intro.js'
import ProductFruits from '@/components/subcomponents/ProductFruits'
import userService from '@/services/auth/userService'

export default {
  name: 'App',
  components: { ProductFruits, PortalLayout, OnboardingLayout, BasicLayout },
  computed: {
    ...mapGetters({
      layoutType: 'layout/getType',
      isLogged: 'user/isLogged',
      user: 'user/user',
      middlewareJwt: 'user/jwt',
      generalLoading: 'layout/generalLoading',
      typeChat: 'user/typeChat',
      screenDevice: 'layout/screenDevice'
    }),
    useNativeGuide () {
      return process.env.VUE_APP_USE_NATIVE_GUIDE === 'true'
    },
    isMobile: function () {
      return this.screenDevice === this.$screenDeviceConstants.MOBILE
    },
    productFruitsWorkspace () {
      return process.env.VUE_APP_FRUIT_PRODUCT_WORKSPACE
    }
  },
  data () {
    return {
      intro: null
    }
  },
  created () {
    if (this.isLogged && !this.$cookies.isKey('isLogged')) {
      CookieService.userHasLoggedIn(this.user)
    } else if (this.isLogged && this.$cookies.isKey('isLogged') && CookieService.isDifferentFromCookie(this.user)) {
      CookieService.userHasLoggedIn(this.user)
    } else if (!this.isLogged && this.$cookies.isKey('isLogged')) {
      CookieService.userHasLoggedOut()
    }
  },
  mounted () {
    if (this.typeChat === 'ai') {
      this.loadChatScript('https://chatling.ai/js/embed.js', 'chatling-embed-script', '3124118184')
    } else {
      this.loadChatScript(`//js-eu1.hs-scripts.com/${process.env.VUE_APP_HUBSPOT_KEY}`, 'hs-script-loader')
    }
  },
  methods: {
    regenerateMiddlewareJwt () {
      try {
        userService.regenerateMiddlewareJwt({
          jwt: this.middlewareJwt
        })
      } catch (e) {
        console.error(e)
      }
    },
    loadChatScript (src, id, dataId = null) {
      let script = document.createElement('script')

      script.setAttribute('src', src)
      script.setAttribute('id', id)
      script.setAttribute('type', 'text/javascript')
      script.setAttribute('async', 'true')
      script.setAttribute('defer', 'true')

      if (dataId) {
        script.setAttribute('data-id', dataId)
      }

      document.body.appendChild(script)

      this.currentScriptId = id
    },
    removeCurrentChatScript () {
      if (this.currentScriptId) {
        let existingScript = document.getElementById(this.currentScriptId)

        if (existingScript) {
          document.body.removeChild(existingScript)
        }

        if (document.getElementById('chatling-chat-iframe')) {
          document.body.removeChild(document.getElementById('chatling-chat-iframe'))
        }

        document.getElementById('chatling-open-chat-icon')?.remove()

        document.getElementById('chatling-close-chat-icon')?.remove()

        if (document.getElementById('hubspot-messages-iframe-container')) {
          document.body.removeChild(document.getElementById('hubspot-messages-iframe-container'))
        }

        this.currentScriptId = null
      }
    }
  },
  watch: {
    'user.has_access_in_portal': function (newValue) {
      this.regenerateMiddlewareJwt()
    },
    typeChat (newValue) {
      this.removeCurrentChatScript()

      if (newValue === 'ai') {
        this.loadChatScript('https://chatling.ai/js/embed.js', 'chatling-embed-script', '3124118184')
      } else if (newValue === 'standard') {
        this.loadChatScript(`//js-eu1.hs-scripts.com/${process.env.VUE_APP_HUBSPOT_KEY}`, 'hs-script-loader')
      }
    }
  }
}
</script>

<style lang="scss">
@import "assets/scss/general";
@font-face {
  font-family: 'ProximaNova';
  src: url("./assets/fonts/ProximaNova-Regular.ttf") format("truetype");
}
@font-face {
  font-family: 'ProximaNovaBold';
  src: url("./assets/fonts/ProximaNova-Bold.ttf") format("truetype");
}
@font-face {
  font-family: 'ProximaNovaExtraBold';
  src: url("./assets/fonts/ProximaNova-Extrabld.ttf") format("truetype");
}
@font-face {
  font-family: 'ProximaNovaExtraBoldItalic';
  src: url("./assets/fonts/ProximaNova-ExtrabldIt.ttf") format("truetype");
}
</style>

<style lang="css">
@import "assets/css/tailwind.css";
</style>
