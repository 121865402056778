<template>
  <div class="footer-bar">
    <Button
      v-if="anniversary && anniversary <= today && !isDesktop"
      class="absolute top-0 left-1/2"
      :extra-classes="'anniversary-button'"
      :text="$t('general.menu.update_user_data')"
      :type="'btn-secondary'"
      @call="updateData()"
    />
    <Button class="mr-5" :loading=arenaXtLoading @call="getJwt" extra-classes="px-5" :text="$t('general.menu.arena_xt')" :type="'btn-primary'" :icon="'icons/bvb'" :icon-position="'left'"/>
    <Button class="mr-5" :loading=middlewareLoading v-if="middlewareActive" @call="getMiddlewareToken" extra-classes="px-5" :text="$t('general.menu.web_terminal')" :type="'btn-primary'" :icon="'icons/bvb'" :icon-position="'left'"/>
    <Button class="mr-5" :loading=DXLoading @call="getDxToken" extra-classes="px-5" :text="$t('GLOBAL')" :icon="'icons/global'" :icon-position="'left'" :type="'btn btn-primary'"/>

    <PopUpModal
      :modal-fit-content="true"
      :modal-opened="showNewUserModal"
      @closeModal="closePopUp()">
      <div :class="[!isMobile ? 'flex flex-col justify-center': 'mt-16']">
        <div class="text-center">{{ $t('general.general.new_user_warn') }}</div>
        <Button class="mt-5 mx-auto" :text="$t('general.general.ok')" :type="'btn-primary'" @call="closePopUp()"/>
      </div>

    </PopUpModal>
  </div>
</template>

<script>
import Button from '../subcomponents/Button.vue'
import NavbarButtonMethods from '../../mixins/NavbarButtonMethods'
import AnniversaryMethodsMixin from '../../mixins/AnniversaryMethods'
import PopUpModal from '@/components/subcomponents/PopUpModal.vue'
import {mapGetters} from 'vuex'
export default {
  name: 'FooterBar',
  components: { PopUpModal, Button },
  mixins: [ NavbarButtonMethods, AnniversaryMethodsMixin ],
  computed: {
    ...mapGetters({
      screenDevice: 'layout/screenDevice',
      middlewareActive: 'user/middlewareActive'
    }),
    isMobile () {
      return this.screenDevice === this.$screenDeviceConstants.MOBILE
    },
    isDesktop () {
      return this.screenDevice === this.$screenDeviceConstants.DESKTOP
    }
  }
}
</script>
