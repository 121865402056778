<template>
  <div class="sell-targets w-full">
    <div class="thead">
      <div class="tr">
        <div class="th">{{ $t('deposit_withdrawal.auto_invest.instruments.table.instrument') }}</div>
        <div class="th">{{ $t('deposit_withdrawal.auto_invest.instruments.table.value') }}</div>
        <div class="th">{{ $t('deposit_withdrawal.auto_invest.targets.target_weights') }}</div>
        <div class="th">
          <Button
            @call="distributeEquallySum"
            extra-classes="custom-position-buttons"
            :text="$t('deposit_withdrawal.auto_invest.targets.distribute_equally')"
            :type="'btn-dark-outline'"
          />
        </div>
      </div>
    </div>
    <div class="tbody">
      <div v-for="(instrument, index) in soldInstruments" :key="index">
        <div class="tr">
          <div class="td">
            <div class="flex items-center gap-3">
              <StockSecurityLogo :img="instrument.position.stock_security.logo" :color="colorBlock[index % 5]" :symbol="instrument.position.stock_security.symbol"/>
              <div>
                {{ instrument.position.stock_security.name ? instrument.position.stock_security.name : '-' }}
              </div>
            </div>
          </div>

          <div class="td">
            <div :class="{ 'error-color': !instrument.valid }">{{ instrument.soldValue }} USD</div>
          </div>

          <div class="td">
            <div>
              <input
                @input="sliderChanged($event, instrument)"
                type="range"
                min="0"
                max="100"
                step="1"
                :id="`range-${index}`"
                :value="instrument.sold_percentage_shown"
                class="slider"
                :disabled="instrument.isLocked"
                :class="[`color-${index % 5}`]"
              />
            </div>
            <div class="special-target-input-wrapper">
              <div class="special-target-input" :class="{'error-color': Math.abs(Math.round(sumOfInstruments * 100) / 100 - parseFloat(total)) > 0.09}">
                <InputText
                  id="'pie-name'"
                  :type-input="'number'"
                  :class="{'pointer-events-none': instrument.isLocked}"
                  :value="instrument.sold_percentage_shown"
                  :max-decimals="2"
                  @input="inputChanged($event, instrument)"
                  @focusout="inputChanged($event, instrument)"
                />

                <div>%</div>
              </div>
            </div>
          </div>

          <div class="td">
            <img
              class="cursor-pointer"
              alt=""
              :src="require(`@/assets/img/icons/${instrument.isLocked ? 'closed' : 'open'}_lock_icon.svg`) "
              @click="toggleLockedInstrument(instrument)"
            >
          </div>

          <div class="td">
            <img
              class="cursor-pointer"
              alt=""
              :src="require('@/assets/img/icons/circled_minus_white_bg.svg')"
              :class="{ 'pointer-events-none opacity-50': !instrument.isRemovable }"
              @click="removeInstrument(instrument)"
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StockSecurityLogo from '@/components/subcomponents/StockSecurityLogo.vue'
import InputText from '@/components/subcomponents/InputText.vue'
import {mapGetters} from 'vuex'
import Button from '@/components/subcomponents/Button.vue'

export default {
  name: 'SellTargetsInstrumentTable',
  components: {Button, InputText, StockSecurityLogo},
  props: {
    total: {
      type: Number,
      default: 0
    },
    instruments: {
      type: Array,
      default: () => []
    },
    isSellAll: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      newPie: 'pies/newPie'
    }),

    currentSumOfSoldInstruments () {
      return this.soldInstruments.reduce((acc, instrument) => {
        return acc + parseFloat(instrument.position.current_value)
      }, 0)
    }
  },
  data () {
    return {
      soldInstruments: [],
      sumOfInstruments: 0,
      colorBlock: [
        'light-blue',
        'dark-blue',
        'yellow',
        'primary',
        'pink'
      ]
    }
  },
  mounted () {
    this.instruments.forEach(instrument => {
      if (instrument.current_value !== 0) {
        this.soldInstruments.push({
          // they are snake case for easier data transfer to the backend
          stock_security_id: instrument.stock_security.id,
          sold_percentage: 0,
          is_completely_sold: 0,
          position: instrument,
          soldValue: 0,
          valid: true,
          isLocked: false,
          isRemovable: true
        })
      }
    })

    this.distributeEquallySum()

    this.validateInstruments()
    this.determineRemovableInstruments()
  },
  methods: {
    determineRemovableInstruments () {
      this.soldInstruments.forEach(instrument => {
        instrument.isRemovable = this.currentSumOfSoldInstruments - parseFloat(instrument.position.current_value) >= parseFloat(this.total)
      })
    },

    removeInstrument (instrument) {
      this.soldInstruments = this.soldInstruments.filter((item) => {
        return item.stock_security_id !== instrument.stock_security_id
      })

      this.distributeEquallySum()
      this.determineRemovableInstruments()
    },

    toggleLockedInstrument (instrument) {
      instrument.isLocked = !instrument.isLocked

      if (!instrument.isLocked && Math.abs(parseFloat(instrument.soldValue) - parseFloat(instrument.position.current_value.toFixed(2))) < 0.09) {
        instrument.sold_percentage -= 0.1
        instrument.sold_percentage_shown = instrument.sold_percentage.toFixed(2)
        instrument.soldValue = ((instrument.sold_percentage / 100) * this.total).toFixed(2)
      }
    },

    distributeEquallySum () {
      if (this.isSellAll) {
        this.soldInstruments.forEach(instrument => {
          instrument.sold_percentage = instrument.position.current_value * 100 / this.total
          instrument.sold_percentage_shown = instrument.sold_percentage.toFixed(2)
          instrument.soldValue = ((instrument.sold_percentage / 100) * this.total).toFixed(2)
          instrument.isLocked = Math.abs(parseFloat(instrument.soldValue) - parseFloat(instrument.position.current_value.toFixed(2))) < 0.09
        })
        return
      }
      let unlockedInstruments = this.soldInstruments.filter(instrument => !instrument.isLocked)

      let total = 100
      this.sumOfInstruments = 0

      this.soldInstruments.forEach(instrument => {
        if (instrument.isLocked) {
          total -= instrument.sold_percentage_shown
          this.sumOfInstruments += parseFloat(instrument.soldValue)
        }
      })

      const length = unlockedInstruments.length
      const targetPercentage = Math.floor(total / length * 100) / 100
      let sumP = 0

      unlockedInstruments.forEach((instrument, index) => {
        if (index === length - 1) {
          instrument.sold_percentage = Math.max(0, Math.min((total - sumP), instrument.position.current_value * 100 / this.total))
        } else {
          instrument.sold_percentage = Math.max(0, Math.min(targetPercentage, instrument.position.current_value * 100 / this.total))
          sumP += instrument.sold_percentage
        }
        instrument.sold_percentage_shown = instrument.sold_percentage.toFixed(2)
        instrument.soldValue = ((instrument.sold_percentage / 100) * this.total).toFixed(2)
        instrument.isLocked = Math.abs(parseFloat(instrument.soldValue) - parseFloat(instrument.position.current_value.toFixed(2))) < 0.09

        this.sumOfInstruments += (instrument.sold_percentage / 100) * this.total
      })

      unlockedInstruments.forEach(instrument => {
        let soldInstrumentIndex = this.soldInstruments.findIndex(soldInstrument => soldInstrument.stock_security_id === instrument.stock_security_id)

        this.soldInstruments[soldInstrumentIndex] = instrument
      })

      const epsilon = 0.09

      if (this.sumOfInstruments < parseFloat(this.total) - epsilon) {
        this.distributeEquallySum()
      }
    },

    recalculateSum () {
      this.sumOfInstruments = 0

      this.soldInstruments.forEach((instrument) => {
        instrument.soldValue = ((instrument.sold_percentage / 100) * this.total).toFixed(2)
        this.sumOfInstruments += Math.round(((instrument.sold_percentage / 100) * this.total) * 100) / 100
        instrument.is_completely_sold = instrument.position.current_value - instrument.soldValue <= 0.09 ? 1 : 0
      })

      this.$emit('instrumentsTotalInvalid', Math.abs(Math.round(this.sumOfInstruments * 100) / 100 - parseFloat(this.total)) > 0.09)
    },

    validateInstruments () {
      let instrumentsInvalid = this.soldInstruments.filter(instrument => !instrument.valid).length

      this.$emit('instrumentsInvalid', !!instrumentsInvalid)
    },

    sliderChanged (e, instrument) {
      let soldInstrument = this.soldInstruments.find(soldInstrument => soldInstrument.stock_security_id === instrument.stock_security_id)

      soldInstrument.sold_percentage = Math.min(e.target.value, (((Math.floor(instrument.position.current_value * 100) / 100) * 100) / this.total))
      soldInstrument.sold_percentage_shown = soldInstrument.sold_percentage.toFixed(2)

      const currentValue = parseFloat(instrument.position.current_value.toFixed(2))
      const inputValue = (Math.floor(parseFloat(e.target.value)) / 100) * (parseFloat(this.total))

      soldInstrument.isLocked = inputValue >= currentValue + 10
    },

    inputChanged (e, instrument) {
      let soldInstrument = this.soldInstruments.find(soldInstrument => soldInstrument.stock_security_id === instrument.stock_security_id)
      soldInstrument.sold_percentage_shown = Math.min(e.length ? e : 0, Math.round((((Math.floor(instrument.position.current_value * 100) / 100) * 100) / this.total) * 100) / 100)

      soldInstrument.sold_percentage = Math.min(e.length ? e : 0, (((Math.floor(instrument.position.current_value * 100) / 100) * 100) / this.total))

      soldInstrument.isLocked = Math.abs(parseFloat(instrument.soldValue) - parseFloat(instrument.position.current_value.toFixed(2))) < 0.09
    }
  },

  watch: {
    soldInstruments: {
      handler: function () {
        this.validateInstruments()
        this.$emit('stockInstruments', this.soldInstruments)
        this.recalculateSum()
      },
      deep: true
    }
  }
}
</script>
