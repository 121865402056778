<template>
  <div class="select-drop-down">
    <label v-if="label" class="form-label inline-block pl-5" :class="{'grey-label' : greyLabel}" :for="id">{{ label }}</label>
    <img class="icon" v-if="icon" :src="require(`@/assets/img/icons/` + icon + `.svg`)" :class="{ 'no-icon': value }" alt="icon">
    <v-select :single-line="true"
              :value='valueFormatted'
              @input="inputChanged"
              :placeholder="placeholder"
              :searchable="false"
              :id="id"
              :clearable="clearable"
              :class="{'disabled': disabled, 'icon-wrapper': icon && !value, 'big-padding': bigPadding}"
              class="v-select input-primary w-full truncated-select"
              :options="optionsFormatted"
              :sufix="'text'"
    >
      <template #open-indicator="{ attributes }">
        <img :src="require('@/assets/img/icons/arrow-down-gray.svg')" v-bind="attributes">
      </template>
      <template v-slot:no-options>{{ $t('general.general.no_options') }}</template>
    </v-select>
  </div>

</template>

<script>
export default {
  name: 'SelectDropDown',
  props: {
    id: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    greyLabel: {
      type: Boolean,
      default: true
    },
    value: {
      type: [String, Object, Array],
      default: ''
    },
    options: {
      type: [Object, Array]
    },
    disabled: {
      type: Boolean,
      default: false
    },
    nameFormat: {
      type: String,
      default: ''
    },
    valueReturn: {
      type: Boolean,
      default: false
    },
    showCurrency: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: ''
    },
    bigPadding: {
      type: Boolean,
      default: false
    },
    isMobile: {
      type: Boolean,
      default: false
    },
    displayKey: {
      type: String,
      default: ''
    },
    attributes: {
      'ref': 'openIndicator',
      'role': 'presentation',
      'class': 'vs__open-indicator'
    },
    clearable: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    optionsFormatted () {
      if (this.displayKey) {
        return this.options.map(option => ({ ...option, label: option[this.displayKey] }))
      }
      if (this.showCurrency) {
        if (this.isMobile) {
          return this.options.map(option => ({ ...option, label: option.iban + ' ' + option.currency }))
        }
        return this.options.map(option => ({ ...option, label: option.iban.substring(0, 15) + '... ' + option.currency }))
      }
      return this.options
    },
    valueFormatted () {
      if (this.showCurrency) {
        if (this.isMobile) {
          return this.value ? this.value.iban.substring(0, this.value.iban.length - 3) + '... ' + ' ' + this.value.currency : ''
        }
        return this.value ? this.value.iban.substring(0, 12) + '... ' + this.value.currency : ''
      }
      return this.value
    }
  },
  methods: {
    inputChanged (value) {
      if (this.valueReturn) {
        this.$emit('input', value.value)
      } else {
        let sentValue = this.nameFormat ? value[this.nameFormat] : value
        this.$emit('input', sentValue)
      }
    }
  }
}
</script>

<style scoped>
</style>
