export default {
  general: {
    upload_new_document: 'Upload a new document',
    upload_document: 'Upload document',
    cancel: 'Cancel',
    document_name: 'Document name',
    edit: 'Edit',
    document_type: 'Document type',
    document_category: 'Document category',
    identity_card: 'Identity card',
    bank_statement_document: 'Bank statement document',
    other_documents: 'Other documents',
    enter_document_name: 'Please enter the document name!',
    select_document_type: 'Please select the document type!',
    new_document: 'Please upload the document!',
    size_too_big: 'Documentul nu poate depăși dimensiunea de 25MB',
    type_document: 'Format invalid!',
    type_assets_document: 'The file must be in PDF, DOC, DOCX, JPG, JPEG or PNG format.',
    select_document_category: 'Please select the document category!',
    download_draft_message: 'Please download the document below and send it completed with the rest of the additional documents.',
    download_draft_label: 'Download - Document Draft:',
    download: 'Download',
    delete_confirmation: 'Are you sure you want to delete {documentName}?',
    delete: 'Cancel',
    confirm: 'Delete',
    send_request: 'Send request',
    upload_successful: 'The request has been successfully recorded. The transfer of instruments will be made shortly.',
    pj_upload_successful: 'Documents have been uploaded. We will analyze them and we will notify you.',
    notifications_documents_missing: 'You have no documents attached to your notifications',
    send_documents: 'Send documents'
  },
  breadcrumb: {
    document: 'Documents',
    client_documents: 'Client\'s documents',
    profile_documents: 'Contractual documents',
    pj_documents: 'Legal person documents',
    notification_documents: 'Notification documents',
    invoices: 'Invoices'
  },
  legal_person: {
    contractual_documents: 'Documente contractuale',
    company_identification_documents: 'Documente identificare companie',
    documents_from_legal_person: 'List of documents required from the legal entity',
    documents_for_legal_person: 'Lista documentelor din partea Investimental',
    cui: 'The company\'s unique registration certificate (C.U.I.)',
    society_act: 'The articles of incorporation, the company contract, the company\'s statutes (the version in force on the date of the account opening request) - copy',
    beneficiary_declaration: 'Declaration regarding the beneficial owner registered with ONRC - copy',
    identity_documents: 'Copies of the identity documents, within their validity period, for the legal representative in relation to' +
      ' the Investmental, real beneficiary, administrators and persons authorized to make decisions on their behalf, as you presented these persons to us in Annex 1',
    multiple_admins: 'If there are several administrators with full powers, a Document from the company designating the person acting on the account opened at InvestiMental is required',
    bank_account_document: 'Bank account statement, other document certifying the holder of the bank account declared, through Annex 1, in relation to Investimental',
    lei_code: `The LEI code (can be obtained from the Central Depository - <a target="_blank" class="color-blue" href="https://lei.roclear.ro/">LEI Central Depository (roclear.ro))</a>`,
    attachment_1: 'Anexă 1 Date și informații persoane juridice',
    attachment_2: 'Anexă 2 Declarația privind rezidența fiscală persoane juridice',
    attachment_3: 'Anexă 3 Serviciul tranzacționare BVB Spot',
    attachment_4: 'Anexă 4 Serviciul tranzacționare Investimental Global',
    contract: 'Contract servicii de investiții financiare',
    evaluation_questionnaire: 'Chestionar evaluare - Reprezentanti legali/persoane care urmeaza sa ia decizii in numele si pe seama clientului PJ',
    signed_documents: 'The above documents must be signed by the legal representative with an electronic signature.',
    cui_button: 'Upload CUI',
    society_act_button: 'Upload the constitutive act',
    beneficiary_declaration_button: 'Upload beneficiary declaration',
    identity_documents_button: 'Upload identity documents',
    multiple_admins_button: 'Upload company document',
    bank_account_document_button: 'Upload bank account statement',
    lei_code_button: `Upload LEI code`,
    pj_documents_info: 'Pentru a finaliza procesul de Onboarding, trebuie să ne trimiți documentele de identificare ale companiei, precum și contractele și anexele semnate electronic de către tine, în calitate de reprezentant legal al societății. Noi le vom analiza și te vom anunța când contul tău va fi validat.',
    documents_from_investimental: 'List of documents provided by Investimental to the Legal Entity'
  }
}
