import {menus} from '@/menus'

const getDefaultState = () => {
  return {
    tabs: '',
    menuName: ''
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    resetState (state) {
      Object.assign(state, getDefaultState())
    },

    setMenu (state, tabs) {
      state.tabs = tabs
    },

    setMenuName (state, menuName) {
      state.menuName = menuName
    }
  },
  getters: {
    getMenu ({tabs}) {
      return tabs
    },

    getMenuName ({menuName}) {
      return menuName
    }
  },
  actions: {
    setMenu (ctx, to) {
      if (to) {
        ctx.commit('setMenu', menus[to])
        ctx.commit('setMenuName', to)
      } else {
        ctx.commit('setMenu', null)
        ctx.commit('setMenuName', null)
      }
    }
  }
}
