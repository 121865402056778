import {wpLink} from '../../../../mixins/WordpressLinks'

export default {
  general: {
    cnp: 'CNP',
    number: 'Number',
    address: 'Address',
    identity_card_type: 'Identity card type',
    series: 'Series',
    home_address: 'Home address',
    update_id_card_info: 'These data are taken from the identity document and can be only changed by updating it',
    update_id_card: 'Update identity card',
    update_ocr_data: 'Correct the data',
    upload_id_card: 'Please upload a valid identity card',
    save_message: 'If everything is OK click on `Save changes`.',
    success_id: 'You have successfully uploaded a new identity document',
    save_data_succes: 'The data are succesfully saved',
    first_name: 'First name',
    last_name: 'Last name',
    full_name: 'Full name',
    correspondence_address: 'Correspondence',
    change_address: 'Change address',
    full_address: 'Full address',
    permanent_address: 'Permanent address',
    expiration_date: 'Expiration date',
    release_date: 'Release date',
    username_not_generated: 'Generating...',
    domicile_address: 'Domicile address',
    identity_card_address: 'Address (identity card)',
    edit: 'Edit',
    address_note: 'Informațiile sunt salvate la click pe Continuă'
  },
  residence_data: {
    us_resident: 'I have  residency/tax obligations in US',
    ro_resident: 'I am tax resident in Romania',
    other_resident: 'I have residency/tax obligations in another country (does not apply to the USA)',
    fiscal_code: 'Tax identification code',
    fiscal_code_us: 'Tax identification code - USA',
    country: 'Country',
    select_option_validation: 'Please select at least one option',
    fiscal_code_validation: 'Complete with fiscal code for every country',
    country_validation: 'Please complete country and fiscal code',
    add_country: 'Add country',
    data_saved: 'Data saved!',
    duplicate_countries_validation: 'Please do not select multiple identical countries of residence'
  },
  calendar: {
    months: {
      january: {
        long: 'January',
        short: 'Jan'
      },
      february: {
        long: 'February',
        short: 'Feb'
      },
      march: {
        long: 'March',
        short: 'Mar'
      },
      april: {
        long: 'April',
        short: 'Apr'
      },
      may: {
        long: 'May',
        short: 'May'
      },
      june: {
        long: 'June',
        short: 'Jun'
      },
      july: {
        long: 'July',
        short: 'Jul'
      },
      august: {
        long: 'August',
        short: 'Aug'
      },
      september: {
        long: 'September',
        short: 'Sep'
      },
      october: {
        long: 'October',
        short: 'Oct'
      },
      november: {
        long: 'November',
        short: 'Nov'
      },
      december: {
        long: 'December',
        short: 'Dec'
      }
    },
    days: {
      monday: 'M',
      tuesday: 'T',
      wednesday: 'W',
      thursday: 'T',
      friday: 'F',
      saturday: 'S',
      sunday: 'S'
    }
  },
  referrals: {
    generate_link: 'Generate link',
    recommend_link: `Recommend Investimental and win! More details ` +
      `<a class="color-black font-bold" target="_blank" href="${wpLink('/recomanda-un-prieten/')}">HERE.</a>`,
    recommend_link_mobile: `Recommend us and win! Details ` +
      `<a class="color-black font-bold" target="_blank" href="${wpLink('/recomanda-un-prieten/')}">HERE.</a>`,
    message_copied_succesfully: 'Link copied successfully!'
  }
}
