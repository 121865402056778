import {USER_STATUS_APPROVED, USER_STATUS_NEW, USER_STATUS_PENDING, USER_STATUS_REJECTED} from './userService'
import Vue from 'vue'
import { generateFingerPrint } from '@/helpers/fingerPrint'
import getBrowserFingerprint from 'get-browser-fingerprint'

class CookieService {
  getLevel (user) {
    if (!user.has_access_in_portal) {
      return 'incomplete'
    }

    let cookieLvl = ''

    switch (user.status) {
      case USER_STATUS_NEW:
        cookieLvl = USER_STATUS_NEW
        break
      case USER_STATUS_APPROVED:
        cookieLvl = USER_STATUS_APPROVED
        break
      case USER_STATUS_REJECTED:
        cookieLvl = USER_STATUS_REJECTED
        break
      case USER_STATUS_PENDING:
        cookieLvl = USER_STATUS_PENDING
        break
      default:
        break
    }

    return cookieLvl
  }

  isDifferentFromCookie (user) {
    let userInfo = Vue.$cookies.get('userInfo')
    let cookieLvl = Vue.$cookies.get('isLogged')

    if (userInfo.id !== user.id) {
      return true
    }

    if (userInfo.first_name !== user.first_name) {
      return true
    }

    if (userInfo.last_name !== user.last_name) {
      return true
    }

    if (cookieLvl !== this.getLevel(user)) {
      return true
    }

    return false
  }

  userHasLoggedIn (user) {
    let cookieLvl = this.getLevel(user)

    let userInfo = {
      id: user.id,
      first_name: user.first_name,
      last_name: user.last_name
    }

    Vue.$cookies.set('userInfo', userInfo, '365d', null, process.env.VUE_APP_COOKIE_DOMAIN)
    Vue.$cookies.set('isLogged', cookieLvl, '365d', null, process.env.VUE_APP_COOKIE_DOMAIN)
  }

  userHasLoggedOut () {
    Vue.$cookies.remove('userInfo', null, process.env.VUE_APP_COOKIE_DOMAIN)
    Vue.$cookies.remove('isLogged', null, process.env.VUE_APP_COOKIE_DOMAIN)
  }

  fingerPrint () {
    let fingerPrint = Vue.$cookies.get('fingerPrint')

    if (!fingerPrint) {
      fingerPrint = getBrowserFingerprint()

      Vue.$cookies.set('fingerPrint', fingerPrint, '365d', null, process.env.VUE_APP_COOKIE_DOMAIN)
    }

    return fingerPrint.toString()
  }
}

export default new CookieService()
