<template>
  <div>
    <div v-if="!loadingPie">
      <div class="my-5 pie-back-button" @click="goBackToPies">
        <img alt="arrow-back" :src="require('@/assets/img/icons/simple_arrow_left_gray.svg')">
        <span>{{ $t('deposit_withdrawal.auto_invest.go_back_to_pies') }}</span>
      </div>
      <div class="desktop-flex gap-8">
        <Card :card-classes="'width-max w-full'" :height-full="true">
          <div slot="body" class="h-full">
            <div class="refresh-wrapper" v-if="pie.positions && pie.positions.length">
              <img class="refresh-icon" :class="{'sync-loading': loadingSyncPie, 'cool-down': timeout}" :src="require('@/assets/img/icons/refresh_icon.svg')" alt="refresh" @click="refreshData()">
              <div class="tooltip-wrapper">
                <img class="icon-info" alt="my-account" src="~@/assets/img/icons/info.svg">
                <div class="tooltip" v-if="dxAccountsTradingAccessEnabled">{{ $t('deposit_withdrawal.auto_invest.pie.refresh_info_short') }}</div>
                <div class="tooltip" v-else>{{ $t('deposit_withdrawal.auto_invest.pie.refresh_info') }}</div>
              </div>
            </div>
            <div class="flex flex-col h-full">
              <div class="flex-grow">
                <PieStocksTableDesktop
                  :stocks-pie="pie.positions"
                  :editing-pie="editingPie"
                />
                <div class="flex justify-end" v-if="!editingPie || !newPie.positions">
                  <Button
                    @call="openRebalancePie()"
                    extra-classes="px-5 mr-4"
                    :disabled="ordersInProgress || pie.total_value === 0 || loadingSyncPie ||
                          isNameEdit || (pie.positions && !pie.positions.length) || blockedPie"
                    :text="pie.total_value_invested > 0 ? $t('deposit_withdrawal.auto_invest.rebalance_pie') : $t('deposit_withdrawal.auto_invest.buy_pie')"
                    :type="'btn-primary'"
                  />

                  <Button
                    @call="editPie()"
                    extra-classes="px-5 mr-4"
                    :icon="'icons/circled_add'"
                    :icon-position="'left'"
                    :disabled="ordersInProgress || loadingSyncPie || isNameEdit || blockedPie"
                    :text="$t('deposit_withdrawal.auto_invest.edit_pie')"
                    :type="'btn-dark-background'"/>

                  <Button
                    @call="sell()"
                    extra-classes="px-5"
                    :disabled="ordersInProgress || pie.total_value_invested === 0 || loadingSyncPie ||
                     isNameEdit || (pie.positions && !pie.positions.length) || blockedPie"
                    :text="$t('deposit_withdrawal.auto_invest.sell_pie')"
                    :type="'btn-dark-outline'"/>
                </div>

                <PulseLoader class="mt-20 text-center" v-if="loadingSyncPie"/>
              </div>

              <div class="text-sm italic mb-2" v-if="!dxAccountsTradingAccessEnabled">
                <div class="mb-2 font-bold">{{ $t('deposit_withdrawal.auto_invest.pies_info.first_info')}}</div>
                <div v-html="$t('deposit_withdrawal.auto_invest.pies_info.second_info')"/>
              </div>

              <div class="flex justify-between" v-if="editingPie && newPie.positions">
                <div class="flex items-center">
                  <div
                    class="italic underline mr-4 cursor-pointer"
                    :class="{'pointer-events-none' : editingPie && !newPie.positions}"
                    @click="showCancelPieModal">
                    {{ $t('deposit_withdrawal.auto_invest.cancel_pie') }}
                  </div>
                </div>

                <Button
                  :loading=savingPie
                  @call="savePie"
                  extra-classes="px-5"
                  :text="$t('deposit_withdrawal.auto_invest.save_pie')"
                  :disabled="editingPie && !newPie.positions"
                  :type="'btn-dark-background'"/>
              </div>

              <div v-if="ordersInProgress">
                <div class="flex justify-between items-center">
                  <div>
                    {{ $t('deposit_withdrawal.auto_invest.completed_orders') }}
                    <span class="ml-3 color-primary font-bold">{{ ((ordersClosed/totalOrders)*100).toFixed(2) }}%</span>
                  </div>
                  <Button
                    :loading=cancelOrdersLoader
                    @call="cancelOrders"
                    extra-classes="px-5"
                    :text="$t('general.general.cancel')"
                    :type="'btn-dark-background'"/>
                </div>
                <div class="progress-bar mt-3.5" id="progress-bar"></div>
              </div>
            </div>

          </div>
        </Card>
        <Card :card-classes="'pie-card'">
          <div slot="title" class="mb-11">
            <div class="initiation-date" v-if="pie.created_at">
              {{ $t('deposit_withdrawal.auto_invest.pie.initiation_date') }}: {{ formatDate(pie.created_at) }}
            </div>
            <div class="flex title justify-between" :class="{'title-underline' : isNameEdit}">
              <div v-if="!isNameEdit">
                {{ pie.account_alias }}
              </div>

              <InputText
                class="input-pie-name"
                v-if="isNameEdit"
                :id="'pieName'"
                v-model="pieName"
                :type-input="'text'"
              />

              <img
                alt="edit-name"
                :src="require(`@/assets/img/icons/${ isNameEdit ? 'squared_check_mark' : 'squared_edit' }.svg`)"
                class="cursor-pointer"
                :class="[(isNameEdit && (pieName.length === 0 || pieName.length > 20)) || editingPie ? 'pointer-events-none opacity-50' : '']"
                @click="editName"
              >
            </div>
            <div class="initiation-date" v-if="pie.account_code">
              {{ pie.account_code }}
            </div>

            <div v-if="validations.nameLength" class="error-color text-xs">{{ validations.nameLength }}</div>
          </div>
          <div slot="body">
            <PieChartData
              v-if="pie.positions"
              :pie="pie"
              :disabled-buttons="ordersInProgress || getPieId === null || loadingSyncPie || isNameEdit || blockedPie || editingPie"
              :new-pie="pie.total_value === 0"
              :dataset-chart-labels="pie.positions.map(position => position.stock_security.symbol)"
              @withdrawalSetMessage="setMessageTransfer"
              @transferSetMessage="setMessageTransfer"
            />
          </div>
        </Card>
      </div>

      <PopUpModal
        class="h-fit"
        :modal-fit-content="true"
        :modal-opened="confirmationCancel"
        :modal-title="$t('general.general.attention')"
        @closeModal="closePopUp()">
        <div>
          <div class="text-center" v-html="$t('general.general.loose_updates')"/>
          <div class="flex justify-center gap-3">
            <Button class="mt-5 mx-auto" :text="$t('general.general.yes_undo')" :type="'btn-dark-outline'" @call="cancelPie()"/>
            <Button class="mt-5 mx-auto" :text="$t('general.general.no_thinking')" :type="'btn-dark-background'" @call="closePopUp()"/>
          </div>
        </div>
      </PopUpModal>

      <PopUpModal
        class="h-fit"
        :modal-fit-content="true"
        :modal-opened="showRebalanceModal"
        :modal-title="$t('deposit_withdrawal.auto_invest.rebalance.title')"
        @closeModal="closeRebalancePie()">
        <div>
          <div class="mb-4" v-html="$t('deposit_withdrawal.auto_invest.rebalance.message_1')"/>
          <div class="mb-4" v-if="commissions && pie.positions && commissions.length < pie.positions.length" v-html="$t('deposit_withdrawal.auto_invest.rebalance.orders_restrictions')"/>
          <div class="mb-4">
            <table class="w-full" v-if="!loadingCommissions">
              <thead>
                <tr>
                  <th>{{ $t('deposit_withdrawal.auto_invest.symbol') }}</th>
                  <th>{{ $t('deposit_withdrawal.auto_invest.side') }}</th>
                  <th>{{ $t('deposit_withdrawal.auto_invest.sum') }}</th>
                  <th>{{ $t('deposit_withdrawal.auto_invest.commission')}}</th>
                  <th>{{ $t('deposit_withdrawal.auto_invest.value') }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(commission, key) in commissions" :key="key">
                  <td class="text-center">{{ commission.symbol }}</td>
                  <td class="text-center">{{ commission.side }}</td>
                  <td class="text-center">{{ parseFloat(commission.orderValueWithoutCommission).toLocaleString('en-US', {maximumFractionDigits: 2, minimumFractionDigits:2}) }}</td>
                  <td class="text-center">{{ parseFloat(commission.commission).toLocaleString('en-US', {maximumFractionDigits: 2, minimumFractionDigits:2}) }}</td>
                  <td class="text-center">{{ parseFloat(commission.finalValue).toLocaleString('en-US', {maximumFractionDigits: 2, minimumFractionDigits:2}) }}</td>
                </tr>
              </tbody>
            </table>
            <pulse-loader class="text-center" v-else/>
          </div>

          <div class="text-center mb-4" v-html="$t('deposit_withdrawal.auto_invest.rebalance.message_2')"/>
          <div class="text-center font-bold" v-html="$t('deposit_withdrawal.auto_invest.rebalance.message_3')"/>

          <div class="flex justify-center gap-3 mt-8">
            <Button
              class="mx-auto"
              :text="$t('general.general.no_thinking')"
              :type="'btn-dark-outline'"
              :loading="loadingRebalancingPie"
              @call="closeRebalancePie()"
            />
            <Button
              class="mx-auto"
              :text="$t('general.general.agree')"
              :type="'btn-dark-background'"
              :loading="loadingRebalancingPie"
              @call="rebalancePie()"
            />
          </div>
          <div class="flex text-sm mt-5 justify-center color-blue-gray">
            <div class="mr-8">{{ $t('deposit_withdrawal.auto_invest.rebalance.account_code') }}: {{ pie.account_code }} </div>
            <div class="mr-3 font-semibold">{{ dateRebalance }}</div>
            <div class="font-semibold">{{ hoursRebalance }}</div>
          </div>
        </div>
      </PopUpModal>

      <PopUpModal
        class="h-fit"
        :modal-fit-content="true"
        :modal-opened="showMessageModal"
        :closable-modal="!messageTransfer"
        @closeModal="closeMessageModal()">

        <div class="text-center font-bold text-xl mb-3">{{ message }}</div>
        <Button :w100="true" v-if="!messageTransfer" class="mx-auto" :text="$t('general.general.close')" :type="'btn-dark-background'" @call="closeMessageModal()"/>

        <div v-if="secondMessage.length" class="text-sm mt-3">{{ secondMessage }}</div>
      </PopUpModal>

      <SellPie
        v-if="sellPie"
        :pie="pie"
        :max-available-sum="maxSumForSell"
        @closePopUp="sellPie = false"
        @startSellingPie="startSellingPie"
      />
    </div>

    <pulse-loader v-if="loadingPie" class="mt-20"/>

  </div>
</template>

<script>
import Button from '@/components/subcomponents/Button.vue'
import Card from '@/components/subcomponents/Card.vue'
import TwoFactorAuthenticationActivation from '@/components/auth/subcomponents/TwoFactorAuthenticationActivation.vue'
import DoughnutChart from '@/components/subcomponents/charts/DoughnutChart.vue'
import PieChart from '@/components/subcomponents/charts/PieChart.vue'
import PieChartData from '@/components/subcomponents/auto-invest/PieChartData.vue'
import PieStocksTableDesktop from '@/components/subcomponents/auto-invest/PieStocksTableDesktop.vue'
import InputText from '@/components/subcomponents/InputText.vue'
import piesService from '@/services/deposit-withdrawal/piesService'
import {mapActions, mapGetters} from 'vuex'
import PopUpModal from '@/components/subcomponents/PopUpModal.vue'
import SellPie from '@/components/subcomponents/auto-invest/SellPie.vue'
import { CronJob } from 'cron'

export default {
  name: 'ShowPieDesktop',
  components: {
    SellPie,
    PopUpModal,
    InputText,
    PieStocksTableDesktop,
    PieChartData,
    PieChart,
    DoughnutChart,
    TwoFactorAuthenticationActivation,
    Card,
    Button
  },
  props: {
    existingNewPie: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapGetters({
      newPie: 'pies/newPie',
      addedStockSecurities: 'pies/addedStockSecurities',
      deletedStockSecurities: 'pies/deletedStockSecurities',
      getPieId: 'pies/pieId',
      pies: 'pies/pies',
      minimumValueOfOrder: 'user/minimumValueOfOrder',
      dxAccountsTradingAccessEnabled: 'user/dxAccountsTradingAccessEnabled'
    }),
    maxSumForSell () {
      let sum = 0
      this.pie.positions.filter(position => {
        if (position.current_value) {
          sum += position.current_value
        }
      })
      return parseFloat(sum).toLocaleString('en-US', {maximumFractionDigits: 2})
    }
  },
  data () {
    return {
      pie: {},
      isNameEdit: false,
      pieName: '',
      editingPie: false,
      confirmationCancel: false,
      savingPie: false,
      showRebalanceModal: false,
      showMessageModal: false,
      message: '',
      sellPie: false,
      rebalancingPie: false,
      sellingPie: false,
      completedOrdersNumber: 0,
      totalOrders: 0,
      cancelOrdersLoader: false,
      interval: null,
      ordersInProgress: false,
      ordersClosed: 0,
      batchId: null,
      errorAdding: false,
      loadingSyncPie: false,
      loadingPie: false,
      timeout: false,
      commissions: [],
      loadingCommissions: false,
      dateRebalance: '',
      hoursRebalance: '',
      validations: {
        nameLength: ''
      },
      commissionPercentageEquity: 0,
      loadingRebalancingPie: false,
      messageTransfer: false,
      blockedPie: false,
      secondMessage: '',
      job: null
    }
  },
  async mounted () {
    await this.getPie()

    if (this.getPieId !== -1) {
      if (this.batchId && this.pie.status === this.$piesConstants.PIE_BLOCKED) {
        await this.getCompletedOrdersNumber()
      } else {
        if (this.job) {
          this.job.stop()
        }
      }

      if (this.pie.status === this.$piesConstants.PIE_BLOCKED) {
        this.blockedPie = true
      }

      this.loadingPie = false

      await this.refreshData()
    } else {
      this.loadingPie = false
    }
  },
  methods: {
    ...mapActions({
      setDeletedStockSecurities: 'pies/setDeletedStockSecurities',
      setAddedStockSecurities: 'pies/setAddedStockSecurities',
      setPieId: 'pies/setPieId',
      setNewPie: 'pies/setNewPie'
    }),

    todayDate () {
      const date = new Date()
      this.dateRebalance = date.getDate() + ' ' + date.toLocaleString('ro-RO', { month: 'long' }) + ' ' + date.getFullYear()
    },

    todayHours () {
      const date = new Date()
      this.hoursRebalance = date.getHours() + ':' + ('0' + (date.getMinutes())).slice(-2)
    },

    async refreshData () {
      this.loadingSyncPie = true
      if (!this.timeout) {
        await piesService.syncPie(this.pie.id).then(response => {
          this.pie = response.data.data.pie
        }).catch(error => {
          console.log(error)
        })
      }
      this.loadingSyncPie = false
      this.timeout = true

      setTimeout(() => {
        this.refreshTimeout()
      }, 1000 * 60 * 15)
    },

    refreshTimeout () {
      this.timeout = false
    },

    async getPie () {
      if (this.getPieId !== -1) {
        this.loadingPie = true

        await piesService.getPie(this.getPieId).then(response => {
          this.pie = response.data.data.pie
          this.batchId = response.data.data.batch_id
        }).catch(error => {
          console.log(error)
        })

        this.pieName = this.pie.account_alias

        if (this.editingPie) {
          this.pie.positions = this.newPie.positions
        }
      } else {
        this.editingPie = true
        this.pie = this.newPie
      }
    },

    formatDate (date) {
      let d = new Date(date)
      return ('0' + (d.getDate())).slice(-2) + '.' + ('0' + (d.getMonth() + 1)).slice(-2) + '.' + d.getFullYear()
    },

    closeMessageModal () {
      this.showMessageModal = false
      this.message = ''
      this.secondMessage = ''
      if (!this.pie.id && this.errorAdding) {
        this.$emit('goBackToPies')
        this.errorAdding = false
      }
    },

    goBackToPies () {
      this.$emit('goBackToPies')
      this.setDeletedStockSecurities([])
      this.setAddedStockSecurities([])
      this.setNewPie({})
    },

    async setMessage (message) {
      this.message = message
      this.showMessageModal = true

      if (this.pie.id && !this.messageTransfer) {
        await this.getPie()
        this.loadingPie = false
      }
    },

    setMessageTransfer (message) {
      this.messageTransfer = true
      this.setMessage(message)

      setTimeout(async () => {
        await this.getPie()
        this.loadingPie = false
        this.showMessageModal = false
        this.messageTransfer = false
      }, 5000)
    },

    async editName () {
      if (this.isNameEdit) {
        if (this.pieName !== this.pie.account_alias) {
          await this.saveName()
        }
        this.isNameEdit = false
      } else {
        this.isNameEdit = true
      }
    },

    async saveName () {
      let payload = {
        name: this.pieName
      }

      await piesService.updateName(this.pie.id, payload).then(response => {
        this.pie = response.data.data.pie
      }).catch(error => {
        console.log(error)
      })
    },

    showCancelPieModal () {
      this.confirmationCancel = true
    },

    closePopUp () {
      this.confirmationCancel = false
    },

    async cancelPie () {
      this.confirmationCancel = false
      this.editingPie = false
      await this.setDeletedStockSecurities([])
      await this.setAddedStockSecurities([])
      await this.setNewPie({})

      if (this.pie.id) {
        await this.getPie()
        this.loadingPie = false
        this.$emit('cancelUpdates')
      } else {
        this.$emit('goBackToPies')
      }
    },

    async savePie () {
      this.savingPie = true
      if (!this.pie.id) {
        await piesService.storePie(this.newPie).then(response => {
          this.pie = response.data.data.pie
          this.setPieId(this.pie.id)
        }).catch(error => {
          console.log(error)
          this.setMessage(error.response.data.message)
          this.errorAdding = true
        })

        this.editingPie = false
        this.savingPie = false
        this.$emit('savedPie')

        await this.$router.push({name: 'pie', params: {id: this.pie.id}})
      } else {
        this.pie.positions.forEach(position => {
          position['stock_security_id'] = position.stock_security.id
        })

        let payload = {
          added_stock_securities: this.addedStockSecurities,
          deleted_stock_securities: this.deletedStockSecurities,
          edited_targets: this.pie.positions
        }

        await piesService.updatePie(this.pie.id, payload).then(response => {
          this.pie = response.data.data.pie
        }).catch(error => {
          console.log(error)
          this.setMessage(error.response.data.message)
        })
        this.editingPie = false
        this.savingPie = false

        this.$emit('savedPie')
      }

      await this.setDeletedStockSecurities([])
      await this.setAddedStockSecurities([])
      await this.setNewPie({})
    },

    async cancelOrders () {
      this.cancelOrdersLoader = true
      await piesService.cancelOrders(this.pie.id, this.batchId).then(async () => {
        await this.getPie()
        this.loadingPie = false
        this.ordersInProgress = false
        this.job.stop()
      }).catch(error => {
        this.message = error.response.data.message
        this.showMessageModal = true
      })

      this.cancelOrdersLoader = false
    },

    async openRebalancePie () {
      this.loadingCommissions = true

      this.todayHours()
      this.todayDate()

      await piesService.getCommissionPercentageEquityPie(this.pie.id).then(response => {
        this.commissionPercentageEquity = response.data.data.commissionPercentageEquity / 100
      }).catch(error => {
        console.log(error)
      })

      await piesService.getCommissionsPie(this.pie.id).then(response => {
        this.commissions = response.data.data.commissions
      }).catch(error => {
        console.log(error)
      })

      let minSum = 0
      let positionFiltered = this.pie.positions.filter(position => position.target_percentage_of_pie > position.current_percentage_of_pie)
      let minPercentage = Math.min(...positionFiltered.map(position => position.target_percentage_of_pie))
      let impossibleRebalanceMessage = ''

      minSum = (parseFloat(this.minimumValueOfOrder) / (minPercentage / 100)) * (1 + this.commissionPercentageEquity) + 1
      minSum = Math.ceil(minSum * 100) / 100

      if (this.pie.total_value < minSum) {
        impossibleRebalanceMessage = this.$t('deposit_withdrawal.auto_invest.validations.min_sum_for_rebalance') + ' $' + minSum
      } else if (this.commissions.length === 0) {
        impossibleRebalanceMessage = this.$t('deposit_withdrawal.auto_invest.validations.no_orders_for_rebalance')
        this.secondMessage = this.$t('deposit_withdrawal.auto_invest.rebalance.orders_restrictions')
      }

      if (impossibleRebalanceMessage.length) {
        this.showMessageModal = true
        this.message = impossibleRebalanceMessage
      } else {
        this.showRebalanceModal = true
      }

      this.loadingCommissions = false

      await this.refreshData()
    },

    closeRebalancePie () {
      this.showRebalanceModal = false
      this.message = ''
    },

    startRecursiveRequestForOrdersNumber () {
      this.getCompletedOrdersNumber()

      let this1 = this

      this.job = new CronJob(
        '30 * * * * *',
        function () {
          this1.getCompletedOrdersNumber()
        },
        null,
        false,
        'Europe/Bucharest'
      )

      this.job.start()
    },

    async rebalancePie () {
      this.loadingRebalancingPie = true
      const uuidv4 = require('uuid/v4')

      this.batchId = uuidv4()
      await piesService.rebalancePie(this.pie.id, this.batchId).then(response => {
        this.message = response.data.message
        this.showMessageModal = true
        this.showRebalanceModal = false

        this.rebalancingPie = true
        this.getCompletedOrdersNumber()
      }).catch(error => {
        this.message = error.response.data.message
        this.showMessageModal = true
        this.showRebalanceModal = false
      })

      await this.getPie()
      this.loadingPie = false
      this.loadingRebalancingPie = false
    },

    async startSellingPie (payload) {
      this.sellingPie = true
      this.batchId = payload.batchId
      await this.getCompletedOrdersNumber()

      this.message = payload.message
      this.showMessageModal = true
      this.sellPie = false
    },

    editPie () {
      this.$emit('editPie', this.pie.id)
      this.editingPie = true

      this.refreshData()
    },

    sell () {
      this.sellPie = true

      this.refreshData()
    },

    async getCompletedOrdersNumber () {
      if (this.batchId) {
        let failedOrdersNumber = 0
        await piesService.getCompletedOrders(this.pie.id, this.batchId)
          .then(response => {
            this.completedOrdersNumber = response.data.data.completedOrdersNumber
            this.totalOrders = response.data.data.totalOrdersNumber
            failedOrdersNumber += response.data.data.canceledOrdersNumber
            failedOrdersNumber += response.data.data.rejectedOrdersNumber
            failedOrdersNumber += response.data.data.failedOrdersNumber
            this.ordersClosed = this.completedOrdersNumber + failedOrdersNumber

            if (this.ordersClosed < this.totalOrders) {
              this.ordersInProgress = true
            }

            let progress = document.getElementById('progress-bar')

            if (this.ordersClosed / this.totalOrders === 0 && progress) {
              progress.style.background = 'linear-gradient(to right, #3ad5af 0%, #e6e6e6 0%)'
            } else {
              progress.style.background = 'linear-gradient(to right, #3ad5af ' + ((this.ordersClosed / this.totalOrders) * 100) + '%, #e6e6e6 ' + ((this.ordersClosed / this.totalOrders) * 100) + '%)'
            }

            if (this.ordersClosed === this.totalOrders) {
              this.job.stop()
              this.ordersInProgress = false
              this.rebalancingPie = false
              this.sellingPie = false
              this.showMessageModal = true
              this.message = this.$t('deposit_withdrawal.auto_invest.rebalance.success_message')
              this.getPie()
              this.loadingPie = false
            }
          })
          .catch(error => {
            console.log(error)
          })
      }
    }
  },
  watch: {
    ordersInProgress: {
      handler: function (ordersInProgress) {
        if (ordersInProgress) {
          this.$nextTick(() => {
            if (this.ordersClosed < this.totalOrders) {
              this.startRecursiveRequestForOrdersNumber()
            }
          })
        }
      },
      deep: true
    },
    existingNewPie: {
      handler: function () {
        if (this.existingNewPie) {
          this.pie.positions = this.newPie.positions
          this.$emit('resetExistingNewPie')
        }
      },
      deep: true
    },
    pieName: {
      handler: function (val) {
        if (val.length > 20) {
          this.validations.nameLength = this.$i18n.t('deposit_withdrawal.auto_invest.name_pie_max_length')
        } else {
          this.validations.nameLength = ''
        }
      }
    }
  }
}
</script>
