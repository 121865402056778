<template>
  <div class="relative doughnut-chart" v-if="chartDataSets.datasets[0].data.length">
    <Doughnut
      class="relative"
      ref="doughnutChart"
      :chart-options="chartOptions"
      :chart-data="chartDataSets"
      :chart-id="chartId"
      :dataset-id-key="datasetIdKey"
      :plugins="plugins"
      :css-classes="cssClasses"
      :styles="styles"
      :width="width"
      :height="height"
    />

    <div v-if="legendPlace !== 'right'">
      <div class="legend-container">
        <div class="legend" v-for="(item, index) in chartDataSets.datasets[0].data" :key="index">
          <div class="legend-item flex items-center">
            <div class="legend-color" :class="[`label-color-${getColorIndex(index)}`]"></div>
            <div class="legend-label">{{ `${chartDataSets.labels[index]} - ${item.toFixed(2)}%` }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Doughnut } from 'vue-chartjs/legacy'

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale
} from 'chart.js'
import {mapGetters} from 'vuex'

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale)

export default {
  name: 'DoughnutChart',
  components: {
    Doughnut
  },
  props: {
    total: {
      type: Number,
      default: 0
    },
    chartId: {
      type: String,
      default: 'doughnut-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    chartDataSets: {
      type: Object,
      default: () => {}
    },
    plugins: {
      type: Array,
      default: () => []
    },
    legendPlace: {
      type: String,
      default: 'bottom'
    },
    withCurrency: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      screenDevice: 'layout/screenDevice'
    }),
    isMobile () {
      return this.screenDevice === this.$screenDeviceConstants.MOBILE
    }
  },
  methods: {
    kFormatter (num) {
      return Math.abs(num) > 999 ? Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + 'k' : Math.sign(num) * Math.abs(num).toFixed(2)
    },
    getColorIndex (index) {
      return this.chartDataSets.datasets[0].backgroundColor[index % 8].substring(1)
    }
  },
  created () {
    this.chartOptions.plugins.legend.position = this.isMobile ? 'bottom' : this.legendPlace

    if (!this.isMobile) {
      if (this.legendPlace === 'right') {
        this.height = 250
      }
    } else {
      if (this.legendPlace === 'right') {
        this.height = 350
      }
    }
  },
  data () {
    return {
      height: 250,
      width: 250,
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        onResize: (context) => {
          let totalDiv = document.getElementById('totalDiv' + this.chartId)

          if (!totalDiv) {
            totalDiv = document.createElement('div')
            totalDiv.classList.add(`total-bubble`)
            totalDiv.classList.add(`${this.legendPlace}-legend`)
            totalDiv.classList.add(`${this.chartId}`)

            const newContent = document.createTextNode(this.kFormatter(this.total) + (this.withCurrency ? ' RON' : ''))

            totalDiv.appendChild(newContent)
            totalDiv.id = 'totalDiv' + this.chartId

            context.canvas.parentNode.appendChild(totalDiv)
          }
        },
        elements: {
          arc: {
            borderWidth: 3
          }
        },
        plugins: {
          tooltip: {
            displayColors: false,
            callbacks: {
              title: function (tooltipItems, data) {
                return null
              },
              label: function (tooltipItems, data) {
                return tooltipItems.formattedValue + '%'
              }
            }
          },
          legend: {
            display: this.legendPlace === 'right',
            align: 'center',
            labels: {
              usePointStyle: true,
              generateLabels (chart) {
                const data = chart.data

                if (data.labels.length && data.datasets.length) {
                  const {labels: {pointStyle}} = chart.legend.options

                  return data.labels.map((label, i) => {
                    const meta = chart.getDatasetMeta(0)
                    const style = meta.controller.getStyle(i)

                    return {
                      text: `${label} - ${chart.data.datasets[0].data[i].toFixed(2)}%`,
                      fillStyle: style.backgroundColor,
                      strokeStyle: style.borderColor,
                      lineWidth: style.borderWidth,
                      pointStyle: pointStyle,
                      hidden: !chart.getDataVisibility(i),

                      index: i
                    }
                  })
                }

                return []
              }
            }
          }
        }
      }
    }
  }
}
</script>
