<template>
  <div class="pagination" v-if="data.total != 0">
    <div @click="goToPage(1)" class="button-element first">
      <img :src="require('@/assets/img/icons/double_arrow_left_white.svg')">
    </div>

    <div @click="goToPage( currentPage - 1)" class="button-element prev">
      <img :src="require('@/assets/img/icons/simple_arrow_left_white.svg')">
    </div>

    <div class="page-element" :class="[1 === currentPage ? 'active not-clickable': '']" @click="goToPage(1)">
      {{ 1 }}
    </div>

    <div class="flex gap-2.5" v-if="data.last_page > 2">

      <div v-if="pagesInView[0] - 1 !== 1">...</div>

      <div @click="goToPage(index)" class="page-element" :class="[index === currentPage ? 'active not-clickable': '']" v-for="index in pagesInView" :key="index">
          {{ index }}
      </div>

      <div v-if="pagesInView[pagesInView.length - 1] + 1 !== data.last_page">...</div>

    </div>

    <div class="page-element" :class="[data.last_page === currentPage ? 'active not-clickable': '']" @click="goToPage(data.last_page)">
      {{ data.last_page }}
    </div>

    <div v-if="data.last_page !== 1" @click="goToPage(currentPage + 1)" class="button-element next">
      <img :src="require('@/assets/img/icons/simple_arrow_left_white.svg')">
    </div>

    <div v-if="data.last_page !== 1" @click="goToPage(data.last_page)" class="button-element last">
      <img :src="require('@/assets/img/icons/double_arrow_left_white.svg')">
    </div>
  </div>
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    data: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      pagesInView: [2]
    }
  },

  computed: {
    currentPage () {
      return this.data.current_page
    }
  },

  methods: {
    goToPage ($page) {
      if (this.data.last_page > 1 && $page >= 1 && $page !== this.data.current_page && $page <= this.data.last_page) {
        this.$emit('goToPage', $page)
      }
    }
  },
  watch: {
    currentPage: {
      handler (val) {
        if (val === 1) {
          this.pagesInView = [2]
        } else if (val === 2) {
          this.pagesInView = [2, 3]
        } else if (val === this.data.last_page - 1) {
          this.pagesInView = [val - 1, val]
        } else if (val === this.data.last_page) {
          this.pagesInView = [this.data.last_page - 1]
        } else {
          this.pagesInView = [val - 1, val, val + 1]
        }
      },
      deep: true
    }
  }
}
</script>
