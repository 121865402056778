<template>
  <div class="personal-data">
    <general-layout>
      <template v-slot:title>
        <vue-title :title="$t('general.meta_titles.personal_data')"></vue-title>
      </template>
      <template v-slot:tabs>
        <Tabs
          :show-id-title="false"/>
      </template>

      <template v-slot:body>
        <div v-if="!hasAccessInPortal">
          <div class="desktop-flex gap-8">
            <ContinueToOnboarding></ContinueToOnboarding>

            <div v-if="!isMobile">
              <Card :card-classes="'w-full h-full' + (isMobile ? ' mb-8' : '')">
                <template v-slot:body>
                  <div>
                    <p v-html="$t('guides.personal_data.personal_p1')"/>
                    <br>
                    <p v-html="$t('guides.personal_data.personal_p2')"/>
                  </div>
                </template>
              </Card>
            </div>
          </div>
        </div>

        <div v-if="hasAccessInPortal" class="desktop-flex gap-8" :class="[isTablet ? 'flex-col items-center' : 'items-stretch']">
          <PulseLoader v-if="loading" class="w-fit m-auto p-10 justify-center flex"/>

          <Transition appear name="fade">
            <Card v-if="!loading" :card-classes="'mobile-border-none margin-bottom-big mobile-card-portal no-shadow'">
              <template v-slot:body>
                <IdentityCardForm
                  :edit-domicile="editDomicileAddress"
                  @edit="setCIEdit"
                  @closeAddressPopup="closeAddressPopup"
                />
              </template>
            </Card>
          </Transition>

          <AddressesCards :edit-ci="editCi" class="addresses-cards" @editDomicileAddress="setEditDomicileAddress"></AddressesCards>

          <input
            id="ic_document"
            :ref="'ic_document'"
            hidden
            name="ic"
            type="file"
            @change="handleFileUpload"
          />
        </div>

        <div :class="[!isMobile ? 'mt-8' : 'flex mt-8', isTablet ? 'flex-col items-center mt-8' : 'items-stretch']" class="desktop-flex gap-8" v-if="hasAccessInPortal">
          <Card :card-classes="'w-full' + (isMobile ? ' mb-8' : '')" v-if="!isMobile">
            <template v-slot:body>
              <div>
                <p v-html="$t('guides.personal_data.personal_p1')"/>
              </div>
            </template>
          </Card>

          <Card :card-classes="'w-full'" v-if="!isMobile">
            <template v-slot:body>
              <div>
                <p v-html="$t('guides.personal_data.personal_p2')"/>
              </div>
            </template>
          </Card>

          <div class="w-full" :class="{'mx-auto': isTablet}">
            <Resources :links="resourcesLinks"/>
          </div>
        </div>
      </template>
    </general-layout>
  </div>
</template>

<script>
import GeneralLayout from '../../../layout/GeneralLayout'
import Tabs from '../../../layout/Tabs'
import Button from '../../../subcomponents/Button'
import {mapGetters} from 'vuex'
import InputText from '../../../subcomponents/InputText'
import Card from '../../../subcomponents/Card'
import IconBox from '../../../subcomponents/IconBox.vue'
import IconMessage from '../../../subcomponents/IconMessage'
import Message from '../../../subcomponents/Message'
import ContinueToOnboarding from '../../ContinueToOnboarding'
import FullAddress from '../../../subcomponents/FullAddress'
import IdentityCardForm from './IdentityCardForm'
import FullAddressMixin from '../../../../mixins/FullAddress'
import PopUpModal from '../../../subcomponents/PopUpModal.vue'
import Resources from '../../../subcomponents/Resources.vue'
import AddressesCards from './AddressesCards.vue'

export default {
  name: 'PersonalData',
  components: {
    AddressesCards,
    Resources,
    PopUpModal,
    IdentityCardForm,
    FullAddress,
    GeneralLayout,
    Tabs,
    Button,
    Card,
    InputText,
    IconBox,
    IconMessage,
    Message,
    ContinueToOnboarding
  },
  mixins: [FullAddressMixin],
  data () {
    return {
      loading: true,
      validation: null,
      firstName: null,
      lastName: null,
      cnp: null,
      CI: null,
      userData: null,
      identity_card: null,
      savedData: false,
      editID: false,
      editName: false,
      editCnp: false,
      editCi: false,
      editDomicileAddress: false,
      loadingData: {
        saveAddress: false
      },
      resourcesLinks: [
        { label: this.$t('useful_links.personal_data.personal_data_identity_proof'), value: '/faq/cu-ce-document-fac-dovada-adresei/' },
        { label: this.$t('useful_links.personal_data.personal_data_address_proof'), value: '/faq/cu-ce-document-fac-dovada-adresei-2/' }
      ]
    }
  },
  async mounted () {
    if (this.isLogged) {
      await this.setData()
    }
  },
  methods: {
    setCIEdit (isEdit) {
      this.editCi = isEdit
    },
    async setData () {
      this.validation = null

      this.userData = this.user
      this.firstName = this.userData.first_name
      this.lastName = this.userData.last_name
      this.email = this.userData.email
      this.telephone = this.userData.phone

      if (this.userData.identity_card) {
        this.cnp = this.userData.identity_card.personal_identification_number
        this.CI = this.userData.identity_card.number
      }
      this.loading = false
    },
    handleFileUpload (event) {
      this.identity_card = event.target.files[0]
    },
    setEditDomicileAddress () {
      this.editDomicileAddress = true
    },
    closeAddressPopup () {
      this.editDomicileAddress = false
    }
  },
  computed: {
    ...mapGetters({
      screenDevice: 'layout/screenDevice',
      hasAccessInPortal: 'user/hasAccessInPortal',
      isLogged: 'user/isLogged',
      user: 'user/user'
    }),
    isMobile () {
      return this.screenDevice === this.$screenDeviceConstants.MOBILE
    },
    isTablet () {
      return this.screenDevice === this.$screenDeviceConstants.TABLET
    }
  },
  watch: {
    editCorrespondenceAddress (nVal) {
      if (nVal) {
        this.editPermanentAddress = false
      }
    },
    editPermanentAddress (nVal) {
      if (nVal) {
        this.editCorrespondenceAddress = false
      }
    }
  }
}
</script>

<style scoped></style>
