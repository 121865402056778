import routes from '../../router'
import {store} from '../index'
import screenDeviceConstants from '../../constants/screenDeviceConstants'

const getDefaultState = () => {
  return {
    // states could be 'basic', 'onboarding', 'portal'
    type: 'basic',
    screenDevice: '',
    generalLoading: false,
    collapsedReferralsMessage: false
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    resetState (state) {
      Object.assign(state, getDefaultState())
    },
    setType (state, type) {
      state.type = type
    },
    setScreenDevice (state, screenDevice) {
      state.screenDevice = screenDevice
    },
    setGeneralLoading (state, generalLoading) {
      state.generalLoading = generalLoading
    },
    setCollapsedReferralsMessage (state, collapsedReferralsMessage) {
      state.collapsedReferralsMessage = collapsedReferralsMessage
    },
    setCollapsedBannerMessage (state, collapsedBannerMessage) {
      state.collapsedBannerMessage = collapsedBannerMessage
    }
  },
  getters: {
    getType ({type}) {
      return type
    },
    screenDevice ({screenDevice}) {
      return screenDevice
    },
    generalLoading ({generalLoading}) {
      return generalLoading
    },
    collapsedReferralsMessage ({collapsedReferralsMessage}) {
      return collapsedReferralsMessage
    },
    collapsedBannerMessage ({collapsedBannerMessage}) {
      return collapsedBannerMessage
    }
  },
  actions: {
    setScreenDevice (ctx) {
      if (window.outerWidth <= screenDeviceConstants.MOBILE_MAX_SIZE) {
        ctx.commit('setScreenDevice', screenDeviceConstants.MOBILE)
        return
      }

      if (window.outerWidth > screenDeviceConstants.MOBILE_MAX_SIZE && window.innerWidth <= screenDeviceConstants.TABLET_MAX_SIZE) {
        ctx.commit('setScreenDevice', screenDeviceConstants.TABLET)
        return
      }

      ctx.commit('setScreenDevice', screenDeviceConstants.DESKTOP)
    },

    async setType (ctx, to = null) {
      let currentPathName = window.location.pathname

      if (to) {
        currentPathName = to.path
      }

      let currentRoute = routes.getRoutes().find(route => route.path === currentPathName || (route.regex && route.regex.test(currentPathName)))
      let isRegister = false
      if (currentRoute && (currentRoute.name === 'register' || currentRoute.name === 'register-lms' || currentRoute.name === 'register-pj')) {
        isRegister = true
      }

      let isBasicLayout = currentRoute && currentRoute.meta && currentRoute.meta.basicLayout
        ? currentRoute.meta.basicLayout : false

      if (!currentRoute) {
        window.location.href = '/login'
      }

      if (!store.getters['user/isLogged']) {
        if (isBasicLayout) {
          ctx.commit('setType', 'basic')
        } else if ((currentRoute && currentRoute.meta.isOnboarding) || isRegister) {
          ctx.commit('setType', 'onboarding')
        }
      } else {
        if (isBasicLayout) {
          ctx.commit('setType', 'basic')
        } else if ((currentRoute && currentRoute.meta.isOnboarding) || isRegister) {
          ctx.commit('setType', 'onboarding')
        } else {
          ctx.commit('setType', 'portal')
        }
      }
    },

    async setGeneralLoading (ctx, generalLoading) {
      ctx.commit('setGeneralLoading', generalLoading)
    },

    setCollapsedReferralsMessage (ctx, collapsedReferralsMessage) {
      ctx.commit('setCollapsedReferralsMessage', collapsedReferralsMessage)
    },

    setCollapsedBannerMessage (ctx, collapsedBannerMessage) {
      ctx.commit('setCollapsedBannerMessage', collapsedBannerMessage)
    }
  }
}
