<template>
  <div class="input-text">
    <pulse-loader v-if="!loaded"/>
    <input
      v-show="loaded"
      ref="autocomplete"
      type="text"
      :class="classname"
      :id="id"
      :placeholder="placeholder"
      :disabled="disabled"
      v-model="autocompleteText"
      @change="onChange"
    />
  </div>
</template>

<script>
import { Loader } from '@googlemaps/js-api-loader'

const ADDRESS_COMPONENTS = {
  subpremise: 'short_name',
  street_number: 'short_name',
  route: 'long_name',
  locality: 'long_name',
  administrative_area_level_1: 'long_name',
  administrative_area_level_2: 'long_name',
  country: 'long_name',
  postal_code: 'short_name',
  country_code: 'short_name',
  county_code: 'short_name',
  sublocality: 'short_name'
}

/*
  By default, we're only including basic place data because requesting these
  fields place data is not additionally charged by Google. Please refer to:

  https://developers.google.com/maps/billing/understanding-cost-of-use#basic-data
*/

const BASIC_DATA_FIELDS = ['address_components', 'adr_address', 'alt_id',
  'formatted_address', 'geometry', 'icon', 'id', 'name',
  'business_status', 'photo', 'place_id', 'scope', 'type', 'url',
  'utc_offset_minutes', 'vicinity']

export default {
  name: 'VueGoogleAutocomplete',

  props: {
    id: {
      type: String,
      required: true
    },

    classname: String,

    placeholder: {
      type: String
    },

    precompletedText: {
      type: String
    },

    disabled: {
      type: Boolean,
      default: false
    },

    types: {
      type: String,
      default: 'address'
    },

    fields: {
      type: Array,
      default: function () {
        return BASIC_DATA_FIELDS
      }
    },

    country: {
      type: [String, Array],
      default: null
    }
  },

  data () {
    return {
      /**
       * The Autocomplete object.
       *
       * @type {Autocomplete}
       * @link https://developers.google.com/maps/documentation/javascript/reference#Autocomplete
       */
      autocomplete: null,

      /**
       * Autocomplete input text
       * @type {String}
       */
      autocompleteText: '',
      loaded: false
    }
  },

  watch: {
    autocompleteText: function (newVal, oldVal) {
      this.$emit('inputChange', { newVal, oldVal }, this.id)
    },
    country: function (newVal, oldVal) {
      this.autocomplete.setComponentRestrictions({
        country: this.country === null ? [] : this.country
      })
    }
  },

  mounted () {
    const options = {}

    if (this.precompletedText) {
      this.autocompleteText = this.precompletedText
    }

    if (this.types) {
      options.types = [this.types]
    }

    if (this.country) {
      options.componentRestrictions = {
        country: this.country
      }
    }

    const loader = new Loader({
      apiKey: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
      version: 'weekly',
      libraries: ['places']
    })

    loader.load().then((google) => {
      this.autocomplete = new google.maps.places.Autocomplete(
        this.$refs.autocomplete,
        options
      )

      this.autocomplete.setFields(this.fields)

      this.autocomplete.addListener('place_changed', this.onPlaceChanged)
      this.loaded = true
    })
  },

  methods: {
    /**
     * When a place changed
     */
    onPlaceChanged () {
      let place = this.autocomplete.getPlace()

      if (!place.geometry) {
        // User entered the name of a Place that was not suggested and
        // pressed the Enter key, or the Place Details request failed.
        this.$emit('no-results-found', place, this.id)

        return
      }

      if (place.address_components !== undefined) {
        // return returnData object and PlaceResult object
        this.$emit('placechanged', this.formatResult(place), place, this.id)

        // update autocompleteText then emit change event
        this.autocompleteText = this.formatResult(place).route
        this.onChange()
      }
    },

    /**
     * When the input got changed
     */
    onChange () {
      this.$emit('change', this.autocompleteText)
    },

    /**
     * Update the value of the input
     * @param  {String} value
     */
    update (value) {
      this.autocompleteText = value
    },

    /**
     *
     * @param place
     * @returns {{}}
     */
    formatResult (place) {
      let returnData = {}

      // formating result for general fields
      for (let i = 0; i < place.address_components.length; i++) {
        let addressType = place.address_components[i].types[0]

        if (ADDRESS_COMPONENTS[addressType]) {
          returnData[addressType] = place.address_components[i][ADDRESS_COMPONENTS[addressType]]
        }
      }

      // formating result for custom fields
      for (let i = 0; i < place.address_components.length; i++) {
        let addressType = place.address_components[i].types[0]

        if (addressType === 'country') {
          returnData['country_code'] = place.address_components[i][ADDRESS_COMPONENTS['country_code']]
        }

        if (addressType === 'administrative_area_level_1') {
          returnData['county_code'] = place.address_components[i][ADDRESS_COMPONENTS['county_code']]

          if (returnData['county_code'] === 'București') {
            returnData['county_code'] = 'B'
          }
        }

        if (addressType === 'sublocality_level_1') {
          returnData['locality'] = place.address_components[i][ADDRESS_COMPONENTS['sublocality']]
        }

        if (addressType === 'postal_code') {
          returnData['zip_code'] = place.address_components[i][ADDRESS_COMPONENTS['postal_code']]
        }
      }
      return returnData
    }
  }
}
</script>
