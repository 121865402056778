export default {
  general: {
    info_title: 'Ai nevoie de mai mult ajutor?',
    info: 'Am pregătit pentru tine și o serie de <u><b>ghiduri</b></u> care să te ajute să începi cu dreptul tranzacționarea la bursă prin Investimental.'
  },
  sections: {
    transfers_payments: {
      title: 'Transferuri și plăți',
      labels: {
        add_iban: 'Vreau să adaug un nou cod IBAN, cum fac?',
        remove_iban: 'Vreau să șterg un cod IBAN, cum fac?',
        charge_account: 'Vreau să alimentez, cum fac?',
        which_accounts_can_charge: 'În ce conturi bancare Investimental pot face alimentarea?',
        time_to_receive_money_transaction: 'În cât timp ajung banii în contul de tranzacționare?',
        withdraw_money: 'Vreau să retrag bani, cum fac?',
        time_to_receive_money: 'În cât timp ajung banii în contul meu bancar după ce am solicitat retragerea?'
      }
    },
    account_administration: {
      title: 'Administrare cont de utilizator (Investimental și/sau Arena XT)',
      labels: {
        lost_access_to_email: 'Nu mai am acces la email, ce fac?',
        lost_access_to_phone: 'Nu mai am acces la numărul de telefon, ce fac?',
        arena_xt_account: 'Care este contul meu de utilizator Arena XT?',
        forgot_password: 'Am uitat parola, ce fac?',
        change_password: 'Vreau să-mi schimb parola, cum fac?',
        email_not_received: 'Nu am primit email-ul pentru resetarea parolei, ce fac?',
        single_sign_on: 'Ce înseamnă autentificare Single Sign-On (SSO) și la ce mă ajută?'
      }
    },
    open_account: {
      title: 'Deschidere cont',
      labels: {
        proof_of_identity: 'Cu ce document fac dovada identității?',
        proof_of_address: 'Cu ce document fac dovada adresei?',
        proof_of_residence: 'Ce documente sunt necesare pentru dovada rezidenței?',
        what_is_mifid: 'Ce este MIFID?'
      }
    }
  }
}
