<template>
  <div class="input-text">
    <label v-if="label" class="form-label inline-block pl-4" :class="{'grey-label' : greyLabel}" :for="id">{{ label }}</label>

    <input :disabled="disabledInput"
           :id="id"
           :autocomplete="autocomplete"
           :value="value ? value : null"
           :maxlength="maxLength"
           class="input-primary w-full"
           :class="{'disabled' : disabledInput, 'date': typeInput === 'date'}"
           :readonly="readOnly"
           :placeholder="inputPlaceholder" :type="inputTypeVisibility" @input="inputChanged" @focusout="focusOut">

    <div
      v-if="typeInput === 'password'"
      class="right-side-icon-container cursor-pointer"
      @click="showPassword()">
      <img
        v-if="inputTypeVisibility === 'password'"
        src="~@/assets/img/icons/show_password.svg"
        alt="eye-icon"
      />

      <img
        v-if="inputTypeVisibility === 'text'"
        src="~@/assets/img/icons/no_show_password.svg"
        alt="eye-icon"
      />
    </div>

    <div class="right-side-icon-container cursor-pointer" v-else-if="typeInput === 'text' && ( rightSideIcon || sufix )" @click="clickRightSideIcon">
      <img
        v-if="rightSideIcon"
        :src="icon"
        :alt="rightSideIcon"
      />
      <div v-if="sufix" class="opacity-50">{{sufix}}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InputText',
  props: {
    id: {
      type: String,
      default: ''
    },
    autocomplete: {
      type: String,
      default: 'on'
    },
    placeholder: {
      type: String,
      default: ''
    },
    typeInput: {
      type: String,
      default: 'text'
    },
    value: {
      type: [String, Number, Boolean],
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    greyLabel: {
      type: Boolean,
      default: false
    },
    disabledInput: {
      type: Boolean,
      default: false
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    rightSideIcon: {
      type: String,
      default: ''
    },
    sufix: {
      type: String,
      default: ''
    },
    maxLength: {
      type: Number,
      default: 200
    },
    maxDecimals: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      visibility: 'password'
    }
  },
  computed: {
    icon () {
      if (!this.rightSideIcon) {
        return ''
      }

      return require(`@/assets/img/icons/${this.rightSideIcon}.svg`)
    },
    inputPlaceholder () {
      if (this.typeInput === 'date') {
        return this.$t('general.forms.calendar.placeholder')
      }

      return this.placeholder
    },
    inputTypeVisibility () {
      if (this.typeInput === 'password') {
        return this.visibility
      }

      if (this.typeInput === 'date') {
        return 'text'
      }

      return this.typeInput
    }
  },
  methods: {
    inputChanged (e) {
      let value = e.target.value

      if (this.typeInput === 'number' && this.maxDecimals > 0) {
        let originalLength = value.length
        let originalDecimalPos = value.indexOf('.')

        if (value.includes('.') && value.split('.')[1].length > this.maxDecimals) {
          value = parseFloat(value).toFixed(this.maxDecimals)
          e.target.value = value

          let newDecimalPos = value.indexOf('.')
          let cursorPosition = e.target.selectionStart

          if (originalDecimalPos !== -1 && newDecimalPos !== -1) { // If there was and still is a decimal
            if (cursorPosition > newDecimalPos) {
              cursorPosition -= (originalLength - value.length) // Adjust based on length change
            }
          }
        }
      }

      this.$emit('input', value)
    },
    showPassword () {
      this.visibility === 'text' ? this.visibility = 'password' : this.visibility = 'text'
    },
    focusOut (e) {
      let value = e.target.value
      if (this.typeInput === 'number') {
        if (value === '') {
          value = '0.00'
          e.target.value = value
        } else {
          value = parseFloat(value).toFixed(this.maxDecimals)
          e.target.value = value
        }
      }

      this.$emit('focusout', value)
    },
    clickRightSideIcon () {
      this.$emit('clickRightSideIcon')
    }
  }
}
</script>
