import ApiService from '../apiService'

class AddressService {
  async changeAddress (payload) {
    return ApiService.withAuth().post('/api/user/addresses/change', {
      type: payload.type,
      address: payload.address
    })
  }
}

export default new AddressService()
