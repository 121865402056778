<template>
  <div>
    <general-layout>
      <template v-slot:title>
        <vue-title :title="$t('general.meta_titles.profile_documents')"/>
      </template>

      <template v-slot:tabs>
        <Tabs
          :show-id-title="false"
          :tabs-classes="tabsClasses"
        />
      </template>

      <template v-slot:body>
        <div v-if="!hasAccessInPortal">
          <div class="desktop-flex  mt-8 gap-8">
            <ContinueToOnboarding/>

            <div v-if="!isMobile">
              <Card :card-classes="'w-full h-full' + (isMobile ? ' mb-8' : '')">
                <template v-slot:body>
                  <div>
                    <p v-html="$t('guides.account_and_contact_data.account_p2')"/>
                    <br>
                    <p v-html="$t('guides.account_and_contact_data.account_p1')"/>
                  </div>
                </template>
              </Card>
            </div>
          </div>
        </div>

        <div class="mt-8" v-else>
          <Breadcrumb
            v-if="!isMobile"
            :items="[
              {
                label: $t('documents.breadcrumb.document')
              },
              {
                label: $t('documents.breadcrumb.profile_documents'),
                routeName: 'profile-documents'
              }
            ]"
          />

          <div class="flex gap-8 mt-8" v-if="!isMobile">
            <div class="w-full">
              <div>
                <Card class="w-full">
                  <template v-slot:body>
                    <div>
                      <p v-html="$t('guides.profile_documents.p1')"/>
                    </div>
                  </template>
                </Card>
              </div>

              <div class="mt-2">
                <Resources :links="resourcesLinks"/>
              </div>
            </div>

            <div v-if="user.type !== 'company'" class="w-full re-evaluate">
              <IconBox
                class="h-full"
                :routeName="'re-evaluate'"
                :text="$t('guides.general.evaluate_again')"
                icon-color="blue"
                icon="questionnaire"
              >
                <template v-slot:top>
                  <p v-html="$t('guides.profile_documents.p2')"/>
                </template>
              </IconBox>
            </div>
          </div>

          <div v-if="!isMobile" class="my-12 bg-white h-px"></div>

          <div class="client-documents">
            <Card v-if="isMobile" class="w-full mb-4" card-classes="padding-big">
              <template v-slot:body>
                <div>
                  <p v-html="$t('guides.profile_documents.p1')"/>
                </div>
              </template>
            </Card>

            <IconBox
              v-if="isMobile && user.type !== 'company'"
              class="re-evaluate mb-8"
              :routeName="'re-evaluate'"
              :text="$t('guides.general.evaluate_again')"
              icon-color="blue"
              icon="questionnaire"
            >
              <template v-slot:top>
                <p class="mb-10" v-html="$t('guides.profile_documents.p2')"/>
              </template>
            </IconBox>

            <Transition appear name="fade" v-if="documents">
              <div class="documents-data">
                <div
                  class="info document"
                  v-for="(document, counter) in documents" :key="counter">
                  <DocumentCard :document="document" :counter="counter"/>
                </div>
              </div>
            </Transition>
          </div>

          <div v-if="isMobile" class="mt-8">
            <Resources :links="resourcesLinks"/>
          </div>
        </div>
      </template>
    </general-layout>
  </div>
</template>

<script>
import GeneralLayout from '../../layout/GeneralLayout'
import Tabs from '../../layout/Tabs'
import Button from '../../subcomponents/Button'
import { mapGetters } from 'vuex'
import Card from '../../subcomponents/Card'
import IconBox from '../../subcomponents/IconBox.vue'
import ContinueToOnboarding from '../ContinueToOnboarding'

import DocumentService from '../../../services/documents/documentsService'
import Resources from '../../subcomponents/Resources.vue'
import UploadDocuments from '../../subcomponents/UploadDocuments.vue'
import Breadcrumb from '../../subcomponents/Breadcrumb.vue'
import DocumentCard from './DocumentCard.vue'

export default {
  name: 'ProfileDocuments',
  components: {
    DocumentCard,
    Breadcrumb,
    UploadDocuments,
    Resources,
    GeneralLayout,
    Tabs,
    Button,
    Card,
    IconBox,
    ContinueToOnboarding
  },
  data () {
    return {
      loading: true,
      addNewIban: false,
      newIban: null,
      newIbanDocument: null,
      documents: null,
      tabsClasses: [],
      resourcesLinks: [
        { label: this.$t('useful_links.profile_documents.category'), value: '/ghid/ghid-incadrare-in-categoria-client-profesional-la-cerere/' },
        { label: this.$t('useful_links.profile_documents.professional'), value: '/wp-content/uploads/2023/07/2023-03-Cerere-incadrare-categoria-client-profesional.pdf' }
      ]
    }
  },
  async created () {
    if (this.isLogged) {
      await this.setData()
    }
  },
  methods: {
    async setData () {
      let allDbDocuments = await DocumentService.getAllDocuments()

      this.documents = allDbDocuments.data.data
    },
    async download (doc) {
      await DocumentService.downloadDocument(doc)
    },
    handleFileUpload (event) {
      this.newIbanDocument = event.target.files[0]
    }
  },
  computed: {
    ...mapGetters({
      screenDevice: 'layout/screenDevice',
      hasAccessInPortal: 'user/hasAccessInPortal',
      isLogged: 'user/isLogged',
      user: 'user/user'
    }),
    isMobile () {
      return this.screenDevice === this.$screenDeviceConstants.MOBILE
    }
  }
}
</script>

<style scoped></style>
