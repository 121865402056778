<template>
  <div class="icon-message">
    <div class="message">
      <div class="icon"><img :src="getIcon" alt="icon" /></div>
        <div class="description" :class="[{ green: colorGreen }]">
          {{ text }}
        </div>
    </div>
    <div v-if="subtitle" class="subtitle">{{ subtitle }}</div>
  </div>
</template>

<script>
export default {
  name: 'IconMessage',
  props: {
    text: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    textColor: {
      type: String,
      default: ''
    },
    margin: {
      type: Boolean,
      default: false
    },
    colorGreen: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    getIcon () {
      if (this.icon) {
        return require(`@/assets/img/${this.icon}.svg`)
      }
    }
  },
  methods: {
    goToRoute () {
      if (this.routeName) {
        this.$router.push({ name: this.routeName })
      }
    }
  }
}
</script>

<style scoped></style>
