var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('general-layout',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('vue-title',{attrs:{"title":_vm.$t('general.meta_titles.profile_documents')}})]},proxy:true},{key:"tabs",fn:function(){return [_c('Tabs',{attrs:{"show-id-title":false,"tabs-classes":_vm.tabsClasses}})]},proxy:true},{key:"body",fn:function(){return [(!_vm.hasAccessInPortal)?_c('div',[_c('div',{staticClass:"desktop-flex mt-8 gap-8"},[_c('ContinueToOnboarding'),(!_vm.isMobile)?_c('div',[_c('Card',{attrs:{"card-classes":'w-full h-full' + (_vm.isMobile ? ' mb-8' : '')},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('div',[_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t('guides.account_and_contact_data.account_p2'))}}),_c('br'),_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t('guides.account_and_contact_data.account_p1'))}})])]},proxy:true}],null,false,1009614343)})],1):_vm._e()],1)]):_c('div',{staticClass:"mt-8"},[(!_vm.isMobile)?_c('Breadcrumb',{attrs:{"items":[
            {
              label: _vm.$t('documents.breadcrumb.document')
            },
            {
              label: _vm.$t('documents.breadcrumb.invoices'),
              routeName: 'invoices'
            }
          ]}}):_vm._e(),_c('div',{staticClass:"client-documents"},[(_vm.isMobile)?_c('Card',{staticClass:"w-full mb-4",attrs:{"card-classes":"padding-big"},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('div',[_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t('guides.profile_documents.p1'))}})])]},proxy:true}],null,false,3182864415)}):_vm._e(),(_vm.isMobile && _vm.user.type !== 'company')?_c('IconBox',{staticClass:"re-evaluate mb-8",attrs:{"routeName":'re-evaluate',"text":_vm.$t('guides.general.evaluate_again'),"icon-color":"blue","icon":"questionnaire"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('p',{staticClass:"mb-10",domProps:{"innerHTML":_vm._s(_vm.$t('guides.profile_documents.p2'))}})]},proxy:true}],null,false,2559604872)}):_vm._e(),(_vm.documents)?_c('Transition',{attrs:{"appear":"","name":"fade"}},[_c('div',{staticClass:"documents-data"},_vm._l((_vm.documents),function(document,counter){return _c('div',{key:counter,staticClass:"info document"},[_c('DocumentCard',{attrs:{"document":document,"counter":counter}})],1)}),0)]):_vm._e()],1)],1)]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }