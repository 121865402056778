export function romanianPINValidation (pin) {
  const genderDigit = parseInt(pin[0])
  const year = parseInt(pin.substring(1, 3))
  const month = parseInt(pin.substring(3, 5))
  const day = parseInt(pin.substring(5, 7))
  const countyCode = pin.substring(7, 9)
  const controlDigit = parseInt(pin[12])

  if (genderDigit < 1 || genderDigit > 8) {
    return false
  }

  if (year < 0 || year > 99) {
    return false
  }

  if (month < 1 || month > 12) {
    return false
  }

  const isLeapYear = (year % 4 === 0 && (year % 100 !== 0 || year % 400 === 0))
  const maxDaysInMonth = (month === 2 && isLeapYear) ? 29 : new Date(2000 + year, month, 0).getDate()

  if (day < 1 || day > maxDaysInMonth) {
    return false
  }

  const validCountyCodes = []
  for (let i = 1; i <= 56; i++) {
    const formattedNumber = String(i).padStart(2, '0')
    validCountyCodes.push(formattedNumber)
  }

  if (!validCountyCodes.includes(countyCode)) {
    return false
  }

  const controlWeights = [2, 7, 9, 1, 4, 6, 3, 5, 8, 2, 7, 9]
  let sum = 0

  for (let i = 0; i < 12; i++) {
    sum += parseInt(pin[i]) * controlWeights[i]
  }

  const remainder = sum % 11
  const expectedControlDigit = (remainder === 10) ? 1 : remainder

  return expectedControlDigit === controlDigit
}
