import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import sumsub from './modules/sumsub'
import questionnaire from './modules/questionnaire'
import layout from './modules/layout'
import VuexPersist from 'vuex-persist'
import menus from './modules/menus'
import updatePersonalData from './modules/updatePersonalData'
import identityCard from './modules/identityCard'
import guide from './modules/guide'
import pies from './modules/pies'
import staticData from './modules/staticData'

const vuexLocalStorage = new VuexPersist({
  key: 'vuex',
  storage: window.localStorage,
  reducer: (state) => {
    const persistedState = {
      guide: state.guide,
      layout: state.layout,
      menus: state.menus,
      staticData: state.staticData,
      sumsub: state.sumsub,
      pies: state.pies,
      updatePersonalData: {
        isUpdating: state.updatePersonalData.isUpdating
      },
      twoFactor: state.user.twoFactor,
      user: {
        isLogged: state.user.isLogged,
        token: state.user.token,
        jwt: state.user.jwt
      }
    }

    if (state.updatePersonalData.isUpdating) {
      persistedState.updatePersonalData = {
        step: state.updatePersonalData.step,
        updatedPersonalData: state.updatePersonalData.updatedPersonalData,
        temporaryStepQuestionnaire: state.updatePersonalData.temporaryStepQuestionnaire,
        isUpdating: state.updatePersonalData.isUpdating
      }
    }

    return persistedState
  }
})

Vue.use(Vuex)
Vue.use(require('vue-cookies'))

export const store = new Vuex.Store({
  modules: {
    user,
    sumsub,
    questionnaire,
    layout,
    menus,
    updatePersonalData,
    identityCard,
    guide,
    pies,
    staticData
  },
  plugins: [vuexLocalStorage.plugin]
})

window.clearStorage = function () {
  store.commit('user/resetState')
  store.commit('sumsub/resetState')
  store.commit('questionnaire/resetState')
  store.commit('updatePersonalData/resetState')
  store.commit('identityCard/resetState')
  store.commit('guide/resetState')
  store.commit('staticData/resetState')
  store.commit('pies/resetState')
}
