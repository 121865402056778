<template>
  <div>
    <div class="text-lg pb-7">
      {{ parent === 'onboarding' ? $t("onboarding.email_verification.email_verification_guidance") : $t("update_personal_data.email_verification.email_verification_guidance")}}

      <b> {{ " " + email }} </b>
    </div>

    <div class="text-lg pb-7">
      {{ parent === 'onboarding' ? $t("onboarding.email_verification.code_guidance") : $t("update_personal_data.email_verification.code_guidance")}}
    </div>

    <form>
      <div class="text-lg mb-px">
        {{ $t("onboarding.email_verification.verif_code") }}
      </div>

      <OtpInput
        @on-complete="setVerificationCode"
        @on-key-enter="setVerificationCode"
        @on-paste="setVerificationCode">
      </OtpInput>

      <pulse-loader v-if="loading" class="m-auto w-fit" />

      <Message
        v-if="validation.verificationCode"
        :message="validation.verificationCode"
        :type="'error'"
        class="m-auto"
      />

      <Message
        v-if="validation.resentCode"
        :message="validation.resentCode"
        :type="'success'"
        class="m-auto"
      />

      <div class="mt-5">
        <div
          class="color-blue font-bold margin-bot-resend cursor-pointer"
          @click="resendValidationCode">
          {{ $t("onboarding.email_verification.resend_code") }}
        </div>

        <div class="flex justify-between py-4">
          <Button
            @call="resetStep()"
            :text="$t('general.general.back')"
            :type="'btn btn-secondary padding-medium end-position no-wrap'"
            :icon="'icons/arrow_back_green'"
            :icon-position="'left'"
          />
          <Button
            :text="$t('onboarding.general.continue')"
            :type="'btn btn-primary padding-big end-position'"
            :loading="loadingData.validation || loading"
            @call="validateCode"
          />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import Button from './Button.vue'
import OtpInput from './security-code/OtpInput.vue'
import Message from './Message.vue'
import {mapActions, mapGetters} from 'vuex'
import ApiService from '../../services/apiService'

export default {
  name: 'EmailVerificationCode',
  components: { Button, OtpInput, Message },
  props: {
    parent: {
      type: String,
      default: 'onboarding'
    },
    email: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      loadingData: {
        validation: false
      },
      verificationCode: '',
      loading: false,
      validation: {
        verificationCode: '',
        resentCode: ''
      }
    }
  },
  computed: {
    ...mapGetters({
      updatedData: 'updatePersonalData/getUpdatedData',
      user: 'user/user'
    })
  },
  methods: {
    ...mapActions({
      resendCode: 'user/resendCode'
    }),
    resetStep () {
      if (this.parent === 'onboarding') {
        this.$emit('resetStep')
      } else {
        this.$emit('prev', 'updateEmail')
      }
    },
    setVerificationCode (code) {
      this.$emit('setVerificationCode', code)
      this.verificationCode = code
    },
    async resendValidationCode () {
      this.loading = true
      let response = ''

      if (this.parent === 'onboarding') {
        response = await this.resendCode().finally(() => {
          this.loading = false
        })
      } else {
        response = await ApiService.withAuth().post('/api/user/update-email/generate-code', {
          email: this.email
        }).finally(() => {
          this.loading = false
        })
      }

      if (response && response.status === 200) {
        this.validation.resentCode = this.$i18n.t(
          'onboarding.email_verification.code_verif_success'
        )
      }
    },
    async validateCode () {
      this.loadingData.validation = true
      try {
        if (this.parent === 'onboarding') {
          this.$emit('validate')
        } else {
          if (!this.validate()) {
            return
          }

          let updatedData = this.updatedData

          updatedData.step_email.verificationEmail = this.verificationCode

          await ApiService.withAuth().post('/api/user/update-email/verify-code', {
            email_verify_code: this.verificationCode
          }).catch((error) => {
            this.validation.verificationCode = error.response.data.message
          })
          if (this.validation.verificationCode === '' && this.user.type !== 'company') {
            let nextStep = 'updateDomicile'

            this.$emit('next', {updatedData, nextStep})
          }
        }
      } finally {
        this.loadingData.validation = false
      }
    },
    validate () {
      let valid = true

      if (!this.verificationCode) {
        this.validation.verificationCode = this.$t(
          'onboarding.email_verification.code_validation'
        )

        valid = false
      }

      return valid
    }
  },
  watch: {
    verificationCode (nVal) {
      if (nVal) {
        this.validation.verificationCode = ''
      }
    }
  }
}
</script>

<style scoped>

</style>
