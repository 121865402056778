<template>
  <div class="personal-data">
    <general-layout>
      <template v-slot:title>
        <vue-title :title="$t('general.meta_titles.account_transfers')"/>
      </template>

      <template v-slot:tabs>
        <Tabs
          :show-id-title="false"
        />
      </template>

      <template v-slot:body>
        <div>
          <div v-if="!hasAccessInPortal" class="desktop-flex gap-8 items-stretch">
            <ContinueToOnboarding/>

            <div>
              <Card
                class="w-full"
                :card-classes="'mobile-card-resources padding-small flex-layout'"
                :no-title="true"
                v-if="!isMobile"
              >
                <template v-slot:body>
                  <img class="big-icon" :src="require(`@/assets/img/icons/paper-plane.svg`)" alt="paper-plane">

                  <div>
                    <p v-html="$t('deposit_withdrawal.account_transfers.account_transfers_p1')"/>
                    <br>
                    <p v-html="$t('deposit_withdrawal.account_transfers.account_transfers_p2')"/>
                  </div>
                </template>
              </Card>
            </div>
          </div>

          <div v-if="hasAccessInPortal">
            <div class="desktop-flex gap-8">
              <Card
                :class="[!isMobile ? 'w-1/2 !p-12' : '!py-11', isTablet ? 'h-fit !px-12 !pb-12 !pt-4' : '']"
                :w100="true" :no-title="true"
                :card-classes="'margin-bottom-big mobile-card-portal'"
              >
                <template v-slot:title>
                  <div>{{ $t("deposit_withdrawal.account_transfers.new_transfer") }}</div>
                </template>

                <template v-slot:body>
                  <div class="my-2 flex">
                    <div class="mr-2">
                      {{ $t('deposit_withdrawal.account_transfers.available_sum') }}:
                    </div>

                    <div v-if="accountBalance && showCurrency">
                      {{ parseFloat(accountBalance).toLocaleString('en-US', {maximumFractionDigits: 2}) }} {{ fromAccount ? fromAccount.currency : null }}
                    </div>

                    <div v-else class="flex justify-center items-center">
                      <img class="h-4" src="@/assets/img/icons/clock.svg" alt="clock">
                    </div>
                  </div>

                  <div :class="{'desktop-flex': isDesktop}" class=" gap-7 justify-between mb-5">
                    <SelectDropDown
                      :class="[!isDesktop ? 'w-full' : 'w-1/2']"
                      class="mb-5"
                      :id="'accountsFrom'"
                      :options="filterData('from')"
                      :label="$t('deposit_withdrawal.account_transfers.from')"
                      v-model="fromAccount"
                      @input="setSelectedAccount($event, 'from')"
                    />

                    <div class="flex gap-7 w-1/2" v-if="isDesktop">
                      <InputText
                        class="w-1/2"
                        :id="'accountFromSum'"
                        v-model="fromAccountSum"
                        :label="$t('deposit_withdrawal.account_transfers.sum')"
                      />

                      <InputText
                        class="w-1/2"
                        :id="'accountsFromCurrency'"
                        :value="fromAccount && showCurrency ? fromAccount.currency : null"
                        :read-only="true"
                        :label="$t('deposit_withdrawal.account_transfers.currency')"
                      />
                    </div>
                  </div>

                  <Message
                    v-if="validations.fromAccount"
                    :message="validations.fromAccount"
                    :type="'error'"
                    class="m-auto"
                  />

                  <div class="flex justify-between gap-7">
                    <SelectDropDown
                      :class="[!isDesktop ? 'w-full' : 'w-1/2']"
                      :id="'accountsTo'"
                      :options="filterData('to')"
                      :label="$t('deposit_withdrawal.account_transfers.to')"
                      v-model="toAccount"
                      @input="setSelectedAccount($event, 'to')"
                    />

                    <div v-if="isDesktop" class="w-1/2"></div>
                  </div>

                  <div class="flex mt-8" v-if="!isDesktop">
                    <div>
                      <InputText
                        class="mb-7"
                        :id="'accountFromSum'"
                        v-model="fromAccountSum"
                        :label="$t('deposit_withdrawal.account_transfers.sum')"
                      />

                      <InputText
                        :id="'accountsFromCurrency'"
                        :value="fromAccount ? fromAccount.currency : null"
                        :read-only="true"
                        :label="$t('deposit_withdrawal.account_transfers.currency')"
                      />
                    </div>

                    <img v-if="!isDesktop" class=" w-1/2" src="@/assets/img/icons/money-transfer.svg" alt="money-transfer">
                  </div>

                  <Message
                    v-if="validations.toAccount"
                    :message="validations.toAccount"
                    :type="'error'"
                    class="m-auto"
                  />

                  <Message
                    v-if="validations.sum"
                    :message="validations.sum"
                    :type="'error'"
                    class="m-auto"
                  />

                  <Message
                    v-if="serverError"
                    :message="serverError"
                    :type="'error'"
                    class="m-auto"
                  />

                  <Button
                    class="mt-6"
                    :text="$t('general.general.send')"
                    :loading="loadingData.transfer"
                    @call="sendData"
                    :type="'btn-primary'"
                  />

                  <Message
                    v-if="messageSuccess"
                    :message="messageSuccess"
                    :type="'success'"
                    class="m-auto"
                  />

                  <p v-if="differentCurrency" class="font-bold">{{ $t('deposit_withdrawal.general.different_currencies') }}</p>

                </template>
              </Card>

              <div :class="{'w-1/2': isTablet}">
                <Card
                  class="w-full"
                  :card-classes="'mobile-card-resources padding-small hide-on-mobile'"
                  :no-title="true"
                  v-if="!isMobile"
                >
                  <template v-slot:body>
                    <div class="desktop-flex" :class="{'flex-col': isTablet}">
                      <img class="big-icon" :src="require(`@/assets/img/icons/paper-plane.svg`)" alt="paper-plane">

                      <div class="ml-8">
                        <p v-html="$t('deposit_withdrawal.account_transfers.account_transfers_p1')"/>
                        <br>
                        <p v-html="$t('deposit_withdrawal.account_transfers.account_transfers_p2')"/>
                      </div>
                    </div>
                  </template>
                </Card>

                <div class="w-full" :class="[isMobile ? 'mt-8' : 'mt-4']">
                  <Resources :links="resourcesLinks"/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </general-layout>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import GeneralLayout from '../../layout/GeneralLayout'
import Tabs from '../../layout/Tabs'
import Button from '../../subcomponents/Button'
import InputText from '../../subcomponents/InputText'
import Card from '../../subcomponents/Card'
import IconBox from '../../subcomponents/IconBox.vue'
import Message from '../../subcomponents/Message'
import ContinueToOnboarding from '../ContinueToOnboarding'
import SelectDropDown from '../../subcomponents/SelectDropDown'
import AccountTransfersService from '../../../services/deposit-withdrawal/accountTransfersService'
import Calendar from '../../subcomponents/Calendar.vue'
import Resources from '../../subcomponents/Resources.vue'

export default {
  name: 'AccountTransfers',
  components: { Resources, Calendar, SelectDropDown, GeneralLayout, Tabs, Button, InputText, Card, IconBox, Message, ContinueToOnboarding },
  data () {
    return {
      loadingData: {
        transfer: false
      },
      accounts: [],
      accountsList: [],
      accountBalance: '',
      fromAccount: '',
      fromAccountSum: '',
      toAccount: '',
      messageSuccess: '',
      serverError: '',
      validations: {
        toAccount: '',
        fromAccount: '',
        sum: ''
      },
      filters: {
        dateStart: null,
        dateEnd: null,
        status: null
      },
      statuses: [
        { label: this.$t('deposit_withdrawal.account_transfers.status.pending'), value: 'pending' },
        { label: this.$t('deposit_withdrawal.account_transfers.status.charged'), value: 'charged' },
        { label: this.$t('deposit_withdrawal.account_transfers.status.canceled'), value: 'canceled' }
      ],
      resourcesLinks: [
        {
          label: this.$t('useful_links.instruments_transfer.transfer_guide'),
          value: '/faq/transfer-intre-conturile-mele/'
        }
      ],
      showCurrency: false
    }
  },
  computed: {
    ...mapGetters({
      screenDevice: 'layout/screenDevice',
      hasAccessInPortal: 'user/hasAccessInPortal',
      isLogged: 'user/isLogged',
      user: 'user/user'
    }),
    differentCurrency () {
      if (!this.fromAccount || !this.toAccount) {
        return false
      }

      return this.fromAccount.currency !== this.toAccount.currency
    },

    isMobile () {
      return this.screenDevice === this.$screenDeviceConstants.MOBILE
    },

    isTablet () {
      return this.screenDevice === this.$screenDeviceConstants.TABLET
    },

    isDesktop () {
      return this.screenDevice === this.$screenDeviceConstants.DESKTOP
    }
  },
  mounted () {
    if (this.isLogged) {
      this.setAccounts()
    }
  },
  watch: {
    fromAccount () {
      this.validations.fromAccount = ''
      this.serverError = ''

      this.getAvailableSum()
    },
    toAccount () {
      this.validations.toAccount = ''
      this.serverError = ''
    },
    fromAccountSum () {
      this.validations.sum = ''
      this.serverError = ''
    }
  },
  methods: {
    async getAvailableSum () {
      if (this.fromAccount) {
        await AccountTransfersService.getAccountBalance({
          internalAccount: this.fromAccount.code
        })
          .then(response => {
            this.accountBalance = response.data.data.toString()
            this.showCurrency = true
          })
      } else {
        this.accountBalance = ''
      }
    },
    setSelectedAccount (selectedAccount, accountType) {
      if (accountType === 'from') {
        this.fromAccount = this.accounts.find(account => account.code === selectedAccount.code)

        this.showCurrency = false
      } else {
        this.toAccount = this.accounts.find(account => account.code === selectedAccount.code)
      }
    },

    async setAccounts () {
      let accounts = await AccountTransfersService.getAccounts()

      this.accounts = accounts.data.data
      this.accounts = this.accounts
        .filter(account => (account.source === 'arenaxt' && account.status.toLowerCase() !== 'closed') || (account.source !== 'arenaxt' && !account.is_from_pie))

      this.accounts
        .forEach(account => {
          account.name = (account.source === 'arenaxt' ? 'BVB' : 'GLOBAL') + ' ' +
            account.alias + ' ' + account.currency
        })

      this.accountsList = this.accounts.map(account => account.code)
    },

    filterData (filter) {
      this.accounts.forEach(account => {
        account.label = account.name
      })

      if (filter === 'from' && this.toAccount) {
        return this.accounts.filter(account => account.code !== this.toAccount.code)
      } else if (filter === 'to' && this.fromAccount) {
        return this.accounts.filter(account => account.code !== this.fromAccount.code)
      }

      return this.accounts
    },

    async sendData () {
      this.loadingData.transfer = true

      try {
        let payload = {
          fromAmount: this.fromAccountSum,
          fromAccountId: this.fromAccount.code,
          toAccountId: this.toAccount.code
        }

        if (!this.validate()) {
          setTimeout(() => {
            this.loadingData.transfer = false
          }, 1000)

          return
        }

        await this.$confirm(
          this.$t('deposit_withdrawal.account_transfers.confirm_transfer'),
          '',
          'warning',
          {
            cancelButtonText: this.$t('documents.general.delete'),
            confirmButtonText: this.$t('documents.general.confirm'),
            onClose: () => {
              setTimeout(() => {
                this.loadingData.transfer = false
              }, 1000)
            }
          }
        ).then(async () => {
          await AccountTransfersService.sendTransfer(payload)
            .then((response) => {
              if (response.status === 200 || response.status === 201) {
                this.messageSuccess = this.$i18n.t('deposit_withdrawal.account_transfers.message_success')
              }

              setTimeout(() => {
                this.fromAccount = ''
                this.fromAccountSum = ''
                this.toAccount = ''
                this.accountBalance = ''
              }, 1000)

              setTimeout(() => {
                this.messageSuccess = ''
                this.loadingData.transfer = false
              }, 2000)
            })
            .catch((err) => {
              this.serverError = err.response.data.message
              this.loadingData.transfer = false
            })
        })
      } finally {
      }
    },

    formatDate (date) {
      let nDate = new Date(date)

      return `${nDate.getDate()}.${('0' + (nDate.getMonth() + 1)).slice(-2)}.${nDate.getFullYear()}`
    },

    validate () {
      let valid = true

      if (!this.toAccount) {
        this.validations.toAccount = this.$i18n.t('deposit_withdrawal.account_transfers.validation_account')
        valid = false
      }

      if (!this.fromAccount) {
        this.validations.fromAccount = this.$i18n.t('deposit_withdrawal.account_transfers.validation_account')
        valid = false
      }

      if (isNaN(this.fromAccountSum)) {
        this.validations.sum = this.$i18n.t('deposit_withdrawal.account_transfers.validation_sum_NaN')
        valid = false
      }

      if (this.fromAccountSum <= 0) {
        this.validations.sum = this.$i18n.t('deposit_withdrawal.account_transfers.validation_sum_greater_than_zero')
        valid = false
      }

      if (!this.fromAccountSum && this.fromAccount) {
        this.validations.sum = this.$i18n.t('deposit_withdrawal.account_transfers.validation_sum')
        valid = false
      }

      if (parseFloat(this.fromAccountSum) > parseFloat(this.accountBalance)) {
        this.validations.sum = this.$i18n.t('deposit_withdrawal.account_transfers.validation_insufficient_funds')
        valid = false
      }

      return valid
    }
  }
}
</script>

<style scoped>

</style>
