import {wpLink} from '@/mixins/WordpressLinks'

export default {
  general: {
    guides: 'Ghiduri',
    deposit_guide: 'Ghid alimentare cont',
    required_documents: 'Acte necesare',
    options: 'Opțiuni',
    evaluate_again: 'Evaluează din nou',
    retake_guide: 'Reia ghidul'
  },
  deposits: {
    deposits_p1: 'Pentru alimentări cât mai rapide, îți recomandăm să folosești detaliile de plată pre-completate automat de către noi în casetele din partea stânga accesand butonul <img class="inline-block" width="30px" height="30px" src="' + '{fileSrc}' + '"/> Algoritmul de recomandare a contului bancar optimizează procesul de transfer de bani, luând în considerare banca plătitoare, banca beneficiară și valuta contului.',
    deposits_p2: 'Dacă vrei să alimentezi dintr-un alt cont bancar, neînregistrat în Portal, trebuie să-l adaugi în secțiunea <a target="_blank" class="color-blue" href="/bank-accounts">Administrare coduri IBAN.</a>',
    deposits_p1_opened: 'Pentru alimentare iti recomandam sa folosesti detaliile de plata completate de noi automat in dreptul fiecarui cont IBAN pentru ca banii sa ajunga cat mai repede in platforma de tranzactionare.'
  },
  bank_accounts: {
    bank_accounts_p1: '<span class="font-bold color-username">Validarea codului IBAN</span> e facută automat odată cu prima alimentare din contul bancar aferent.',
    bank_accounts_p2: 'Poți înregistra alte coduri IBAN (conturi bancare deschise pe numele tău), pe care vrei să le folosești în relația cu Investimental. Pentru tranzacționarea pe platforma Arena XT, poți adăuga <span class="font-bold color-username">maximum 4 coduri IBAN</span> (pentru conturile tale bancare în RON și EUR).',
    bank_accounts_p3: 'Solicitarile de retragere vor fi permise doar din conturi bancare ale căror coduri IBAN au fost validate.',
    bank_accounts_p1_v1: '<span class="font-bold color-username">Validarea codului IBAN</span> e făcută automat odată cu prima alimentare din contul bancar aferent. Poți înregistra alte coduri IBAN pe care vrei să le folosești în relația cu Investimental.',
    bank_accounts_p2_v1: 'Pentru tranzacționarea pe platforma Arena XT, poți adăuga <span class="font-bold color-username">maximum 4 coduri IBAN</span> (pentru conturi bancare in RON și EUR). Solicitările de retragere vor fi permise doar din conturi bancare ale căror coduri IBAN au fost validate.',
    bank_accounts_addnew_p1: 'Poți adăuga maxim 4 conturi IBAN deschise pe numele tău, în RON și EUR pentru tranzacționarea în Arena XT.',
    bank_accounts_addnew_p2: 'Nu se vor permite solicitări de retrage în conturi IBAN nevalidate printr-o alimentare',
    bank_accounts_addnew_p3: 'Validarea contului IBAN declarat la deschiderea de cont se face automat la prima alimentare efectuată din acel cont.',
    bank_accounts_addnew_p4: 'Dacă dorești să înregistrezi alt cont IBAN ce va fi folosit în relația cu Investimental, te rugăm să le adaugi aici prin selectarea butonului Adaugă un cont IBAN .'
  },
  withdrawals: {
    withdrawals_p1: 'Înainte de a iniția o solicitare de retragere din contul tău de tranzacționare, asigură-te că IBAN-ul declarat în relația cu Investimental a fost validat.',
    withdrawals_p2: 'Asta înseamnă că ai făcut cel puțin o alimentare din acel cont IBAN.'
  },
  instruments_transfer: {
    instruments_transfer_p1: 'Poți transfera instrumentele doar dacă acestea sunt decontate!',
    instruments_transfer_p2: 'Ai instrumente la Depozitarul Central și vrei o situație actualizată, atunci urmează pașii de <a class="color-blue cursor-pointer font-bold break-all" href="https://www.roclear.ro/Detinatori/DetinatoriEmitereExtras">aici</a>'
  },
  account_and_contact_data: {
    account_p1: '<b class="font-bold color-username">Adresa de email și numărul de telefon</b> sunt elemente de identificare ale tale în calitate de client Investimental. Îți recomandăm să nu le modifici decât dacă este absolut necesar!',
    account_p2: '<span class="font-bold color-username">User-ul</span> este același pentru Portal, Investimental BVB și Investimental Global. Acesta poate fi folosit pentru conectarea directă la platformele de tranzacționare Investimental BVB (web sau mobile) și Investimental Global (mobile), dacă nu dorești accesarea acesteia prin intermediul Portalului Investimental. User-ul tău e format din inițialele numelui plus ultimele 6 cifre ale CNP-ului.',
    account_p2_company: '<span class="font-bold color-username">User-ul</span> este același pentru Portal, Investimental BVB și Investimental Global. Acesta poate fi folosit pentru conectarea directă la platformele de tranzacționare Investimental BVB (web sau mobile) și Investimental Global (mobile), dacă nu dorești accesarea acesteia prin intermediul Portalului Investimental.'
  },
  personal_data: {
    personal_p1: '<b>Dacă îți schimbi documentul de identitate sau adresa reședinței permanente</b>, te rugăm să actualizezi imediat informațiile din această secțiune și să încarci un nou document de identitate (dacă este cazul).',
    personal_p2: 'Am preluat adresa ta de domiciliu ca și <b>adresă de corespondență</b>. Dacă vrei să primești eventuala corespondență în format fizic la altă adresă, te rugăm să folosești opțiunea “Editează”.'
  },
  fiscal_data: {
    fiscal_p1: `<i>Înainte de a încărca documentele care atestă rezidența ta de domiciliu, te rugăm să citești <a target="_blank" href="${wpLink('/faq/documente-rezidenta/')}" class="cursor-pointer color-blue font-bold">“Ghid pentru stabilirea rezidenței”</a>.</i>`,
    fiscal_p2: 'Stabilirea rezidenței tale este necesară pentru determinarea obligațiilor tale fiscale în relația cu autoritățile fiscale competente.'
  },
  password_reset: {
    password_p1: 'Parola va fi aceeași pentru Investimental BVB și Investimental Global. Dacă ești logat în Portal, vei fi autentificat automat în platformele de tranzacționare BVB și Global în momentul accesării acestora (via Single Sign-On).'
  },
  two_factor_activation: {
    two_factor_p1: 'Autentificarea cu 2 factori (2FA) este o funcție de securitate suplimentară destinată protejării contului tău de utilizator. Pentru activarea 2FA, trebuie să instalezi aplicația corespunzatoare pe telefonul mobil.',
    two_factor_p2: 'Dacă 2FA este activată și vrei să te loghezi în Portalul Investimental, alături de parolă ți se va solicita introducerea unui cod generat dinamic prin aplicația corespunzătoare.'
  },
  profile_documents: {
    p1: 'Aici găsești toate documentele contractuale în relația ta cu Investimental. Îți recomandăm să le parcurgi cu atenție și să ne contactezi oricând ai nelămuriri.',
    p2: 'Chestionarul privind evaluarea caracterului adecvat al produselor și serviciilor oferite este disponibil mai jos. Te rugăm să-l actualizezi la orice modificare a informațiilor relevante.',
    p3: 'Înainte de a solicita încadrarea ca și client profesional, îți recomandăm să citești Ghidul de mai jos.'
  },
  client_documents: {
    p1: 'Aici poți încărca și vizualiza documentele pe care le-ai transmis către Investimental pe parcursul relației contractuale.'
  },
  notification_documents: {
    p1: 'Aici poate fi vizualizat toate documentele din notificări.'
  },
  account_transfers: {
    account_transfers_p1: 'Completează contul tău de tranzacționare din care dorești să efectuezi transferul,' +
      ' suma pe care vrei să o transferi și valuta, iar noi vom efectua operațiunea de schimb valutar în platforma bancară.' +
      ' Banii vor fi transferați între conturile tale deschise la Investimental după primirea confirmării de la bancă.'
  },
  multi_account: {
    info_p1: 'Imaginează-ți că poți avea mai multe conturi pe care să aplici diverse strategii de investiții: fie că ' +
      'visezi la educația copiilor tăi, la un proiect măreț pentru viitor sau poate doar îți dorești să experimentezi.',
    info_p2: 'Alegerea îți aparține!'
  }
}
