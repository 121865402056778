<template>
  <div>
    <vue-title :title="$t('general.meta_titles.notifications')"></vue-title>
    <general-layout>
      <template v-slot:tabs>
        <Tabs :show-id-title="false" :user-info="userInfo" :general-info="generalInfo"/>
      </template>

      <template v-slot:body>
        <ClientNotifications
          :img-name="'morning_news'"
          :hide-guide="true"
          :only-search-input="true"
          :all-read-button="true"
          type="morning"
        />
      </template>
    </general-layout>
  </div>
</template>

<script>

import ClientNotifications from '@/components/profile/notifications/ClientNotifications.vue'
import {mapActions, mapGetters} from 'vuex'
import GeneralLayout from '@/components/layout/GeneralLayout.vue'
import Tabs from '@/components/layout/Tabs.vue'

export default {
  name: 'MorningNews',
  components: {Tabs, GeneralLayout, ClientNotifications},
  computed: {
    ...mapGetters({
      unreadUserNotifications: 'user/unreadUserNotifications',
      unreadGeneralNotifications: 'user/unreadGeneralNotifications'
    }),
    userInfo () {
      return this.unreadUserNotifications
    },
    generalInfo () {
      return this.unreadGeneralNotifications
    }
  },
  created () {
    this.getUnreadNotifications()
  },
  methods: {
    ...mapActions({
      getUnreadNotifications: 'user/getUnreadNotifications'
    })
  }
}
</script>
