import {wpLink} from '../../../../mixins/WordpressLinks'

export default {
  general: {
    date_added: 'Date added',
    date_transferred: 'Date transferred',
    confirmed: 'Confirmed',
    rejected: 'Rejected',
    pending: 'Pending',
    new: 'New',
    download_bank_statement: 'Download bank statement',
    status: 'Status',
    redirect: 'Take me there',
    different_currencies: 'The operation involves a currency exchange carried out by Investimental at the exchange ' +
      'rate of the partner bank. For amounts above or equivalent to 1,000 EUR, Investimental will negotiate the ' +
      'exchange rate on behalf of the client. Currency exchanges can be made until 17:00.',
    total: 'Total'
  },
  bank_accounts: {
    add_new_iban: 'Add a new IBAN',
    add_iban: 'Add IBAN',
    bank: 'Bank',
    currency: 'Currency',
    existing_iban: 'This iban already exists in the database',
    invalid_iban: 'The iban account entered is not valid',
    account_verif: 'The account could not be verified. Please contact us.',
    complete_data: 'Please fill the IBAN field, currency and upload your document',
    complete_iban: 'Please fill the IBAN field an currency',
    delete_confirmation: 'Are you sure you want to delete the bank account {bankAccount}?',
    is_revolut: 'Is it a Revolut account?',
    exceeded_no_of_bvb_accounts: 'You have already exceeded the maximum number of RON and EUR accounts (you can have a maximum of 4).'
  },
  deposits: {
    details: 'Detalii privind cum se fac alimentările',
    message: 'To make this top-up, please initiate the payment from your internet banking application using the following payment details:',
    beneficiary: 'Beneficiar: Investimental',
    iban: 'IBAN:',
    my_account_title: 'Contul meu inregistrat la Investimental',
    pay_details: 'Detalii de plată:',
    usd_to_usd_transfer: 'USD transfers between banks in Romania are carried out through correspondent ' +
      'banks, which involves a processing time of approx. 2 business days and transfer ' +
      'fees out of Investment\'s control.',
    recurrent_deposit: 'Pentru a beneficia de un flux constant de bani în contul de tranzacționare, îți recomandăm să folosești opțiunea alimentărilor recurente (dacă este disponibilă în aplicația ta de internet banking)',
    deposit_info: 'Detaliile de plată pentru alimentări folosind un cont bancar în {currency} sunt următoarele:',
    download_label: 'Extras Cont',
    deposit: 'Deposit',
    extra_accounts_message: 'The top-up will be made to the original account. If you want to fund an additional account, you will need to initiate a <a class="color-blue" href="/account-transfers">transfer between accounts.</a>',
    success: 'Your withdrawal request has been successfully submitted!',
    failed: 'Your withdrawal request has failed, please try again later.'
  },
  withdrawals: {
    details: 'Detalii privind modalitatea cum se fac retragerile',
    guide: 'Ghid despre retrageri',
    details_arenaXt: 'Intră în <b>ArenaXT</b> - accesează din fereastra de operațiuni meniul Retrageri, apasă pe butonul <b>+</b> și completează câmpurile. Acum trimite și de aici preluăm noi.',
    withdraw: 'Withdraw',
    success: 'Your withdrawal request has been successfully submitted!',
    failed: 'Your withdrawal request has failed, please try again later.'
  },
  instruments_transfer: {
    to_investimental: 'Către Investimental',
    from_investimental: 'De la Investimental',
    to: {
      first_case: {
        title: 'I. Dacă ai instrumente la alt broker:',
        first_step: `<b class="black">Pasul 1:</b> Notifică-ți brokerul la care ai instrumentele financiare că vrei să-ți transferi portofoliul către Investimental și solicită-i un extras de portofoliu.`,
        second_step: `<b class="black">Pasul 2:</b> Descarcă <b><a target="_blank" href="${wpLink('/wp-content/uploads/2022/11/Cerere_transfer_instrumente.pdf')}" class="color-primary cursor-pointer">cererea de transfer</a></b> și completează toate câmpurile. Cererea trebuie semnată și încărcată mai jos.`,
        third_step: `<b class="black">Pasul 3:</b> Încarcă Extrasul de portofoliu primit de la broker.`,
        forth_step: `<b class="black">Pasul 4:</b> Investimental va contacta brokerul de la care vrei să transferi instrumentele pentru a finaliza transferul portofoliului. Vei primi o notificare pe email imediat ce instrumentele tale sunt încărcate în platforma de tranzacționare BVB.`
      },
      second_case: {
        title: 'II. Dacă ai instrumente la Depozitarul Central:',
        first_step: `<b class="black">Pasul 1:</b> Descarcă <b><a target="_blank" href="${wpLink('/wp-content/Transfer-portofoliu/Declaratie_de_transfer_instrumente.pdf')}" class="color-primary cursor-pointer">declarația de transfer</a></b> și completează toate câmpurile. Declarația trebuie semnată și încărcată împreună cu dovada costurilor de achiziție (dacă este cazul).`,
        second_step: '<b class="black">Pasul 2:</b> Investimental va realiza transferul instrumentelor financiare. Vei primi o notificare pe email imediat ce instrumentele tale sunt încărcate în platforma de tranzacționare Arena XT.',
        observation_pj: '<b class="black">Observație:</b> Dacă ai nevoie de eliberarea unui extras de cont de la Depozitarul Central (nu știi' +
          ' câte instrumente ai sau transferul implică coduri confidențiale), descarcă <b><a class="color-primary cursor-pointer" ' +
          'href="https://www.investimental.ro/wp-content/Cerere-eliberare/Cerere-eliberare-extrase-PJ.pdf" target="_blank">' +
          'cererea aceasta</a></b>, completeaz-o și încarc-o mai jos. Eliberarea extrasului poate să implice costurile Depozitarului ' +
          'Central.',
        observation_pf: '<b class="black">Observație:</b> Dacă ai nevoie de eliberarea unui extras de cont de la Depozitarul Central (nu știi' +
          ' câte instrumente ai sau transferul implică coduri confidențiale), descarcă <b><a class="color-primary cursor-pointer" ' +
          'href="https://www.investimental.ro/wp-content/Cerere-eliberare/Cerere-eliberare-extrase-PF.pdf" target="_blank">' +
          'cererea aceasta</a></b>, completeaz-o și încarc-o mai jos. Eliberarea extrasului poate să implice costurile Depozitarului ' +
          'Central.'
      }
    },
    from: {
      title: '(Ne întristează dorința ta 🙁, așteptăm feed-back-ul tău despre experiența cu noi)',
      first_step: `<b class="black">Pasul 1:</b> Descarcă <a target="_blank" class="color-primary cursor-pointer font-bold" href="${wpLink('/wp-content/Transfer-portofoliu/Cerere_transfer_instrumente.pdf')}" download>cererea de transfer</a> și completează toate câmpurile. Cererea trebuie semnată și încărcată.`,
      second_step: `<b class="black">Pasul 2:</b> Investimental îți va trimite extrasul de portofoliu și va aștepta să fie contactată de brokerul la care vrei să-ți transferi portofoliul. Vei primi o notificare pe email imediat ce instrumentele tale sunt transferate.`,
      third_step: `<b class="black">Pasul 3:</b> Noi îți trimitem Extrasul de portofoliu și așteptăm vești de la brokerul unde vrei să transferi portofoliul pentru a efectua operațiunile. Vei primi notificare pe email în momentul când instrumentele au plecat de la noi.`
    }
  },
  fiscal_obligations: {
    date: 'Date',
    price: 'Price',
    tax: 'Tax %/abs',
    anaf_tax: 'Anaf tax ron',
    tax_ron: 'bvb tax ron',
    tax_eur: 'bvb tax eu',
    tax_usd: 'global tax usd',
    total_tax: 'Total tax',
    withheld_tax: 'Withheld',
    rest_of_payment: 'Rest of payment',
    collapse: 'See collapsed table',
    expand: 'See expanded table',
    pay_taxes: 'Pay taxes',
    tax_payment_failed: 'The tax payment could not be made. Please contact us!'
  },
  account_transfers: {
    new_transfer: 'New transfer',
    new_deposit: 'New deposit',
    new_withdrawal: 'New withdrawal',
    transfer_details: 'Transfer details',
    from: 'From',
    to: 'To',
    choose_account: 'Choose transactional account',
    sum: 'Sum',
    currency: 'Currency',
    validation_account: 'Please choose an account',
    validation_sum: 'Please enter the sum',
    validation_sum_greater_than_zero: 'The sum must be greater than 0',
    validation_sum_NaN: 'The sum must be a number',
    validation_insufficient_funds: 'We are sorry, but we did not find enough funds to make this transfer',
    message_success: 'Transfer successful!',
    status: {
      pending: 'Pending',
      charged: 'Charged',
      canceled: 'Canceled'
    },
    status_placeholder: 'All',
    available_sum: 'Available sum',
    confirm_transfer: 'Are you sure you want to make this transfer?',
    account_transfers_p1: 'Fill in your trading account from which you want to make the transfer, the amount you want to transfer and' +
      ' the currency, and we will perform the currency exchange operation in the banking platform.',
    account_transfers_p2: 'The money will be transferred between your accounts opened at Investimental ' +
      '<b>after receiving confirmation from the bank.</b>',
    exchange_rate: 'Rate',
    amount_owed: 'Amount owed',
    amount_withdrawn: 'Amount withdrawn'
  },
  portfolio: {
    symbol: 'Symbol',
    quantity: 'Quantity',
    medium_cost: 'Medium cost',
    market_price: 'Market price',
    variation: 'Variation',
    evaluation: 'Evaluation',
    total: 'TOTAL',
    total_general: 'TOTAL ESTIMATED (la curs BNR)',
    my_portfolio: 'My portfolio',
    currency: 'Currency',
    yield: 'yield',
    consolidated_portfolio: 'Consolidated portfolio RON (estimated value)',
    select_portfolio: 'Select portfolio',
    main_account: 'Main portfolio',
    account: 'Portfolio',
    time_periods: {
      one_day: '1 D',
      one_week: '1 W',
      one_month: '1 M',
      three_months: '3 M',
      one_year: '1 Y',
      max: 'MAX'
    },
    free: 'free',
    corporate_events: {
      issuer: 'Issuer',
      event_type: 'Event type',
      cum_date: 'Cum date',
      ex_date: 'Ex date',
      record_date: 'Record date',
      pay_date: 'Pay date',
      amount: 'Amount',
      remarks: 'Remarks',
      dividend_cash: 'Dividend',
      mcs_gratuite: 'Share capital increase with free shares',
      cupon: 'Coupon',
      increase_factor: 'Allocation index'
    }
  },
  report: {
    pending: 'Processing',
    cancelled: 'Cancelled',
    deposit_in: 'Deposit in',
    withdrawal_from: 'Withdrawal from',
    no_reports: 'There are no transactions to display. Go to the deposit section to make your first deposit.',
    show_more: 'Show more',
    dividend: 'Dividend',
    voucher: 'RECOMMENDATION BONUS',
    withdrawal_tax_from: 'Withdrawal tax PIEs from'
  },
  multi_account: {
    add_new_account: 'Add new account',
    account_type: 'Account type',
    account_currency: 'Account currency',
    account_name: 'Account label',
    add: 'Add',
    validation_account_type: 'Please choose an account type',
    validation_account_currency: 'Please choose an account currency',
    validation_alias: 'Please enter an account label',
    validation_alias_length: 'The account alias must have maximum 20 characters',
    success: 'Account added successfully!',
    alias_warning: 'ATTENTION! The alias cannot be edited later.',
    not_approved: 'Your account is not approved yet. You will be able to add secondary accounts only after approval.',
    not_approved_pies: 'Your account is not approved yet. You will be able to add PIEs accounts only after approval.',
    table: {
      id: 'No.',
      market: 'Market',
      alias: 'Alias',
      currency: 'Currency',
      account: 'Account'
    }
  },
  auto_invest: {
    pies: 'PIES',
    deposit_pie: 'Deposit',
    edit_pie: 'Edit',
    delete_pie: 'Delete',
    sell_pie: 'Sell',
    save_pie: 'Save',
    cancel_pie: 'Cancel changes',
    rebalance_pie: 'Rebalance',
    buy_pie: 'Buy',
    withdraw_pie: 'Withdraw',
    min_sum: 'Minimum amount',
    max_sum: 'Maximum amount',
    completed_orders: 'Processed orders',
    symbol: 'Symbol',
    sum: 'Sum',
    commission: 'Commission',
    value: 'Val. order',
    side: 'Side',
    sell_side: 'SELL',
    name_pie_max_length: 'The name must have maximum 20 characters',
    table: {
      current_value: 'Current value',
      net_deposits: 'Net deposits',
      deposits: 'Deposits',
      annual_medium_yield: 'Annual medium yield',
      see_pie: 'See'
    },
    validations: {
      min_sum_for_rebalance: 'Sum could not be less than ',
      no_orders_for_rebalance: 'There are no orders to rebalance'
    },
    name_pie: 'Name PIE',
    add_pie: 'New PIE',
    go_back_to_pies: 'Go back to PIES',
    close_instruments: 'Close instruments',
    pie: {
      initiation_date: 'Initiation date',
      invested_value: 'Invested value',
      cash_value: 'Cash',
      total_value: 'Total value',
      refresh_info: 'Have you performed operations on this PIE and want to update the information here? Sync the information displayed using this button.',
      refresh_info_short: 'Sync the information displayed using this button.',
      table: {
        instrument: 'Instrument',
        target_current_percentage: {
          target: '% target',
          percentage: '% current'
        },
        value: 'Value',
        difference: 'Difference',
        profit_profit_abs: {
          profit: 'Profit %',
          profit_abs: 'Profit abs. '
        }
      },
      sum_with_two_decimals: 'The sum must have maximum 2 decimals'
    },
    instruments: {
      actions: 'Actions',
      search: 'Search',
      selected: 'Selected',
      select_all: 'Select all',
      top_ten: 'Top 10',
      industries_source: 'Source: Morningstar',
      table: {
        instrument: 'Instrument',
        symbol: 'Symbol',
        issuer: 'Issuer',
        type: 'Instrument type',
        action: 'Action',
        value: 'Value'
      }
    },
    targets: {
      title: 'Personalize your PIE',
      distribute_equally: 'Distribute equally',
      add_instruments: 'Add instruments',
      target_weights: 'Target weights',
      remove: 'Remove',
      total: 'Total',
      validation_min_percentage: 'The percentage value of an instrument cannot be less than 1%'
    },
    transfers: {
      cancel: 'Cancel',
      deposit: 'Deposit',
      withdraw: 'Withdraw',
      sell: 'Sell',
      place_sum: 'Place sum',
      choose_account: 'Choose account',
      max_sum_error: 'The amount cannot be greater than the maximum amount',
      min_sum_error: 'The amount cannot be less than the minimum amount',
      instruments_validation: 'You cannot place orders less than 10 USD, or you cannot have less than 10 USD remaining on an instrument',
      instruments_total_validation: 'The total must be 100%',
      sell_instruments_total_validation: 'The total is {sumOfPercentages}%, it must be 100%',
      percentages_info: 'The percentage value of an instrument is calculated based on the value entered in the previous screen',
      from_account: 'From account',
      withdraw_info: 'If you have made sales, the amounts are blocked until settlement (1 business day)'
    },
    rebalance: {
      title: 'Confirm orders',
      message_1: 'The action requested by you involves the launching of Market - Day orders. The $3 rebalancing fee will be withheld in accordance with the Agreement.',
      message_2: 'If you want to make changes, you can do it in the previous screen.',
      message_3: 'Do you agree to place the orders now?',
      account_code: 'Account code',
      success_message: 'Orders successfully executed!',
      orders_restrictions: 'Rebalancing does not generate orders when the difference between current and target percentages is below 1% and for buy orders below $10.'
    },
    sell: {
      sell_sum: 'Sum for sell',
      message_1: 'The action requested by you involves the launching of Market - Day orders. The $3 sell fee will be withheld in accordance with the Agreement.'
    },
    pies_info: {
      first_info: 'Transactions for PIEs will be conducted from this page.',
      second_info: `If you make transactions through Investment Global on the PIEs account, each transaction will` +
        ` be considered rebalancing and will be charged according to <a target="_blank" class="color-primary underline cursor-pointer"` +
        ` href="${wpLink('/grila-comisioane-investimental/')}">the commission grid.</a>`,
      limits_info: 'You can create up to <span class="color-primary font-bold">{maxNumberPies} PIEs</span>, each with a' +
        ' <span class="color-primary font-bold">minimum of {minInstrumentsPies}</span> and a' +
        ' <span class="color-primary font-bold">maximum of {maxInstrumentsPies}</span> instruments.'
    }
  }
}
