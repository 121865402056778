<template>
  <div class="card" :class="[cardClasses]">
    <div v-if="hasSlot('top')" class="top">
      <slot name="top">
      </slot>
    </div>

    <div v-if="hasSlot('title')" class="title">
      <slot name="title"></slot>
    </div>

    <div class="body w-full" :class="{'h-full' : heightFull}">
      <slot v-if="hasSlot('body')" name="body"></slot>
      <slot></slot>
    </div>
    <div v-if="hasSlot('footer')" class="footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Card',
  props: {
    cardClasses: {
      type: String,
      default: ''
    },
    heightFull: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    hasSlot (slotName) {
      return !!this.$slots[slotName]
    }
  }
}
</script>

<style scoped lang="scss">

</style>
