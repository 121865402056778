<template>
  <div>
    <vue-title :title="$t('general.meta_titles.verify_bank_account')"></vue-title>

    <div class="rounded-lg bg-primary m-auto w-fit">
      <StepBar :current-step="selectedTabId" :steps="tabs"></StepBar>
    </div>
    <Card :card-classes="'margin-bottom-medium mobile-border-none'">
      <template v-slot:top>
        <img src="~@/assets/img/icons/yellow_user_verified.svg">
      </template>

      <template v-slot:title>
        <div>{{ $t('onboarding.general.identity_verification') }}</div>
      </template>

      <template v-slot:body>
        <div>
          <div class="form-group">
            <InputText
              :id="'iban'"
              v-model="iban"
              :label="$t('onboarding.verify_bank_account.iban')"
              :type-input="'text'"
            ></InputText>
          </div>
          <Message v-if="validationIban" :message="validationIban" :type="'error'" class="mb-5"/>

          <SelectDropDown
            v-model="selectedCurrency"
            class="mt-3"
            :id="'currencies'"
            :label="$t('deposit_withdrawal.bank_accounts.currency')"
            :options="currencies"
            required
          />
          <Message v-if="validationCurrency" :message="validationCurrency" :type="'error'" class="mb-5"/>

          <div class="mb-6 mt-3">
            <div class="input-text">
              <label class="form-label inline-block" for="documentIban">{{ $t('onboarding.verify_bank_account.bank_statement') }}</label>
              <input id="documentIban" class="input-primary w-full" name="documentIban" type="file"
                     @change="handleFileUpload($event)">
            </div>
          </div>

          <div v-if="isRevolutIban" class="mb-6">
            <Checkbox
              class="mt-5"
              :id="'revolutCheck'"
              v-model="isRevolutIbanChecked"
              :label="$t('deposit_withdrawal.bank_accounts.is_revolut')"/>
          </div>

          <Message v-if="validationIbanDocument" :message="validationIbanDocument" :type="'error'" class="mb-5"/>
          <Message v-if="validationTypeDocument" :message="validationTypeDocument" :type="'error'" class="mb-5"/>

          <div class="flex justify-between">
            <Button
              @call="resetStep()"
              :text="$t('general.general.back')"
              :type="'btn btn-secondary padding-medium end-position no-wrap'"
              :icon="'icons/arrow_back_green'"
              :icon-position="'left'"
            />
            <Button
              :loading="loadingData.continue"
              @call="send"
              :text="$t('general.general.continue')"
              :type="'btn btn-primary padding-big end-position'"/>
          </div>

          <pulse-loader v-if="!loaded" class="mt-5 w-fit"/>
        </div>
      </template>
    </Card>
  </div>
</template>

<script>
import StepBar from '../layout/StepBar'
import Message from '../subcomponents/Message'
import Button from '../subcomponents/Button'
import bankAccounts from '../../services/deposit-withdrawal/bankAccountsService'
import {ONBOARDING_REVIEW_OCR_DATA, ONBOARDING_SELECT_RESIDENCE} from '../../router'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import userService from '../../services/auth/userService'
import Card from '../subcomponents/Card'
import InputText from '../subcomponents/InputText'
import questionnaireService from '../../services/onboarding/questionnaireService'
import SelectDropDown from '../subcomponents/SelectDropDown'
import Checkbox from '../subcomponents/Checkbox'
export default {
  name: 'BankAccount',
  components: {InputText, Card, Button, StepBar, Message, PulseLoader, SelectDropDown, Checkbox},
  data () {
    return {
      loadingData: {
        continue: false
      },
      bankAccountsData: null,
      tabs: [
        {
          id: 1,
          label: this.$i18n.t('onboarding.general.create_account')
        },
        {
          id: 2,
          label: this.$i18n.t('onboarding.general.identity_verification')
        },
        {
          id: 3,
          label: this.$i18n.t('onboarding.general.financial_data')
        }
      ],
      selectedTabId: 2,
      validationIban: '',
      validationIbanDocument: '',
      validationTypeDocument: '',
      validationCurrency: '',
      iban: '',
      ibanDocument: null,
      loaded: true,
      currencies: ['RON', 'EUR', 'USD'],
      selectedCurrency: '',
      isRevolutIban: 0,
      isRevolutIbanChecked: false,
      allowedDocumentTypes: ['pdf', 'doc', 'docx', 'jpg', 'jpeg', 'png']
    }
  },
  async created () {
    let bankAccountsData = await bankAccounts.getBankAccounts()
    if (bankAccountsData.status === 200) {
      this.bankAccountsData = bankAccountsData.data.data.bankAccounts
    }
    await this.setData()
  },
  methods: {
    async setData () {
      if (this.bankAccountsData[0]) {
        this.iban = this.bankAccountsData[0].iban
      }
    },
    validate () {
      let valid = true
      if (!this.iban) {
        this.validationIban = this.$t('general.validations.iban_validation')

        valid = false
      }
      if (!this.selectedCurrency) {
        this.validationCurrency = this.$t('general.validations.currency_validation')

        valid = false
      }
      if (!this.ibanDocument) {
        this.validationIbanDocument = this.$t('general.validations.bank_statement_validation')

        valid = false
      }
      if (this.ibanDocument && !this.validFileType(this.ibanDocument.type, this.ibanDocument.name)) {
        this.validationTypeDocument = this.$t('general.validations.bank_statement_validation_type')

        valid = false
      }

      return valid
    },
    validFileType (mimeType, name) {
      const mime = require('mime-types')
      var ext = name.substr(name.lastIndexOf('.') + 1)

      return !!(this.allowedDocumentTypes.includes(mime.extension(mimeType)) || this.allowedDocumentTypes.includes(ext))
    },
    handleFileUpload (event) {
      this.ibanDocument = event.target.files[0]
    },
    async send () {
      this.loadingData.continue = true
      try {
        if (!this.validate()) {
          return
        }

        this.loaded = false

        let payload = {
          iban: this.iban,
          document: this.ibanDocument,
          currency: this.selectedCurrency,
          isRevolutIban: this.isRevolutIbanChecked ? 1 : 0
        }

        if (this.bankAccountsData[0]) {
          Object.assign(payload, {update_onboarding: JSON.stringify(this.bankAccountsData[0])})
        }

        let bankAccountsData = await bankAccounts.saveBankAccount(payload).catch((result) => {
          this.loaded = true
          if (result.response.data.data && result.response.data.data.invalidIban) {
            this.validationIban = this.$t(result.response.data.message)
            this.ibanDocument = null
          }
        })
        this.loaded = true

        if (!bankAccountsData) return

        if (bankAccountsData.status === 200) {
          let user = await userService.getUserData()

          await this.$router.push({
            name: user.data.data.user.has_access_in_portal
              ? 'homepage'
              : ONBOARDING_SELECT_RESIDENCE
          })
        }
        return true
      } finally {
        this.loadingData.continue = false
      }
    },
    async resetStep () {
      await this.$confirm(
        this.$t('onboarding.reset_steps.reset_identity'),
        '',
        'warning',
        { cancelButtonText: this.$t('general.general.cancel') }
      ).then(async () => {
        let reset = await questionnaireService.resetStep({reset_reviewed_ocr_data: true})
        if (reset.status === 200) {
          await this.$router.push({name: ONBOARDING_REVIEW_OCR_DATA})
        }
        return reset.status
      })
    }
  },
  watch: {
    iban (newIbanValue) {
      if (newIbanValue) {
        this.validationIban = ''
      }

      if (newIbanValue) {
        this.iban = this.iban.replace(/\s/g, '')
        this.isRevolutIban = newIbanValue.slice(4, 8) === 'BREL'
      } else {
        this.isRevolutIban = false
      }
    },
    ibanDocument (nVal) {
      if (nVal) {
        this.validationIbanDocument = ''
        this.validationTypeDocument = ''
      }
    },
    selectedCurrency (newCurrency) {
      if (newCurrency) {
        this.validationCurrency = ''
      }
    }
  }
}
</script>

<style scoped>

</style>
