<template>
  <div
    class="modal-pop-up"
    v-if="modalOpened">
    <Card class="modal-pop-up-card"
          :class="{'modal-fit-content':modalFitContent, 'small-padding': smallPadding,
           'w-3/6':biggerCard, 'modal-height-content': modalHeightContent, 'smallest-padding': smallestPadding}"
    >
      <template v-slot:body>
        <div class="header" :class="{'centered-title' : centeredTitle}">
          <div>{{ modalTitle }}</div>

          <div class="close-modal cursor-pointer" @click="closeModal" v-if="closableModal">
            <img :src="require('@/assets/img/icons/close.svg')">
          </div>
        </div>

        <slot></slot>
      </template>
    </Card>
  </div>
</template>

<script>
import Card from './Card.vue'

export default {
  name: 'PopUpModal',
  components: {Card},
  props: {
    modalOpened: {
      type: Boolean,
      default: false
    },
    modalTitle: {
      type: String,
      default: ''
    },
    modalFitContent: {
      type: Boolean,
      default: false
    },
    smallPadding: {
      type: Boolean,
      default: false
    },
    centeredTitle: {
      type: Boolean,
      default: true
    },
    biggerCard: {
      type: Boolean,
      default: false
    },
    modalHeightContent: {
      type: Boolean,
      default: false
    },
    smallestPadding: {
      type: Boolean,
      default: false
    },
    closableModal: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    closeModal () {
      this.$emit('closeModal')
    }
  }
}
</script>
