<template>
  <div class="resources">
    <Card class="w-full h-full" :card-classes="cardClasses + ' mobile-card-resources' + (isMobile ? ' !p-11' : '')">
      <template v-slot:title>
        <div class="text-lg mb-4">{{ $t('useful_links.general.useful_links') }}</div>
      </template>

      <template v-slot:body>
        <div v-for="link in links" :key="link.value" class="flex mt-2.5 items-start">
          <img src="@/assets/img/icons/black_right_arrow_filled.svg" alt="arrow-right" class="mr-4 mt-1.5">

          <a target="_blank" :href="wpLink(link.value)">
            {{ link.label }}
          </a>
        </div>
      </template>
    </Card>
  </div>
</template>

<script>

import Card from './Card.vue'
import {mapGetters} from 'vuex'

export default {
  name: 'Resources',
  props: {
    type: {
      type: String,
      default: ''
    },
    links: {
      type: Array,
      required: true
    },
    cardClasses: {
      type: String,
      default: ''
    }
  },
  components: {
    Card
  },
  computed: {
    ...mapGetters({
      screenDevice: 'layout/screenDevice'
    }),
    isMobile () {
      return this.screenDevice === this.$screenDeviceConstants.MOBILE
    }
  }
}
</script>
