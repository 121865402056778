import ApiService from '../apiService'
import snsWebSdk from '@sumsub/websdk'
import routes, {ONBOARDING_REVIEW_OCR_DATA} from '../../router'

import user from './userService'
import sumsub from '../../locales/sumsub'
import i18n from '../../i18n'

var applicantId = ''

class SumsubService {
  getNewAccessSumsub (userId = null) {
    if (!userId) {
      userId = Date.now().toString(36) + Math.random().toString(36).substr(2)
    }

    return ApiService.withAuth().get('/api/sumsub/token', {
      params: {
        userId
      }
    }).then((response) => {
      return response.data.data
    })
  }

  async getNewAccessToken (userId = null) {
    let sumSumData = await this.getNewAccessSumsub(userId)

    return sumSumData.token
  }

  async finishProcess () {
    ApiService.withAuth().post('/api/sumsub/identity/verify', {
      applicantId
    }).then(async (result) => {
      if (result.status === 200) {
        let routeOnboardingReviewOcrData = routes.getRoutes().find((router) => router.name === ONBOARDING_REVIEW_OCR_DATA)

        window.location.href = routeOnboardingReviewOcrData.path
      }
    }).catch((err) => {
      console.log(err)
    })
  }

  async resetProcess () {
    await ApiService.withAuth().put('/api/user', {reset_domicile: true, applicantId})
      .then(async (result) => {
        return result.status
      }).catch((err) => {
        console.log(err)
        return err
      })
  }

  launchWebSdk (userId, accessToken, country = null) {
    var customI18n = {}

    // Check if we have the specific lang with custom additional translations
    if (sumsub[i18n.locale]) {
      customI18n = sumsub[i18n.locale].idDocument
    }

    let snsWebSdkInstance = snsWebSdk.init(
      accessToken,
      () => this.getNewAccessToken(userId)
    )
      .withConf({
        lang: process.env.VUE_APP_I18N_LOCALE,
        country: country,
        questionnaireDef: {
          id: 'KYB_1',
          title: 'Test'
        },
        i18n: customI18n || null,
        onMessage: (type, payload) => {
          console.log('WebSDK onMessage', type, payload)
        },
        uiConf: {
          customCssStr: ':root {\n  --black: #000000;\n   --grey: #F5F5F5;\n  --grey-darker: #B2B2B2;\n  --border-color: #DBDBDB;\n}\n\np {\n  color: var(--black);\n  font-size: 16px;\n  line-height: 24px;\n}\n\nsection {\n  margin: 40px auto;\n}\n\ninput {\n  color: var(--black);\n  font-weight: 600;\n  outline: none;\n}\n\nsection.content {\n  background-color: var(--grey);\n  color: var(--black);\n  padding: 40px 40px 16px;\n  box-shadow: none;\n  border-radius: 6px;\n}\n\nbutton.submit,\nbutton.back {\n  text-transform: capitalize;\n  border-radius: 6px;\n  height: 48px;\n  padding: 0 30px;\n  font-size: 16px;\n  background-image: none !important;\n  transform: none !important;\n  box-shadow: none !important;\n  transition: all 0.2s linear;\n}\n\nbutton.submit {\n  min-width: 132px;\n  background: none;\n  background-color: var(--black);\n}\n\n.round-icon {\n  background-color: var(--black) !important;\n  background-image: none !important;\n}\n\n',
          customCss: `${window.location.origin}/static/sumsub-extra.min.css`
        },
        onError: (error) => {
          console.error('WebSDK onError', error)
        }
      })
      // eslint-disable-next-line standard/object-curly-even-spacing
      .withOptions({addViewportTag: false, adaptIframeHeight: true})
      .on('idCheck.applicantReviewComplete', (payload) => {
        setTimeout(() => {
          this.finishProcess()
        }, 2000)
      })
      .on('idCheck.onApplicantResubmitted', (payload) => {
        setTimeout(() => {
          this.finishProcess()
        }, 2000)
      })
      .on('idCheck.onActionSubmitted', (payload) => {
        setTimeout(() => {
          this.finishProcess()
        }, 2000)
      })
      .on('idCheck.actionCompleted', (payload) => {
        setTimeout(() => {
          this.finishProcess()
        }, 2000)
      })
      .on('idCheck.onApplicantLoaded', (payload) => {
        applicantId = payload.applicantId
        setTimeout(() => {
          this.finishProcess()
        }, 2000)
      })
      .on('idCheck.applicantStatus', (payload) => {
        applicantId = payload.applicantId
        setTimeout(() => {
          this.finishProcess()
        }, 2000)
      })
      .onMessage((type, payload) => {
        console.log('onMessage', type, payload)
      })
      .build()
    snsWebSdkInstance.launch('#sumsub-websdk-container')
  }
}

export default new SumsubService()
