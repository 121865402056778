import ApiService from '../services/apiService'
import AccountTransfersService from '@/services/deposit-withdrawal/accountTransfersService'

export default {
  data () {
    return {
      loading: true,
      consolidatedAssets: {},
      totalAssets: {},
      ronAssets: {},
      eurAssets: {},
      usdAssets: {},
      multiAccounts: [
        {
          alias: '',
          label: this.$i18n.t('deposit_withdrawal.portfolio.main_account'),
          name: this.$i18n.t('deposit_withdrawal.portfolio.main_account'),
          aliases: {}
        }
      ],
      mainAccounts: [],
      selectedAccount: {
        alias: '',
        label: this.$i18n.t('deposit_withdrawal.portfolio.main_account'),
        name: this.$i18n.t('deposit_withdrawal.portfolio.main_account'),
        aliases: {}
      }
    }
  },
  methods: {
    async getPortfolio (alias) {
      await ApiService.withAuth()
        .get('/api/user/portofolio', alias !== '' ? { params: { secondaryAccount: alias } } : '')
        .then(response => {
          this.totalAssets = response.data.data
        })
    },
    async getMultiAccounts () {
      await AccountTransfersService.getAccounts()
        .then((response) => {
          if (response.data.data) {
            response.data.data.forEach(account => {
              if (account.is_main === 0) {
                account.name = this.$i18n.t('deposit_withdrawal.portfolio.account') + ' ' + account.alias
                account.aliases = {}
                if (!this.multiAccounts.filter(acc => acc.alias === account.alias).length) {
                  account.aliases[account.currency] = (account.source === 'arenaxt' ? 'BVB' : 'GLOBAL') + ' ' +
                    account.alias + ' ' + account.currency
                  this.multiAccounts.push(account)
                } else {
                  this.multiAccounts.forEach(acc => {
                    if (acc.alias === account.alias) {
                      acc.aliases[account.currency] = (account.source === 'arenaxt' ? 'BVB' : 'GLOBAL') + ' ' +
                        account.alias + ' ' + account.currency
                    }
                  })
                }
              } else if (account.is_main === 1) {
                account.name = (account.source === 'arenaxt' ? 'BVB' : 'GLOBAL') + ' ' +
                  account.alias + ' ' + account.currency
                this.mainAccounts.push(account)
              }
            })
          }
        })
    },

    sortSecurityAssets (assets) {
      let assetsSorted = assets.sort((asset1, asset2) => {
        if ((asset1.type === 'security' || asset1.type === 'future-security') && (asset2.type === 'security' || asset2.type === 'future-security')) {
          if (asset1.symbol < asset2.symbol) {
            return -1
          }
          if (asset1.symbol > asset2.symbol) {
            return 1
          }
          return 0
        }
      })

      return assetsSorted.sort((asset1, asset2) => {
        if ((asset1.type === 'security' || asset1.type === 'future-security') && (asset2.type === 'security' || asset2.type === 'future-security')) {
          if (asset1.symbol === asset2.symbol) {
            if (asset1.type === asset2.type) {
              return 0
            } else if (asset1.type === 'security') {
              return -1
            } else if (asset2.type === 'security') {
              return 1
            }
          }
        }
      })
    },

    alterDataForTables () {
      let today = new Date()
      today.setHours(0, 0, 0, 0)

      this.ronAssets['assets'] = this.totalAssets.assets.filter(asset => asset.currency === 'RON')
      this.eurAssets['assets'] = this.totalAssets.assets.filter(asset => asset.currency === 'EUR')
      this.usdAssets['assets'] = this.totalAssets.assets.filter(asset => asset.currency === 'USD')

      // filter for future cash/securities
      this.ronAssets.assets = this.ronAssets.assets.filter(asset =>
        (
          (asset.type !== 'future-cash' && asset.type !== 'future-security') ||
          (
            (asset.type === 'future-cash' || asset.type === 'future-security') &&
            (!asset.corporate_event.ex_date || today >= this.getDateTimeNull(asset.corporate_event.ex_date)) &&
            (!asset.corporate_event.pay_date || today <= this.getDateTimeNull(asset.corporate_event.pay_date))
          )
        )
      )

      this.eurAssets['assets'] = this.eurAssets.assets.filter(asset =>
        (
          (asset.type !== 'future-cash' && asset.type !== 'future-security') ||
          (
            (asset.type === 'future-cash' || asset.type === 'future-security') &&
            (!asset.corporate_event.ex_date || today >= this.getDateTimeNull(asset.corporate_event.ex_date)) &&
            (!asset.corporate_event.pay_date || today <= this.getDateTimeNull(asset.corporate_event.pay_date))
          )
        )
      )

      this.usdAssets['assets'] = this.usdAssets.assets.filter(asset =>
        (
          (asset.type !== 'future-cash' && asset.type !== 'future-security') ||
          (
            (asset.type === 'future-cash' || asset.type === 'future-security') &&
            (!asset.corporate_event.ex_date || today >= this.getDateTimeNull(asset.corporate_event.ex_date)) &&
            (!asset.corporate_event.pay_date || today <= this.getDateTimeNull(asset.corporate_event.pay_date))
          )
        )
      )

      this.ronAssets['total'] = this.totalAssets.totals.ronAssetsEvaluation
      this.eurAssets['total'] = this.totalAssets.totals.eurAssetsEvaluation
      this.usdAssets['total'] = this.totalAssets.totals.usdAssetsEvaluation

      this.sortSecurityAssets(this.ronAssets['assets'])
      this.sortSecurityAssets(this.eurAssets['assets'])
      this.sortSecurityAssets(this.usdAssets['assets'])

      this.loading = false
    },

    // LINE CHART
    async filterDataForChart (timePeriod) {
      let today = new Date()
      let dateStart = ''
      let dateEnd = this.formatDate(today)

      if (timePeriod === '1D') {
        dateStart = this.formatDate(today)
      } else if (timePeriod === '1W') {
        dateStart = this.formatDate(this.getOneWeekBefore(today))
      } else if (timePeriod === '1M') {
        dateStart = this.formatDate(this.getOneMonthBefore(today))
      } else if (timePeriod === '3M') {
        dateStart = this.formatDate(this.getThreeMonthsBefore(today))
      } else if (timePeriod === '1Y') {
        dateStart = this.formatDate(this.getOneYearBefore(today))
      }

      let payload = {
        timePeriod
      }

      if (timePeriod !== 'MAX') {
        payload.dateStart = dateStart
        payload.dateEnd = dateEnd
      }

      if (this.selectedAccount.alias !== '') {
        payload.secondaryAccount = this.selectedAccount.alias
      }

      await ApiService.withAuth().get('/api/user/portofolio/consolidated', { params: payload }).then(response => {
        if (response.data.data[0].length === 0) {
          this.consolidatedAssets = {}
        } else {
          this.consolidatedAssets = response.data.data[0]
        }
      })
    },

    // GET DATE FOR FILTERS LINE CHART
    getOneWeekBefore (today) {
      return today.setDate(today.getDate() - 7)
    },
    getOneMonthBefore (today) {
      return today.setMonth(today.getMonth() - 1)
    },
    getThreeMonthsBefore (today) {
      return today.setMonth(today.getMonth() - 3)
    },
    getOneYearBefore (today) {
      return today.setFullYear(today.getFullYear() - 1)
    },
    formatDate (date) {
      let d = new Date(date)
      return d.getFullYear() + '-' + ('0' + (d.getMonth() + 1)).slice(-2) + '-' + ('0' + (d.getDate())).slice(-2)
    },

    getDateTimeNull (date) {
      let d = new Date(date)

      return d.setHours(0, 0, 0, 0)
    },

    // DOUGHNUT CHARTS
    getDataForCurrencyChart () {
      let eurPercentage = (this.totalAssets.totals.eurAssetsEvaluationInRon / this.totalAssets.totals.totalAssetsEvaluationInRon) * 100
      let ronPercentage = (this.totalAssets.totals.ronAssetsEvaluation / this.totalAssets.totals.totalAssetsEvaluationInRon) * 100
      let usdPercentage = (this.totalAssets.totals.usdAssetsEvaluationInRon / this.totalAssets.totals.totalAssetsEvaluationInRon) * 100
      if (isNaN(eurPercentage)) eurPercentage = 0
      if (isNaN(ronPercentage)) ronPercentage = 0
      if (isNaN(usdPercentage)) usdPercentage = 0

      return {
        labels: ['EUR total', 'RON total', 'USD total'],
        datasets: [
          {
            backgroundColor: ['#46A4E4', '#3AD5AF', '#F5D150'],
            data: [eurPercentage, ronPercentage, usdPercentage]
          }
        ]
      }
    },
    getDataForVariationChart () {
      let profitAbove20 = this.totalAssets.assets.filter((asset) => {
        return parseFloat(asset.variation) >= 20.00
      }).length

      let profitUnder20 = this.totalAssets.assets.filter((asset) => {
        return parseFloat(asset.variation) < 20.00 && parseFloat(asset.variation) > 0.00
      }).length

      let lossUnder20 = this.totalAssets.assets.filter((asset) => {
        return parseFloat(asset.variation) >= -20.00 && parseFloat(asset.variation) < 0.00
      }).length

      let lossAbove20 = this.totalAssets.assets.filter((asset) => {
        return parseFloat(asset.variation) < -20.00
      }).length

      let totalAssetsLength = this.totalAssets.assets.filter((asset) => {
        return asset.type === 'security' && asset.variation !== '-'
      }).length

      let chartData = [
        isNaN(profitAbove20 * 100 / totalAssetsLength) ? 0 : profitAbove20 * 100 / totalAssetsLength,
        isNaN(profitUnder20 * 100 / totalAssetsLength) ? 0 : profitUnder20 * 100 / totalAssetsLength,
        isNaN(lossAbove20 * 100 / totalAssetsLength) ? 0 : lossAbove20 * 100 / totalAssetsLength,
        isNaN(lossUnder20 * 100 / totalAssetsLength) ? 0 : lossUnder20 * 100 / totalAssetsLength
      ]

      return {
        labels: ['Profit > 20%', 'Profit < 20%', 'Loss > 20%', 'Loss < 20%'],
        datasets: [
          {
            data: chartData,
            backgroundColor: ['#46A4E4', '#3AD5AF', '#F5D150', '#CEA6DE', '#fb6b36', '#EE27FC', '#1A89D4', '#D2F3E8']
          }
        ]
      }
    },
    getDataForSpecificCurrencyChart (currency) {
      let arraySymbols = []
      let data = []
      let assets = {}

      if (currency === 'RON') {
        assets = this.ronAssets
      } else if (currency === 'EUR') {
        assets = this.eurAssets
      } else if (currency === 'USD') {
        assets = this.usdAssets
      }

      if (assets.assets.length) {
        let cash = assets.assets.find((asset) => {
          return asset.type === 'cash'
        })

        if (assets.assets.length <= 10) {
          assets.assets.forEach((asset) => {
            if (asset.type === 'security') {
              arraySymbols.push(asset.symbol)
            }
          })

          assets.assets.forEach((asset) => {
            if (asset.type === 'security') {
              data.push(parseFloat(asset.evaluation) / parseFloat(assets.total) * 100)
            }
          })
        } else {
          let totalOthers = 0
          let securityAssets = assets.assets.filter((asset) => asset.type === 'security')
          securityAssets.sort((a, b) => (b.evaluation - a.evaluation))

          for (let index = 0; index < 9; index++) {
            if (securityAssets[index].type === 'security') {
              arraySymbols.push(securityAssets[index].symbol)
              data.push(parseFloat(securityAssets[index].evaluation) / parseFloat(assets.total) * 100)
            }
          }
          for (let index = 9; index < securityAssets.length; index++) {
            if (securityAssets[index].type === 'security') {
              totalOthers += parseFloat(securityAssets[index].evaluation) / parseFloat(assets.total) * 100
            }
          }
          arraySymbols.push(this.$i18n.t('general.general.others'))
          data.push(totalOthers)
        }

        if (cash) {
          arraySymbols.push('Cash')
          data.push(parseFloat(cash.evaluation) / parseFloat(assets.total) * 100)
        }

        let sumOfPreviousAssets = 0.00

        for (let index = 0; index < data.length - 1; index++) {
          sumOfPreviousAssets += Math.round(data[index] * 100) / 100
        }

        data[data.length - 1] = 100 - sumOfPreviousAssets
      }

      return {
        labels: arraySymbols,
        datasets: [
          {
            data: data,
            backgroundColor: ['#46A4E4', '#3AD5AF', '#F5D150', '#CEA6DE', '#fb6b36', '#EE27FC', '#1A89D4', '#D2F3E8']
          }
        ]
      }
    }
  },
  watch: {
    selectedAccount: {
      handler: async function () {
        this.loading = true
      },
      deep: true
    }
  }
}
