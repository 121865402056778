export default {
  login: {
    username: 'Adresă de email sau nume de utilizator',
    guidance: 'Intră în contul tău',
    register_question: 'Nu ai cont?',
    username_validation: 'Vă rugăm să introduceți un email sau un nume de utilizator',
    login_validation: 'Parolă sau email/nume de utilizator invalide',
    forgot_password: 'Ai uitat parola?',
    user_rejected: 'Contul tău este inactiv. Pentru mai multe detalii ne poți contacta la suport@investimental.ro'
  },
  general: {
    log_in: 'Log in',
    two_factor_auth: 'Activează autentificarea cu doi factori (2FA)',
    force_reset_password: 'Resetează parola',
    login: 'Intră în cont',
    register: 'Înregistrare',
    reset_password: 'Resetare parolă',
    save: 'Salvează',
    resend_code: 'Retrimite codul'
  },
  recovery_password: {
    title: 'Completează noua parolă',
    success_message: 'Parola a fost schimbată cu succes',
    recovery_instructions: 'Introdu adresa de email și urmează instrucțiunile primite',
    recovery_success: 'Verifică pe email instrucțiunile pentru resetarea parolei.',
    back_to_login: 'Înapoi la login',
    guidance: 'Pentru că siguranța contului și a investițiilor tale sunt vitale vă rugăm să vă setați o parolă noua!'
  },
  reset_password: {
    old_password: 'Parolă veche',
    new_password: 'Parolă nouă',
    repeat_new_password: 'Repetați noua parolă',
    old_password_invalid: 'Parolă veche invalidă!',
    reset_successfully: 'Ai resetat parola cu succes!',
    old_password_validation: 'Te rugăm să introduci parola veche!',
    new_password_validation: 'Te rugăm să introduci o parolă nouă!'
  },
  two_factor_auth: {
    code: 'Codul din aplicatia ta de autentificare',
    recovery_code: 'Codul de recuperare',
    use_recovery_code: 'Folosește codul de recuperare',
    check: 'Verifică',
    save_device: 'Tine minte acest dispozitiv timp de 14 zile',
    guidance: 'Pentru că siguranța contului și a investițiilor tale sunt vitale (dar și pentru că suntem obligați să ți-o cerem) te rugăm să-ți activezi 2FA. Poți folosi varianta SMS sau cea cu aplicație de autentificare (îți recomandăm Google sau Microsoft)',
    enable_2fa: 'Activează 2FA',
    grace_period: 'Îți recomandăm să activezi autentificarea cu doi factori pentru o siguranță mai mare a contului tău. Opțiunea devine obligatorie după {days} zile de la deschiderea contului.',
    download_app: 'Dacă încă nu ai una, o poți descărca de mai jos',
    change2fa: 'Schimbă aplicația 2FA',
    change2fa_q: 'Ești sigur că vrei să dezactivezi temporar funcția 2FA?'
  }
}
