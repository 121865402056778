import {wpLink} from '../../../mixins/WordpressLinks'

export default {
  general: {
    create_account: 'Creare cont',
    identity_verification: 'Verificare identitate',
    reviewed_ocr_data: 'Verificare date personale',
    financial_data: 'Date financiare',
    validate: 'Validează',
    continue: 'Continuă',
    create_legal_account: 'Creare cont',
    legal_person_details: 'Detalii persoană juridică'
  },
  prepare_onboarding: {
    title: 'Bine ai venit la deschiderea de cont!',
    subtitle: 'Pentru o deschidere de cont super rapidă ar fi bine să ai la îndemână:',
    requirement1: 'CI',
    requirement2: 'IBAN-ul tău și un extras de cont sau un alt document sau informație care atestă că ești titularul contului.',
    details_link: 'Dacă ești cetățean străin sau ai domiciliul/rezidența în străinătate, avem aici informațiile care te pot ajuta',
    details1: 'Ca să ne asigurăm că tu ești tu, vei mai avea nevoie și de o cameră video (dacă îți deschizi contul de pe PC te poți muta foarte ușor pe telefon).',
    ending: 'Asta e tot. În câteva minute îți vom ura bun venit în familia Investimental!',
    info: 'Nu uita că dacă întâmpini orice problemă poți accesa chat-ul și un Investimentalist te va ajuta imediat.',
    legal_person: 'Pentru deschideri de cont persoane juridice,',
    click_here: 'click aici'
  },
  email_verification: {
    email_verification_guidance: 'Validați adresa de e-mail',
    code_guidance: 'Vă rugăm să introduceți codul de 4 caractere primit pe adresa de e-mail folosită pentru înregistrare. Nu uita să verifici și casuța de spam/junk.',
    verif_code: 'Cod de verificare',
    resend_code: 'Retrimite cod',
    invalid_code: 'Cod invalid!',
    user_verif_success: 'Utilizator verificat cu succes!',
    code_verif_success: 'Codul a fost trimis cu succes!',
    code_validation: 'Vă rugăm să introduceți codul'
  },
  register: {
    log_in_question: 'Ai deja un cont?',
    company: 'Companie',
    person: 'Persoană',
    not_married: 'Necăsătorit',
    married: 'Căsătorit',
    distance_contract: 'Sunt de acord cu demararea încheierii unui contract la distanță cu Investimental S.A. și cu utilizarea mijloacelor electronice de comunicare la distanță, atât inițial, cât și pe tot parcursul relației de afaceri cu Investimental S.A.',
    investimental_presentation: `Am citit, am înțeles și nu am nicio rezervă cu privire la informațiile din <a target="_blank" href="${wpLink('wp-content/Documente/Document-prezentare.pdf')}" class="color-primary cursor-pointer">Documentul de prezentare al Investimental</a>` +
      ` și <a target="_blank" href="${wpLink('wp-content/Documente/Politica-executare-ordine.pdf')}" class="color-primary cursor-pointer">Politica de executare a ordinelor</a>`,
    gdpr: `Îmi exprim consimțământul expres cu privire la prelucrarea datelor mele cu caracter personal în vederea inițierii deschiderii unui cont de tranzacționare în relație cu Investimental S.A. - <a target="_blank" href="${wpLink('/politica-de-confidentialitate')}" class="color-primary cursor-pointer">Politica de confidențialitate</a>`,
    marketing: 'Sunt de acord să primesc de la Investimental informații despre serviciile oferite, campanii, newsletter, etc.',
    validation_agreement: 'Acest acord este obligatoriu!',
    legal_person_register: 'Ești persoană juridică?'
  },
  select_domicile: {
    permanent_domicile: 'Țară domiciliu',
    select_country: 'Vă rugăm să selectați o țară',
    select_citizenships: 'Vă rugăm să selectați cel puțin o cetățenie',
    complete_citizenships: 'Vă rugăm să completați cetățenia',
    citizenship: 'Cetățenie',
    multiple_citizenship: 'Adaugă cetățenie multiplă'
  },
  verify_bank_account: {
    iban: 'IBAN',
    bank_statement: 'Orice document bancar (care dovedește că ești titlularul IBAN-ului menționat. Exemplu: extras cont bancar, raport tranzacție, scrisoare, informații din aplicația de mobile banking etc.)'
  },
  review_ocr_data: {
    info: 'Am extras următoarele date din documentul tău. Verifică datele completate automat și corectează dacă este cazul.',
    pin_is_duplicated: `Oops! Se pare că ai deja un cont de tranzactionare activ! Dacă ai uitat parola, o poți reseta de {openTag}aici{closeTag}.`
  },
  documents: {
    statement: 'Declarație',
    update_client_data: 'Toate datele si informatiile furnizate in actualul proces de actualizare sunt adevarate, corecte si complete',
    personal_statement: 'Declar pe propria răspundere, cunoscând prevederile legale privind falsul în declarații:',
    client_history: 'Nu dețin calitatea de inculpat și nu am fost condamnat pentru infracțiuni de spălare a banilor, finanțare a terorismului sau evaziune fiscală',
    client_data: 'Informațiile furnizate în vederea deschiderii contului de tranzacționare sunt adevărate, corecte și complete',
    accepted_documents: 'Am citit și accept toate documentele menționate mai jos care sunt parte integrantă a contractului, că înțeleg în totalitate prevederile acestora și că accept toate obligațiile impuse prin contract:',
    consent: 'Înțeleg și accept că bifarea acestor căsuțe are valoare de semnatură cu privire la cele declarate și la documentele contractuale de mai sus și îmi exprim astfel acordul de voință pentru încheierea contractului.'
  },
  reset_steps: {
    reset_identity: 'Sunteți sigur că doriți să vă reîntoarceți la pasul anterior? Va trebui să reluați procesul de identificare și datele colectate vor fi suprascrise.',
    reset_bank_account: 'Sunteți sigur că doriți să vă reîntoarceți la pasul anterior? Va trebui să reluați procesul de verificare IBAN și datele colectate vor fi suprascrise.',
    reset_email_question: 'Întoarcerea la pasul precedent presupune crearea unui cont cu o adresă de email diferită. Ești sigur că vrei să te întorci la primul pas din crearea contului?',
    reset_residence: 'Ești sigur că vrei să resetezi rezidența?',
    reset_questionnaire: 'Ești sigur că vrei să te întorci la chestionar?',
    reviewed_ocr_data: 'Sunteți sigur că doriți să vă reîntoarceți la pasul anterior? Va trebui să reluați procesul de identificare și datele colectate vor fi suprascrise.'
  },
  legal_person: {
    top_message: 'Deschiderea contului de tranzacționare poate fi realizată numai de către un reprezentant legal al persoanei juridice',
    create_account: 'Creare cont persoană juridică',
    email_address: 'Adresa de email a persoanei juridice ce va fi utilizată în relația cu Investimental',
    as_legal_representative: 'În calitate de reprezentant legal al persoanei juridice:'
  },
  legal_person_details: {
    title: 'Date persoană juridică',
    company_details: 'Detalii companie',
    address_office: 'Adresă sediu social (stradă și număr)',
    representative_details: 'Detalii reprezentant legal',
    last_name: 'Nume',
    first_name: 'Prenume',
    address: 'Adresă',
    crn: 'Număr de înregistrare la registrul comerțului',
    tin: 'CUI',
    company_name: 'Nume companie',
    pin: 'CNP',
    lei_code: 'Cod LEI',
    lei_code_expires_at: 'Data expirării codului LEI',
    validation_first_name: 'Vă rugăm să introduceți prenumele!',
    validation_last_name: 'Vă rugăm să introduceți numele!',
    validation_crn: 'Vă rugăm să introduceți numărul de înregistrare la registrul comerțului!',
    validation_tin: 'Vă rugăm să introduceți cod-ul CUI!',
    validation_company_name: 'Vă rugăm să introduceți numele companiei!',
    validation_pin: 'Vă rugăm să introduceți CNP-ul!',
    validation_address: 'Vă rugăm să selectați o adresa!',
    validation_pin_length: 'CNP-ul trebuie să aibă lungimea maximă de 13 cifre',
    validation_pin_invalid: 'CNP-ul nu are format valid',
    validation_crn_length: 'Numărul de înregistrare la registrul comerțului trebuie să aibă între 7 și 15 caractere',
    validation_tin_length: 'Codul CUI trebuie să aibă între 4 și 15 cifre',
    validation_company_name_duplicate: 'Codul CUI este deja asociat unui cont',
    validation_lei_code: 'Vă rugăm să introduceți codul LEI',
    validation_lei_code_length: 'Codul LEI trebuie să aibă 20 de caractere',
    validation_lei_code_expires_at: 'Vă rugăm să introduceți data expirării codului LEI',
    validation_lei_code_expires_at_before_today: 'Data expirării codului LEI trebuie să fie ulterioară datei curente',
    warning_pin_validation: 'CNP-ul nu are un format valid. Te rugăm să verifici datele introduse.'
  }
}
