<template>
  <div class="p-3">
    <div>Butoanele pot avea iconite la stanga sau dreapta</div>
    <Breadcrumb :items="[{label: 'Documente', routeName: 'profile-documents'}, {label: 'Documente contractuale'}]"/>
    <IconColor color="purple" icon="yen" :positions="['top']"/>
    <Button
      class="mb-5"
      :text="'buton verde'"
      :type="'btn btn-primary'"
      :icon="'icons/arrow_icon'"
      :icon-position="'right'"
    >
    </Button>
    <Button
      class="mb-5"
      :text="'buton verde'"
      :type="'btn btn-primary'"
      :icon="'icons/arrow_icon'"
      :icon-position="'left'"
    >
    </Button>
    <Button
      class="mb-5"
      :text="'buton verde contur'"
      :type="'btn-secondary'"
    ></Button>
    <Button
      class="mb-5"
      :text="'buton verde intens'"
      :type="'btn-green-intense'"
    ></Button>
    <Card>
      <img slot="icon" src="~@/assets/img/icons/money.svg" />
      <div slot="title">
        BUNA
      </div>
      <div slot="body">
        <div class="form-group mb-6 w-36">
          <InputText
            :id="'email'"
            :type-input="'email'"
            :label="$t('general.general.email_address')"
          />
        </div>
        <Checkbox
          :id="'checkbox'"
          v-model="checked"
          :name="'checkbox'"
          :label="'test'"
        />
      </div>
    </Card>
    <Message message="asdasd" type="error" />
    <IconBox
      :text="'Avem ghid aici'"
      :icon="'icons/ghid_alimentare_cont'"
    />
    <Button
      @call="testApi()"
      class="mb-5"
      :text="'TEST API'"
      :type="'btn btn-primary'"
      :icon="'icons/arrow_icon'"
      :icon-position="'right'"
    />
    <Button
      class="mb-5"
      :type="'btn'"
      :icon="'icons/trash-can-fill'"
      :icon-position="'center'"
    />
    <span v-if="loaded">
      <SelectDropDownFav
        v-model="testdropwon"
        :name-format="'label'"
        :options="countries"
        :has-favourite="true"
        class="mr-2 w-full"
        @input="updateCitizenship($event, key)"
      />
    </span>
  </div>
</template>

<script>
import Button from './Button'
import InputText from './InputText'
import Checkbox from './Checkbox'
import Card from './Card'
import Message from './Message'
import IconBox from './IconBox.vue'
import ApiService from '../../services/apiService'
import SelectDropDownFav from '../subcomponents/SelectDropDownFav'
import IconColor from './IconColor.vue'
import Breadcrumb from './Breadcrumb.vue'

export default {
  name: 'CommonComponents',
  components: {
    Breadcrumb,
    IconColor,
    Checkbox,
    Button,
    InputText,
    Card,
    Message,
    IconBox,
    SelectDropDownFav
  },
  data () {
    return {
      loaded: false,
      countries: [],
      allCountries: {},
      testdropwon: null,
      checked: false
    }
  },
  methods: {
    async testApi () {
      await ApiService.withAuth().post('/api/user/testing')
    },
    updateCitizenship (asd, add) {
      console.log(asd)
      console.log(add)
    }
  },
  async mounted () {
    let countriesData = await ApiService.withAuth().get('/api/countries')

    if (countriesData.status === 200) {
      var definedFavorites = ['România', 'Albania', 'Algeria']
      var favoritesCo = []

      countriesData.data.data.map(country => {
        if (definedFavorites.includes(country.name)) {
          favoritesCo.push({label: country.name, id: country.id})
        } else {
          this.countries.push({label: country.name, id: country.id})
        }
      })

      favoritesCo.push({
        label: 'none',
        id: null,
        divider: true
      })

      this.countries = favoritesCo.concat(this.countries)

      this.allCountries = this.countries
      this.citizenship = this.countries

      this.loaded = true
    }
  }
}
</script>

<style scoped>
</style>
