<template>
  <div>
    <div v-for="(stock, index) in stocksPie" :key="index">
      <div class="stock-element">
        <div class="flex items-center justify-between">
          <div class="flex items-center gap-3">
            <div class="colored-block" :class="[`${colorBlock[index % 5]}`]"></div>
            <StockSecurityLogo :img="stock.stock_security.logo" :smaller-logo="true" :symbol="stock.stock_security.symbol" :color="colorBlock[index % 5]"/>
            <div class="stock-element-name">
              {{ stock.stock_security.name }}
            </div>
          </div>
          <div class="stock-element-value">{{ stock.current_value ? (Math.floor(stock.current_value * 100) / 100).toLocaleString('en-US', {maximumFractionDigits: 2, minimumFractionDigits: 2}) : 0 }} $</div>
        </div>
        <div class="flex justify-between">
          <div class="column">
            <div class="column-title">{{ $t('deposit_withdrawal.auto_invest.pie.table.target_current_percentage.target') }} / {{ $t('deposit_withdrawal.auto_invest.pie.table.target_current_percentage.percentage') }}</div>
            <div>
              {{ getTargetPercentageOfPie(stock, index) }}
              /
              {{ stock.current_percentage_of_pie ?
              (Math.floor(stock.current_percentage_of_pie * 100) / 100).toLocaleString('en-US', {maximumFractionDigits: 2, minimumFractionDigits: 2}) :
              parseFloat('0').toLocaleString('en-US', {maximumFractionDigits: 2, minimumFractionDigits: 2}) }}
            </div>
          </div>
          <div class="column right">
            <div class="column-title">{{ $t('deposit_withdrawal.auto_invest.pie.table.profit_profit_abs.profit') }} / {{ $t('deposit_withdrawal.auto_invest.pie.table.profit_profit_abs.profit_abs') }}</div>

            <div>{{ stock.profit ? parseFloat(stock.profit).toLocaleString('en-US', {maximumFractionDigits: 2, minimumFractionDigits: 2}) : 0 }}% / {{ stock.profit_absolute ? parseFloat(stock.profit_absolute).toLocaleString('en-US', {maximumFractionDigits: 2, minimumFractionDigits: 2}) : 0 }} $</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StockSecurityLogo from '@/components/subcomponents/StockSecurityLogo.vue'

export default {
  name: 'PieStocksTableMobile',
  components: {StockSecurityLogo},
  props: {
    stocksPie: {
      type: Array,
      default: () => []
    },
    editingPie: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      colorBlock: [
        'light-blue',
        'dark-blue',
        'yellow',
        'primary',
        'pink'
      ]
    }
  },
  methods: {
    getTargetPercentageOfPie (stock, index) {
      let sum = 0
      if (index !== this.stocksPie.length - 1) {
        return (Math.floor(stock.target_percentage_of_pie * 100) / 100).toLocaleString('en-US', {maximumFractionDigits: 2, minimumFractionDigits: 2})
      } else {
        if (this.editingPie) {
          return (Math.ceil(stock.target_percentage_of_pie * 100) / 100).toLocaleString('en-US', {maximumFractionDigits: 2, minimumFractionDigits: 2})
        } else {
          this.stocksPie.forEach((currStock) => {
            if (currStock.id !== stock.id) {
              sum += parseFloat(currStock.target_percentage_of_pie)
            }
          })
          sum = Math.floor(sum * 100) / 100

          return (Math.floor((100 - sum) * 100) / 100).toLocaleString('en-US', {maximumFractionDigits: 2, minimumFractionDigits: 2})
        }
      }
    }
  }
}
</script>
