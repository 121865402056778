<template>
  <div v-on:keyup.enter="loginUser" class="login-component login pb-10">
    <vue-title :title="$t('general.meta_titles.login')"></vue-title>

    <Card class="padding-custom" :card-classes="'margin-bottom-medium'">
      <div slot="title" class="title">{{ $t('auth.general.log_in') }}</div>
      <div slot="title" class="subtitle">{{ $t('auth.login.guidance') }}</div>
      <div slot="body">
        <form>
          <div class="form-group mb-6">
            <InputText
              :id="'username'"
              v-model="username"
              :type-input="'text'"
              :label="$t('auth.login.username')"
            ></InputText>
          </div>

          <Message
            v-if="validation.username"
            :message="validation.username"
            :type="'error'"
            class="mb-5"
          />

          <span
            class="cursor-pointer color-blue font-bold position-custom z-10"
            @click="goToForgotPassword()"
          >
            {{ $t('auth.login.forgot_password') }}
          </span>

          <div class="form-group mb-6">
            <InputText
              :id="'password'"
              v-model="password"
              :type-input="'password'"
              :label="$t('general.general.password')"
            ></InputText>
          </div>

          <Message
            v-if="validation.password"
            :message="validation.password"
            :type="'error'"
            class="mb-5"
          />

          <div @click="loginUser">
            <Button :w100="true" :text="$t('auth.general.login')" :type="'btn-primary'"/>
          </div>

          <Message
            v-if="validation.general"
            :message="validation.general"
            :type="'error'"
            class="mb-5"
          />

          <div class="flex mt-5">
            <div class="text-lg mr-1">
              {{ $t('auth.login.register_question') }}
            </div>

            <div>
              <span
                class="cursor-pointer color-blue font-bold text-lg"
                @click="createNewAccount"
              >
                {{ $t('auth.general.register') }}
              </span>
            </div>
          </div>
        </form>
      </div>
    </Card>
  </div>
</template>

<script>
import Button from '../subcomponents/Button'
import Message from '../subcomponents/Message'
import {mapActions, mapGetters} from 'vuex'
import InputText from '../subcomponents/InputText'
import Card from '../subcomponents/Card'
import UserService from '../../services/auth/userService'
import {getFingerPrint} from '@/helpers/fingerPrint'

export default {
  name: 'Login',
  components: {Card, InputText, Button, Message},
  computed: {
    ...mapGetters({
      isLogged: 'user/isLogged',
      twoFactor: 'user/twoFactor',
      user: 'user/user'
    })
  },
  data () {
    const device = getFingerPrint()

    return {
      username: '',
      password: '',
      validation: {
        username: '',
        password: '',
        general: ''
      },
      device
    }
  },
  async mounted () {
    if (this.$route.query.jwt) {
      await this.loginFromJwt(this.$route.query.jwt)

      await this.nextStepIfLoggedIn()

      if (this.twoFactor) {
        await this.$router.push({name: 'two-factor'})
        return
      }
    }

    await this.nextStepIfLoggedIn()
  },
  methods: {
    ...mapActions({
      login: 'user/login',
      loginFromJwt: 'user/loginFromJwt'
    }),

    async goToForgotPassword () {
      if (this.$route.meta['source'] !== 'lms') {
        await this.$router.push({name: 'password-recovery-send-link'})
      } else {
        await this.$router.push({name: 'password-recovery-send-link-lms'})
      }
    },

    async createNewAccount () {
      if (this.$route.meta['source'] !== 'lms') {
        await this.$router.push({ name: 'register' })
      } else {
        await this.$router.push({ name: 'register-lms' })
      }
    },

    async nextStepIfLoggedIn () {
      if (this.isLogged) {
        if (this.$route.meta['source'] !== 'lms') {
          await this.$router.push({ name: 'homepage' })
        } else {
          await UserService.getLmsUrl(this.user)
            .then(response => {
              window.location.href = response.data.data.url
            })
        }
      }
    },

    validate () {
      let valid = true

      if (!this.password) {
        this.validation.password = this.$t('general.validations.password_validation')
        valid = false
      }

      if (!this.username) {
        this.validation.username = this.$t('auth.login.username_validation')
        valid = false
      }

      return valid
    },
    async loginUser () {
      if (!this.validate()) {
        return null
      }

      let loginResponse = await this.login({
        username: this.username,
        password: this.password,
        device: this.device
      }).catch(response => {
        let data = response.response.data
        if (response.response.status === 401) {
          if (data.data['user_rejected']) {
            this.validation.general = this.$i18n.t('auth.login.user_rejected')
          } else {
            this.validation.general = this.$i18n.t('auth.login.login_validation')
          }
        } else if (response.response.status === 422) {
          if (data.errors.username) {
            this.validation.general = this.$i18n.t('auth.login.username_validation')
          } else if (data.errors.password) {
            this.validation.general = this.$i18n.t('general.validations.password_validation')
          }
        }
        if (!this.validation.general) {
          this.validation.general = data.message
        }
      })

      if (!loginResponse) {
        return
      }

      await this.nextStepIfLoggedIn()

      if (this.twoFactor) {
        await this.$router.push({name: 'two-factor'})
      }
    }
  },
  watch: {
    password (nVal) {
      if (nVal) {
        this.validation.password = ''
        this.validation.general = ''
      }
    },
    username (nVal) {
      if (nVal) {
        this.validation.username = ''
        this.validation.general = ''
      }
    }
  }
}
</script>

<style scoped></style>
