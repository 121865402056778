import UserService from '../services/auth/userService'
import {mapGetters} from 'vuex'

export default {
  computed: {
    ...mapGetters({
      user: 'user/user'
    }),
    today () {
      let date = new Date()

      date.setHours(0, 0, 0, 0)

      return date
    },
    anniversary () {
      let date = new Date(this.user.last_anniversary_contract_at ? this.user.last_anniversary_contract_at : '')

      date.setHours(0, 0, 0, 0)
      date.setFullYear(date.getFullYear() + 1)
      date.setDate(date.getDate() - 30)

      return date
    }
  },
  methods: {
    updateData () {
      this.$router.push({name: 'update-personal-data'})
    }
  }
}
