<template>
  <div class="verify-identity-component">
    <vue-title :title="$t('general.meta_titles.verify_identity')"></vue-title>

    <div class="p-6 rounded-lg bg-primary m-auto">
      <StepBar :current-step="selectedTabId" :steps="tabs"></StepBar>
    </div>

    <div class="relative">
      <div id="sumsub-websdk-container"></div>

      <Button
        class="m-top-custom"
        :icon-position="'left'"
        :text="$t('general.general.back')"
        :type="'btn btn-secondary padding-medium no-wrap'"
        @call="resetStep()"
      />
    </div>
  </div>
</template>

<script>

import StepBar from '../layout/StepBar'
import {mapActions, mapGetters} from 'vuex'
import sumsub from '../../services/auth/sumsubService'
import Button from '../subcomponents/Button'
import {ONBOARDING_REVIEW_OCR_DATA, ONBOARDING_VERIFY_IDENTITY} from '@/router'

export default {
  name: 'VerifyIdentity',
  components: {StepBar, Button},
  data () {
    return {
      interval: null,
      tabs: [
        {
          id: 1,
          label: this.$i18n.t('onboarding.general.create_account')
        },
        {
          id: 2,
          label: this.$i18n.t('onboarding.general.identity_verification')
        },
        {
          id: 3,
          label: this.$i18n.t('onboarding.general.financial_data')
        }
      ],
      selectedTabId: 2,
      accessToken: '',
      tabsClasses: ['justify-center']
    }
  },
  computed: {
    ...mapGetters({
      userId: 'sumsub/userId',
      token: 'sumsub/token',
      user: 'user/user'
    })
  },
  methods: {
    ...mapActions({
      getToken: 'sumsub/getToken'
    }),
    async resetStep () {
      await this.$confirm(
        this.$t('onboarding.reset_steps.reset_identity'),
        '',
        'warning',
        {cancelButtonText: this.$t('general.general.cancel')}
      ).then(async () => {
        await sumsub.resetProcess()
        await this.$router.push({name: ONBOARDING_REVIEW_OCR_DATA})
      })
    }
  },
  destroyed () {
    clearInterval(this.interval)
  },
  async mounted () {
    await this.getToken(this.user.id)

    if (this.$router.currentRoute.name !== ONBOARDING_VERIFY_IDENTITY) {
      clearInterval(this.interval)

      return
    }

    let country = null

    if (this.user.domicile) {
      country = this.user.domicile.code_alpha3
    }

    sumsub.launchWebSdk(this.user.id, this.token, country)

    this.interval = setInterval(() => {
      sumsub.finishProcess()
    }, 45000)
  }
}
</script>

<style scoped>

</style>
