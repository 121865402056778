export default {
  general: {
    info_title: 'Do you need more help?',
    info: 'We have prepared for you a series of <u><b>guides</b></u> to help you get started with trading on the stock exchange through Investimental.'
  },
  sections: {
    transfers_payments: {
      title: 'Transfers and payments',
      labels: {
        add_iban: 'I want to add a new IBAN code, how do I do that?',
        remove_iban: 'I want to remove an IBAN code, how do I do that?',
        charge_account: 'I want to charge, how do I do that?',
        which_accounts_can_charge: 'In what Investimental bank accounts can I charge?',
        time_to_receive_money_transaction: 'How long does it take for the money to reach the trading account?',
        withdraw_money: 'I want to withdraw money, how do I do that?',
        time_to_receive_money: 'How long does it take for the money to reach my bank account after I requested the withdrawal?'
      }
    },
    account_administration: {
      title: 'User account administration (Investimental and/or Arena XT)',
      labels: {
        lost_access_to_email: 'I no longer have access to email, what do I do?',
        lost_access_to_phone: 'I no longer have access to the phone number, what do I do?',
        arena_xt_account: 'What is my Arena XT user account?',
        forgot_password: 'I forgot my password, what do I do?',
        change_password: 'I want to change my password, how do I do that?',
        email_not_received: 'I did not receive the email to reset my password, what do I do?',
        single_sign_on: 'What does Single Sign-On (SSO) authentication mean and how does it help me?'
      }
    },
    open_account: {
      title: 'Account opening',
      labels: {
        proof_of_identity: 'What document do I use to prove my identity?',
        proof_of_address: 'What document do I use to prove my address?',
        proof_of_residence: 'What documents are required to prove residence?',
        what_is_mifid: 'What is MIFID?'
      }
    }
  }
}
