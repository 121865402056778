<template>
  <div class="login-component login pb-10">
    <vue-title :title="$t('general.meta_titles.password_recovery')"></vue-title>
    <Card class="padding-custom w-fit"
          :card-classes="'margin-bottom-medium'">
      <div slot="title" class="title">{{ $t('auth.general.reset_password') }}</div>
      <div slot="body">
        <div v-if="!emailSent">
          <form>
            <div>{{ $t('auth.recovery_password.recovery_instructions') }}</div>
            <div class="form-group my-6">
              <InputText
                :id="'email'"
                v-model="email"
                :disabled="true"
                :type-input="'email'"
              />
            </div>

            <Message v-if="validation.email" :message="validation.email" :type="'error'" class="mb-5"/>
            <Message v-if="validation.general" :message="validation.general" :type="'error'" class="mb-5"/>
            <Button :loading="loadingData.resendEmail" @call="recoverPassword" :w100="true" :text="$t('general.general.send')" :type="'btn-primary'"/>
          </form>
          <div class="mt-6">
            <span class="cursor-pointer color-blue font-semibold" @click="goToLogin()">{{ $t('auth.recovery_password.back_to_login') }}</span>
          </div>
        </div>
        <div v-else class="flex center p-6 bg-primary max-w-md m-auto h-60 ">
          <div>
            {{ $t('auth.recovery_password.recovery_success') }}
          </div>
        </div>
      </div>
    </Card>
  </div>
</template>

<script>
import Button from '../subcomponents/Button'
import Message from '../subcomponents/Message'
import Card from '../subcomponents/Card'
import {mapActions, mapGetters} from 'vuex'
import InputText from '../subcomponents/InputText'

export default {
  name: 'PasswordRecoverySendLink',
  components: { Button, Message, Card, InputText },
  computed: {
    ...mapGetters({
      isLogged: 'user/isLogged',
      twoFactor: 'user/twoFactor'
    })
  },
  data () {
    return {
      loadingData: {
        resendEmail: false
      },
      email: '',
      password: '',
      validation: {
        email: '',
        password: '',
        general: ''
      },
      emailSent: false
    }
  },
  methods: {
    ...mapActions({
      login: 'user/login',
      sendRecoveryLink: 'user/sendRecoveryLink'
    }),
    async goToLogin () {
      if (this.$route.meta['source'] !== 'lms') {
        await this.$router.push({name: 'login'})
      } else {
        await this.$router.push({name: 'login-lms'})
      }
    },
    validate () {
      let valid = true

      const validateEmail = (email) => {
        return String(email)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          )
      }

      if (!this.email) {
        this.validation.email = this.$t('general.validations.email_validation')

        valid = false
      } else if (!validateEmail(this.email)) {
        this.validation.email = this.$t('general.validations.email_format_validation')

        valid = false
      }

      return valid
    },
    async recoverPassword () {
      try {
        this.loadingData.resendEmail = true
        if (!this.validate()) {
          return null
        }

        let response = await this.sendRecoveryLink({
          email: this.email
        }).catch((error) => {
          this.validation.general = error.response.data.message
        })

        if (response && response.data.data && response.data.data.sent) {
          this.emailSent = response.data.data.sent
        }
      } finally {
        this.loadingData.resendEmail = false
      }
    }
  },
  watch: {
    email (nVal) {
      if (nVal) {
        this.validation.email = ''
        this.validation.general = ''
      }
    }
  }
}
</script>
<style scoped>

</style>
