<template>
  <div class="notifications configurations">
    <general-layout>
      <template v-slot:title>
        <vue-title :title="$t('general.meta_titles.configure_notifications')"/>
      </template>

      <template v-slot:tabs>
        <Tabs
          :show-id-title="false"
          :user-info="userInfo"
          :general-info="generalInfo"
        />
      </template>

      <template v-slot:body>
        <div class="desktop-flex gap-8" :class="(isDesktop ? 'mt-10' : 'mt-2.5')" v-if="!loading">
          <Card class="w-full mb-5" :card-classes="'mobile-card-portal px-9'">
            <template v-slot:title>
              <div class="flex justify-between items-center flex-col gap-4">
                <div class="flex justify-between items-start">
                  <div class="mr-2 header">
                    {{ $t('notifications.general.marketing_agreement') }}
                  </div>

                  <toggle-button
                    class="mt-2"
                    :color="isPendingMarketing ? 'orange' : ''"
                    @change="changeMarketing()"
                    v-model="marketingAgreement"
                  />
                </div>

                <div class="flex justify-between items-start">
                  <div class="mr-2 header">
                    {{ $t('notifications.general.transactions_notifications_agreement') }}
                  </div>

                  <toggle-button
                    class="mt-2"
                    @change="changeTransactionsNotifications()"
                    v-model="transactionsNotificationsAgreement"
                  />
                </div>
              </div>
            </template>

            <template v-slot:body>
              <div class="mt-4" v-if="isPendingMarketing">
                {{ $t('notifications.notification.request_processing') }}
              </div>
            </template>
          </Card>

          <div class="w-full" :class="[isMobile ? 'mt-8' : '']">
            <div class="w-full">
              <IconBox
                icon-color="green"
                icon="help-placeholder"
                class="mb-2 guide"
                :href="wpLink('/ghid/deschidere-cont-persoane-fizice/')"
                :text="$t('useful_links.notifications.guide_open_account')"
              />

              <IconBox
                icon-color="yellow"
                icon="help-placeholder"
                class="mb-2 guide"
                :href="wpLink('/ghid/ghid-alimentare-cont/')"
                :text="$t('guides.general.deposit_guide')"
              />
            </div>
          </div>
        </div>

        <pulse-loader class="mt-5" v-if="loading"/>
      </template>
    </general-layout>
  </div>
</template>

<script>
import GeneralLayout from '../../layout/GeneralLayout'
import Tabs from '../../layout/Tabs'
import Button from '../../subcomponents/Button'
import NotificationsService from '../../../services/notifications/notificationsService'
import InputText from '../../subcomponents/InputText'
import Card from '../../subcomponents/Card'
import IconBox from '../../subcomponents/IconBox.vue'
import {mapActions, mapGetters} from 'vuex'
import Resources from '@/components/subcomponents/Resources.vue'

export default {
  name: 'Configurations',
  components: { Resources, GeneralLayout, Tabs, Button, IconBox, Card, InputText },
  data () {
    return {
      loading: false,
      marketingAgreement: null,
      transactionsNotificationsAgreement: null,
      dbMarketingAgreement: null,
      agreements: null,
      userInfo: 0,
      generalInfo: 0
    }
  },
  async created () {
    this.loading = true

    if (this.isLogged) {
      await this.setData()
    }
  },
  computed: {
    ...mapGetters({
      screenDevice: 'layout/screenDevice',
      unreadUserNotifications: 'user/unreadUserNotifications',
      unreadGeneralNotifications: 'user/unreadGeneralNotifications',
      isLogged: 'user/isLogged'
    }),
    isDesktop () {
      return this.screenDevice === this.$screenDeviceConstants.DESKTOP
    },
    isMobile () {
      return this.screenDevice === this.$screenDeviceConstants.MOBILE
    },
    isPendingMarketing () {
      return this.dbMarketingAgreement && this.dbMarketingAgreement.type === 'pending_marketing'
    }
  },
  methods: {
    ...mapActions({
      getUnreadNotifications: 'user/getUnreadNotifications'
    }),
    async setData () {
      let dbMarketingAgreement = await NotificationsService.getAgreementType(
        'marketing'
      )

      let transactionsNotificationsAgreement = await NotificationsService.getAgreementType(
        'transactions-notifications'
      )

      if (dbMarketingAgreement.data.data.agreements.length) {
        this.dbMarketingAgreement = dbMarketingAgreement.data.data.agreements[0]
      } else {
        this.dbMarketingAgreement = null
      }

      if (transactionsNotificationsAgreement.data.data.agreements.length) {
        this.transactionsNotificationsAgreement = transactionsNotificationsAgreement.data.data.agreements[0]
      } else {
        this.transactionsNotificationsAgreement = null
      }

      this.marketingAgreement = !!dbMarketingAgreement.data.data.agreements.length

      await this.getUnreadNotifications()

      this.userInfo = this.unreadUserNotifications
      this.generalInfo = this.unreadGeneralNotifications
      this.loading = false
    },
    async changeMarketing () {
      this.loading = true
      await NotificationsService.setAgreement('marketing', {
        value: this.marketingAgreement
      }).finally(() => {
        this.setData()
      })
    },
    async changeTransactionsNotifications () {
      this.loading = true
      await NotificationsService.setAgreement('transactions-notifications', {
        value: this.transactionsNotificationsAgreement
      }).finally(() => {
        this.setData()
      })
    }
  }
}
</script>
