<template>
  <div>
    <vue-title :title="$t('general.meta_titles.review_ocr_data')"></vue-title>

    <div class="p-6 rounded-lg bg-primary m-auto">
      <StepBar :current-step="selectedTabId" :steps="tabs"></StepBar>
    </div>

    <div class="relative personal-data">
      <Card :card-classes="'margin-bottom-medium mobile-border-none'">
        <template v-slot:top>
          <img src="~@/assets/img/icons/yellow_user_verified.svg">
        </template>

        <template v-slot:title>
          <div>{{ $t('onboarding.general.identity_verification') }}</div>
        </template>

        <template v-slot:body>
          <div>
            <div v-if="!loading">
              <Message
                v-if="userData.identity_card.pin_is_duplicated && isProduction"
                :message="openRecoveryPassword"
                :type="'error'"
                :formatIsHtml="true"
                class="mb-5"
              />

              <div v-else>
                <div class="mb-5">{{ $t('onboarding.review_ocr_data.info') }}</div>

                <IdentityCardForm
                  @setDomicile="setDomicile"
                  :open-address-field="openAddressField"
                  :onboarding="true"/>

                <Message
                  v-if="validationAddress"
                  :message="$t('general.address.complete_address')"
                  :type="'error'"
                  class="mb-5"
                />
                <Message
                  v-if="validation.general"
                  :message="validation.general"
                  :type="'error'"
                  class="mb-5"
                />

                <div class="flex justify-between mt-10">
                  <div>
                    <Button
                      :icon="'icons/arrow_back_green'"
                      :icon-position="'left'"
                      :text="$t('general.general.back')"
                      :type="'btn btn-secondary padding-medium no-wrap'"
                      @call="resetStep()"
                    />
                  </div>

                  <div>
                    <Button
                      :text="$t('general.general.continue')"
                      :type="'btn btn-primary padding-medium no-wrap'"
                      :loading="loadingData.continue"
                      @call="nextStep()"
                    />
                  </div>
                </div></div>

            </div>

            <PulseLoader v-if="loading" class="w-fit m-auto p-10 justify-center flex"/>
          </div>
        </template>
      </Card>
    </div>
  </div>
</template>

<script>

import StepBar from '../layout/StepBar'
import Card from '../subcomponents/Card'
import {mapActions, mapGetters} from 'vuex'
import Button from '../subcomponents/Button'
import questionnaireService from '../../services/onboarding/questionnaireService'
import IdentityCardForm from '../profile/settings/PersonalData/IdentityCardForm'
import Message from '../subcomponents/Message'
import IdentityCardService from '../../services/personal-data/identityCardService'
import {ONBOARDING_BANK_ACCOUNT, ONBOARDING_VERIFY_IDENTITY} from '@/router'
import ApiService from '@/services/apiService'

export default {
  name: 'ReviewOcrData',
  components: {Message, IdentityCardForm, StepBar, Button, Card},
  data () {
    return {
      userData: {},
      interval: null,
      loading: true,
      openAddressField: false,
      tabs: [
        {
          id: 1,
          label: this.$i18n.t('onboarding.general.create_account')
        },
        {
          id: 2,
          label: this.$i18n.t('onboarding.general.identity_verification')
        },
        {
          id: 3,
          label: this.$i18n.t('onboarding.general.financial_data')
        }
      ],
      selectedTabId: 2,
      validationAddress: false,
      firstName: null,
      lastName: null,
      cnp: null,
      CI: null,
      series: null,
      releaseDate: '',
      expirationDate: '',
      address: {},
      nationality: '',
      type: '',
      savedData: false,
      types: [],
      validation: {
        general: '',
        street: '',
        country: '',
        locality: '',
        expirationDate: '',
        releaseDate: ''
      },
      loadingData: {
        continue: false
      }
    }
  },
  computed: {
    ...mapGetters({
      user: 'user/user',
      identityCardInfo: 'identityCard/getIdentityCardInfo'
    }),
    isProduction () {
      return process.env.VUE_APP_ENV === 'production'
    },
    openRecoveryPassword () {
      return this.$i18n.t('onboarding.review_ocr_data.pin_is_duplicated',
        {
          openTag: `<span id="redirect-to-password-recovery-link" class="color-primary cursor-pointer">`,
          closeTag: '</span>'
        }
      )
    }
  },
  methods: {
    ...mapActions({
      getUserData: 'user/getUserData',
      saveNewIdCard: 'identityCard/saveIdCard',
      validate: 'identityCard/validate',
      logout: 'user/logout'
    }),

    formatDateForBackEnd (date) {
      if (!date) {
        return ''
      }
      const tmpDate = date.split('/').reverse().join('-')

      let newDate = new Date(tmpDate)

      let month = '' + (newDate.getMonth() + 1)
      let day = '' + newDate.getDate()
      let year = newDate.getFullYear()

      if (month.length < 2) {
        month = '0' + month
      }

      if (day.length < 2) {
        day = '0' + day
      }

      return [year, month, day].join('-')
    },
    async saveData () {
      let idCard = {
        first_name: this.identityCardInfo.firstName,
        last_name: this.identityCardInfo.lastName,
        personal_identification_number: this.identityCardInfo.cnp,
        address: JSON.stringify(this.identityCardInfo.address),
        type: this.identityCardInfo.type ? this.identityCardInfo.type : this.types.id_card,
        nationality: this.identityCardInfo.nationality,
        number: this.identityCardInfo.CI,
        expiration_date: this.formatDateForBackEnd(this.identityCardInfo.expirationDate),
        release_date: this.formatDateForBackEnd(this.identityCardInfo.releaseDate),
        identity_type: this.identityCardInfo.identity_type,
        on_boarding: true,
        file: this.identityCardInfo.identity_card
      }
      let responseStatus = 0

      if (idCard.type !== 'passport') {
        idCard.series = this.identityCardInfo.series
      }

      await this.saveNewIdCard(idCard).then((response) => {
        if (response.status === 200 || response.status === 201) {
          this.isEdit = false
          this.savedData = true
          responseStatus = response.status
        }
      }).catch((response) => {
        if (response.response.status === 500) {
          this.validation.general = this.$i18n.t('general.validations.internal_error')
        }

        if (response.response.status === 422) {
          this.validation.general = response.response.data.message
        }
        responseStatus = response.response.status
      })

      return responseStatus
    },

    async nextStep () {
      this.loadingData.continue = true
      try {
        const validation = await this.validate(this.identityCardInfo)

        if (!validation) {
          return
        }
        let responseStatus = await this.saveData()

        if (responseStatus === 200) {
          if (!this.address && Object.values(this.identityCardInfo.address).length) {
            this.validationAddress = true

            return
          }

          const userData = await ApiService.withAuth().post('/api/user/onboarding/update-reviewed-ocr-data')

          if (userData.status === 200) {
            let user = userData.data.data.user

            if (user.has_access_in_portal) {
              await this.$router.push({name: 'homepage'})

              return
            }

            await this.$router.push({name: ONBOARDING_BANK_ACCOUNT})
          }
        }
      } finally {
        this.loadingData.continue = false
      }
    },

    setDomicile (address) {
      this.validationAddress = false
      this.address = address
    },

    async checkIfWebhookArrived () {
      let userDataDb = await this.getUserData()

      if (userDataDb.status === 200 && userDataDb.data.data.user.identity_status === 'completed') {
        this.userData = userDataDb.data.data.user

        if (!userDataDb.data.data.user.identity_card.address) {
          this.openAddressField = true
        }

        this.userData = userDataDb.data.data.user

        this.loading = false
      }
    },

    async resetStep () {
      await this.$confirm(
        this.$t('onboarding.reset_steps.reviewed_ocr_data'),
        '',
        'warning',
        {cancelButtonText: this.$t('general.general.cancel')}
      ).then(async () => {
        let reset = await questionnaireService.resetStep({reset_identity: true})

        if (reset.status === 200) {
          await this.$router.push({name: ONBOARDING_VERIFY_IDENTITY})
        }

        return reset.status
      })
    }
  },
  async mounted () {
    await this.checkIfWebhookArrived()
    await IdentityCardService.getTypes().then((response) => {
      if (response.status === 200 && response.data.data) {
        this.types = response.data.data
      }
    })

    if (this.loading) {
      this.interval = setInterval(this.checkIfWebhookArrived, 5000)
    }
  },
  beforeDestroy () {
    clearInterval(this.interval)
  },
  watch: {
    loading (loading) {
      if (!loading) {
        clearInterval(this.interval)
      }
    }
  }
}
</script>

<style scoped>

</style>
