<template>
  <div v-if="pies">
    <table class="autoinvest-table">
      <colgroup>
        <col span="1" style="width: 14.28%;">
        <col span="1" style="width: 21.28%;">
        <col span="1" style="width: 11.28%;">
        <col span="1" style="width: 11.28%;">
        <col span="1" style="width: 12.28%;">
        <col span="1" style="width: 14.30%;">
        <col span="1" style="width: 14.30%;">
      </colgroup>
      <thead>
        <tr>
          <th></th>
          <th></th>
          <th>{{ $t('deposit_withdrawal.auto_invest.table.current_value') }}</th>
          <th>{{ $t('deposit_withdrawal.auto_invest.table.deposits') }}</th>
          <th>{{ $t('deposit_withdrawal.auto_invest.table.annual_medium_yield') }}</th>
          <th></th>
          <th></th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="(pie, key) in pies.pies" :key="key" class="row">
          <td class="name-pie">{{ pie.account_alias }}</td>
          <td class="w-fit">
            <PieChart
              :width="82"
              :height="82"
              :border-width="2"
              :chart-data-sets="getDataSetsForArray(pie)"
              :chart-data-sets-labels="datasetChartLabels(pie)"
              :chart-id="`wee-${pie.id}`"
            />
          </td>

          <td>
            &#36;{{ parseFloat(pie.total_value).toLocaleString('en-US', {maximumFractionDigits: 2, minimumFractionDigits: 2}) }}
          </td>
          <td>
            &#36;{{ parseFloat(pie.deposits_value).toLocaleString('en-US', {maximumFractionDigits: 2, minimumFractionDigits: 2}) }}
          </td>
          <td>
            {{ parseFloat(pie.variation).toLocaleString('en-US', {maximumFractionDigits: 2, minimumFractionDigits: 2}) }}%
          </td>
          <td>
            <Button
              :loading=seeLoader
              @call="seePie(pie.id)"
              extra-classes="px-5"
              :text="$t('deposit_withdrawal.auto_invest.table.see_pie')"
              :type="'btn-dark-background'"
            />
          </td>
          <td>
            <Button
              :loading=depositLoader
              @call="depositPie(pie)"
              extra-classes="px-5"
              :text="$t('deposit_withdrawal.auto_invest.deposit_pie')"
              :disabled="pie.status === $piesConstants.PIE_BLOCKED"
              :type="'btn-dark-outline'"
            />
          </td>
        </tr>
      <tr class="total-row">
        <td>{{ $t('deposit_withdrawal.general.total') }}:</td>
        <td></td>
        <td>
          &#36;{{ parseFloat(pies.pies_data.total_pies_value).toLocaleString('en-US', {maximumFractionDigits: 2, minimumFractionDigits: 2}) }}
        </td>
        <td>
          &#36;{{ parseFloat(pies.pies_data.total_pies_deposits).toLocaleString('en-US', {maximumFractionDigits: 2, minimumFractionDigits: 2}) }}
        </td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
      </tbody>
    </table>
    <PopUpModal
      class="h-fit"
      :modal-fit-content="true"
      :modal-opened="showDepositModal"
      @closeModal="closeDepositPopUp()">
      <div>
        <div class="text-center text-2xl font-bold mb-4">{{ $t('deposit_withdrawal.deposits.deposit') }}</div>

        <div class="font-bold mb-2">
          {{ $t('deposit_withdrawal.account_transfers.available_sum') }}: {{ parseFloat(accountBalance).toLocaleString('en-US', {maximumFractionDigits: 2, minimumFractionDigits: 2}) }} USD
        </div>

        <div class="font-bold mb-4" v-if="selectedPie.total_value === 0">
          {{ $t('deposit_withdrawal.auto_invest.min_sum')}}: {{ minSumDeposit.toLocaleString('en-US', {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2
        }) }} USD
        </div>

        <InputText
          :id="'sumForDeposit'"
          v-model="sumForDeposit"
          :type-input="'number'"
          :max-decimals="2"
          @input="truncateSum"
          :label="$t('deposit_withdrawal.auto_invest.transfers.place_sum')"
          :sufix="'USD'"
        />
        <div v-if="validationDecimalsSum" class="error-color text-xs mt-1 pl-2">{{ validationDecimalsSum }}</div>

        <SelectDropDown
          v-model="selectedAccount"
          class="mt-3"
          :id="'accounts'"
          :label="$t('deposit_withdrawal.auto_invest.transfers.from_account')"
          :options="accounts"
          disabled
        />

        <div class="flex mt-8">
          <Button
            class="mt-5 mx-auto"
            :text="$t('deposit_withdrawal.auto_invest.transfers.cancel')"
            :type="'btn-dark-outline'"
            @call="closeDepositPopUp()"/>
          <Button
            class="mt-5 mx-auto"
            :text="$t('deposit_withdrawal.auto_invest.transfers.deposit')"
            :type="'btn-dark-background'"
            :disabled="!sumForDeposit || !Object.keys(selectedAccount).length ||
             (selectedPie.total_value === 0 && sumForDeposit < minSumDeposit) ||
              parseFloat(sumForDeposit) > parseFloat(accountBalance) || !!validationDecimalsSum.length > 0"
            @call="deposit()"/>
        </div>

      </div>
    </PopUpModal>

    <PopUpModal
      class="h-fit"
      :modal-fit-content="true"
      :closable-modal="false"
      :modal-opened="showMessageModal"
      @closeModal="closeMessageModal()">

      <div class="text-center font-bold text-xl mb-3">{{ message }}</div>
    </PopUpModal>
  </div>
</template>

<script>
import Button from '@/components/subcomponents/Button.vue'
import PieChart from '@/components/subcomponents/charts/PieChart.vue'
import {mapGetters} from 'vuex'
import AccountTransfersService from '@/services/deposit-withdrawal/accountTransfersService'
import InputText from '@/components/subcomponents/InputText.vue'
import SelectDropDown from '@/components/subcomponents/SelectDropDown.vue'
import PopUpModal from '@/components/subcomponents/PopUpModal.vue'
import piesService from '@/services/deposit-withdrawal/piesService'

export default {
  name: 'AutoInvestPiesTableDesktop',
  components: {PopUpModal, SelectDropDown, InputText, PieChart, Button},
  data () {
    return {
      seeLoader: false,
      depositLoader: false,
      piesData: [],
      total: {},
      showDepositModal: false,
      selectedAccount: {},
      accountBalance: 0,
      sumForDeposit: '',
      selectedPie: {},
      showMessageModal: false,
      message: '',
      accounts: [],
      commissionPercentageEquity: null,
      validationDecimalsSum: ''

    }
  },
  computed: {
    ...mapGetters({
      pies: 'pies/pies',
      minimumValueOfOrder: 'user/minimumValueOfOrder'
    }),
    minSumDeposit () {
      let minSum = 0

      let minPercentage = Math.min(...this.selectedPie.positions.map(position => position.target_percentage_of_pie))

      minSum = (parseFloat(this.minimumValueOfOrder) / (minPercentage / 100)) * (1 + this.commissionPercentageEquity) + 1

      return Math.ceil(minSum * 100) / 100
    }
  },
  mounted () {
    this.setAccounts()
  },
  methods: {

    async setAccounts () {
      let accounts = await AccountTransfersService.getAccounts()

      this.accounts = accounts.data.data
      this.accounts.forEach(account => {
        account.name = (account.source === 'arenaxt' ? 'BVB' : 'GLOBAL') + ' ' +
          account.alias + ' ' + account.currency
      })

      this.accounts.forEach(account => {
        account.label = account.name
      })
    },

    truncateSum (event) {
      if (String(event).split('.')[1]?.length > 2) {
        this.validationDecimalsSum = this.$i18n.t('deposit_withdrawal.auto_invest.pie.sum_with_two_decimals')
      } else {
        this.validationDecimalsSum = ''
      }
    },

    closeMessageModal () {
      this.showMessageModal = false
    },

    async deposit () {
      let payload = {
        fromAmount: this.sumForDeposit,
        fromAccountId: this.selectedAccount.code,
        toAccountId: this.selectedPie.account_code
      }

      await AccountTransfersService.sendTransfer(payload)
        .then(response => {
          this.message = response.data.message
        }).catch(error => {
          this.message = error.response.data.message
        })

      this.showDepositModal = false
      this.showMessageModal = true

      setTimeout(() => {
        this.sumForDeposit = ''
        this.selectedAccount = {}
        this.accountBalance = 0
      }, 1000)

      setTimeout(() => {
        this.$emit('getAllPies')
      }, 5000)
    },

    setSelectedAccount () {
      this.selectedAccount = this.accounts.find(account => account.currency === 'USD' && account.is_main)
    },

    async depositPie (pie) {
      this.selectedPie = pie
      this.showDepositModal = true

      piesService.getCommissionPercentageEquityPie(this.selectedPie.id).then(response => {
        this.commissionPercentageEquity = response.data.data.commissionPercentageEquity / 100
      }).catch(error => {
        console.log(error)
      })

      await this.setSelectedAccount()
      await this.getAvailableSum(this.selectedAccount.code)
    },

    closeDepositPopUp () {
      this.showDepositModal = false

      setTimeout(() => {
        this.sumForDeposit = ''
        this.selectedAccount = {}
        this.accountBalance = 0
        this.validationDecimalsSum = ''
      }, 1000)
    },

    async getAvailableSum (accountCode) {
      await AccountTransfersService.getAccountBalance({
        internalAccount: accountCode
      })
        .then(response => {
          this.accountBalance = response.data.data.toString()
        })
    },
    getDataSetsForArray (pie) {
      let arrayOfCurrentPercentages = pie.positions.map(a => a.current_percentage_of_pie)
      let arrayOfTargetPercentages = pie.positions.map(a => parseInt(a.target_percentage_of_pie))

      if (arrayOfCurrentPercentages.every(val => val === 0)) {
        return arrayOfTargetPercentages
      } else {
        return arrayOfCurrentPercentages
      }
    },
    datasetChartLabels (pie) {
      return pie.positions.map(a => a.stock_security.symbol)
    },
    seePie (id) {
      this.$emit('seePie', id)
    }
  }
}
</script>
