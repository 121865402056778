import UserService from '../../services/auth/userService'
import NotificationsService from '../../services/notifications/notificationsService'
import AddressService from '../../services/resources/addressService'
import CookieService from '../../services/auth/cookieService'

export const TWO_FA_TYPE_NOT_NEEDED = null
export const TWO_FA_TYPE_AUTH = 'authenticator'
export const TWO_FA_TYPE_SMS = 'sms'

const getDefaultState = () => {
  return {
    name: 'Homepage',
    isLogged: false,
    token: '',
    jwt: '',
    twoFactor: TWO_FA_TYPE_NOT_NEEDED,
    user: {},
    email: '',
    username: '',
    firstName: '',
    lastName: '',
    cui: '',
    type: '',
    companyName: '',
    maritalStatus: '',
    phone: '',
    gdprAccord: false,
    marketingAccord: false,
    unreadNotifications: 0,
    unreadUserNotifications: 0,
    unreadGeneralNotifications: 0,
    has_access_in_portal: false,
    maintenanceModeAnnouncement: '',
    banner: '',
    activeSaltedge: '',
    hasAttachments: false,
    typeChat: 'standard',
    maximumNumberOfPies: 10,
    minimumValueOfOrder: 10,
    maximumInstrumentsOfPie: 20,
    minimumInstrumentsOfPie: 2,
    dxAccountsTradingAccessEnabled: false,
    middlewareActive: false,
    saltedgeDepositModalMessage: '',
    bankAccountsRejectedDepositModalMessage: ''
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    resetState (state) {
      Object.assign(state, getDefaultState())
    },
    setToken (state, token) {
      state.token = token
    },
    setJwt (state, jwt) {
      state.jwt = jwt
    },
    setUser (state, user) {
      state.user = user
    },
    setEmail (state, email) {
      state.email = email
    },
    setFirstName (state, firstName) {
      state.firstName = firstName
    },
    setLastName (state, lastName) {
      state.lastName = lastName
    },
    setCui (state, cui) {
      state.cui = cui
    },
    setType (state, type) {
      state.type = type
    },
    setCompanyName (state, companyName) {
      state.companyName = companyName
    },
    setMaritalStatus (state, maritalStatus) {
      state.maritalStatus = maritalStatus
    },
    setPhone (state, phone) {
      state.phone = phone
    },
    setLoggedId (state, loggedId) {
      state.isLogged = loggedId

      if (loggedId) {
        CookieService.userHasLoggedIn(state.user)
      } else {
        CookieService.userHasLoggedOut()
      }
    },
    setTwoFactor (state, twoFactor) {
      state.twoFactor = twoFactor
    },
    setMiddlewareActive (state, middlewareActive) {
      state.middlewareActive = middlewareActive
    },
    setGdprAccord (state, gdprAccord) {
      state.gdprAccord = gdprAccord
    },
    setMarketingAccord (state, marketingAccord) {
      state.marketingAccord = marketingAccord
    },
    setUsername (state, username) {
      state.username = username
    },
    setUnreadNotifications (state, unreadNotifications) {
      state.unreadNotifications = unreadNotifications
    },
    setUserUnreadNotifications (state, unreadUserNotifications) {
      state.unreadUserNotifications = unreadUserNotifications
    },
    setGeneralUnreadNotifications (state, unreadGeneralNotifications) {
      state.unreadGeneralNotifications = unreadGeneralNotifications
    },
    setMaintenanceModeAnnouncement (state, maintenanceModeAnnouncement) {
      state.maintenanceModeAnnouncement = maintenanceModeAnnouncement
    },
    setBanner (state, banner) {
      state.banner = banner
    },
    setActiveSaltedge (state, activeSaltedge) {
      state.activeSaltedge = activeSaltedge
    },
    setHasAttachments (state, hasAttachments) {
      state.hasAttachments = hasAttachments
    },
    setTypeChat (state, typeChat) {
      state.typeChat = typeChat
    },
    setMaximumNumberOfPies (state, maximumNumberOfPies) {
      state.maximumNumberOfPies = maximumNumberOfPies
    },
    setMinimumValueOfOrder (state, minimumValueOfOrder) {
      state.minimumValueOfOrder = minimumValueOfOrder
    },
    setMaximumInstrumentsOfPie (state, maximumInstrumentsOfPie) {
      state.maximumInstrumentsOfPie = maximumInstrumentsOfPie
    },
    setMinimumInstrumentsOfPie (state, minimumInstrumentsOfPie) {
      state.minimumInstrumentsOfPie = minimumInstrumentsOfPie
    },
    setDxAccountsTradingAccessEnabled (state, dxAccountsTradingAccessEnabled) {
      state.dxAccountsTradingAccessEnabled = dxAccountsTradingAccessEnabled
    },
    setSaltedgeDepositModalMessage (state, saltedgeDepositModalMessage) {
      state.saltedgeDepositModalMessage = saltedgeDepositModalMessage ? JSON.parse(saltedgeDepositModalMessage) : ''
    },
    setBankAccountsRejectedDepositModalMessage (state, bankAccountsRejectedDepositModalMessage) {
      state.bankAccountsRejectedDepositModalMessage = bankAccountsRejectedDepositModalMessage ? JSON.parse(bankAccountsRejectedDepositModalMessage) : ''
    }
  },
  getters: {
    user ({user}) {
      return user
    },
    name ({name}) {
      return name
    },
    email ({email}) {
      return email
    },
    middlewareActive ({middlewareActive}) {
      return middlewareActive
    },
    username ({username}) {
      return username
    },
    firstName ({firstName}) {
      return firstName
    },
    lastName ({lastName}) {
      return lastName
    },
    cui ({cui}) {
      return cui
    },
    type ({type}) {
      return type
    },
    companyName ({companyName}) {
      return companyName
    },
    maritalStatus ({maritalStatus}) {
      return maritalStatus
    },
    phone ({phone}) {
      return phone
    },
    gdprAccord ({gdprAccord}) {
      return gdprAccord
    },
    marketingAccord ({marketingAccord}) {
      return marketingAccord
    },
    isLogged ({isLogged}) {
      return isLogged
    },
    token ({token}) {
      return token
    },
    jwt ({jwt}) {
      return jwt
    },
    twoFactor ({twoFactor}) {
      return twoFactor
    },
    hasAccessInPortal (state) {
      if (!state.isLogged) {
        return false
      }

      return state.user.has_access_in_portal
    },
    unreadNotifications ({unreadNotifications}) {
      return unreadNotifications
    },
    unreadUserNotifications ({unreadUserNotifications}) {
      return unreadUserNotifications
    },
    unreadGeneralNotifications ({unreadGeneralNotifications}) {
      return unreadGeneralNotifications
    },
    maintenanceModeAnnouncement ({maintenanceModeAnnouncement}) {
      return maintenanceModeAnnouncement
    },
    banner ({banner}) {
      return banner
    },
    activeSaltedge ({activeSaltedge}) {
      return activeSaltedge && activeSaltedge.toLowerCase() === 'true'
    },
    hasAttachments ({hasAttachments}) {
      return hasAttachments
    },
    typeChat ({typeChat}) {
      return typeChat
    },
    maximumNumberOfPies ({maximumNumberOfPies}) {
      return maximumNumberOfPies
    },
    minimumValueOfOrder ({minimumValueOfOrder}) {
      return minimumValueOfOrder
    },
    maximumInstrumentsOfPie ({maximumInstrumentsOfPie}) {
      return maximumInstrumentsOfPie
    },
    minimumInstrumentsOfPie ({minimumInstrumentsOfPie}) {
      return minimumInstrumentsOfPie
    },
    userIsApproved (state) {
      return state.user && state.user.status === 'approved'
    },
    userIsStaff (state) {
      return state.user && state.user.client_type.toLowerCase() === 'staff'
    },
    userIsRelevant (state) {
      return state.user && state.user.client_type.toLowerCase() === 'relevant'
    },
    dxAccountsTradingAccessEnabled (state) {
      return state.dxAccountsTradingAccessEnabled
    },
    saltedgeDepositModalMessage (state) {
      return state.saltedgeDepositModalMessage
    },
    bankAccountsRejectedDepositModalMessage (state) {
      return state.bankAccountsRejectedDepositModalMessage
    }
  },
  actions: {
    resetState (ctx) {
      ctx.commit('user/resetState')
    },
    async setLoggedId (ctx, payload) {
      ctx.commit('setLoggedId', payload.loggedIn)

      if (payload.has2FAConfirmed) {
        ctx.dispatch('fetch')
      }
    },

    setEmail (ctx, payload) {
      ctx.commit('setEmail', payload)
    },

    setMiddlewareActive (ctx, payload) {
      ctx.commit('setMiddlewareActive', payload)
    },

    setMaintenanceModeAnnouncement (ctx, payload) {
      ctx.commit('setMaintenanceModeAnnouncement', payload)
    },
    setActiveSaltedge (ctx, payload) {
      ctx.commit('setActiveSaltedge', payload)
    },

    setUsername (ctx, payload) {
      ctx.commit('setUsername', payload)
    },

    setFirstName (ctx, payload) {
      ctx.commit('setFirstName', payload)
    },

    setLastName (ctx, payload) {
      ctx.commit('setLastName', payload)
    },

    setCui (ctx, payload) {
      ctx.commit('setCui', payload)
    },

    setType (ctx, payload) {
      ctx.commit('setType', payload)
    },

    setCompanyName (ctx, payload) {
      ctx.commit('setCompanyName', payload)
    },

    setMaritalStatus (ctx, payload) {
      ctx.commit('setMaritalStatus', payload)
    },

    setPhone (ctx, payload) {
      ctx.commit('setPhone', payload)
    },

    setGdprAccord (ctx, payload) {
      ctx.commit('setGdprAccord', payload)
    },

    setMarketingAccord (ctx, payload) {
      ctx.commit('setMarketingAccord', payload)
    },

    setToken (ctx, payload) {
      ctx.commit('setToken', payload)
    },

    setJwt (ctx, jwt) {
      ctx.commit('setJwt', jwt)
    },

    async login (ctx, payload) {
      let login = await UserService.login(payload.username, payload.password, payload.device ? payload.device : null)

      if (login.status === 200) {
        ctx.commit('setToken', login.data.data.token)
        ctx.commit('setJwt', login.data.data.jwt)
        ctx.commit('setLoggedId', !login.data.data.two_factor)
        ctx.commit('setTwoFactor', login.data.data.two_factor)

        if (!login.data.data.two_factor) {
          ctx.dispatch('fetch')
        }
      }

      return login
    },

    async loginFromJwt (ctx, payload) {
      let loginFromJwt = await UserService.loginFromJwt(payload)

      if (loginFromJwt.status === 200) {
        ctx.commit('setToken', loginFromJwt.data.data.token)
        ctx.commit('setLoggedId', !loginFromJwt.data.data.two_factor)
        ctx.commit('setTwoFactor', loginFromJwt.data.data.two_factor)

        if (!loginFromJwt.data.data.two_factor) {
          let user = await UserService.getUserData()

          ctx.commit('setUser', user.data.data.user)
        }
      }

      return loginFromJwt
    },

    async sendRecoveryLink (ctx, payload) {
      return UserService.sendRecoveryLink(payload)
    },

    async recoverPassword (ctx, payload) {
      return UserService.recoverPassword(payload)
    },

    async logout (ctx, onlyEraseSession = false) {
      if (onlyEraseSession) {
        ctx.commit('setToken', '')
        ctx.commit('setUser', {})
        ctx.commit('setLoggedId', false)
        ctx.commit('setTwoFactor', TWO_FA_TYPE_NOT_NEEDED)
        ctx.commit('setGdprAccord', false)

        return
      }

      let logout = await UserService.logout()

      if (logout.status === 200) {
        ctx.commit('setToken', '')
        ctx.commit('setUser', {})
        ctx.commit('setLoggedId', false)
        ctx.commit('setTwoFactor', TWO_FA_TYPE_NOT_NEEDED)
        ctx.commit('setGdprAccord', false)
      }
    },

    async resetPassword (ctx, payload) {
      return UserService.resetPassword(payload)
    },

    async forceResetPassword (ctx, payload) {
      return UserService.forceResetPassword(payload)
    },

    async register (ctx, payload) {
      let register = await UserService.register(payload)

      if (register.status === 200) {
        ctx.commit('setToken', register.data.data.token)
      }

      return register
    },

    async verifyCode (ctx, payload) {
      return UserService.verifyCode(payload)
    },

    async resendCode () {
      return UserService.resendCode()
    },

    async getQrCode (ctx) {
      return UserService.getQrCode()
    },

    async challenge (ctx, payload) {
      return UserService.challenge(payload)
    },
    async enableTwoFactor (ctx, payload) {
      let twoFactor = await UserService.enableTwoFactor(payload)

      if (twoFactor.status === 200) {
        ctx.commit('setTwoFactor', twoFactor.data.data.two_factor)
      }

      return twoFactor
    },
    async createQR (ctx) {
      return UserService.createQR()
    },
    async confirm (ctx, payload) {
      return UserService.confirm(payload)
    },
    async disableTwoFactor (ctx) {
      let twoFactor = await UserService.disableTwoFactor()
      if (twoFactor.status === 200) {
        ctx.commit('setTwoFactor', TWO_FA_TYPE_NOT_NEEDED)
      }
      return twoFactor
    },
    async confirmPassword (ctx, payload) {
      return UserService.confirmPassword(payload)
    },
    async verifySmsCode (ctx, payload) {
      return UserService.verifySmsCode(payload)
    },
    async generateSmscode (ctx, payload) {
      return UserService.generateSmscode(payload)
    },
    async getUserData (payload) {
      return UserService.getUserData(payload.id)
    },
    async getUserAddressData (payload) {
      return UserService.getUserAddressData(payload.id)
    },
    async changeAddress (ctx, payload) {
      return AddressService.changeAddress(payload)
    },
    async getUnreadNotifications (ctx) {
      if (!ctx.state.isLogged) {
        return
      }

      let unreadNotifications = await NotificationsService.getUnreadNotifications()

      if (!unreadNotifications) {
        return
      }

      let unreadUserNotifications = unreadNotifications.data.data.unread_user_notifications
      let maintenanceModeAnnouncement = unreadNotifications.data.data.variables.maintenance_announcement
      let banner = unreadNotifications.data.data.variables.portal_banner
      let activeSaltEdge = unreadNotifications.data.data.variables.fe_deposits_saltedge_active
      let unreadGeneralNotifications = unreadNotifications.data.data.unread_general_notifications

      let hasAttachments = unreadNotifications.data.data.has_attachments

      let chatType = unreadNotifications.data.data.variables.active_chat_type_computed

      let maximumNumberOfPies = unreadNotifications.data.data.variables.pies_accounts_limit
      let minimumValueOfOrder = unreadNotifications.data.data.variables.pies_minimum_order_value

      let maximumInstrumentsOfPie = unreadNotifications.data.data.variables.pies_max_instruments_limit
      let minimumInstrumentsOfPie = unreadNotifications.data.data.variables.pies_min_instruments_limit

      let dxAccountsTradingAccessEnabled = unreadNotifications.data.data.variables.dx_accounts_trading_access_enabled !== '0'
      let saltedgeDepositModalMessage = unreadNotifications.data.data.variables.saltedge_deposit_modal_message
      let bankAccountsRejectedDepositModalMessage = unreadNotifications.data.data.variables.bank_accounts_rejected_deposit_modal_message
      let middlewareActive = !!unreadNotifications.data.data.variables.middleware_active

      unreadNotifications = unreadNotifications.data.data.unread_notifications

      ctx.commit('setMaintenanceModeAnnouncement', maintenanceModeAnnouncement)
      ctx.commit('setBanner', banner)
      ctx.commit('setMiddlewareActive', middlewareActive)
      ctx.commit('setActiveSaltedge', activeSaltEdge)
      ctx.commit('setUnreadNotifications', unreadNotifications)
      ctx.commit('setUserUnreadNotifications', unreadUserNotifications)
      ctx.commit('setGeneralUnreadNotifications', unreadGeneralNotifications)
      ctx.commit('setHasAttachments', hasAttachments)
      ctx.commit('setTypeChat', chatType)
      ctx.commit('setMaximumNumberOfPies', maximumNumberOfPies)
      ctx.commit('setMinimumValueOfOrder', minimumValueOfOrder)
      ctx.commit('setMaximumInstrumentsOfPie', maximumInstrumentsOfPie)
      ctx.commit('setMinimumInstrumentsOfPie', minimumInstrumentsOfPie)
      ctx.commit('setDxAccountsTradingAccessEnabled', dxAccountsTradingAccessEnabled)
      ctx.commit('setSaltedgeDepositModalMessage', saltedgeDepositModalMessage)
      ctx.commit('setBankAccountsRejectedDepositModalMessage', bankAccountsRejectedDepositModalMessage)
    },

    async fetch (ctx) {
      const user = await UserService.getUserData()

      if (!user) {
        return null
      }

      ctx.commit('setUser', user.data.data.user)

      return user
    },

    async updateUser (ctx, payload) {
      await UserService.save(payload).then((response) => {
        if (response.status === 200) {
          ctx.commit('setUser', response.data.data.user)
        }
      })
    }

  }
}
