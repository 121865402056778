<template>
  <div v-if="Object.keys(accounts).length">
    <Transition name="slide-fade">
      <table class="assets-table">
        <colgroup>
          <col span="1" style="width: 10%;">
          <col span="1" style="width: 22.5%;">
          <col span="1" style="width: 22.5%;">
          <col span="1" style="width: 22.5%;">
          <col span="1" style="width: 22.5%;">
        </colgroup>
        <thead>
          <tr>
            <th>{{ $t('deposit_withdrawal.multi_account.table.id') }}</th>
            <th>{{ $t('deposit_withdrawal.multi_account.table.market') }}</th>
            <th>{{ $t('deposit_withdrawal.multi_account.table.alias') }}</th>
            <th>{{ $t('deposit_withdrawal.multi_account.table.currency') }}</th>
            <th>{{ $t('deposit_withdrawal.multi_account.table.account') }}</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(account, key) in accounts" :key="key" class="row">
            <td>{{ key + 1 }}</td>
            <td>{{ account.source === 'arenaxt' ? 'BVB' : 'GLOBAL'}}</td>
            <td>{{ account.alias }}</td>
            <td>{{ account.currency }}</td>
            <td>{{ account.code }}</td>
          </tr>
        </tbody>
      </table>
    </Transition>
  </div>
</template>

<script>

export default {
  name: 'MultiAccountTableDesktop',
  props: {
    accounts: {
      type: Array,
      default: () => {
        return []
      }
    }
  }
}

</script>
