<template>
<div class="icon-color" :class="{'small': small, 'smallest' : smallest}">
  <div class="circle center" :class="color">
    <div class="icon" :class="[positions]"><img :src="!isIconFromBe ? iconSrc : icon" alt=""></div>
  </div>
</div>
</template>

<script>
export default {
  name: 'IconColor',
  props: {
    // left, right, top, bottom
    positions: {
      type: Array,
      default: () => []
    },
    // svg name from assets/img/icons eg. yen
    icon: {
      type: String,
      default: ''
    },
    // blue, pink, purple, yellow, green, orange
    color: {
      type: String,
      default: ''
    },
    small: {
      type: Boolean,
      default: false
    },
    smallest: {
      type: Boolean,
      default: false
    },
    isIconFromBe: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    iconSrc () {
      return require(`@/assets/img/icons/${this.icon}.svg`)
    }
  }
}
</script>
