<template>
  <div>
    <div v-if="loaded">
      <PopUpModal
        class="h-fit"
        :modal-fit-content="true"
        :modal-opened="steps.showNamePieModal"
        :modal-title="$t('deposit_withdrawal.auto_invest.add_pie')"
        @closeModal="clearData()">
        <div class="flex flex-col">
          <InputText
            id="'pie-name'"
            :label="$t('deposit_withdrawal.auto_invest.name_pie')"
            v-model="newPie.account_alias"
          />

          <div v-if="validations.nameLength" class="error-color text-xs">{{validations.nameLength}}</div>

          <div class="flex justify-center mb-10 lg:mb-auto mt-10 lg:mt-20">
            <Button
              @call="clearData"
              extra-classes="px-5 mt-3"
              :text="$t('general.general.cancel')"
              :type="'btn-dark-outline'"
            />

            <Button
              @call="nextStep('showInstrumentsForPie', true)"
              :disabled="!newPie.account_alias || !!validations.nameLength.length"
              extra-classes="px-5 mt-3 ml-4"
              :text="$t('deposit_withdrawal.multi_account.add')"
              :type="'btn-dark-background'"
            />
          </div>
        </div>
      </PopUpModal>

      <PopUpModal
        v-if="isDesktop"
        class="h-fit w-big"
        :modal-fit-content="true"
        :smallest-padding="true"
        :modal-opened="steps.showInstrumentsForPie"
        @closeModal="clearData()">
        <div class="flex flex-col">
          <InstrumentsComponent :pie="newPie" @nextStep="nextStep('showTargetsPie')"/>
        </div>
      </PopUpModal>

      <InstrumentsComponentMobile v-if="!isDesktop && steps.showInstrumentsForPie" :pie="newPie" @nextStep="nextStep('showTargetsPie')" @closeInstruments="clearData()"/>

      <PopUpModal
        v-if="isDesktop"
        class="h-fit w-big"
        :small-padding="true"
        :modal-fit-content="true"
        :centered-title="false"
        :modal-title="$t('deposit_withdrawal.auto_invest.targets.title')"
        :modal-opened="steps.showTargetsPie"
        @closeModal="clearData()">
        <div class="flex flex-col">
          <TargetsPiesComponent @lastStep="goToLastStep()" @addInstruments="nextStep('showInstrumentsForPie')"/>
        </div>
      </PopUpModal>

      <TargetsPiesComponentMobile v-if="!isDesktop && steps.showTargetsPie" @lastStep="goToLastStep()" @addInstruments="nextStep('showInstrumentsForPie')"/>

    </div>
    <div v-else>
      <PulseLoader/>
    </div>
  </div>

</template>

<script>
import Button from '@/components/subcomponents/Button.vue'
import InputText from '@/components/subcomponents/InputText.vue'
import PopUpModal from '@/components/subcomponents/PopUpModal.vue'
import {mapActions, mapGetters} from 'vuex'
import InstrumentsComponent from '@/components/subcomponents/auto-invest/InstrumentsComponent.vue'
import TargetsPiesComponent from '@/components/subcomponents/auto-invest/TargetsPiesComponent.vue'
import InstrumentsComponentMobile from '@/components/subcomponents/auto-invest/InstrumentsComponentMobile.vue'
import TargetsPiesComponentMobile from '@/components/subcomponents/auto-invest/TargetsPiesComponentMobile.vue'
import piesService from '@/services/deposit-withdrawal/piesService'

export default {
  name: 'SavePie',
  components: {
    TargetsPiesComponentMobile,
    InstrumentsComponentMobile,
    TargetsPiesComponent,
    InstrumentsComponent,
    PopUpModal,
    InputText,
    Button
  },
  props: {
    step: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      pie: {},
      newPie: {
        account_alias: '',
        positions: []
      },
      steps: {
        showNamePieModal: false,
        showInstrumentsForPie: false,
        showTargetsPie: false
      },
      loaded: false,
      validations: {
        nameLength: ''
      }
    }
  },
  computed: {
    ...mapGetters({
      pieNew: 'pies/newPie',
      pies: 'pies/pies',
      pieId: 'pies/pieId',
      screenDevice: 'layout/screenDevice'
    }),

    isDesktop () {
      return this.screenDevice === this.$screenDeviceConstants.DESKTOP
    }
  },
  async mounted () {
    if (this.pieId) {
      await this.getPie()
    }

    if (this.step === '') {
      this.$emit('setMobileStep', 'showNamePieModal')
    }

    if (this.step === 'showInstrumentsForPie') {
      await this.setNewPieInstruments()
      this.nextStep('showInstrumentsForPie')
    }

    this.loaded = true
  },
  methods: {
    ...mapActions({
      setNewPie: 'pies/setNewPie'
    }),

    setNewPieInstruments () {
      if (this.pie) {
        this.newPie = this.pie
      }
    },

    clearData () {
      this.$emit('clearData')
      this.$emit('closeModal')
    },

    closePopUp () {
      this.$emit('closeModal')
    },
    nextStep (stepKey, setNewPie = false) {
      if (setNewPie) {
        this.setNewPie(this.newPie)
      }

      this.steps[stepKey] = true
      this.$emit('setMobileStep', stepKey)
      Object.keys(this.steps).forEach(key => {
        if (key !== stepKey) {
          this.steps[key] = false
        }
      })
    },
    goToLastStep () {
      this.closePopUp()
      this.$emit('lastStep')
    },
    async getPie () {
      await piesService.getPie(this.pieId).then(response => {
        this.pie = response.data.data.pie
      }).catch(error => {
        console.log(error)
      })
    }
  },
  watch: {
    step: {
      async handler (step) {
        this.nextStep(step)
        if (this.pieId) {
          await this.getPie()
        }
        if (this.pie) {
          this.newPie = this.pie
        }
      },
      deep: true
    },
    'newPie.account_alias' (val) {
      if (val && val.length > 20) {
        this.validations.nameLength = this.$i18n.t('deposit_withdrawal.auto_invest.name_pie_max_length')
      } else {
        this.validations.nameLength = ''
      }
    }
  }
}
</script>
