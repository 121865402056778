<template>
  <div>
    <div @mouseover='arrow = "icons/arrow-right-circle-filled"' @mouseleave='arrow = "icons/arrow-right-circle-lined"'
         class="icon-box" :class="[ borderColor, { 'cursor-pointer': hasLink } ]"
         @click="goToRoute()"
    >
      <slot name="top"></slot>

      <div class="center">
        <icon-color :color="iconColor" :icon="icon" :positions="iconPositions"/>

        <div class="text-container">
          <div class="additional-text">{{ additionalText }}</div>
          <span class="text-xl">{{ text }}</span>
        </div>

        <slot name="body"></slot>

        <div v-if="!underlinedText && hasLink && !underlineLink" class="arrow">
          <img :src="require(`@/assets/img/${arrow}.svg`)" alt="icon"/>
        </div>
      </div>

      <slot name="bottom"></slot>
    </div>
  </div>
</template>

<script>
import IconColor from './IconColor.vue'

export default {
  name: 'IconBox',
  components: {IconColor},
  props: {
    routeName: {
      type: String,
      default: ''
    },
    href: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    additionalText: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: 'green'
    },
    iconColor: {
      type: String,
      default: ''
    },
    // left, right, top, bottom
    iconPositions: {
      type: Array,
      default: () => []
    },
    iconLink: {
      type: Boolean,
      default: true
    },
    underlinedText: {
      type: Boolean,
      default: false
    },
    borderColor: {
      type: String,
      default: 'grey'
    },
    underlineLink: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      arrow: 'icons/arrow-right-circle-lined'
    }
  },
  computed: {
    hasLink () {
      return (!!this.routeName || !!this.href)
    }
  },
  methods: {
    goToRoute () {
      if (this.routeName) {
        this.$router.push({name: this.routeName})
      }

      if (this.href) {
        window.open(this.href, '_blank')
      }
    }
  }
}
</script>

<style scoped>

</style>
