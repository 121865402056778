<template>
  <div>
    <Card :card-classes="'margin-bottom-big mobile-card-portal h-full relative'" :title-small="true"
          class="personal-info" v-if="!isMobile">
      <div class="desktop-flex mb-5">
        <div class="w-1/2 mt-5 flex flex-col">
          <div class="font-bold text-[20px] mb-2.5">{{ $t('settings.general.domicile_address') }}</div>
          <div class="font-bold">{{ address && address.county ? address.county : '' }}</div>
          <div class="">{{ getLongLabelForAddress(address) }}</div>
          <Button :disabled="!editCi" class="mt-5" @call="setEditDomicileAddress" :text="$t('settings.general.edit')"></Button>
        </div>
        <div>
          <img :src="require('@/assets/img/address_image.png')">
        </div>
      </div>
      <div class="desktop-flex">
        <div class="w-1/2 flex flex-col">
          <div class="font-bold text-[20px] mb-2.5">{{ $t('settings.general.correspondence_address') }}</div>
          <div class="font-bold">{{ fullCorrespondenceAddress && fullCorrespondenceAddress.county ? fullCorrespondenceAddress.county : '' }}</div>
          <div class="grow">{{ getLongLabelForAddress(fullCorrespondenceAddress) }}</div>
          <Button class="mt-5" @call="setEditCorrespondenceAddress" :type="'btn-secondary'" :text="$t('settings.general.edit')"></Button>
        </div>
        <div class="divider"></div>
        <div class="w-1/2 flex flex-col">
          <div class="font-bold text-[20px] mb-2.5">{{ $t('settings.general.permanent_address') }}</div>
          <div class="font-bold">{{ fullPermanentAddress && fullPermanentAddress.county ? fullPermanentAddress.county : '' }}</div>
          <div class="grow">{{ getLongLabelForAddress(fullPermanentAddress) }}</div>
          <Button class="mt-5" @call="setEditPermanentAddress" :type="'btn-secondary'" :text="$t('settings.general.edit')"></Button>
        </div>
      </div>

      <Button
        v-if="anniversary && anniversary <= today && isDesktop"
        class="absolute bottom-0 left-1/2 w-full"
        :extra-classes="'anniversary-button'"
        :text="$t('general.menu.update_user_data')"
        :type="'btn-secondary'"
        @call="updateData()"
      />
    </Card>

    <Card :card-classes="'margin-bottom-big mobile-card-portal mt-8'" :title-small="true"
          class="personal-info" v-if="isMobile">
      <div class="flex flex-col items-center">
        <div>
          <img :src="require('@/assets/img/address_image.png')">
        </div>
        <div class="mt-8 px-6 pb-7">
          <div class="font-bold text-[20px] mb-2.5">{{ $t('settings.general.domicile_address') }}</div>
          <div class="font-bold">{{ address && address.county ? address.county : '' }}</div>
          <div class="">{{ getLongLabelForAddress(address) }}</div>
          <Button :disabled="!editCi" class="mt-5" @call="setEditDomicileAddress" :text="$t('settings.general.edit')"></Button>
        </div>
      </div>
    </Card>

    <Card :card-classes="'margin-bottom-big mobile-card-portal mt-8'" :title-small="true"
          class="personal-info" v-if="isMobile">
      <div class="flex flex-col items-center px-6 py-7">
        <div>
          <div class="font-bold text-[20px] mb-2.5">{{ $t('settings.general.correspondence_address') }} :</div>
          <div class="font-bold">{{ fullCorrespondenceAddress && fullCorrespondenceAddress.county ? fullCorrespondenceAddress.county : '' }}</div>
          <div class="grow">{{ getLongLabelForAddress(fullCorrespondenceAddress) }}</div>
          <Button class="mt-5" @call="setEditCorrespondenceAddress" :type="'btn-secondary'" :text="$t('settings.general.edit')"></Button>
        </div>
        <div class="divider"></div>
        <div>
          <div class="font-bold text-[20px] mb-2.5">{{ $t('settings.general.permanent_address') }}</div>
          <div class="font-bold">{{ fullPermanentAddress && fullPermanentAddress.county ? fullPermanentAddress.county : '' }}</div>
          <div class="grow">{{ getLongLabelForAddress(fullPermanentAddress) }}</div>
          <Button class="mt-5" @call="setEditPermanentAddress" :type="'btn-secondary'" :text="$t('settings.general.edit')"></Button>
        </div>
      </div>
    </Card>

    <PopUpModal
      class="h-full w-full"
      :modal-title="$t('settings.general.correspondence_address')"
      :modal-opened="editCorrespondenceAddress"
      @closeModal="closeModal('editCorrespondenceAddress')">

      <FullAddress
        :pre-selected-address="fullCorrespondenceAddress"
        @fullAddress="setFullCorrespondenceAddress"/>
    </PopUpModal>

    <PopUpModal
      class="h-full w-full"
      :modal-title="$t('settings.general.permanent_address')"
      :modal-opened="editPermanentAddress"
      @closeModal="closeModal('editPermanentAddress')">

      <FullAddress
        :pre-selected-address="fullPermanentAddress"
        @fullAddress="setFullPermanentAddress"/>
    </PopUpModal>
  </div>
</template>

<script>
import Button from '../../../subcomponents/Button.vue'
import Card from '../../../subcomponents/Card.vue'
import {mapActions, mapGetters} from 'vuex'
import PopUpModal from '../../../subcomponents/PopUpModal.vue'
import FullAddress from '../../../subcomponents/FullAddress.vue'
import FullAddressMixin from '../../../../mixins/FullAddress'
import AnniversaryMethodsMixin from '../../../../mixins/AnniversaryMethods'

export default {
  name: 'AddressesCards',
  components: {FullAddress, PopUpModal, Card, Button},
  mixins: [FullAddressMixin, AnniversaryMethodsMixin],
  computed: {
    ...mapGetters({
      screenDevice: 'layout/screenDevice',
      user: 'user/user'
    }),
    isMobile () {
      return this.screenDevice === this.$screenDeviceConstants.MOBILE
    },
    isDesktop () {
      return this.screenDevice === this.$screenDeviceConstants.DESKTOP
    }
  },
  props: {
    editCi: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      validationAddress: '',
      editCorrespondenceAddress: false,
      editPermanentAddress: false,
      editDomicileAddress: false,
      fullCorrespondenceAddress: {
        country: '',
        county: '',
        locality: '',
        street: '',
        number: '',
        building: '',
        floor: '',
        apartment: '',
        staircase: '',
        zip_code: '',
        country_code: '',
        county_code: ''
      },
      fullPermanentAddress: {
        country: '',
        county: '',
        locality: '',
        street: '',
        number: '',
        building: '',
        floor: '',
        apartment: '',
        staircase: '',
        zip_code: '',
        country_code: '',
        county_code: ''
      },
      address: null
    }
  },
  mounted () {
    this.setData()
  },
  methods: {
    ...mapActions({
      changeAddress: 'user/changeAddress',
      getUserAddressData: 'user/getUserAddressData'
    }),
    async setData () {
      this.validation = null

      let userAddressDataDb = await this.getUserAddressData()

      if (userAddressDataDb.status === 200) {
        let addresses = userAddressDataDb.data.data.addresses

        addresses.forEach((address) => {
          if (address.type === 'permanent') {
            this.fullPermanentAddress = address
          }

          if (address.type === 'correspondence') {
            this.fullCorrespondenceAddress = address
          }

          if (address.type === 'domicile') {
            this.address = address
          }
        })
      }
      this.loading = false
    },

    async saveAddress (type, address) {
      if (this.validate(address)) {
        let response = await this.changeAddress({
          type: type,
          address: JSON.stringify(address)
        })

        if (response.status === 200) {
          await this.setData()
          this.validationAddress = ''
        }
      }
    },

    validate (address) {
      if (address.country === '' || (!address.county && address.country.code === 'RO') || !address.locality || !address.street) {
        this.validationAddress = `<div>${this.$i18n.t('general.validations.address_partial_error')}<ul class="custom-ul">`

        if (address.country === '') {
          this.validationAddress += `<li>${this.$i18n.t('general.validations.address_fields.country')}</li>`
        }

        if (!address.county && address.country.code === 'RO') {
          this.validationAddress += `<li>${this.$i18n.t('general.validations.address_fields.county')}</li>`
        }

        if (!address.locality) {
          this.validationAddress += `<li>${this.$i18n.t('general.validations.address_fields.locality')}</li>`
        }

        if (!address.street) {
          this.validationAddress += `<li>${this.$i18n.t('general.validations.address_fields.street')}</li>`
        }

        this.validationAddress += `</ul></div>`

        return false
      }

      return true
    },
    setEditCorrespondenceAddress () {
      this.editCorrespondenceAddress = true
      this.editPermanentAddress = false
      this.editDomicileAddress = false
    },
    setEditPermanentAddress () {
      this.editCorrespondenceAddress = false
      this.editPermanentAddress = true
      this.editDomicileAddress = false
    },
    setEditDomicileAddress () {
      this.editCorrespondenceAddress = false
      this.editPermanentAddress = false
      this.$emit('editDomicileAddress')
    },
    setFullPermanentAddress (address) {
      this.fullPermanentAddress = address
      this.editPermanentAddress = false
      this.saveAddress('permanent', this.fullPermanentAddress)
    },
    setFullCorrespondenceAddress (address) {
      this.fullCorrespondenceAddress = address
      this.editCorrespondenceAddress = false
      this.saveAddress('correspondence', this.fullCorrespondenceAddress)
    },
    closeModal (modalToClose) {
      this.setData()
      this[modalToClose] = false
    }
  },
  watch: {
    'user.domicile_address': {
      handler () {
        this.setData()
      },
      deep: true
    }
  }
}
</script>

<style scoped>

</style>
