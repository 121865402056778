<template>
  <div>
    <button
      :disabled="getStatus"
      :class="[type, extraClasses, { active: active }, { 'w-full': w100 }, { 'w-50': w50 }, {'disabled pointer-events-none': getStatus}, { 'loading-button': loading }]"
      class="
        btn
        cursor-pointer
        text-white
        font-medium
        leading-tight
        round-button
        transition
        duration-150
        ease-in-out" type="button" @click="actionClick()">
      <img :class="iconPosition" :src="getIcon" v-if="iconPosition === 'left'" alt="icon">
      <img :class="iconPosition" :src="getIcon" v-if="iconPosition === 'center'" alt="icon">
      <span class="font-extrabold">
        {{ text }}
      </span>
      <img :class="iconPosition" :src="getIcon" v-if="iconPosition === 'right'" alt="icon">
    </button>
  </div>
</template>

<script>
export default {
  name: 'Button',
  props: {
    type: {
      type: String,
      default: 'btn-primary'
    },
    extraClasses: {
      type: String,
      default: ''
    },
    routeName: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    active: {
      type: Boolean,
      default: false
    },
    w100: {
      type: Boolean,
      default: false
    },
    w50: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: ''
    },
    iconPosition: {
      type: String,
      default: ''
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    getIcon () {
      if (this.icon) {
        return require(`@/assets/img/${this.icon}.svg`)
      }
    },
    getStatus () {
      return this.disabled || this.loading
    }
  },
  methods: {
    actionClick () {
      if (this.getStatus) {
        return
      }

      if (this.routeName) {
        this.$router.push({ name: this.routeName })
      } else {
        this.$emit('call')
      }
    }
  }
}
</script>

<style scoped>

</style>
