<template>
  <div class="portofolio-transfer">
    <general-layout>
      <template v-slot:title>
        <vue-title :title="$t('general.meta_titles.instruments_transfer')"></vue-title>
      </template>

      <template v-slot:tabs>
        <Tabs :show-id-title="false" />
      </template>

      <template v-slot:body>
        <div class="desktop-flex gap-8">
          <div class="w-full">
            <PulseLoader class="w-fit m-auto p-10 justify-center flex" v-if="loading"/>

            <Transition-Group v-if="!loading" appear name="fade">
              <Accordion
                :card-classes="'mobile-card-portal'"
                class="mb-4"
                icon="transfer-portfolio"
                icon-color="green"
                :icon-position="['left']"
                :key="'to'"
              >
                <template v-slot:title>{{ $t('deposit_withdrawal.instruments_transfer.to_investimental') }}</template>

                <template v-slot:body>
                  <div
                    class="font-bold mb-4"
                    v-html="$t('deposit_withdrawal.instruments_transfer.to.first_case.title')"
                  ></div>

                  <div
                    class="mb-3"
                    v-html="$t('deposit_withdrawal.instruments_transfer.to.first_case.first_step')"
                  ></div>

                  <div
                    class="mb-3"
                    v-html="$t('deposit_withdrawal.instruments_transfer.to.first_case.second_step')"
                  ></div>

                  <div
                    class="mb-6 mt-4"
                    v-html="$t('deposit_withdrawal.instruments_transfer.to.first_case.third_step')"
                  ></div>

                  <UploadDocuments
                    ref="broker"
                    :type="'to-us'"
                    :selected-document-type="documentType"
                    :component="'instruments_transfer'"
                    :selected-document-category="documentCategory"
                    :all-document-categories="documentCategories"
                    :upload-multiple-files="true"
                    :class="{ 'disabled pointer-events-none': files['broker'].length >= 25 }"
                    @documentUploaded="handleFileUpload($event,'broker')"
                    @documentsUploaded="handleFileUpload($event,'broker')"
                  />

                  <div
                    v-for="(brokerFile, index) in files['broker']"
                    :key="'broker' + index + validationCounter['broker']"
                    v-if="brokerFile.name"
                    class="flex flex-col"
                  >
                    <div class="flex items-center mt-4">
                      {{brokerFile.name}}

                      <div @click="deleteFile(index, 'broker')" class="ml-4">
                        <img src="@/assets/img/icons/close.svg" alt="close">
                      </div>
                    </div>

                    <Message
                      v-if="validation['sizeDocument']['broker'][index]"
                      :message="validation['sizeDocument']['broker'][index]"
                      :type="'error'"
                    />

                    <Message
                      v-if="validation['typeDocument']['broker'][index]"
                      :message="validation['typeDocument']['broker'][index]"
                      :type="'error'"
                    />
                  </div>

                  <div
                    class="mb-6 mt-4"
                    v-html="$t('deposit_withdrawal.instruments_transfer.to.first_case.forth_step')"
                  ></div>

                  <Message
                    v-if="uploadSuccessful.broker"
                    :message="$t('documents.general.upload_successful')"
                    :type="'success'"
                    class="mb-5"
                  />

                  <div @click="saveDocuments('broker', 'to-us')" class="mb-6" :class="{ 'pointer-events-none': !files['broker'].length || loadingDocumentUpload }">
                    <Button
                      :loading="loadingDocumentUpload"
                      :class="{ 'disabled disabled-transparent': !files['broker'].length }"
                      :text="$t('documents.general.send_request')"
                      :type="'btn-primary'"
                    />
                  </div>

                  <div
                    class="font-bold mb-4"
                    v-html="$t('deposit_withdrawal.instruments_transfer.to.second_case.title')"
                  ></div>

                  <div
                    class="mb-3"
                    v-html="$t('deposit_withdrawal.instruments_transfer.to.second_case.first_step')"
                  ></div>

                  <div
                    v-if="user.type !== 'person'"
                    class="mb-3"
                    v-html="$t('deposit_withdrawal.instruments_transfer.to.second_case.observation_pj')"
                  ></div>

                  <div
                    v-else
                    class="mb-3"
                    v-html="$t('deposit_withdrawal.instruments_transfer.to.second_case.observation_pf')"
                  ></div>

                  <UploadDocuments
                    ref="centralDeposit"
                    :type="'to-us'"
                    :selected-document-type="documentType"
                    :component="'instruments_transfer'"
                    :selected-document-category="documentCategory"
                    :all-document-categories="documentCategories"
                    :upload-multiple-files="true"
                    :class="{ 'disabled pointer-events-none': files['centralDeposit'].length >= 25 }"
                    @documentUploaded="handleFileUpload($event,'centralDeposit')"
                    @documentsUploaded="handleFileUpload($event,'centralDeposit')"
                  />

                  <div
                    v-for="(centralDepositFile, index) in files['centralDeposit']"
                    :key="'centralDepositFile' + index + validationCounter['centralDeposit']"
                    v-if="centralDepositFile.name"
                    class="flex flex-col"
                  >
                    <div class="flex items-center mt-4">
                      {{centralDepositFile.name}}

                      <div @click="deleteFile(index, 'centralDeposit')" class="ml-4">
                        <img src="@/assets/img/icons/close.svg" alt="close">
                      </div>
                    </div>

                    <Message
                      v-if="validation['sizeDocument']['centralDeposit'][index]"
                      :message="validation['sizeDocument']['centralDeposit'][index]"
                      :type="'error'"
                    />

                    <Message
                      v-if="validation['typeDocument']['centralDeposit'][index]"
                      :message="validation['typeDocument']['centralDeposit'][index]"
                      :type="'error'"
                    />
                  </div>

                  <div class="mb-6 mt-4" v-html="$t('deposit_withdrawal.instruments_transfer.to.second_case.second_step')"></div>

                  <Message
                    v-if="uploadSuccessful.centralDeposit"
                    :message="$t('documents.general.upload_successful')"
                    :type="'success'"
                    class="mb-5"
                  />

                  <div @click="saveDocuments('centralDeposit', 'to-us')" :class="{ 'pointer-events-none': !files['centralDeposit'].length || loadingDocumentUpload }">
                    <Button
                      :loading="loadingDocumentUpload"
                      :class="{ 'disabled disabled-transparent': !files['centralDeposit'].length }"
                      :text="$t('documents.general.send_request')"
                      :type="'btn-primary'"
                    />
                  </div>
                </template>
              </Accordion>

              <Accordion :card-classes="'mobile-card-portal'" icon="transfer-portfolio" icon-color="green" :icon-position="['left']" :key="'from'">
                <template v-slot:title>
                  {{ $t('deposit_withdrawal.instruments_transfer.from_investimental') }}
                </template>

                <template v-slot:body>
                  <div class="mb-3" v-html="$t('deposit_withdrawal.instruments_transfer.from.first_step')"></div>
                  <div class="mb-3" v-html="$t('deposit_withdrawal.instruments_transfer.from.second_step')"></div>

                  <UploadDocuments
                    :selected-document-type="documentType"
                    :component="'instruments_transfer'"
                    :type="'from-us'"
                    :selected-document-category="documentCategory"
                    :document-categories="documentCategories"
                    ref="transferRequest"
                    :upload-multiple-files="true"
                    :class="{ 'disabled pointer-events-none': files['transferRequest'].length >= 25 }"
                    @documentUploaded="handleFileUpload($event,'transferRequest')"
                    @documentsUploaded="handleFileUpload($event,'transferRequest')"
                  />

                  <div
                    v-for="(transferRequestFile, index) in files['transferRequest']"
                    :key="'transferRequestFile' + index + validationCounter['transferRequest']"
                    v-if="transferRequestFile.name"
                    class="flex flex-col"
                  >
                    <div class="flex items-center mt-4">
                      {{transferRequestFile.name}}

                      <div @click="deleteFile(index, 'transferRequest')" class="ml-4">
                        <img src="@/assets/img/icons/close.svg" alt="close">
                      </div>
                    </div>

                    <Message
                      v-if="validation['sizeDocument']['transferRequest'][index]"
                      :message="validation['sizeDocument']['transferRequest'][index]"
                      :type="'error'"
                    />

                    <Message
                      v-if="validation['typeDocument']['transferRequest'][index]"
                      :message="validation['typeDocument']['transferRequest'][index]"
                      :type="'error'"
                    />
                  </div>

                  <Message
                    v-if="uploadSuccessful.transferRequest"
                    :message="$t('documents.general.upload_successful')"
                    :type="'success'"
                    class="mb-5"
                  />

                  <div class="mt-4" @click="saveDocuments('transferRequest', 'from-us')" :class="{ 'pointer-events-none': !files['transferRequest'].length || loadingDocumentUpload }">
                    <Button
                      :loading="loadingDocumentUpload"
                      :class="{ 'disabled disabled-transparent': !files['transferRequest'].length }"
                      :text="$t('documents.general.send_request')"
                      :type="'btn-primary'"
                    />
                  </div>
                </template>
              </Accordion>
            </Transition-Group>
          </div>

          <div class="w-full">
            <Card class="w-full"
                  :card-classes="'mobile-card-resources padding-small flex-layout blue-links'"
                  :no-title="true"
                  v-if="!isMobile"
            >
              <template v-slot:body>
                <img class="big-icon" :src="require(`@/assets/img/transfer-portfolio-right-resources.png`)" alt="transfer-portfolio">

                <div>
                  <p v-html="$t('guides.instruments_transfer.instruments_transfer_p1')"/>
                  <br>
                  <p v-html="$t('guides.instruments_transfer.instruments_transfer_p2')"/>
                </div>
              </template>
            </Card>

            <Resources :class="[isMobile ? 'mt-8' : 'mt-4']" :links="resourcesLinks" />
          </div>
        </div>
      </template>
    </general-layout>
  </div>
</template>
<script>
import GeneralLayout from '../../layout/GeneralLayout'
import Tabs from '../../layout/Tabs'
import Button from '../../subcomponents/Button'
import { mapGetters } from 'vuex'
import InputText from '../../subcomponents/InputText'
import Accordion from '../../subcomponents/Accordion'
import Card from '../../subcomponents/Card'
import IconBox from '../../subcomponents/IconBox.vue'
import Message from '../../subcomponents/Message'
import UploadDocuments from '../../subcomponents/UploadDocuments'
import DocumentsService from '../../../services/documents/documentsService'
import ApiService from '../../../services/apiService'
import Resources from '../../subcomponents/Resources.vue'
import mime from 'mime-types'

export default {
  name: 'InstrumentsTransfer',
  components: { UploadDocuments, GeneralLayout, Tabs, Button, InputText, Accordion, IconBox, Message, Card, Resources },
  data () {
    return {
      opened: 'to',
      documentType: {
        id: 3,
        label: this.$i18n.t('documents.general.other_documents'),
        value: 'others'
      },
      documentCategories: [],
      documentCategory: 'transfer-instruments',
      loading: true,
      loadingDocumentUpload: false,
      source: null,
      message: '',
      files: {
        broker: [],
        centralDeposit: [],
        transferRequest: []
      },
      uploadSuccessful: {
        broker: false,
        centralDeposit: false,
        transferRequest: false
      },
      resourcesLinks: [
        {
          label: this.$t('useful_links.instruments_transfer.transfer_to_investimental'),
          value: '/faq/cum-imi-pot-transfera-portofoliul-de-actiuni-la-investimental-2/'
        },
        {
          label: this.$t('useful_links.instruments_transfer.instruments_transfer_guide'),
          value: '/ghid/ghid-transferuri-instrumente-financiare/'
        }
      ],
      validation: {
        sizeDocument: {
          broker: [],
          centralDeposit: [],
          transferRequest: []
        },
        typeDocument: {
          broker: [],
          centralDeposit: [],
          transferRequest: []
        }
      },
      allowedDocumentTypes: ['pdf', 'doc', 'docx', 'jpg', 'jpeg', 'png'],
      validationCounter: {
        broker: 0,
        centralDeposit: 0,
        transferRequest: 0
      }
    }
  },
  computed: {
    ...mapGetters({
      user: 'user/user',
      screenDevice: 'layout/screenDevice',
      hasAccessInPortal: 'user/hasAccessInPortal',
      isLogged: 'user/isLogged'
    }),
    isMobile () {
      return this.screenDevice === this.$screenDeviceConstants.MOBILE
    }
  },
  async mounted () {
    if (this.isLogged) {
      await this.getCategory()
    }
  },
  methods: {
    async getCategory () {
      let allDBClientDocumentCategories = await DocumentsService.getClientDocumentCategories()

      this.documentCategories = allDBClientDocumentCategories.data.data.clientDocumentCategories
      this.loading = false
    },
    deleteFile (index, category) {
      this.files[category].splice(index, 1)
    },
    handleFileUpload (files, category) {
      for (let i = 0; i < files.length; i++) {
        if (this.files[category].length < 25) {
          this.files[category].push(files[i])
        }
      }

      this.validation.sizeDocument[category] = new Array(this.files[category].length).fill('')
      this.validation.typeDocument[category] = new Array(this.files[category].length).fill('')
    },
    async saveDocuments (category, destination) {
      let valid = true

      this.files[category].forEach((file, index) => {
        if (!this.validateDocument(index, file, category)) {
          valid = false
        }
      })

      this.validationCounter[category]++

      if (!valid) {
        return
      }

      this.loadingDocumentUpload = true

      let response = await ApiService.withAuth().post(
        'api/user/asset-transfers',
        {
          type: destination, files: this.files[category]
        },
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      )

      if (response.status === 200) {
        this.uploadSuccessful[category] = true
        this.files[category] = []

        setTimeout(() => {
          this.uploadSuccessful[category] = false
        }, 3000)
      }

      this.loadingDocumentUpload = false
    },
    validateDocument (index, document, category) {
      let valid = true

      if (((document.size / 1024) / 1024).toFixed(4) > 25) {
        this.validation['sizeDocument'][category][index] = this.$t('documents.general.size_too_big')

        valid = false
      }

      if (!this.validFileType(document.type, document.name)) {
        this.validation['typeDocument'][category][index] = this.$t('documents.general.type_assets_document')

        valid = false
      }

      return valid
    },
    validFileType (mimeType, name) {
      const mime = require('mime-types')
      var ext = name.substr(name.lastIndexOf('.') + 1)

      return !!(this.allowedDocumentTypes.includes(mime.extension(mimeType)) || this.allowedDocumentTypes.includes(ext))
    }
  }
}
</script>

<style scoped></style>
