import axios from 'axios'
import {store} from '@/store'
import routes from '@/router'

const url = process.env.VUE_APP_PORTAL_ENDPOINT

let http = null

const ApiService = {
  http: null,

  init: function () {
    if (http) {
      return
    }
    http = axios.create({
      baseURL: url,
      headers: {
        'Content-Type': 'application/json'
      },
      withCredentials: true
    })

    http.interceptors.request.use(async function (config) {
      if (['post', 'put', 'patch'].includes(config.method)) {
        await http.get('/sanctum/csrf-cookie')
      }
      // Do something before request is sent
      return config
    }, function (error) {
      // Do something with request error
      return Promise.reject(error)
    })

    // Intercept
    http.interceptors.response.use(function (response) {
      return response
    }, function (error) {
      if (error.response.status === 401 && store.getters['user/isLogged']) {
        let currentRoute = routes.getRoutes().find(route => route.path === window.location.pathname)

        if (currentRoute && currentRoute.meta.requiresAuth) {
          window.location.href = '/login'
        }
      }
      if (error.response.status === 503) {
        if (error.response.data.data.redirect_to) {
          window.location = error.response.data.data.redirect_to
        }
      }
      if (error.response.status === 419) {
      }
      return Promise.reject(error)
    })

    this.http = http
  },

  withAuth: function (token = '') {
    this.init()

    if (!token) {
      token = store.getters['user/token']
    }

    let http = this.http

    http.defaults.headers.common.Authorization = `Bearer ${token}`

    return http
  },

  withoutAuth () {
    this.init()

    let http = this.http

    http.defaults.headers.common.Authorization = ``

    return http
  }
}

export default ApiService
