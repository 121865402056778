import ApiService from '../apiService'

class PiesService {
  getAllInstruments (page, payload) {
    return ApiService.withAuth().get('api/stock-securities?page=' + page, { params: payload })
  }
  getIndustries () {
    return ApiService.withAuth().get('api/industries')
  }
  storePie (payload) {
    return ApiService.withAuth().post('api/user/pies', payload)
  }
  getAllPies () {
    return ApiService.withAuth().get('api/user/pies')
  }
  getPie (id) {
    return ApiService.withAuth().get('api/user/pies/' + id)
  }
  updateName (id, payload) {
    return ApiService.withAuth().patch('api/user/pies/' + id, payload)
  }
  rebalancePie (id, batchId) {
    return ApiService.withAuth().get('api/user/pies/rebalance/' + id, { params: {batch_id: batchId} })
  }
  updatePie (id, payload) {
    return ApiService.withAuth().post('api/user/pies/' + id, payload)
  }
  sellPie (id, payload) {
    return ApiService.withAuth().post('api/user/pies/' + id + '/sell', payload)
  }
  getCompletedOrders (id, batchId) {
    return ApiService.withAuth().get('api/user/pies/' + id + '/completed-orders', { params: {batch_id: batchId} })
  }
  cancelOrders (id, batchId) {
    return ApiService.withAuth().get('api/user/pies/' + id + '/cancel-orders', { params: {batch_id: batchId} })
  }
  getTaxesForPie (id) {
    return ApiService.withAuth().get('api/user/pies/' + id + '/pies-taxes-sum')
  }
  syncPie (id) {
    return ApiService.withAuth().get('api/user/pies/' + id + '/sync-pie')
  }
  getCommissionsPie (id) {
    return ApiService.withAuth().get('api/user/pies/' + id + '/commissions')
  }
  getCommissionPercentageEquityPie (id) {
    return ApiService.withAuth().get('api/user/pies/' + id + '/commission-percentage-equity')
  }
}

export default new PiesService()
