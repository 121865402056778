import {wpLink} from '@/mixins/WordpressLinks'

export default {
  general: {
    date_added: 'Adăugat pe',
    date_transferred: 'Transferat pe',
    confirmed: 'Valid',
    rejected: 'Respins',
    pending: 'În așteptare',
    new: 'Nou',
    download_bank_statement: 'Descarcă extrasul de cont',
    status: 'Status',
    redirect: 'Confirmare plată',
    different_currencies: 'Operațiunea implică un schimb valutar efectuat de către Investimental la cursul băncii ' +
      'partenere. Pentru sumele de peste 1.000 EUR sau echivalent, Investimental va negocia cursul de ' +
      'schimb în numele clientului. Schimburile valutare pot fi efectuate până la ora 17:00.',
    total: 'Total'
  },
  bank_accounts: {
    add_new_iban: 'Adaugă un nou IBAN',
    add_iban: 'Adaugă IBAN',
    bank: 'Banca',
    currency: 'Moneda',
    existing_iban: 'Acest iban există deja în baza de date',
    invalid_iban: 'Contul IBAN introdus nu este valid',
    account_verif: 'Contul nu a putut fi verificat. Te rugăm să ne contactezi.',
    complete_data: 'Vă rugăm să completați câmpul IBAN, moneda și să încărcați documentul dvs.',
    complete_iban: 'Vă rugăm să completați câmpul IBAN și moneda',
    delete_confirmation: 'Ești sigur că vrei să ștergi contul {bankAccount}?',
    is_revolut: 'Este cont de Revolut?',
    exceeded_no_of_bvb_accounts: 'Ai atins deja numărul maxim de conturi pentru monedele RON și EURO (poți avea maxim 4 pentru ambele).'
  },
  deposits: {
    details: 'Detalii privind cum se fac alimentările',
    message: 'Pentru a efectua această alimentare, te rugăm să inițiezi plata din aplicația ta de internet banking folosind următoarele detalii de plată:',
    beneficiary: 'Beneficiar: Investimental',
    iban: 'IBAN:',
    my_account_title: 'Contul meu inregistrat la Investimental',
    pay_details: 'Detalii de plată:',
    usd_to_usd_transfer: 'Transferurile de USD între bănci din România se efectuează prin bănci ' +
      'corespondente ceea ce implică un timp de procesare de aprox. 2 zile lucrătoare ' +
      'și taxe de transfer în afara controlului Investimental. ',
    recurrent_deposit: 'Pentru a beneficia de un flux constant de bani în contul de tranzacționare, îți recomandăm să folosești opțiunea alimentărilor recurente (dacă este disponibilă în aplicația ta de internet banking)',
    deposit_info: 'Detaliile de plată pentru alimentări folosind un cont bancar în {currency} sunt următoarele:',
    download_label: 'Extras Cont',
    deposit: 'Alimentează',
    extra_accounts_message: 'Alimentarea se va face în contul inițial. Dacă vrei alimentarea unui cont suplimentar, va trebui să inițiezi un <a class="color-blue" href="/account-transfers">transfer între conturi.</a>',
    success: 'Vei fi redirecționat automat pentru a confirma tranzacția. Dacă nu se întâmplă acest lucru, te rugăm apasă pe "Confirmare plată".',
    failed: 'Cererea ta nu a putut fi realizată. Te rugăm să încerci mai târziu.'
  },
  withdrawals: {
    details: 'Detalii privind cum se fac retragerile',
    guide: 'Ghid despre retrageri',
    details_arenaXt: 'Poți iniția cereri de retragere în conturile tale IBAN validate în relația cu Investimental: Intra în <b>ArenaXT</b> - Accesează din fereastra de operațiuni meniul Retrageri, apasă pe butonul + și completează câmpurile. Acum trimite și de aici preluăm noi.',
    withdraw: 'Retrage',
    success: 'Retragerea a fost inițiată cu succes!',
    failed: 'Cererea ta nu a putut fi realizată. Te rugăm să încerci mai târziu.'
  },
  instruments_transfer: {
    to_investimental: 'Către Investimental',
    from_investimental: 'De la Investimental',
    to: {
      first_case: {
        title: 'I. Dacă ai instrumente la alt broker:',
        first_step: `<b class="black">Pasul 1:</b> Notifică-ți brokerul la care ai instrumentele financiare că vrei să-ți transferi portofoliul către Investimental și solicită-i un extras de portofoliu.`,
        second_step: `<b class="black">Pasul 2:</b> Descarcă <b><a target="_blank" href="${wpLink('/wp-content/Transfer-portofoliu/Cerere_transfer_instrumente.pdf')}" class="color-primary cursor-pointer">cererea de transfer</a></b> și completează toate câmpurile. Cererea trebuie semnată și încărcată mai jos.`,
        third_step: `<b class="black">Pasul 3:</b> Încarcă Extrasul de portofoliu primit de la broker.`,
        forth_step: `<b class="black">Pasul 4:</b> Investimental va contacta brokerul de la care vrei să transferi instrumentele pentru a finaliza transferul portofoliului. Vei primi o notificare pe email imediat ce instrumentele tale sunt încărcate în platforma de tranzacționare BVB.`
      },
      second_case: {
        title: 'II. Dacă ai instrumente la Depozitarul Central:',
        first_step: `<b class="black">Pasul 1:</b> Descarcă <b><a target="_blank" href="${wpLink('/wp-content/Transfer-portofoliu/Declaratie_de_transfer_instrumente.pdf')}" class="color-primary cursor-pointer">declarația de transfer</a></b> și completează toate câmpurile. Declarația trebuie semnată și încărcată împreună cu dovada costurilor de achiziție (dacă este cazul).`,
        second_step: '<b class="black">Pasul 2:</b> Investimental va realiza transferul instrumentelor financiare. Vei primi o notificare pe email imediat ce instrumentele tale sunt încărcate în platforma de tranzacționare Arena XT.',
        observation_pj: '<b class="black">Observație:</b> Dacă ai nevoie de eliberarea unui extras de cont de la Depozitarul Central (nu știi' +
          ' câte instrumente ai sau transferul implică coduri confidențiale), descarcă <b><a class="color-primary cursor-pointer"' +
          ' href="https://www.investimental.ro/wp-content/Cerere-eliberare/Cerere-eliberare-extrase-PJ.pdf" target="_blank">' +
          'cererea aceasta</a></b>, completeaz-o și încarc-o mai jos. Eliberarea extrasului poate să implice costurile Depozitarului ' +
          'Central.',
        observation_pf: '<b class="black">Observație:</b> Dacă ai nevoie de eliberarea unui extras de cont de la Depozitarul Central (nu știi' +
          ' câte instrumente ai sau transferul implică coduri confidențiale), descarcă <b><a class="color-primary cursor-pointer"' +
          ' href="https://www.investimental.ro/wp-content/Cerere-eliberare/Cerere-eliberare-extrase-PF.pdf" target="_blank">' +
          'cererea aceasta</a></b>, completeaz-o și încarc-o mai jos. Eliberarea extrasului poate să implice costurile Depozitarului ' +
          'Central.'
      }
    },
    from: {
      title: '(Ne întristează dorința ta 🙁, așteptăm feed-back-ul tău despre experiența cu noi)',
      first_step: `<b class="black">Pasul 1:</b> Descarcă <a target="_blank" class="color-primary cursor-pointer font-bold" href="${wpLink('/wp-content/uploads/2022/11/Cerere_transfer_instrumente.pdf')}" download>cererea de transfer</a> și completează toate câmpurile. Cererea trebuie semnată și încărcată.`,
      second_step: `<b class="black">Pasul 2:</b> Investimental îți va trimite extrasul de portofoliu și va aștepta să fie contactată de brokerul la care vrei să-ți transferi portofoliul. Vei primi o notificare pe email imediat ce instrumentele tale sunt transferate.`,
      third_step: `<b class="black">Pasul 3:</b> Noi îți trimitem Extrasul de portofoliu și așteptăm vești de la brokerul unde vrei să transferi portofoliul pentru a efectua operațiunile. Vei primi notificare pe email în momentul când instrumentele au plecat de la noi.`
    }
  },
  fiscal_obligations: {
    date: 'Data',
    price: 'Preț',
    tax: 'Impozit %/abs',
    anaf_tax: 'Impozit anaf ron',
    tax_ron: 'impozit bvb ron',
    tax_eur: 'impozit bvb eur',
    tax_usd: 'impozit global usd',
    total_tax: 'Total impozit',
    withheld_tax: 'Reținut',
    rest_of_payment: 'Rest de plată',
    collapse: 'Vezi tabel restrâns',
    expand: 'Vezi tabel extins',
    pay_taxes: 'Plătește impozit',
    tax_payment_failed: 'Plata impozitului nu a putut fi realizată. Te rugăm să ne contactezi!'
  },
  account_transfers: {
    new_transfer: 'Transfer nou',
    new_deposit: 'Alimentare nouă',
    new_withdrawal: 'Retragere nouă',
    transfer_details: 'Detalii alimentare',
    from: 'Din contul',
    to: 'În contul',
    choose_account: 'Alege cont tranzacționare',
    sum: 'Sumă',
    currency: 'Valută',
    validation_account: 'Vă rugăm să selectați un cont',
    validation_sum: 'Vă rugăm să introduceți suma',
    validation_sum_greater_than_zero: 'Suma trebuie să fie mai mare ca 0',
    validation_sum_NaN: 'Suma trebuie să fie un număr',
    validation_insufficient_funds: 'Ne pare rău, nu putem efectua transferul, nu am găsit suficient cash în contul tău.',
    message_success: 'Cerere înregistrată cu succes!',
    status: {
      pending: 'În așteptare',
      charged: 'Procesat',
      canceled: 'Anulat'
    },
    status_placeholder: 'Toate',
    available_sum: 'Sumă disponibilă',
    confirm_transfer: 'Ești sigur că vrei să efectuezi acest transfer?',
    account_transfers_p1: 'Completează contul tău de tranzacționare din care dorești să efectuezi transferul, ' +
      'suma pe care vrei să o transferi și valuta, iar noi vom efectua operațiunea de ' +
      'schimb valutar în platforma bancară.',
    account_transfers_p2: 'Banii vor fi transferați între conturile tale deschise la Investimental ' +
      '<b>după primirea confirmării de la bancă.</b>',
    exchange_rate: 'Curs',
    amount_owed: 'Sume datorate',
    amount_withdrawn: 'Sumă retrasă'
  },
  portfolio: {
    symbol: 'Simbol',
    quantity: 'Cantitate',
    medium_cost: 'Cost mediu',
    market_price: 'Preț piață',
    variation: 'Variație',
    evaluation: 'Evaluare',
    total: 'TOTAL',
    total_general: 'TOTAL ESTIMAT (la curs BNR)',
    my_portfolio: 'Portofoliul meu',
    currency: 'Valută',
    yield: 'Randament',
    select_portfolio: 'Selectează portofoliu',
    main_account: 'Portofoliu principal',
    account: 'Portofoliu',
    consolidated_portfolio: 'Portofoliu consolidat RON (valoare estimată)',
    time_periods: {
      one_day: '1 Z',
      one_week: '1 S',
      one_month: '1 L',
      three_months: '3 L',
      one_year: '1 A',
      max: 'MAX'
    },
    free: 'gratuite',
    corporate_events: {
      issuer: 'Emitent',
      event_type: 'Tip eveniment',
      cum_date: 'Cum date',
      ex_date: 'Ex date',
      record_date: 'Data înregistrării',
      pay_date: 'Data plății',
      amount: 'Valoare',
      remarks: 'Observații',
      dividend_cash: 'Dividend',
      mcs_gratuite: 'Majorare de Capital Social cu Actiuni gratuite',
      cupon: 'Cupon',
      increase_factor: 'Indice de alocare'
    }
  },
  report: {
    pending: 'În procesare',
    cancelled: 'Anulat',
    deposit_in: 'Alimentare în',
    withdrawal_from: 'Retragere din',
    no_reports: 'Nu există tranzacții de afișat. Mergi la secțiunea alimentări pentru a realiza prima depunere.',
    show_more: 'Afișează mai mult',
    dividend: 'Dividend',
    voucher: 'BONUS RECOMANDARE',
    withdrawal_tax_from: 'Retragere taxe PIEs din'
  },
  multi_account: {
    add_new_account: 'Adaugă cont nou',
    account_type: 'Tip cont',
    account_currency: 'Valută cont',
    account_name: 'Etichetă cont',
    add: 'Adaugă',
    validation_account_type: 'Vă rugăm să selectați un tip de cont',
    validation_account_currency: 'Vă rugăm să selectați o valută',
    validation_alias: 'Vă rugăm să introduceți o etichetă',
    validation_alias_length: 'Eticheta poate să aibă maxim 20 de caractere',
    success: 'Cont adăugat cu succes!',
    alias_warning: 'ATENTIE! Alias-ul nu poate fi editat ulterior.',
    not_approved: 'Contul tău nu este încă aprobat. Vei putea adăuga conturi secundare doar după ce contul tău va fi aprobat.',
    not_approved_pies: 'Contul tău nu este încă aprobat. Vei putea adăuga PIEs doar după ce contul tău va fi aprobat.',
    table: {
      id: 'Nr. crt.',
      market: 'Piață',
      alias: 'Alias',
      currency: 'Valută',
      account: 'Cont'
    }
  },
  auto_invest: {
    pies: 'PIES',
    deposit_pie: 'Alimentează',
    edit_pie: 'Editează',
    delete_pie: 'Șterge',
    sell_pie: 'Vinde',
    save_pie: 'Salvează',
    cancel_pie: 'Anulează modificările',
    rebalance_pie: 'Rebalansează',
    buy_pie: 'Cumpără',
    withdraw_pie: 'Retrage',
    min_sum: 'Sumă minimă',
    max_sum: 'Sumă maximă',
    completed_orders: 'Ordine procesate',
    symbol: 'Simbol',
    sum: 'Sumă',
    commission: 'Comision',
    value: 'Val. ordin',
    side: 'Direcție',
    sell_side: 'SELL',
    name_pie_max_length: 'Numele PIE-ului poate să aibă maxim 20 de caractere',
    table: {
      current_value: 'Valoare curentă',
      net_deposits: 'Alimentări nete',
      deposits: 'Alimentări',
      annual_medium_yield: 'Rand. anual mediu',
      see_pie: 'Vizualizează'
    },
    validations: {
      min_sum_for_rebalance: 'Suma nu poate fi mai mică decât ',
      no_orders_for_rebalance: 'Nu există ordine pentru rebalansare'
    },
    add_pie: 'PIE nou',
    name_pie: 'Nume PIE',
    go_back_to_pies: 'Înapoi la ecran PIES',
    close_instruments: 'Închide instrumentele',
    pie: {
      initiation_date: 'Data inițierii',
      invested_value: 'Valoare investită',
      cash_value: 'Cash',
      total_value: 'Valoare totală',
      refresh_info: 'Ai efectuat operațiuni pe acest PIE în Investimental Global și vrei să actualizezi informațiile aici? Sincronizează informațiile afișate folosind acest buton',
      refresh_info_short: 'Sincronizează informațiile afișate folosind acest buton',
      table: {
        instrument: 'Instrument',
        target_current_percentage: {
          target: '% țintă',
          percentage: '% curentă'
        },
        value: 'Valoare',
        difference: 'Diferență',
        profit_profit_abs: {
          profit: 'Profit %',
          profit_abs: 'Profit abs. '
        }
      },
      sum_with_two_decimals: 'Suma trebuie să aibă maxim două zecimale'
    },
    instruments: {
      actions: 'Acțiuni',
      search: 'Caută',
      selected: 'Selectate',
      select_all: 'Selectează toate',
      top_ten: 'Top 10',
      industries_source: 'Sursa: Morningstar',
      table: {
        instrument: 'Instrument',
        symbol: 'Simbol',
        issuer: 'Emitent',
        type: 'Tip instrument',
        action: 'Acțiune',
        value: 'Valoare'
      }
    },
    targets: {
      title: 'Personalizează PIE-ul tău',
      distribute_equally: 'Distribuie în mod egal',
      add_instruments: 'Adaugă instrumente',
      target_weights: 'Ponderile țintă',
      remove: 'Elimină',
      total: 'Total',
      validation_min_percentage: 'Ponderile nu pot fi mai mici de 1%'
    },
    transfers: {
      cancel: 'Anulează',
      deposit: 'Alimentează',
      withdraw: 'Retrage',
      sell: 'Vinde',
      place_sum: 'Introdu suma',
      choose_account: 'Alege contul',
      max_sum_error: 'Suma nu poate depăși valoarea sumei maxime',
      min_sum_error: 'Suma nu poate fi mai mică decât valoarea sumei minime',
      instruments_validation: 'Nu se pot plasa ordine cu valoare de sub 10 USD, sau nu pot ramâne mai puțin de 10 USD pe instrument',
      instruments_total_validation: 'Totalul trebuie să fie 100%',
      sell_instruments_total_validation: 'Totalul este {sumOfPercentages}%, trebuie să fie 100%',
      percentages_info: 'Valoarea procentuală a unui instrument este calculată pe baza valorii introduse în ecranul anterior',
      from_account: 'Din contul',
      withdraw_info: 'Dacă ai efectuat vânzări, sumele sunt blocate până la decontare (1 zi lucrătoare)'
    },
    rebalance: {
      title: 'Confirmare ordine',
      message_1: 'Acțiunea cerută de tine implică lansarea unor ordine de tip Market - Day. Taxa de rebalansare de 3$ va fi reținută conform Contractului.',
      message_2: 'Dacă dorești să faci modificări, o poți face în ecranul anterior.',
      message_3: 'Ești de acord să lansezi ordinele acum?',
      account_code: 'Cod cont',
      success_message: 'Ordinele au fost executate cu succes!',
      orders_restrictions: 'Rebalansarea nu generează ordine atunci când diferența dintre ponderea curentă și cea țintă este sub 1% și pentru ordine de cumpărare sub $10.'
    },

    sell: {
      sell_sum: 'Suma de vânzare',
      message_1: 'Acțiunea cerută de tine implică lansarea unor ordine de tip Market - Day. Taxa de vânzare de 3$ va fi reținută conform Contractului.'
    },
    pies_info: {
      first_info: 'Tranzacțiile pentru PIEs trebuie efectuate din această pagină.',
      second_info: `Dacă efectuezi tranzacții prin Investimental Global pe contul de PIEs, fiecare tranzacție va fi` +
        ` considerată rebalansare și va fi taxată conform <a target="_blank" class="color-primary underline cursor-pointer"` +
        ` href="${wpLink('/grila-comisioane-investimental/')}">grilei de comisionare.</a>`,
      limits_info: 'Poți crea până la <span class="color-primary font-bold">{maxNumberPies} PIE-uri</span>,' +
        ' fiecare cu <span class="color-primary font-bold">minim {minInstrumentsPies}</span> și' +
        ' <span class="color-primary font-bold">maxim {maxInstrumentsPies}</span> instrumente.'
    }
  }
}
