<template>
  <div class="email-verification">
    <vue-title :title="$t('general.meta_titles.email_verification')"></vue-title>

    <div class="m-auto" v-if="$route.meta['source'] !== 'lms'">
      <StepBar :current-step="selectedTabId" :steps="tabs"></StepBar>
    </div>
    <Card :card-classes="'margin-bottom-medium mobile-border-none'"
          :class="[$route.meta['source'] === 'lms' ?
          (screenDevice !== $screenDeviceConstants.DESKTOP && screenDevice !== $screenDeviceConstants.TABLET ? 'mt-28' : 'mt-36') :
          '']">
      <template v-slot:top>
        <img src="~@/assets/img/icons/green_circle_person.svg"/>
      </template>

      <template v-slot:title>
        <div>{{ $t("onboarding.general.create_account") }}</div>
      </template>

      <template v-slot:body>
        <div class="email-verification-body">
          <EmailVerificationCode :email="email"
                                 @setVerificationCode="setVerificationCode"
                                 @validate="validateCode"
                                 @resetStep="resetStep"/>
          <pulse-loader v-if="loading" class="m-auto w-fit" />
          <Message
            v-if="validation.verificationCode"
            :message="validation.verificationCode"
            :type="'error'"
            class="m-auto"
          />

          <Message
            v-if="lmsError"
            :message="lmsError"
            :type="'error'"
            class="m-auto"
          />

          <Message
            v-if="validation.resentCode"
            :message="validation.resentCode"
            :type="'success'"
            class="m-auto"
          />
        </div>
      </template>
    </Card>
  </div>
</template>

<script>
import StepBar from '../layout/StepBar'
import { mapActions, mapGetters } from 'vuex'
import Button from '../subcomponents/Button'
import Message from '../subcomponents/Message'
import {ONBOARDING_INFO, ONBOARDING_PHONE_VALIDATION} from '../../router'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import Card from '../subcomponents/Card'
import OtpInput from '../subcomponents/security-code/OtpInput'
import EmailVerificationCode from '../subcomponents/EmailVerificationCode.vue'
import UserService from '../../services/auth/userService'

export default {
  name: 'EmailVerification',
  components: { Card, StepBar, Button, Message, PulseLoader, OtpInput, EmailVerificationCode },
  data () {
    return {
      tabs: [
        {
          id: 1,
          label: this.$i18n.t('onboarding.general.create_account')
        },
        {
          id: 2,
          label: this.$i18n.t('onboarding.general.identity_verification')
        },
        {
          id: 3,
          label: this.$i18n.t('onboarding.general.financial_data')
        }
      ],
      selectedTabId: 1,
      verificationCode: '',
      loading: false,
      email: '',
      tabsClasses: ['justify-center'],
      validation: {
        verificationCode: '',
        resentCode: ''
      },
      lmsError: '',
      interval: null
    }
  },
  mounted () {
    this.email = this.userEmail
  },
  computed: {
    ...mapGetters({
      user: 'user/user',
      userEmail: 'user/email',
      userToken: 'user/token',
      screenDevice: 'layout/screenDevice'
    })
  },
  methods: {
    ...mapActions({
      verifyCode: 'user/verifyCode',
      resendCode: 'user/resendCode',
      logout: 'user/logout'
    }),
    async validateCode () {
      if (!this.verificationCode) {
        this.validation.verificationCode = this.$i18n.t(
          'onboarding.email_verification.code_validation'
        )
      } else {
        this.loading = true
        let response = await this.verifyCode({
          verificationCode: this.verificationCode,
          hubspotCookie: this.$cookies.get('hubspotutk')
        }).catch(error => {
          this.loading = false
          if (error.response.status === 400) {
            this.validation.verificationCode = this.$i18n.t(
              'onboarding.email_verification.invalid_code'
            )
          }
        })

        if (response && response.status === 200) {
          this.validation.resentCode = this.$i18n.t(
            'onboarding.email_verification.user_verif_success'
          )
          if (this.$route.meta['source'] === 'lms') {
            let responseLms = await UserService.getLmsUrl()

            if (responseLms.data.data.url && responseLms.data.data.url.length) {
              window.location.href = responseLms.data.data.url
            }

            if (responseLms.status === 500) {
              this.lmsError = response.message
            }
            if (responseLms.data.data.status === 'retry') {
              this.interval = setInterval(this.regenerateLmsUrl, 1500)
            }
          } else if (this.user.type === 'company') {
            await this.$router.push({name: ONBOARDING_PHONE_VALIDATION})
          } else {
            await this.$router.push({name: ONBOARDING_INFO})
          }
        }

        this.loading = false
      }
    },

    regenerateLmsUrl () {
      UserService.getLmsUrl()
        .then(response => {
          if (response.data.data.status === 'done') {
            window.location.href = response.data.data.url
            clearInterval(this.interval)
          }
        })
    },

    async resetStep () {
      await this.$confirm(
        this.$t('onboarding.reset_steps.reset_email_question'),
        '',
        'warning',
        { cancelButtonText: this.$t('general.general.cancel') }
      ).then(async () => {
        await this.logout()
        if (!this.isLogged) {
          if (this.$route.meta['source'] === 'lms') {
            await this.$router.push({name: 'register-lms'})
          } else {
            await this.$router.push({name: 'register'})
          }
        }
      })
    },
    setVerificationCode (code) {
      this.verificationCode = code
    }
  },
  watch: {
    verificationCode () {
      this.validation.verificationCode = ''
    }
  }
}
</script>

<style scoped></style>
