export default {
  general: {
    upload_new_document: 'Încarcă un nou document',
    upload_document: 'Încarcă document',
    cancel: 'Anulează',
    document_name: 'Nume document',
    edit: 'Editează',
    document_type: 'Tip document',
    document_category: 'Categorie document',
    identity_card: 'Buletin',
    bank_statement_document: 'Extras de cont',
    other_documents: 'Alte documente',
    enter_document_name: 'Vă rugăm să introduceți numele documentului!',
    select_document_type: 'Vă rugăm să selectați tipul documentului!',
    new_document: 'Vă rugăm să încărcați documentul!',
    size_too_big: 'Documentul nu poate depăși dimensiunea de 25MB',
    type_document: 'Format invalid!',
    type_assets_document: 'Documentul trebuie să fie de tip PDF, DOC, DOCX, JPG, JPEG sau PNG.',
    select_document_category: 'Vă rugăm să selectați categoria documentului!',
    download_draft_message: 'Te rugăm să descarci documentul de mai jos și să-l trimiți completat o dată cu restul documentelor adiționale.',
    download_draft_label: 'Descarcă - Document Draft:',
    download: 'Descarcă',
    delete_confirmation: 'Ești sigur că vrei să ștergi {documentName}?',
    delete: 'Renunț',
    confirm: 'Confirm',
    send_request: 'Trimite cerere',
    upload_successful: 'Cererea a fost înregistrată cu succes. Transferul instrumentelor va fi efectuat în scurt timp.',
    pj_upload_successful: 'Documentele au fost trimise. Le vom analiza și revenim.',
    notifications_documents_missing: 'Nu ai documente atașate în notificări',
    send_documents: 'Trimite fișierele'
  },
  breadcrumb: {
    document: 'Documente',
    client_documents: 'Documente client',
    profile_documents: 'Documente contractuale',
    pj_documents: 'Documente persoana juridică',
    notification_documents: 'Atașamente Notificări',
    invoices: 'Facturi'
  },
  legal_person: {
    contractual_documents: 'Documente contractuale',
    company_identification_documents: 'Documente identificare companie',
    documents_from_legal_person: 'Lista documentelor necesare din partea persoanei juridice',
    documents_for_legal_person: 'Lista documentelor din partea Investimental',
    cui: 'Certificatul unic de înregistrare al societății (C.U.I.)',
    society_act: 'Actul constitutiv/contractul de societate/statutul societății (varianta în vigoare la data solicitării de deschidere cont) - copie',
    beneficiary_declaration: 'Declarație privind beneficiarul real înregistrată la ONRC - copie',
    identity_documents: 'Copii ale Documentelor identitate, în termen de valabilitate, pentru reprezentantul legal în relație cu Investimental, beneficiar' +
      ' real, administratori și persoane autorizate să ia decizii în numele și pe seama companiei, așa cum ne-ai prezentat aceste persoane în Anexa 1',
    multiple_admins: 'În cazul în care există mai mulți administratori cu puteri depline, este necesar un Document din partea societății de' +
      ' desemnare a persoanei ce acționează pe contul deschis la InvestiMental',
    bank_account_document: 'Extras de cont bancar / alt document care să ateste titularul contului bancar declarat, prin Anexa 1, în relație cu Investimental',
    lei_code: `Codul LEI (se poate obține de la Depozitarul Central - <a target="_blank" class="color-blue" href="https://lei.roclear.ro/">Depozitarul Central LEI (roclear.ro))</a>`,
    attachment_1: 'Anexă 1 Date și informații persoane juridice',
    attachment_2: 'Anexă 2 Declarația privind rezidența fiscală persoane juridice',
    attachment_3: 'Anexă 3 Serviciul tranzacționare BVB Spot',
    attachment_4: 'Anexă 4 Serviciul tranzacționare Investimental Global',
    contract: 'Contract servicii de investiții financiare',
    evaluation_questionnaire: 'Chestionar evaluare - Reprezentanți legali/persoane care urmează să ia decizii în numele și pe seama clientului PJ',
    signed_documents: 'Documentele de mai sus trebuie semnate de reprezentantul legal cu semnătură electronică.',
    cui_button: 'Încarcă CUI',
    society_act_button: 'Încarcă actul constitutiv',
    beneficiary_declaration_button: 'Încarcă declarație beneficiar',
    identity_documents_button: 'Încarcă documente identitate',
    multiple_admins_button: 'Încarcă document societate',
    bank_account_document_button: 'Încarcă extras de cont bancar',
    lei_code_button: `Încarcă codul LEI`,
    pj_documents_info: 'Pentru a finaliza procesul de Onboarding, trebuie să ne trimiți documentele de identificare ale companiei, precum și contractele și anexele semnate electronic de către tine, în calitate de reprezentant legal al societății. Noi le vom analiza și te vom anunța când contul tău va fi validat.',
    documents_from_investimental: 'Lista documentelor furnizate de Investimental catre Persoana Juridică'
  }
}
