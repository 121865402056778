<template>
  <div>
    <general-layout>
      <template v-slot:title>
        <vue-title :title="$t('general.meta_titles.client_documents')"/>
      </template>

      <template v-slot:tabs>
        <Tabs
          :show-id-title="false"
          :tabs-classes="tabsClasses"
        />
      </template>

      <template v-slot:body>
        <div v-if="!hasAccessInPortal">
          <div class="desktop-flex gap-8 mt-8">
            <ContinueToOnboarding/>

            <div v-if="!isMobile">
              <Card :card-classes="'w-full h-full' + (isMobile ? ' mb-8' : '')">
                <template v-slot:body>
                  <div>
                    <p v-html="$t('guides.account_and_contact_data.account_p2')"/>
                    <br>
                    <p v-html="$t('guides.account_and_contact_data.account_p1')"/>
                  </div>
                </template>
              </Card>
            </div>
          </div>
        </div>

        <div class="mt-8" v-else>
          <Breadcrumb
            v-if="!isMobile"
            :items="[
              {
                label: $t('documents.breadcrumb.document')
              },
              {
                label: $t('documents.breadcrumb.client_documents'),
                routeName: 'client-documents'
              }
            ]"
          />

          <div class="client-documents">
            <div class="padding-card">
              <Button
                :class="[isMobile ? 'mb-8' : 'mb-12']"
                v-if="!uploadDocument"
                :text="$t('documents.general.upload_new_document')"
                @call="setUploadDocument(true)"
                :type="'btn-primary'"
              />

              <Transition appear name="fade">
                <div class="upload-document" v-if="uploadDocument">
                  <div>
                    <Card class="personal-info"
                          :card-classes="'mobile-card-portal'">
                      <template v-slot:body>
                        <UploadDocuments
                          :component="'documents'"
                          :all-document-types="documentTypes"
                          :all-document-categories="documentCategories"
                          @success="saveData"
                          @closeUpload="closeUpload"
                        />
                      </template>
                    </Card>
                  </div>

                  <div class="flex flex-col" v-if="!isMobile">
                    <div class="grow">
                      <Card class="h-full flex items-center" :card-classes="'mobile-card-resources padding-small'">
                        <template v-slot:body>
                          <div>
                            <p v-html="$t('guides.client_documents.p1')"/>
                          </div>
                        </template>
                      </Card>
                    </div>

                    <div class="mt-8">
                      <Resources :links="resourcesLinks"/>
                    </div>
                  </div>
                </div>
              </Transition>
            </div>

            <Transition appear name="fade" v-if="documents && !uploadDocument">
              <div class="documents-data">
                <div
                  class="info document"
                  v-for="(document, counter) in documents" :key="counter">
                  <DocumentCard :document="document" :counter="counter"/>
                </div>
              </div>
            </Transition>
          </div>

          <div v-if="!isMobile" class="my-12 bg-white h-px"></div>

          <div v-if="!uploadDocument || isMobile">
            <div v-if="!isMobile" class="mt-8">
              <Card class="w-full" :card-classes="'mobile-card-resources padding-small'">
                <template v-slot:body>
                  <div>
                    <p v-html="$t('guides.client_documents.p1')"/>
                  </div>
                </template>
              </Card>
            </div>

            <div :class="[isMobile ? 'mt-8' : 'mt-2']">
              <Resources :links="resourcesLinks"/>
            </div>
          </div>
        </div>
      </template>
    </general-layout>
  </div>
</template>

<script>
import GeneralLayout from '../../layout/GeneralLayout'
import Tabs from '../../layout/Tabs'
import Button from '../../subcomponents/Button'
import { mapGetters } from 'vuex'
import Card from '../../subcomponents/Card'
import IconBox from '../../subcomponents/IconBox.vue'
import DocumentsService from '../../../services/documents/documentsService'
import InputText from '../../subcomponents/InputText'
import Message from '../../subcomponents/Message'
import SelectDropDown from '../../subcomponents/SelectDropDown'
import UploadDocuments from '../../subcomponents/UploadDocuments'
import DocumentCard from './DocumentCard.vue'
import Breadcrumb from '../../subcomponents/Breadcrumb.vue'
import Resources from '../../subcomponents/Resources.vue'
import ContinueToOnboarding from '../ContinueToOnboarding.vue'

export default {
  name: 'ClientDocuments',
  components: { ContinueToOnboarding, Resources, Breadcrumb, DocumentCard, UploadDocuments, GeneralLayout, Tabs, Button, InputText, Card, IconBox, Message, SelectDropDown },
  data () {
    return {
      loadingData: {
        deleteDocument: false
      },
      resourcesLinks: [
        { label: this.$t('useful_links.profile_documents.category'), value: '/ghid/ghid-incadrare-in-categoria-client-profesional-la-cerere/' },
        { label: this.$t('useful_links.profile_documents.professional'), value: '/wp-content/uploads/2023/07/2023-03-Cerere-incadrare-categoria-client-profesional.pdf' }
      ],
      uploadDocument: false,
      documentName: '',
      validation: {
        newDocument: '',
        documentName: '',
        documentType: '',
        general: ''
      },
      newDocument: null,
      tabsClasses: [],
      documentType: null,
      documents: null,
      documentCategories: null,
      documentTypes: [
        {
          id: 1,
          label: this.$i18n.t('documents.general.identity_card'),
          value: 'identity_card'
        },
        {
          id: 2,
          label: this.$i18n.t('documents.general.bank_statement_document'),
          value: 'bank_statement_document'
        },
        {
          id: 3,
          label: this.$i18n.t('documents.general.other_documents'),
          value: 'others'
        }
      ]
    }
  },
  async created () {
    if (this.isLogged) {
      await this.setData()
    }
  },
  methods: {
    closeUpload () {
      this.uploadDocument = false
    },
    saveData () {
      setTimeout(() => {
        this.loading = true
        this.uploadDocument = false
        this.setData()
      }, 3000)
    },
    async setData () {
      let allDbDocuments = await DocumentsService.getUploadedDocuments()
      this.documents = allDbDocuments.data.data

      let allDBClientDocumentCategories = await DocumentsService.getClientDocumentCategories()
      this.documentCategories = allDBClientDocumentCategories.data.data.clientDocumentCategories

      if (!this.documentCategories.length) {
        this.documentCategories.unshift({label: 'Fără Categorie', value: ''})
      }
    },
    async download (doc) {
      await DocumentsService.downloadDocument(doc)
    },
    async remove (document) {
      await this.$confirm(
        this.$t('documents.general.delete_confirmation', {documentName: document.name}),
        '',
        'warning',
        {cancelButtonText: this.$t('documents.general.delete'), confirmButtonText: this.$t('documents.general.confirm')}
      ).then(async () => {
        this.loadingData.deleteDocument = true
        let response = await DocumentsService.deleteDocument(document.id)

        if (response.status === 200) {
          await this.setData()
        }
      })
      this.loadingData.deleteDocument = false
    },
    setUploadDocument (value) {
      this.uploadDocument = value
    }
  },
  computed: {
    ...mapGetters({
      screenDevice: 'layout/screenDevice',
      isLogged: 'user/isLogged',
      hasAccessInPortal: 'user/hasAccessInPortal'
    }),
    isMobile () {
      return this.screenDevice === this.$screenDeviceConstants.MOBILE
    }
  }

}
</script>

<style scoped></style>
