<template>
  <div class="select-drop-down">
    <label v-if="label" class="form-label inline-block" :for="id">{{ label }}</label>
    <v-select :single-line="true"
              :value="value"
              @input="inputChanged"
              :placeholder="placeholder"
              :searchable="true"
              :id="id"
              class="v-select input-primary w-full"
              :class="{'disabled': disabled}"
              :selectable="optionIsNotDivider"
              @search="searchSubString"
              :options="optionsCopy">
                <template #option="{ label, divider }">
                  <h3 v-if="!divider" class="m-0">{{ label }}</h3>
                  <template v-if="divider">
                      <hr class="noclick">
                  </template>
                </template>
        </v-select>
  </div>

</template>

<script>

export default {
  name: 'SelectDropDownFav',
  props: {
    id: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    value: {
      type: [String, Object, Array],
      default: ''
    },
    options: {
      type: [Object, Array]
    },
    disabled: {
      type: Boolean,
      default: false
    },
    nameFormat: {
      type: String,
      default: ''
    },
    nameFormatSearch: {
      type: String,
      default: ''
    },
    hasFavourite: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      optionsCopy: this.options
    }
  },
  methods: {
    inputChanged (value) {
      let sentValue = (this.nameFormat && value) ? value[this.nameFormat] : value
      this.$emit('input', sentValue)
    },
    optionIsNotDivider (option) {
      return !option.divider
    },
    searchSubString (search) {
      this.optionsCopy = this.options.filter(option =>
        (this.nameFormatSearch ? option[this.nameFormatSearch] : option).toLowerCase().startsWith(search.toLowerCase())
      )
    }
  }
}
</script>

<style scoped>
.noclick::before {
  cursor: pointer;
  border-bottom: 1px solid black;
}
</style>
