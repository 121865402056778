<template>
  <div class="personal-data">
    <div v-if="!loading" class="personal-info">
      <div class="mb-4 flex justify-between">
        <div>
          <div v-if="editCI">
            <InputText
              :disabled-input="!editCI"
              :id="'lastName'"
              :label="$t('settings.general.last_name')"
              v-model="lastName"
              :type-input="'text'"
            />

            <InputText
              :disabled-input="!editCI"
              :id="'firstName'"
              v-model="firstName"
              :label="$t('settings.general.first_name')"
              :type-input="'text'"
            />
          </div>

          <span v-else class="font-bold">{{ firstName }} {{ lastName }}</span>
        </div>
      </div>

      <div class="flex justify-between items-start mb-4">
        <div class="flex flex-col">
          <span class="font-bold">{{ $t('settings.general.cnp') }}</span>

          <InputText
            :disabled-input="!editCI"
            :id="'cnp'"
            v-model="cnp"
            :type-input="'text'"
          />
        </div>
      </div>

      <div class="flex justify-between items-start mb-4" v-if="getUserData.identity_card.type !== 'passport'">
        <div class="flex flex-col">
          <span class="font-bold">{{ $t('settings.general.series') }}</span>

          <div class="flex">
            <InputText
              class="mr-2"
              :id="'series'"
              :disabled-input="!editCI"
              v-model="series"
              :type-input="'text'"
            />
          </div>
        </div>
      </div>

      <div class="flex justify-between items-start mb-4">
        <div class="flex flex-col">
          <span class="font-bold">{{ $t('settings.general.number') }}</span>

          <div class="flex">
            <InputText
              :id="'number'"
              v-model="number"
              :disabled-input="!editCI"
              :type-input="'text'"
            />
          </div>
        </div>
      </div>

      <div class="flex justify-between items-start mb-4">
        <div class="flex flex-col">
          <span class="font-bold">{{ $t('settings.general.expiration_date') }}</span>

          <span v-if="!editCI" class="edit">{{ formatDate(expirationDate) }}</span>

          <FunctionalCalendar
            v-else
            v-model="calendarDataExpiration"
            :date-format="'mm/dd/yyyy'"
            :is-date-picker="true"
            :change-year-function="true"
            :change-month-function="true"
            :is-modal="true"
            :disabled-dates="['beforeToday']"
            @choseDay="choseExpirationDay">
            <template v-slot:datePickerInput>
              <InputText
                :id="'expirationDate'"
                v-model="expirationDate"
                :type-input="'text'"
              />
            </template>
          </FunctionalCalendar>
        </div>
      </div>

      <div class="flex justify-between items-start mb-4">
        <div class="flex flex-col">
          <span class="font-bold">{{ $t('settings.general.release_date') }}</span>

          <span v-if="!editCI" class="edit">{{ formatDate(releaseDate) }}</span>

          <FunctionalCalendar
            v-else
            v-model="calendarDataRelease"
            :date-format="'mm/dd/yyyy'"
            :is-date-picker="true"
            :change-year-function="true"
            :change-month-function="true"
            :is-modal="true"
            :disabled-dates="['afterToday']"
            @choseDay="choseReleaseDay">
            <template v-slot:datePickerInput>
              <InputText
                :id="'releaseDate'"
                v-model="releaseDate"
                :type-input="'text'"
              />
            </template>
          </FunctionalCalendar>
        </div>
      </div>

      <div class="mb-4">
        <div class="flex justify-between items-start mb-2" >
          <span class="font-bold">{{ $t('settings.general.home_address') }}</span>
        </div>

        <span class="edit" v-if="!editCI">{{ getLongLabelForAddress(address) }}</span>

        <FullAddress v-else
                     :type="'domicile'"
                     :no-padding-margin="true"
                     :preSelectedAddress=address
                     :precompleted-text="precompletedText"
                     @fullAddress="setNewAddress">
        </FullAddress>
      </div>

      <div class="file-upload flex justify-between info" v-if="editCI">
        <span class="document cursor-pointer" @click="$refs.ic_document.click()">
          <img
            :src="require(`@/assets/img/icons/upload-document.svg`)"
            alt="icon"
          />

          <span v-if="file">{{ file.name }}</span>

          <span v-else>
            {{ $t('Select Identity Card') }}

            <span class="info-title">{{ $t('general.general.file_types') }}</span>
          </span>
        </span>

        <input
          v-if="editCI"
          id="ic_document"
          :ref="'ic_document'"
          hidden
          name="ic"
          type="file"
          @change="handleFileUpload"
        />
      </div>

      <Button :text="!editCI ? $t('general.general.edit') : $t('general.general.close')"
              :type="'btn btn-primary mt-3'"
              @call="toggleEditCI"/>

      <div>
        <Message v-if="validations.firstName" :message="validations.firstName" :type="'error'" class="mb-5"/>
        <Message v-if="validations.lastName" :message="validations.lastName" :type="'error'" class="mb-5"/>
        <Message v-if="validations.series && getUserData.identity_card.type !== 'passport'" :message="validations.series" :type="'error'" class="mb-5"/>
        <Message v-if="validations.number" :message="validations.number" :type="'error'" class="mb-5"/>
        <Message v-if="validations.cnp" :message="validations.cnp" :type="'error'" class="mb-5"/>
        <Message v-if="validations.releaseDate" :message="validations.releaseDate" :type="'error'" class="mb-5"/>
        <Message v-if="validations.expirationDate" :message="validations.expirationDate" :type="'error'" class="mb-5"/>
        <Message v-if="validations.identityCardFile" :message="validations.identityCardFile" :type="'error'" class="mb-5"/>
        <Message v-if="validations.address" :message="validations.address" :type="'error'" class="mb-5"/>
      </div>

      <div class="flex justify-between items-center mt-10">
        <Button :icon="'icons/arrow_back_green'"
                :icon-position="'left'"
                :text="$t('general.general.back')"
                :type="'btn btn-secondary padding-big'"
                @call="resetStep"/>

        <Button :icon="'icons/check-white'"
                :icon-position="'right'"
                :text="$t('general.general.confirm')"
                :type="'btn btn-primary padding-big'"
                @call="next"/>
      </div>
    </div>
    <pulse-loader v-if="loading" class="w-fit m-auto p-10 justify-center flex"/>
  </div>
</template>

<script>
import Button from '../../subcomponents/Button.vue'
import InputText from '../../subcomponents/InputText.vue'
import {mapActions, mapGetters} from 'vuex'
import FullAddress from '../../subcomponents/FullAddress.vue'
import FullAddressMixin from '../../../mixins/FullAddress'
import Message from '../../subcomponents/Message.vue'
import documentsService from '@/services/documents/documentsService'

export default {
  name: 'UpdateIdentityForm',
  components: { Message, FullAddress, Button, InputText },
  mixins: [FullAddressMixin],
  computed: {
    ...mapGetters({
      updatedData: 'updatePersonalData/getUpdatedData',
      getUserData: 'user/user'
    })
  },
  data () {
    return {
      firstName: '',
      lastName: '',
      cnp: '',
      series: '',
      number: '',
      expirationDate: '',
      releaseDate: '',
      address: '',
      calendarDataRelease: {},
      calendarDataExpiration: {},
      editFile: false,
      file: null,
      precompletedText: '',
      editCI: false,
      validations: {
        general: '',
        expirationDate: '',
        releaseDate: '',
        firstName: '',
        lastName: '',
        identityCardFile: '',
        cnp: '',
        series: '',
        number: '',
        address: ''
      },
      loading: false
    }
  },
  async created () {
    this.loading = true
    if (this.updatedData.step_identity_card.identityCard) {
      await this.getTmpFile()
    }

    this.address = (('address' in this.updatedData.step_identity_card) && Object.keys(this.updatedData.step_identity_card.address).length)
      ? this.updatedData.step_identity_card.address : this.getUserData.identity_card.address
    this.formatAddress()
    this.cnp = (('pin' in this.updatedData.step_identity_card) && this.updatedData.step_identity_card.pin)
      ? this.updatedData.step_identity_card.pin : this.getUserData.identity_card.personal_identification_number
    this.number = (('number' in this.updatedData.step_identity_card) && this.updatedData.step_identity_card.number)
      ? this.updatedData.step_identity_card.number : this.getUserData.identity_card.number
    this.series = (('series' in this.updatedData.step_identity_card) && this.updatedData.step_identity_card.series)
      ? this.updatedData.step_identity_card.series : this.getUserData.identity_card.series
    this.firstName = (('first_name' in this.updatedData.step_identity_card) && this.updatedData.step_identity_card.first_name)
      ? this.updatedData.step_identity_card.first_name : this.getUserData.first_name
    this.lastName = (('last_name' in this.updatedData.step_identity_card) && this.updatedData.step_identity_card.last_name)
      ? this.updatedData.step_identity_card.last_name : this.getUserData.last_name
    this.expirationDate = (('expiration_date' in this.updatedData.step_identity_card) && this.updatedData.step_identity_card.expiration_date)
      ? this.updatedData.step_identity_card.expiration_date : this.getUserData.identity_card.expiration_date
    this.releaseDate = (('release_date' in this.updatedData.step_identity_card) && this.updatedData.step_identity_card.release_date)
      ? this.updatedData.step_identity_card.release_date : this.getUserData.identity_card.release_date
    this.precompletedText = `${this.address.street}`

    if (this.address.number) {
      this.precompletedText += `, ${this.$i18n.t('general.address.number')} ${this.address.number}`
    }
    this.loading = false
  },
  methods: {
    ...mapActions({
      validateIdData: 'identityCard/validate'
    }),

    async getTmpFile () {
      await documentsService.getTmpDocuments(this.updatedData.step_identity_card.identityCard).then(response => {
        this.file = new File([response.data], this.updatedData.step_identity_card.fileName, {
          type: this.updatedData.step_identity_card.fileType
        })
      }).catch(() => {
        this.file = null
      })
    },

    async resetStep () {
      this.$emit('prev', 'updatePhoneNumber')
    },
    handleFileUpload (event) {
      this.file = event.target.files[0]
    },
    toggleEditCI () {
      this.editCI = !this.editCI
      if (this.editCI) {
        this.series = ''
        this.number = ''
        this.releaseDate = ''
        this.expirationDate = ''
      }
    },
    formatAddress () {
      this.address.country = typeof this.address.country === 'string' ? this.address.country : this.address.country.name
      this.address.country_code = typeof this.address.country === 'string' ? this.address.country_code : this.address.country.code
      if (this.address.county) {
        this.address.county = typeof this.address.county === 'string' ? this.address.county : this.address.county.name
        this.address.county_code = typeof this.address.county === 'string' ? this.address.county_code : this.address.county.code
      }
      if (this.address.locality) {
        this.address.locality = typeof this.address.locality === 'string' ? this.address.locality : this.address.locality.name
      }
    },

    choseReleaseDay (day) {
      let date = new Date(day.date)

      this.releaseDate = this.formatDate(date)
    },

    choseExpirationDay (day) {
      let date = new Date(day.date)

      this.expirationDate = this.formatDate(date)
    },

    formatDate (date) {
      if (!date) {
        return ''
      }

      let newDate = new Date(date)
      let month = '' + (newDate.getMonth() + 1)
      let day = '' + newDate.getDate()
      let year = newDate.getFullYear()

      if (month.length < 2) {
        month = '0' + month
      }

      if (day.length < 2) {
        day = '0' + day
      }

      return [day, month, year].join('/')
    },

    formatDateForBackEnd (date) {
      if (!date) {
        return ''
      }

      const tmpDate = date.split('/').reverse().join('/')

      let newDate = new Date(tmpDate)

      let month = '' + (newDate.getMonth() + 1)
      let day = '' + newDate.getDate()
      let year = newDate.getFullYear()

      if (month.length < 2) {
        month = '0' + month
      }

      if (day.length < 2) {
        day = '0' + day
      }

      return [year, month, day].join('-')
    },

    setNewAddress (address) {
      this.address = address
    },

    validate () {
      let valid = true

      if (!this.firstName.length) {
        this.validations.firstName = this.$i18n.t('general.validations.enter_first_name')

        valid = false
      }

      if (!this.lastName.length) {
        this.validations.lastName = this.$i18n.t('general.validations.enter_last_name')

        valid = false
      }

      if (!this.cnp.length) {
        this.validations.cnp = this.$i18n.t('general.validations.enter_pin')

        valid = false
      }

      if (this.getUserData.identity_card.type !== 'passport' && (this.series === null || !this.series.length)) {
        this.validations.series = this.$i18n.t('general.validations.enter_series')

        valid = false
      }

      if (!this.number.length) {
        this.validations.number = this.$i18n.t('general.validations.enter_number')

        valid = false
      }

      if (this.expirationDate === null || !this.expirationDate.length) {
        this.validations.expirationDate = this.$i18n.t('general.validations.expiration_date')

        valid = false
      }

      if (!this.address.number || !this.address.street || !this.address.locality || !this.address.country) {
        this.validations.address = this.$i18n.t('general.validations.address_field')

        valid = false
      }

      return valid
    },
    async next () {
      if (this.validate()) {
        let updatedData = this.updatedData

        if (this.address.label !== this.getUserData.domicile_address.label) {
          updatedData.step_identity_card.address = this.address
        }

        if (this.expirationDate !== this.getUserData.identity_card.expiration_date) {
          updatedData.step_identity_card.expiration_date = this.formatDateForBackEnd(this.expirationDate)
        }

        if (this.releaseDate !== this.getUserData.identity_card.release_date) {
          updatedData.step_identity_card.release_date = this.formatDateForBackEnd(this.releaseDate)
        }

        if (this.series !== this.getUserData.identity_card.series) {
          updatedData.step_identity_card.series = this.series
        }

        if (this.number !== this.getUserData.identity_card.number) {
          updatedData.step_identity_card.number = this.number
        }

        if (this.cnp !== this.getUserData.identity_card.personal_identification_number) {
          updatedData.step_identity_card.pin = this.cnp
        }

        if (this.firstName !== this.getUserData.first_name) {
          updatedData.step_identity_card.first_name = this.firstName
        }

        if (this.lastName !== this.getUserData.last_name) {
          updatedData.step_identity_card.last_name = this.lastName
        }

        if (this.file) {
          updatedData.step_identity_card.fileName = this.file.name
          updatedData.step_identity_card.fileType = this.file.type
          await documentsService.saveTmpDocuments({file: this.file}).then(response => {
            updatedData.step_identity_card.identityCard = response.data.data.url
          })
        }

        const identityCardFields = ['address', 'expiration_date', 'release_date', 'series', 'number', 'cnp', 'first_name', 'last_name']

        const identityCardUpdates = Object.keys(this.updatedData.step_identity_card).some(element => {
          return identityCardFields.includes(element)
        })

        if (identityCardUpdates) {
          updatedData.step_identity_card.last_name = this.lastName
          updatedData.step_identity_card.first_name = this.firstName
        }

        if (identityCardUpdates && !this.file
        ) {
          this.validations.identityCardFile = this.$i18n.t('general.validations.upload_id_card')

          return
        }

        let nextStep = 'updateResidence'
        this.$emit('next', {updatedData, nextStep})
      }
    }
  },
  watch: {
    address: {
      handler (address) {
        if (address) {
          this.validations.address = ''
        }
      },
      deep: true
    },
    firstName (nVal) {
      if (nVal) {
        this.validations.firstName = ''
      }
    },
    lastName (nVal) {
      if (nVal) {
        this.validations.lastName = ''
      }
    },
    cnp (nVal) {
      if (nVal) {
        this.validations.cnp = ''
      }
    },
    series (nVal) {
      if (nVal) {
        this.validations.series = ''
      }
    },
    number (nVal) {
      if (nVal) {
        this.validations.number = ''
      }
    },
    expirationDate (nVal) {
      if (nVal) {
        this.validations.expirationDate = ''
      }
    },
    releaseDate (nVal) {
      if (nVal) {
        this.validations.releaseDate = ''
      }
    },
    file (nVal) {
      if (nVal) {
        this.validations.file = ''
      }
    }
  }
}
</script>

<style scoped>

</style>
