import { render, staticRenderFns } from "./MultipleQuestion.vue?vue&type=template&id=733cad09&scoped=true"
import script from "./MultipleQuestion.vue?vue&type=script&lang=js"
export * from "./MultipleQuestion.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "733cad09",
  null
  
)

export default component.exports