<template>
  <div>
    <vue-title :title="$t('general.meta_titles.documents')"></vue-title>

    <div class="rounded-lg bg-primary m-auto w-fit">
      <StepBar :current-step="4" :steps="tabs"></StepBar>
    </div>

    <Card v-if="loaded" :card-classes="'margin-bottom-medium mobile-border-none'">
      <template v-slot:top>
        <img src="~@/assets/img/icons/declaratie_icon.svg">
      </template>

      <template v-slot:title>
        <div>{{ $t('onboarding.documents.statement') }}</div>
      </template>

      <template v-slot:body>
        <div>
          <div class="mb-10">
            {{ $t('onboarding.documents.personal_statement') }}
          </div>

          <div class="mb-10">
            <Checkbox
              :id="'first-check'"
              v-model="agreements.cleanClientHistory"
              :label="$t('onboarding.documents.client_history')"
            />

            <Message v-if="!validations.cleanClientHistory" :message="$t('general.validations.checkbox_validation')"
                     :type="'error'"/>
          </div>

          <div class="mb-10">
            <Checkbox
              :id="'second-check'"
              v-model="agreements.cleanClientData"
              :label="$t('onboarding.documents.client_data')"
            />

            <Message v-if="!validations.cleanClientData" :message="$t('general.validations.checkbox_validation')"
                     :type="'error'"/>
          </div>

          <div class="mb-10">
            <Checkbox
              :id="'third-check'"
              v-model="agreements.acceptedDocuments"
              :label="$t('onboarding.documents.accepted_documents')"
            />

            <div v-for="(document) in documents" :key="document.id" class="ml-8">
              <div class="cursor-pointer color-blue text-lg" @click="download(document)">
                {{ document.label }}
              </div>
            </div>

            <Message v-if="!validations.acceptedDocuments" :message="$t('general.validations.checkbox_validation')"
                     :type="'error'"/>
          </div>

          <div class="text-lg font-semibold mt-8 mb-9">
            {{ $t('onboarding.documents.consent') }}
          </div>

          <div class="flex justify-between items-center mt-10">
            <Button
              @call="resetStep()"
              :text="$t('general.general.back')"
              :type="'btn btn-secondary padding-medium end-position no-wrap'"
              :icon="'icons/arrow_back_green'"
              :icon-position="'left'"
            />
            <div class="w-fit">
              <Button :loading="loadingData.markDocuments"
                      @call="markDocumentAsAccepted"
                      :text="$t('general.general.complete')"
                      :type="'btn btn-primary padding-big'"/>
            </div>
          </div>
        </div>
      </template>
    </Card>

    <pulse-loader v-if="!loaded" class="m-auto w-fit"/>

    <Message v-if="!validateServerError" :formatIsHtml="true"
             :message="$t('general.validations.documents_server_error')" :type="'error'"/>
  </div>
</template>

<script>
import StepBar from '../layout/StepBar'
import ApiService from '../../services/apiService'
import questionnaireService from '../../services/onboarding/questionnaireService'
import Button from '../subcomponents/Button'
import Message from '../subcomponents/Message'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import Card from '../subcomponents/Card'
import Checkbox from '../subcomponents/Checkbox'
import {ONBOARDING_QUESTIONNAIRE} from '../../router'
import FillPersonalDocument from '../../mixins/FillPersonalDocument'

export default {
  name: 'Documents',
  components: {Card, Message, Button, StepBar, PulseLoader, Checkbox},
  mixins: [FillPersonalDocument],
  data () {
    return {
      loadingData: {
        markDocuments: false
      },
      tabs: [
        {
          id: 1,
          label: this.$i18n.t('onboarding.general.create_account')
        },
        {
          id: 2,
          label: this.$i18n.t('onboarding.general.identity_verification')
        },
        {
          id: 3,
          label: this.$i18n.t('onboarding.general.financial_data')
        }
      ],
      documents: [],
      loaded: false,
      agreements: {
        cleanClientHistory: false,
        cleanClientData: false,
        acceptedDocuments: false
      },
      validations: {
        cleanClientHistory: true,
        cleanClientData: true,
        acceptedDocuments: true
      },
      validateServerError: true
    }
  },
  async mounted () {
    await this.fillDocuments()
  },
  methods: {
    makeRequest () {
      return ApiService.withAuth().post('/api/user/documents/generate')
    },
    async markDocumentAsAccepted () {
      this.loadingData.markDocuments = true
      try {
        if (!this.validate()) {
          return
        }
        this.loaded = false
        let documentResponse = await ApiService.withAuth().post('/api/user/documents/consent', {
          agreements: this.agreements
        })

        if (documentResponse.status === 200) {
          this.loaded = true
          await this.$router.push({name: 'homepage'})
        }
      } finally {
        this.loadingData.markDocuments = false
      }
    },
    async resetStep () {
      await this.$confirm(
        this.$t('onboarding.reset_steps.reset_questionnaire'),
        '',
        'warning',
        {cancelButtonText: this.$t('general.general.cancel')}
      ).then(async () => {
        let reset = await questionnaireService.resetStep({reset_questionnaire: true})

        if (reset.status === 200) {
          await this.$router.push({name: ONBOARDING_QUESTIONNAIRE})
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
