<template>
  <div class="card accordion bold-black" :class="[cardClasses]">
    <div v-if="hasSlot('title')" class="title">
      <IconColor v-if="icon" :color="iconColor" :icon="icon" :positions="iconPosition" />
      <span><slot name="title"></slot></span>
      <div class="arrow" :class="{ active: show }">
        <img
          @click="toggle"
          width="41px"
          height="41px"
          :src="require(`@/assets/img/icons/arrow-outline.svg`)"
        />
      </div>
    </div>
    <Transition name="accordion" v-on:before-enter="beforeEnter" v-on:enter="enter" v-on:before-leave="beforeLeave" v-on:leave="leave">
      <div class="body" v-show="show">
        <slot v-if="hasSlot('body')" name="body"></slot>
      </div>
    </Transition>
  </div>
</template>

<script>
import IconColor from './IconColor.vue'
export default {
  name: 'Accordion',
  components: { IconColor },
  props: {
    cardClasses: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    iconColor: {
      type: String,
      default: ''
    },
    iconPosition: {
      type: Array,
      default: () => []
    }
  },
  data: function () {
    return {
      show: false
    }
  },
  methods: {
    hasSlot (slotName) {
      return !!this.$slots[slotName]
    },
    toggle: function () {
      this.show = !this.show
    },
    beforeEnter: function (el) {
      el.style.height = '0'
    },
    enter: function (el) {
      el.style.height = '100%'
    },
    beforeLeave: function (el) {
      el.style.height = '100%'
    },
    leave: function (el) {
      el.style.height = '0'
    }
  }
}
</script>
