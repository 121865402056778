import Vue from 'vue'
import Router from 'vue-router'
import Login from '../components/auth/Login'
import Register from '../components/onboarding/Register'
import LegalPersonRegister from '../components/onboarding/LegalPersonRegister'
import OnboardingInfo from '../components/onboarding/OnboardingInfo'
import Questionnaire from '../components/onboarding/Questionnaire'
import {store} from '@/store'
import Dashboard from '../components/profile/Dashboard'
import TwoFactorAuthentication from '../components/auth/TwoFactorAuthentication'
import VerifyIdentity from '../components/onboarding/VerifyIdentity'
import PasswordReset from '../components/auth/PasswordReset'
import EmailVerification from '../components/onboarding/EmailVerification'
import MultiFactorAuthentication from '../components/profile/TwoFactorAuthenticationActivationPage'
import Settings from '../components/profile/settings/Settings'
import PersonalData from '../components/profile/settings/PersonalData/PersonalData'
import FiscalData from '../components/profile/settings/FiscalData/FiscalData'
import BankAccounts from '../components/profile/deposit-withdrawal/BankAccounts'
import ManageCards from '../components/profile/deposit-withdrawal/ManageCards'
import Deposits from '../components/profile/deposit-withdrawal/Deposits'
import Withdrawals from '../components/profile/deposit-withdrawal/Withdrawals'
import Portfolio from '../components/profile/deposit-withdrawal/Portfolio'
import Documents from '../components/onboarding/Documents'
import SelectResidence from '../components/onboarding/SelectResidence'
import PasswordRecoverySendLink from '../components/auth/PasswordRecoverySendLink'
import PasswordRecovery from '../components/auth/PasswordRecovery'
import ProfileDocuments from '../components/profile/documents/ProfileDocuments'
import Configurations from '../components/profile/notifications/Configurations'
import Notifications from '../components/profile/notifications/Notifications'
import SelectDomicile from '../components/onboarding/SelectDomicile'
import CommonComponents from '../components/subcomponents/CommonComponents'
import VerifyBankAccount from '../components/onboarding/VerifyBankAccount'
import ReEvaluate from '../components/profile/documents/ReEvaluate'
import ClientDocuments from '../components/profile/documents/ClientDocuments'
import InstrumentsTransfer from '../components/profile/deposit-withdrawal/InstrumentsTransfer'
import AccountTransfers from '../components/profile/deposit-withdrawal/AccountTransfers'
import ReviewOcrData from '../components/onboarding/ReviewOcrData'
import Messages from '../components/profile/notifications/Messages'
import UpdatePersonalData from '../components/profile/update-personal-data/UpdatePersonalData.vue'
import ForceTwoFactorAuthentication from '../components/auth/ForceTwoFactorAuthentication.vue'
import Help from '../components/profile/help/Help.vue'
import FiscalObligations from '@/components/profile/deposit-withdrawal/FiscalObligations.vue'
import Report from '@/components/profile/deposit-withdrawal/Report.vue'
import NotificationDocuments from '../components/profile/documents/NotificationDocuments.vue'
import MultiAccount from '@/components/profile/deposit-withdrawal/MultiAccount.vue'
import PhoneValidation from '@/components/auth/PhoneValidation.vue'
import LegalPersonDetails from '@/components/onboarding/LegalPersonDetails.vue'
import LegalPersonData from '@/components/profile/settings/LegalPersonData.vue'
import LegalPersonDocuments from '@/components/profile/documents/LegalPersonDocuments.vue'
import Invoices from '@/components/profile/documents/Invoices.vue'
import AutoInvest from '@/components/profile/deposit-withdrawal/auto-invest/AutoInvest.vue'
import MorningNews from '@/components/profile/notifications/MorningNews.vue'
import AnalysesNews from '@/components/profile/notifications/AnalysesNews.vue'
import FlashReport from '@/components/profile/notifications/FlashReport.vue'
import ForceResetPasswordPage from '@/components/auth/ForceResetPasswordPage.vue'
import ShowPie from '@/components/profile/deposit-withdrawal/auto-invest/ShowPie.vue'

Vue.use(Router)

export const ONBOARDING_EMAIL_VERIFICATION = 'onboarding-email-verification'
export const ONBOARDING_INFO = 'onboarding-info'
export const ONBOARDING_SELECT_DOMICILE = 'onboarding-select-domicile'
export const ONBOARDING_VERIFY_IDENTITY = 'onboarding-verify-identity'
export const ONBOARDING_REVIEW_OCR_DATA = 'onboarding-review-ocr-data'
export const ONBOARDING_BANK_ACCOUNT = 'onboarding-verify-bank-account'
export const ONBOARDING_QUESTIONNAIRE = 'onboarding-questionnaire'
export const ONBOARDING_DOCUMENTS = 'onboarding-documents'
export const ONBOARDING_LEGAL_PERSON_DETAILS = 'onboarding-legal-person-details'
export const ONBOARDING_SELECT_RESIDENCE = 'onboarding-select-residence'
export const FORCE_TWO_FACTOR_AUTHENTICATION_ACTIVATION = 'force-two-factor-authentication-activation'
export const FORCE_RESET_PASSWORD = 'force-reset-password'
export const ONBOARDING_PHONE_VALIDATION = 'onboarding-phone-validation'

let routes = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'homepage',
      component: Dashboard,
      meta: {
        requiresAuth: true
      },
      redirect: to => {
        return {name: 'settings'}
      }
    },
    {
      path: '/settings',
      name: 'settings',
      component: Settings,
      meta: {
        menu: 'settings',
        hideNav: true,
        requiresAuth: true
      }
    },
    {
      path: '/personal-data',
      name: 'personal-data',
      component: PersonalData,
      meta: {
        menu: 'settings',
        hideNav: true,
        requiresAuth: true
      }
    },
    {
      path: '/pj-data',
      name: 'pj-data',
      component: LegalPersonData,
      meta: {
        menu: 'settings',
        hideNav: true,
        requiresAuth: true
      }
    },
    {
      path: '/pj-documents',
      name: 'pj-documents',
      component: LegalPersonDocuments,
      meta: {
        menu: 'settings',
        hideNav: true,
        requiresAuth: true
      }
    },
    {
      path: '/invoices',
      name: 'invoices',
      component: Invoices,
      meta: {
        menu: 'settings',
        hideNav: true,
        requiresAuth: true
      }
    },
    {
      path: '/fiscal-data',
      name: 'fiscal-data',
      component: FiscalData,
      meta: {
        menu: 'settings',
        hideNav: true,
        requiresAuth: true
      }
    },
    {
      path: '/bank-accounts',
      name: 'bank-accounts',
      component: BankAccounts,
      meta: {
        menu: 'bankAccounts',
        hideNav: true,
        requiresAuth: true
      }
    },
    {
      path: '/manage-cards',
      name: 'manage-cards',
      component: ManageCards,
      meta: {
        hideNav: true,
        requiresAuth: true
      }
    },
    {
      path: '/profile-documents',
      name: 'profile-documents',
      component: ProfileDocuments,
      meta: {
        menu: 'settings',
        hideNav: true,
        requiresAuth: true
      }
    },
    {
      path: '/client-documents',
      name: 'client-documents',
      component: ClientDocuments,
      meta: {
        menu: 'settings',
        hideNav: true,
        requiresAuth: true
      }
    },
    {
      path: '/notification-documents',
      name: 'notification-documents',
      component: NotificationDocuments,
      meta: {
        menu: 'settings',
        hideNav: true,
        requiresAuth: true
      }
    },
    {
      path: '/re-evaluate',
      name: 're-evaluate',
      component: ReEvaluate,
      meta: {
        hideNav: true,
        requiresAuth: true
      }
    },
    {
      path: '/deposits',
      name: 'deposits',
      component: Deposits,
      meta: {
        menu: 'bankAccounts',
        hideNav: true,
        requiresAuth: true
      }
    },
    {
      path: '/withdrawals',
      name: 'withdrawals',
      component: Withdrawals,
      meta: {
        menu: 'bankAccounts',
        hideNav: true,
        requiresAuth: true
      }
    },
    {
      path: '/instruments-transfer',
      name: 'instruments-transfer',
      component: InstrumentsTransfer,
      meta: {
        menu: 'assets',
        hideNav: true,
        requiresAuth: true
      }
    },
    {
      path: '/account-transfers',
      name: 'account-transfers',
      component: AccountTransfers,
      meta: {
        menu: 'bankAccounts',
        hideNav: true,
        requiresAuth: true
      }
    },
    {
      path: '/portfolio',
      name: 'portfolio',
      component: Portfolio,
      meta: {
        menu: 'bankAccounts',
        parent: 'bank-accounts-index',
        hideNav: true,
        requiresAuth: true
      }
    },
    {
      path: '/register',
      name: 'register',
      component: Register,
      meta: {
        requiresNoAuth: true
      }
    },
    {
      path: '/register-pj',
      name: 'register-pj',
      component: LegalPersonRegister,
      meta: {
        requiresNoAuth: true,
        source: 'PJ'
      }
    },
    {
      path: '/password-reset',
      name: 'password-reset',
      component: PasswordReset,
      meta: {
        menu: 'settings',
        hideNav: true,
        requiresAuth: true
      }
    },
    {
      path: '/password-recovery-send-link',
      name: 'password-recovery-send-link',
      component: PasswordRecoverySendLink,
      meta: {
        requiresNoAuth: true
      }
    },
    {
      path: '/password-recovery/:token',
      name: 'password-recovery',
      component: PasswordRecovery,
      meta: {
        requiresNoAuth: true
      }
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
      meta: {
        requiresNoAuth: true,
        basicLayout: true
      }
    },
    {
      path: '/two-factor-authentication-activation',
      name: 'two-factor-authentication-activation',
      component: MultiFactorAuthentication,
      meta: {
        menu: 'security',
        hideNav: true,
        requiresAuth: true
      }
    },
    {
      path: '/force-two-factor-authentication-activation',
      name: FORCE_TWO_FACTOR_AUTHENTICATION_ACTIVATION,
      component: ForceTwoFactorAuthentication,
      meta: {
        basicLayout: true,
        requiresAuth: true
      }
    },
    {
      path: '/force-reset-password',
      name: FORCE_RESET_PASSWORD,
      component: ForceResetPasswordPage,
      meta: {
        basicLayout: true,
        requiresAuth: true
      }
    },
    {
      path: '/login/two-factor',
      name: 'two-factor',
      component: TwoFactorAuthentication,
      meta: {
        requiresNoAuth: true
      }
    },
    {
      path: '/configure-notifications',
      name: 'configure-notifications',
      component: Configurations,
      meta: {
        menu: 'notifications',
        requiresAuth: true
      }
    },
    {
      path: '/notifications',
      name: 'notifications',
      component: Notifications,
      meta: {
        menu: 'notifications',
        requiresAuth: true
      }
    },
    {
      path: '/messages',
      name: 'messages',
      component: Messages,
      meta: {
        menu: 'notifications',
        requiresAuth: true
      }
    },
    {
      path: '/morning-news',
      name: 'morning-news',
      component: MorningNews,
      meta: {
        menu: 'notifications',
        requiresAuth: true
      }
    },
    {
      path: '/analyses',
      name: 'analyses',
      component: AnalysesNews,
      meta: {
        menu: 'notifications',
        requiresAuth: true
      }
    },
    {
      path: '/flash-report',
      name: 'flash-report',
      component: FlashReport,
      meta: {
        menu: 'notifications',
        requiresAuth: true
      }
    },
    {
      path: '/email-verification',
      name: ONBOARDING_EMAIL_VERIFICATION,
      component: EmailVerification,
      meta: {
        requiresAuth: true,
        isOnboarding: true
      }
    },
    {
      path: '/phone-validation',
      name: ONBOARDING_PHONE_VALIDATION,
      component: PhoneValidation,
      meta: {
        requiresAuth: true,
        isOnboarding: true,
        source: 'PJ'
      }
    },
    {
      path: '/prepare-onboarding',
      name: ONBOARDING_INFO,
      component: OnboardingInfo,
      meta: {
        requiresAuth: true,
        isOnboarding: true,
        basicLayout: true
      }
    },
    {
      path: '/select-domicile',
      name: ONBOARDING_SELECT_DOMICILE,
      component: SelectDomicile,
      meta: {
        requiresAuth: true,
        isOnboarding: true
      }
    },
    {
      path: '/verify-identity',
      name: ONBOARDING_VERIFY_IDENTITY,
      component: VerifyIdentity,
      meta: {
        requiresAuth: true,
        isOnboarding: true
      }
    },
    {
      path: '/review-ocr-data',
      name: ONBOARDING_REVIEW_OCR_DATA,
      component: ReviewOcrData,
      meta: {
        requiresAuth: true,
        isOnboarding: true
      }
    },
    {
      path: '/select-residence',
      name: ONBOARDING_SELECT_RESIDENCE,
      component: SelectResidence,
      meta: {
        requiresAuth: true,
        isOnboarding: true
      }
    },
    {
      path: '/verify-bank-account',
      name: ONBOARDING_BANK_ACCOUNT,
      component: VerifyBankAccount,
      meta: {
        requiresAuth: true,
        isOnboarding: true
      }
    },
    {
      path: '/questionnaire',
      name: ONBOARDING_QUESTIONNAIRE,
      component: Questionnaire,
      meta: {
        requiresAuth: true,
        isOnboarding: true
      }
    },
    {
      path: '/documents',
      name: ONBOARDING_DOCUMENTS,
      component: Documents,
      meta: {
        requiresAuth: true,
        isOnboarding: true
      }
    },
    {
      path: '/legal-person-details',
      name: ONBOARDING_LEGAL_PERSON_DETAILS,
      component: LegalPersonDetails,
      meta: {
        requiresAuth: false,
        isOnboarding: true
      }
    },
    {
      path: '/common-components',
      name: 'common-components',
      component: CommonComponents,
      meta: {
        requiresAuth: false
      }
    },
    {
      path: '/register-lms',
      name: 'register-lms',
      component: Register,
      meta: {
        requiresNoAuth: true,
        source: 'lms'
      }
    },
    {
      path: '/email-verification-lms',
      name: 'email-verification-lms',
      component: EmailVerification,
      meta: {
        isOnboarding: true,
        requiresAuth: true,
        source: 'lms'
      }
    },
    {
      path: '/email-verification-pj',
      name: 'email-verification-pj',
      component: EmailVerification,
      meta: {
        isOnboarding: true,
        requiresAuth: true,
        source: 'PJ'
      }
    },
    {
      path: '/login-lms',
      name: 'login-lms',
      component: Login,
      meta: {
        requiresNoAuth: true,
        source: 'lms'
      }
    },
    {
      path: '/password-recovery-send-link-lms',
      name: 'password-recovery-send-link-lms',
      component: PasswordRecoverySendLink,
      meta: {
        requiresNoAuth: true,
        source: 'lms'
      }
    },
    {
      path: '/update-personal-data',
      name: 'update-personal-data',
      component: UpdatePersonalData,
      meta: {
        requiresAuth: true,
        isOnboarding: true
      }
    },
    {
      path: '/portofolio',
      name: 'portofolio',
      component: Portfolio,
      meta: {
        menu: 'assets',
        requiresAuth: true
      }
    },
    {
      path: '/fiscal-debt',
      name: 'fiscal-debt',
      component: FiscalObligations,
      meta: {
        menu: 'assets',
        requiresAuth: true
      }
    },
    {
      path: '/multi-account',
      name: 'multi-account',
      component: MultiAccount,
      meta: {
        menu: 'assets',
        requiresAuth: true
      }
    },
    {
      path: '/pies',
      name: 'pies',
      component: AutoInvest,
      meta: {
        menu: 'assets',
        requiresAuth: true
      }
    },
    {
      path: '/pies/:id',
      name: 'pie',
      component: ShowPie,
      meta: {
        menu: 'assets',
        requiresAuth: true
      }
    },
    {
      path: '/help',
      name: 'help',
      component: Help,
      meta: {
        menu: 'help',
        requiresAuth: true
      }
    },
    {
      path: '/report',
      name: 'report',
      component: Report,
      meta: {
        menu: 'bankAccounts',
        requiresAuth: true
      }
    }
  ]
})

let alreadyFetched = false
let user = null

routes.beforeEach(async (to, from, next) => {
  await store.dispatch('layout/setGeneralLoading', true)

  if (store.getters['pies/pieId'] && to.name !== 'pie') {
    await store.dispatch('pies/setPieId', null)
  }

  if (to.meta.menu) {
    await store.dispatch('menus/setMenu', to.meta.menu)
  } else {
    await store.dispatch('menus/setMenu', null)
  }

  if (store.getters['user/isLogged'] && !alreadyFetched) {
    user = await store.dispatch('user/fetch', null)
    alreadyFetched = true
  }

  let userData = user ? user.data.data.user : null

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters['user/isLogged'] && userData.has_force_reset_password && !userData.has_access_in_portal && to.name !== FORCE_RESET_PASSWORD) {
      next({name: FORCE_RESET_PASSWORD})

      return
    }

    if (store.getters['user/isLogged'] && userData.need_two_factor_auth && userData.has_access_in_portal && to.name !== FORCE_TWO_FACTOR_AUTHENTICATION_ACTIVATION) {
      next({name: FORCE_TWO_FACTOR_AUTHENTICATION_ACTIVATION})

      return
    }

    if (!store.getters['user/isLogged']) {
      alreadyFetched = false

      next({name: 'login'})

      await store.dispatch('layout/setGeneralLoading', false)

      return
    } else if (to.meta.isOnboarding && to.name !== 'update-personal-data' && from.meta.source !== 'PJ' && store.getters['user/user'].type !== 'company') {
      let redirected = onBoardingMiddleware(next, userData, to)

      if (redirected) {
        await store.dispatch('layout/setGeneralLoading', false)

        return
      }

      alreadyFetched = false

      await store.dispatch('layout/setGeneralLoading', false)

      next()

      return
    }
  } else {
    alreadyFetched = false

    next()

    await store.dispatch('layout/setGeneralLoading', false)

    return
  }

  if (to.matched.some(record => record.meta.requiresNoAuth)) {
    alreadyFetched = false

    if (store.getters['user/isLogged']) {
      next({name: 'homepage'})

      await store.dispatch('layout/setGeneralLoading', false)
    } else {
      next()

      await store.dispatch('layout/setGeneralLoading', false)
    }
  } else {
    next()

    await store.dispatch('layout/setGeneralLoading', false)
  }

  await store.dispatch('layout/setGeneralLoading', false)
})

routes.afterEach(async (to, from) => {
  await store.dispatch('layout/setType', to)
})

function onBoardingMiddleware (next, user, to) {
  if (!user.has_verified_email && user.type !== 'company') {
    if (to.name === ONBOARDING_EMAIL_VERIFICATION || to.name === 'email-verification-lms') {
      return false
    }
    if (to.meta.source === 'lms') {
      next({name: 'email-verification-lms'})
    } else {
      next({name: ONBOARDING_EMAIL_VERIFICATION})
    }

    return true
  } else if (to.name === ONBOARDING_EMAIL_VERIFICATION) {
    next({name: ONBOARDING_INFO})
    return true
  }

  if (to.name === ONBOARDING_INFO && !user.has_completed_domicile) {
    return false
  }

  if (!user.has_completed_domicile && user.type !== 'company') {
    if (to.name === ONBOARDING_SELECT_DOMICILE) {
      return false
    }

    next({name: ONBOARDING_SELECT_DOMICILE})

    return true
  } else if (to.name === ONBOARDING_SELECT_DOMICILE) {
    next({name: ONBOARDING_VERIFY_IDENTITY})

    return true
  }

  if (!user.has_identity_verification_required && user.type !== 'company') {
    if (to.name === ONBOARDING_VERIFY_IDENTITY) {
      return false
    }

    next({name: ONBOARDING_VERIFY_IDENTITY})

    return true
  } else if (to.name === ONBOARDING_VERIFY_IDENTITY) {
    next({name: ONBOARDING_REVIEW_OCR_DATA})

    return true
  }

  if (!user.has_reviewed_ocr_data_at && user.type !== 'company') {
    if (to.name === ONBOARDING_REVIEW_OCR_DATA) {
      return false
    }

    next({name: ONBOARDING_REVIEW_OCR_DATA})

    return true
  } else if (to.name === ONBOARDING_REVIEW_OCR_DATA) {
    next({name: ONBOARDING_BANK_ACCOUNT})

    return true
  }

  if (!user.has_bank_account_completed && user.type !== 'company') {
    if (to.name === ONBOARDING_BANK_ACCOUNT) {
      return false
    }

    next({name: ONBOARDING_BANK_ACCOUNT})

    return true
  } else if (to.name === ONBOARDING_BANK_ACCOUNT) {
    next({name: ONBOARDING_SELECT_RESIDENCE})

    return true
  }

  if (!user.has_completed_residence && user.type !== 'company') {
    if (to.name === ONBOARDING_SELECT_RESIDENCE) {
      return false
    }

    next({name: ONBOARDING_SELECT_RESIDENCE})

    return true
  } else if (to.name === ONBOARDING_SELECT_RESIDENCE) {
    next({name: ONBOARDING_QUESTIONNAIRE})

    return true
  }

  if (!user.has_questionnaire_completed && user.type !== 'company') {
    if (to.name === ONBOARDING_QUESTIONNAIRE) {
      return false
    }

    next({name: ONBOARDING_QUESTIONNAIRE})

    return true
  } else if (to.name === ONBOARDING_QUESTIONNAIRE) {
    next({name: ONBOARDING_DOCUMENTS})

    return true
  }

  if (!user.has_documents_consent && user.type !== 'company') {
    if (to.name === ONBOARDING_DOCUMENTS) {
      return false
    }

    next({name: ONBOARDING_DOCUMENTS})

    return true
  } else if (to.name === ONBOARDING_DOCUMENTS) {
    next({name: 'homepage'})

    return true
  }

  return false
}

export default routes
