<template>
  <div class="help">
    <vue-title :title="$t('general.meta_titles.help')"></vue-title>
    <general-layout>
      <template v-slot:tabs>
        <Tabs
          :show-id-title="false"
        />
      </template>

      <template v-slot:body>
        <div class="info">
          <div class="info-title desktop-flex justify-between">
            {{ $t('help.general.info_title') }}
            <Button v-if="isDesktop && user.type !== 'company'" :text="$t('guides.general.retake_guide')" @call="retryTour"></Button>
          </div>
          <div class="info-text" v-html="$t('help.general.info')"></div>
        </div>

        <Button class="my-5" v-if="!isDesktop && user.type !== 'company'" :text="$t('guides.general.retake_guide')" @call="retryTour"></Button>

        <div class="section" v-for="section in sections" :key="section.title">
          <div class="section-title">
            {{ section.title }}
          </div>
          <div class="links">
            <icon-box
              v-for="link in section.links"
              :key="link.label"
              :icon="link.icon_path"
              :icon-color="link.icon_color"
              :icon-positions="[link.position]"
              :text="link.label"
              :href="link.link"
            />
          </div>
        </div>
      </template>
    </general-layout>
  </div>
</template>

<script>
import GeneralLayout from '../../layout/GeneralLayout'
import Tabs from '../../layout/Tabs'
import IconBox from '../../subcomponents/IconBox.vue'
import HelpLinks from '../../../mixins/HelpLinks'
import Button from '@/components/subcomponents/Button.vue'
import {mapActions, mapGetters} from 'vuex'
import Vue from 'vue'
import UserService from '@/services/auth/userService'

export default {
  name: 'Help',
  components: {Button, IconBox, GeneralLayout, Tabs},
  mixins: [HelpLinks],
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters({
      user: 'user/user',
      screenDevice: 'layout/screenDevice'
    }),
    isDesktop () {
      return this.screenDevice === this.$screenDeviceConstants.DESKTOP
    },
    useNativeGuide () {
      return process.env.VUE_APP_USE_NATIVE_GUIDE === 'true'
    }
  },
  methods: {
    ...mapActions({
      updateUser: 'user/updateUser'
    }),
    async retryTour () {
      if (this.useNativeGuide) {
        await this.updateUser({'guide_completed': 0})
        await this.$router.push({name: 'settings'})
      } else {
        let tourIds = window.productFruits.api.tours.getTours()
        let tourId = tourIds.filter(tour => this.isDesktop ? tour.name.includes('Desktop') : tour.name.includes('Mobile'))[0].id

        window.productFruits.api.tours.tryStartTour(tourId)
      }
    }
  }
}
</script>
