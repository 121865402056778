<template>
  <div>
    <div v-for="(question, questionIndex) in questions" :key="questionIndex">
      <div>
        <ButtonQuestion v-if="question.type === 'button'"
                        :parent="parent"
                        :key="componentKey[question.identifier]"
                        :pre-selected-answer="selectedQuestionsAnswers[question.identifier]"
                        :question="question"
                        :disabled="disabled"
                        @toggleDisabledQuestion="toggleDisabledQuestion"
                        @setAnswers="setAnswers"/>

        <SelectorQuestion v-if="question.type === 'selector'"
                          :parent="parent"
                          :key="componentKey[question.identifier]"
                          :pre-selected-answer="selectedQuestionsAnswers[question.identifier]"
                          :question="question"
                          :details-error="detailsError"
                          :disabled="disabled"
                          @toggleDisabledQuestion="toggleDisabledQuestion"
                          @setAnswers="setAnswers"/>

        <InputQuestion v-if="question.type === 'input'"
                       :parent="parent"
                       :key="componentKey[question.identifier]"
                       :pre-selected-answer="selectedQuestionsAnswers[question.identifier]"
                       :question="question"
                       :disabled="disabled"
                       @toggleDisabledQuestion="toggleDisabledQuestion"
                       @setAnswers="setAnswers"/>
      </div>
      <div v-if="questionIndex < questions.length -1" class="mb-2">Sau</div>
    </div>
  </div>
</template>

<script>
import ButtonQuestion from './ButtonQuestion'
import SelectorQuestion from './SelectorQuestion'
import Button from '../../subcomponents/Button'
import InputQuestion from './InputQuestion'
import {ref} from 'vue'

export default {
  name: 'MultipleQuestion',
  components: {InputQuestion, ButtonQuestion, SelectorQuestion, Button},
  props: {
    parent: {
      type: String,
      default: 'onboarding'
    },
    questions: {
      type: Array,
      required: true
    },
    identifier: {
      type: String,
      required: true
    },
    questionsAnswers: {
      type: Object,
      default: () => {
        return {}
      }
    },
    detailsError: {
      type: String,
      default: ''
    },
    disabledQuestion: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      selectedQuestionsAnswers: {},
      componentKey: {},
      questionIdentifier: '',
      disabled: true
    }
  },
  beforeMount () {
    this.selectedQuestionsAnswers = this.questionsAnswers
    this.disabled = this.disabledQuestion

    this.$emit('setAnswers', this.identifier, this.selectedQuestionsAnswers)

    this.questions.forEach((question) => {
      this.$set(this.componentKey, question.identifier, ref(0))
    })
  },
  methods: {
    async forceRerender (questionIdentifier) {
      if (questionIdentifier === '') {
        return
      }
      this.componentKey[questionIdentifier]++
    },
    resetSelectedQuestionsAnswers () {
      this.selectedQuestionsAnswers = {}
    },
    setAnswers (identifier, answers) {
      if (this.emptyAnswers(answers)) {
        return
      }

      if (this.questionIdentifier !== identifier && this.questionIdentifier !== '') {
        this.forceRerender(this.questionIdentifier)
      }

      this.resetSelectedQuestionsAnswers()
      this.$set(this.selectedQuestionsAnswers, identifier, answers)
      this.questionIdentifier = identifier

      this.$emit('setAnswers', this.identifier, this.selectedQuestionsAnswers)
    },
    emptyAnswers (answers) {
      if (typeof answers === 'object') {
        return !Object.keys(answers).length
      }

      if (typeof answers === 'string') {
        return answers === ''
      }

      return !answers
    },
    toggleDisabledQuestion (disabledQuestion) {
      this.disabled = disabledQuestion
      this.$emit('toggleDisabledQuestion', disabledQuestion)
    }
  }
}
</script>

<style scoped>

</style>
