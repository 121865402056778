<template>
  <div>
    <div class="table-targets w-full" v-if="!loadingInstruments">
      <div v-for="(instrument, index) in addedInstruments" :key="index" class="target-instrument with-bg">
        <div class="flex flex-col gap-4">
          <div class="flex justify-between items-center">
            <div class="flex items-center gap-3">
              <StockSecurityLogo :img="instrument.stock_security.logo" :smaller-logo="true" :symbol="instrument.stock_security.symbol" :color="colorBlock[index % 5]"/>
              <div>
                {{ instrument.stock_security.name ? instrument.stock_security.name : '-' }}
              </div>
            </div>

            <div class="flex gap-3.5">
              <div class="remove-text">{{ $t('deposit_withdrawal.auto_invest.targets.remove') }}</div>
              <img
                class="cursor-pointer remove-img"
                alt=""
                :src="require('@/assets/img/icons/circled_minus_white_bg.svg')"
                :class="{'opacity-50 pointer-events-none': instruments.length < minimumInstrumentsOfPie ||
                (instrument.current_value ? parseFloat(instrument.current_value).toFixed(2) !== '0.00' : false) }"
                @click="removeInstrument(instrument)"
              >
            </div>
          </div>

          <div class="flex w-full">
            <div class="w-full">
              <input
                type="range"
                min="1"
                max="100"
                :id="`range-${index}`"
                :value="instrument.target_shown"
                @input="changeTarget($event, index)"
                class="slider w-full"
                :class="[`color-${index % 5}`]"
              />
            </div>
            <div class="special-target-input-wrapper">
              <div class="special-target-input" :class="{'error-color': total !== 100}">
                <InputText
                  id="'pie-name'"
                  :value="instrument.target_shown"
                  @input="changeTargetInput($event, index)"
                />
                <div>%</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <pulse-loader v-if="loadingInstruments" />
  </div>
</template>

<script>
import StockSecurityLogo from '@/components/subcomponents/StockSecurityLogo.vue'
import InputText from '@/components/subcomponents/InputText.vue'
import {mapGetters} from 'vuex'

export default {
  name: 'TargetsInstrumentTableMobile',
  components: {InputText, StockSecurityLogo},
  props: {
    total: {
      type: Number,
      default: 0
    },
    instruments: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapGetters({
      newPie: 'pies/newPie',
      minimumInstrumentsOfPie: 'user/minimumInstrumentsOfPie'
    })
  },
  data () {
    return {
      addedInstruments: [],
      colorBlock: [
        'light-blue',
        'dark-blue',
        'yellow',
        'primary',
        'pink'
      ],
      loadingInstruments: false
    }
  },
  mounted () {
    this.addedInstruments = this.instruments
  },
  methods: {
    setPositions () {
      this.$emit('setPositions', this.addedInstruments)
    },
    removeInstrument (instrument) {
      this.$emit('removeInstrument', instrument)
    },
    changeTarget (event, index) {
      this.addedInstruments[index].target_percentage_of_pie = event.target.value
      this.addedInstruments[index].target_shown = Math.round(event.target.value * 100) / 100

      if (event.target.value < 1) {
        this.$emit('validationPercentage', this.$i18n.t('deposit_withdrawal.auto_invest.targets.validation_min_percentage'))
      } else {
        this.$emit('validationPercentage', '')
      }
    },
    changeTargetInput (event, index) {
      this.addedInstruments[index].target_percentage_of_pie = event
      this.addedInstruments[index].target_shown = Math.round(event * 100) / 100

      if (event < 1) {
        this.$emit('validationPercentage', this.$i18n.t('deposit_withdrawal.auto_invest.targets.validation_min_percentage'))
      } else {
        this.$emit('validationPercentage', '')
      }
    }
  },
  watch: {
    addedInstruments: {
      handler (addedInstruments) {
        this.setPositions(addedInstruments)
      },
      deep: true
    },
    instruments: {
      handler (instruments) {
        this.loadingInstruments = true
        this.addedInstruments = instruments
        this.loadingInstruments = false
      },
      deep: true
    }
  }
}
</script>
