<template>
  <div class="personal-data">
    <general-layout>
      <template v-slot:title>
        <vue-title :title="$t('general.meta_titles.settings')"></vue-title>
      </template>

      <template v-slot:tabs>
        <Tabs
          :show-id-title="false"
          :tabs-classes="tabsClasses"/>
      </template>

      <template v-slot:body>
        <div v-if="!hasAccessInPortal">
          <div class="desktop-flex gap-8">
            <ContinueToOnboarding></ContinueToOnboarding>

            <div v-if="!isMobile">
              <Card :card-classes="'w-full h-full'">
                <template v-slot:body>
                  <div>
                    <p v-if="user.type !== 'company'" v-html="$t('guides.account_and_contact_data.account_p2')"/>
                    <p v-else v-html="$t('guides.account_and_contact_data.account_p2_company')"/>
                    <br>
                    <p v-html="$t('guides.account_and_contact_data.account_p1')"/>

                  </div>
                </template>
              </Card>
            </div>
          </div>
        </div>

        <div>
          <div v-if="hasAccessInPortal">
            <Transition appear name="fade">
              <div>
                <div class="settings-contact-data">
                  <IconBox class="username"
                           icon-color="green"
                           icon="user-dot-border"
                           :additional-text="`${$t('general.general.username')}: `"
                           :text="username"
                           :icon-positions="['left']"
                  ></IconBox>

                  <IconBox icon-color="green"
                           icon="envelope"
                           :additional-text="`${$t('general.general.email_address')}: `"
                           :text="email"
                           :icon-positions="['left']"
                  ></IconBox>

                  <IconBox icon-color="green"
                           icon="phone-filled"
                           :additional-text="`${$t('general.general.phone_number')}: `"
                           :text="telephone"
                           :icon-positions="['left']"
                  ></IconBox>

                  <Card :card-classes="'w-full' + (isMobile ? ' mb-8' : '')" v-if="!isMobile">
                    <template v-slot:body>
                      <div>
                        <p v-if="user.type !== 'company'" v-html="$t('guides.account_and_contact_data.account_p2')"/>
                        <p v-else v-html="$t('guides.account_and_contact_data.account_p2_company')"/>
                      </div>
                    </template>
                  </Card>

                  <Card :card-classes="'w-full'" v-if="!isMobile">
                    <template v-slot:body>
                      <div>
                        <p v-html="$t('guides.account_and_contact_data.account_p1')"/>
                      </div>
                    </template>
                  </Card>

                  <div class="w-full" :class="{'mt-8': isMobile}">
                    <Resources :links="resourcesLinks"/>
                  </div>
                </div>
              </div>
            </Transition>
          </div>
        </div>
      </template>
    </general-layout>
  </div>
</template>

<script>
import GeneralLayout from '../../layout/GeneralLayout'
import Tabs from '../../layout/Tabs'
import Button from '../../subcomponents/Button'
import {mapActions, mapGetters} from 'vuex'
import InputText from '../../subcomponents/InputText'
import Card from '../../subcomponents/Card'
import IconBox from '../../subcomponents/IconBox.vue'
import ContinueToOnboarding from '../ContinueToOnboarding'
import Resources from '../../subcomponents/Resources.vue'
import introJS from 'intro.js'
import UserService from '../../../services/auth/userService'
import Referrals from '../../subcomponents/Referrals.vue'

export default {
  name: 'Settings',
  components: {Referrals, GeneralLayout, Tabs, Button, InputText, Card, IconBox, ContinueToOnboarding, Resources},
  data () {
    return {
      editEmail: false,
      email: null,
      username: null,
      editTel: false,
      telephone: null,
      editUsername: false,
      userPortal: null,
      userArenaXT: null,
      tabsClasses: [],
      resourcesLinks: [
        { label: this.$t('useful_links.account_and_contact_data.account_cant_access_email'), value: '/faq/nu-mai-am-acces-la-email-ce-fac/' },
        { label: this.$t('useful_links.account_and_contact_data.account_cant_access_phone'), value: '/faq/nu-mai-am-acces-la-numarul-de-telefon-ce-fac/' },
        { label: this.$t('useful_links.account_and_contact_data.account_my_user_account'), value: '/faq/care-este-contul-meu-de-utilizator-arena-xt/' }
      ]
    }
  },
  computed: {
    ...mapGetters({
      hasAccessInPortal: 'user/hasAccessInPortal',
      isLogged: 'user/isLogged',
      user: 'user/user',
      screenDevice: 'layout/screenDevice'
    }),
    isMobile () {
      return this.screenDevice === this.$screenDeviceConstants.MOBILE
    }
  },
  mounted () {
    this.setData()

    this.$nextTick(async () => {
      const sleep = (ms) => {
        return new Promise(resolve => setTimeout(resolve, ms))
      }

      await sleep(500)

      this.setIntroPing((Math.random() + 1).toString(36).substring(7))
    })
  },
  methods: {
    ...mapActions({
      setIntroPing: 'guide/setIntroPing'
    }),
    async setData () {
      this.email = this.user.email
      this.telephone = this.user.phone
      this.username = this.user.username
    }
  }
}
</script>

<style scoped></style>
