<template>
  <div>
    <vue-title :title="$t('general.meta_titles.legal_person_details')"></vue-title>

    <div class="m-auto">
      <StepBar :current-step="selectedTabId" :steps="tabs"></StepBar>
    </div>

    <Card :card-classes="'margin-bottom-medium mobile-border-none'">
      <template v-slot:top>
        <img src="~@/assets/img/icons/green_circle_person.svg">
      </template>

      <template v-slot:title>
        <div>{{ $t('onboarding.legal_person_details.title') }}</div>
      </template>

      <template v-slot:body>
        <form>

          <div class="font-semibold">
            {{ $t('onboarding.legal_person_details.company_details') }}
          </div>

          <div class="form-group mb-6">
            <FullAddress
            :preSelectedAddress="{}"
            :precompleted-text="''"
            :type="'onboarding'"
            :show-save-button="false"
            :show-only-first-input="true"
            :label="$t('onboarding.legal_person_details.address_office') + ' *'"
            @addressSet="validation.address = ''"
            ref="address"
            class="mt-5"/>
            <Message v-if="validation.address" :message="validation.address" :type="'error'"/>
          </div>

          <div class="form-group mb-6">
            <InputText
              :id="'crn'"
              v-model="crn"
              :type-input="'text'"
              :label="$t('onboarding.legal_person_details.crn') + ' *'">
            </InputText>
          </div>

          <Message v-if="validation.crn" :message="validation.crn" :type="'error'" class="mb-5"/>
          <Message v-if="validation.crnLength" :message="validation.crnLength" :type="'error'" class="mb-5"/>

          <div class="form-group mb-6">
            <InputText
              :id="'tin'"
              v-model="tin"
              :type-input="'text'"
              :label="$t('onboarding.legal_person_details.tin') + ' *'">
            </InputText>
          </div>

          <Message v-if="validation.tin" :message="validation.tin" :type="'error'" class="mb-5"/>
          <Message v-if="validation.tinLength" :message="validation.tinLength" :type="'error'" class="mb-5"/>

          <div class="form-group mb-6">
            <InputText
              :id="'companyName'"
              v-model="companyName"
              :type-input="'text'"
              :label="$t('onboarding.legal_person_details.company_name') + ' *'">
            </InputText>
          </div>

          <Message v-if="validation.companyName" :message="validation.companyName" :type="'error'" class="mb-5"/>

          <div class="form-group mb-6">
            <InputText
              :id="'leiCode'"
              v-model="leiCode"
              :type-input="'text'"
              :label="$t('onboarding.legal_person_details.lei_code')">
            </InputText>
          </div>

          <div class="form-group mb-6">
            <Calendar
              id="leiCodeExpirationDate"
              v-model="leiCodeExpirationDate"
              :disabled-dates="['beforeToday', today]"
              :input-label="$t('onboarding.legal_person_details.lei_code_expires_at')"
              />
          </div>

          <div class="mb-5 font-semibold">
            {{ $t('onboarding.legal_person_details.representative_details') }}
          </div>

          <div class="form-group mb-6">
            <InputText
              :id="'lastName'"
              v-model="lastName"
              :type-input="'text'"
              :label="$t('onboarding.legal_person_details.last_name') + ' *'">
            </InputText>
          </div>

          <Message v-if="validation.lastName" :message="validation.lastName" :type="'error'" class="mb-5"/>

          <div class="form-group mb-6">
            <InputText
              :id="'firstName'"
              v-model="firstName"
              :type-input="'text'"
              :label="$t('onboarding.legal_person_details.first_name') + ' *'">
            </InputText>
          </div>

          <Message v-if="validation.firstName" :message="validation.firstName" :type="'error'" class="mb-5"/>

          <div class="form-group mb-6">
            <InputText
              :id="'pin'"
              v-model="pin"
              :type-input="'number'"
              :label="$t('onboarding.legal_person_details.pin') + ' *'">
            </InputText>
          </div>

          <Message v-if="validation.pin" :message="validation.pin" :type="'error'" class="mb-5"/>
          <Message v-if="validation.pinLength" :message="validation.pinLength" :type="'error'" class="mb-5"/>

          <div class="text-sm">{{$t('general.general.required')}}</div>

          <div class="my-5 font-semibold">{{ $t('onboarding.legal_person.as_legal_representative') }}</div>

          <div class="my-4" v-for="agreement in agreements" :key="agreement.type">
            <Checkbox
              :id="agreement.type"
              v-model="agreement.checked"
              :name="agreement.type"
              :label="agreement.label">
            </Checkbox>

            <Message v-if="validation.agreements[agreement.value]" :type="'error'" :message="validation.agreements[agreement.value]"></Message>
          </div>

          <Message v-if="validation.general" :message="validation.general" :type="'error'" class="mb-5"/>

          <Button class="mb-5"
                  :text="$t('general.general.continue')"
                  :type="'btn btn-primary padding-big m-auto'"
                  :loading="loading"
                  @call="sendData"
          />

          <pulse-loader class="mt-5" v-if="loading"/>
        </form>
      </template>
    </Card>
  </div>
</template>

<script>
import Tabs from '../layout/Tabs'
import {mapActions, mapGetters} from 'vuex'
import Button from '../subcomponents/Button'
import Message from '../subcomponents/Message'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import StepBar from '../layout/StepBar'
import Card from '../subcomponents/Card'
import InputText from '../subcomponents/InputText'
import Checkbox from '../subcomponents/Checkbox'
import PasswordValidations from '../../mixins/PasswordValidations'
import SelectDropDownFav from '@/components/subcomponents/SelectDropDownFav.vue'
import ApiService from '@/services/apiService'
import FullAddress from '@/components/subcomponents/FullAddress.vue'
import Calendar from '@/components/subcomponents/Calendar.vue'

export default {
  name: 'LegalPersonDetails',
  components: {
    Calendar,
    FullAddress,
    SelectDropDownFav,
    InputText,
    Card,
    StepBar,
    Tabs,
    Button,
    Message,
    PulseLoader,
    Checkbox},
  mixins: [ PasswordValidations ],
  data () {
    return {
      loading: true,
      firstName: '',
      lastName: '',
      crn: '',
      tin: '',
      companyName: '',
      pin: '',
      leiCode: '',
      leiCodeExpirationDate: '',
      tabs: [
        {
          id: 1,
          label: this.$i18n.t('onboarding.general.create_legal_account')
        },
        {
          id: 2,
          label: this.$i18n.t('onboarding.general.identity_verification')
        },
        {
          id: 3,
          label: this.$i18n.t('onboarding.general.legal_person_details')
        }
      ],
      selectedTabId: 3,
      validation: {
        general: '',
        agreements: {
          'distanceContract': '',
          'investimentalPresentation': ''
        },
        firstName: '',
        lastName: '',
        crn: '',
        tin: '',
        companyName: '',
        pin: '',
        address: '',
        crnLength: '',
        tinLength: '',
        pinLength: ''
      },
      agreements:
        {
          'distanceContract': {
            type: 'distance-contract',
            label: this.$i18n.t('onboarding.register.distance_contract'),
            checked: false,
            required: true,
            value: 'distanceContract'
          },
          'investimentalPresentation': {
            type: 'investimental-presentation',
            label: this.$i18n.t('onboarding.register.investimental_presentation'),
            checked: false,
            required: true,
            value: 'investimentalPresentation'
          }
        },
      tabsClasses: ['justify-center'],
      countriesRemaining: [],
      countriesAll: [],
      selectedCountry: null,
      address: null
    }
  },
  async mounted () {
    let countriesData = await ApiService.withAuth().get('/api/countries')
    let favCountry = process.env.VUE_APP_FAVORITE_COUNTRY
    let favCountryCollected = []

    if (countriesData.status === 200) {
      countriesData.data.data.map(country => {
        if (favCountry.includes(country.name)) {
          favCountryCollected.push(this.createCountryObj(country))
        } else {
          this.countriesAll.push(this.createCountryObj(country))
        }
      })

      favCountryCollected.push({
        label: 'none',
        id: null,
        divider: true
      })

      this.countriesAll = favCountryCollected.concat(this.countriesAll)

      this.countriesRemaining = this.countriesAll

      this.loading = false
    }
  },
  computed: {
    ...mapGetters({
      user: 'user/user',
      getEmail: 'user/email',
      getGdprAccord: 'user/gdprAccord',
      getMarketingAccord: 'user/marketingAccord',
      getUserToken: 'user/token',
      screenDevice: 'layout/screenDevice'
    }),
    today () {
      let today = new Date()
      return today.getDate() + '/' + (today.getMonth() + 1) + '/' + today.getFullYear()
    }
  },
  methods: {
    ...mapActions({
      register: 'user/register',
      setEmail: 'user/setEmail',
      setGdprAccord: 'user/setGdprAccord',
      setMarketingAccord: 'user/setMarketingAccord',
      setToken: 'user/setToken',
      setLoggedIn: 'user/setLoggedId',
      setUsername: 'user/setUsername'
    }),

    validate () {
      let valid = true

      if (!this.agreements.distanceContract.checked) {
        this.validation.agreements.distanceContract = this.$t('onboarding.register.validation_agreement')

        valid = false
      }

      if (!this.agreements.investimentalPresentation.checked) {
        this.validation.agreements.investimentalPresentation = this.$t('onboarding.register.validation_agreement')

        valid = false
      }

      if (!this.firstName) {
        this.validation.firstName = this.$t('onboarding.legal_person_details.validation_first_name')

        valid = false
      }

      if (!this.lastName) {
        this.validation.lastName = this.$t('onboarding.legal_person_details.validation_last_name')

        valid = false
      }

      if (!this.crn) {
        this.validation.crn = this.$t('onboarding.legal_person_details.validation_crn')

        valid = false
      } else if (this.validation.crnLength) {
        valid = false
      }

      if (!this.tin) {
        this.validation.tin = this.$t('onboarding.legal_person_details.validation_tin')

        valid = false
      } else if (this.validation.tinLength) {
        valid = false
      }

      if (!this.companyName) {
        this.validation.companyName = this.$t('onboarding.legal_person_details.validation_company_name')

        valid = false
      }

      if (!this.pin) {
        this.validation.pin = this.$t('onboarding.legal_person_details.validation_pin')

        valid = false
      } else if (this.validation.pinLength) {
        valid = false
      } else if (this.pin.length > 13) {
        this.validation.pinLength = this.$t('onboarding.legal_person_details.validation_pin_length')

        valid = false
      }

      if (!this.$refs.address.address.country) {
        this.validation.address = this.$t('onboarding.legal_person_details.validation_address')

        valid = false
      }

      return valid
    },
    createCountryObj (country) {
      return {label: country.name, id: country.id}
    },
    formatDate (date) {
      let d = new Date(date.split('/').reverse().join('-'))
      return d.getFullYear() + '-' + ('0' + (d.getMonth() + 1)).slice(-2) + '-' + ('0' + (d.getDate() + 1)).slice(-2)
    },
    async sendData () {
      if (this.validate()) {
        let payload = {
          name: this.companyName,
          tin: this.tin,
          crn: this.crn,
          administrator_pin: this.pin,
          administrator_last_name: this.lastName,
          administrator_first_name: this.firstName,
          country_code: this.$refs.address.address.country_code
        }

        if (this.leiCode) {
          payload.lei_code = this.leiCode
        }

        if (this.leiCodeExpirationDate) {
          payload.lei_code_expires_at = this.formatDate(this.leiCodeExpirationDate)
        }

        let response = await ApiService.withAuth().post('/api/user/onboarding/company/add-company', payload, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).catch(e => {
          if (e.response.data.data && e.response.data.data.isDuplicate) {
            this.validation.general = this.$t('onboarding.legal_person_details.validation_company_name_duplicate')
          } else {
            this.validation.general = e.response.data.message
          }
        })

        if (response && response.status === 200) {
          payload = {
            address: JSON.stringify(this.$refs.address.address)
          }
          response = await ApiService.withAuth().post('/api/user/onboarding/company/add-company-address', payload, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })

          if (response.status === 200) {
            await this.$router.push({name: this.user.type === 'company' ? 'pj-documents' : 'homepage'})
          }
        }
      }
    }
  },
  watch: {
    firstName () {
      this.validation.firstName = ''
    },

    lastName () {
      this.validation.lastName = ''
    },

    crn (newCrn) {
      if (newCrn.length) {
        this.validation.crn = ''
      }
      if (newCrn.length < 7 || newCrn.length > 15) {
        this.validation.crnLength = this.$t('onboarding.legal_person_details.validation_crn_length')
      } else {
        this.validation.crnLength = ''
      }
    },

    pin (newPin) {
      if (newPin.length) {
        this.validation.pin = ''
      }
      if (newPin.length > 13) {
        this.validation.pinLength = this.$t('onboarding.legal_person_details.validation_pin_length')
      } else {
        this.validation.pinLength = ''
      }
    },

    tin (newTin) {
      if (newTin.length) {
        this.validation.tin = ''
      }
      if (newTin.length < 4 || newTin.length > 15) {
        this.validation.tinLength = this.$t('onboarding.legal_person_details.validation_tin_length')
      } else {
        this.validation.tinLength = ''
      }
    },

    leiCode: {
      handler (newLeiCode) {
        if (newLeiCode.length) {
          this.validation.leiCode = ''
        }
        if (newLeiCode.length !== 20) {
          this.validation.leiCodeLength = this.$t('onboarding.legal_person_details.validation_lei_code_length')
        } else {
          this.validation.leiCodeLength = ''
        }
      },
      deep: true
    },

    leiCodeExpirationDate: {
      handler (newLeiCodeExpirationDate) {
        newLeiCodeExpirationDate = newLeiCodeExpirationDate.split('/').reverse().join('-')
        if (newLeiCodeExpirationDate) {
          this.validation.leiCodeExpirationDate = ''
        }
        if (new Date(newLeiCodeExpirationDate) < new Date()) {
          this.validation.leiCodeExpirationDateBeforeToday = this.$t('onboarding.legal_person_details.validation_lei_code_expires_at_before_today')
        } else {
          this.validation.leiCodeExpirationDateBeforeToday = ''
        }
      }
    },

    companyName () {
      this.validation.companyName = ''
    },

    agreements: {
      handler (val) {
        if (val.distanceContract.checked) {
          this.validation.agreements.distanceContract = ''
        }

        if (val.investimentalPresentation.checked) {
          this.validation.agreements.investimentalPresentation = ''
        }
      },
      deep: true
    }
  }
}
</script>

<style scoped>

</style>
