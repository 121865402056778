<template>
<div>
  <div class="block">
    <div v-for="(item, index) in items" :key="index" class="mr-4 inline-block">
      <div class="inline-block"
           :class="{'font-bold color-primary' : index === items.length - 1, 'cursor-pointer': item.routeName}"
           @click="to(item)">
        {{ item.label }}
      </div>
      <div class="inline-block ml-4" v-if="index < items.length - 1">
        <img class="w-2" :src="require(`@/assets/img/icons/arrow_right_simple.svg`)">
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'Breadcrumb',
  props: {
    items: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    to (item) {
      if (item.routeName) {
        this.$router.push({name: item.routeName})
      }
    }
  }
}
</script>

<style scoped>
</style>
