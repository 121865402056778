<template>
  <div v-if="loaded && allCountries.length" >
    <div class="mb-10">
      <div class="pl-4" :class="{'disabled-select': disabledCountry}">
        {{ $t('onboarding.select_domicile.permanent_domicile') }}:
      </div>
      <div class="flex items-end mt-2">
        <SelectDropDownFav
            :id="'countrySelectorId'"
            v-model="selectedCountry"
            :options="allCountries"
            class="mr-2 w-full"
            :disabled="disabledCountry"
            required
        />
        <img class="mb-3 cursor-pointer w-6 max-w-none" alt="edit" src="~@/assets/img/icons/edit-icon.svg" @click="toggleDisabledCountry">
      </div>

      <Message v-if="validateCountry" :message="$t('onboarding.select_domicile.select_country')" :type="'error'"/>
    </div>

    <div class="mb-10">
      <div class="pl-4" :class="{'disabled-select': disabledCitizenships}">{{ $t('onboarding.select_domicile.citizenship') }}</div>
      <div class="flex items-start">
        <div class="w-full">
          <div
            v-for="(selectedCitizenship, key) in selectedCitizenships"
            :key="key"
            class="flex w-full mt-2">
            <SelectDropDownFav
                :id="selectedCitizenship.label+'id'"
                v-model="selectedCitizenship.label"
                :name-format="'label'"
                :options="countriesRemaining"
                class="mr-2 w-full"
                :disabled="disabledCitizenships"
                @input="updateCitizenship($event, key)"
            />
            <div
                v-if="manyCitizenshipsExist"
                class="cursor-pointer delete-button"
                :class="{'pointer-events-none': disabledCitizenships}"
                @click="deleteCitizenshipModel(key)">
              <div class="flex items-center delete-icon">
                <img
                    :src="require(`@/assets/img/icons/trash-can-fill.svg`)"
                    alt="icon"
                />
              </div>
            </div>
          </div>
        </div>
        <img
            :class="{'ml-3': manyCitizenshipsExist}"
            class="mt-4 cursor-pointer w-6 max-w-none"
            alt="edit"
            src="~@/assets/img/icons/edit-icon.svg"
            @click="toggleDisabledCitizenships"
        >
      </div>

      <div class="mt-5 w-fit" :class="{'pointer-events-none': disabledCitizenships}" @click="addCitizenship()">
        <Button
            :disabled="disabledCitizenships"
            :icon="'icons/plus-circle-lined'"
            :icon-position="'left'"
            :text="$t('onboarding.select_domicile.multiple_citizenship')"
            :type="'btn-icon-text disabled-transparent'"
            class="mt-4 w-fit"
        />
      </div>
    </div>

    <Message v-if="validateCitizenships" :message="$t('onboarding.select_domicile.select_citizenships')"
             :type="'error'"/>
    <Message v-if="completedCitizenships" :message="$t('onboarding.select_domicile.complete_citizenships')"
             :type="'error'"/>

    <div class="flex justify-between items-center mt-10">
      <Button :icon="'icons/arrow_back_green'"
              :icon-position="'left'"
              :text="$t('general.general.back')"
              :type="'btn btn-secondary padding-big'"
              @call="goBackToPortal"/>

      <Button :icon="'icons/check-white'"
              :icon-position="'right'"
              :text="$t('general.general.confirm')"
              :type="'btn btn-primary padding-big'"
              @call="next"/>
    </div>
  </div>
  <pulse-loader v-else class="m-auto w-fit"/>
</template>

<script>
import Message from '../../subcomponents/Message.vue'
import Button from '../../subcomponents/Button.vue'
import SelectDropDown from '../../subcomponents/SelectDropDown.vue'
import {mapActions, mapGetters} from 'vuex'
import SelectDropDownFav from '../../subcomponents/SelectDropDownFav.vue'
import ApiService from '@/services/apiService'
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
export default {
  name: 'UpdateDomicile',
  components: {SelectDropDownFav, Button, Message, SelectDropDown, PulseLoader},
  data () {
    return {
      countriesDataApi: {},
      allCountries: [],
      selectedCountry: null,
      loaded: false,
      validateCountry: false,
      selectedCitizenships: [],
      citizenships: [],
      validateCitizenships: false,
      completedCitizenships: false,
      disabledCitizenships: true,
      disabledCountry: true,
      deletedCitizenships: [],
      countriesRemaining: []
    }
  },
  async mounted () {
    await ApiService.withAuth().get('/api/countries').then(response => {
      this.setCountries(response.data.data)
    }).finally(this.loaded = true)

    let countriesData = this.getCountries
    let favCountry = process.env.VUE_APP_FAVORITE_COUNTRY
    let favCountryCollected = []

    if (countriesData.length) {
      countriesData.map(country => {
        if (favCountry.includes(country.name)) {
          favCountryCollected.push(this.createCountryObj(country))
        } else {
          this.allCountries.push(this.createCountryObj(country))
        }
      })

      favCountryCollected.push({
        label: 'none',
        id: null,
        divider: true
      })

      this.allCountries = favCountryCollected.concat(this.allCountries)

      this.countriesRemaining = this.allCountries
      this.citizenships = this.countriesRemaining

      let userCitizenships = (this.updatedData.step_country.citizenshipsArray && this.updatedData.step_country.citizenshipsArray.length) ? this.updatedData.step_country.citizenshipsArray : this.getUserData.citizenships
      let userCountryDomicile = this.updatedData.step_country.domicile ? this.getCountryById(this.updatedData.step_country.domicile) : this.getUserData.domicile

      if (userCitizenships.length) {
        this.selectedCitizenships = userCitizenships.map((country) => {
          return {
            label: (this.updatedData.step_country.citizenshipsArray && this.updatedData.step_country.citizenshipsArray.length) ? country.label : country.name,
            id: country.id
          }
        })
      } else {
        this.addCitizenship()
      }

      if (userCountryDomicile) {
        this.selectedCountry = {
          id: userCountryDomicile.id,
          label: this.updatedData.step_country.domicile ? userCountryDomicile.label : userCountryDomicile.name
        }
      }

      this.loaded = true
    }
  },
  methods: {
    ...mapActions({
      setCountries: 'staticData/setCountries'
    }),
    goBackToPortal () {
      this.$router.push('personal-data')
    },
    getCountryById (id) {
      return this.allCountries.find(country => country.id === id)
    },
    createCountryObj (country) {
      return {label: country.name, id: country.id}
    },
    toggleDisabledCountry () {
      this.disabledCountry = !this.disabledCountry
    },
    toggleDisabledCitizenships () {
      this.disabledCitizenships = !this.disabledCitizenships
    },
    deleteCitizenshipModel (key) {
      if (this.selectedCitizenships[key].id) {
        this.deletedCitizenships.push(this.selectedCitizenships[key].id)
      }

      this.selectedCitizenships.splice(key, 1)
    },
    updateCitizenship (label, key) {
      this.citizenships.find(citizenship => citizenship.label === label)
      this.$set(this.selectedCitizenships, key, JSON.parse(JSON.stringify(this.citizenships.find(citizenship => citizenship.label === label))))
    },
    addCitizenship () {
      this.selectedCitizenships.push({
        label: '',
        id: null
      })
    },
    validate () {
      let valid = true

      if (!this.selectedCountry) {
        this.validateCountry = true

        valid = false
      }

      if (!this.selectedCitizenships.length) {
        this.validateCitizenships = true

        valid = false
      } else {
        for (let obj of this.selectedCitizenships) {
          if (obj.id === null || obj.label === '') {
            this.completedCitizenships = true

            valid = false
          }
        }
      }

      return valid
    },
    async next () {
      if (!this.validate()) {
        return
      }

      let updatedData = this.updatedData

      if (this.selectedCountry.id !== this.getUserData.domicile) {
        updatedData.step_country.domicile = this.selectedCountry.id
      }

      updatedData.step_country.citizenshipsArray = this.selectedCitizenships
      updatedData.step_country.citizenships = this.selectedCitizenships.map((country) => {
        return country.id
      }).join(',')

      updatedData.step_country.deleted_citizenships = this.deletedCitizenships.join(',')

      let nextStep = 'updatePhoneNumber'
      this.$emit('next', {updatedData, nextStep})
    }
  },
  computed: {
    ...mapGetters({
      getUserData: 'user/user',
      updatedData: 'updatePersonalData/getUpdatedData',
      getCountries: 'staticData/getCountries'
    }),
    manyCitizenshipsExist () {
      return this.selectedCitizenships.length > 1
    }
  },
  watch: {
    country () {
      this.validateCountry = false
    },
    selectedCitizenships (selectedCitizenships) {
      let citizenshipIds = []

      selectedCitizenships.forEach((citizenship) => {
        if (citizenship.id) {
          citizenshipIds.push(citizenship.id)
        }
      })

      this.countriesDataApi = this.allCountries.filter((country) => !citizenshipIds.includes(country.id))

      this.validateCitizenships = false
      this.completedCitizenships = false
    }
  }
}
</script>
