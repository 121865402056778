<template>
  <div>
    <div class="mb-10">
      {{ $t('onboarding.documents.personal_statement') }}
    </div>

    <div class="mb-10">
      <Checkbox
        :id="'first-check'"
        v-model="agreements.cleanClientHistory"
        :label="$t('onboarding.documents.client_history')"
      />

      <Message
        v-if="!validations.cleanClientHistory"
        :message="$t('general.validations.checkbox_validation')"
        :type="'error'"
      />
    </div>

    <div class="mb-10">
      <Checkbox
        :id="'second-check'"
        v-model="agreements.cleanClientData"
        :label="$t('onboarding.documents.update_client_data')"
      />

      <Message
        v-if="!validations.cleanClientData"
        :message="$t('general.validations.checkbox_validation')"
        :type="'error'"
      />
    </div>

    <div v-for="document in documents" :key="document.id" class="mb-10">
      <div class="cursor-pointer color-blue text-lg" @click="download(document)">
        {{ document.original_name }}
      </div>
    </div>

    <div class="text-lg font-semibold mt-8 mb-9">
      {{ $t('onboarding.documents.consent') }}
    </div>

    <div class="flex justify-between items-center mt-10" v-if="generatedDocument">
      <Button
        :disabled="!generatedDocument"
        @call="resetStep()"
        :text="$t('general.general.back')"
        :type="'btn btn-secondary padding-medium end-position no-wrap'"
        :icon="'icons/arrow_back_green'"
        :icon-position="'left'"
      />

      <div class="w-fit"
           :class="{'disabled pointer-events-none': !loaded}"
           @click="storeData">
        <Button
          :icon="'icons/arrow_icon'"
          :icon-position="'right'"
          :text="$t('general.general.complete')"
          :type="'btn btn-primary padding-big'"
        />
      </div>
    </div>

    <pulse-loader v-if="!loaded" class="mt-7 mx-auto w-fit"/>

    <Message
      v-if="!validateServerError"
      :formatIsHtml="true"
      :message="$t('general.validations.documents_server_error')"
      :type="'error'"
    />

    <Message
      v-if="validations.failedUpdateData"
      :formatIsHtml="true"
      :message="validations.failedUpdateData"
      :type="'error'"
    />
  </div>
</template>

<script>
import PulseLoader from 'vue-spinner/src/PulseLoader.vue'
import Card from '../../subcomponents/Card.vue'
import Message from '../../subcomponents/Message.vue'
import Button from '../../subcomponents/Button.vue'
import StepBar from '../../layout/StepBar.vue'
import Checkbox from '../../subcomponents/Checkbox.vue'
import ApiService from '../../../services/apiService'
import {mapActions, mapGetters} from 'vuex'
import FillPersonalDocument from '../../../mixins/FillPersonalDocument'
import documentsService from '@/services/documents/documentsService'

export default {
  name: 'UpdateDocuments',
  components: {Card, Message, Button, StepBar, PulseLoader, Checkbox},
  mixins: [FillPersonalDocument],
  data () {
    return {
      tabs: [
        {
          id: 1,
          label: this.$i18n.t('onboarding.general.create_account')
        },
        {
          id: 2,
          label: this.$i18n.t('onboarding.general.identity_verification')
        },
        {
          id: 3,
          label: this.$i18n.t('onboarding.general.financial_data')
        }
      ],
      documents: [],
      loaded: false,
      agreements: {
        cleanClientHistory: false,
        cleanClientData: false
      },
      validations: {
        cleanClientHistory: true,
        cleanClientData: true,
        failedUpdateData: ''
      },
      validateServerError: true,
      formData: new FormData(),
      generatedDocument: false,
      tmpFile: null
    }
  },
  computed: {
    ...mapGetters({
      updatedData: 'updatePersonalData/getUpdatedData'
    })
  },
  async mounted () {
    await this.getFormattedData()
    await this.fillDocuments()
  },
  methods: {
    ...mapActions({
      setUpdatedDataStepAgreements: 'updatePersonalData/setUpdatedDataStepAgreements',
      resetState: 'updatePersonalData/resetState',
      fetchUser: 'user/fetch',
      setStep: 'updatePersonalData/setStep'
    }),
    makeRequest () {
      return ApiService.withAuth().post('/api/user/documents/anniversary', this.formData).finally(() => {
        this.generatedDocument = true
      })
    },
    async getTmpFile () {
      await documentsService.getTmpDocuments(this.updatedData.step_identity_card.identityCard).then(response => {
        this.tmpFile = new File([response.data], this.updatedData.step_identity_card.fileName, {
          type: this.updatedData.step_identity_card.fileType
        })
      }).catch(error => {
        this.validations.failedUpdateData = error.response.data.message
        setTimeout(() => {
          this.validations.failedUpdateData = ''
          this.setStep('updateIdentityCard')
        }, 3000)
      })
    },
    async getFormattedData () {
      this.formData = new FormData()

      await this.setUpdatedDataStepAgreements(this.agreements)

      if (this.updatedData.step_identity_card.identityCard) {
        await this.getTmpFile()
        this.formData.append('identity_card_file', this.tmpFile)
      }

      this.formData.append('step_country', JSON.stringify(this.updatedData.step_country))
      this.formData.append('step_identity_card', JSON.stringify(this.updatedData.step_identity_card))
      this.formData.append('step_tax_residence', JSON.stringify(this.updatedData.step_tax_residence))
      this.formData.append('step_questionnaire', JSON.stringify(this.updatedData.step_questionnaire))
    },

    async storeData () {
      if (!this.validate()) {
        return
      }

      this.loaded = false

      this.formData.append('step_agreements', JSON.stringify(this.updatedData.step_agreements))

      let response = await ApiService.withAuth().post('/api/user/update-deprecated-data', this.formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).catch(error => {
        this.validations.failedUpdateData = error.response.data.message
      }).finally(() => {
        this.loaded = true
      })

      if (response.status === 200) {
        await this.resetState()
        await this.fetchUser()
        await this.$router.push({name: 'homepage'})
      }
    },
    async resetStep () {
      this.$emit('prev', 'updateQuestionnaire', {questionnaire: {showIntro: false, selectedQuestion: 17}})
    }
  }
}
</script>

<style scoped>

</style>
