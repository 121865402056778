import {store} from '@/store'

export class ClearGarbageStorageService {
  static clearPersonalDataStorage () {
    const steps = Object.assign([], store.getters['updatePersonalData/getAllSteps'])

    if (!steps.includes(store.getters['updatePersonalData/getStep'])) {
      store.commit('updatePersonalData/resetState')
    }
  }

  static clear () {
    this.clearPersonalDataStorage()
  }
}
