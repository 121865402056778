<template>
  <div>
    <div class="form-group mb-6 flex items-end">
      <InputText
        class="w-full mr-3"
        :id="'email'"
        v-model="email"
        :type-input="'email'"
        :disabled-input="!edit"
        :label="$t('general.general.email_address')">
      </InputText>
      <img class="mb-3 cursor-pointer w-6 max-w-none" alt="edit" src="~@/assets/img/icons/edit-icon.svg" @click="toggleEditEmail()">
    </div>
    <div class="flex justify-between">
      <Button :icon="'icons/arrow_back_green'"
              :icon-position="'left'"
              :text="$t('general.general.back')"
              :type="'btn btn-secondary padding-big'"
              @call="goBackToPortal"/>

      <Button :icon="'icons/check-white'"
              :icon-position="'right'"
              :text="$t('general.general.confirm')"
              :type="'btn btn-primary padding-big'"
              @call="next"/>
    </div>
  </div>
</template>

<script>
import InputText from '../../subcomponents/InputText.vue'
import Button from '../../subcomponents/Button.vue'
import {mapGetters} from 'vuex'
import ApiService from '../../../services/apiService'

export default {
  name: 'UpdateEmail',
  components: { Button, InputText },
  data () {
    return {
      email: '',
      edit: false
    }
  },
  created () {
    this.email = this.updatedData.step_email.email ? this.updatedData.step_email.email : this.getUserData.email
  },
  computed: {
    ...mapGetters({
      updatedData: 'updatePersonalData/getUpdatedData',
      getUserData: 'user/user'
    })
  },
  methods: {
    goBackToPortal () {
      this.$router.push('settings')
    },
    next () {
      let updatedData = this.updatedData
      updatedData.step_email.email = (this.email !== this.getUserData.email) ? this.email : ''
      let nextStep = (this.email !== this.getUserData.email) ? 'verificationEmail' : 'updateDomicile'

      if (updatedData.step_email.email) {
        this.generateVerificationCode()
      }

      this.$emit('next', {updatedData, nextStep})
    },
    generateVerificationCode () {
      ApiService.withAuth().post('/api/user/update-email/generate-code', {
        email: this.email
      })
    },
    toggleEditEmail () {
      this.edit = !this.edit
    }
  }

}
</script>

<style scoped>

</style>
