<template>
  <div>
    <vue-title :title="$t('general.meta_titles.questionnaire')"></vue-title>

    <div class="rounded-lg bg-primary m-auto">
      <StepBar :current-step="selectedTabId" :steps="tabs"></StepBar>
    </div>

    <div>
      <Card :card-classes="'margin-bottom-medium mobile-border-none'">
        <template v-slot:top>
          <img alt="questionnaire" src="~@/assets/img/icons/questionnaire.svg">
        </template>

        <template v-slot:title>
          <div slot="title">{{ $t('general.questionnaire.title') }}</div>
        </template>

        <template v-slot:body>
          <div v-if="loaded">
            <Main v-if="!showIntro"
                  :questions="questions"
                  :questions-answers="answers"
                  @back="back"
                  @finish="finish"
                  @next="next"
                  @showIntro="toggleShowIntro"/>

            <div v-else>
              <div class="mt-4 mb-10">{{ $t('general.questionnaire.intro') }}</div>

              <div class="nav m-top-custom-l flex justify-between">
                <Button :icon="'icons/arrow_icon_back'"
                        :icon-position="'left'"
                        :text="$t('general.questionnaire.back')"
                        :type="'btn padding-medium btn-secondary'"
                        class="prev"
                        @call="resetStep"/>

                <Button :disabled="false"
                        :text="$t('general.questionnaire.continue')"
                        :type="'btn btn-primary padding-big end-position'"
                        class="next"
                        @call="startQuestionnaire"/>
              </div>
            </div>
          </div>

          <PulseLoader v-if="!loaded"/>
        </template>
      </Card>
    </div>
  </div>
</template>

<script>
import Card from '../subcomponents/Card'
import StepBar from '../layout/StepBar'
import Main from '../questionnaire/Main'
import questionnaireService from '../../services/onboarding/questionnaireService'
import PulseLoader from 'vue-spinner/src/PulseLoader'
import Button from '../subcomponents/Button'
import {ONBOARDING_DOCUMENTS, ONBOARDING_SELECT_RESIDENCE} from '../../router'
export default {
  name: 'Questionnaire',
  components: {Main, Card, StepBar, PulseLoader, Button},
  data () {
    return {
      tabs: [
        {
          id: 1,
          label: this.$i18n.t('onboarding.general.create_account')
        },
        {
          id: 2,
          label: this.$i18n.t('onboarding.general.identity_verification')
        },
        {
          id: 3,
          label: this.$i18n.t('onboarding.general.financial_data')
        }
      ],
      selectedTabId: 3,
      questions: [],
      answers: {},
      loaded: false,
      showIntro: false
    }
  },
  async mounted () {
    let questionsIndexResponse = await questionnaireService.getQuestions()

    if (questionsIndexResponse.status === 200) {
      this.questions = questionsIndexResponse.data.data
    }

    let questionnaireResponse = await questionnaireService.getQuestionnaire()

    if (questionnaireResponse.status === 200) {
      if (questionnaireResponse.data.data.questionnaire &&
        Object.values(questionnaireResponse.data.data.questionnaire.answers).length) {
        this.answers = questionnaireResponse.data.data.questionnaire.answers
      }
    }

    if (!Object.keys(this.answers).length) {
      this.showIntro = true
    }

    this.loaded = true
  },
  methods: {
    startQuestionnaire () {
      this.showIntro = false
    },
    toggleShowIntro () {
      this.showIntro = true
    },
    saveAnswer (selectedQuestionsAnswers, isFinish = false) {
      return questionnaireService.saveQuestionnaire({
        answers: selectedQuestionsAnswers,
        isFinish,
        redoTest: false
      })
    },
    next (selectedQuestionsAnswers) {
      this.saveAnswer(selectedQuestionsAnswers)
    },
    back (selectedQuestionsAnswers) {
      this.saveAnswer(selectedQuestionsAnswers)
    },
    async finish (selectedQuestionsAnswers) {
      await this.saveAnswer(selectedQuestionsAnswers, true)
      await this.$router.push({name: ONBOARDING_DOCUMENTS})
    },
    async resetStep () {
      await this.$confirm(
        this.$t('onboarding.reset_steps.reset_residence'),
        '',
        'warning',
        {cancelButtonText: this.$t('general.general.cancel')}
      ).then(async () => {
        let reset = await questionnaireService.resetStep({reset_residence: true})
        if (reset.status === 200) {
          await this.$router.push({name: ONBOARDING_SELECT_RESIDENCE})
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
