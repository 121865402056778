<template>
  <div class="personal-data residence-data">
    <div class="personal-info">
      <div class="info mb-6">
        <Checkbox
          class="mb-4"
          :id="'roFiscal'"
          v-model="roFiscal"
          :label="$t('settings.residence_data.ro_resident')"
        />

        <div v-if="roFiscal && userData.identity_card" class="font-bold mt-4 ml-8">
          {{ userData.identity_card.personal_identification_number }}
        </div>
      </div>

      <div class="info mb-6" >
        <Checkbox
          class="mb-4"
          :id="'otherFiscal'"
          v-model="otherFiscal"
          :label="
            $t('settings.residence_data.other_resident')
          "
        />

        <template v-if="otherFiscal && countries.length">
          <div
            v-for="(fiscalData, counter) in otherFiscalData"
            :key="counter"
            class="mt-4 other-fiscal-data"
          >
            <div class="select-residence-fiscal-data">
              <SelectDropDown
                :id="fiscalData.country ? fiscalData.country.label : '' +'-id'"
                v-model="fiscalData.country"
                :label="$t('settings.residence_data.country')"
                :options="countries"
                class="mr-6"
                required
              />

              <InputText
                :id="fiscalData.country + 'fiscalDataCode'"
                v-model="fiscalData.fiscalCode"
                :label="$t('settings.residence_data.fiscal_code')"
                :type-input="'text'"
                required
              />

              <div
                v-if="otherFiscalData.length > 1"
                class="cursor-pointer delete-button"
                @click="deleteOtherFiscal(counter)">
                <div class="flex items-center delete-icon">
                  <span v-if="screenDevice === $screenDeviceConstants.MOBILE">{{ $t('general.general.delete') }}</span>

                  <img
                    :src="require(`@/assets/img/icons/trash-can-fill.svg`)"
                    alt="icon"
                  />
                </div>
              </div>
            </div>
          </div>

          <Message
            v-if="!fiscalOtherValid"
            :message="fiscalValidationMessage"
            :type="'error'"
          />
          <Message
            v-if="!countryOtherValid"
            :message="countryOtherValidMessage"
            :type="'error'"
          />

          <Button
            :icon="'icons/plus-circle-lined'"
            :icon-position="'left'"
            :text="$t('settings.residence_data.add_country')"
            :type="'btn-icon-text'"
            class="mt-4 w-fit ml-10 mb-6"
            @call="addOtherFiscal">
          </Button>
        </template>
      </div>

      <div class="info mb-6">
        <Checkbox
          :id="'usaFiscal'"
          v-model="usaFiscal"
          :label="$t('settings.residence_data.us_resident')"
          :name="'checkbox'"
        />
      </div>

      <InputText v-if="usaFiscal"
          :id="'USfiscalDataCode'"
          v-model="usaFiscalNumber"
          :label="$t('settings.residence_data.fiscal_code_us')"
          :type-input="'text'"
          required
      />

      <Message
          v-if="!fiscalUsaValid"
          :message="fiscalValidationMessage"
          :type="'error'"
      />

      <Message
        v-if="dataSaved"
        class="mb-5"
        :message="$t('settings.residence_data.data_saved')"
        :type="'success'"
      />

      <Message
        v-if="validation"
        :message="validation"
        :type="'error'"
      />

      <div class="mt-5 flex justify-between items-center">
        <Button
          @call="resetStep()"
          class="mr-4"
          :text="$t('general.general.back')"
          :type="'btn btn-secondary padding-medium end-position no-wrap'"
          :icon="'icons/arrow_back_green'"
          :icon-position="'left'"
          v-if="$route.meta.isOnboarding"
        />

        <Button
          :loading="loadingSaveResidence"
          :disabled="countries.length === 0"
          @call="save()"
          :text="$t('general.general.continue')"
          :type="'btn btn-primary padding-big end-position'"/>
      </div>
    </div>
  </div>
</template>

<script>
import GeneralLayout from '../../../layout/GeneralLayout'
import Button from '../../../subcomponents/Button'
import {mapActions, mapGetters} from 'vuex'
import ApiService from '../../../../services/apiService'
import questionnaireService from '../../../../services/onboarding/questionnaireService'
import Message from '../../../subcomponents/Message'
import {ONBOARDING_QUESTIONNAIRE, ONBOARDING_BANK_ACCOUNT} from '../../../../router'
import InputText from '../../../subcomponents/InputText'
import Card from '../../../subcomponents/Card'
import Checkbox from '../../../subcomponents/Checkbox'
import IconBox from '../../../subcomponents/IconBox.vue'
import SelectDropDown from '../../../subcomponents/SelectDropDown'

export default {
  name: 'SelectResidenceData',
  components: {
    Message,
    GeneralLayout,
    Button,
    Card,
    InputText,
    IconBox,
    Checkbox,
    SelectDropDown
  },
  data () {
    return {
      countries: [],
      allCountries: {},
      fiscalOtherValid: true,
      countryOtherValid: true,
      fiscalUsaValid: true,
      loading: true,
      loadingSaveResidence: false,
      usaFiscal: false,
      usaFiscalNumber: undefined,
      otherFiscal: false,
      roFiscal: false,
      dataSaved: false,
      fiscalValidationMessage: '',
      countryOtherValidMessage: '',
      otherFiscalData: [],
      userData: null,
      validation: ''
    }
  },
  async created () {
    let countriesData = await ApiService.withAuth().get('/api/countries')
    let userData = await this.getUserData()

    if (userData.status !== 200 || countriesData.status !== 200) {
      return
    }

    this.countries = countriesData.data.data.map(countryData => {
      return {
        id: countryData.code,
        label: countryData.name
      }
    }).filter((country) => {
      return country.id.toUpperCase() !== 'RO' && country.id !== 'US'
    })

    this.allCountries = this.countries

    this.userData = userData.data.data.user

    this.roFiscal = this.userData.has_tax_residence_ro === true
    this.usaFiscal = this.userData.has_tax_residence_usa === true

    let other = []

    if (Object.values(this.userData.tax_residence_other).length) {
      other = this.userData.tax_residence_other
    }

    if (other.length) {
      this.otherFiscalData = other.map(taxResidenceOther => {
        return {
          country: this.countries.filter((country) => country.id === taxResidenceOther.country_code)[0],
          fiscalCode: taxResidenceOther.tax_residence_number
        }
      })

      this.otherFiscal = true
    }

    if (!(['RO', 'US'].includes(this.userData.domicile.code))) {
      let country = this.otherFiscalData.find((obj) => {
        return obj.country.id === this.userData.domicile.code
      })

      if (country == null) {
        this.otherFiscalData.unshift({
          country: {
            id: this.userData.domicile.code,
            label: this.userData.domicile.name
          },
          fiscalCode: undefined
        })
        this.otherFiscal = true
      }
    }

    if (this.userData.domicile.code === 'US') {
      this.usaFiscal = true
      if (this.userData.tax_residence_usa_number) {
        this.usaFiscalNumber = this.userData.tax_residence_usa_number
      }
    }

    await this.setData()
  },
  computed: {
    ...mapGetters({
      hasAccessInPortal: 'user/hasAccessInPortal',
      screenDevice: 'layout/screenDevice'
    })
  },
  methods: {
    ...mapActions({
      getUserData: 'user/getUserData'
    }),
    async setData () {
      this.email = this.userData.email
      this.telephone = this.userData.phone
    },
    addOtherFiscal () {
      this.otherFiscalData.push({
        country: '',
        fiscalCode: ''
      })
    },
    deleteOtherFiscal (counter) {
      this.otherFiscalData.splice(counter, 1)
    },
    doesContainDuplicates (array) {
      let set = new Set(array)
      return set.size !== array.length
    },
    async save () {
      try {
        this.loadingSaveResidence = true
        let taxResidenceOther = []
        let countries = []

        if (!this.otherFiscal && !this.roFiscal && !this.usaFiscal) {
          this.validation = this.$t('settings.residence_data.select_option_validation')
          return
        }

        this.otherFiscalData.forEach(fiscalData => {
          if (!fiscalData.country) {
            this.countryOtherValid = false
          }

          if (fiscalData.country && !fiscalData.fiscalCode) {
            this.fiscalOtherValid = false
            this.otherFiscal = true
          }

          if (fiscalData.country && fiscalData.fiscalCode) {
            taxResidenceOther.push({
              country_code: fiscalData.country.id,
              tax_residence_number: fiscalData.fiscalCode
            })

            countries.push(fiscalData.country.id)
          }
        })

        if (this.usaFiscal && this.usaFiscalNumber == null) {
          this.fiscalUsaValid = false
        }

        if (this.doesContainDuplicates(countries)) {
          this.fiscalOtherValid = false
          this.fiscalValidationMessage = this.$t('settings.residence_data.duplicate_countries_validation')
          return
        }

        if (!this.fiscalOtherValid) {
          this.fiscalValidationMessage = this.$t('settings.residence_data.fiscal_code_validation')
          return
        }

        if (!this.fiscalUsaValid) {
          this.fiscalValidationMessage = this.$t('settings.residence_data.fiscal_code_validation')
          return
        }

        if (this.otherFiscal && !this.countryOtherValid) {
          this.countryOtherValidMessage = this.$t('settings.residence_data.country_validation')
          return
        }

        let payload = {
          has_tax_residence_ro: this.roFiscal ? 1 : 0,
          has_tax_residence_usa: this.usaFiscal ? 1 : 0,
          tax_residence_usa_number: this.usaFiscalNumber,
          tax_residence_other: taxResidenceOther
        }

        let selectResidenceData = await ApiService.withAuth().put(
          '/api/user',
          payload
        )
        if (selectResidenceData.status === 200) {
          if (!this.hasAccessInPortal) {
            await this.$router.push({name: ONBOARDING_QUESTIONNAIRE})
            return
          }
          this.dataSaved = true
          setTimeout(() => {
            this.dataSaved = false
          }, 2000)
        }
        this.loadingSaveResidence = false
      } finally {
        this.loadingSaveResidence = false
      }
    },
    async resetStep () {
      await this.$confirm(
        this.$t('onboarding.reset_steps.reset_bank_account'),
        '',
        'warning',
        { cancelButtonText: this.$t('general.general.cancel') }
      ).then(async () => {
        let reset = await questionnaireService.resetStep({reset_bank_account: true})
        if (reset.status === 200) {
          await this.$router.push({name: ONBOARDING_BANK_ACCOUNT})
        }
      })
    }
  },
  watch: {
    otherFiscalData: {
      handler (otherFiscalData) {
        this.fiscalOtherValid = true
        this.countryOtherValid = true
        let countriesIds = []

        otherFiscalData.forEach((fiscalData) => {
          if (fiscalData.country) {
            countriesIds.push(fiscalData.country.id)
          }
        })

        this.countries = this.allCountries.filter((country) => !countriesIds.includes(country.id))
      },
      deep: true
    },
    otherFiscal (nVal) {
      if (nVal) {
        this.validation = ''
        this.usaFiscal = false
        this.usaFiscalNumber = undefined
      }
    },
    roFiscal (nVal) {
      if (nVal) {
        this.validation = ''
      }
    },
    usaFiscal (nVal) {
      if (nVal) {
        this.validation = ''
        this.otherFiscal = false
        this.otherFiscalData = []
      }
    },
    usaFiscalNumber (nVal) {
      if (nVal) {
        this.validation = ''
        this.fiscalUsaValid = true
      }
    }
  }
}
</script>

<style scoped></style>
