<template>
  <div class="mb-6">
    <div v-if="allDocumentTypes.length">
      <SelectDropDown
        :id="newDocument+'id'"
        v-model="documentType"
        :options="allDocumentTypes"
        :placeholder="$t('documents.general.document_type')"
        :label="$t('documents.general.document_type')"
        class="mt-2"
        required
      />

      <Message v-if="validation.documentType" :message="validation.documentType" :type="'error'" class="mb-5"/>
    </div>

    <div v-if="allDocumentCategories.length && !selectedDocumentCategory">
      <SelectDropDown
        :id="newDocument+'id'"
        v-model="documentCategory"
        :options="allDocumentCategories"
        :placeholder="$t('documents.general.document_category')"
        :label="$t('documents.general.document_category')"
        class="mt-2"
        required
      />
      <div v-if="documentCategory">
        <div v-if="documentCategory.draft_document">
          <Message v-if="documentCategory.draft_document.original_name" :message="$t('documents.general.download_draft_message')" :type="'success'" class="mb-5"/>

          <div class="file-upload info flex items-center">
            <span @click="download(documentCategory.draft_document)" class="document cursor-pointer">
              <img
                :src="require(`@/assets/img/icons/upload-document.svg`)"
                alt="icon"
              />

              <span>{{ $t('documents.general.download_draft_label') }} {{ documentCategory.draft_document.original_name }}</span>
            </span>

            <div
              class="edit-button pl-2"
              @click="download(documentCategory.draft_document)">
              {{ $t('documents.general.download') }}
            </div>
          </div>
        </div>

        <hr/>
      </div>

      <Message v-if="validation.documentCategory" :message="validation.documentCategory" :type="'error'" class="mb-5"/>
    </div>

    <div class="file-upload info flex items-center my-8">
      <span @click="$refs.newDocument.click()" class="document cursor-pointer">
        <img
          :src="require(`@/assets/img/icons/upload-document.svg`)"
          alt="icon"
        />

        <span v-if="newDocument && component !== 'instruments_transfer'">{{ newDocument.name }}</span>

        <span class="info-title" v-else>
          <span class="font-bold underline color-black">{{ $t("general.general.upload_files") }}</span>

          <span class="info-title">{{ ' ' + $t('general.general.file_types') }}</span>
        </span>
      </span>

      <div
        v-if="newDocument && component !== 'instruments_transfer'"
        class="edit-button pl-2"
        @click="$refs.newDocument.click()">
        {{ $t('documents.general.edit') }}
      </div>
    </div>

    <input
      :ref="'newDocument'"
      hidden
      name="document"
      id="document"
      type="file"
      multiple="uploadMultipleFiles"
      @change="handleFileUpload"
    />

    <Message v-if="validation.newDocument" :message="validation.newDocument" :type="'error'" class="mb-5"/>
    <Message v-if="validation.sizeDocument" :message="validation.sizeDocument" :type="'error'" class="mb-5"/>
    <Message v-if="validation.typeDocument" :message="validation.typeDocument" :type="'error'" class="mb-5"/>

    <div class="desktop-flex" v-if="component !== 'instruments_transfer' && component !== 'pj_documents'">
      <Button :loading="loadingData.save" @call="save()" :class="[isMobile ? 'mb-8' : 'mr-2']" :text="buttonText ? buttonText : $t('documents.general.upload_document')" :type="'btn-primary'"/>
      <Button
        :text="$t('documents.general.cancel')"
        @call="$emit('closeUpload')"
        :type="'btn-secondary'"
        v-if="component === 'documents'"
      />
    </div>

    <Message :type="'success'" :message="success" v-if="success" class="mb-5"/>
    <Message :type="'error'" :message="error" v-if="error" class="mb-5"/>
  </div>
</template>

<script>
import DocumentsService from '../../services/documents/documentsService'
import Message from './Message'
import InputText from './InputText'
import Button from './Button'
import SelectDropDown from './SelectDropDown'
import {mapGetters} from 'vuex'

export default {
  name: 'UploadDocuments',
  components: { Message, InputText, Button, SelectDropDown },
  computed: {
    ...mapGetters({
      screenDevice: 'layout/screenDevice'
    }),
    isMobile () {
      return this.screenDevice === this.$screenDeviceConstants.MOBILE
    }
  },
  props: {
    allDocumentTypes: {
      type: Array,
      default: () => []
    },
    allDocumentCategories: {
      type: Array,
      default: () => []
    },
    selectedDocumentType: {
      type: Object,
      default: () => {}
    },
    selectedDocumentCategory: {
      type: String,
      default: ''
    },
    component: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    },
    uploadMultipleFiles: {
      type: Boolean,
      default: false
    },
    buttonText: {
      type: String,
      default: ''
    }
  },
  mounted () {
    if (this.selectedDocumentType && this.component !== 'instruments_transfer') {
      this.documentType = this.selectedDocumentType
    }

    if (this.selectedDocumentCategory && this.component !== 'instruments_transfer') {
      this.documentCategory = this.allDocumentCategories.filter(categ => categ.identifier === this.selectedDocumentCategory)[0]
    }
  },
  data () {
    return {
      loadingData: {
        save: false
      },
      validation: {
        newDocument: '',
        documentType: '',
        documentName: '',
        sizeDocument: '',
        typeDocument: '',
        documentCategory: ''
      },
      newDocument: null,
      newDocuments: null,
      documentType: null,
      documentCategory: null,
      documentName: null,
      success: '',
      error: '',
      allowedDocumentTypes: ['pdf', 'doc', 'docx', 'jpg', 'jpeg', 'png', 'xls', 'xlsx', 'csv', 'zip', 'rar']
    }
  },
  methods: {
    reset () {
      this.newDocument = null
      this.newDocuments = null
      this.documentType = null
      this.documentCategory = null
      this.documentName = null
      this.success = ''
      this.validation = {
        newDocument: '',
        documentType: '',
        documentName: '',
        sizeDocument: '',
        typeDocument: '',
        documentCategory: ''
      }
    },
    handleFileUpload (event) {
      if (this.uploadMultipleFiles) {
        this.newDocuments = event.target.files
      } else {
        this.newDocument = event.target.files[0]
      }
    },
    async save () {
      this.loadingData.save = true
      if (this.component === 'pj-coduments') {
        this.$emit('save')
        this.loadingData.save = false
        return
      }
      if (this.component === 'instruments-transfer') {
        return
      }

      try {
        if (this.validateDocument()) {
          await DocumentsService.saveDocument({
            name: this.documentName,
            type: this.documentType,
            category: this.documentCategory,
            file: this.newDocument
          }).then((response) => {
            if (response.status === 200 || response.status === 201) {
              this.$emit('success')
              this.success = this.$i18n.t('general.validations.success_upload_document')

              setTimeout(() => {
                this.success = ''
              }, 3000)
              if (response.data.data.updated) {
                this.documentName = ''
                this.documentType = this.selectedDocumentType ? this.selectedDocumentType : ''
                this.documentCategory = this.selectedDocumentCategory ? this.selectedDocumentCategory : ''
                this.newDocument = null
              }
            }
          }).catch(() => {
            this.error = this.$i18n.t('general.validations.error_upload_document')

            setTimeout(() => {
              this.error = ''
            }, 3000)
          })
        }
      } finally {
        this.loadingData.save = false
      }
    },
    validateDocument () {
      let valid = true

      if (!this.newDocument) {
        this.validation.newDocument = this.$t('documents.general.new_document')
        valid = false
        return
      }

      if (((this.newDocument.size / 1024) / 1024).toFixed(4) > 25) {
        this.validation.sizeDocument = this.$t('documents.general.size_too_big')

        valid = false
      }

      if (!this.validFileType(this.newDocument.type, this.newDocument.name)) {
        this.validation.typeDocument = this.$t('documents.general.type_document')

        valid = false
      }

      if (this.component === 'instruments_transfer') {
        return true
      }

      if (!this.documentType) {
        this.validation.documentType = this.$t('documents.general.select_document_type')

        valid = false
      }

      if (!this.documentCategory) {
        this.validation.documentCategory = this.$t('documents.general.select_document_category')

        valid = false
      }

      if (!this.newDocument) {
        this.validation.newDocument = this.$t('documents.general.new_document')

        valid = false
      }

      return valid
    },
    validFileType (mimeType, name) {
      const mime = require('mime-types')
      var ext = name.substr(name.lastIndexOf('.') + 1)

      return !!(this.allowedDocumentTypes.includes(mime.extension(mimeType)) || this.allowedDocumentTypes.includes(ext))
    },
    async download (doc) {
      await DocumentsService.downloadDocument(doc)
    }
  },
  watch: {
    documentName (nVal) {
      if (nVal) {
        this.validation.documentName = ''
      }
    },
    documentType (nVal) {
      if (nVal) {
        this.validation.documentType = ''
      }
    },
    documentCategory (nVal) {
      if (nVal) {
        this.validation.documentCategory = ''
      }
    },
    newDocument (nVal) {
      if (nVal) {
        this.validation.newDocument = ''
        this.validation.sizeDocument = ''
        this.validation.typeDocument = ''
        this.validation.documentCategory = ''

        if (this.validateDocument()) {
          this.$emit('documentUploaded', nVal, this.type)
        }
      }
    },
    newDocuments (nVal) {
      this.$emit('documentsUploaded', nVal, this.type)
    }
  }
}
</script>

<style scoped>

</style>
