import {wpLink} from '../../../../mixins/WordpressLinks'

export default {
  general: {
    cnp: 'CNP',
    number: 'Număr',
    series: 'Serie',
    address: 'Adresă',
    identity_card_type: 'Tip document de identitate',
    home_address: 'Adresă de domiciliu',
    update_id_card_info: 'Aceste date sunt extrase din documentul de identitate și pot fi modificate doar prin actualizarea acestuia',
    update_id_card: 'Actualizează documentul de identitate',
    update_ocr_data: 'Corectează datele',
    upload_id_card: 'Vă rugăm încărcați un document de identitate valabil.',
    save_message: 'Dacă este totul în regulă dă click pe `Salvează modificarile`.',
    success_id: 'Ai încarcat cu succes un nou document de identiate',
    save_data_succes: 'Datele au fost salvate cu succes',
    first_name: 'Prenume',
    last_name: 'Nume',
    full_name: 'Nume complet',
    correspondence_address: 'Corespondență',
    change_address: 'Schimbă adresa',
    full_address: 'Adresă completă',
    permanent_address: 'Locuință permanentă',
    expiration_date: 'Dată de expirare',
    release_date: 'Dată de eliberare',
    username_not_generated: 'În curs de generare...',
    domicile_address: 'Adresă de domiciliu',
    identity_card_address: 'Adresă (act identitate)',
    edit: 'Editează',
    address_note: 'Informațiile sunt salvate la click pe Continuă'

  },
  residence_data: {
    us_resident: 'Am rezidență/obligații fiscale în SUA',
    ro_resident: 'Am rezidență fiscală în România',
    other_resident: 'Am rezidență/obligații fiscale în altă țară (nu se aplică pentru SUA)',
    fiscal_code: 'Cod de identificare fiscală',
    fiscal_code_us: 'Cod de identificare fiscală - SUA',
    country: 'Țara',
    select_option_validation: 'Vă rugăm să selectați cel putin o opțiune',
    fiscal_code_validation: 'Vă rugăm completați codul fiscal pentru fiecare țară',
    country_validation: 'Vă rugăm completați țara și codul fiscal',
    add_country: 'Adaugă țară',
    data_saved: 'Date salvate!',
    duplicate_countries_validation: 'Vă rugăm să nu selectați mai multe țări de rezidență identice'
  },
  calendar: {
    months: {
      january: {
        long: 'Ianuarie',
        short: 'Ian'
      },
      february: {
        long: 'Februarie',
        short: 'Feb'
      },
      march: {
        long: 'Martie',
        short: 'Mar'
      },
      april: {
        long: 'Aprilie',
        short: 'Apr'
      },
      may: {
        long: 'Mai',
        short: 'Mai'
      },
      june: {
        long: 'Iunie',
        short: 'Iun'
      },
      july: {
        long: 'Iulie',
        short: 'Iul'
      },
      august: {
        long: 'August',
        short: 'Aug'
      },
      september: {
        long: 'Septembrie',
        short: 'Sep'
      },
      october: {
        long: 'Octombrie',
        short: 'Oct'
      },
      november: {
        long: 'Noiembrie',
        short: 'Nov'
      },
      december: {
        long: 'Decembrie',
        short: 'Dec'
      }
    },
    days: {
      monday: 'L',
      tuesday: 'M',
      wednesday: 'M',
      thursday: 'J',
      friday: 'V',
      saturday: 'S',
      sunday: 'D'
    }
  },
  referrals: {
    generate_link: 'Generează link',
    recommend_link: `Recomandă Investimental și câștigă! Mai multe detalii ` +
      `<a class="color-black font-bold" target="_blank" href="${wpLink('/recomanda-un-prieten/')}">AICI.</a>`,
    recommend_link_mobile: `Recomandă-ne și câștigă! Detalii ` +
      `<a class="color-black font-bold" target="_blank" href="${wpLink('/recomanda-un-prieten/')}">AICI.</a>`,
    message_copied_succesfully: 'Link copiat cu succes!'
  }
}
