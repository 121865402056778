<template>
  <div class="pb-10" :class="{'px-5' : isMobile}">
    <Card v-on:keyup.enter="challengeUser"
          class="padding-custom w-full m-auto"
          :card-classes="'mobile-card-portal'"
    >
      <div class="text-center" slot="title">{{ $t("auth.general.log_in") }}</div>

      <div slot="body" class="mt-4">
        <div v-if="!useRecoveryCode" class="form-group mb-6">
          <div>
            <div class="mt-4 w-full mb-6 text-center">
              {{ twoFactor === TWO_FA_TYPE_AUTH ? $i18n.t('auth.two_factor_auth.code') : $i18n.t('general.validations.sms_code') }}
            </div>

            <OtpInput
              type="tel"
              :code-length="6"
              @on-complete="setVerificationCode"
              @on-paste="setVerificationCode"
              @on-key-enter="enterPressed"
            />
          </div>
        </div>

        <div v-else class="form-group mb-6">
          <InputText
            class="m-bottom-custom"
            v-model="recoveryCode"
            id="recoveryCode"
            :type-input="'text'"
            :label="$t('auth.two_factor_auth.recovery_code')"
          />
        </div>

        <Message v-if="error" :type="'error'" :message="error"/>

        <Checkbox
          class="mt-5 justify-center"
          v-model="saveDevice"
          id="saveDevice"
          @on-key-enter="enterPressed"
          :label="$t('auth.two_factor_auth.save_device')"
        />

        <div class="mt-5" @click="challengeUser">
          <Button
            :w100="true"
            :text="$t('auth.two_factor_auth.check')"
            :type="'btn-primary'"
          />
        </div>

        <div v-if="isSms">
          <ResendCode/>
        </div>
      </div>
    </Card>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import Button from '../subcomponents/Button'
import Message from '../subcomponents/Message'
import Card from '../subcomponents/Card'
import InputText from '../subcomponents/InputText'
import Checkbox from '../subcomponents/Checkbox'
import OtpInput from '../subcomponents/security-code/OtpInput.vue'
import { TWO_FA_TYPE_AUTH, TWO_FA_TYPE_SMS } from '@/store/modules/user'
import {getFingerPrint} from '@/helpers/fingerPrint'
import ResendCode from '@/components/auth/subcomponents/ResendCode.vue'

export default {
  name: 'TwoFactorAuthentication',
  components: {ResendCode, OtpInput, Message, Button, Card, InputText, Checkbox},
  data () {
    const device = getFingerPrint()

    return {
      code: '',
      recoveryCode: '',
      useRecoveryCode: false,
      error: '',
      qrCode: '',
      saveDevice: false,
      loading: false,
      device,
      TWO_FA_TYPE_AUTH: TWO_FA_TYPE_AUTH,
      TWO_FA_TYPE_SMS: TWO_FA_TYPE_SMS,
      timer: 0
    }
  },
  computed: {
    ...mapGetters({
      twoFactor: 'user/twoFactor',
      screenDevice: 'layout/screenDevice'
    }),
    isMobile () {
      return this.screenDevice === this.$screenDeviceConstants.MOBILE
    },
    isSms () {
      return this.twoFactor === this.TWO_FA_TYPE_SMS
    }
  },
  async created () {
    if (this.isSms) {
      await this.generateSmscode()
    }
  },
  methods: {
    ...mapActions({
      challenge: 'user/challenge',
      getQrCode: 'user/getQrCode',
      setLoggedId: 'user/setLoggedId',
      generateSmscode: 'user/generateSmscode',
      verifySmsCode: 'user/verifySmsCode'
    }),

    async enterPressed (code = null) {
      if (code) {
        await this.setVerificationCode(code)
      }
      await this.challengeUser()
    },

    setVerificationCode (code) {
      this.code = code
    },

    async challengeUser () {
      let recoveryCode = this.recoveryCode
      let code = this.code
      let device = this.saveDevice ? this.device : null

      if (this.useRecoveryCode) {
        code = null
      } else {
        recoveryCode = null
      }

      this.challenge({
        recovery_code: recoveryCode,
        code,
        device
      }).catch((result) => {
        this.error = result.response.data.message
      }).then(async (result) => {
        if (result.status === 200) {
          await this.setLoggedId({
            loggedIn: true,
            has2FAConfirmed: true
          })

          await this.$router.push({name: 'homepage'})
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
