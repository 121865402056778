import roIdDocument from './languages/ro/sumsub/id-document-level'
import enIdDocument from './languages/en/sumsub/id-document-level'
const sumsub = {
  ro: {
    idDocument: roIdDocument
  },
  en: {
    idDocument: enIdDocument
  }
}

export default sumsub
