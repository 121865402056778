<template>
  <div>
    <Card :card-classes="'margin-bottom-medium mobile-border-none'"
          :class="[screenDevice !== $screenDeviceConstants.DESKTOP && screenDevice !== $screenDeviceConstants.TABLET ? 'mt-28' : 'mt-36']">
      <img alt="green_circle_person" slot="icon" src="~@/assets/img/icons/green_circle_person.svg" />
      <div slot="title">{{ $t("update_personal_data.general.update_account") }}</div>
      <div slot="body" class="email-verification-body">
        <div>
          <UpdateDomicile v-if="getStep === 'updateDomicile'"
                          @next="nextStep"/>
          <PhoneNumber v-if="getStep === 'updatePhoneNumber'"
                       @next="nextStep"
                       @prev="prevStep"/>
          <VerifyPhoneNumber v-if="getStep === 'verifyPhoneNumber'"
                     @next="nextStep"
                     @prev="prevStep"/>
          <UpdateIdentityForm v-if="getStep === 'updateIdentityCard'"
                              @next="nextStep"
                              @prev="prevStep"/>
          <UpdateResidence v-if="getStep === 'updateResidence'"
                            @next="nextStep"
                            @prev="prevStep"/>
          <UpdateQuestionnaire v-if="getStep === 'updateQuestionnaire'"
                               :show-intro-prop="showIntro"
                               :selected-question-prop="selectedQuestion"
                               :from-docs="fromDocs"
                               @setFromDocs="fromDocs = false"
                               @next="nextStep"
                               @prev="prevStep"/>
          <UpdateDocuments v-if="getStep === 'updateDocuments'"
                                @next="nextStep"
                                @prev="prevStep"/>
        </div>
      </div>
    </Card>
  </div>
</template>

<script>
import Card from '../../subcomponents/Card.vue'
import UpdateEmail from './UpdateEmail.vue'
import UpdateDomicile from './UpdateDomicile.vue'
import {mapActions, mapGetters} from 'vuex'
import EmailVerificationCode from '../../subcomponents/EmailVerificationCode.vue'
import PhoneNumber from './PhoneNumber.vue'
import UpdateResidence from './UpdateResidence.vue'
import UpdateIdentityForm from './UpdateIdentityForm.vue'
import UpdateQuestionnaire from './UpdateQuestionnaire.vue'
import UpdateDocuments from './UpdateDocuments.vue'
import VerifyPhoneNumber from './VerifyPhoneNumber.vue'
import ApiService from '../../../services/apiService'
import {ClearGarbageStorageService} from '@/services/clearGarbageStorageService'

export default {
  name: 'UpdatePersonalData',
  components: {
    UpdateDocuments,
    UpdateQuestionnaire,
    PhoneNumber,
    EmailVerificationCode,
    UpdateEmail,
    Card,
    UpdateDomicile,
    UpdateIdentityForm,
    UpdateResidence,
    VerifyPhoneNumber
  },
  data () {
    return {
      step: 'updateDomicile',
      showIntro: true,
      selectedQuestion: 0,
      fromDocs: false
    }
  },
  async mounted () {
    ClearGarbageStorageService.clear()
    ApiService.withAuth().post('/api/user/documents/anniversary/order')
    await this.setIsUpdating(true)
  },
  computed: {
    ...mapGetters({
      updatedData: 'updatePersonalData/getUpdatedData',
      getStep: 'updatePersonalData/getStep',
      screenDevice: 'layout/screenDevice'
    })
  },
  methods: {
    ...mapActions({
      setUpdatedData: 'updatePersonalData/setUpdatedData',
      setStep: 'updatePersonalData/setStep',
      setIsUpdating: 'updatePersonalData/setIsUpdating',
      resetUpdatedData: 'updatePersonalData/resetState'
    }),
    nextStep (userDataArgs) {
      if (userDataArgs) {
        this.setUpdatedData(userDataArgs.updatedData)
        this.setStep(userDataArgs.nextStep)
      }
    },
    prevStep (step, extraData = null) {
      this.setStep(step)
      if (extraData && extraData.questionnaire) {
        this.showIntro = extraData.questionnaire.showIntro
        this.selectedQuestion = extraData.questionnaire.selectedQuestion
        this.fromDocs = true
      }
    }
  }
}
</script>

<style scoped>

</style>
