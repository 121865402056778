import ApiService from '../apiService'

class IdentityCardService {
  async saveNewIdCard (payload) {
    return ApiService.withAuth().post('/api/user/identity-cards', payload, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }

  async getTypes () {
    return ApiService.withAuth().get('/api/user/identity-cards/types')
  }
}

export default new IdentityCardService()
