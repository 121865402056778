const getDefaultState = () => {
  return {
    countries: []
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    resetState (state) {
      Object.assign(state, getDefaultState())
    },

    setCountries (state, countries) {
      state.countries = countries
    }
  },
  getters: {
    getCountries ({countries}) {
      return countries
    }
  },
  actions: {
    setCountries (ctx, countries) {
      ctx.commit('setCountries', countries)
    }
  }
}
