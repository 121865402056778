<template>
  <div class="country-codes-phone">
    <div class="form-label inline-block pl-4">{{ $t('update_personal_data.general.dial_code') }}</div>

    <div
      v-if="!countriesVisible"
      class="area-code"
      :class="{'disabled' : disabledInput}"
      @click="toggleCountries()"
    >
      <div class="flag" v-if="$attrs.value">
        <img :src="$attrs.value.flag" :alt="`country-${$attrs.value.code}`">
      </div>
      <div class="code">
        {{ $attrs.value.dial_code ? $attrs.value.dial_code : '' }}
      </div>
    </div>

    <div v-if="countriesVisible">
      <InputText
        class="relative"
        v-model="searchValue"
      >
      </InputText>
      <div class="country-codes" >
        <div
          v-for="(country, index) in searchableCountries"
          :key="country.code + index"
          class="country-code"
          @click="setValue(country)"
        >
          <div class="flag">
            <img onerror="this.style.display='none'" :src="country.flag" :alt="`country-${country.code}-${index}`">
          </div>
          <div class="code">
            {{ country.dial_code }}
          </div>
          <div class="country-name">
            {{ country.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputText from './InputText.vue'

export default {
  name: 'CountryCodesPhone',
  components: {InputText},
  props: {
    countriesAvailable: {
      type: Array,
      default: () => [],
      required: true
    },
    disabledInput: {
      type: Boolean,
      default: false
    },
    pjOnboarding: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      countriesVisible: false,
      searchValue: '',
      searchableCountries: this.countriesAvailable
    }
  },

  mounted () {
    document.addEventListener('click', this.close)
    this.filterCountriesAvailable()
  },
  beforeDestroy () {
    document.removeEventListener('click', this.close)
  },
  methods: {

    filterCountriesAvailable () {
      const element = this.searchableCountries.filter(country => {
        return country.code === 'RO'
      })[0]

      const elementIndex = this.countriesAvailable.indexOf(element)

      this.searchableCountries.splice(0, 0, this.searchableCountries.splice(elementIndex, 1)[0])
    },

    searchCountries () {
      this.searchableCountries = this.countriesAvailable.filter(country => {
        return country.name.toLowerCase().startsWith(this.searchValue.toLowerCase())
      })
    },
    searchDialCodes () {
      this.searchableCountries = this.countriesAvailable.filter(country => {
        return country.dial_code.toLowerCase().startsWith(this.searchValue.toLowerCase())
      })
    },
    toggleCountries () {
      this.countriesVisible = !this.countriesVisible
    },
    setValue (country) {
      this.$emit('setValueOfCode', country)
      this.toggleCountries()
    },
    close (e) {
      if (!this.$el.contains(e.target) && (e.path && !e.path.find(el => el.className === 'area-code'))) {
        this.countriesVisible = false
        this.searchValue = ''
      }
    }
  },
  watch: {
    searchValue: {
      handler (searchValue) {
        if (/[A-Za-z]/.test(searchValue)) {
          this.searchCountries()
        } else {
          this.searchDialCodes()
        }
      },
      deep: true
    }
  }
}
</script>

<style scoped>

</style>
