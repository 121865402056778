<template>
  <div class="bank-accounts">
    <div v-if="bankAccounts.length < 4">
      <div class="bank-accounts-data">
        <div>
          <div v-if="newIbanIsAdded">
            <AddBankAccount :bank-accounts="bankAccounts" @setData="setData" @cancelNewIbanIsAdded="cancelNewIbanIsAdded"/>
          </div>

          <Transition-Group appear name="fade">
            <template v-if="bankAccounts && !newIbanIsAdded">
              <div
                class="info bank-account"
                v-for="(bankAccount, counter) in bankAccounts" :key="counter" v-if="counter < 2" ref="bankAccountItem">
                <BankAccountItem @setData="setData" :bank-account="bankAccount" :counter="counter" :key="counter" :deletable="Object.keys(bankAccounts).length > 1"/>
              </div>
            </template>
          </Transition-Group>
          <Button
            class="m-bottom-custom"
            :class="{'mt-10' : bankAccounts.length}"
            v-if="!newIbanIsAdded && bankAccounts.length < 3"
            :text="$t('deposit_withdrawal.bank_accounts.add_new_iban')"
            @call="setNewIbanIsAdded"
            :type="'btn-primary'"
          />
        </div>

        <div>
          <Card class="w-full mb-4" v-if="!newIbanIsAdded">
            <template v-slot:body>
              <div>
                <p v-html="$t('guides.bank_accounts.bank_accounts_p1')"></p>
                <br>
                <p v-html="$t('guides.bank_accounts.bank_accounts_p2')"></p>
                <br>
                <p>{{ $t('guides.bank_accounts.bank_accounts_p3') }}</p>
              </div>
            </template>
          </Card>

          <Card class="w-full mb-4" v-if="newIbanIsAdded">
            <template v-slot:body>
              <div>
                <p>{{ $t('guides.bank_accounts.bank_accounts_addnew_p1') }}</p>
                <br>
                <p>{{ $t('guides.bank_accounts.bank_accounts_addnew_p2') }}</p>
                <br>
                <p>{{ $t('guides.bank_accounts.bank_accounts_addnew_p3') }}</p>
                <br>
                <p>{{ $t('guides.bank_accounts.bank_accounts_addnew_p4') }}</p>
              </div>
            </template>
          </Card>

          <Resources :links="resourcesLinks"/>
        </div>
      </div>

      <div class="info bank-account" ref="bankAccountItem3" v-if="!newIbanIsAdded && bankAccounts[2]">
        <BankAccountItem
          @setData="setData"
          class="w-50 mt-6"
          :bank-account="bankAccounts[2]"
          :counter="2"
          :key="2"
          :deletable="Object.keys(bankAccounts).length > 1"
        />
      </div>

      <div class="padding-card mt-8">
        <Button
          class="m-bottom-custom"
          v-if="!newIbanIsAdded && bankAccounts.length === 3"
          :text="$t('deposit_withdrawal.bank_accounts.add_new_iban')"
          @call="setNewIbanIsAdded"
          :type="'btn-primary'"
        />
      </div>
    </div>

    <div v-else class="bank-accounts-4">
      <div>
        <div v-if="newIbanIsAdded">
          <AddBankAccount :bank-accounts="bankAccounts" @setData="setData" @cancelNewIbanIsAdded="cancelNewIbanIsAdded"/>
        </div>

        <div class="grid grid-cols-2 gap-8" v-if="bankAccounts && !newIbanIsAdded">
          <div
            class=""
            v-for="(bankAccount, counter) in bankAccounts" :key="counter" ref="bankAccountItem">
            <BankAccountItem
              :deletable="Object.keys(bankAccounts).length > 1"
              :bank-account="bankAccount"
              :counter="counter"
              :key="counter"
              @setData="setData"
            />
          </div>
        </div>

        <Button
          class="m-bottom-custom mt-10"
          v-if="!newIbanIsAdded"
          :text="$t('deposit_withdrawal.bank_accounts.add_new_iban')"
          @call="setNewIbanIsAdded"
          :type="'btn-primary'"
        />
      </div>

      <div class="my-12 bg-white h-px"></div>

      <div class="grid grid-cols-3 gap-8">
        <Card :card-classes="'w-full'">
          <template v-slot:body>
            <div>
              <p v-html="$t('guides.bank_accounts.bank_accounts_p1_v1')"/>
            </div>
          </template>
        </Card>

        <Card :card-classes="'w-full'">
          <template v-slot:body>
            <div>
              <p v-html="$t('guides.bank_accounts.bank_accounts_p2_v1')"/>
            </div>
          </template>
        </Card>

        <div class="w-full h-full">
          <Resources class="h-full" card-classes="h-full" :links="resourcesLinks"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GeneralLayout from '../../layout/GeneralLayout'
import Tabs from '../../layout/Tabs'
import Button from '../../subcomponents/Button'
import InputText from '../../subcomponents/InputText'
import Card from '../../subcomponents/Card'
import IconBox from '../../subcomponents/IconBox.vue'
import Message from '../../subcomponents/Message'
import ContinueToOnboarding from '../ContinueToOnboarding'
import SelectDropDown from '../../subcomponents/SelectDropDown'
import Checkbox from '../../subcomponents/Checkbox.vue'
import BankAccountItem from './BankAccountItem.vue'
import AddBankAccount from './AddBankAccount.vue'
import Resources from '../../subcomponents/Resources.vue'
import BankAccountsService from '../../../services/deposit-withdrawal/bankAccountsService'

export default {
  name: 'BankAccountsDesktop',
  components: { Resources, AddBankAccount, BankAccountItem, Checkbox, GeneralLayout, Tabs, Button, InputText, Card, IconBox, Message, ContinueToOnboarding, SelectDropDown },
  props: {
    bankAccountsDefault: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      bankAccounts: this.bankAccountsDefault,
      newIbanIsAdded: false,
      resourcesLinks: [
        { label: this.$t('useful_links.bank_accounts.bank_accounts_how_to_add_iban'), value: '/faq/vreau-sa-adaug-un-nou-cod-iban-cum-fac/' },
        { label: this.$t('useful_links.bank_accounts.bank_accounts_how_to_delete_iban'), value: '/faq/vreau-sa-sterg-un-cod-iban-cum-fac/' }
      ],
      newIban: null,
      isRevolutIbanChecked: false,
      isRevolutIban: false,
      validation: '',
      selectedCurrency: '',
      bankAccountItemHeight: 0
    }
  },
  mounted () {
    if (this.$refs.bankAccountItem) {
      this.bankAccountItemHeight = this.$refs.bankAccountItem[0].offsetHeight + 'px'
    }

    this.setThirdBankAccountItemHeight()
  },
  methods: {
    async setData () {
      let bankAccounts = await BankAccountsService.getBankAccounts()

      this.bankAccounts = bankAccounts.data.data.bankAccounts

      this.setThirdBankAccountItemHeight()
    },
    setThirdBankAccountItemHeight () {
      this.$nextTick(() => {
        if (this.$refs.bankAccountItem3) {
          this.$refs.bankAccountItem3.style.height = this.bankAccountItemHeight
        }
      })
    },
    cancelNewIbanIsAdded () {
      this.newIbanIsAdded = false
      this.setThirdBankAccountItemHeight()
    },
    async setNewIbanIsAdded () {
      this.newIbanIsAdded = true
      this.newIban = null
      this.newIbanDocument = null
      this.isRevolutIbanChecked = false
      this.isRevolutIban = false
      this.validation = ''
      this.selectedCurrency = ''
      this.setThirdBankAccountItemHeight()
    }
  },
  watch: {
    newIban: {
      handler (newIbanValue) {
        if (newIbanValue) {
          this.newIban = this.newIban.replace(/\s/g, '')
          this.isRevolutIban = newIbanValue.slice(4, 8) === 'BREL'
        } else {
          this.isRevolutIban = false
        }
      },
      deep: true
    }
  }
}
</script>

<style scoped></style>
