import ApiService from '../apiService'

class BankAccountService {
  getBankAccounts () {
    return ApiService.withAuth().get('/api/user/bank-accounts')
  }
  saveBankAccount (payload) {
    return ApiService.withAuth().post('/api/user/bank-accounts', payload, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }
  deleteBankAccount (payload) {
    return ApiService.withAuth().delete('/api/user/bank-accounts/' + payload)
  }

  getLibraBankFintechs () {
    return ApiService.withAuth().get('/api/user/banks/libra-bank-fintechs')
  }
}

export default new BankAccountService()
