<template>
  <div>
    <general-layout>
      <template v-slot:tabs>
        <Tabs
          :show-id-title="false"
          :tabs-classes="tabsClasses"
        />
      </template>

      <template v-slot:body>
        <div class="mr-6 deposit-withdrawal">
          <span class="warning">Momentan nu oferim</span>
        </div>
      </template>

      <template v-slot:observations>
        <div>
          <div class="left-side-title">{{ $t('guides.general.guides') }}</div>
          <IconBox
            :text="$t('guides.general.deposit_guide')"
            :icon="'icons/ghid_alimentare_cont'"
          />
          <IconBox
            :text="$t('guides.general.required_documents')"
            :icon="'icons/acte_necesare'"
          />
        </div>
      </template>

      <template v-slot:resources>
        <div class="mt-4">
          <div class="left-side-title">{{ $t('useful_links.general.useful_links') }}</div>

          <Card class="w-full"
                :card-classes="'grey-card mobile-card-resources padding-small'">
            <template v-slot:body>
              <div>
                <a class="color-blue" target="_blank" href="#">
                  {{ $t('guides.general.deposit_guide') }}
                </a>
              </div>

              <div class="mt-2">
                <a class="color-blue" target="_blank" href="#">
                  {{ $t('useful_links.general.withdrawals_guide') }}
                </a>
              </div>

              <div class="mt-2">
                <a class="color-blue" target="_blank" href="#">
                  {{ $t('useful_links.general.install_arena_xt') }}
                </a>
              </div>
            </template>
          </Card>
        </div>
      </template>
    </general-layout>
  </div>
</template>

<script>
import GeneralLayout from '../../layout/GeneralLayout'
import Tabs from '../../layout/Tabs'
import Button from '../../subcomponents/Button'
import {mapGetters} from 'vuex'

export default {
  name: 'ManageCards',
  components: {GeneralLayout, Tabs, Button},
  data () {
    return {
      loading: true,
      tabsClasses: ['mb-4']
    }
  },
  computed: {
    ...mapGetters({
      screenDevice: 'layout/screenDevice'
    })
  }
}
</script>

<style scoped></style>
