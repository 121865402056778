<template>
  <div class="portofolio-page">
    <general-layout :no-padding-top="true" :no-padding="isMobile">
      <template v-slot:title>
        <vue-title :title="$t('general.meta_titles.pies')"></vue-title>
      </template>

      <template v-slot:tabs>
        <Tabs
          :show-id-title="false"
        />
      </template>

      <template v-slot:body>
        <div v-if="user.status !== 'approved'">
          <Card :card-classes="'margin-bottom-medium mobile-border-none mb-4 mt-12'">
            <template v-slot:body>
              {{ $t('deposit_withdrawal.multi_account.not_approved_pies') }}
            </template>
          </Card>
        </div>
        <template v-else>
          <div v-if="showBackgroundData">
            <div v-if="!loading">
              <div v-if="pies.pies && pies.pies.length" class="autoinvest-pies mt-8">
                <Button
                  v-if="isMobile && maximumNumberOfPies > pies.pies.length"
                  :loading=addPieLoader
                  @call="addPie"
                  extra-classes="px-5 mt-7 mobile-button"
                  :icon="'icons/circled_add'"
                  :icon-position="'left'"
                  :text="$t('deposit_withdrawal.auto_invest.add_pie')"
                  :type="'btn-primary'"
                />

                <AutoInvestPiesTableDesktop v-if="isDesktop" @seePie="seePie" @getAllPies="getAllPies"/>
                <AutoInvestPiesTableMobile v-else @seePie="seePie" @getAllPies="getAllPies"/>

                <Button
                  v-if="!isMobile && maximumNumberOfPies > pies.pies.length"
                  :loading=addPieLoader
                  @call="addPie"
                  extra-classes="px-5 mt-7"
                  :icon="'icons/circled_add'"
                  :icon-position="'left'"
                  :text="$t('deposit_withdrawal.auto_invest.add_pie')"
                  :type="'btn-primary'"
                />
                <div class="desktop-flex gap-8" :class="[isMobile ? 'px-5 mt-8' : 'mt-9']">
                  <Resources class="w-full" :links="resourcesLinks"/>
                  <Card :card-classes="'margin-bottom-medium mobile-border-none'" :class="[isMobile ? 'mt-6' : '']">
                    <template v-slot:body>
                      <div v-html="$t('deposit_withdrawal.auto_invest.pies_info.limits_info', {maxNumberPies: maximumNumberOfPies, minInstrumentsPies: minimumInstrumentsOfPie, maxInstrumentsPies: maximumInstrumentsOfPie})"/>
                      <div class="font-bold mb-2" v-if="!dxAccountsTradingAccessEnabled">{{ $t('deposit_withdrawal.auto_invest.pies_info.first_info')}}</div>
                      <div v-if="!dxAccountsTradingAccessEnabled" v-html="$t('deposit_withdrawal.auto_invest.pies_info.second_info')"/>
                    </template>
                  </Card>
                </div>
              </div>
              <div v-else class="desktop-flex gap-8" :class="[isMobile ? 'px-5 mt-8' : 'mt-9']">
                <Card
                  class="w-full"
                  :no-title="true"
                >
                  <img class="mx-auto image-dimensions-multi-cont"
                       :src="require(`@/assets/img/pies_empty_state.svg`)"
                       alt="multi-cont">

                  <Button
                    class="flex justify-center w-fit mx-auto"
                    :loading=addPieLoader
                    @call="addPie"
                    extra-classes="px-5 mt-7"
                    :icon="'icons/circled_add'"
                    :icon-position="'left'"
                    :text="$t('deposit_withdrawal.auto_invest.add_pie')"
                    :type="'btn-primary'"
                  />
                </Card>
                <div :class="[isMobile ? 'mt-6 gap-6' : 'gap-8']" class="w-full h-full flex flex-col">
                  <Card :card-classes="'margin-bottom-medium mobile-border-none'">
                    <template v-slot:body>
                      <div v-html="$t('deposit_withdrawal.auto_invest.pies_info.limits_info', {maxNumberPies: maximumNumberOfPies, minInstrumentsPies: minimumInstrumentsOfPie, maxInstrumentsPies: maximumInstrumentsOfPie})"/>
                      <div class="font-bold mb-2" v-if="!dxAccountsTradingAccessEnabled">{{ $t('deposit_withdrawal.auto_invest.pies_info.first_info')}}</div>
                      <div v-if="!dxAccountsTradingAccessEnabled" v-html="$t('deposit_withdrawal.auto_invest.pies_info.second_info')"/>
                    </template>
                  </Card>
                  <Resources :links="resourcesLinks"/>
                </div>
              </div>

            </div>

            <div class="flex justify-center items-center custom-height-loader" v-if="loading">
              <PulseLoader />
            </div>
          </div>
          <div v-if="savingPie" class="autoinvest-pies">
            <SavePie
              :step="step"
              @lastStep="lastStep"
              @setMobileStep="step = $event"
              @clearData="clearData"
              @closeModal="closePieModal"
            />
          </div>
        </template>
      </template>
    </general-layout>
  </div>
</template>

<script>
import GeneralLayout from '@/components/layout/GeneralLayout.vue'
import Tabs from '@/components/layout/Tabs.vue'
import AutoInvestPiesTableDesktop from '@/components/subcomponents/auto-invest/AutoInvestPiesTableDesktop.vue'
import Button from '@/components/subcomponents/Button.vue'
import ShowPieMobile from '@/components/profile/deposit-withdrawal/auto-invest/ShowPieMobile.vue'
import {mapActions, mapGetters} from 'vuex'
import PopUpModal from '@/components/subcomponents/PopUpModal.vue'
import InputText from '@/components/subcomponents/InputText.vue'
import SavePie from '@/components/profile/deposit-withdrawal/auto-invest/SavePie.vue'
import piesService from '@/services/deposit-withdrawal/piesService'
import AutoInvestPiesTableMobile from '@/components/subcomponents/auto-invest/AutoInvestPiesTableMobile.vue'
import ShowPieDesktop from '@/components/profile/deposit-withdrawal/auto-invest/ShowPieDesktop.vue'
import Card from '@/components/subcomponents/Card.vue'
import Resources from '@/components/subcomponents/Resources.vue'
import {Chart} from 'chart.js'

export default {
  name: 'AutoInvest',
  components: {
    Resources,
    Card,
    ShowPieDesktop,
    AutoInvestPiesTableDesktop,
    AutoInvestPiesTableMobile,
    SavePie,
    InputText,
    PopUpModal,
    ShowPieMobile,
    Button,
    Tabs,
    GeneralLayout
  },
  data () {
    return {
      addPieLoader: false,
      loading: false,
      savingPie: false,
      step: '',
      existingNewPie: false,
      resourcesLinks: [
        { label: this.$t('useful_links.pies.what_are_pies'), value: '/ghid/ce-sunt-pies/' },
        { label: this.$t('useful_links.pies.how_to_use'), value: '/ghid/ghid-pies' },
        { label: this.$t('useful_links.pies.long_term_invest'), value: '/blog/investitia-pe-termen-lung-in-pies' }
      ]
    }
  },
  computed: {
    ...mapGetters({
      pies: 'pies/pies',
      screenDevice: 'layout/screenDevice',
      maximumNumberOfPies: 'user/maximumNumberOfPies',
      maximumInstrumentsOfPie: 'user/maximumInstrumentsOfPie',
      minimumInstrumentsOfPie: 'user/minimumInstrumentsOfPie',
      user: 'user/user',
      addedStockSecurities: 'pies/addedStockSecurities',
      deletedStockSecurities: 'pies/deletedStockSecurities',
      dxAccountsTradingAccessEnabled: 'user/dxAccountsTradingAccessEnabled'
    }),
    isMobile () {
      return this.screenDevice === this.$screenDeviceConstants.MOBILE
    },
    isDesktop () {
      return this.screenDevice === this.$screenDeviceConstants.DESKTOP
    },
    showBackgroundData () {
      if (!this.isDesktop) {
        return !this.step.length || this.step === 'showNamePieModal'
      } else {
        return true
      }
    },
    isProduction () {
      return process.env.VUE_APP_ENV === 'production'
    }
  },
  mounted () {
    this.registerNewChartPlugin()
    this.getAllPies()
  },
  methods: {
    ...mapActions({
      setPieId: 'pies/setPieId',
      setNewPie: 'pies/setNewPie',
      setPies: 'pies/setPies'
    }),

    closePieModal () {
      this.savingPie = false
      this.step = ''
    },

    clearData () {
      this.setNewPie({})
    },

    async lastStep () {
      await this.setPieId(-1)
      await this.$router.push({name: 'pie', params: {id: -1}})
    },
    async seePie (id) {
      await this.setPieId(id)
      await this.$router.push({name: 'pie', params: {id}})
    },

    addPie () {
      this.savingPie = true
    },

    async getAllPies () {
      this.loading = true
      await piesService.getAllPies()
        .then(response => {
          this.setPies(response.data.data)
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.loading = false
        })
    },

    registerNewChartPlugin () {
      Chart.register({
        id: 'textCenter',
        beforeDraw: function (chart, args, options) {
          const enableTextCenter = chart.options.plugins.textCenter.enabled
          if (!enableTextCenter) {
            return
          }
          const {ctx, chartArea: {top, right, bottom, left, width, height}} = chart
          ctx.save()
          ctx.font = '700 22px sans-serif'
          ctx.fillStyle = chart.config._config.options.centerTextColor || '#001C4D'
          ctx.textAlign = 'center'
          ctx.textBaseline = 'middle'
          const totalText = chart.config._config.options.centerText || 'Total\n100%'
          const textLines = totalText.split('\n')
          const firstLineY = top + height / 2 - 5
          ctx.fillText(textLines[0], left + width / 2, firstLineY)

          const secondLineY = top + height / 2 + 17
          ctx.fillText(textLines[1], (left + width / 2) + 3, secondLineY)
          ctx.restore()
        }
      })
    }
  }
}
</script>
