<template>
  <div v-if="assets.assets.length">
    <Transition name="slide-fade">
      <table class="assets-table" v-if="openedTable">
        <thead>
          <tr>
            <th>{{ $t('deposit_withdrawal.portfolio.symbol') }}</th>
            <th>{{ $t('deposit_withdrawal.portfolio.quantity') }}</th>
            <th>{{ $t('deposit_withdrawal.portfolio.medium_cost') }}</th>
            <th>{{ $t('deposit_withdrawal.portfolio.market_price') }}</th>
            <th>{{ $t('deposit_withdrawal.portfolio.variation') }}</th>
            <th>{{ $t('deposit_withdrawal.portfolio.evaluation') }}</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(asset, key) in assets.assets" :key="key" class="row" v-if="asset.type === 'security'">
            <td>
              <div class="symbol" :class="[`symbol-${colorSymbol}`]">
                {{asset.symbol}}
              </div>
            </td>

            <td>{{ asset.quantity ? parseFloat(asset.quantity).toLocaleString('en-US', {maximumFractionDigits: 8}) : '-' }}</td>
            <td>{{ asset.medium_cost && parseFloat(asset.medium_cost) !== 0 ? (Math.floor(parseFloat(asset.medium_cost) * 100) / 100) : '-' }}</td>
            <td>{{ asset.market_price ? (Math.floor(parseFloat(asset.market_price) * 10000) / 10000).toLocaleString('en-US', {maximumFractionDigits: 4}) : '-' }}</td>
            <td>{{ asset.variation === '-' ? `${asset.variation}` : `${parseFloat(asset.variation)}%` }}</td>
            <td>{{ asset.evaluation ? (Math.floor(parseFloat(asset.evaluation) * 100) / 100).toLocaleString('en-US', {maximumFractionDigits: 2}) : '-' }}</td>
          </tr>

          <tr v-for="(asset, key) in assets.assets" :key="key" class="row" v-if="asset.type === 'future-security'">
            <td>
              <div class="symbol cursor-pointer symbol-future-cash flex" :class="[`symbol-${colorSymbol}`]" @click="openCorporateDetails(asset.type, key)">
                <img class="icon" src="@/assets/img/icons/click.svg" alt="click">

                {{asset.symbol}} {{ $t('deposit_withdrawal.portfolio.free')}}
              </div>
            </td>

            <td>{{ asset.quantity ? Math.trunc(parseFloat(asset.quantity)) : '-' }}</td>
            <td>{{ asset.medium_cost && parseFloat(asset.medium_cost) !== 0 ? (Math.floor(parseFloat(asset.medium_cost) * 100) / 100) : '-' }}</td>
            <td>{{ asset.market_price ? (Math.floor(parseFloat(asset.market_price) * 10000) / 10000).toLocaleString('en-US', {maximumFractionDigits: 4}) : '-' }}</td>
            <td>{{ asset.variation === '-' ? `${asset.variation}` : `${parseFloat(asset.variation)}%` }}</td>
            <td>{{ asset.evaluation ? (Math.floor(parseFloat(asset.evaluation) * 100) / 100).toLocaleString('en-US', {maximumFractionDigits: 2}) : '-' }}</td>
          </tr>

          <tr v-for="(asset, key) in assets.assets" :key="key" class="row" v-if="asset.type === 'future-cash'">
            <td>
              <div class="symbol cursor-pointer symbol-future-cash flex" :class="[`symbol-${colorSymbol}`]" @click="openCorporateDetails(asset.type, key)">
                <img class="icon" src="@/assets/img/icons/click.svg" alt="click">

                {{ asset.corporate_event.event_type === 'dividend_cash' ? $t('deposit_withdrawal.report.dividend') :
                $t('deposit_withdrawal.portfolio.corporate_events.cupon') }} {{asset.symbol}}
              </div>
            </td>

            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>{{ asset.evaluation ? (Math.floor(parseFloat(asset.evaluation)*100)/100).toLocaleString('en-US', {maximumFractionDigits: 2}) : '-' }}</td>
          </tr>

          <tr v-for="(asset, key) in assets.assets" :key="key" class="row" v-if="asset.type === 'cash'">
            <td>
              <div class="symbol" :class="[`symbol-${colorSymbol}`]">
                CASH {{getCurrency}}
              </div>
            </td>

            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>-</td>
            <td>{{ asset.evaluation ? (Math.floor(parseFloat(asset.evaluation)*100)/100).toLocaleString('en-US', {maximumFractionDigits: 2}) : '-' }}</td>
          </tr>
        </tbody>
      </table>
    </Transition>

    <PopUpModal
      v-if="corporateEvent && showCorporateEvDetails"
      :modal-fit-content="true"
      :modal-opened="showCorporateEvDetails"
      :modal-title="corporateEvent.issuer"
      @closeModal="closePopUp()">
      <div>
        <div class="mb-3">
          <span class="font-bold">{{ $t('deposit_withdrawal.portfolio.corporate_events.issuer') }}:</span>
          {{ corporateEvent.issuer }}
        </div>

        <div class="mb-3">
          <span class="font-bold">{{ $t('deposit_withdrawal.portfolio.corporate_events.event_type') }}:</span>
          {{ $t(`deposit_withdrawal.portfolio.corporate_events.${corporateEvent.event_type}`) }}
        </div>
        <div class="mb-3">
          <span class="font-bold">{{ $t('deposit_withdrawal.portfolio.corporate_events.cum_date') }}:</span>
          {{ corporateEvent.cum_date ? formatDate(corporateEvent.cum_date) : '-' }}
        </div>

        <div class="mb-3">
          <span class="font-bold">{{ $t('deposit_withdrawal.portfolio.corporate_events.ex_date') }}:</span>
          {{ corporateEvent.ex_date ? formatDate(corporateEvent.ex_date) : '-' }}
        </div>

        <div class="mb-3">
          <span class="font-bold">{{ $t('deposit_withdrawal.portfolio.corporate_events.record_date') }}:</span>
          {{ corporateEvent.record_date ? formatDate(corporateEvent.record_date) : '-' }}
        </div>

        <div class="mb-3">
          <span class="font-bold">{{ $t('deposit_withdrawal.portfolio.corporate_events.pay_date') }}:</span>
          {{ corporateEvent.pay_date ? formatDate(corporateEvent.pay_date) : '-'}}
        </div>

        <div class="mb-3" v-if="corporateEvent.event_type === 'dividend_cash'">
          <span class="font-bold">{{ $t('deposit_withdrawal.portfolio.corporate_events.dividend_cash') }}:</span>
          {{ corporateEvent.amount ?
          parseFloat(corporateEvent.amount).toLocaleString('en-US', {maximumFractionDigits: 3}) : '-' }}
        </div>

        <div class="mb-3" v-else-if="corporateEvent.event_type === 'cupon'">
          <span class="font-bold">{{ $t('deposit_withdrawal.portfolio.corporate_events.amount') }}:</span>
          {{ corporateEvent.amount ?
          parseFloat(corporateEvent.amount).toLocaleString('en-US', {maximumFractionDigits: 3}) : '-' }}
        </div>

        <div class="mb-3" v-else>
          <span class="font-bold">{{ $t('deposit_withdrawal.portfolio.corporate_events.increase_factor') }}:</span>
          {{ corporateEvent.increase_factor ?
          parseFloat(corporateEvent.increase_factor).toLocaleString('en-US', {maximumFractionDigits: 3}) : '-' }}
        </div>

        <div class="mb-3">
          <span class="font-bold">{{ $t('deposit_withdrawal.portfolio.corporate_events.remarks') }}:</span>
          {{ corporateEvent.remarks }}
        </div>
      </div>
    </PopUpModal>

    <div class="total-assets cursor-pointer" @click="toggleTable">
      <div class="left-side " >
        <div class="yellow-bubble-arrow">
          <img :src="require(`@/assets/img/icons/arrow-white-${openedTable ? 'up' : 'down'}.svg`)">
        </div>

        <div class="total-title">
          <div class="instrument-name">{{ `${ $t('deposit_withdrawal.portfolio.total') } ${ instrumentName }` }}:</div>
          <div class="date">{{getDate}}</div>
        </div>
      </div>

      <div class="total-value">
        {{ assets.total ? assets.total.toLocaleString('en-US', {maximumFractionDigits: 2}) : 0 }} {{ getCurrency }}
      </div>
    </div>
  </div>
</template>

<script>

import PopUpModal from '@/components/subcomponents/PopUpModal.vue'
import Button from '@/components/subcomponents/Button.vue'

export default {
  name: 'AssetsTableDesktop',
  components: {Button, PopUpModal},
  props: {
    colorSymbol: {
      type: String,
      default: 'green'
    },
    assets: {
      type: Object,
      default: () => {
        return {}
      }
    },
    instrumentName: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      openedTable: false,
      loading: true,
      tabsClasses: ['mb-4'],
      showCorporateEvDetails: false,
      corporateEvent: null
    }
  },
  computed: {
    getCurrency () {
      return this.assets.assets.length ? this.assets.assets[0].currency : ''
    },
    getDate () {
      if (this.assets.assets.length === 0) return ''
      return this.formatDate(this.assets.assets.reduce((maxDate, asset) => asset.date > maxDate ? asset.date : maxDate, this.assets.assets[0].date))
    }
  },
  methods: {

    closePopUp () {
      this.showCorporateEvDetails = false
      this.corporateEvent = null
    },

    openCorporateDetails (type, key) {
      this.showCorporateEvDetails = true
      this.corporateEvent = this.assets.assets[key].corporate_event
    },
    toggleTable () {
      this.openedTable = !this.openedTable
    },
    formatDate (oldDate) {
      let date = new Date(oldDate)

      return `${('0' + (date.getDate())).slice(-2)}/${('0' + (date.getMonth() + 1)).slice(-2)}/${date.getFullYear()}`
    }

  }
}

</script>
