export default {
  methods: {
    focusOut (password, repeatPassword) {
      this.validation.repeatPassword = ''
      this.validation.passwordMatch = password !== repeatPassword ? this.$t('general.validations.password_match') : ''
    },
    validatePassword (password) {
      let valid = true

      if (!this.password) {
        this.validation.password.push(this.$t('general.validations.password_validation'))
        valid = false
      } else {
        const containsUppercase = /[A-Z]/.test(password)
        const containsLowercase = /[a-z]/.test(password)
        if (!containsLowercase || !containsUppercase) {
          this.validation.password.push(this.$t('general.validations.password_characters_validation'))
          valid = false
        }

        const hasDiacritic = function (str) {
          const combiningDiacriticMarks = /[\u0300-\u036f\u1ab0-\u1aff\u1dc0-\u1dff\u20d0-\u20ff\ufe20-\ufe2f]/
          return combiningDiacriticMarks.test(str.normalize('NFD'))
        }

        if (hasDiacritic(password)) {
          this.validation.password.push(this.$t('general.validations.password_diacritic_validation'))
          valid = false
        }

        const containsNumber = /[0-9]/.test(password)
        if (!containsNumber) {
          this.validation.password.push(this.$t('general.validations.password_number_validation'))
          valid = false
        }
        const containsSpecial = new RegExp('(?=.*[@#$%^&+.,:;!=]).*$').test(password)
        if (!containsSpecial) {
          this.validation.password.push(this.$t('general.validations.password_symbol_validation'))
          valid = false
        }
        const isAppropriateLength = password.length >= 8 && password.length <= 20
        if (!isAppropriateLength) {
          this.validation.password.push(this.$t('general.validations.password_length_validation'))
          valid = false
        }

        if (password.includes(' ')) {
          this.validation.password.push(this.$t('general.validations.password_validation_alpha_dash'))
          valid = false
        }
      }
      if (!this.repeatPassword) {
        this.validation.repeatPassword = this.$t('general.validations.repeat_password_validation')
        valid = false
      }

      if (this.password && this.repeatPassword && this.password !== this.repeatPassword) {
        this.validation.passwordMatch = this.$t('general.validations.password_match')
        valid = false
      }

      return valid
    }
  }
}
