<template>
  <div class="personal-data">
    <general-layout>
      <template v-slot:title>
        <vue-title :title="$t('general.meta_titles.deposits')"></vue-title>
      </template>

      <template v-slot:tabs>
        <Tabs
          :show-id-title="false"
        />
      </template>

      <template v-slot:body>
        <div v-if="!hasAccessInPortal">
          <ContinueToOnboarding/>
        </div>

        <div v-if="hasAccessInPortal">
          <PulseLoader class="w-fit m-auto p-10 justify-center flex" v-if="loading"/>

          <div class="desktop-flex gap-8">

            <div class="deposits-card">
              <Card
                    v-if="!showMessage"
                    :w100="true" :no-title="true"
                    :card-classes="'margin-bottom-big mobile-card-portal'">
                <template v-slot:title>
                  <div>{{ $t("deposit_withdrawal.account_transfers.new_deposit") }}</div>
                </template>

                <template v-slot:body>
                  <div class="my-2" v-if="accountBalance">
                    {{ $t('deposit_withdrawal.account_transfers.available_sum') }}: {{ parseFloat(accountBalance).toLocaleString('en-US', {maximumFractionDigits: 2}) }} {{fromAccount ? fromAccount.currency : null}}
                  </div>

                  <div :class="{'desktop-flex': isDesktop}" class="gap-7 justify-between mb-5">
                    <div :class="[!isDesktop ? 'w-full' : 'w-1/2']">
                      <SelectDropDown
                        class="mb-5"
                        :id="'accountsFrom'"
                        :options="filterData('from')"
                        :label="$t('deposit_withdrawal.account_transfers.from')"
                        v-model="fromAccount"
                        @input="setSelectedAccount($event, 'from')"
                      />
                      <Message
                        v-if="validations.fromAccount"
                        :message="validations.fromAccount"
                        :type="'error'"
                        class="m-auto"
                      />
                    </div>
                    <div class="flex gap-7 w-1/4" v-if="isDesktop">
                      <InputText
                        :id="'accountsFromCurrency'"
                        :value="fromAccount ? fromAccount.currency : null"
                        :read-only="true"
                        :label="$t('deposit_withdrawal.account_transfers.currency')"
                      />
                    </div>
                    <div class="flex gap-7 w-1/4" v-if="isDesktop">
                      <InputText
                          v-if="isSaltEdgeCompatible"
                          :id="'amountValue'"
                          v-model="amount"
                          :label="$t('deposit_withdrawal.account_transfers.sum')"
                      />
                    </div>
                  </div>
                  <div class="flex gap-7 justify-between">
                    <div :class="[!isDesktop ? 'w-full' : 'w-1/2']">
                      <SelectDropDown
                          :id="'accountsTo'"
                          :options="filterData('to')"
                          :label="$t('deposit_withdrawal.account_transfers.to')"
                          v-model="toAccount"
                          @input="setSelectedAccount($event, 'to')"
                      />
                      <Message
                          v-if="validations.toAccount"
                          :message="validations.toAccount"
                          :type="'error'"
                          class="m-auto"
                      />
                      <div class="mt-6 flex" v-if="!isDesktop">
                        <div class="w-1/2 gap-2">
                          <div>
                            <InputText
                                :id="'accountsFromCurrency'"
                                :value="fromAccount ? fromAccount.currency : null"
                                :read-only="true"
                                :label="$t('deposit_withdrawal.account_transfers.currency')"
                            />
                          </div>
                          <div>
                            <InputText
                                v-if="isSaltEdgeCompatible"
                                :id="'amountValue'"
                                v-model="amount"
                                :label="$t('deposit_withdrawal.account_transfers.sum')"
                            />
                          </div>
                        </div>
                        <img v-if="!isDesktop" class=" w-1/2" src="@/assets/img/icons/money-transfer.svg" alt="money-transfer">
                      </div>
                      <p v-if="transactionalAccounts.length > 3" class="mt-4" v-html="$t('deposit_withdrawal.deposits.extra_accounts_message')"/>
                      <div class="flex gap-2">
                        <Button
                            class="mt-6"
                            :text="$t('general.general.display')"
                            :loading="loading"
                            @call="sendData"
                            :type="'btn-primary'"
                        />
                        <Button
                            v-if="isSaltEdgeCompatible"
                            class="mt-6"
                            :text="$t('deposit_withdrawal.deposits.deposit')"
                            :loading="loading"
                            @call="sendDepositRequest"
                            :type="'btn-primary'"
                        />
                      </div>
                      <Message
                          v-if="depositStatus.fail"
                          :message="depositStatus.fail"
                          :type="'error'"
                          class="m-auto"
                      />
                      <Message
                          v-if="depositStatus.success"
                          :message="depositStatus.success"
                          :type="'success'"
                          class="m-auto"
                      />
                      <a v-if="depositStatus.url" :href="depositStatus.url" target="_blank">
                        <Button
                            class="m-top-custom m-bottom-custom"
                            :text="$t('deposit_withdrawal.general.redirect')"
                            :type="'btn-secondary'"/>
                      </a>
                    </div>
                    <img v-if="isDesktop" class=" w-1/2" src="@/assets/img/icons/money-transfer.svg" alt="money-transfer">
                  </div>
                </template>
              </Card>

              <Card v-else class=" m-bottom-custom bank-account"
                    :card-classes="'mobile-card-portal'">
                <template v-slot:body>
                  <div>
                    <p class="font-bold">{{ $t('deposit_withdrawal.deposits.details') }}</p>
                    <br>
                    <p>{{ $t('deposit_withdrawal.deposits.message') }}</p>
                    <br>
                    <p>{{ $t('deposit_withdrawal.deposits.beneficiary') }}</p>
                    <br>
                    <p>{{ $t('deposit_withdrawal.deposits.iban') + ' ' + fromAccount.recommended_admin_bank_account.iban }}</p>
                    <br>
                    <p>{{ $t('deposit_withdrawal.deposits.pay_details') + ' ' + user.username + ( toAccount.currency !== fromAccount.currency ?  (' ' + toAccount.currency) : '' )}}</p>
                    <br>
                    <p v-if="usdTransfer" class="font-bold">{{ $t('deposit_withdrawal.deposits.usd_to_usd_transfer')}}</p>

                    <p v-if="differentCurrency" class="font-bold">{{ $t('deposit_withdrawal.general.different_currencies') }}</p>

                    <Button
                        class="m-top-custom m-bottom-custom"
                        :text="$t('general.general.back')"
                        @call="back"
                        :type="'btn-secondary'"
                    />
                  </div>
                </template>
              </Card>

              <PopUpModal
                class="h-fit"
                :small-padding="true"
                :modal-fit-content="true"
                :modal-opened="messagePopUp.length > 0"
                :closable-modal="false"
              >
                <div class="flex justify-center">
                  <TimerComponent :total-time="5"/>
                </div>

                <div v-html="messagePopUp"></div>
              </PopUpModal>
            </div>
            <Resources :class="[!isMobile ? 'w-1/2' : 'mt-8']" :links="resourcesLinks"/>
          </div>
        </div>
      </template>
    </general-layout>
  </div>
</template>

<script>
import GeneralLayout from '../../layout/GeneralLayout'
import Tabs from '../../layout/Tabs'
import Button from '../../subcomponents/Button'
import BankAccountsService from '../../../services/deposit-withdrawal/bankAccountsService'
import AccountTransfersService from '../../../services/deposit-withdrawal/accountTransfersService'
import DocumentService from '../../../services/documents/documentsService'
import {mapActions, mapGetters} from 'vuex'

import InputText from '../../subcomponents/InputText'
import Card from '../../subcomponents/Card'
import IconBox from '../../subcomponents/IconBox.vue'
import ContinueToOnboarding from '../ContinueToOnboarding'
import Message from '../../subcomponents/Message'
import SelectDropDown from '../../subcomponents/SelectDropDown'
import Resources from '../../subcomponents/Resources'
import DepositsService from '@/services/deposit-withdrawal/depositsService'
import PopUpModal from '@/components/subcomponents/PopUpModal.vue'
import TimerComponent from '@/components/subcomponents/TimerComponent.vue'
export default {
  name: 'Deposits',
  components: { TimerComponent, PopUpModal, GeneralLayout, Tabs, Button, InputText, Card, IconBox, ContinueToOnboarding, Message, SelectDropDown, Resources },
  data () {
    return {
      loading: true,
      bankAccounts: null,
      accountsList: [],
      openedDeposit: 0,
      resourcesLinks: [
        { label: this.$t('useful_links.deposits.deposits_guide'), value: '/ghid/ghid-alimentare-cont/' },
        { label: this.$t('useful_links.deposits.deposit_how_to'), value: '/faq/vreau-sa-alimentez/' },
        { label: this.$t('useful_links.deposits.deposit_what_accounts'), value: '/faq/in-ce-conturi-investimental-pot-alimenta/' },
        { label: this.$t('useful_links.deposits.deposit_money_time_to_arrive'), value: '/faq/in-cat-timp-ajung-banii-in-contul-de-tranzactionare/' }
      ],
      transactionalAccounts: [],
      accountBalance: '',
      fromAccount: '',
      toAccount: '',
      amount: '',
      validations: {
        toAccount: '',
        fromAccount: ''
      },
      depositStatus: {
        success: '',
        fail: '',
        url: ''
      },
      showMessage: false,
      messagePopUp: '',
      seconds: 5
    }
  },
  async created () {
    if (this.isLogged) {
      await this.setData()
    }
  },
  watch: {
    fromAccount () {
      this.validations.fromAccount = ''
    },
    toAccount () {
      this.validations.toAccount = ''
    }
  },
  mounted () {
    this.$nextTick(async () => {
      const sleep = (ms) => {
        return new Promise(resolve => setTimeout(resolve, ms))
      }

      await sleep(1000)

      await this.setIntroPing((Math.random() + 1).toString(36).substring(7))
    })
  },
  methods: {
    ...mapActions({
      setIntroPing: 'guide/setIntroPing'
    }),
    async setData () {
      let bankAccounts = await BankAccountsService.getBankAccounts()

      this.bankAccounts = bankAccounts.data.data.bankAccounts
      this.accountsList = this.bankAccounts.map(account => account.iban)

      let transactionalAccounts = await AccountTransfersService.getAccounts()

      this.transactionalAccounts = transactionalAccounts.data.data

      this.transactionalAccounts = this.transactionalAccounts
        .filter(account => (account.source === 'arenaxt' && account.status.toLowerCase() !== 'closed') || account.source !== 'arenaxt')

      this.transactionalAccounts
        .forEach(account => {
          account.name = (account.source === 'arenaxt' ? 'BVB' : 'GLOBAL') + ' ' +
          account.alias + ' ' + account.currency
        })

      this.loading = false
    },

    verifyBankAccountsStatus () {
      return this.bankAccounts.filter(account => account.status === 'rejected').length === this.bankAccounts.length
    },

    async sendDepositRequest () {
      if (this.verifyBankAccountsStatus() && typeof this.bankAccountsRejectedDepositModalMessage === 'object') {
        this.messagePopUp = this.bankAccountsRejectedDepositModalMessage.body
        setTimeout(() => {
          this.messagePopUp = ''
        }, 5000)
        return
      }
      if (this.validate()) {
        this.loading = true
        try {
          await DepositsService.sendDeposit({
            from: this.fromAccount.iban,
            to: this.toAccount.id,
            amount: this.amount
          }).then((response) => {
            if (response.status === 200 && response.data.data.connect_url) {
              this.depositStatus.url = response.data.data.connect_url
              this.toAccount = ''
              this.fromAccount = ''
              this.amount = ''

              if (this.screenDevice === this.$screenDeviceConstants.DESKTOP && typeof this.saltedgeDepositModalMessage === 'object') {
                this.messagePopUp = this.saltedgeDepositModalMessage.body
                setTimeout(() => {
                  window.open(response.data.data.connect_url, '_blank')
                  this.messagePopUp = ''
                }, 5000)
              } else {
                window.open(response.data.data.connect_url, '_blank')
                this.depositStatus.success = this.$i18n.t('deposit_withdrawal.deposits.success')
              }

              setTimeout(() => {
                this.depositStatus.success = ''
                this.depositStatus.url = ''
              }, 30000)
            } else {
              this.depositStatus.fail = this.$i18n.t('deposit_withdrawal.deposits.failed')
              setTimeout(() => {
                this.depositStatus.fail = ''
              }, 5000)
            }
          }).catch(() => {
            this.depositStatus.fail = this.$i18n.t('deposit_withdrawal.deposits.failed')
            setTimeout(() => {
              this.depositStatus.fail = ''
            }, 5000)
          })
        } finally {
          this.loading = false
        }
      }
    },
    async download (doc) {
      await DocumentService.downloadDocument(doc)
    },
    async sendData () {
      if (this.verifyBankAccountsStatus()) {
        this.messagePopUp = this.bankAccountsRejectedDepositModalMessage.body
        setTimeout(() => {
          this.messagePopUp = ''
        }, 5000)
        return
      }
      if (this.validate()) {
        this.loading = true
        try {
          this.showMessage = true
        } finally {
          this.loading = false
        }
      }
    },
    filterData (filter) {
      if (filter === 'to') {
        this.transactionalAccounts.forEach(account => {
          account.label = account.name
        })

        return this.transactionalAccounts.filter(account => account.is_main === 1)
      }

      if (!this.bankAccounts) {
        return this.accountsList
      }

      this.bankAccounts.forEach(account => {
        account.label = account.iban
      })

      return this.bankAccounts.filter(account => account !== this.fromAccount.iban)
    },
    setSelectedAccount (selectedAccount, accountType) {
      if (accountType === 'from') {
        this.fromAccount = selectedAccount ? this.bankAccounts.find(account => account.iban === selectedAccount.iban) : null
      } else {
        this.toAccount = selectedAccount ? this.transactionalAccounts.find(account => account.code === selectedAccount.code) : null
      }
    },
    validate () {
      let valid = true

      if (!this.toAccount) {
        this.validations.toAccount = this.$i18n.t('deposit_withdrawal.account_transfers.validation_account')

        valid = false
      }

      if (!this.fromAccount) {
        this.validations.fromAccount = this.$i18n.t('deposit_withdrawal.account_transfers.validation_account')

        valid = false
      }

      return valid
    },
    back () {
      this.fromAccount = ''
      this.toAccount = ''
      this.showMessage = false
    }
  },
  computed: {
    ...mapGetters({
      user: 'user/user',
      activeSaltedge: 'user/activeSaltedge',
      userIsStaff: 'user/userIsStaff',
      screenDevice: 'layout/screenDevice',
      hasAccessInPortal: 'user/hasAccessInPortal',
      isLogged: 'user/isLogged',
      saltedgeDepositModalMessage: 'user/saltedgeDepositModalMessage',
      bankAccountsRejectedDepositModalMessage: 'user/bankAccountsRejectedDepositModalMessage'

    }),
    differentCurrency () {
      return this.fromAccount.currency !== this.toAccount.currency
    },
    usdTransfer () {
      return this.fromAccount.currency === 'USD'
    },
    isMobile () {
      return this.screenDevice === this.$screenDeviceConstants.MOBILE
    },
    isTablet () {
      return this.screenDevice === this.$screenDeviceConstants.TABLET
    },
    isDesktop () {
      return this.screenDevice === this.$screenDeviceConstants.DESKTOP
    },
    isSaltEdgeCompatible () {
      const hasSaltEdge = this.fromAccount.saltedge
      return !this.activeSaltedge ? (hasSaltEdge && this.userIsStaff) : hasSaltEdge
    }
  }
}
</script>

<style scoped></style>
