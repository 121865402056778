<template>
  <div>
    <general-layout>
      <template v-slot:title>
        <vue-title :title="$t('general.meta_titles.client_documents')"/>
      </template>

      <template v-slot:tabs>
        <Tabs
          :show-id-title="false"
          :tabs-classes="tabsClasses"
        />
      </template>

      <template v-slot:body>
        <div v-if="!hasAccessInPortal">
          <div class="desktop-flex gap-8 mt-8">
            <ContinueToOnboarding/>

            <div v-if="!isMobile">
              <Card :card-classes="'w-full h-full' + (isMobile ? ' mb-8' : '')">
                <template v-slot:body>
                  <div>
                    <p v-html="$t('guides.account_and_contact_data.account_p2')"/>
                    <br>
                    <p v-html="$t('guides.account_and_contact_data.account_p1')"/>
                  </div>
                </template>
              </Card>
            </div>
          </div>
        </div>

        <div class="mt-8" v-else>
          <Breadcrumb
            v-if="!isMobile"
            :items="[{label: $t('documents.breadcrumb.document')},{label: $t('documents.breadcrumb.notification_documents'),routeName: 'client-documents'}]"
          ></Breadcrumb>

          <div class="client-documents" v-if="!loading">
            <Transition appear name="fade" v-if="documents">
              <div class="documents-data">
                <div
                  class="info document"
                  v-for="(document, counter) in documents" :key="counter">
                  <DocumentCard :document="document" :counter="counter"/>
                </div>
              </div>
            </Transition>
            <Transition appear name="fade" v-else>
              <div class="documents-data">
                {{ $t('documents.general.notifications_documents_missing') }}
              </div>
            </Transition>
          </div>
          <div v-if="!isMobile" class="my-12 bg-white h-px"></div>
          <div v-if="isMobile">
            <div v-if="!isMobile" class="mt-8">
              <Card class="w-full" :card-classes="'mobile-card-resources padding-small'">
                <template v-slot:body>
                  <div>
                    <p v-html="$t('guides.notification_documents.p1')"/>
                  </div>
                </template>
              </Card>
            </div>
            <div :class="[isMobile ? 'mt-8' : 'mt-2']">
              <Resources :links="resourcesLinks"/>
            </div>
          </div>
        </div>
      </template>
    </general-layout>
  </div>
</template>

<script>
import GeneralLayout from '../../layout/GeneralLayout'
import Tabs from '../../layout/Tabs'
import { mapGetters } from 'vuex'
import Card from '../../subcomponents/Card'
import DocumentsService from '../../../services/documents/documentsService'
import DocumentCard from './DocumentCard.vue'
import Breadcrumb from '../../subcomponents/Breadcrumb.vue'
import Resources from '../../subcomponents/Resources.vue'
import ContinueToOnboarding from '../ContinueToOnboarding.vue'

export default {
  name: 'NotificationDocuments',
  components: { ContinueToOnboarding, Resources, Breadcrumb, DocumentCard, GeneralLayout, Tabs, Card },
  data () {
    return {
      resourcesLinks: [
        { label: this.$t('useful_links.profile_documents.category'), value: '/ghid/ghid-incadrare-in-categoria-client-profesional-la-cerere/' },
        { label: this.$t('useful_links.profile_documents.professional'), value: '/wp-content/uploads/2023/07/2023-03-Cerere-incadrare-categoria-client-profesional.pdf' }
      ],
      tabsClasses: [],
      documents: null,
      loading: true
    }
  },
  async created () {
    if (this.isLogged) {
      await this.setData()
    }
  },
  methods: {
    saveData () {
      this.loading = true
      this.setData()
    },
    async setData () {
      let allDbDocuments = await DocumentsService.getUserNotificationDocuments()
      this.documents = allDbDocuments.data.data
      this.loading = false
    },
    async download (doc) {
      await DocumentsService.downloadDocument(doc)
    }
  },
  computed: {
    ...mapGetters({
      screenDevice: 'layout/screenDevice',
      isLogged: 'user/isLogged',
      hasAccessInPortal: 'user/hasAccessInPortal'
    }),
    isMobile () {
      return this.screenDevice === this.$screenDeviceConstants.MOBILE
    }
  }

}
</script>

<style scoped></style>
