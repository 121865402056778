import {store} from '../index'

const getDefaultState = () => {
  return {
    updatedPersonalData: {
      step_country: {},
      step_phone: {},
      step_identity_card: {},
      step_tax_residence: {},
      step_agreements: {},
      step_questionnaire: {}
    },
    temporaryStepQuestionnaire: {},
    step: 'updateDomicile',
    isUpdating: false,
    steps: ['updateDomicile', 'updatePhoneNumber', 'verifyPhoneNumber', 'updateIdentityCard', 'updateResidence', 'updateQuestionnaire', 'updateDocuments']
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    resetState (state) {
      Object.assign(state, getDefaultState())
    },
    setUpdatedData (state, data) {
      state.updatedPersonalData = data
    },
    setUpdatedDataStepAgreements (state, agreements) {
      state.updatedPersonalData.step_agreements.agreements = agreements
    },
    setStep (state, data) {
      state.step = data
    },
    setTemporaryStepQuestionnaire (state, temporaryData) {
      state.temporaryStepQuestionnaire[Object.keys(temporaryData)[0]] = Object.values(temporaryData)[0]
    },
    setIsUpdating (state, isUpdating) {
      state.isUpdating = isUpdating
    }
  },
  getters: {
    getUpdatedData ({updatedPersonalData}) {
      if (store.getters['user/isLogged']) {
        return updatedPersonalData
      }
    },
    getStep ({step}) {
      return step
    },
    getTemporaryStepQuestionnaire ({temporaryStepQuestionnaire}) {
      return temporaryStepQuestionnaire
    },
    getAllSteps ({steps}) {
      return steps
    }
  },
  actions: {
    resetState (ctx) {
      ctx.commit('resetState')
    },
    setUpdatedData (ctx, updatedPersonalData) {
      ctx.commit('setUpdatedData', updatedPersonalData)
    },
    setStep (ctx, step) {
      ctx.commit('setStep', step)
    },
    setUpdatedDataStepAgreements (ctx, agreements) {
      ctx.commit('setUpdatedDataStepAgreements', agreements)
    },
    setTemporaryStepQuestionnaire (ctx, temporaryData) {
      ctx.commit('setTemporaryStepQuestionnaire', temporaryData)
    },
    setIsUpdating (ctx, isUpdating) {
      ctx.commit('setIsUpdating', isUpdating)
    }
  }
}
