<template>
  <div class="assets-mobile">

    <div class="total-assets cursor-pointer mb-4" @click="toggleTable">
      <div class="left-side cursor-pointer">
        <div class="instrument-name">{{ name }}:</div>
      </div>
      <div class="flex">
        <div class="total-value mr-4">
          {{ totalTax }}
        </div>
        <div class="yellow-bubble-arrow">
          <img :src="require(`@/assets/img/icons/arrow-white-${openedTable ? 'up' : 'down'}.svg`)" alt="arrow">
        </div>
      </div>

    </div>
    <Transition name="slide-fade">
      <div class="flex bg-white pt-5 pl-5 pb-8 gap-5 table-assets"  v-if="openedTable">
        <div>
          <div class="header-table flex flex-col">
            <div class="symbol">{{ $t('deposit_withdrawal.portfolio.symbol') }}</div>
            <div>{{ $t('deposit_withdrawal.fiscal_obligations.date') }}</div>
            <div>{{ $t('deposit_withdrawal.portfolio.quantity') }}</div>
            <div>{{ $t('deposit_withdrawal.fiscal_obligations.price') + ' ' + getCurrency }}</div>
            <div>{{ $t('deposit_withdrawal.portfolio.medium_cost') + ' ' + getCurrency }}</div>
            <div>{{ $t('deposit_withdrawal.fiscal_obligations.tax') + ' ' + getCurrency }}</div>
            <div>{{ $t('deposit_withdrawal.fiscal_obligations.anaf_tax')}}</div>
          </div>
        </div>
        <div class="overflow-x-scroll flex gap-5">
          <div class=" body-table flex flex-col mr-4" v-for="(asset, key) in assets" :key="key">
            <div class="symbol" :class="[`symbol-${colorSymbol}`]">
              {{asset.symbol ? asset.symbol : '-'}}
            </div>
            <div>{{ asset.date ? formatDate(asset.date) : '-' }}</div>
            <div>{{ asset.quantity ?  formattedQuantity(asset) : '-' }}</div>
            <div>{{ asset.price ? asset.price : '-' }}</div>
            <div>{{ asset.medium_cost ? asset.medium_cost : '-' }}</div>
            <div>{{ getTax(asset) }}</div>
            <div>{{ asset.tax_anaf ? parseFloat(asset.tax_anaf).toLocaleString('en-US', {maximumFractionDigits: 0}) : '-' }}</div>
          </div>
        </div>

      </div>

    </Transition>
  </div>
</template>

<script>

export default {
  name: 'FiscalObligationsAssetsTableMobile',
  props: {
    colorSymbol: {
      type: String,
      default: 'green'
    },
    assets: {
      type: Array,
      default: () => {
        return []
      }
    },
    expanded: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      openedTable: false,
      loading: true,
      tabsClasses: ['mb-4']
    }
  },
  watch: {
    expanded () {
      this.openedTable = this.expanded
    }
  },
  computed: {
    getCurrency () {
      return this.assets[0].currency
    },
    getDate () {
      return this.formatDate(this.assets[0].date)
    },
    totalTax () {
      let total = 0
      this.assets.forEach(asset => {
        total += parseFloat(asset.tax_anaf)
      })

      return parseFloat(total).toLocaleString('en-US', {maximumFractionDigits: 0})
    }
  },
  methods: {
    toggleTable () {
      this.openedTable = !this.openedTable
    },
    formatDate (oldDate) {
      let date = new Date(oldDate)
      return `${('0' + (date.getDate())).slice(-2)}/${('0' + (date.getMonth() + 1)).slice(-2)}/${date.getFullYear()}`
    },
    getTax (asset) {
      let tax = asset.tax_percentage ? parseFloat(asset.tax_percentage).toLocaleString('en-US', {maximumFractionDigits: 0}) + '% / ' : '-'
      return tax + asset.tax_value ? asset.tax_value : '-'
    },
    formattedQuantity (asset) {
      return parseFloat(asset.quantity).toLocaleString(
        'en-US',
        {maximumFractionDigits: this.getCurrency === 'USD' ? 3 : 0}
      )
    }
  }
}

</script>
