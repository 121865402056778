<template>
  <div>
    <pulse-loader v-if="!loaded" class="m-auto w-fit" />
    <div v-else>
      <div class="form-group mb-6 flex items-end" >
        <CountryCodesPhone
          :pj-onboarding="pjOnboarding"
          :countries-available="countriesData"
          class="mr-7 w-1/3"
          v-model="selectedCode"
          :disabled-input="!canEditPhoneNumber && !pjOnboarding"
          @setValueOfCode="setSelectedCode">
        </CountryCodesPhone>

        <InputText
          class="w-full mr-3"
          :id="'phoneNumber'"
          v-model="phoneNumber"
          :type-input="'text'"
          :disabled-input="!canEditPhoneNumber && !pjOnboarding"
          :label="$t('general.general.phone_number')">
        </InputText>
        <img v-if="!pjOnboarding" class="mb-3 cursor-pointer w-6 max-w-none" alt="edit" src="~@/assets/img/icons/edit-icon.svg" @click="toggleCanEditPhoneNumber">
      </div>

      <Message
        v-if="validations.phoneNumber"
        :message="validations.phoneNumber"
        :type="'error'"
        class="mb-5"
      />

      <Message
        v-if="validations.dialCode"
        :message="validations.dialCode"
        :type="'error'"
        class="mb-5"
      />

      <div class="flex justify-between items-center mt-10">
        <Button :icon="'icons/arrow_back_green'"
                :icon-position="'left'"
                :text="$t('general.general.back')"
                :type="'btn btn-secondary padding-big'"
                @call="resetStep"/>

        <Button :icon="'icons/check-white'"
                :icon-position="'right'"
                :text="$t('general.general.confirm')"
                :type="'btn btn-primary padding-big'"
                @call="next"/>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '../../subcomponents/Button.vue'
import InputText from '../../subcomponents/InputText.vue'
import {mapGetters} from 'vuex'
import { countries } from 'country-flags-svg'
import codes from 'country-calling-code'
import ApiService from '../../../services/apiService'
import SelectDropDownFav from '../../subcomponents/SelectDropDownFav.vue'
import CountryCodesPhone from '../../subcomponents/CountryCodesPhone.vue'
import Message from '../../subcomponents/Message.vue'

export default {
  name: 'PhoneNumber',
  components: {
    Message,
    Button,
    InputText,
    SelectDropDownFav,
    CountryCodesPhone
  },
  computed: {
    ...mapGetters({
      getUserData: 'user/user',
      updatedData: 'updatePersonalData/getUpdatedData'
    })
  },
  props: {
    pjOnboarding: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      loaded: false,
      phoneNumber: '',
      canEditPhoneNumber: false,
      flags: countries,
      codes: codes,
      countriesData: [],
      selectedCode: '',
      validations: {
        dialCode: '',
        phoneNumber: ''
      },
      countriesOrderedByDialCode: []
    }
  },
  async created () {
    await this.countriesCodeFlagsArray()
    if (!this.pjOnboarding) {
      this.phoneNumber = this.updatedData.step_phone.phone_number ? this.updatedData.step_phone.phone_number : this.getUserData.phone
      this.getDialCodeAndNumber()
    }
    this.loaded = true
  },
  methods: {
    getDialCodeAndNumber () {
      let domicileCode = this.getUserData.domicile.code
      let domicileCountry = this.countriesData.find(country => country.code === domicileCode)
      let domicileDialCode = domicileCountry.dial_code

      if (this.phoneNumber.startsWith(domicileDialCode)) {
        this.phoneNumber = this.phoneNumber.replace(domicileDialCode, '')
        this.selectedCode = domicileCountry
      } else {
        this.countriesOrderedByDialCode = this.countriesOrderedByDialCode.sort(this.dynamicSortCountries('dial_code'))

        this.countriesOrderedByDialCode.forEach(country => {
          if (this.phoneNumber.startsWith(country.dial_code)) {
            this.phoneNumber = this.phoneNumber.replace(country.dial_code, '')
            this.selectedCode = country
          }
        })
      }
    },

    dynamicSortCountries (property) {
      let sortOrder = 1

      if (property[0] === '-') {
        sortOrder = -1
        property = property.substr(1)
      }

      return function (country1, country2) {
        let result = (country1[property] < country2[property]) ? -1 : (country1[property] > country2[property]) ? 1 : 0
        return result * sortOrder
      }
    },

    setSelectedCode (code) {
      this.selectedCode = code
    },

    async countriesCodeFlagsArray () {
      let countries = {}
      await ApiService.withAuth().get('/api/countries').then(response => {
        countries = response.data.data
      })
      countries.map((country) => {
        let flag = this.flags.filter(flag => {
          return flag.iso2 === country.code
        })
        let code = this.codes.filter(callingCode => {
          return callingCode.isoCode2 === country.code
        })

        if (code[0]) {
          code[0].countryCodes.forEach((callingCode, index) => {
            this.countriesData.push({
              ...country,
              flag: flag[0] ? flag[0].flag : '',
              dial_code: '+' + code[0].countryCodes[index]
            })
          })
        }
      })

      this.countriesOrderedByDialCode = JSON.parse(JSON.stringify(this.countriesData))
    },

    toggleCanEditPhoneNumber () {
      this.canEditPhoneNumber = !this.canEditPhoneNumber
    },

    validate () {
      if (!this.selectedCode) {
        this.validations.dialCode = this.$i18n.t('general.validations.dial_code')
        return false
      }
      if (!this.phoneNumber) {
        this.validations.phoneNumber = this.$i18n.t('general.validations.phone_number')
        return false
      }
      return true
    },

    next () {
      if (!this.validate()) {
        return
      }

      let phoneNumber = this.selectedCode.dial_code + this.phoneNumber
      let updatedData = this.updatedData

      if (this.pjOnboarding) {
        this.$emit('validate', phoneNumber)
        return
      }

      updatedData.step_phone.phone_number = (phoneNumber !== this.getUserData.phone) ? phoneNumber : ''
      if (updatedData.step_phone.phone_number) {
        this.generateVerificationCode(updatedData.step_phone.phone_number)
      }

      let nextStep = (phoneNumber !== this.getUserData.phone) ? 'verifyPhoneNumber' : 'updateIdentityCard'
      this.$emit('next', {updatedData, nextStep})
    },

    generateVerificationCode (phone) {
      ApiService.withAuth().post('/api/user/update-phone/generate-code', {
        phone: phone
      })
    },

    async resetStep () {
      if (this.pjOnboarding) {
        this.$emit('back')
      } else {
        this.$emit('prev', 'updateDomicile')
      }
    }
  },
  watch: {
    phoneNumber: {
      handler (newPhoneNumber) {
        this.$emit('phoneInput')
        if (newPhoneNumber) {
          this.validations.phoneNumber = ''
        }
      },
      deep: true
    },
    selectedCode: {
      handler (newSelectedCode) {
        if (newSelectedCode) {
          this.validations.dialCode = ''
        }
      },
      deep: true
    }
  }
}
</script>

<style scoped>

</style>
