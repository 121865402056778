export default {
  general: {
    beta: 'BETA',
    email_address: 'Email address',
    password: 'Password',
    repeat_password: 'Repeat password',
    continue: 'Continue',
    confirm: 'Confirm',
    click_here: 'Click here',
    delete: 'Delete',
    send: 'Send',
    complete: 'Complete',
    upload_files: 'Upload files',
    upload_file: 'Upload file',
    file_types: 'PDF, PNG or JPG',
    edit: 'Edit',
    cancel: 'Cancel',
    created: 'Created',
    username: 'User',
    phone_number: 'Phone number',
    update: 'Update',
    save: 'Save',
    code: 'Code',
    enabled: 'Enabled',
    disabled: 'Disabled',
    password_rules: 'Between 8-20 characters, at least one capital letter, at least one number, at least one symbol ( @#$%^&+.,:;!= )',
    all: 'All',
    back: 'Back',
    reset: 'Reset',
    password_confirmation_button: 'Confirm password',
    display: 'Display',
    year: 'Year',
    month: 'Month',
    january: 'January',
    february: 'February',
    march: 'March',
    april: 'April',
    may: 'May',
    june: 'June',
    july: 'July',
    august: 'August',
    september: 'September',
    october: 'October',
    november: 'November',
    december: 'December',
    no_options: 'Sorry, no matching options.',
    others: 'Others',
    ok: 'Ok',
    new_user_warn: 'În urma răspunsurilor date de tine în procesul de onboarding, contul tău e în curs de validare manuală. Te ținem la curent pe email și promitem că nu va dura mult.',
    new_company_warn: 'Pentru a putea accesa platforma de tranzacționare, mai e nevoie de un pas: te rugăm să încarci documentele necesare pentru finalizarea procesului de onboarding. Le găsești {openTag}AICI{closeTag}.',
    upload: 'Upload',
    required: '* Required fields',
    next_step: 'Go further',
    attention: 'Attention !',
    loose_updates: 'Unsaved changes will be undone and cannot be recovered.<span class="font-bold">Agree?</span>',
    yes_undo: 'Yes, undo',
    no_thinking: 'No, I\'m thinking',
    agree: 'I agree',
    close: 'Close',
    how_did_you_hear: 'How did you hear about us? (Optional)'
  },
  validations: {
    password_validation: 'Please enter a password',
    password_validation_alpha_dash: 'Password should contain only alphanumeric and punctuation characters.',
    password_characters_validation: 'The password must contain at least one uppercase and one lowercase letter.',
    password_diacritic_validation: 'The password must not contain diacritics.',
    password_number_validation: 'The password must contain at least one number.',
    password_symbol_validation: 'The password must contain at least one symbol.</br>( @ # $ % ^ & + . , : ; ! = )',
    password_length_validation: 'The password must be at between 8 and 20 characters',
    repeat_password_validation: 'Please repeat the password',
    password_match: 'Password does not match',
    email_validation: 'Please enter an email',
    email_format_validation: 'Please enter a valid email',
    iban_validation: 'Invalid IBAN',
    iban_duplicate: 'This IBAN is already associated with an account',
    bank_statement_validation: 'Please upload an account statement',
    bank_statement_validation_type: 'The file must be in PDF, DOC, DOCX, JPG, JPEG or PNG format',
    currency_validation: 'Please choose the currency',
    checkbox_validation: 'Please complete the field above',
    password_confirmation: 'Please confirm the password',
    incorrect_password: 'The password is incorrect!',
    internal_error: 'An internal error has occurred, please contact us',
    choose_answer: 'Va rugam selectati un raspuns!',
    code: 'Please scan the QR code and confirm the code!',
    complete_field: 'Va rugam completati acest camp!',
    address_field: 'Please complete the address field!',
    address_partial_error: 'Please complete',
    address_fields: {
      country: 'country',
      county: 'county',
      locality: 'locality',
      street: 'street',
      number: 'number',
      zip_code: 'zip code'
    },
    enter_address_street: 'Please complete the street field!',
    enter_address_country: 'Please complete the country field!',
    enter_address_locality: 'Please complete the locality field!',
    enter_first_name: 'Please complete the first name field!',
    enter_last_name: 'Please complete the last name field!',
    enter_identity_card_file: 'Please upload your identity card!',
    success_upload_document: 'Document uploaded successfully!',
    email_already_exists: 'Adresa de e-mail este deja asociată unui cont. Pentru a continua deschiderea de cont folosind această adresă de e-mail trebuie să accesați <a target="_blank" href="/login" class="underline cursor-pointer">Login</a> și să introduceți adresa de e-mail și parola setată anterior',
    documents_server_error: 'Your request could not be processed. Please contact us <a target="_blank" href="https://investimental.websenior.ro/contact/">https://investimental.websenior.ro/contact/</a>',
    release_date: 'Please select the release date!',
    release_date_future: 'Release date can not be in the future!',
    expiration_date_after_release_date: 'The expiration date must be at least one day after the release date!',
    expiration_date: 'Please select the expiration date!',
    expiration_date_past: 'Expiration date can not be in the past!',
    upload_id_card: 'Please upload an identity card copy!',
    enter_series: 'Please complete the series field!',
    enter_number: 'Please complete the number field!',
    phone_number: 'Please enter a phone number!',
    dial_code: 'Please select a dial code!',
    enter_pin: 'Please complete the PIN field!',
    error_upload_document: 'The document could not be uploaded. Please try again later or contact us!'
  },
  questionnaire: {
    title: 'Complete the registration questionnaire',
    back: 'Back',
    finish: 'Finish',
    next: 'Next',
    continue: 'Continue',
    intro: 'Este important să răspunzi sincer și complet la toate solicitările de informații în cadrul Chestionarului,\n' +
      'pentru a permite Investimental să evalueze corect toleranța ta la risc, capacitatea de a suporta pierderi și,\n' +
      'implicit, caracterul corespunzător/adecvat al produselor și serviciilor pe care Investimental ți le poate oferi.'
  },
  menu: {
    portal: 'Portal',
    my_account: 'My profile',
    deposit: 'Deposit',
    withdraw: 'Withdraw',
    transfers: 'Investitions',
    money: 'Money',
    documents: 'Documents',
    security: 'Security',
    notifications: 'Notifications',
    messages: 'Messages',
    help: 'Help',
    log_out: 'Log out',
    visit_investimental: 'Visit Investimental',
    dx: 'DX',
    arena_xt: 'BVB',
    personalised_documents: 'Contractuals',
    bank_accounts: 'IBAN codes',
    deposits: 'Deposits',
    withdrawals: 'Withdrawals',
    password_reset: 'Portal password reset',
    mfa_activation: '2FA activation',
    settings: 'Account and contact data',
    personal_data: 'Personal data',
    fiscal_data: 'Fiscal data',
    configurations: 'Configurations',
    clients_documents: 'Client',
    instruments_transfer: 'Instruments transfer',
    multi_account: 'Multi Account',
    account_transfers: 'Transfers between accounts',
    update_user_data: 'Update your data',
    lms: 'Mentor',
    global: 'GLOBAL',
    portofolio: 'Portofolio',
    internal_transfers: 'Internal transfers',
    fiscal_debt: 'Fiscal debt',
    report: 'Report',
    notification_documents: 'Notification documents',
    pj_data: 'Legal person details',
    pj_documents: 'Legal person documents',
    invoices: 'Invoices',
    pies: 'PIES',
    analyses: 'Analyses',
    morning_news: 'Morning news',
    flash_report: 'Flash report'
  },
  footer: {
    disclaimer: 'Disclaimer',
    personal_data_policy: 'Confidentiality Policy',
    cookie_policy: 'Cookie Policy'
  },
  two_factor_auth_activation: {
    disabled: 'Two-factor authentication succesfully disabled!',
    failed: 'Incorrect code!',
    no_2fa: 'No 2FA',
    google_authentication_2fa: 'Google Authenticator 2FA',
    sms_authentication_2fa: 'SMS 2FA',
    enabled_google: 'Google Authenticator 2FA succesfully enabled',
    enabled_sms: 'SMS 2FA succesfully enabled'
  },
  address: {
    country: 'Country',
    county: 'County',
    locality_sector: 'Locality/Sector',
    street: 'Street',
    street_short: 'Str.',
    number: 'No.',
    building: 'Building',
    building_short: 'Bld.',
    entrance: 'Entrance',
    entrance_short: 'Entr.',
    flat: 'Flat',
    flat_short: 'Fl.',
    floor: 'Floor',
    floor_short: 'Flr.',
    zip_code: 'Zip code',
    google_autocomplete_label: 'Search by street and number',
    complete_address: 'Please enter the address!',
    start_typing: 'Start typing'
  },
  continue_to_onboarding: {
    restrict_access_message: 'For accessing this zone you must finish the onboarding process.',
    press_button_message: 'Press the button below to continue.',
    button_text: 'Finish onboarding'
  },
  meta_titles: {
    register: 'Register | Investimental Onboarding',
    email_verification: 'Email Verification | Investimental Onboarding',
    domicile: 'Domicile | Investimental Onboarding',
    verify_identity: 'Verify Identity | Investimental Onboarding',
    review_ocr_data: 'Review OCR Data | Investimental Onboarding',
    verify_bank_account: 'Verify Bank Account | Investimental Onboarding',
    select_residence: 'Residence | Investimental Onboarding',
    questionnaire: 'Questionnaire | Investimental Onboarding',
    documents: 'Documents | Investimental Onboarding',
    onboarding_info: 'Informations | Investimental Onboarding',
    personal_data: 'Personal Data | Investimental Portal',
    settings: 'User and contact data | Investimental Portal',
    fiscal_data: 'Fiscal Data | Investimental Portal',
    fiscal_debt: 'Fiscal Debt | Investimental Portal',
    bank_accounts: 'IBAN Codes | Investimental Portal',
    deposits: 'Deposits | Investimental Portal',
    withdrawals: 'Withdrawals | Investimental Portal',
    instruments_transfer: 'Portfolio Transfer | Investimental Portal',
    multi_account: 'Multi Account | Investimental Portal',
    account_transfers: 'Internal accounts transfers | Investimental Portal',
    profile_documents: 'Contractual Documents | Investimental Portal',
    client_documents: 'Client Documents | Investimental Portal',
    password_reset: 'Security | Investimental Portal',
    two_factor_authentication_activation: '2FA Activation | Investimental Portal',
    notifications: 'Notifications | Investimental Portal',
    messages: 'Messages | Investimental Portal',
    configure_notifications: 'Configure Notifications | Investimental Portal',
    login: 'Login | Investimental Portal',
    help: 'Help | Investimental Portal',
    portofolio: 'Portfolio | Investimental Portal',
    report: 'Report | Investimental Portal',
    password_recovery: 'Password Recovery | Investimental Portal',
    legal_person_details: 'Legal Person Details | Investimental Onboarding',
    phone_validation: 'Phone Validation | Investimental Onboarding',
    legal_person_data: 'Legal Person Data | Investimental Portal',
    pies: 'PIES | Investimental Portal',
    force_reset_password: 'Reset password | Investimental Portal'
  },
  forms: {
    calendar: {
      placeholder: 'dd.mm.yyyy'
    }
  },
  days_of_week: {
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday',
    sunday: 'Sunday'
  },
  months_of_year: {
    january: 'January',
    february: 'February',
    march: 'March',
    april: 'April',
    may: 'May',
    june: 'June',
    july: 'July',
    august: 'August',
    september: 'September',
    october: 'October',
    november: 'November',
    december: 'December'
  }
}
