export default {
  general: {
    update_account: 'Update data and information',
    dial_code: 'Dial code'
  },
  email_verification: {
    email_verification_guidance: 'Validate your email address',
    code_guidance: 'Please enter the 4 character code received on your email address. Don\'t forget to check your spam/junk folder.'
  },
  phone_number: {
    phone_verification_guidance: 'Validate your phone number',
    code_guidance: 'Please enter the 6 character code received on your phone number.'
  }
}
