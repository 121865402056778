import { render, staticRenderFns } from "./ButtonQuestion.vue?vue&type=template&id=ab46ca64&scoped=true"
import script from "./ButtonQuestion.vue?vue&type=script&lang=js"
export * from "./ButtonQuestion.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ab46ca64",
  null
  
)

export default component.exports