<template>
  <div class="container form-section mx-auto font-sans full-address" v-if="!loading">
    <div class="" :class="[ isAddressCompleted ? 'street' : 'w-full' ]">
      <div class="input-text">
        <label v-if="label" class="form-label inline-block pl-4"> {{ label }} </label>
        <label v-else class="form-label inline-block pl-4"> {{ $t('general.address.google_autocomplete_label') }} </label>
        <GoogleAutocomplete
          :key="refreshKey"
          :placeholder="`${$t('general.address.start_typing')}`"
          :precompleted-text="address.street"
          id="map" classname="input-primary w-full"
          v-on:placechanged="getAddressData"
          :value="address.street"
          :max-length="maxLength.street"
        />
      </div>

      <InputText v-if="isAddressCompleted" :max-length="maxLength.number" :disabled-input="!address.street" :label="`${$t('general.address.number')}`" :id="'country-number'" v-model="address.number" required/>
    </div>

    <div class="my-4" v-if="isAddressCompleted">
      <InputText
        right-side-icon="lock_icon"
        :label="`${$t('general.address.country')}`"
        :id="'country-id'"
        v-model="address.country"
        :type-input="'text'"
        required
        :disabled-input="true"
      />
    </div>

    <div class="mb-4" v-if="address.county && address.country_code === 'RO' && isAddressCompleted">
      <InputText
        :label="`${$t('general.address.county')}`"
        :id="'county-id'"
        v-model="address.county"
        required
        :disabled-input="true"
        right-side-icon="lock_icon"
        :type-input="'text'"
      />
    </div>

    <div class="form-group mb-4" v-if="isAddressCompleted">
      <InputText
        id="'locality'"
        v-model="address.locality"
        :label="`${$t('general.address.locality_sector')}`"
        :type-input="'text'"
        required
        :disabled-input="disableLocality"
        right-side-icon="lock_icon"
      />
    </div>

    <div class="form-group mb-4" v-if="isAddressCompleted">
      <InputText
        :id="'zip_code'"
        v-model="address.zip_code"
        :label="`${$t('general.address.zip_code')}`"
        :type-input="'text'"
      />
    </div>

    <div class="address-grid" v-if="isAddressCompleted">
      <div class="form-group mb-4">
        <InputText
          :id="'building'"
          v-model="address.building"
          :label="$t('general.address.building')"
          :max-length="maxLength.building"
          :type-input="'text'"
        />
      </div>

      <div class="form-group mb-4">
        <InputText
          :id="'staircase'"
          v-model="address.staircase"
          :label="$t('general.address.entrance')"
          :max-length="maxLength.entrance"
          :type-input="'text'"
        />
      </div>
    </div>

    <div class="address-grid" v-if="isAddressCompleted">
      <div class="form-group mb-4">
        <InputText
          :id="'floor'"
          v-model="address.floor"
          :label="$t('general.address.floor')"
          :max-length="maxLength.floor"
          :type-input="'text'"
        />
      </div>

      <div class="form-group mb-4">
        <InputText
          :id="'apartment'"
          v-model="address.apartment"
          :max-length="maxLength.apartment"
          :label="$t('general.address.flat')"
          :type-input="'text'"
        />
      </div>
    </div>

    <IconMessage
      v-if="savedAddress && showSaveButton"
      :color-green="true"
      :icon="'icons/check-mark-8'"
      :text="$t('settings.general.save_data_succes')"
    />

    <Button
      v-if="showSaveButton"
      :class="[isMobile ? 'w-full' : 'm-auto w-fit']"
      :text="$t('general.general.save')"
      :disabled="!address.street || !address.number || !address.country || !address.locality"
      @call="saveAddress"
    />

  </div>
</template>

<script>

import GoogleAutocomplete from './GoogleAutocomplete'
import InputText from './InputText'
import Button from './Button.vue'
import {mapGetters} from 'vuex'
import Message from './Message.vue'
import IconMessage from '@/components/subcomponents/IconMessage.vue'
import {ref} from 'vue'

export default {
  name: 'FullAddress',
  components: { IconMessage, Message, Button, GoogleAutocomplete, InputText },
  props: {
    preSelectedAddress: {
      type: Object,
      default: () => { }
    },

    type: {
      type: String
    },

    showSaveButton: {
      type: Boolean,
      default: true
    },

    showOnlyFirstInput: {
      type: Boolean,
      default: false
    },

    label: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      disableLocality: true,
      loading: true,
      address: {
        country: '',
        county: '',
        locality: '',
        street: '',
        number: '',
        building: '',
        floor: '',
        apartment: '',
        staircase: '',
        zip_code: '',
        country_code: '',
        county_code: '',
        sublocality: '',
        type: '',
        label: '',
        labelLong: ''
      },
      savedAddress: false,
      refreshKey: ref(0),
      maxLength: {
        number: 16,
        building: 16,
        entrance: 4,
        floor: 10,
        apartment: 16,
        street: 39
      }
    }
  },
  computed: {
    ...mapGetters({
      screenDevice: 'layout/screenDevice'
    }),
    isMobile () {
      return this.screenDevice === this.$screenDeviceConstants.MOBILE
    },
    isAddressCompleted () {
      return (this.address.street && this.showOnlyFirstInput) || !this.showOnlyFirstInput || !!Object.keys(this.preSelectedAddress).length
    }
  },
  methods: {
    getAddressData (address) {
      this.disableLocality = !!address.locality
      this.address.country = address.country
      this.address.county = address.administrative_area_level_1 || address.sublocality
      this.address.locality = address.locality
      this.address.street = address.route
      this.address.number = address.street_number
      this.address.zip_code = address.zip_code
      this.address.country_code = address.country_code
      this.address.county_code = address.county_code
      this.address.sublocality = address.sublocality
      this.address.type = this.type
      this.address.label = this.formatLabel()
      this.$emit('addressSet')
    },
    async setData () {
      if (Object.keys(this.preSelectedAddress).length) {
        this.address = this.preSelectedAddress
        this.refreshKey += 1
      }

      this.loading = false
    },
    formatLabel () {
      return this.address.street + (this.address.number ? ', ' + this.$i18n.t('general.address.number') + ' ' + this.address.number : '') +
        (this.address.building ? ', ' + this.$i18n.t('general.address.building_short') + ' ' + this.address.building : '') +
        (this.address.staircase ? ', ' + this.$i18n.t('general.address.entrance_short') + ' ' + this.address.staircase : '') +
        (this.address.floor ? ', ' + this.$i18n.t('general.address.floor_short') + ' ' + this.address.floor : '') +
        (this.address.apartment ? ', ' + this.$i18n.t('general.address.flat_short') + ' ' + this.address.apartment : '') +
        (this.address.zip_code ? ', ' + this.$i18n.t('general.address.zip_code') + ' ' + this.address.zip_code : '')
    },
    saveAddress () {
      this.savedAddress = true

      this.$emit('fullAddress', this.address)
    }
  },
  async mounted () {
    this.loading = true
    await this.setData()
  },
  watch: {
    address: {
      handler (nVal) {
        if (nVal.country_code !== 'RO') {
          nVal.county = ''
        }

        this.savedAddress = false

        this.address.label = this.formatLabel(nVal)

        if (!this.showSaveButton) {
          this.saveAddress()
        }
      },
      deep: true
    },
    preSelectedAddress () {
      this.loading = true

      this.setData()
    }
  }
}
</script>

<style scoped>

</style>
