import {wpLink} from '@/mixins/WordpressLinks'

export default {
  general: {
    create_account: 'Create account',
    identity_verification: 'Identity verification',
    reviewed_ocr_data: 'Personal data verification',
    financial_data: 'Financial data',
    validate: 'Validate',
    continue: 'Continue',
    create_legal_account: 'Create account'
  },
  prepare_onboarding: {
    title: 'Welcome to account opening!',
    subtitle: 'For a super-fast account opening, it would be good to have the following at hand:',
    requirement1: 'ID',
    requirement2: 'Your IBAN and a bank statement or another document or information that proves you are the account holder.',
    details_link: 'If you are a foreign citizen or have your domicile/residence abroad, we have here the information that can help you',
    details1: 'To ensure that you are indeed yourself, you will also need a video camera (if you are opening your account from a PC, you can easily switch to your phone).',
    ending: 'That\'s all. In a few minutes we will welcome you to the Investimental family!',
    info: 'Don\'t forget that if you encounter any problem, you can access the chat and an Investmentist will help you immediately.',
    legal_person: 'For account openings for legal entities,',
    click_here: 'click here'
  },
  email_verification: {
    email_verification_guidance: 'Validate the email address',
    code_guidance: 'Please enter the 4 characters code received on the e-mail address used for registration. Don\'t forget to check your spam/junk folder.',
    verif_code: 'Verification code',
    resend_code: 'Resend code',
    invalid_code: 'Invalid code!',
    user_verif_success: 'User verified successfully!',
    code_verif_success: 'Code was sent successfully!',
    code_validation: 'Please enter the code'
  },
  register: {
    log_in_question: 'Already have an account?',
    company: 'Company',
    person: 'Person',
    not_married: 'Not married',
    married: 'Married',
    distance_contract: 'I agree to start concluding a distance contract with Investimental S.A. and with the use of remote electronic means of communication, both initially and throughout the business relationship with Investimental S.A.',
    investimental_presentation: `I have read, understood and have no reservations regarding the information given in <a target="_blank" href="${wpLink('wp-content/Documente/Document-prezentare.pdf')}" class="color-primary cursor-pointer">Investimental presentation document</a>` +
      ` and the <a target="_blank" href="${wpLink('wp-content/Documente/Politica-executare-ordine.pdf')}" class="color-primary cursor-pointer">Order execution policy</a>`,
    gdpr: `I give my express consent regarding the processing of my personal data in order to initiate the opening of a trading account in relation to Investimental S.A. - <a target="_blank" href="${wpLink('/politica-de-confidentialitate')}" class="color-primary cursor-pointer">Privacy policy</a>`,
    marketing: 'I agree to receive from Investimental information about the offered services, campaigns, newsletter, etc.',
    validation_agreement: 'This agreement is mandatory!',
    legal_person_register: 'Are you a legal person?'
  },
  select_domicile: {
    permanent_domicile: 'Permanent domicile',
    select_country: 'Please select a country',
    select_citizenships: 'Please select at least one citizenship',
    complete_citizenships: 'Please complete the citizenship',
    citizenship: 'Citizenship',
    multiple_citizenship: 'Add multiple citizenship'
  },
  verify_bank_account: {
    iban: 'IBAN',
    bank_statement: 'Orice document bancar (care dovedește că ești titlularul IBAN-ului menționat. Exemplu: extras cont bancar, raport tranzacție, scrisoare, informații din aplicația de mobile banking etc.)'
  },
  review_ocr_data: {
    info: 'We extracted the following data from your document. It checks the complete data automatically and corrects if necessary.',
    pin_is_duplicated: `Oops! It seems like you already have an active trading account! If you forgot your password, you can reset it {openTag}here{closeTag}.`
  },
  documents: {
    statement: 'Statement',
    update_client_data: 'Toate datele si informatiile furnizate in actualul proces de actualizare sunt adevarate, corecte si complete',
    personal_statement: 'Declar pe propria răspundere, cunoscând prevederile legale privind falsul în declarații:',
    client_history: 'Nu dețin calitatea de inculpat și nu am fost condamnat pentru infracțiuni de spălare a banilor, finanțare a terorismului sau evaziune fiscală',
    client_data: 'Informațiile furnizate în vederea deschiderii contului de tranzacționare sunt adevărate, corecte și complete',
    accepted_documents: 'Am citit și accept toate documentele menționate mai jos care sunt parte integrantă a contractului, că înțeleg în totalitate prevederile acestora și că accept toate obligațiile impuse prin contract:',
    consent: 'Înțeleg și accept că bifarea acestor căsuțe are valoare de semnatură cu privire la cele declarate și la documentele contractuale de mai sus și îmi exprim astfel acordul de voință pentru încheierea contractului.'
  },
  reset_steps: {
    reset_identity: 'Are you sure you want to go back to the previous step? You will have to restart the identification process and the data collected will be overwritten.',
    reset_bank_account: 'Are you sure you want to go back to the previous step? You will have to restart the bank account process and the data collected will be overwritten.',
    reset_email_question: 'Întoarcerea la pasul precedent presupune crearea unui cont cu o adresă de email diferită. Ești sigur că vrei să te întorci la primul pas din crearea contului?',
    reset_residence: 'Are you shure you want to change the residence?',
    reset_questionnaire: 'Ești sigur că vrei să te întorci la chestionar?',
    reviewed_ocr_data: 'Are you sure you want to go back to the previous step? You will have to restart the identification process and the data collected will be overwritten.'
  },
  legal_person: {
    top_message: 'The trading account can only be opened by a legal representative of the legal entity',
    create_account: 'Create a legal entity account',
    email_address: 'The email address of the legal entity that will be used in the relationship with Investimental',
    as_legal_representative: 'As the legal representative of the legal entity:'
  },
  legal_person_details: {
    title: 'Legal entity details',
    company_details: 'Company details',
    address_office: 'Address of the registered office (street and number)',
    representative_details: 'Legal representative details',
    last_name: 'Last name',
    first_name: 'First name',
    address: 'Address',
    crn: 'Company registration number',
    tin: 'Tax identification number',
    company_name: 'Company name',
    pin: 'PIN',
    lei_code: 'LEI code',
    lei_code_expires_at: 'LEI code expiration date',
    validation_first_name: 'Please enter your first name!',
    validation_last_name: 'Please enter your last name!',
    validation_crn: 'Please enter your company registration number!',
    validation_tin: 'Please enter your tax identification number!',
    validation_company_name: 'Please enter your company name!',
    validation_pin: 'Please enter your personal identification number!',
    validation_country: 'Please enter select a country!',
    validation_pin_length: 'The pin must have maximum 13 digits',
    validation_pin_invalid: 'The pin is invalid',
    validation_crn_length: 'The crn length must be between 7 and 15 characters',
    validation_tin_length: 'The tin length must be between 4 and 15 characters',
    validation_company_name_duplicate: 'The CRN code is already associated with an account',
    validation_lei_code: 'Please enter your LEI code',
    validation_lei_code_length: 'LEI code must have 20 characters',
    validation_lei_code_expires_at: 'Please enter your LEI code expiration date',
    validation_lei_code_expires_at_before_today: 'The expiration date must be after today',
    warning_pin_validation: 'The PIN is not valid. Please check the entered data.'
  }
}
