<template>
  <div class="table-pie-stocks w-full">
    <div class="thead">
      <div class="tr">
        <div class="th">{{ $t('deposit_withdrawal.auto_invest.pie.table.instrument') }}</div>
        <div class="th">{{ $t('deposit_withdrawal.auto_invest.pie.table.value') }}</div>
        <div class="th flex flex-col items-center">
          <div>{{ $t('deposit_withdrawal.auto_invest.pie.table.target_current_percentage.target') }}</div>
          <div class="divider-for-values w-3/6"></div>
          <div>{{ $t('deposit_withdrawal.auto_invest.pie.table.target_current_percentage.percentage') }}</div>
        </div>
        <div class="th flex flex-col items-center">
          <div>{{ $t('deposit_withdrawal.auto_invest.pie.table.profit_profit_abs.profit') }}</div>
          <div class="divider-for-values w-3/6"></div>
          <div>{{ $t('deposit_withdrawal.auto_invest.pie.table.profit_profit_abs.profit_abs') }}</div>
        </div>
      </div>
    </div>
    <div class="tbody">
      <div class="tr" v-for="(stock, index) in stocksPie" :key="index">
        <div class="td">
          <div class="flex items-center gap-3">
            <div class="colored-block" :class="[`${colorBlock[index % 5]}`]"></div>
            <StockSecurityLogo :img="stock.stock_security.logo" :symbol="stock.stock_security.symbol" :color="colorBlock[index % 5]"/>
            <div class="stock-name">
              {{ stock.stock_security.name }}
            </div>
          </div>
        </div>
        <div class="td">$ {{ stock.current_value ? (Math.floor(stock.current_value * 100) / 100).toLocaleString('en-US', {maximumFractionDigits: 2, minimumFractionDigits: 2}) : 0 }}</div>

        <div class="td flex flex-col items-center">
          <div>{{ getTargetPercentageOfPie(stock, index) }}</div>
          <div class="divider-for-values w-3/12"></div>
          <div>{{ stock.current_percentage_of_pie ?
            (Math.floor(stock.current_percentage_of_pie * 100) / 100).toLocaleString('en-US', {maximumFractionDigits: 2, minimumFractionDigits: 2}) :
            parseFloat('0').toLocaleString('en-US', {maximumFractionDigits: 2, minimumFractionDigits: 2}) }}</div>
        </div>

        <div class="td flex flex-col items-center">
          <div>{{ stock.profit ? parseFloat(stock.profit).toLocaleString('en-US', {maximumFractionDigits: 2, minimumFractionDigits: 2}) : 0 }}% </div>
          <div class="divider-for-values w-3/12"></div>
          <div>$ {{ stock.profit_absolute ? parseFloat(stock.profit_absolute).toLocaleString('en-US', {maximumFractionDigits: 2, minimumFractionDigits: 2}) : 0 }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StockSecurityLogo from '@/components/subcomponents/StockSecurityLogo.vue'

export default {
  name: 'PieStocksTableDesktop',
  components: {StockSecurityLogo},
  props: {
    stocksPie: {
      type: Array,
      default: () => []
    },
    editingPie: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      colorBlock: [
        'light-blue',
        'dark-blue',
        'yellow',
        'primary',
        'pink'
      ]
    }
  },
  methods: {
    getTargetPercentageOfPie (stock, index) {
      let sum = 0
      if (index !== this.stocksPie.length - 1) {
        return (Math.floor(stock.target_percentage_of_pie * 100) / 100).toLocaleString('en-US', {maximumFractionDigits: 2, minimumFractionDigits: 2})
      } else {
        if (this.editingPie) {
          return (Math.ceil(stock.target_percentage_of_pie * 100) / 100).toLocaleString('en-US', {maximumFractionDigits: 2, minimumFractionDigits: 2})
        } else {
          this.stocksPie.forEach((currStock) => {
            if (currStock.id !== stock.id) {
              sum += parseFloat(currStock.target_percentage_of_pie)
            }
          })
          sum = Math.floor(sum * 100) / 100

          return (Math.floor((100 - sum) * 100) / 100).toLocaleString('en-US', {maximumFractionDigits: 2, minimumFractionDigits: 2})
        }
      }
    }
  }
}
</script>
