<template>
    <div class="login-component login pb-10">
        <vue-title
            :title="
                $t('general.meta_titles.two_factor_authentication_activation')
            "
        ></vue-title>
        <Card class="padding-custom fa-container text-center" :card-classes="'margin-bottom-medium'">
            <div slot="title" class="title">
                {{ $t("auth.general.two_factor_auth") }}
            </div>
            <div slot="title" class="subtitle">
                {{ $t("auth.two_factor_auth.guidance") }}
            </div>
            <div slot="body">
                <TwoFactorAuthenticationActivation @selected="selectedItem" :can-disable="false" />
                <div class="download-app">
                    <div>{{ $t("auth.two_factor_auth.download_app")  }}</div>
                    <div class="download-icons">
                        <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en"><img src="~@/assets/img/downloadgoogle.png" alt="" /></a>
                        <a href="https://apps.apple.com/us/app/google-authenticator/id388497605"><img src="~@/assets/img/downloadapple.png" alt="" /></a>
                    </div>
                </div>
            </div>
            <div v-if="!selected" slot="footer">
              <span class="flex text-left m-top-custom"><img class="m-right-custom" src="@/assets/img/icons/attention-onboarding.svg" alt="transfer-portfolio" />{{ $t('onboarding.prepare_onboarding.info') }}</span>
            </div>
        </Card>
    </div>
</template>

<script>
import Card from '../subcomponents/Card'
import TwoFactorAuthenticationActivation from '../auth/subcomponents/TwoFactorAuthenticationActivation'
import { mapGetters } from 'vuex'

export default {
  name: 'ForceTwoFactorAuthentication',
  components: { TwoFactorAuthenticationActivation, Card },
  computed: {
    ...mapGetters({
      user: 'user/user'
    })
  },
  data () {
    return {
      selected: false
    }
  },
  async mounted () {
    if (this.user && !this.user.need_two_factor_auth) {
      this.goToHomepage()
    }
  },
  methods: {
    goToHomepage () {
      window.location.href = '/'
    },
    selectedItem () {
      this.selected = true
    }
  },
  watch: {
    user: {
      handler (user) {
        if (user && !user.need_two_factor_auth) {
          this.goToHomepage()
        }
      },
      deep: true
    }
  }
}
</script>

<style scoped></style>
