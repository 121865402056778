<template>
  <div class="calendar">
    <FunctionalCalendar
      ref="calendar"
      :class="{'pointer-events-none' : disable}"
      :is-date-picker="true"
      :is-modal="true"
      :disabled-dates="disabledDates"
      :change-month-function="true"
      :change-year-function="true"
      @dayClicked="dayClicked"
      :date-format="format"
    >
      <template v-slot:datePickerInput>
        <InputText
          :id="id"
          v-model="selectedDate"
          :label="inputLabel"
          :type-input="'date'"
          :grey-label="inputGreyLabel"
          :disabled-input="disable"
          :autocomplete="'off'"
          :read-only = "isMobile"
        />
      </template>
    </FunctionalCalendar>
  </div>
</template>

<script>
import InputText from './InputText.vue'
import {format} from '@/helpers/dateHelper'
import {mapGetters} from 'vuex'

export default {
  name: 'Calendar',
  components: {
    InputText
  },
  props: {
    format: {
      type: String,
      default: 'dd/mm/yyyy'
    },
    value: {
      type: String,
      default: ''
    },
    inputLabel: {
      type: String,
      default: ''
    },
    inputGreyLabel: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    disable: {
      type: Boolean,
      default: false
    },
    disabledDates: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapGetters({
      screenDevice: 'layout/screenDevice'
    }),
    isMobile () {
      return this.screenDevice === this.$screenDeviceConstants.MOBILE
    }
  },
  methods: {
    dayClicked (day) {
      this.$emit('input', day.date)
      this.$refs.calendar.showCalendar = false
    }
  },
  data () {
    let calendarDataRelease = {}
    let selectedDate = null

    if (this.value) {
      calendarDataRelease.selectedDate = this.value
      selectedDate = format(this.value, this.format)
    }

    return {
      calendarDataRelease,
      selectedDate
    }
  },
  watch: {
    selectedDate () {
      this.$emit('input', this.selectedDate.replace(' ', ''))
    },
    value (newValue) {
      this.selectedDate = newValue.replace(' ', '')
    }
  }
}
</script>

<style scoped>

</style>
