import ApiService from '../apiService'

class AccountTransfersService {
  getAccounts (params = null) {
    return ApiService.withAuth().get('/api/user/accounts', { params: params })
  }
  sendTransfer (payload) {
    return ApiService.withAuth().post('api/user/internal-transfers', payload)
  }
  getTransfers () {
    return ApiService.withAuth().get('/api/user/internal-transfers')
  }
  getAccountBalance (payload) {
    return ApiService.withAuth().post('/api/user/internal-transfers/account-balance', payload)
  }
}

export default new AccountTransfersService()
