<template>
  <div>
    <nav>
      <div :class="[classNavbar]">
        <a target="_blank" :href="getWpEndpoint()" v-if="!isLms">
          <img class="m-auto logo" src="../../../assets/img/logo.svg" alt="logo">
        </a>
        <a target="_blank" :href="getLMSWpLink()" v-else>
          <img class="m-auto investimentor-logo" src="../../../assets/img/logo_investimentor.png" alt="logo">
        </a>
        <div v-if="layoutType === 'onboarding' && !isRegister" class="log-out cursor-pointer" @click="logoutUser">
          <img alt="log-out" src="~@/assets/img/icons/logout_blue.svg">
          <div v-if="screenDevice !== $screenDeviceConstants.MOBILE" >
            {{ $t('general.menu.log_out') }}
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'

export default {
  name: 'Navbar',
  computed: {
    ...mapGetters({
      layoutType: 'layout/getType',
      screenDevice: 'layout/screenDevice'
    }),
    classNavbar () {
      return this.layoutType === 'basic' ? 'basic-navbar' : 'onboarding-navbar'
    },
    isRegister () {
      return this.$route.name === 'register' || this.$route.name === 'register-lms' || this.$route.name === 'register-pj'
    },
    isLms () {
      return this.$route.meta['source'] === 'lms'
    }
  },
  methods: {
    ...mapActions({
      logout: 'user/logout'
    }),
    async logoutUser () {
      await this.logout()
      if (!this.isLogged) {
        await this.$router.push({name: 'login'})
      }
    }
  }
}
</script>

<style scoped>

</style>
