<template>
  <div class="tabs-component flex flex-wrap" :class="tabsClasses" v-if="tabs">
    <div class="text-sm font-medium text-center" :class="{'max-w-full w-full': !isDesktop}">
      <ul class="-mb-px" :class="{'flex flex-wrap': isDesktop}">
        <template v-if="!isDesktop && currentTab && (tab.route === currentTab.route || (tab.parent && tab.parent === currentTab.parent))" v-for="tab in tabs">
          <div :key="tab.label">
            <div :class="{'my-profile' : menuName === 'settings'}">
              <h1 class="page-title">{{ tab.title }}</h1>
            </div>
          </div>
        </template>

        <div class="relative tabs-wrapper">
          <div class="background-fade-left"></div>
          <div class="background-fade-right"></div>
          <div :class="{'links-wrapper-mobile': !isDesktop, 'links-wrapper flex': isDesktop}">
            <template v-for="tab in tabs">
              <li
                v-if="tabVisible(tab)"
                class="link-wrapper relative"
                :key="tab.id"
                :class="{
                  'cursor-pointer': tab.route,
                   'link-wrapper-mobile': !isDesktop,
                   'active': currentRouteName === tab.route || (currentTab && tab.parent && tab.parent === currentTab.parent),
                   'tab-deposits' : tab.route === 'deposits' && menuName === 'bankAccounts',
                   'id-expired' : tab.route === 'personal-data' && isIdExpired
                }"
                @click="toggleSubroutesDropdown(tab.id)"
              >
                <div class="notifications-wrapper">
                  <a @click="moveToRoute(tab)" :class="((currentTab && currentTab.route === tab.route) || (currentTab && tab.parent && tab.parent === currentTab.parent)) ? 'active' : ''" class="inline-block rounded-t-lg">{{showIdTitle ? tab.id + ' - ' : null }}{{ tab.label }}</a>
                  <span class="bubble" :class="{'active': currentRouteName === tab.route}" v-if="tab.unreadNotificationsBubble && userInfo">{{userInfo}}</span>
                  <span class="bubble" :class="{'active': currentRouteName === tab.route}" v-if="tab.unreadMessagesBubble && generalInfo">{{generalInfo}}</span>
                  <div v-if="tab.isBeta" :class="{'active': currentTab && currentTab.route && currentTab.route === tab.route}" class="is-beta">{{ $t('general.general.beta') }}</div>
                </div>

                <div v-if="isDesktop">
                  <div v-if="tab.subroutes">
                    <img :class="{'rotate': subrouteDropdownId}" :src="require(`@/assets/img/icons/arrow-down.svg`)" class="w-6 h-6" />

                    <div v-if="subrouteDropdownId === tab.id" class="subroutes-wrapper">
                      <template v-for="subroute in tab.subroutes">
                        <a v-if="tabVisible(subroute)"  :key="subroute.label" class="subroute" @click.prevent="moveToRoute(subroute)">{{ subroute.label }}</a>
                      </template>
                    </div>
                  </div>
                </div>
              </li>
            </template>
          </div>
        </div>

        <template v-if="!isDesktop && currentTab && currentTab.parent" v-for="tab in tabs">
          <div v-if="tab.parent === currentTab.parent && tabVisible(tab)" :key="tab.route" class="subroutes-wrapper">
            <template v-for="subroute in tab.subroutes" v-if="tab.subroutes && currentTab.parent === subroute.parent">
              <div v-if="tabVisible(subroute)" class="subroute" :class="{'active': currentRouteName === subroute.route}" :key="subroute.route" @click="moveToRoute(subroute)">
                {{ subroute.label }}
              </div>
            </template>
          </div>
        </template>
      </ul>
    </div>
  </div>
</template>

<script>

import {mapActions, mapGetters} from 'vuex'
import introJS from 'intro.js'

export default {
  name: 'Tabs',
  props: {
    tabsClasses: {
      type: Array
    },
    showIdTitle: {
      type: Boolean,
      default: true
    },
    userInfo: {
      type: Number,
      default: 0
    },
    generalInfo: {
      type: Number,
      default: 0
    },
    isShowPie: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      menuOpened: false,
      subrouteDropdownId: null,
      currentTab: null,
      scrolled: false
    }
  },
  methods: {
    ...mapActions({
      setIntroPing: 'guide/setIntroPing',
      setPieId: 'pies/setPieId'
    }),
    moveToRoute (tab) {
      if (!tab.route) {
        return
      }

      if (tab.subroutes && this.isDesktop) {
        return
      }
      this.scrollToSelectedSubTab()

      this.$router.push({name: tab.route})
    },

    toggleSubroutesDropdown (tabId) {
      if (this.subrouteDropdownId === tabId) {
        this.subrouteDropdownId = null

        return
      }

      this.subrouteDropdownId = tabId
    },

    getSelectedTab () {
      const currentRouteName = this.$route.name

      if (!this.tabs) {
        return
      }

      let currentTab = this.tabs.find(tab => tab.route === currentRouteName)

      if (!currentTab) {
        this.tabs.forEach(tab => {
          if (tab.subroutes) {
            let subTab = tab.subroutes.find(subroute => {
              return subroute.route === currentRouteName
            })

            if (subTab) {
              currentTab = subTab
              this.scrollToSelectedTab()
              this.scrollToSelectedSubTab()
              return null
            }
          }
        })
      }
      if (currentTab) {
        this.currentTab = currentTab
      }
      setTimeout(() => {
        this.scrollToSelectedTab()
        this.scrollToSelectedSubTab()
      })
    },

    scrollToSelectedTab () {
      let menuWrapper = document.querySelector('.links-wrapper-mobile')
      if (menuWrapper) {
        for (let child of menuWrapper.children) {
          if (child.classList.contains('active')) {
            this.$nextTick(() => {
              menuWrapper.scrollTo({
                left: child.getBoundingClientRect().left - 50
              })
            })
          }
        }
      }
    },

    scrollToSelectedSubTab () {
      let subroutesWrapper = document.querySelector('.subroutes-wrapper')
      if (subroutesWrapper) {
        for (let child of subroutesWrapper.children) {
          if (child.classList.contains('active')) {
            this.$nextTick(() => {
              subroutesWrapper.scrollTo({
                left: child.getBoundingClientRect().left - 50
              })
            })
          }
        }
      }
    },

    tabVisible (tab) {
      if (tab.route === 'notification-documents') {
        return this.hasAttachments
      }

      if (tab.hideForClientType) {
        return this.user.client_type.toLowerCase() === 'staff'
      }

      if (tab.hideForS1User && !this.hasAccessInPortal) {
        return !tab.hideForS1User
      }

      if (tab.hideForDesktop) {
        return !this.isDesktop
      }

      if (tab.hideForMobile) {
        return this.isDesktop
      }

      if (tab.showOnlyToCompany) {
        return this.user.type !== 'person'
      } else {
        return !tab.hideForCompany || this.user.type === 'person'
      }
    }
  },

  async mounted () {
    this.getSelectedTab()

    if (this.isShowPie) {
      this.currentTab = this.tabs.find(tab => tab.route === 'pies')
    }
  },

  computed: {
    ...mapGetters({
      tabs: 'menus/getMenu',
      screenDevice: 'layout/screenDevice',
      user: 'user/user',
      hasAttachments: 'user/hasAttachments',
      menuName: 'menus/getMenuName',
      hasAccessInPortal: 'user/hasAccessInPortal',
      getPieId: 'pies/pieId'
    }),
    currentRouteName () {
      return this.$route.name
    },
    isDesktop () {
      return this.screenDevice === this.$screenDeviceConstants.DESKTOP
    },
    isIdExpired () {
      return this.user.id_expired ?? false
    }
  }
}
</script>

<style scoped>

</style>
