<template>
  <div :class="[!isMobile ? 'pt-12' : '']">
      <Card :card-classes="'margin-bottom-medium mobile-border-none m-auto'">
        <img slot="icon" alt="my-account" src="~@/assets/img/icons/questionnaire.svg">
        <div slot="title">{{ $t('general.questionnaire.title') }}</div>
        <div v-if="loaded" slot="body">
          <Main v-if="!showIntro"
                :questions="questions"
                :questions-answers="answers"
                @back="back"
                @finish="finish"
                @next="next"
                @showIntro="toggleShowIntro"/>
          <div v-else>
            <div class="mt-4 mb-10">{{ $t('general.questionnaire.intro') }}</div>
            <div class="nav m-top-custom-l flex justify-between">
              <Button :icon="'icons/arrow_icon_back'"
                      :icon-position="'left'"
                      :text="$t('general.questionnaire.back')"
                      :type="'btn btn-secondary'"
                      class="prev"
                      @call="resetStep"/>
              <Button :disabled="false"
                      :icon="'icons/arrow_icon'"
                      :icon-position="'right'"
                      :text="$t('general.questionnaire.next')"
                      :type="'btn btn-primary end-position'"
                      class="next"
                      @call="startQuestionnaire"/>
            </div>
          </div>
        </div>
        <PulseLoader v-if="!loaded" slot="body"/>
      </Card>
  </div>
</template>

<script>
import Card from '../../subcomponents/Card'
import Main from '../../questionnaire/Main'
import questionnaireService from '../../../services/onboarding/questionnaireService'
import PulseLoader from 'vue-spinner/src/PulseLoader'
import GeneralLayout from '../../layout/GeneralLayout'
import Button from '../../subcomponents/Button'
import {mapGetters} from 'vuex'

export default {
  name: 'ReEvaluate',
  components: {Main, Card, PulseLoader, GeneralLayout, Button},
  computed: {
    ...mapGetters({
      screenDevice: 'layout/screenDevice'
    }),
    isMobile () {
      return this.screenDevice === this.$screenDeviceConstants.MOBILE
    }
  },
  data () {
    return {
      questions: [],
      answers: {},
      loaded: false,
      showIntro: true
    }
  },
  async mounted () {
    let questionsIndexResponse = await questionnaireService.getQuestions('test')

    if (questionsIndexResponse.status === 200) {
      this.questions = questionsIndexResponse.data.data
    }

    this.loaded = true
  },
  methods: {
    startQuestionnaire () {
      this.showIntro = false
    },
    toggleShowIntro () {
      this.showIntro = true
    },
    saveAnswer (selectedQuestionsAnswers, isFinish = false) {
      return questionnaireService.saveQuestionnaire({
        answers: selectedQuestionsAnswers,
        isFinish,
        redoTest: 1
      })
    },
    next (selectedQuestionsAnswers) {
    },
    back (selectedQuestionsAnswers) {
    },
    async resetStep () {
      await this.$router.push({name: 'profile-documents'})
    },
    async finish (selectedQuestionsAnswers) {
      await this.saveAnswer(selectedQuestionsAnswers, true)
      await this.$router.push({name: 'profile-documents'})
    }
  }
}
</script>

<style scoped>

</style>
