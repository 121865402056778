<template>
  <div>
    <div class="pl-5 mt-5 pie-back-button" @click="closeInstruments">
      <img alt="arrow-back" :src="require('@/assets/img/icons/simple_arrow_left_gray.svg')">
      <span>{{ $t('deposit_withdrawal.auto_invest.close_instruments') }}</span>
    </div>
    <div class="instruments-table mt-7">
      <div class="right-side flex flex-col">
        <div class="flex-grow w-full">
          <div class="industries-wrapper relative">
            <div class="background-fade-right" v-if="industries.length > 2 && !isCompletelyScrolled"></div>
            <div class="background-fade-left" v-if="industries.length > 2 && !isAtStart"></div>
            <div class="industries " v-if="!loadingIndustries" @scroll="scrollPosition">
              <div class="flex gap-1.5">
                <div
                  class="industry cursor-pointer"
                  v-for="(industry, key) in industries.slice(0, halfIndustriesLength)"
                  :key="key"
                  :class="{'selected' : seeSelectedIndustry(industry)}"
                  :id="`industry-${key}`"
                  @click="setIndustry(industry)"
                >
                  <IconColor
                    :icon="industry.icon ? industry.icon : 'bank'"
                    :is-icon-from-be="!!industry.icon"
                    :color="colorsIndustries[key % 6]"
                    :positions="[positionsInstruments[key % 6]]"
                    :smallest="true"
                  />
                  <div>
                    {{ industry.name }}
                  </div>
                </div>
              </div>
              <div class="flex gap-1.5">
                <div
                  class="industry cursor-pointer"
                  v-for="(industry, key) in industries.slice(halfIndustriesLength)"
                  :key="key"
                  :class="{'selected' : seeSelectedIndustry(industry)}"
                  @click="setIndustry(industry)"
                >
                  <IconColor
                    :icon="industry.icon ? industry.icon : 'bank'"
                    :color="colorsIndustries[(key + parseInt(halfIndustriesLength)) % 6]"
                    :positions="[positionsInstruments[(key + parseInt(halfIndustriesLength)) % 6]]"
                    :smallest="true"
                  />
                  <div>
                    {{ industry.name }}
                  </div>
                </div>
              </div>

            </div>
            <div class="color-light-gray text-sm pl-5"  v-if="!loadingIndustries">{{ $t('deposit_withdrawal.auto_invest.instruments.industries_source') }}</div>

            <PulseLoader v-else/>
          </div>

          <div class="mt-4 mb-3 px-5 text-center">
            <div class="font-bold" v-if="addedInstruments.length">
              {{ $t('deposit_withdrawal.auto_invest.instruments.selected') }}:
            </div>
            <div class="added-instruments mt-2">
              <div
                class="added-instrument"
                :class="[`color-${index % 2}`]"
                v-for="(addedInstrument, index) in addedInstruments"
                :key="index"
              >
                <span>{{ addedInstrument.stock_security.symbol }}</span>
                <img
                  class="cursor-pointer"
                  :src="require('@/assets/img/icons/close_circled.svg')"
                  :class="{'opacity-50 pointer-events-none': addedInstrument.current_value > 0}"
                  @click="removeInstrument(addedInstrument.stock_security.id)"
                  alt="close"
                >
              </div>
            </div>
          </div>

          <div class="mt-4 mb-3 px-5">
            <span class="font-bold">{{ $t('deposit_withdrawal.auto_invest.instruments.selected') }}: </span>
            <span>{{addedInstruments.length}}/{{maximumInstrumentsOfPie}}</span>
          </div>
        </div>
      </div>
      <div class="w-full px-5">
        <div class="search-instruments">
          <InputText
            class="w-full"
            :right-side-icon="'search_icon'"
            id="'search-instruments'"
            :placeholder="$t('deposit_withdrawal.auto_invest.instruments.search')"
            v-model="payload.search"
            @input="reloadData"/>
        </div>
        <div class="top-10-ribbon">
          <div class="uppercase font-bold">{{$t('deposit_withdrawal.auto_invest.instruments.top_ten')}}</div>

          <Checkbox
            :class="{'opacity-50 pointer-events-none' : !selectedTopTen && (payload.search.length || instruments.length < 10 || addedInstruments.length > 10)}"
            :id="'select-all-top-ten'"
            v-model="selectedTopTen"
            :label="messageSelectTopTen"
            :checkbox-white="true"
          />
        </div>
        <div class="instruments-box" v-if="!loadingInstruments">
          <InstrumentsTableMobile
            :max-number-of-instruments="maximumInstrumentsOfPie"
            :pages-number="pagesNumber"
            :stock-securities="instruments"
            :current-page="page"
            :added-instruments="addedInstruments"
            :editing-pie="!!pie.id"
            :search="payload.search.length"
            @addedInstrument="addedInstrument"
            @removeInstrument="removeInstrument"
            @nextPage="nextPage"/>
        </div>

        <PulseLoader class="flex justify-center mt-4" v-else/>

        <div>
          <Button
            :text="$t('general.general.next_step')"
            :type="'btn-primary'"
            :disabled="addedInstruments.length < minimumInstrumentsOfPie"
            class="mt-4 w-fit mx-auto"
            :extra-classes="'px-5'"
            @call="nextStep()"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PiesService from '@/services/deposit-withdrawal/piesService'
import InputText from '@/components/subcomponents/InputText.vue'
import Checkbox from '@/components/subcomponents/Checkbox.vue'
import InstrumentsTableMobile from '@/components/subcomponents/auto-invest/InstrumentsTableMobile.vue'
import Button from '@/components/subcomponents/Button.vue'
import {mapActions, mapGetters} from 'vuex'
import IconColor from '@/components/subcomponents/IconColor.vue'
import GeneralLayout from '@/components/layout/GeneralLayout.vue'
import Tabs from '@/components/layout/Tabs.vue'
import InstrumentsTable from '@/components/subcomponents/auto-invest/InstrumentsTable.vue'

export default {
  name: 'InstrumentsComponentMobile',
  components: {InstrumentsTable, Tabs, GeneralLayout, IconColor, Button, InstrumentsTableMobile, Checkbox, InputText},
  props: {
    pie: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      loadingInstruments: false,
      loadingIndustries: false,
      instruments: [],
      industries: [],
      page: 1,
      payload: {
        type: 'stock',
        search: ''
      },
      selectedTopTen: null,
      pagesNumber: 1,
      addedInstruments: [],
      colorsIndustries: ['blue', 'pink', 'yellow', 'green', 'orange', 'purple'],
      positionsInstruments: ['left', 'bottom', 'top', 'right'],
      selectedIndustry: null,
      savedInstruments: [],
      deletedInstruments: [],
      instrumentRemovedByHand: false,
      isCompletelyScrolled: false,
      isAtStart: true
    }
  },
  computed: {
    ...mapGetters({
      newPie: 'pies/newPie',
      minimumInstrumentsOfPie: 'user/minimumInstrumentsOfPie',
      maximumInstrumentsOfPie: 'user/maximumInstrumentsOfPie',
      deletedStockSecurities: 'pies/deletedStockSecurities',
      addedStockSecurities: 'pies/addedStockSecurities'
    }),

    messageSelectTopTen () {
      return `<span>
                ${this.$t('deposit_withdrawal.auto_invest.instruments.select_all')}
                <span class="font-bold">
                  ${this.$t('deposit_withdrawal.auto_invest.instruments.top_ten')}
                </span>
              </span>`
    },
    halfIndustriesLength () {
      return this.industries.length % 2 === 0 ? this.industries.length / 2 : this.industries.length / 2 + 1
    }
  },
  async mounted () {
    this.loadingInstruments = true
    await this.getAllInstruments(1, this.payload)
    await this.getIndustries()

    this.addedInstruments = this.newPie.positions ? this.newPie.positions : (this.pie.positions ? this.pie.positions : [])
    this.loadingInstruments = false

    this.deletedInstruments = this.deletedStockSecurities
  },
  methods: {
    ...mapActions({
      setNewPie: 'pies/setNewPie',
      setDeletedStockSecurities: 'pies/setDeletedStockSecurities',
      setAddedStockSecurities: 'pies/setAddedStockSecurities'
    }),

    scrollPosition () {
      if (this.industries.length > 2) {
        this.isCompletelyScrolled = document.getElementById(`industry-${Math.floor(this.halfIndustriesLength) - 1}`).getBoundingClientRect().right <= screen.width
        this.isAtStart = document.getElementById(`industry-0`).getBoundingClientRect().left === 20
      }
    },

    addedInstrumentsFromTop10 () {
      let top10Instruments = this.instruments.slice(0, 10)

      if (top10Instruments.length) {
        this.selectedTopTen = top10Instruments.every(top10Instrument =>
          this.addedInstruments.some(instrument => instrument.stock_security.id === top10Instrument.id)
        )
      } else {
        this.selectedTopTen = false
      }
    },

    closeInstruments () {
      this.$emit('closeInstruments')
    },

    async getAllInstruments (page, payload) {
      await PiesService.getAllInstruments(page || 1, payload)
        .then(response => {
          if (page === 1) {
            this.instruments = []
          }

          this.instruments = this.instruments.concat(Object.values(response.data.data.securities.data))
          this.pagesNumber = response.data.data.securities.last_page
        })
        .catch(error => {
          console.log(error)
        })

      this.addedInstrumentsFromTop10()
    },

    reloadData () {
      this.page = 1
      this.getAllInstruments(this.page, this.payload)
    },

    async getIndustries () {
      this.loadingIndustries = true
      await PiesService.getIndustries()
        .then(response => {
          this.industries = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.loadingIndustries = false
        })
    },
    seeSelectedIndustry (industry) {
      return this.payload.industry_identifier && this.payload.industry_identifier === industry.identifier
    },
    setIndustry (industry) {
      if (this.seeSelectedIndustry(industry)) {
        this.payload = {
          type: 'stock',
          search: ''
        }

        this.selectedIndustry = null
      } else {
        this.payload['industry_identifier'] = industry.identifier
        this.selectedIndustry = industry
      }
      this.instrumentRemovedByHand = true
      this.reloadData()
    },
    nextPage () {
      this.page += 1
      this.getAllInstruments(this.page, this.payload)
    },

    addedInstrument (addedInstrument) {
      this.addedInstruments.push(addedInstrument)
    },

    async removeInstrument (instrumentId) {
      this.deletedInstruments = this.deletedStockSecurities
      let addedStocks = this.addedStockSecurities
      let addedInstrument = this.addedInstruments.filter(item => item.stock_security.id === instrumentId)[0].stock_security
      this.addedInstruments = this.addedInstruments.filter(item => item.stock_security.id !== instrumentId)

      if (this.addedStockSecurities.filter(item => item === instrumentId).length) {
        addedStocks = addedStocks.filter(item => item !== addedInstrument.id)
        await this.setAddedStockSecurities(addedStocks)
      } else {
        this.deletedInstruments.push(addedInstrument.id)
        await this.setDeletedStockSecurities(this.deletedInstruments)
      }

      this.instrumentRemovedByHand = true

      this.addedInstrumentsFromTop10()
    },

    nextStep () {
      let pie = this.newPie
      pie['positions'] = this.addedInstruments

      this.setNewPie(pie)
      this.$emit('nextStep')
    }
  },
  watch: {
    selectedTopTen: {
      handler (value) {
        if (value) {
          let addedInstrumentsKeys = this.addedStockSecurities

          this.instruments.forEach((instrument, key) => {
            if (key <= 9) {
              let addedInstrument = {
                stock_security_id: instrument.id,
                stock_security: instrument,
                target_percentage_of_pie: 0
              }

              if (this.addedInstruments.filter(item => item.stock_security.id === addedInstrument.stock_security_id).length === 0) {
                this.addedInstruments.push(addedInstrument)
                addedInstrumentsKeys.push(addedInstrument.stock_security_id)
              }
            }
          })

          this.setAddedStockSecurities(addedInstrumentsKeys)

          this.savedInstruments = this.instruments
        } else if (!this.instrumentRemovedByHand) {
          let top10Ids = []

          this.instruments.forEach((instrument, key) => {
            if (key <= 9) {
              top10Ids.push(instrument.id)
            }
          })

          let deletedInstruments = this.addedInstruments.filter((deletedInstrument) => top10Ids.includes(deletedInstrument.stock_security.id) && !deletedInstrument.current_value)

          if (deletedInstruments.length === 0) {
            return
          }

          let recentlyAddedInstruments = this.addedStockSecurities
          let oldInstruments = []

          deletedInstruments.forEach((deletedInstrument) => {
            if (this.addedStockSecurities.includes(deletedInstrument.stock_security.id)) {
              recentlyAddedInstruments = recentlyAddedInstruments.filter((recentlyAddedInstrument) =>
                recentlyAddedInstrument !== deletedInstrument.stock_security.id
              )
            } else {
              oldInstruments.push(deletedInstrument)
            }
          })

          if (oldInstruments.length) {
            this.setDeletedStockSecurities(oldInstruments.map((oldInstrument) => oldInstrument.stock_security.id))
          }
          this.setAddedStockSecurities(recentlyAddedInstruments)

          this.addedInstruments = this.addedInstruments.filter((addedInstrument) =>
            !top10Ids.includes(addedInstrument.stock_security.id) || addedInstrument.current_value ||
            addedInstrument.current_value > 0)
        }
        this.instrumentRemovedByHand = false
      },
      deep: true
    }
  }
}
</script>
