<template>
  <div class="security">
    <general-layout>
      <template v-slot:title>
        <vue-title :title="$t('general.meta_titles.password_reset')"></vue-title>
      </template>

      <template v-slot:tabs>
        <Tabs :show-id-title="false" :tabs-classes="tabsClasses"/>
      </template>

      <template v-slot:body>
        <div class="desktop-flex gap-8" :class="[isMobile ? '' : 'mt-12']">
          <div class="security-container">
            <Card class="w-full" :class="{'!p-12': !isMobile}" :card-classes="'mobile-card-portal password-reset'">
              <template v-slot:title>
                <div :class="{'mt-5': isMobile}">
                  <span class="header">{{ $t('auth.general.reset_password') }}</span>
                </div>
              </template>

              <template v-slot:body>
                <div class="password-reset-component mt-6">
                  <InputText
                    class="m-bottom-custom"
                    v-model="oldPassword"
                    id="password_old"
                    :type-input="'password'"
                    :grey-label="true"
                    :label="$t('auth.reset_password.old_password')"
                  />

                  <Message
                    :type="'error'"
                    v-if="validation.oldPassword"
                    :message="validation.oldPassword"
                    class="mb-5"
                  />

                  <InputText
                    v-model="password"
                    id="password_new"
                    :grey-label="true"
                    :type-input="'password'"
                    :label="$t('auth.reset_password.new_password')"
                  />

                  <span class="color-light-gray text-sm">{{ $t('general.general.password_rules') }}</span>

                  <div v-for="validation in validation.password" :key="validation">
                    <Message :formatIsHtml="true" :message="validation" :type="'error'" class="mb-5"/>
                  </div>

                  <InputText
                    class="mb-8 mt-5"
                    v-model="repeatPassword"
                    id="password_repeat"
                    :grey-label="true"
                    :type-input="'password'"
                    :label="$t('auth.reset_password.repeat_new_password')"
                    @focusout="focusOut(password, repeatPassword)"/>

                  <Message
                    :type="'error'"
                    v-if="validation.repeatPassword"
                    :message="validation.repeatPassword"
                    class="mb-5"
                  />

                  <Message
                    v-if="validation.passwordMatch"
                    :message="validation.passwordMatch"
                    :type="'error'"
                    class="mb-5"
                  />

                  <Message
                    :type="'success'"
                    v-if="validation.general.success"
                    :message="validation.general.success"
                    class="mb-5"
                  />

                  <Message
                    :type="'error'"
                    v-if="validation.general.error"
                    :message="validation.general.error"
                    class="mb-5"
                  />

                  <Button
                    @call="passwordReset"
                    :extra-classes="'!px-8 !py-3'"
                    :loading="loadingData.save"
                    :text="$t('auth.general.save')"
                    :type="'btn-primary'"
                  />
                </div>
              </template>
            </Card>

            <TwoFactorAuthenticationActivation/>
          </div>

          <div>
            <div v-if="!isMobile">
              <Card class="w-full" :card-classes="'mobile-card-resources padding-small'">
                <template v-slot:body>
                  <div :class="{flex: !isMobile}" class="guide-wrapper">
                    <div class="image" :class="{'mr-8': !isMobile}">
                      <img class="security-img" width="230" height="230" src="@/assets/img/security-info.svg" alt="security">
                    </div>

                    <div class="flex items-center">
                      <p class="guide-text" v-html="$t('guides.password_reset.password_p1')"/>
                    </div>
                  </div>
                </template>
              </Card>
            </div>

            <div class="mt-8">
              <Resources :links="resourcesLinks"/>
            </div>
          </div>
        </div>
      </template>
    </general-layout>
  </div>
</template>

<script>
import GeneralLayout from '../layout/GeneralLayout'
import Button from '../subcomponents/Button'
import {mapActions, mapGetters} from 'vuex'
import Message from '../subcomponents/Message'
import Tabs from '../layout/Tabs'
import InputText from '../subcomponents/InputText'
import Card from '../subcomponents/Card'
import IconBox from '../subcomponents/IconBox.vue'
import PasswordValidations from '../../mixins/PasswordValidations'
import TwoFactorAuthenticationActivation from '../profile/TwoFactorAuthenticationActivation.vue'
import Resources from '../subcomponents/Resources.vue'

export default {
  name: 'PasswordReset',
  components: {
    Resources,
    TwoFactorAuthenticationActivation,
    GeneralLayout,
    Button,
    Message,
    Tabs,
    InputText,
    Card,
    IconBox
  },
  mixins: [ PasswordValidations ],
  data () {
    return {
      loadingData: {
        save: false
      },
      oldPassword: '',
      password: '',
      repeatPassword: '',
      validation: {
        oldPassword: '',
        password: [],
        repeatPassword: '',
        passwordMatch: '',
        general: {
          success: '',
          error: ''
        }
      },
      resourcesLinks: [
        { label: this.$t('useful_links.password_reset.password_how_to'), value: '/faq/cum-imi-pot-schimba-parola-in-portal/' },
        { label: this.$t('useful_links.password_reset.password_email_problem'), value: '/faq/nu-am-primit-email-ul-pentru-resetarea-parolei-ce-fac/' },
        { label: this.$t('useful_links.password_reset.password_sso'), value: '/faq/ce-insemna-autentificare-single-sign-on-sso-si-la-ce-ma-ajuta/' },
        { label: this.$t('useful_links.password_reset.password_forget'), value: '/faq/ce-fac-daca-am-uitat-parola/' }
      ],
      tabsClasses: []
    }
  },
  methods: {
    ...mapActions({
      resetPassword: 'user/resetPassword'
    }),
    async passwordReset () {
      try {
        this.loadingData.save = true
        if (!this.validate()) {
          return
        }

        await this.resetPassword({
          old_password: this.oldPassword,
          new_password: this.password
        }).then(response => {
          if (response.status === 200) {
            this.validation.general.success = this.$i18n.t('auth.reset_password.reset_successfully')
            this.oldPassword = ''
            this.password = ''
            this.repeatPassword = ''

            setTimeout(() => {
              this.validation.general.success = ''
            }, 5000)
          }
        }).catch(response => {
          if (response.response.status === 401) {
            this.validation.general.error = this.$i18n.t('auth.reset_password.old_password_invalid')
            if (response.response.data.data.hasOwnProperty('reset') && response.response.data.data.reset === false) {
              this.validation.general.error = response.response.data.message
            }
          } else {
            this.validation.general.error = response.response.data.message
          }

          setTimeout(() => {
            this.validation.general.error = ''
          }, 5000)
        })
      } finally {
        this.loadingData.save = false
      }
    },
    validate () {
      this.validation.password = []
      let valid = true

      if (!this.oldPassword) {
        this.validation.oldPassword = this.$t('auth.reset_password.old_password_validation')
        valid = false
      }

      if (!this.validatePassword(this.password, this.repeatPassword)) {
        valid = false
      }

      return valid
    }
  },
  computed: {
    ...mapGetters({
      screenDevice: 'layout/screenDevice'
    }),
    isMobile () {
      return this.screenDevice === this.$screenDeviceConstants.MOBILE
    }
  },
  watch: {
    password (nVal) {
      if (nVal) {
        this.validation.password = []
        this.validation.general.error = ''
        this.validation.passwordMatch = ''
      }
    },
    oldPassword (nVal) {
      if (nVal) {
        this.validation.oldPassword = ''
        this.validation.general.error = ''
      }
    },
    repeatPassword (nVal) {
      if (nVal) {
        this.validation.repeatPassword = ''
        this.validation.general.error = ''
      }
    }
  }
}
</script>

<style scoped></style>
